<template>
	
	<div>


				<div class="site-wrapper no-header" v-if="userRole == 'fleet'">
				
					<div class="container">

						<h3 class="is-size-5">Admin Update Vessel Counts</h3>

						<input class="input" type="text" placeholder="Vessel ID to be updated" v-model="vesselID" />

						<span v-if="showButtons" class="button is-info" @click="getCounts()">Update Counts</span>

						<span v-if="showButtons" class="button is-warning" @click="clearData()">Clear</span>

						<hr>

						<ul>
							<li v-for="(item, index) in count" :key="index">{{index}}: {{item}}</li>
						</ul>

						<span v-if="showUpdateButton" class="button is-danger" @click="updateVessel()">Update Vessel Document</span>

					</div>
				
					<div class="container">

						<hr>

						<h3 class="is-size-5">Admin Audit Vessel</h3>

						<input class="input" type="text" placeholder="Vessel ID to be audited" v-model="vesselAuditID" />

						<span v-if="showAuditButtons" class="button is-primary" @click="auditVessel()">Audit Vessel</span>

						<span v-if="showAuditButtons" class="button is-warning" @click="clearData()">Clear</span>

						<hr>

						<ul>
							<li v-for="(item, index) in auditResult" :key="index">{{index}}: {{item}}</li>
						</ul>

						<span v-if="showAuditLogButton" class="button is-danger" @click="logAudit()">Create Audit Log</span>

					</div>


				</div>

				<div class="container" v-else>
					<p>No access.</p>
				</div>




	</div>


</template>


<script>
	
	import firebase from 'firebase/compat/app';
	import { db } from '../main.js';
	import 'firebase/compat/auth'; 
	import 'firebase/firestore';
	import 'firebase/compat/functions';
	import NProgress from 'nprogress';

	

	export default {

		name: 'AdminAudits',

		data: function() {
			return {
				vesselID: '',
				vesselAuditID: '',
				
				count: {
					maintenanceLogs: 0,
					startupLogs: 0,
					maintenanceChecklists: 0,
					startupChecklists: 0,
					contacts: 0,
					sops: 0,
					eops: 0,
					policies: 0,
					hazards: 0,
				},

				showUpdateButton: false,
				showAuditLogButton: false,

				auditResult: {
			    hasOwnerStatement: false,
			    hasMasterStatement: false,
			    countInductions: 0,
			    countHazards: 0,
			    countContacts: 0,
			    countPolicies: 0,
			    countSOPS: 0,
			    countEOPS: 0,
			    countStartupItems: 0,
			    countStartupLogs: 0,
			    countMaintenanceItems: 0,
			    countMaintenanceLogs: 0,
			    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
			    auditScore: 0,
			    auditCode: 'red',
			    passedItems: [],
			    failedItems: [],
			  },
			  auditVesselOwnerID: '',

			}
		},

		computed: {
			showButtons() {
				return this.vesselID != '';
			},
			showAuditButtons() {
				return this.vesselAuditID != '';
			},
			userRole() {
	    	return this.$store.getters.getSubscriptionInfo.userrole;
	    },
		},

		methods: {

			clearCounts: function() {
				this.count.maintenanceLogs = 0;
				this.count.startupLogs = 0;
				this.count.maintenanceChecklists = 0;
				this.count.startupChecklists = 0;
				this.count.contacts = 0;
				this.count.sops = 0;
				this.count.eops = 0;
				this.count.policies = 0;
				this.count.hazards = 0;
			},

			getCounts: function() {
				NProgress.start();
				this.clearCounts();

				var self = this;
				db.collection('vessels').doc(self.vesselID).collection('logMaintenance').get().then(snap => {
				  self.count.maintenanceLogs = (snap.size ? snap.size : 0) 
				})
				.then(function() {
					db.collection('vessels').doc(self.vesselID).collection('logStartups').get().then(snap => {
					   self.count.startupLogs = (snap.size ? snap.size : 0) 
					});
				})
				.then(function() {
					db.collection('vessels').doc(self.vesselID).collection('smsMaintenanceItems').get().then(snap => {
					   self.count.maintenanceChecklists += (snap.size ? snap.size : 0) 
					});
				})
				.then(function() {
					db.collection('vessels').doc(self.vesselID).collection('smsCompanyMaintenanceItems').get().then(snap => {
					   self.count.maintenanceChecklists += (snap.size ? snap.size : 0) 
					});
				})
				.then(function() {
					db.collection('vessels').doc(self.vesselID).collection('smsStartupItems').get().then(snap => {
					   self.count.startupChecklists += (snap.size ? snap.size : 0) 
					});
				})
				.then(function() {
					db.collection('vessels').doc(self.vesselID).collection('smsCompanyStartupItems').get().then(snap => {
					   self.count.startupChecklists += (snap.size ? snap.size : 0) 
					});
				})
				.then(function() {
					db.collection('vessels').doc(self.vesselID).collection('smsContacts').get().then(snap => {
					   self.count.contacts += (snap.size ? snap.size : 0) 
					});
				})
				.then(function() {
					db.collection('vessels').doc(self.vesselID).collection('smsCompanyContacts').get().then(snap => {
					   self.count.contacts += (snap.size ? snap.size : 0) 
					});
				})
				.then(function() {
					db.collection('vessels').doc(self.vesselID).collection('smsHazards').get().then(snap => {
					   self.count.hazards += (snap.size ? snap.size : 0) 
					});
				})
				.then(function() {
					db.collection('vessels').doc(self.vesselID).collection('smsPolicies').get().then(snap => {
					   self.count.policies += (snap.size ? snap.size : 0) 
					});
				})
				.then(function() {
					db.collection('vessels').doc(self.vesselID).collection('smsCompanyPolicies').get().then(snap => {
					   self.count.policies += (snap.size ? snap.size : 0) 
					});
				})
				.then(function() {
					db.collection('vessels').doc(self.vesselID).collection('smsSOPS').get().then(snap => {
					   self.count.sops += (snap.size ? snap.size : 0) 
					});
				})
				.then(function() {
					db.collection('vessels').doc(self.vesselID).collection('smsCompanySOPS').get().then(snap => {
					   self.count.sops += (snap.size ? snap.size : 0) 
					});
				})
				.then(function() {
					db.collection('vessels').doc(self.vesselID).collection('smsEOPS').get().then(snap => {
					   self.count.eops += (snap.size ? snap.size : 0) 
					});
				})
				.then(function() {
					db.collection('vessels').doc(self.vesselID).collection('smsCompanyEOPS').get().then(snap => {
					   self.count.eops += (snap.size ? snap.size : 0) 
					});
				})
				.then(function() {
					console.log(self.count);
					self.showUpdateButton = true;
				});

				// db.collection('vessels').doc(this.vesselID).collection().get().then(function(doc) {

				// }).catch(function(err) {
				// 		console.log(err);
				// 		NProgress.done();
				// })

				NProgress.done();

			},

			updateVessel: function() {
				var self = this;
				NProgress.start();
				db.collection('vessels').doc(self.vesselID).update({
					countStartupItems: self.count.startupChecklists,
					countMaintenanceItems: self.count.maintenanceChecklists,
					countStartupLogs: self.count.startupLogs,
					countMaintenanceLogs: self.count.maintenanceLogs,
					countHazards: self.count.hazards,
					countContacts: self.count.contacts,
					countPolicies: self.count.policies,
					countSOPS: self.count.sops,
					countEOPS: self.count.eops,
				}).then(function() {
					NProgress.done();
				}).catch(function(err) {
					console.log(err);
					NProgress.done();
				});
			},

			auditVessel: function() {
				var self = this;
				NProgress.start();
				db.collection('vessels').doc(self.vesselAuditID).get().then(function(doc) {
					var data = doc.data();
					self.auditVesselOwnerID = data.vesselOwnerID;
					//console.log(self.auditVesselOwnerID);

					if (data.countHazards > 0) {
						self.auditResult.countHazards = data.countHazards;
						self.auditResult.auditScore += 2;
						self.auditResult.passedItems.push('hazards');
					} else {
	          self.auditResult.failedItems.push('hazards');
	        }

					if (data.countContacts > 0) {
						self.auditResult.countContacts = data.countContacts;
						self.auditResult.auditScore += 2;
						self.auditResult.passedItems.push('contacts');
					} else {
	          self.auditResult.failedItems.push('contacts');
	        }

					if (data.countPolicies > 0) {
						self.auditResult.countPolicies = data.countPolicies;
						self.auditResult.auditScore += 2;
						self.auditResult.passedItems.push('policies');
					} else {
	          self.auditResult.failedItems.push('policies');
	        }

					if (data.countSOPS > 0) {
						self.auditResult.countSOPS = data.countSOPS;
						self.auditResult.auditScore += 2;
						self.auditResult.passedItems.push('SOPS');
					} else {
	          self.auditResult.failedItems.push('SOPS');
	        }

					if (data.countEOPS > 0) {
						self.auditResult.countEOPS = data.countEOPS;
						self.auditResult.auditScore += 2;
						self.auditResult.passedItems.push('EOPS');
					} else {
	          self.auditResult.failedItems.push('EOPS');
	        }

					if (data.countStartupLogs > 0) {
						self.auditResult.countStartupLogs = data.countStartupLogs;
						self.auditResult.auditScore += 2;
						self.auditResult.passedItems.push('startupLogs');
					} else {
	          self.auditResult.failedItems.push('startupLogs');
	        }

					if (data.countMaintenanceLogs > 0) {
						self.auditResult.countMaintenanceLogs = data.countMaintenanceLogs;
						self.auditResult.auditScore += 2;
						self.auditResult.passedItems.push('maintenanceLogs');
					} else {
	          self.auditResult.failedItems.push('maintenanceLogs');
	        }

					if (data.countStartupItems > 0) {
						self.auditResult.countStartupItems = data.countStartupItems;
						self.auditResult.auditScore += 1;
						self.auditResult.passedItems.push('startupItems');
					} else {
	          self.auditResult.failedItems.push('startupItems');
	        }

					if (data.countMaintenanceItems > 0) {
						self.auditResult.countMaintenanceItems = data.countMaintenanceItems;
						self.auditResult.auditScore += 1;
						self.auditResult.passedItems.push('maintenanceItems');
					} else {
	          self.auditResult.failedItems.push('maintenanceItems');
	        }

	        console.log('Score 1: ',self.auditResult.auditScore);

				}).then(function() {
					db.collection('userdata').doc(self.auditVesselOwnerID).get().then(function(doc) {
						console.log(doc.data());
						if (doc.data().ownerStatement != '') {
							self.auditResult.hasOwnerStatement = true;
							self.auditResult.auditScore += 1;
							self.auditResult.passedItems.push('ownerStatement');
						} else {
		          self.auditResult.failedItems.push('ownerStatement');
		        }	

						if (doc.data().masterStatement != '') {
							self.auditResult.hasMasterStatement = true;
							self.auditResult.auditScore += 1;
							self.auditResult.passedItems.push('masterStatement');
						} else {
		          self.auditResult.failedItems.push('masterStatement');
		        }
		        console.log('Score 2: ',self.auditResult.auditScore);
					})
					.then(function() {
						db.collection('userdata').doc(self.auditVesselOwnerID).collection('crewInductions').get().then(snap => {
						   self.auditResult.countInductions += (snap.size ? snap.size : 0);
						   if (snap.size > 0) {
						   		self.auditResult.auditScore += 2;
									self.auditResult.passedItems.push('crewInductions');
						   } else {
				          self.auditResult.failedItems.push('crewInductions');
				       }
				       console.log('Score 3: ',self.auditResult.auditScore);
						})
						.then(function() {
							console.log(self.auditResult.auditScore);
							//self.auditResult.auditCode = (self.auditResult.auditScore > 10 ? (self.auditResult.auditScore == 20 ? 'green' : 'orange') : 'red');
							if (self.auditResult.auditScore > 10) {
								self.auditResult.auditCode = self.auditResult.auditScore > 19 ? 'green' : 'orange';
							}
							else {
								self.auditResult.auditCode = 'red';
							}
							self.showAuditLogButton = true;
							console.log('Score 4: ',self.auditResult.auditScore);
							NProgress.done();
						});
					});
				});
			},

			logAudit: function() {
				NProgress.start();
				console.log("Log this: ",this.auditResult);
				var self = this;
				db.collection('vessels').doc(self.vesselAuditID).collection('smsAudits').add(self.auditResult)
				.then(function(doc) {
					var auditID = doc.id;
					console.log(auditID);
		      db.collection('vessels').doc(self.vesselAuditID).update({
		        lastAuditDate: firebase.firestore.FieldValue.serverTimestamp(),
		        lastAuditID: auditID,
		        lastAuditCode: self.auditResult.auditCode,
		        lastAuditScore: self.auditResult.auditScore,
		      });
		    }).then(function() {
		    	NProgress.done();
		    }).catch(function(error) {
		    	console.log(error);
		    	NProgress.done();
		    });
			},

			clearData: function() {
				this.vesselID = '';
				this.vesselAuditID = '';
				this.count = {
					maintenanceLogs: 0,
					startupLogs: 0,
					maintenanceChecklists: 0,
					startupChecklists: 0,
					contacts: 0,
					sops: 0,
					eops: 0,
					policies: 0,
					hazards: 0,
				};
				this.showUpdateButton = false;
				this.auditResult = {
			    hasOwnerStatement: false,
			    hasMasterStatement: false,
			    countInductions: false,
			    countHazards: false,
			    countContacts: false,
			    countPolicies: false,
			    countSOPS: false,
			    countEOPS: false,
			    countStartupItems: false,
			    countStartupLogs: false,
			    countMaintenanceItems: false,
			    countMaintenanceLogs: false,
			    //timestamp: firebase.firestore.FieldValue.serverTimestamp(),
			    auditScore: 0,
			    auditCode: 'red',
			    passedItems: [],
			    failedItems: [],
			  };
			  this.auditResult = {
			    hasOwnerStatement: false,
			    hasMasterStatement: false,
			    countInductions: 0,
			    countHazards: 0,
			    countContacts: 0,
			    countPolicies: 0,
			    countSOPS: 0,
			    countEOPS: 0,
			    countStartupItems: 0,
			    countStartupLogs: 0,
			    countMaintenanceItems: 0,
			    countMaintenanceLogs: 0,
			    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
			    auditScore: 0,
			    auditCode: 'red',
			    passedItems: [],
			    failedItems: [],
			  };
			  this.auditVesselOwnerID = '';
			  this.showAuditLogButton = false;
			},



		}

}



</script>


<style lang="scss" scoped>
	
	h3 {
		margin-bottom: 1rem;
	}
	span.button {
		margin-top: 1rem;
		margin-right: 1rem;
	}
	table {
		width: 100%;
		tr {
			width: 100%;
			td {
				&:first-of-type {
					width: 35%;
				}
			}
		}
	}
</style>









