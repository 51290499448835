<template>
	
	<div>

			<div class="site-wrapper no-header">
			
				<div class="container">

						<h3 class="is-size-5">Twilio Testing</h3><br>

						<div class="form-group-reg">      
              <label>Mobile number ({{finalNumber}})</label>
              <input class="input" type="tel" placeholder="Starting with 04.." v-model="phone" maxlength="10" v-on:keypress="isNumber($event)">
            </div>

            <div class="form-group-reg">      
              <!-- <textarea rows="3" placeholder="SMS Message" class="input" :maxlength="limit" v-model="message" v-on:keypress="countCharacters($event)"></textarea> -->
              <label>Message</label>
              <input class="input" type="text" placeholder="SMS Message" v-model="message" :maxlength="limit">
            	<label class="right">{{messageLength}}/{{limit}}</label>
            </div>

            <div class="buttons">
              <button :disabled="!phoneNumberValid || message.length == 0" class="button is-info" @click.prevent="sendSMS">Send SMS</button>
              <button class="button" @click.prevent="clear">Clear</button>
            </div>						

				</div>

			</div>


			




	</div>

</template>


<script> 

	import firebase from 'firebase/compat/app';
	import { db } from '../main.js';
	import 'firebase/compat/auth'; 
	import 'firebase/firestore';
	import 'firebase/compat/functions';
	import NProgress from 'nprogress';	
	//import * as moment from 'moment';

	export default {

		name: 'AdminVessels',
		components: {
	  	
	  },
		mounted: function() {
			firebase.auth().onAuthStateChanged( this.onUserLogin );	
		},

		data: function() {
			return {
				phone: '',
				limit: 60,
				message: '',
			}
		},

		computed: {
			isSteve() {
				return this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' || this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1';
			},
			user() {
	    	return this.$store.getters.getUser;
	    },
	    phoneNumberValid() {
	      return this.phone.length == 10;
	    },
	    messageLength() {
	    	return this.message.length;
	    },
	    finalNumber() {
	      return "+61" + this.phone.slice(1,10);
	    },
		},

		methods: {

			onUserLogin: function( user ) {
      	if (user) {      		
	    		console.log(user);
	      }
      },

      close: function() {
      	
      },

    	isNumber: function($evt) {
	      $evt = ($evt) ? $evt : window.event;
	      var charCode = ($evt.which) ? $evt.which : $evt.keyCode;
	      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
	        $evt.preventDefault();
	      } else {
	        return true;
	      }
	    },

	    clear: function() {
	    	this.message = '';
	    	this.phone = '';
	    },

	    sendSMS: function() {
	    	let self = this;      
	      let message = {
	        title: 'Send this SMS?',
	        body: 'Charges may apply.'
	      };
	      let options = {
	        okText: 'Yes, SEND',
	        backdropClose: true,
	        cancelText: 'Cancel',
	      };
	      this.$dialog
	        .confirm(message, options)
	        .then(function() {
	          NProgress.start();	
				var sendTwilioSMS = firebase.functions().httpsCallable('sendTwilioSMS');
		        sendTwilioSMS({
		        	toNumber: self.finalNumber,
		        	message: self.message,
					revisionDescription: 'OffshoreSMS Admin has sent an SMS message',
		        });
		        self.clear();
		        NProgress.done();
	        })
	        .catch(function(error) {
	        	console.log(error);
	          NProgress.done();
	        });  
	    },

      

		},

	}



</script> 




<style lang="scss" scoped>
	
	textarea {
		min-height: 100px !important;
		box-shadow: none;
    padding: 0.6rem 1rem;
  }
  label {
  	&.right {
  		text-align: right;
  		display: block;
  	}
  }
	

</style> 