import Vue from 'vue';
import Vuex from 'vuex';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/firestore';

import { db } from './main.js';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null,
        subscriptionInfo: {},
        isAdmin: false,
        // offshoresmsAdmins: ['kZ0YdR4pk7bOVKaEM2wB6a44Nym2', '3wTzZecD4QYOPUk4IyWRA2JmDcJ2'],
        offshoresmsAdmins: ['3wTzZecD4QYOPUk4IyWRA2JmDcJ2']
    },
    getters: {
        getUser: state => {
            return state.user;
        },
        getSubscriptionInfo: state => {
            return state.subscriptionInfo;
        },
        getIsAdmin: state => {
            return state.isAdmin;
        },
        getOffshoresmsAdmins: state => {
            return state.offshoresmsAdmins;
        }
    },
    mutations: {
        RESETSTATES: state => {
            state.user = null;
            state.subscriptionInfo = {};
        },
        SETISADMIN: state => {
            firebase.auth().onAuthStateChanged(user => {
                console.log('User:', user.uid);
                if (
                    user.uid === 'M3GGz7qWIxNqAsN2Ojglrv334jm1' ||
                    user.uid === '9u1j9Bo1QDWU8fSxW1LiIA4qa103' ||
                    user.uid === '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ||
                    user.uid === 'kZ0YdR4pk7bOVKaEM2wB6a44Nym2'
                ) {
                    state.isAdmin = true;
                } else {
                    state.isAdmin = false;
                }
            });
        },
        SETUSER: state => {
            state.user = firebase.auth().currentUser;
        },
        SETSUBSCRIPTIONINFO: state => {
            if (state.user) {
                db.collection('userdata')
                    .doc(state.user.uid)
                    .onSnapshot(function(documentSnapshot) {
                        if (documentSnapshot.exists) {
                            var data = documentSnapshot.data();
                            let tempDate = data.anniversaryDate ? data.anniversaryDate.toDate() : '';
                            let stringDate = String(tempDate);
                            state.subscriptionInfo.currentMaxVessels = data.maxVessels ? data.maxVessels : 0;
                            state.subscriptionInfo.anniversaryDate = stringDate;
                            state.subscriptionInfo.nextCharge = data.nextCharge ? data.nextCharge : 0;
                            state.subscriptionInfo.test = stringDate;
                            state.subscriptionInfo.userrole = data.userrole ? data.userrole : 0;
                            state.subscriptionInfo.isTrainer = data.isTrainer ? data.isTrainer : false;
                            state.subscriptionInfo.doesDiving = data.doesDiving ? data.doesDiving : false;
                            state.subscriptionInfo.trainerOrganisation = data.trainerOrganisation ? data.trainerOrganisation : 'not set';
                            state.subscriptionInfo.trainerDefaultState = data.trainerDefaultState ? data.trainerDefaultState : false;
                            state.subscriptionInfo.trainerDefaultIndustry = data.trainerDefaultIndustry ? data.trainerDefaultIndustry : false;
                            state.subscriptionInfo.trainerDefaultIndustry = data.trainerDefaultIndustry ? data.trainerDefaultIndustry : false;
                            state.subscriptionInfo.username = data.username ? data.username : 'No name';
                            state.subscriptionInfo.useremail = data.useremail ? data.useremail : 'No email';
                            state.subscriptionInfo.userphone = data.userphone ? data.userphone : 'No phone';
                            state.subscriptionInfo.userid = data.userid ? data.userid : documentSnapshot.id;
                            state.subscriptionInfo.takenDashboardTour = data.takenDashboardTour ? data.takenDashboardTour : false;
                            state.subscriptionInfo.seenAppPrompt = data.seenAppPrompt ? data.seenAppPrompt : false;
                            state.subscriptionInfo.seenAppPromptDate = data.seenAppPromptDate ? data.seenAppPromptDate : '';
                        }
                    });
            }
        }
    },
    actions: {
        setUser: context => {
            context.commit('SETUSER');
        },
        SETSUBSCRIPTIONINFO: context => {
            context.commit('SETSUBSCRIPTIONINFO');
        },
        resetStates: context => {
            context.commit('RESETSTATES');
        },
        SETISADMIN: context => {
            context.commit('SETISADMIN');
        }
    }
});
