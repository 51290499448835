<template>

    <div class="site-wrapper no-header">

  		<div class="container">
  			
  			<div v-if="isAdmin">

          <div class="notification is-info"><h3>All App Users</h3></div>

          <div class="select">
            <select v-model="selectedState">
              <option value="all">All States</option>
              <option v-for="state in allstates" :value="state.name" :key="state.id">
                {{ state.name }}
              </option>
            </select>
          </div>

          <div class="select">
            <select v-model="selectedIndustry">
              <option value="all">All Industries</option>
              <option v-for="industry in industries" :value="industry.name" :key="industry.id">
                {{ industry.name }}
              </option>
            </select>
          </div>

           <div class="select">
            <select v-model="selectedRole">
              <option value="all">All Plans</option>
              <option v-for="role in roles" :value="role.slug" :key="role.slug">
                {{ role.name }}
              </option>
            </select>
          </div>

           <div class="select">
            <select v-model="selectedGroup">
              <option value="all">All Groups</option>
              <option v-for="group in groups" :value="group.name" :key="group.name">
                {{ group.name }}
              </option>
            </select>
          </div>

          <hr>


          <div v-if="!isSelected" class="notification is-primary">
            <input class="input" v-model="findName" id="filtername" autocomplete="off" type="text" placeholder="Start typing user name..." />
          </div>

          <div class="buttons">
            <span v-if="findName != ''" class="button is-warning" @click="clearUser()">Clear</span>
          </div>

          <hr>


          <!-- <ul>
            <li v-for="email in selectedUsers">{{email}}</li>
          </ul> -->

          {{selectedUsers.length}}


          <div v-if="users">

            <table>
              <tr v-for="(user, index) in filteredNames" :key="index">
                <td>{{user.username}} - {{user.useremail}}</td>
                <td>
                  <input class="is-checkradio" type="checkbox" :id="'index_id_' + index" :value="user.useremail" v-model="selectedUsers">
                  <label :for="'index_id_' + index"> </label>
                </td>
              </tr>
            </table>

          </div>



        </div>

  			<div v-else>This page is restricted.</div>

  		</div>


      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showProfile}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">User Details: {{selectedUser.username}}</p>
              <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>
            <section class="modal-card-body">       

              <table class="table is-fullwidth">
                <tbody>
                  <tr>
                    <td>Name:</td>
                    <td>{{selectedUser.username}}</td>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <td v-if="isSteve"><a :href="'mailto:' + selectedUser.useremail + '?Subject=Message%20from%20Offshore%20SMS%20Team'">{{selectedUser.useremail}}</a></td>
                    <td v-else><span>{{selectedUser.useremail}}</span></td>
                  </tr>
                  <tr>
                    <td>Phone:</td>
                    <td v-if="isSteve"><a :href="'tel:' + selectedUser.userphone">{{selectedUser.userphone}}</a></td>
                    <td v-else><span>{{selectedUser.userphone}}</span></td>
                  </tr>
                  <tr>
                    <td>Industry:</td>
                    <td>{{selectedUser.industry}}</td>
                  </tr>

                  <tr>
                    <td>State:</td>
                    <td>{{selectedUser.userstate}}</td>
                  </tr>
                  <tr>
                    <td>Signup Date:</td>
                    <td>{{selectedUser.signUpDate}}</td>
                  </tr>
                  <tr>
                    <td>Signup Platform</td>
                    <td><span :class="selectedUser.signupPlatform">{{selectedUser.signupPlatform}}</span></td>
                  </tr>
                  <tr>
                    <td>Has logged in on app?</td>
                    <td>{{selectedUser.hasLoggedInOnApp ? 'Yes' : '-'}}</td>
                  </tr>

                  <tr>
                    <td colspan="2"><div class="spacer s10"></div><span class="heading">Profiling information from user</span></td>
                  </tr>
                  <tr>
                    <td>User Segments</td>
                    <td v-if="selectedUser.profilingInfo.selectedTypes">
                      <div v-for="item in selectedUser.profilingInfo.selectedTypes">{{item}}</div>
                    </td>
                    <td v-else>-</td>
                  </tr>
                  <tr>
                    <td>Industries</td>
                    <td v-if="selectedUser.profilingInfo.selectedIndustries">
                      <div v-for="item in selectedUser.profilingInfo.selectedIndustries">{{item}}</div>
                    </td>
                    <td v-else>-</td>
                  </tr>
                  <tr>
                    <td>Vessels in Operation</td>
                    <td v-if="selectedUser.profilingInfo.numVessels">{{selectedUser.profilingInfo.numVessels}}</td>
                    <td v-else>-</td>
                  </tr>
                  <tr>
                    <td>Crew in Operation</td>
                    <td v-if="selectedUser.profilingInfo.numCrew">{{selectedUser.profilingInfo.numCrew}}</td>
                    <td v-else>-</td>
                  </tr>
                  <tr>
                    <td>Downloaded App?</td>
                    <td v-if="selectedUser.profilingInfo.appAnswers">
                      <div v-for="item in selectedUser.profilingInfo.appAnswers">{{item}}</div>
                    </td>
                    <td v-else>-</td>
                  </tr>
                  <tr>
                    <td>How they found us?</td>
                    <td v-if="selectedUser.profilingInfo.findAnswers">
                      <div v-for="item in selectedUser.profilingInfo.findAnswers">{{item}}</div>
                    </td>
                    <td v-else>-</td>
                  </tr>

                  <tr>
                    <td colspan="2"><div class="spacer s10"></div><span class="heading">Other information</span></td>
                  </tr>
                  <tr>
                    <td>Own Vessels</td>
                    <td><div v-for="vessel in selectedUser.myVessels">{{vessel.vesselName}}</div></td>
                  </tr>
                  <tr>
                    <td>Own Vessel Count</td>
                    <td>{{selectedUser.myVessels.length}}</td>
                  </tr>
                  <tr>
                    <td>Vessels I Master</td>
                    <td><div v-for="vessel in selectedUser.masteredVessels">{{vessel}}</div></td>
                  </tr>
                  <tr>
                    <td>Vessels I Crew</td>
                    <td><div v-for="vessel in selectedUser.crewedVessels">{{vessel}}</div></td>
                  </tr>
                  <tr>
                    <td>My Fleets</td>
                    <td>
                      <div v-for="fleet in selectedUser.myCompanies">{{fleet}}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>Favourite Vessel Name</td>
                    <td>{{selectedUser.favouriteVesselName}}</td>
                  </tr>
                  <tr>
                    <td>Favourite Fixed?</td>
                    <td>{{selectedUser.favouriteFixed ? 'Yes' : 'NO!'}}</td>
                  </tr>

                  <tr>
                    <td>User ID:</td>
                    <td>{{selectedUser.userid}}</td>
                  </tr>

                  <tr>
                    <td>Admin notes:</td>
                    <td>{{selectedUser.adminNotes}}</td>
                  </tr>
                </tbody>
              </table>

            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click.prevent="close">Done</button>
              <button v-if="isSteve" class="button is-danger" @click.prevent="fixFavourite(selectedUser)">Fix Fave</button>
              <button v-if="isSteve" class="button is-warning" @click.prevent="updateProfile(selectedUser.userid)">Update Profile</button>
              <button v-if="isSteve" class="button is-warning" @click.prevent="updateEmail(selectedUser.userid)">Update Email</button>
              <button v-if="isSteve" class="button is-info" @click.prevent="addEntry(selectedUser)">Timeline</button>
            </footer>
        </div>
      </div>




    </div>

</template>



<script>
// @ is an alias to /src
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; 
import * as moment from 'moment';
  import NProgress from 'nprogress';

export default {
  name: 'SelectedUsers',

  data: function() {
    return {
      firebaseid: '',
      firebaseemail: '',
      firebasedisplayname: '',
      users: [],
      allstates: [
        { id: "1", name: "Tasmania" },
        { id: "2", name: "Victoria" },
        { id: "3", name: "New South Wales" },
        { id: "4", name: "Queensland" },
        { id: "5", name: "South Australia" },
        { id: "6", name: "Western Australia" },
        { id: "7", name: "Northern Territory" }
      ],
      industries: [
        { id: "1", name: "Fishing"},
        { id: "2", name: "Aquaculture"},
        { id: "3", name: "Commercial Diving"},
        { id: "4", name: "Marine Construction"},
        { id: "5", name: "Charter Vessel"},
        { id: "6", name: "Passenger Vessel"},
        { id: "7", name: "Other"},
      ],
      roles: [
        {slug: 'free', name: 'Free'},
        {slug: 'starter', name: 'Starter'},
        {slug: 'fleet', name: 'Fleet'},
      ],
      groups: [
        {id: '1', name: 'Tasmanian Abalone'},
      ],
      selectedState: 'all',
      selectedIndustry: 'all',
      selectedRole: 'all',
      selectedGroup: 'all',
      showProfile: false,

      selectedUserID: '',
      selectedUser: {
        userid: '',
        username: '',
        useremail: '',
        userphone: '',
        industry: '',
        userrole: '',
        userstate: '',
        signUpDate: '',
        anniversaryDate: '',
        maxVessels: '',
        adminNotes: '',
        doesDiving: '',
        isTrainer: '',
        myVessels: [],
        lastCharge: '',
        nextCharge: '',
        lastPayment: '',
        masteredVesselIDs: [],
        crewedVesselIDs: [],
        masteredVessels: [],
        crewedVessels: [],
        favouriteFixed: false,
        favouriteVesselID: '',
        favouriteVesselName: '',
        favouriteVesselCanDoLogs: false,
        favouriteVesselIsCompany: false,
        favouriteVesselIsMine: false,
        signupPlatform: '',
        profilingInfo: {}
      },

      isSelected: false,
      findName: '',
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      selectedUsers: [],

    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    userData() {
      return this.$store.getters.getSubscriptionInfo;
    },
    filteredUsers() {
      let self = this;
      let tempArray = this.users;

      if (self.selectedState != 'all') {
        tempArray = tempArray.filter(user => {
          return user.userstate == self.selectedState 
        })
      }

      if (self.selectedIndustry != 'all') {
        tempArray = tempArray.filter(user => {
          return user.industry == self.selectedIndustry 
        })
      }

      if (self.selectedRole != 'all') {
        tempArray = tempArray.filter(user => {
          return user.userrole == self.selectedRole 
        })
      }

      if (self.selectedGroup != 'all') {
        tempArray = tempArray.filter(user => {
          return user.userGroup == self.selectedGroup 
        })
      }

      return tempArray;
    },

    filteredNames() {
      let filter = new RegExp(this.findName, 'i')
      return this.filteredUsers.filter(user => user.username.match(filter));
    },

    selectedVessels() {
      let self = this;
      let tempVessels = '';
      if (this.selectedUser.userid != '' && this.selectedUser.myVessels != undefined) {
        let tempVessels = self.selectedUser.myVessels.map(vessel => vessel.vesselName);
        tempVessels.join(', ');
      }
      return tempVessels;
    },
    isAdmin() {
      return this.$store.getters.getIsAdmin;
    },
    isSteve() {
      return this.firebaseid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2';
    }

  },

  mounted() {
    this.$store.dispatch('setUser');
    this.$store.dispatch('SETSUBSCRIPTIONINFO');
    firebase.auth().onAuthStateChanged( this.onUserLogin ); 
  },

  methods: {
    onUserLogin: function( user ) {
      if (user) {         
        this.firebaseemail = user.email;
        this.firebaseid = user.uid;
        this.firebasedisplayname = this.userData.username;
        this.loadUsers();
      }
    },

    loadUsers: function() {
      let self = this;
      db.collection('userdata').orderBy('signUpDate','desc').onSnapshot(function(querySnapshot) {
        self.users = [];
        querySnapshot.forEach(function(doc) {
          // self.users.push(doc.data())
          self.users.push({
            userid: doc.id,
            signUpDate: doc.data().signUpDate,
            userstate: doc.data().userstate,
            industry: doc.data().industry,
            userrole: doc.data().userrole,
            userGroup: doc.data().userGroup ? doc.data().userGroup : '',
            username: doc.data().username,
            useremail: doc.data().useremail,
            favouriteVesselID: doc.data().favouriteVesselID ? doc.data().favouriteVesselID : '',
            signupPlatform: doc.data().signupPlatform ? doc.data().signupPlatform : 'not set',
            favouriteFixed: doc.data().favouriteFixed ? doc.data().favouriteFixed : false,
            // profilingInfo: doc.data().profilingInfo ? doc.data().profilingInfo : '',
          })
        });
      })
    },

    cleanDate: function(date) {
      // console.log('Date: ',date);
      // return moment(date.toDate()).format('MMM Do, h:mm:ss a');
      return moment(date.toDate()).format('D MMM, h:mm a');
    },

    clearUser: function() {
      this.findName = '';
      this.isSelected = false;
    },

    addEntry(user) {
      let self = this;
      console.log(user);
      this.$router.push({ 
        name: 'TimelineEntry', 
        params: { 
          currentUser: user,
        } 
      });
    },

    async viewProfile(userid) {
      //console.log(userid);
      let self = this;
      NProgress.start();
      await db.collection('userdata').doc(userid).get().then(function(doc) {
        //console.log(doc.data());
        //console.log(doc.data().myVessels);
        self.selectedUser.userid = userid;
        self.selectedUser.username = doc.data().username;
        self.selectedUser.useremail = doc.data().useremail;
        self.selectedUser.userphone = doc.data().userphone;
        self.selectedUser.industry = doc.data().industry;
        self.selectedUser.userrole = doc.data().userrole;
        self.selectedUser.userstate = doc.data().userstate;
        self.selectedUser.signUpDate = self.cleanDate(doc.data().signUpDate);
        // self.selectedUser.cleanAnniversaryDate = self.cleanDate(doc.data().anniversaryDate);
        self.selectedUser.anniversaryDate = doc.data().anniversaryDate ? doc.data().anniversaryDate.toDate()  : '';
        self.selectedUser.maxVessels = doc.data().maxVessels;
        self.selectedUser.adminNotes = doc.data().adminNotes ? doc.data().adminNotes : '';
        self.selectedUser.doesDiving = doc.data().doesDiving ? doc.data().doesDiving : false;
        self.selectedUser.isTrainer = doc.data().isTrainer ? doc.data().isTrainer : false;
        self.selectedUser.lastCharge = doc.data().lastCharge;
        self.selectedUser.nextCharge = doc.data().nextCharge;
        self.selectedUser.lastPayment = doc.data().lastPayment;
        self.selectedUser.userGroup = doc.data().userGroup ? doc.data().userGroup : '';
        self.selectedUser.signupPlatform = doc.data().signupPlatform ? doc.data().signupPlatform : 'not set',
        //
        self.selectedUser.masteredVesselIDs = doc.data().vesselsThatIMaster ? doc.data().vesselsThatIMaster : [];
        self.selectedUser.crewedVesselIDs = doc.data().vesselsThatICrew ? doc.data().vesselsThatICrew : [];
        self.selectedUser.myVessels = doc.data().myVessels ? doc.data().myVessels : [];
        self.selectedUser.favouriteVesselID = doc.data().favouriteVesselID ? doc.data().favouriteVesselID : '';
        self.selectedUser.favouriteVesselName = doc.data().favouriteVesselName ? doc.data().favouriteVesselName : '';
        self.selectedUser.favouriteVesselCanDoLogs = doc.data().favouriteVesselCanDoLogs ? doc.data().favouriteVesselCanDoLogs : false;
        self.selectedUser.favouriteVesselIsCompany = doc.data().favouriteVesselIsCompany ? doc.data().favouriteVesselIsCompany : false;
        self.selectedUser.favouriteVesselIsMine = doc.data().favouriteVesselIsMine ? doc.data().favouriteVesselIsMine : false;
        self.selectedUser.favouriteFixed = doc.data().favouriteFixed ? doc.data().favouriteFixed : false;
        self.selectedUser.profilingInfo = doc.data().profilingInfo ? doc.data().profilingInfo : {};
        self.selectedUser.hasLoggedInOnApp = doc.data().hasLoggedInOnApp ? doc.data().hasLoggedInOnApp : false;

      }).then(async function() {
        await db.collection('userdata').doc(userid).collection('myCompanies').get().then((snap) => {
          self.selectedUser.myCompanies = [];
          snap.forEach((docRef) => {
            self.selectedUser.myCompanies.push(docRef.data().companyName);
          });
        })
      }).then(async function() {
        self.selectedUser.crewedVesselIDs.forEach(async (vesselID) => {
          await db.collection('vessels').doc(vesselID).get().then((docRef) => {
            self.selectedUser.crewedVessels.push(docRef.data().vesselName);
          });
        })
      }).then(async function() {
        self.selectedUser.masteredVesselIDs.forEach(async (vesselID) => {
          await db.collection('vessels').doc(vesselID).get().then((docRef) => {
            self.selectedUser.masteredVessels.push(docRef.data().vesselName);
          });
        })
      }).then(function() {
        NProgress.done();
        self.showProfile = true;
      }).catch(function(err) {
        NProgress.done();
        console.log('Error',err);
      });
    },

    close: function() {
      this.selectedUserID = '';
      this.selectedUser = {
        userid: '',
        username: '',
        useremail: '',
        userphone: '',
        industry: '',
        userrole: '',
        userstate: '',
        signUpDate: '',
        anniversaryDate: '',
        maxVessels: '',
        adminNotes: '',
        doesDiving: '',
        isTrainer: '',
        myVessels: [],
        lastCharge: '',
        nextCharge: '',
        lastPayment: '',
        masteredVesselIDs: [],
        crewedVesselIDs: [],
        masteredVessels: [],
        crewedVessels: [],
        favouriteVesselID: '',
        favouriteVesselName: '',
        favouriteVesselCanDoLogs: false,
        favouriteVesselIsCompany: false,
        favouriteVesselIsMine: false,
        signupPlatform: '',
        favouriteFixed: false,
        profilingInfo: {},
      };
      this.showProfile = false;

    },

    updateProfile(id) {
      console.log('Sending UserID:',id);
      this.showProfile = false;
      let self = this;
      console.log(self.selectedUser);
      this.$router.push({ name: 'UpdateProfile', params: { 
          sentUser: self.selectedUser
        }
      });
    },

    updateEmail(id) {
      // console.log('Sending UserID:',id);
      this.showProfile = false;
      let self = this;
      console.log(self.selectedUser);
      this.$router.push({ name: 'UpdateEmail', params: { 
          sentUser: self.selectedUser
        }
      });
    },

    sendAlert(userID, userName) {
      this.$router.push({ name: 'AddAlert', params: { 
          userID: userID,
          userName: userName
        }
      });
    },

    async fixFavourite(selectedUser) {
      console.log(selectedUser);
      // this.showProfile = false;
      // this.$router.push({ name: 'FixFavourites', params: { 
      //     sentUser: selectedUser
      //   }
      // });
      let self = this;
      let newFave = {
        favouriteVesselName: '',
        favouriteVesselID: '',
        favouriteVesselCanDoLogs: false,
        favouriteVesselIsCompany: false,
        favouriteVesselIsMine: false
      }
      let faveVesselName = '';
      
      if (selectedUser.myVessels.length > 0) {
        newFave = {
          favouriteVesselName: selectedUser.myVessels[0].vesselName,
          favouriteVesselID: selectedUser.myVessels[0].vesselID,
          favouriteVesselCanDoLogs: true,
          favouriteVesselIsCompany: false,
          favouriteVesselIsMine: true
        }
      } else {
        if (selectedUser.masteredVesselIDs.length > 0) {
          await db.collection('vessels').doc(selectedUser.masteredVesselIDs[0]).get().then((doc) => {
            faveVesselName = doc.data().vesselName ? doc.data().vesselName : 'Name not found';
            newFave = {
              favouriteVesselName: faveVesselName,
              favouriteVesselID: selectedUser.masteredVesselIDs[0],
              favouriteVesselCanDoLogs: true,
              favouriteVesselIsCompany: doc.data().isCompanyVessel ? doc.data().isCompanyVessel : false,
              favouriteVesselIsMine: false
            }
          });
        } else {
          newFave = {
            favouriteVesselName: 'No vessel set',
            favouriteVesselID: ' ',
            favouriteVesselCanDoLogs: false,
            favouriteVesselIsCompany: false,
            favouriteVesselIsMine: false
          }
        }
      }

      console.log(newFave);

      NProgress.start();
      var adminFixFavourite = firebase.functions().httpsCallable('adminFixFavourite');
      adminFixFavourite({
        sentUser: selectedUser,
        newFave: newFave
      })
      .then(function(result) {
        var sanitizedMessage = result.data.text;
        // console.log('Result',sanitizedMessage);
        console.log('Success Message: ',result.data.message);
        NProgress.done();
        self.close();
      })
      .catch(function(error) {
        // console.log('Error',error.text);
        console.log('Error Message: ',error.message);
        NProgress.done();
      }); 


    },

    

    simulateConnection() {

    },



  }


}
</script>



<style lang="scss" scoped>

  ol {
    li {
      margin-bottom: 10px;
      cursor: pointer;
      div {
        display: inline-block;
        // &:hover {
        //   color: #209cee;
        // }
        &.fixed {
          color: limegreen;
        }
        &.app {
          color: dodgerblue;
          // font-weight: bold;
        }
        &.web {
          color: green;
          // font-weight: bold;
        }
      }
    }
  }
  span {
    &.app {
      color: cadetblue;
      font-weight: bold;
    }
    &.web {
      color: green;
      font-weight: bold;
    }
  }

</style>