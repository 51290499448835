<template>
  
  <div>

    <div class="site-wrapper no-header">
      
      <div class="">

        

        <div class="map-wrapper">


          <h3 class="is-size-5">Active Vessels Map ({{markers.length}})</h3>

          <!-- {{markers}} -->

          <!-- <GmapMap
            :center="center"
            :zoom="10"
            style="width:100%;  height: 555px;">
            <gmap-marker
              :key="index"
              v-for="(gmp, index) in locations"
              :position="gmp"
              @click="center=gmp"
            ></gmap-marker>
          </GmapMap> -->

          <!-- <GmapAutocomplete
            @place_changed='setPlace()' 
            :options="{fields: ['geometry', 'formatted_address', 'address_components']}" 
          />
          <button @click='addMarker()'>Add</button> -->

          <br>

          <GmapMap
            :center='center'
            :zoom='4'
            style='width:100%;  height: 600px;'
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position" 
              :icon="m.position.isOverdue ? redIcon : greenIcon"
              @click="showDetails(m)"
            />
          </GmapMap>


          <hr>

          <div class="marker-list">
            <h3>Current Active Vessels</h3>
            <ol>
              <li v-for="marker in markers" :key="marker.position.id">{{marker.position.name}} - {{formatDate(marker.position.timestamp)}}</li>
            </ol>
          </div>

          <!-- <GmapMap
            :center='center'
            :zoom="0"
            map-type-id="terrain"
            style="width:100%;  height: 400px; "
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              @click="center=m.position"
            />
          </GmapMap> -->
          
        </div>

      </div>

    </div>


    <div class="modal modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': showInfoModal }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head is-info">
          <p class="modal-card-title">{{selectedMarker.name}}</p>
          <button class="delete" aria-label="close" @click.prevent="close"></button>
        </header>
        <section class="modal-card-body">
          <h4>Timestamp: {{formatDate(selectedMarker.timestamp)}}</h4>
          <p>LAT: {{selectedMarker.lat}}</p>
          <p>LONG: {{selectedMarker.lng}}</p>
          <p>What3Words: {{selectedMarker.words}}</p>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-info" @click.prevent="close">Done</button>
        </footer>
      </div>
    </div>



    
  </div>



</template>




<script>


import * as VueGoogleMaps from 'vue2-google-maps';
import firebase from 'firebase/compat/app';
import { db } from '../main.js';
import 'firebase/compat/auth'; 
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';  
import * as moment from 'moment';

export default {

  name: 'ActiveVesselsMap',

  components: {

  },

  mounted: function() {
    // this.geolocate();
    this.loadMarkers();
  },

  computed: {
    greenIcon() {
      return require('@/assets/img/map-marker-green.png')
    },
    redIcon() {
      return require('@/assets/img/map-marker-red.png')
    }
  },

  data: function() {
    return {
      center: { 
        lat: -26.7837304, 
        lng: 135.4458825 
      },
      locations: [],
      currentLocation: null,
      markers: [],
      places: [],
      icons: [
        '@/assets/img/map-marker-red.png','@/assets/img/map-marker-green.png'
      ],
      icon: './assets/img/map-marker-green.png',
      // labelSettings: {
      //   text: 'Xv',
      //   color: 'green'
      // }
      selectedMarker: '',
      showInfoModal: false,


    }
  },

  methods: {

    formatDate : function (sentDate) {
        let fixDate = (sentDate ? sentDate.toDate() : new Date());
        //console.log("Fix date:",fixDate);
        return moment(fixDate).format('D MMM YYYY (h:mm a)');
    },

    close() {
      this.showInfoModal = false;
    },

    setPlace(place) {
      console.log(place);
      this.currentLocation = place;
    },
    addMarker() {
      console.log('Marking...');
      if (this.currentLocation) {
        const marker = {
          lat: this.currentLocation.geometry.location.lat(),
          lng: this.currentLocation.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentLocation);
        this.center = marker;
        this.currentLocation = null;
      }
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },

    setLocationLatLng: function() {
      navigator.geolocation.getCurrentPosition(geolocation => {
        this.center = {
          lat: geolocation.coords.latitude,
          lng: geolocation.coords.longitude
        };
      });

      this.locations = [
        {
            lat: 39.7837304,
            lng: -100.4458825,
            label: 'United States'
        },
        {
            lat: 38.6529545,
            lng: -90.2411166,
            label: 'St. Louis'
        },
        {
            lat: 41.3828939,
            lng: 2.1774322,
            label: 'Barcelona'
        },
        {
            lat: -10.3333333,
            lng: -53.2,
            label: 'Brazil'
        }
      ];

    },


    
    loadMarkers() {
      console.log('loading markers');
      let self = this;
      db.collection('activeVesselPositions').orderBy('timestamp','desc').onSnapshot((querySnapshot) => {
        self.markers = [];
        querySnapshot.forEach((doc) => {
          // self.markers.push(doc.data());
          let marker = {
            id: doc.id,
            lat: doc.data().latitude,
            lng: doc.data().longitude,
            name: doc.data().vesselName,
            words: doc.data().what3words,
            timestamp: doc.data().timestamp,
            isOverdue: doc.data().isOverdue ? doc.data().isOverdue : false,
          };
          self.markers.push({ position: marker });
        });
      });
    },

    showDetails(m) {
      console.log(m.position.name);
      this.selectedMarker = m.position;
      this.showInfoModal = true;
      navigator.clipboard.writeText(m.position.words);
    },

    showInfoBox() {
      let self = this;
      this.showInfoModal = true;
    }







  }

}

</script>




<style lang="scss" scoped>


  .map-wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 2rem auto;
    min-height: 600px;
  }

  .marker-list {
    margin: 0 auto 2rem;
    width: 90%;
    h3 {
      margin: 0 0 1rem;
      font-weight: bold;
      text-transform: uppercase;
    }
    ol {
      li {
        margin-bottom: 5px;
      }
    }
  }




</style>










