<template>

    <div class="site-wrapper no-header">
    
        <div class="container">

            <h3 class="is-size-5">User Square Settings :{{currentUserData.squareCustomerID}}</h3><br>

            <!-- <div class="spacer s20"></div> -->
            
            <!-- {{currentUserData}} -->

            <hr>

            <div v-if="userLoaded">

                <div>
                    <h4 class="heading">User Info</h4>
                    <p>Name: {{currentUserData.username}}</p>
                    <p>Email: {{currentUserData.useremail}}</p>
                    <p>Phone: {{currentUserData.userphone}}</p>
                    <div class="spacer s20"></div>
                </div>

                <hr>
                

                <div>
                    <h4 class="heading">User Vessels:</h4>
                    <ul class="vessel-list" v-if="allVessels.length > 0">
                        <li v-for="vessel in allVessels" :key="vessel.id" @click="showVesselOptions(vessel)" :class="[vessel.show ? 'open' : '']">
                            <div v-if="!vessel.isExpired">{{vessel.vesselName}} {{vessel.isFreebie ? '(Freebie)' : ''}} [{{vessel.aaaPlans.join(', ')}}] (Exp: {{formatDate(vessel.nextChargeDate.toDate(),'DD MMM, YYYY')}})</div>
                            <div v-else class="red">{{vessel.vesselName}} - Expired permanently</div>
                            <div v-if="vessel.show && !vessel.isExpired">
                                <div class="spacer s10"></div>
                                <div v-if="allPlans.length > 0">
                                    <ul class="plans">
                                        <li v-for="plan in allPlans" :key="plan.id" @click="selectPlan(vessel,plan)">
                                            <!-- <div class="plan-item" :class="[plan.selected ? 'selected' : '',plan.id == vessel.currentSquarePlanID ? 'current' : '',plan.id == vesselOpenOrderID(vessel.id) ? 'ordered' : '']"> -->
                                            <div class="plan-item" :class="[vessel.selectedPlanID == plan.id ? 'selected' : '',plan.id == vessel.currentSquarePlanID ? 'current' : '']">
                                                <div class="plan-details">
                                                    <div class="plan-top-info">
                                                        <div class="plan-title">{{plan.title.replace('OffshoreSMS ','')}}:</div>
                                                        <div class="plan-price">${{plan.price}}</div>
                                                    </div>
                                                    <!-- <div class="plan-bottom-info">
                                                        <div class="plan-description">{{plan.description}}</div>
                                                    </div> -->
                                                    <div v-if="plan.isSandbox" class="red"><font-awesome-icon icon="exclamation-triangle" /> Sandbox Plan</div>
                                                </div>
                                                <div class="plan-select">
                                                    <span v-if="plan.selected" class="selected"><font-awesome-icon icon="check" /></span>
                                                    <span v-else><font-awesome-icon icon="minus" /></span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <p v-else><font-awesome-icon icon="sync-alt" class="fa-spin" />&nbsp; Loading plans..</p>


                                <div class="spacer s10"></div>
                                <div class="buttons">
                                    <button :disabled="vesselsInLocalOrder.includes(vessel.id) || vessel.selectedPlanID == ''" class="button is-small is-warning" @click="addToLocalCart(vessel)"><font-awesome-icon icon="cart-plus" />&nbsp; Add to Local Order</button>
                                    <button :disabled="newSquareOrderVesselIDs.includes(vessel.id) || vessel.selectedPlanID == ''" class="button is-small is-info" @click="addToSquareCart(vessel)"><font-awesome-icon icon="cart-plus" />&nbsp; Add to Square Order</button>
                                </div>

                            </div>
                        </li>
                    </ul>
                    <p v-else>No vessels found</p>
                </div>

                <hr>
                
                <div>
                    <h4 class="heading">Square Customer ID</h4>
                    <div class="buttons">
                        <span @click="checkIfCustomerExists(currentUserData)" v-if="!currentUserData.squareCustomerID || currentUserData.squareCustomerID == ''" class="button is-small is-warning square"><font-awesome-icon icon="exclamation-triangle" />&nbsp; Check Square Customer ID</span>
                        <span v-else @click="checkIfCustomerExists(currentUserData)" class="button is-small is-success square"><font-awesome-icon icon="check-circle" />&nbsp; {{currentUserData.squareCustomerID}}</span>
                    </div>
                    <div class="spacer s20"></div>
                </div>

                <hr>
                
                <div>
                    <h4 class="heading">Saved Credit Cards</h4>

                    <ul v-if="savedCards.length > 0" class="saved-cards">
                            <li v-for="(card, index) in savedCards" :key="card.docID" @click="selectCard(card)">
                                <div class="card-item" :class="[card.selected ? 'selected' : '', card.isExpired ? 'expired' : '']">
                                    <div class="card-icon">
                                        <img :src="getIcon(card.cardBrand)" alt="" />
                                    </div>
                                    <div class="card-details">
                                        <div class="card-holder">
                                            <div class="card-holder-label">Name:</div>
                                            <div class="card-holder-name">{{card.cardholderName}}</div>
                                        </div>
                                        <div class="card-expiry-info">
                                            <div class="card-digits" :class="{'expired': card.isExpired}">**** **** **** {{card.last4}}</div>
                                            <div class="card-expiry" :class="{'expired': card.isExpired}">(Exp {{parseInt(card.expMonth)}}/{{parseInt(card.expYear)}})</div>
                                            <div class="card-warning" v-if="card.isExpired">This card may be expired</div>
                                        </div>
                                    </div>
                                    <div class="card-select">
                                        <span v-if="card.selected" class="selected"><font-awesome-icon icon="check" /></span>
                                        <span v-else><font-awesome-icon icon="minus" /></span>
                                    </div>
                                </div>
                                <div class="delete" @click="deleteCard(card, index)"></div>
                            </li>
                    </ul>
                    <p v-else class="">No saved cards on file.</p>
    
                    <div class="spacer s20"></div>
                    <div v-if="showAddCardButton" class="buttons">
                        <button class="button is-danger" @click="showAddCard = true">Add a New Card</button>
                    </div>
                    
                    <div class="spacer s20"></div>


                </div>

                <hr>


                <div>
                    <h4 class="heading">Current Invoices in Square</h4>
                    {{customerInvoicesInSquare}}
                    <div class="spacer s20"></div>

                    <div class="button is-light" @click="listSquareInvoices()">Reload Invoices</div>

                </div>

                <hr>


                <div>
                    <h4 class="heading">Current User's Local Order</h4>
                    
                    <ul class="cart">
                        <li v-for="(item,ix) in currentLocalOrder" :key="`cart-${ix}`">
                            <div class="vessel">
                                <span class="bold"><font-awesome-icon icon="ship" /> &nbsp;{{item.vesselName}}</span>
                                <span class="delete" @click="deleteOrderItem(item,ix)"></span>
                            </div>
                            <div class="details">
                                <span class="plan">{{item.planName}}</span>
                                <span class="price">${{item.nextVesselCharge}}</span>
                            </div>
                            <!-- <span>Valid until {{renewalDate}}</span> -->
                            <span>Next renewal will be on {{calcRenewalDate(item)}}</span>
                        </li>
                    </ul>

                    <div class="table-wrapper">
                        <table class="totals">
                            <tr>
                                <td>GST:</td>
                                <td>${{totalGST}}</td>
                            </tr>
                            <tr class="total">
                                <td>TOTAL:</td>
                                <td>${{totalCart}}</td>
                            </tr>
                        </table>
                    </div>

                    <div v-if="currentLocalOrder.length > 0" class="buttons paynow">
                        <button class="button is-success" @click="createPayment()"><font-awesome-icon icon="cart-plus" /> &nbsp;Take User Payment</button>
                    </div>
                    
                    <div class="spacer s20"></div>
                </div>

                <hr>


                <div>
                    <h4 class="heading">Build a Square Order</h4>

                    <div>
                        <h3>Step 1 - Items added to a local order</h3>

                        <ol class="square-order">
                            <li v-for="(item, inx) in currentSquareOrder" :key="`inv-${inx}`">
                                {{item.vesselName}} ({{item.planName}}: ${{item.nextVesselCharge}})
                            </li>
                        </ol>

                        <div class="spacer s20"></div>
                    </div>

                    <div v-if="currentSquareOrder.length > 0">
                        <h3>Step 2 - Create the order then publish an invoice</h3>

                        <div class="buttons">
                            <span class="button is-warning is-medium" @click="createNewSquareOrderLocal()">Create new invoice in Square</span>
                        </div>

                        <div class="spacer s20"></div>
                    </div>


                    <div v-if="currentSquareOrder.length > 0 && invoiceCreated">
                        <h3>Step 3 - Display all orders for this customer</h3>

                        <div class="spacer s20"></div>
                    </div>


                    <hr>

                    {{newestSquareOrder}}

                    <div class="spacer s20"></div>
                </div>

                <hr>





            </div>

            <div class="spacer s40"></div>

                <div class="buttons">
                <span class="button is-info" @click="$router.back()">Go Back</span>
            </div>

            

        

        </div>


        <NewCardModal v-if="showAddCard && currentUserData.squareCustomerID && currentUserData.squareCustomerID != ''" 
            theme="is-primary" 
            title="Add a New Credit Card" 
            :isFleet="isFleet" 
            :squareCustomerID="currentUserData.squareCustomerID" 
            :userID="sentUserID" 
            :userName="currentUserData.username" 
            @returnResult="receiveCreditCardOnFile($event)">
        </NewCardModal>




    </div>



</template>


<script> 

import firebase from 'firebase/compat/app';
import { db } from '../main.js';
import 'firebase/compat/auth'; 
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';	
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
const NewCardModal = () => import('../reusables/NewCardModal.vue');

	export default {

        props: {
            sentUserID: String,
        },

        components: {
            NewCardModal
	    },
		mounted: function() {
            console.log('sentUserID',this.sentUserID);
			firebase.auth().onAuthStateChanged( this.onUserLogin );	

            let squareScript = document.createElement('script')
            squareScript.setAttribute('src', 'https://sandbox.web.squarecdn.com/v1/square.js')
            document.head.appendChild(squareScript)

            let self = this;
            setTimeout(() => {
                loadSavedCards();
                loadSquarePlans();
                // self.daysLeft = self.daysLeftInPeriod();
                // self.updateDaysLeftInPeriod();
            }, 1000);
  
            function loadSavedCards() {
                // const q = query(collection(getFirestore(), "vessels/" + self.currentVesselID + '/ccof'));
                // const cards = onSnapshot(q, (querySnapshot) => {
                //     self.savedCards = [];
                //     querySnapshot.forEach((doc) => {
                //         // console.log(doc.data());
                //         let data = doc.data();
                //         data.docID = doc.id;
                //         data.selected = false;
                        
                //         let d = new Date();
                //         let thisMonth = d.getMonth() + 1;
                //         let thisYear = d.getFullYear();
                //         let yearPast = parseInt(thisYear) > parseInt(data.expYear);
                //         let yearCurrent = parseInt(thisYear) == parseInt(data.expYear);
                //         let monthPast = parseInt(thisMonth) > parseInt(data.expMonth);
                //         data.isExpired = yearPast || (yearCurrent && monthPast);

                //         self.savedCards.push(data);
                //     });
                // });
                //
                // let self = this;
                db.collection('userdata').doc(self.sentUserID).collection('ccof').onSnapshot((querySnapshot) => {
                    self.savedCards = [];
                    querySnapshot.forEach((doc) => {
                        let data = doc.data();
                        data.docID = doc.id;
                        data.selected = self.currentUserData.savedUserCreditCardID && self.currentUserData.savedUserCreditCardID == doc.id;
                        
                        let d = new Date();
                        let thisMonth = d.getMonth() + 1;
                        let thisYear = d.getFullYear();
                        let yearPast = parseInt(thisYear) > parseInt(data.expYear);
                        let yearCurrent = parseInt(thisYear) == parseInt(data.expYear);
                        let monthPast = parseInt(thisMonth) > parseInt(data.expMonth);
                        data.isExpired = yearPast || (yearCurrent && monthPast);

                        self.savedCards.push(data);
                    });
                });
            }

            function loadSquarePlans() {
                // const q = query(collection(getFirestore(), "square/plans/subscriptions"), where("isSandbox","==",true), where("active","==",true));
                // const cards = onSnapshot(q, (querySnapshot) => {
                //     self.allPlans = [];
                //     querySnapshot.forEach((doc) => {
                //         let data = doc.data();
                //         // console.log(data);
                //         data.id = doc.id;
                //         data.selected = false;
                //         self.allPlans.push(data);
                //     });
                // });
                //
                // let self = this;
                db.collection('square/plans/subscriptions').where("isSandbox","==",false).where("active","==",true).onSnapshot((querySnapshot) => {
                    self.allPlans = [];
                    querySnapshot.forEach((doc) => {
                        let data = doc.data();
                        data.id = doc.id;
                        // data.selected = self.vesselData.currentSquarePlanID == doc.id ? true : false;
                        self.allPlans.push(data);
                    });
                });
            }      
            
            setTimeout(() => {
            // this.checkSquareCustomerStatus();
                this.listSquareInvoices();
            }, 1500);


		},

		data: function() {
			return {
                // reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
                allVessels: [],

                isFleet: false,
                allPlans: [],

                currentUserData: {},
                userLoaded: false,

                planSaved: false,
                planSelected: false,

                savedCards: [],
                showAddCard: false,
                showCardForm: false,
                showAddCardButton: true,
                started: false,
                results: {
                    one: [],
                    two: [],
                    three: [],
                    four: [],
                    five: [],
                },
                brands: {
                    visa: require('@/assets/img/visa.jpg'),
                    mc: require('@/assets/img/mc.jpg'),
                    other: require('@/assets/img/other-card.jpg'),
                },

                vesselsInSquareOrder: [],
                currentSquareOrder: [],
                // squareOrder: [],

                newestSquareOrder: {},
                customerInvoicesInSquare: [],

                invoiceCreated: false,


			}
		},

		computed: {
			user() {
                return this.$store.getters.getUser;
            },
            isSteve() {
                return this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false;
            },
            currentLocalOrder() {
                return this.currentUserData.currentLocalOrder ? this.currentUserData.currentLocalOrder : [];
            },
            totalCart() {
                return this.currentLocalOrder.reduce((acc, item) => acc + item.nextVesselCharge, 0).toFixed(2);
            },
            totalGST() {
                return (this.totalCart / 11).toFixed(2);
            },
            renewalDate() {
                return new Date();
            },
            selectedPlan() {
                return this.allPlans.filter((plan) => plan.selected).map((p) => p);
            },
            vesselsInLocalOrder() {
                return this.currentLocalOrder.map((o) => o.vesselID);
            },
            selectedCard() {
                return this.savedCards.filter((card) => card.selected).map((c) => c);
            },
            selectedCardID() {
                return this.selectedCard.length > 0 ? this.selectedCard[0].id : '';
            },
            newSquareOrderVesselIDs() {
                return this.currentSquareOrder.map((v) => v.vesselID);
            }
            

		},

        watch: {

        },

		methods: {

			onUserLogin: function( user ) {
                if (user) {      		
                    this.loadUserData();
                }
            },

            close() {
                this.showAddCard = false;
            },
  
            getIcon(brand) {
                // console.log('Brand',brand);
                return brand == 'VISA' ? this.brands.visa : brand == 'MASTERCARD' ? this.brands.mc : this.brands.other;
            },

            getNumberOfDays(start, end) {
                const date1 = new Date(start);
                // const date2 = new Date(end);
                const date2 = new Date();

                // One day in milliseconds
                const oneDay = 1000 * 60 * 60 * 24;

                // Calculating the time difference between two dates
                const diffInTime = date2.getTime() - date1.getTime();

                // Calculating the no. of days between two dates
                const diffInDays = Math.round(diffInTime / oneDay) * -1;

                return diffInDays;
            },

            loadUserData() {
                let self = this;
                NProgress.start();
                db.collection('userdata').doc(this.sentUserID).onSnapshot((doc) => {
                    // console.log(doc.data());
                    self.currentUserData = doc.data();
                    self.userLoaded = true;
                });
                setTimeout(() => {
                    self.loadVesselData();
                    NProgress.done();
                }, 1500);
            },  

            loadVesselData() {
                let self = this;
                if (this.currentUserData.myVessels && this.currentUserData.myVessels.length > 0) {
                    self.currentUserData.myVessels.forEach((vessel) => {
                        db.collection('vessels').doc(vessel.vesselID).get().then((doc) => {
                            let data = doc.data();
                            data.id = doc.id;
                            data.show = false;
                            data.selectedPlanID = '';
                            data.selectedPlan = {};
                            // data.currentSquarePlanID = '';
                            self.allVessels.push(data);
                        });
                    });
                }
            },


            formatDate : function (sentDate,format) {
                return moment(sentDate).format(format);
            },

            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },


            // vesselOpenOrderID(vesselID) {
            //     let matchingOrders = this.currentLocalOrder.filter((m) => m.vesselID == vesselID).map((n) => n.selectedPlanID);
            //     return matchingOrders.length > 0 ? matchingOrders[0] : '';
            // },
            // vesselhasOpenOrder(vesselID) {
            //     return this.currentLocalOrder.filter((m) => m.vesselID == vesselID).map((n) => n).length > 0;
            // },

            // a card is selected from the list of available cards
            selectCard(card) {
                let self = this;
                NProgress.start();
                if (!card.isExpired) {
                    this.savedCards.forEach((c) => {
                        c.selected = false;
                    });
                    card.selected = true;
                    db.collection('userdata').doc(self.sentUserID).update({
                        // savedCompanyCreditCardDocID: card.docID
                        savedUserCreditCardID: card.docID
                    });
                }
                setTimeout(() => {
                    NProgress.done();
                }, 1000);
            },

            deleteCard(card, index) {
                console.log(card);
                let self = this;
                let message;
                message = {
                    title: 'Really remove this card?',
                    body: ''
                };
                let options = {
                    okText: 'Yes, Remove',
                    backdropClose: true,
                    cancelText: 'Cancel',
                };
                this.$dialog
                    .confirm(message, options)
                    .then(function() {
                        NProgress.start();
                        db.collection('userdata').doc(self.sentUserID).collection('ccof').doc(card.docID).delete();
                        setTimeout(() => {
                            NProgress.done();
                        }, 1500);
                    })
                .catch(function() {
                    NProgress.done();
                });
            },

            selectPlan(vessel,plan) {
                // console.log(plan.id);
                // this.allPlans.forEach((p) => {
                //     p.selected = false;
                // });
                // plan.selected = true;
                vessel.selectedPlanID = plan.id;
                vessel.selectedPlan = plan;
                // this.planSaved = true;
                // this.planSelected = true;
                // if (!this.currentVessel.currentSquarePlanID || this.currentVessel.currentSquarePlanID != plan.id) this.updateVesselData({currentSquarePlanID: plan.id});
            },

            receiveCreditCardOnFile(result) {
                let self = this;
                if (!result.success) {
                    console.log('card not returned.');
                    self.close();
                    // self.results.two.push('Adding card failed.');
                    // self.started = false;
                } else {
                    console.log('Card on file added successfully with ID: ' + result.newCard.card.id);
                    self.close();
                    // console.log(result);
                    // self.results.two.push('Card on file added successfully with ID: ' + result.newCard.card.id);
                    // self.started = false;
                }

            },

            addToLocalCart(vessel) {
                let self = this;
                let message;
                message = {
                    title: 'Add to user\'s order?',
                    body: 'This is immediate and will update the user\'s cart'
                };
                let options = {
                    okText: 'Yes, Add',
                    backdropClose: true,
                    cancelText: 'Cancel',
                };
                this.$dialog
                .confirm(message, options)
                .then(function() {
                    NProgress.start();
                    let lineItem = {
                        vesselName: vessel.vesselName,
                        vesselID: vessel.id,
                        autoRenew: vessel.autoRenew ? vessel.autoRenew : true,
                        nextChargeDate: vessel.nextChargeDate,
                        nextVesselCharge: vessel.selectedPlan.price,
                        planName: vessel.selectedPlan.title,
                        aaaPlans: vessel.selectedPlan.aaaPlans,
                        selectedPlanID: vessel.selectedPlan.id,
            
                    }
                    console.log(lineItem);
                    db.collection('userdata').doc(self.sentUserID).update({
                        currentLocalOrder: firebase.firestore.FieldValue.arrayUnion(lineItem),
                    }); 
                    setTimeout(() => {
                        NProgress.done();
                    }, 1500);
                })
                .catch(function() {
                    NProgress.done();
                });

            },

            addToSquareCart(vessel) {
                let self = this;
                console.log(vessel);
                if (!this.newSquareOrderVesselIDs.includes(vessel.id)) {
                    let lineItem = {
                        vesselName: vessel.vesselName,
                        vesselID: vessel.id,
                        autoRenew: vessel.autoRenew ? vessel.autoRenew : true,
                        nextChargeDate: vessel.nextChargeDate,
                        nextVesselCharge: vessel.selectedPlan.price,
                        planName: vessel.selectedPlan.title,
                        aaaPlans: vessel.selectedPlan.aaaPlans,
                        selectedPlanID: vessel.selectedPlan.id,
            
                    }
                    self.currentSquareOrder.push(lineItem);
                }
            },

            calcRenewalDate(item) {
                let today = new Date().getTime();
                let nextChargeDate = item.nextChargeDate.toDate().getTime();
                let isPast = today > nextChargeDate;
                
                let newAnniversary = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
                let newAnniversaryTime = newAnniversary.getTime();
                let currentAnniversary = new Date(new Date(item.nextChargeDate.toDate()).setFullYear(new Date(item.nextChargeDate.toDate()).getFullYear() + 1));
                
                // let returnDate = isPast ? newAnniversary : nextChargeDate < newAnniversaryTime ? currentAnniversary : newAnniversary;
                let returnDate = isPast ? newAnniversary : currentAnniversary;

                return item.isFreebie ? newAnniversary.toDateString() : returnDate.toDateString();
                
                
            },

            deleteOrderItem(item,index) {               

                let self = this;
                let message;
                message = {
                    title: 'Really remove this item?',
                    body: ''
                };
                let options = {
                    okText: 'Yes, Remove',
                    backdropClose: true,
                    cancelText: 'Cancel',
                };
                this.$dialog
                .confirm(message, options)
                .then(function() {
                    NProgress.start();
                    let updatedCurrentOrder = self.currentLocalOrder;
                    updatedCurrentOrder.splice(index, 1);
                    // let userID = self.userData.userid;
                    // self.$store.dispatch('UPDATEUSERCART',{userID,updatedCurrentOrder});
                    db.collection('userdata').doc(self.sentUserID).update({
                        currentLocalOrder: updatedCurrentOrder
                    });
                    setTimeout(() => {
                        NProgress.done();
                    }, 1500);
                })
                .catch(function() {
                    NProgress.done();
                });
                
            },

            showVesselOptions(vessel) {
                let shown = vessel.show;
                this.allVessels.forEach((v) => v.show = false);
                // vessel.show = !shown;
                vessel.show = true;
                // console.log(vessel.vesselName);
            },



            // start new square functions ========================================================

            checkIfCustomerExists(user) {
                console.log(user);
                if (!user.userid || user.userid == '') return;
                
                let self = this;
                NProgress.start();

                if (user.squareCustomerID && user.squareCustomerID != '') {
                    this.searchCustomersByCustomerID(user);
                } else {
                    this.searchCustomersByReferenceID(user);
                }
            
            },

            searchCustomersByCustomerID(user) {
                let self = this;
                // console.log(user);
                console.log('This user has a Square Customer ID on file. Retrieving from Square...');
                
                var retrieveCustomerObject = firebase.functions().httpsCallable('retrieveCustomerObject');
                retrieveCustomerObject({
                    customerID: user.squareCustomerID,
                })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    // console.log('Result',sanitizedMessage);
                    // console.log('Success?',result.data.success);
                    if (result.data.success) {
                        if (sanitizedMessage.customer) {
                            console.log('Customer located in Square with customer ID: ' + sanitizedMessage.customer.id);
                            console.log('Returned referenceId from Square:',sanitizedMessage.customer.referenceId);
                            // NB if the returned square customer ID does not match the saved customer ID then throw alert!
                            // if (user.squareCustomerID != sanitizedMessage.customer.id) {
                            if (user.userid != sanitizedMessage.customer.referenceId) {
                                console.log('User ID mismatched with Square referenceId - fixing in referenceId in Square');
                                self.fixSquareCustomerMismatch(user);
                            } else if (user.squareCustomerID != sanitizedMessage.customer.id) {
                                console.log('squareCustomerID mismatched with Square customerId - fixing in firestore userdata');
                                self.updateUserData(user.userid,{
                                    squareCustomerID: sanitizedMessage.customer.id
                                });
                                NProgress.done();
                            } else {
                                console.log('Everything OK - customer userid matches up with referenceId in Square');
                                NProgress.done();
                            }
                        
                        } else {
                            console.log('Search found no existing customer object in Square');
                            console.log('Running function: createNewSquareCustomer');
                            // self.createNewSquareCustomer(user); // add new customer if not found
                            self.searchCustomersByReferenceID(user);
                        }
                    } else {
                        // console.log('Error locating customer in Square');
                        // window.alert('The function searchCustomersByCustomerID returned an error');
                        // NProgress.done();
                        console.log('The function searchCustomersByCustomerID returned an error');
                        // if (sanitizedMessage.errors && sanitizedMessage.errors[0].code == 'NOT_FOUND') self.createNewSquareCustomer(user);
                        self.searchCustomersByReferenceID(user);
                    }
                })
                .catch(function(result) {
                    console.log('Cloud function error: retrieveCustomerObject',result.data.text);
                    window.alert('The cloud function retrieveCustomerObject returned an error');
                    NProgress.done();
                });
            
            },

            fixSquareCustomerMismatch(user) {
                console.log('Function Z in flow diagram - fixing the mismatch');
                this.updateCustomerByCustomerID(user);
            },

            updateCustomerByCustomerID(user) {
                let self = this;
                NProgress.start();
                // console.log(user);
                console.log('Updating customer in Square with current user ID as referenceId...');
                
                var updateCustomerObject = firebase.functions().httpsCallable('updateCustomerObject');
                updateCustomerObject({
                    customerID: user.squareCustomerID,
                    updateFields: {
                        referenceId: user.userid
                    }
                })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    // console.log('Result',sanitizedMessage);
                    // console.log('Success?',result.data.success);
                    if (result.data.success) {
                        if (sanitizedMessage.customer) {
                            console.log('Customer updated in Square with correct user id');
                            NProgress.done();                  
                        } else {
                            console.log('Customer update failed.');
                            NProgress.done();    
                        }
                    } else {
                        console.log('The function updateCustomerByCustomerID returned an error');
                    }
                })
                .catch(function(error) {
                    console.log('Cloud function error: updateCustomerObject',error);
                    NProgress.done();      
                });
                
            },

            searchCustomersByReferenceID(user) {
                let self = this;
                NProgress.start();
                console.log('This user has no squareCustomerID on file - checking Square for a customer referenceId matching userID: ',user.userid);
                
                var searchCustomerObject = firebase.functions().httpsCallable('searchCustomerObject');
                searchCustomerObject({
                    referenceID: user.userid,
                })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    if (result.data.success) {
                        if (sanitizedMessage.customers && sanitizedMessage.customers.length > 0) {
                            console.log('Customer objects located in Square: ' + sanitizedMessage.customers.length);
                            console.log('Updating this user\'s squareCustomerID with the returned customerId found in Square for this user',sanitizedMessage.customers[0].id);
                            self.updateUserData(user.userid,{
                                squareCustomerID: sanitizedMessage.customers[0].id
                            });
                            NProgress.done();
                        } else {
                            console.log('Search found no existing customer object in Square for this userid');
                            console.log('Running function: createNewSquareCustomer');
                            self.createNewSquareCustomer(user); // add new customer if not found
                        }
                    } else {
                        console.log('Search found no existing customer object in Square for this userid');
                        console.log('Running function: createNewSquareCustomer');
                        if (sanitizedMessage.errors) self.createNewSquareCustomer(user); 
                        NProgress.done();
                    }
                })
                .catch(function(result) {
                    console.log('Cloud function error: searchCustomerObject',result.data.text);
                    window.alert('The cloud function searchCustomerObject returned an error');
                    NProgress.done();
                });

            },

            createNewSquareCustomer(user) {
                console.log('Creating new user with referenceID',user.userid);
                NProgress.start();
                let self = this;
                let customerData = {
                    givenName: user.username, 
                    emailAddress: user.useremail != '' ? user.useremail : '', //
                    phoneNumber: user.userphone != '' ? user.userphone : '', //
                    referenceId: user.userid, //
                    note: user.username + ' (userid: ' + user.userid + ')' //
                }

                var createNewCustomerObject = firebase.functions().httpsCallable('createNewCustomerObject');
                createNewCustomerObject({
                    customerData: customerData,
                })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    console.log('Result',sanitizedMessage);
                    console.log('Success?',result.data.success);
                    if (result.data.success) {
                        // customer created, save id and object to user data in fb
                        console.log('User successfully created as a customer in Square with customer ID: ' + sanitizedMessage.customer.id);
                        self.updateUserData(user.userid, {
                            squareCustomerID: sanitizedMessage.customer.id
                        });
                        NProgress.done();  
                    } else {
                        console.log('Error running function createNewSquareCustomer');
                        NProgress.done();
                    }
                })
                .catch(function(result) {
                    console.log('Error with cloud function: createNewCustomerObject');
                    console.log('result',result);
                    NProgress.done();
                });

            },
                    
            async updateUserData(userID, updateData) {
                // console.log(userID, updateData);
                db.collection('userdata').doc(userID).update(updateData);
            },

            
            createNewSquareOrderLocal() {
                NProgress.start();
                let self = this;
                let randomID = uuidv4();
                let lineItems = [];
                this.currentSquareOrder.forEach((item) => {
                    lineItems.push({
                        uid: item.vesselID,
                        name: item.vesselName + ': ' + item.planName,
                        quantity: '1',
                        metadata: {
                            'vesselID': item.vesselID
                        },
                        basePriceMoney: {
                            amount: (parseInt(item.nextVesselCharge) * 100),
                            currency: 'AUD'
                        }
                    });
                });
                
                // console.log(lineItems);

                var createNewSquareOrder = firebase.functions().httpsCallable('createNewSquareOrder');
                createNewSquareOrder({
                    customerID: self.currentUserData.squareCustomerID,
                    lineItems: lineItems,
                    ticketName: self.currentUserData.username,
                    idempotencyKey: randomID,
                    // locationId: '1GP4THBJTTGA2' // SANDBOX
                    locationId: '40ZFX2VT9XGE3' // PRODUCTION
                })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    console.log(sanitizedMessage);
                    if (result.data.success) {
                        if (sanitizedMessage.order && sanitizedMessage.order.id) {
                            console.log('New order created in Square: ' + sanitizedMessage.order.id);
                            // create a new invoice based on this order:
                            self.newestSquareOrder = sanitizedMessage.order;
                            self.createSquareInvoiceFromOrder();
                            NProgress.done();
                        } else {
                            console.log('Something didn\'t work');
                            NProgress.done();
                        }
                    } else {
                        console.log('Function returned an error when trying to create a new order');
                        NProgress.done();
                    }
                })
                .catch(function(result) {
                    console.log('Cloud function error: createNewSquareOrder',result);
                    window.alert('The cloud function createNewSquareOrder returned an error');
                    NProgress.done();
                });


            },


            createSquareInvoiceFromOrder() {

                if (Object.keys(this.newestSquareOrder).length) {
                   console.log('create new invoice');

                    // invoicesApi.createInvoice

                    // if invoice id returned: invoicesApi.publishInvoice

                    // if invoice published successfully: self.listSquareInvoices()




                }

            },

            listSquareInvoices() {
                NProgress.start();
                let self = this;
                let sqID = self.currentUserData.squareCustomerID ? self.currentUserData.squareCustomerID : '';
                let payload = {
                    // locationIds: ['1GP4THBJTTGA2'], // SANDBOX
                    customerIds: [sqID], // SANDBOX
                    locationIds: ['40ZFX2VT9XGE3'], // PRODUCTION
                    // customerIds: ['P3KJPJFH50SQKDP6BFDCTQNANW'] // PRODUCTION
                };
                console.log(payload);
                var loadSquareCustomerInvoices = firebase.functions().httpsCallable('loadSquareCustomerInvoices');
                loadSquareCustomerInvoices(payload)
                .then(function(result) {
                    // console.log('Result',result.data.result);
                    var sanitizedMessage = JSON.parse(result.data.text);
                    console.log(sanitizedMessage);
                    if (result.data.success) {
                        if (sanitizedMessage.invoices) {
                            console.log('Number of Square invoices found: ' + sanitizedMessage.invoices.length);
                            self.customerInvoicesInSquare = sanitizedMessage.invoices;
                            NProgress.done();
                        } else {
                            console.log('Something didn\'t work');
                            NProgress.done();
                        }
                    } else {
                        console.log('Function returned an error when trying to create a new order');
                        NProgress.done();
                    }
                })
                .catch(function(result) {
                    console.log('Cloud function error: loadSquareCustomerInvoices',result.data.text);
                    NProgress.done();
                });
            },


            // end new square functions ========================================================










            getStartDate(date) {
                var mm = date.getMonth() + 1; // getMonth() is zero-based
                var dd = date.getDate();

                return [date.getFullYear(),
                    (mm>9 ? '' : '0') + mm,
                    (dd>9 ? '' : '0') + dd
                    ].join('-');

            },




        

        }

	}



</script> 




<style lang="scss" scoped>
	
  .site-wrapper .container {
    max-width: 1050px;
  }
	.button {
		margin-top: 0;
    &.is-skip {
      background-color: #ff7878;
      color: #fff;
      border-color: #fff;
    }
	}
  input[type="text"] {
    padding: 2rem 1rem;
  }
  .form-group {
    label {
      top: auto;
      bottom: -25px;
    }
  }
	
  
  .modal {
    .modal-card {
      .modal-card-head {
        z-index: 999 !important;
      }
      .modal-card-foot {
        z-index: 999 !important;
      }
    }
  }
  table {
    td {
      &.is-updated {
        color: green;
        font-weight: bold;
      }
    }
  }
  .bold {
    font-weight: bold;
  }
  
  
  .vessel-name {
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      color: steelblue;
    }
  }
  .is-company {
    font-weight: bold;
    color: green;
  }
  .not-company {
    color: orange;
  }
  .red {
    color: red;
  }
  .buttons {
    margin-bottom: 0;
    &.paynow {
        justify-content: end;
        margin: 1rem 0 3rem;
      }
  }

.button {
  &.square {
    // margin: 0 10px;
    // display: inline-block;
  }
}

.vessel-list {
    &>li {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 12px;
        display: block;
        margin-bottom: 8px;
        cursor: pointer;
        background: #f4f4f4;
        transition: 0.3s;
        &:hover {
            background: #fff;
        }
        &.open {
            background: #fff;
        }
    }
}


.plans {
      li {
        display: inline-block;
        margin-right: 10px;
          .plan-item {
              border-radius: 6px;
              border: 1px solid #e4e4e4;
              padding: 7px;
              margin-bottom: 1.2rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              transition: 0.3s;
              max-width: 480px;
            //   &:hover {
            //       background: #effeef;
            //       border-color: #a3ecc3;
            //   }
              &.selected {
                  background: #effeef;
                  border-color: #a3ecc3;
              }
              &.ordered {
                  background: #fff6e6;
                  border-color: #ffecc8;
              }
              .plan-details {
                  display: flex;
                  flex-direction: column;
                //   width: calc(100% - 100px);
                  .plan-top-info {
                      display: flex;
  
                      .plan-title {
                          text-transform: uppercase;
                          margin-right: 6px;
                          font-size: 14px;
                      }
                      .plan-price {
                          font-weight: bold;
                          text-transform: uppercase;
                          font-size: 14px;
                      }
                  }
                  .plan-bottom-info {
                      display: flex;
                      align-items: center;
                      .description {
                          font-weight: bold;
                          font-size: 1.05rem;
                          letter-spacing: 1px;
                          margin-right: 10px;
                          color: mediumseagreen;
                      }
                  }
              }
              .plan-select {
                  width: 50px;
                  text-align: center;
                  span {
                      border-radius: 50%;
                      background: #e8e8e8;
                      width: 30px;
                      height: 30px;
                      padding: 10px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: #999;
                      margin: 0 auto;
                      &.selected {
                          background: mediumseagreen;
                          color: #fff;
                      }
                  }
              }
          }
      }
  }

.cart {
    // margin: 2rem 0;
    li {
        border-bottom: 1px dashed #e1e1e1;
        padding-bottom: 1.5rem;
        margin-bottom: 2rem;
        // &:last-of-type {
        //     border: none;
        // }
        .vessel {
            display: flex;
            justify-content: space-between;
            .delete {
                display: none;
            }
        }
        .details {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
            .plan {
                color: mediumseagreen;
                font-weight: bold;
                margin-bottom: 8px;
            }
            .price {
                font-size: 1.2rem;
                color: mediumseagreen;
                font-weight: bold;
                letter-spacing: 1px;
            }
        }
        &:hover {
            .vessel {
                .delete {
                    display: block;
                }
            }
        }
    }
}


.saved-cards {
    li {
        display: flex;
        .card-item {
            border-radius: 6px;
            border: 1px solid #e4e4e4;
            padding: 12px;
            margin-bottom: 1.2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            transition: 0.3s;
            max-width: 550px;
            &:hover {
                background: #f0f7fa;
                border-color: lightblue;
            }
            &.selected {
                background: #f0f7fa;
                border-color: lightblue;
            }
            &.expired {
                border-color: red;
            }
            .card-icon {
                width: 70px;
                padding-right: 12px;
                img {
                    width: 55px;
                }
            }
            .card-details {
                display: flex;
                flex-direction: column;
                width: calc(100% - 100px);
                .card-holder {
                    display: flex;

                    .card-holder-label {
                        text-transform: uppercase;
                        margin-right: 6px;
                    }
                    .card-holder-name {
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }
                .card-expiry-info {
                    display: flex;
                    align-items: center;
                    .card-digits {
                        font-weight: bold;
                        font-size: 1.05rem;
                        letter-spacing: 1px;
                        margin-right: 10px;
                        color: mediumseagreen;
                        &.expired {
                            color: red;
                        }
                    }
                    .card-expiry {
                        font-size: 1rem;
                        color: mediumseagreen;
                        &.expired {
                            color: red;
                        }
                    }
                    .card-warning {

                    }
                }
            }
            .card-select {
                width: 60px;
                text-align: center;
                span {
                    border-radius: 50%;
                    background: #e8e8e8;
                    width: 30px;
                    height: 30px;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #999;
                    margin: 0 auto;
                    &.selected {
                        background: dodgerblue;
                        color: #fff;
                    }
                }
            }
        }
        .delete {
            margin-left: 10px;
            margin-top: 5px;
            display: none;
            cursor: pointer;
        }
        &:hover {
            .delete {
                display: block;
            }
        }
    }
}

.square-order {
    li {
        border-bottom: 1px dashed #ddd;
        padding-bottom: 10px;
        margin-bottom: 10px;
        &:last-of-type {
            border: none;
        }
    }
}

h4.heading {
    margin-bottom: 10px;
    color: indianred;
    font-weight: bold;
}

.table-wrapper {
    display: flex;
    justify-content: end;
    table {
        &.totals {
            min-width: 200px;
            tr {
                td {
                    text-align: right;
                    padding-bottom: 7px;
                }
                &.total {
                    font-size: 1.5rem;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
            }
        }
    }
}
  

</style> 





