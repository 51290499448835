import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,

    routes: [
        { path: '*', redirect: '/' },
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/about',
            name: 'about',
            component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
        },
        {
            path: '/sign-in',
            name: 'sign-in',
            component: () => import(/* webpackChunkName: "home" */ './components/SignIn.vue')
        },
        {
            path: '/conversations',
            name: 'SmsConversations',
            component: () => import(/* webpackChunkName: "home" */ './components/SmsConversations.vue')
        },

        {
            path: '/users',
            name: 'users',
            component: () => import(/* webpackChunkName: "home" */ './components/Users.vue')
        },

        {
            path: '/all-users',
            name: 'AllUsers',
            component: () => import(/* webpackChunkName: "home" */ './components/AllUsers.vue')
        },

        {
            path: '/find-user',
            name: 'FindUser',
            component: () => import('./components/FindUser'),
            meta: { requiresAuth: true }
        },

        {
            path: '/selected-users',
            name: 'SelectUsers',
            component: () => import(/* webpackChunkName: "home" */ './components/SelectUsers.vue')
        },

        {
            path: '/activity',
            name: 'activity',
            component: () => import(/* webpackChunkName: "home" */ './components/Activity.vue')
        },

        {
            path: '/action-tags',
            name: 'ActionTags',
            component: () => import(/* webpackChunkName: "home" */ './components/ActionTags.vue')
        },

        {
            path: '/404',
            name: '404',
            component: () => import('./components/Error404')
        },

        {
            path: '/admin-audits',
            name: 'AdminAudits',
            component: () => import('./components/AdminAudits'),
            meta: { requiresAuth: true }
        },

        {
            path: '/dev-timeline',
            name: 'DevTimeline',
            component: () => import('./components/DevTimeline'),
            meta: { requiresAuth: true }
        },

        {
            path: '/update-profile',
            name: 'UpdateProfile',
            component: () => import('./components/UpdateProfile'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/business-data',
            name: 'BusinessData',
            component: () => import('./components/BusinessData'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/update-email',
            name: 'UpdateEmail',
            component: () => import('./components/UpdateEmail'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/update-phone',
            name: 'UpdatePhone',
            component: () => import('./components/UpdatePhone'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/update-user-phone',
            name: 'UpdateUserPhone',
            component: () => import('./components/UpdateUserPhone'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/create-invoice',
            name: 'CreateInvoice',
            component: () => import('./components/CreateInvoice'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/create-vessel-invoice',
            name: 'CreateVesselInvoice',
            component: () => import('./components/CreateVesselInvoice'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/print-vessel-invoice',
            name: 'PrintVesselInvoice',
            component: () => import('./components/PrintVesselInvoice'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/fix-favourites',
            name: 'FixFavourites',
            component: () => import('./components/FixFavourites'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/audit-vessel',
            name: 'AuditVessel',
            component: () => import('./components/AuditVessel'),
            meta: { requiresAuth: true }
        },

        {
            path: '/admin-payments',
            name: 'AdminPayments',
            component: () => import('./components/AdminPayments'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/active-trips',
            name: 'ActiveTrips',
            component: () => import('./components/ActiveTrips'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/active-vessels-map',
            name: 'ActiveVesselsMap',
            component: () => import('./components/ActiveVesselsMap'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/update-vessel',
            name: 'UpdateVessel',
            component: () => import('./components/UpdateVessel'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/anything-goes',
            name: 'AnythingGoes',
            component: () => import('./components/AnythingGoes'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/user-timelines',
            name: 'UserTimelines',
            component: () => import('./components/UserTimelines'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/crm-timelines',
            name: 'CrmTimelines',
            component: () => import('./components/CrmTimelines'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/crm-follow-ups',
            name: 'CrmFollowUps',
            component: () => import('./components/CrmFollowUps'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/crm-entry',
            name: 'CrmEntry',
            component: () => import('./components/CrmEntry'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/all-crm-timelines',
            name: 'AllCrmTimelines',
            component: () => import('./components/AllCrmTimelines'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/all-timelines',
            name: 'AllTimelines',
            component: () => import('./components/AllTimelines'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/timeline-entry',
            name: 'TimelineEntry',
            component: () => import('./components/TimelineEntry'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/admin-create-user',
            name: 'AdminCreateUser',
            component: () => import('./components/AdminCreateUser'),
            meta: { requiresAuth: true }
        },

        {
            path: '/custom-exports-inductions',
            name: 'CustomExportsInductions',
            component: () => import('./components/custom/CustomExportsInductions'),
            meta: { requiresAuth: true }
        },
        {
            path: '/admin-create-fleet',
            name: 'AdminCreateFleet',
            component: () => import('./components/AdminCreateFleet'),
            meta: { requiresAuth: true }
        },

        {
            path: '/rename-fleet',
            name: 'RenameFleet',
            component: () => import('./components/RenameFleet'),
            meta: { requiresAuth: true }
        },

        {
            path: '/delete-fleet',
            name: 'DeleteFleet',
            component: () => import('./components/DeleteFleet'),
            meta: { requiresAuth: true }
        },

        {
            path: '/admin-add-vessel',
            name: 'AdminAddVessel',
            component: () => import('./components/AdminAddVessel'),
            meta: { requiresAuth: true }
        },

        {
            path: '/admin-add-existing-vessel',
            name: 'AdminAddExistingVessel',
            component: () => import('./components/AdminAddExistingVessel'),
            meta: { requiresAuth: true }
        },

        {
            path: '/admin-add-fleet-vessel',
            name: 'AdminAddFleetVessel',
            component: () => import('./components/AdminAddFleetVessel'),
            meta: { requiresAuth: true }
        },

        {
            path: '/admin-add-fleet-user',
            name: 'AdminAddFleetUser',
            component: () => import('./components/AdminAddFleetUser'),
            meta: { requiresAuth: true }
        },

        {
            path: '/admin-create-company',
            name: 'AdminCreateCompany',
            component: () => import('./components/AdminCreateCompany'),
            meta: { requiresAuth: true }
        },

        {
            path: '/write-document',
            name: 'WriteDocument',
            component: () => import('./components/WriteDocument'),
            meta: { requiresAuth: true }
        },

        {
            path: '/write-multiple-docs',
            name: 'WriteMultipleDocs',
            component: () => import('./components/WriteMultipleDocs'),
            meta: { requiresAuth: true }
        },

        {
            path: '/write-custom-document',
            name: 'WriteCustomDocument',
            component: () => import('./components/WriteCustomDocument'),
            meta: { requiresAuth: true }
        },

        {
            path: '/admin-sms',
            name: 'AdminSms',
            component: () => import(/* webpackChunkName: "group-welcome" */ './components/AdminSms')
        },

        {
            path: '/admin-vessels',
            name: 'admin-vessels',
            component: () => import('./components/AdminVessels'),
            meta: { requiresAuth: true }
        },

        {
            path: '/admin-vessel-status',
            name: 'AdminVesselStatus',
            component: () => import('./components/AdminVesselStatus'),
            meta: { requiresAuth: true }
        },

        {
            path: '/fleet-vessels',
            name: 'fleet-vessels',
            component: () => import('./components/FleetVessels'),
            meta: { requiresAuth: true }
        },

        {
            path: '/all-companies',
            name: 'AllCompanies',
            component: () => import('./components/AllCompanies'),
            meta: { requiresAuth: true }
        },

        {
            path: '/all-fleets',
            name: 'AllFleets',
            component: () => import('./components/AllFleets'),
            meta: { requiresAuth: true }
        },

        {
            path: '/all-fleets-new',
            name: 'AllFleetsNew',
            component: () => import('./components/AllFleetsNew'),
            meta: { requiresAuth: true }
        },

        {
            path: '/all-fleet-data',
            name: 'AllFleetData',
            component: () => import('./components/AllFleetData'),
            meta: { requiresAuth: true }
        },

        {
            path: '/copy-multiple-documents',
            name: 'CopyMultipleDocuments',
            component: () => import('./components/CopyMultipleDocuments'),
            meta: { requiresAuth: true }
        },

        {
            path: '/update-single-document',
            name: 'UpdateSingleDocument',
            component: () => import('./components/UpdateSingleDocument'),
            meta: { requiresAuth: true }
        },

        {
            path: '/copy-document-to-collections',
            name: 'CopyDocumentToCollections',
            component: () => import('./components/CopyDocumentToCollections'),
            meta: { requiresAuth: true }
        },

        {
            path: '/copy-collection-to-documents',
            name: 'CopyCollectionToDocuments',
            component: () => import('./components/CopyCollectionToDocuments'),
            meta: { requiresAuth: true }
        },

        {
            path: '/copy-document-fields-to-documents',
            name: 'CopyDocumentFieldsToDocuments',
            component: () => import('./components/CopyDocumentFieldsToDocuments'),
            meta: { requiresAuth: true }
        },

        {
            path: '/delete-multiple-documents',
            name: 'DeleteMultipleDocuments',
            component: () => import('./components/DeleteMultipleDocuments'),
            meta: { requiresAuth: true }
        },

        {
            path: '/customers-vessels',
            name: 'CustomersVessels',
            component: () => import('./components/CustomersVessels'),
            meta: { requiresAuth: true }
        },

        {
            path: '/add-fleet-to-user',
            name: 'AddFleetToUser',
            component: () => import('./components/AddFleetToUser'),
            meta: { requiresAuth: true }
        },

        {
            path: '/manage-all-vessels',
            name: 'ManageAllVessels',
            component: () => import('./components/ManageAllVessels'),
            meta: { requiresAuth: true }
        },

        {
            path: '/move-vessel-to-fleet',
            name: 'MoveVesselToFleet',
            component: () => import('./components/MoveVesselToFleet'),
            meta: { requiresAuth: true }
        },

        {
            path: '/move-vessel-between-fleets',
            name: 'MoveVesselBetweenFleets',
            component: () => import('./components/MoveVesselBetweenFleets'),
            meta: { requiresAuth: true }
        },

        {
            path: '/move-teams-between-fleets',
            name: 'MoveTeamsBetweenFleets',
            component: () => import('./components/MoveTeamsBetweenFleets'),
            meta: { requiresAuth: true }
        },

        {
            path: '/move-fleet-items-between-fleets',
            name: 'MoveFleetItemsBetweenFleets',
            component: () => import('./components/MoveFleetItemsBetweenFleets'),
            meta: { requiresAuth: true }
        },

        {
            path: '/user-square-settings',
            name: 'UserSquareSettings',
            component: () => import('./components/UserSquareSettings'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/activity-dashboard',
            name: 'ActivityDashboard',
            component: () => import('./components/ActivityDashboard'),
            meta: { requiresAuth: true }
        },

        {
            path: '/all-stats',
            name: 'AllStats',
            component: () => import('./components/AllStats'),
            meta: { requiresAuth: true }
        },

        {
            path: '/quick-vessel-setup',
            name: 'QuickVesselSetup',
            component: () => import('./components/QuickVesselSetup'),
            meta: { requiresAuth: true }
        },

        {
            path: '/tag-library',
            name: 'TagLibrary',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/TagLibrary'),
            meta: { requiresAuth: true }
        },

        {
            path: '/landing-build-content',
            name: 'LandingBuildContent',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/LandingBuildContent'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-helper-statements',
            name: 'BuildHelperStatements',
            component: () => import('./components/build-content/BuildHelperStatements'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-pooled-all-statements',
            name: 'BuildPooledAllStatements',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildPooledAllStatements'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-pooled-statements',
            name: 'BuildPooledStatements',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildPooledStatements'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-pooled-hazards',
            name: 'BuildPooledHazards',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildPooledHazards'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-pooled-contacts',
            name: 'BuildPooledContacts',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildPooledContacts'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-pooled-policies',
            name: 'BuildPooledPolicies',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildPooledPolicies'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-pooled-sops',
            name: 'BuildPooledSops',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildPooledSops'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-pooled-eops',
            name: 'BuildPooledEops',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildPooledEops'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-pooled-startups',
            name: 'BuildPooledStartups',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildPooledStartups'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-pooled-maintenance',
            name: 'BuildPooledMaintenance',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildPooledMaintenance'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-pooled-inductions',
            name: 'BuildPooledInductions',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildPooledInductions'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-pooled-passenger-inductions',
            name: 'BuildPooledPassengerInductions',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildPooledPassengerInductions'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-pooled-shutdowns',
            name: 'BuildPooledShutdowns',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildPooledShutdowns'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-pooled-jsas',
            name: 'BuildPooledJSAs',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildPooledJSAs'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-pooled-checkin-items',
            name: 'BuildPooledCheckInItems',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildPooledCheckInItems'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-micro-sops',
            name: 'BuildMicroSops',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildMicroSops'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-micro-eops',
            name: 'BuildMicroEops',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildMicroEops'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-micro-policies',
            name: 'BuildMicroPolicies',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildMicroPolicies'),
            meta: { requiresAuth: true }
        },

        {
            path: '/build-cloud-helpers',
            name: 'BuildCloudHelpers',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/build-content/BuildCloudHelpers'),
            meta: { requiresAuth: true }
        },

        {
            path: '/landing-fix-sms',
            name: 'LandingFixSms',
            component: () => import(/* webpackChunkName: "group-build-content" */ './components/fix-sms/LandingFixSms'),
            meta: { requiresAuth: true }
        },

        {
            path: '/fix-sms-info',
            name: 'FixSmsInfo',
            component: () => import(/* webpackChunkName: "group-fixes" */ './components/fix-sms/FixSmsInfo'),
            meta: { requiresAuth: true }
        },

        {
            path: '/fix-sms-statements',
            name: 'FixSmsStatements',
            component: () => import(/* webpackChunkName: "group-fixes" */ './components/fix-sms/FixSmsStatements'),
            meta: { requiresAuth: true }
        },

        {
            path: '/fix-sms-hazards',
            name: 'FixSmsHazards',
            component: () => import(/* webpackChunkName: "group-fixes" */ './components/fix-sms/FixSmsHazards'),
            meta: { requiresAuth: true }
        },

        {
            path: '/fix-sms-contacts',
            name: 'FixSmsContacts',
            component: () => import(/* webpackChunkName: "group-fixes" */ './components/fix-sms/FixSmsContacts'),
            meta: { requiresAuth: true }
        },

        {
            path: '/fix-sms-policies',
            name: 'FixSmsPolicies',
            component: () => import(/* webpackChunkName: "group-fixes" */ './components/fix-sms/FixSmsPolicies'),
            meta: { requiresAuth: true }
        },

        {
            path: '/fix-sms-sops',
            name: 'FixSmsSops',
            component: () => import(/* webpackChunkName: "group-fixes" */ './components/fix-sms/FixSmsSops'),
            meta: { requiresAuth: true }
        },

        {
            path: '/fix-sms-eops',
            name: 'FixSmsEops',
            component: () => import(/* webpackChunkName: "group-fixes" */ './components/fix-sms/FixSmsEops'),
            meta: { requiresAuth: true }
        },

        {
            path: '/fix-sms-startups',
            name: 'FixSmsStartups',
            component: () => import(/* webpackChunkName: "group-fixes" */ './components/fix-sms/FixSmsStartups'),
            meta: { requiresAuth: true }
        },

        {
            path: '/fix-sms-maintenance',
            name: 'FixSmsMaintenance',
            component: () => import(/* webpackChunkName: "group-fixes" */ './components/fix-sms/FixSmsMaintenance'),
            meta: { requiresAuth: true }
        },

        {
            path: '/fix-sms-inductions',
            name: 'FixSmsInductions',
            component: () => import(/* webpackChunkName: "group-fixes" */ './components/fix-sms/FixSmsInductions'),
            meta: { requiresAuth: true }
        },

        {
            path: '/fix-sms-safety-briefings',
            name: 'FixSmsSafetyBriefings',
            component: () => import(/* webpackChunkName: "group-fixes" */ './components/fix-sms/FixSmsSafetyBriefings'),
            meta: { requiresAuth: true }
        },

        {
            path: '/fix-sms-revisions',
            name: 'FixSmsRevisions',
            component: () => import(/* webpackChunkName: "group-fixes" */ './components/fix-sms/FixSmsRevisions'),
            meta: { requiresAuth: true }
        },

        {
            path: '/view-sms',
            name: 'ViewSms',
            component: () => import('./components/ViewSms'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/copy-sms',
            name: 'CopySms',
            component: () => import('./components/CopySms'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/payments',
            name: 'Payments',
            component: () => import('./components/Payments'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/update-counts',
            name: 'UpdateCounts',
            component: () => import('./components/UpdateCounts'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/copy-document',
            name: 'CopyDocument',
            component: () => import('./components/CopyDocument'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/add-alert',
            name: 'AddAlert',
            component: () => import('./components/AddAlert'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/one-off-function-call',
            name: 'OneOffFunctionCall',
            component: () => import('./components/OneOffFunctionCall'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/vessels-loop',
            name: 'VesselsLoop',
            component: () => import('./components/VesselsLoop'),
            meta: { requiresAuth: true },
            props: true
        },

        {
            path: '/all-fleet-admins',
            name: 'AllFleetAdmins',
            component: () => import('./components/AllFleetAdmins'),
            meta: { requiresAuth: true },
            props: true
        }
    ]
});
