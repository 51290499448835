<template>
  <div>
    <div class="site-wrapper no-header">
      <div class="container">
        <h3 class="is-size-5">Write a Helper Statement</h3>

        <div>
          <div class="spacer s20"></div>

          <div class="file-metadata">
            <label class="label">Title</label>
            <input
              class="input meta-key"
              type="text"
              placeholder="Enter key"
              v-model="newStatement.title"
            />
            <label class="label">Statement</label>
            <textarea
              class="textarea"
              placeholder="Enter value"
              v-model="newStatement.details"
            ></textarea>
          </div>

          <div class="spacer s20"></div>

          <div class="form-group-reg">
            <div class="checkbox-wrapper">
              <label class="label">Add tags</label>
              <div class="field" v-for="tag in tags" :key="tag.tag">
                <input
                  class="is-checkradio"
                  type="checkbox"
                  :id="tag.tag"
                  :value="tag.tag"
                  v-model="newStatement.tags"
                />
                <label :for="tag.tag">{{ tag.title }}</label>
              </div>
            </div>
          </div>

          <div class="spacer s20"></div>

          <div class="clear"></div>

          <div class="input-wrapper">
            <label>Index</label>
            <input class="input" type="tel" v-model="newStatement.index" />
          </div>

          <div class="clear"></div>

          <div class="spacer s20"></div>

          <div class="buttons">
            <span
              :disabled="showButton"
              class="button is-warning is-medium"
              @click="saveNewDocument()"
              >Save Statement</span
            >
          </div>
        </div>

        <div class="spacer s50"></div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import { db } from "@/main.js";
import "firebase/compat/auth";
import "firebase/firestore";
import "firebase/compat/functions";
import NProgress from "nprogress";
import { v4 as uuidv4 } from "uuid";
//import * as moment from 'moment';

export default {
  components: {},
  mounted: function() {
    firebase.auth().onAuthStateChanged(this.onUserLogin);
  },

  data: function() {
    return {
      newStatement: {
        index: 99,
        title: "",
        details: "",
        tags: [],
      },
      tags: [
        { title: "Owner", tag: "owner" },
        { title: "Crew", tag: "crew" },
        { title: "Master", tag: "master" },
        { title: "Engineer", tag: "engineer" },
        { title: "Designated Person", tag: "designated" },
        { title: "Charter Vessel", tag: "charter" },
        { title: "Commercial Diving", tag: "diving" },
        { title: "Marine Construction", tag: "construction" },
        { title: "Commercial Fishing", tag: "fishing" },
        // { title: "", tag: "" },
        // { title: "", tag: "" },
        // { title: "", tag: "" },
      ],
      updateData: {},
    };
  },

  computed: {
    isSteve() {
      return (
        this.user.uid == "3wTzZecD4QYOPUk4IyWRA2JmDcJ2" ||
        this.user.uid == "M3GGz7qWIxNqAsN2Ojglrv334jm1"
      );
    },
    showButton() {
      return this.newStatement.title == "" || this.newStatement.details == "";
    },
  },

  methods: {
    onUserLogin: function(user) {
      if (user) {
        // console.log(user);
      }
    },

    saveNewDocument() {
      let self = this;

      let updateData = {
        title: this.newStatement.title,
        details: this.newStatement.details,
        tags: this.newStatement.tags,
        index: parseInt(this.newStatement.index),
      };

      let message;
      message = {
        title: "Save new statement?",
        body: "Click YES below proceed.",
      };
      let options = {
        okText: "YES, SAVE",
        backdropClose: true,
        cancelText: "Cancel",
      };
      this.$dialog
        .confirm(message, options)
        .then(function(dialog) {
          NProgress.start();

          db.collection("pooledAllStatements")
            .add(updateData)
            .then(() => {
              self.clear();
              NProgress.done();
            })
            .catch((err) => {
              console.log("error", err);
              self.clear();
              NProgress.done();
            });
        })
        .catch(function(error) {
          console.log(error);
          NProgress.done();
        });
    },

    clear() {
      this.newStatement = {
        index: 99,
        title: "",
        details: "",
        tags: [],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="text"],
input[type="tel"] {
  width: 100%;
  float: left;
  display: block;
  margin-bottom: 2rem;
  padding: 15px;
  height: auto;
}
label {
  margin-bottom: 10px;
  display: block;
}
.textarea {
  width: 100%;
  padding: 15px;
  min-height: 400px;
  margin-bottom: 2rem;
}
</style>
