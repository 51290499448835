<template>
  
  <div class="site-wrapper no-header">

    <div class="container">
    
       <div class="notification is-info"><h3>Add a Fleet to a User</h3><p>And the user as fleet admin</p></div>

        <div v-if="!userSelected" class="notification is-primary">
          <input class="input" v-model="findName" id="filtername" autocomplete="off" type="text" placeholder="Start typing user name..." />
        </div>

        <div v-if="userSelected" class="highlight">User: {{selectedUser.username}} </div>

        <div v-if="findName.length > 2 && !userSelected">

          <div v-for="(user, index) in filteredNames" :key="index">

            <div class="buttons results">
              <span class="button is-light" @click="selectUser(user)">{{user.username}}</span>
            </div>

          </div>

        </div>




        <div v-if="userSelected && !fleetSelected" class="notification is-primary">
          <input class="input" v-model="findFleet" id="filterfleet" autocomplete="off" type="text" placeholder="Start typing fleet name..." />
        </div>

        <div v-if="fleetSelected" class="highlight">User: {{selectedFleet.companyName}} </div>

        <div v-if="findFleet.length > 2 && !fleetSelected">

          <div v-for="(fleet, index) in filteredFleets" :key="index">

            <div class="buttons results">
              <span class="button is-light" @click="selectFleet(fleet)">{{fleet.companyName}}</span>
            </div>

          </div>

        </div>

        <div v-if="userSelected && fleetSelected" class="buttons">
          <span class="button is-info is-medium" @click="addFleetToUser()">Add User to Fleet / Fleet to User</span>
        </div>



        <div class="buttons">
          <span v-if="findName != ''" class="button is-warning" @click="clearAll()">Clear</span>
        </div>






    </div>



  </div>


</template>




<script>
  
  import { db } from '../main.js';
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth'; 
  import * as moment from 'moment';
  import NProgress from 'nprogress';

  export default {
    name: 'AdminAddVessel',

    computed: {
      user() {
        return this.$store.getters.getUser;
      },
      userData() {
        return this.$store.getters.getSubscriptionInfo;
      },
      filteredNames() {
        let filter = new RegExp(this.findName, 'i')
        return this.users.filter(user => user.username.match(filter));
      },
      filteredFleets() {
        let filter = new RegExp(this.findFleet, 'i')
        return this.fleets.filter(fleet => fleet.companyName.match(filter));
      },
      showSave() {
        return false;
      },

      



    },

    data() {
      return {
        users: [],
        fleets: [],
        userSelected: false,
        fleetSelected: false,
        findName: '',
        findFleet: '',
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        selectedUser: '',
        selectedFleet: '',
        

      } 

    },

    mounted() {
      // this.$store.dispatch('setUser');
      // this.$store.dispatch('SETSUBSCRIPTIONINFO');
      firebase.auth().onAuthStateChanged( this.onUserLogin ); 
    },

    methods: {
      onUserLogin: function( user ) {
        if (user) {         
          this.firebaseemail = user.email;
          this.firebaseid = user.uid;
          this.firebasedisplayname = this.userData.username;
          this.loadUsers();
          this.loadFleets();
        }
      },

      loadUsers() {
        let self = this;
        db.collection('userdata').orderBy('signUpDate','desc').onSnapshot(function(querySnapshot) {
          self.users = [];
          querySnapshot.forEach(function(doc) {
            // self.users.push(doc.data())
            self.users.push({
              userid: doc.id,
              signUpDate: doc.data().signUpDate,
              userstate: doc.data().userstate,
              industry: doc.data().industry,
              userrole: doc.data().userrole,
              userGroup: doc.data().userGroup ? doc.data().userGroup : '',
              username: doc.data().username,
              useremail: doc.data().useremail ? doc.data().useremail : '',
              userphone: doc.data().userphone ? doc.data().userphone : '',
              favouriteVesselID: doc.data().favouriteVesselID ? doc.data().favouriteVesselID : '',
              signupPlatform: doc.data().signupPlatform ? doc.data().signupPlatform : 'not set',
              favouriteFixed: doc.data().favouriteFixed ? doc.data().favouriteFixed : false,
              // profilingInfo: doc.data().profilingInfo ? doc.data().profilingInfo : '',
            });
          });
        })
      },

      loadFleets() {
        let self = this;
        db.collection('companies').onSnapshot(function(querySnapshot) {
          self.fleets = [];
          querySnapshot.forEach(function(doc) {
            // self.fleets.push(doc.data())
            self.fleets.push({
              companyID: doc.id,
              companyName: doc.data().companyName ? doc.data().companyName : 'No name',
              companyState: doc.data().companyState ? doc.data().companyState : 'No state',
            });
          });
        })
      },

      cleanPhone(number) {
        return "0" + number.slice(3,12);
      },

      selectUser(user) {
        console.log(user);
        this.userSelected = true;
        this.selectedUser = user;
      },

      selectFleet(fleet) {
        console.log(fleet);
        this.fleetSelected = true;
        this.selectedFleet = fleet;
      },

      clearAll: function() {
        this.findName = '';
        this.findFleet = '';
        this.userSelected = false;
        this.fleetSelected = false;
        this.selectedUser = '';
        this.selectedFleet = '';
      },


      addFleetToUser() {
        let self = this;

        let userData = {
          userid: this.selectedUser.userid,
          username: this.selectedUser.username,
          useremail: this.selectedUser.useremail,
        }
        let fleetData = {
          companyID: this.selectedFleet.companyID,
          companyName: this.selectedFleet.companyName,
          companyState: this.selectedFleet.companyState,
        }
        console.log(userData);
        console.log(fleetData);

        let message;
        message = {
          title: 'Confirm Add Fleet to User?',
          body: 'Click YES below to proceed.'
        };
        let options = {
          okText: 'YES',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(function(dialog) {
              
              NProgress.start();
              var addUserToFleet = firebase.functions().httpsCallable('addUserToFleet');
              addUserToFleet({
                userData: userData,
                fleetData: fleetData
              })
              .then(function(result) {
                // var sanitizedMessage = result.data.text;
                // console.log('Result',sanitizedMessage);
                // console.log('Message',result.data.message);
                self.clearAll();
                NProgress.done();

              })
              .catch(function(error) {
                console.log('Error',error);
                NProgress.done();
              }); 


          })
          .catch(function(error) {
            console.log(error); 
            NProgress.done();
          });         




      }





    }

  }



</script>



<style lang="scss" scoped>
  
  .highlight {
    background: cadetblue;
    padding: 15px;
    color: #fff;
  }
  .posi {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: #fff;
    z-index: 999;
  }
  label {
    &.label {
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 10px;
      &.tight {
        margin-bottom: 4px;
      }
    }
  }
  .buttons.results {
    margin-bottom: 4px;
  }




</style>





