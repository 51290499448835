<template>
  
  <div class="site-wrapper no-header">
    
      <div class="container">

        <h3 class="is-size-5">All CRM and Customer Follow Ups</h3>

        <ul v-if="allFollowUps.length > 0" class="sms-steps steps is-vertical has-content-centered">

          <li v-for="(step, index) in allFollowUps" :key="index" class="steps-segment" :class="{'due':step.isDue, 'past':step.isPast}">
            <span class="steps-marker" :class="{'due':step.isDue, 'past':step.isPast}">
              <span class="icon"><font-awesome-icon :icon="step.icon" /></span>
            </span>
            <div class="steps-content">
                <!-- <p class="heading">{{formatDate(step.timestamp,'DDD MMM YYYY (h:mm a)')}}</p> -->
                <p class="heading">{{formatDate(step.date,'D MMM YYYY (h:mm a)')}}</p>
                <p @click="showDetails(step)" class="bold upper clickable" :class="{'due':step.isDue, 'past':step.isPast}">{{step.action}}</p>
                <p class="italic">{{step.username}}</p>
                <p>---------------------------</p>
                <div v-for="line in step.notes.split('\n')" :key="line">{{line}}<br></div>
                <div v-if="step.followUpAdded" class="follow-up">Follow up added</div>
                <div v-if="isSteve" class="buttons">
                  <span class="button is-light" @click="markCompleted(step)">Mark as Complete</span>
                </div>
                <div class="spacer s10"></div>
            </div>
          </li>

          <li class="steps-segment">
            <span class="steps-marker">
              <span class="icon"><span><font-awesome-icon icon="check" /></span></span>
            </span>
            <div class="steps-content">
                <p class="heading">&nbsp;</p>
                <div class="spacer s10"></div>
            </div>
          </li>

        </ul>  

        <div v-else class="">
          Loading follow ups...
        </div>

        <hr>


        <div class="buttons">
          <span class="button is-info" @click="goBack()"><span><font-awesome-icon icon="chevron-left" /></span> &nbsp;Back &nbsp;</span>
          <!-- <span v-if="findName != '' || !selected" class="button is-warning" @click="clearUser()">Clear</span>
          <span v-if="findName != '' && selected" class="button is-primary" @click="addEntry()">Add Entry</span> -->
        </div>
        

      </div>


      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showDetailsModal}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Follow Up Details</p>
              <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>
            <section class="modal-card-body">    

              <p>NAME: {{selectedItem.username}}</p>
              <p>ACTION: {{selectedItem.action}}</p>
              <p>NOTES: {{selectedItem.notes}}</p>

              <div v-if="loadingUserData"><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading user info...</div>
              <div v-else>
                <p>NUMBER: <a :href="'tel:' + selectedItem.userphone">{{selectedItem.userphone}}</a></p>
                <p>EMAIL: <a :href="'mailto:' + selectedItem.useremail + '?Subject=Message%20from%20Offshore%20SMS%20Team'">{{selectedItem.useremail}}</a></p>
              </div>

            </section>
            <footer class="modal-card-foot">
              <button class="button is-delete" @click.prevent="close">Done</button>
            </footer>
        </div>
      </div>


  </div>

</template>


<script>
  

  import { db } from '../main.js';
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth'; 
  import 'firebase/firestore';
  import NProgress from 'nprogress';
  import * as moment from 'moment';

  export default {

    name: 'CrmFollowUps',
    props: {
      isAdding: Boolean,
      sentUser: Object,
    },
    components: {

    },
    computed: {
      filteredNames() {
        let filter = new RegExp(this.findName, 'i')
        return this.allUsers.filter(item => item.userName.match(filter));
      },
      isSteve() {
        return this.firebaseid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2';
      }

    },

    mounted: function() {
      let self = this;
      firebase.auth().onAuthStateChanged( this.onUserLogin );   
    },

    data: function() {
      return {
        firebaseid: '',
        // reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        allFollowUps: [],
        showDetailsModal: false,
        selectedItem: {},
        loadingUserData: false,

      }
    },

    methods: {

      onUserLogin: function( user ) {
        let self = this;
        if (user) {         
          this.firebaseid = user.uid;
          this.loadFollowUps();
        }     
          
      },

      close: function() {
        // this.selectedUser = {};
        this.showAddEntry = false;
        this.showDetailsModal = false;
        this.selectedItem = {};
        this.loadingUserData = false;

      },

      formatDate : function (sentDate,format) {
        return moment(sentDate).format(format);
      },

      loadFollowUps() {
        let self = this;
        db.collection('crmFollowUps').orderBy('date','asc').where('complete','==',false).onSnapshot((querySnapshot) => {
          self.allFollowUps = [];
          querySnapshot.forEach((doc) => {
            let isDue = moment(doc.data().date.toDate()).isBefore(moment().add(2, 'days'));
            let isPast = moment(doc.data().date.toDate()).isBefore(moment());
            self.allFollowUps.push({
              id: doc.id,
              timestamp: doc.data().timestamp ? doc.data().timestamp.toDate() : '',
              date: doc.data().date ? doc.data().date.toDate() : '',
              action: doc.data().action ? doc.data().action : 'No action',
              notes: doc.data().notes ? doc.data().notes : '',
              followUpAdded: doc.data().followUpAdded ? doc.data().followUpAdded : false,
              username: doc.data().userName ? doc.data().userName : 'No Username',
              userid: doc.data().userID ? doc.data().userID : 'No ID',
              userphone: doc.data().userphone ? doc.data().userphone : '',
              useremail: doc.data().useremail ? doc.data().useremail : '',
              isDue: isDue,
              isPast: isPast,
              icon: isPast ? 'exclamation-triangle' : isDue ? 'stopwatch' : 'calendar-check',
            });
          });
        });
      },

      // loadUserInfo(userid) {
      //   this.loadingUserData = true;
      //   let self = this;
      //   db.collection('userdata').doc(userid).get().then((doc) => {
      //     self.selectedItem.phone = doc.data().userphone ? cleanPhone....
      //   })
      //   .then(() => {
      //     NProgress.done();
      //   });
      // },

      goBack: function() {
        this.$router.go(-1);
        // let self = this;
        // this.$router.push({ 
        //   name: 'UserTimelines', 
        //   params: { 
        //     isAdding: true,
        //     sentUser: self.currentUser,
        //   } 
        // });
      },

      showDetails(step) {
        // console.log(step);
        this.selectedItem = step;
        this.showDetailsModal = true;
      },

      markCompleted(item) {
        let self = this;      
        let message = {
          title: 'Mark as Complete?',
          body: ''
        };
        let options = {
          okText: 'Yes, Complete',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(function() {
            NProgress.start();           
            db.collection('crmFollowUps').doc(item.id).update({
              complete: true
            });
            NProgress.done();
          })
          .catch(function(error) {
            console.log(error);
            NProgress.done();
          });  


      }


    }

  }


</script>


<style lang="scss" scoped>
  
  h3 {
    margin: 1rem 0 1.5rem;
  }
  .select {
    width: 100%;
    max-width: 400px;
    select {
      width: 100%;
    }
  }

  h4 {
    text-transform: uppercase;
    margin-bottom: 0.8rem;
    font-weight: bold;
  }

  ul.vessel-list {
    max-width: 320px;
    li {
      margin-bottom: 1rem;
      &:last-of-type {
        margin-bottom: 0;
      }
      .vessel-name {
        float: left;
      }
      .button {
        float: right;
      }
    }
  }
  .buttons {
    margin-top: 1rem;
  }
  .button {
    margin-top: 0;
  }

  input.entryTitle {
    padding: 2rem 1rem;
  }

  textarea {
    padding: 1rem;
  }

  .steps-content {
    p {
      &.bold {
        font-weight: bold;
      }
      &.upper {
        text-transform: uppercase;
      }
      &.italic {
        font-style: italic;
      }
    }
    .follow-up {
      font-style: italic;
      color: orangered;
    }    
    .due {
      color: darkorange;
    }
    .past {
      color: red;
    }
  }
  .steps.is-vertical {
    .steps-marker {
      &.due {
        background-color: darkorange !important;
      }
      &.past {
        background-color: red !important;
      }
    }
    .steps-segment {
      &:after {
        bottom: 10px;
        width: 4px;
        left: 14px;
        top: 40px;
      }
      &:not(:last-child):after {
        bottom: 10px;
        width: 4px;
        left: 14px;
        top: 40px;
      }
      &.due {
        &:after {
          background-color: darkorange;
        }
      }
      &.past {
        &:after {
          background-color: red;
        }
      }
    }
  }



</style>