<template>
	
	<div>

			<div class="site-wrapper no-header">
			
				<div class="container">

						<h3 class="is-size-5">All Fleet Admins</h3><br>

            <table>
              <tbody>
                <tr v-for="(admin, index) in allAdmins">
                  <td>{{admin.adminEmail}}</td>
                </tr>
              </tbody>
            </table>


				</div>

			</div>



	</div>

</template>


<script> 

	import firebase from 'firebase/compat/app';
	import { db } from '../main.js';
	import 'firebase/compat/auth'; 
	import 'firebase/firestore';
	import 'firebase/compat/functions';
	import NProgress from 'nprogress';	
	import * as moment from 'moment';

	// const UpdateAudit = () => import('./UpdateAudit.vue')

	export default {

		name: 'AllCompanies',
		components: {
	  	// UpdateAudit
	  },
		mounted: function() {
			firebase.auth().onAuthStateChanged( this.onUserLogin );	
		},

		data: function() {
			return {
        allCompanies: [],
        allAdminIDs: [],
        allAdmins: [],
			}
		},

		computed: {
			user() {
	    	return this.$store.getters.getUser;
	    },
      isSteve() {
        return this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false;
      },
		},

		methods: {

			onUserLogin: function( user ) {
      	if (user) {      		
          this.loadAllCompanies();
	      }
      },

      close: function() {

      },

      async loadAllCompanies() {
        let self = this;
        NProgress.start();
        await db.collection('companies').get().then((querySnapshot) => {
          self.allCompanies = [];
          querySnapshot.forEach((doc) => {
            self.allCompanies.push({
              id: doc.id,
              companyName: doc.data().companyName,
              adminUsers: doc.data().adminUsers ? doc.data().adminUsers : [],
            });
            if (doc.data().adminUsers) {
              doc.data().adminUsers.forEach((admin) => {
                if (!self.allAdminIDs.includes(admin)) self.allAdminIDs.push(admin);
              });
            }
          });
        })
        .then(async () => {
          // console.log(self.allAdminIDs);
          await Promise.all(self.allAdminIDs.map(async (adminID, index) => {
            await db.collection('userdata').doc(adminID).get().then((docRef) => {
              // console.log(docRef.data());
              if (docRef.data() != undefined) {
                self.allAdmins.push({
                  adminName: docRef.data().username ? docRef.data().username : 'no name',
                  adminEmail: docRef.data().useremail ? docRef.data().useremail : 'No email',
                  adminID: adminID
                });
              }
            });
          }));
        })
        .then(() => {
          NProgress.done();
        });
      },

      // loadAllVessels: function() {
      // 	let self = this;
      //   let cutoffDate = moment().add(1, 'months');
      // 	db.collection('vessels').orderBy("vesselName", "asc").where('isCompanyVessel','==',true).onSnapshot(function(querySnapshot) {
      // 		self.allVessels = [];
      // 		querySnapshot.forEach((doc) => {
      // 			//let auditD = (doc.data().lastAuditDate ? doc.data().lastAuditDate.toDate() : 'vvv');
      // 			//console.log(auditD);
      // 			self.allVessels.push({
      // 				vesselID: doc.id,
      // 				vesselName: doc.data().vesselName,
      // 				// lastAuditID: (doc.data().lastAuditID ? doc.data().lastAuditID : 'NA'),
      // 				//lastAuditDate: (doc.data().lastAuditDate ? doc.data().lastAuditDate.toDate() : 'Never'),
      // 				// lastAuditDate: (doc.data().lastAuditDate ? self.formatDate(doc.data().lastAuditDate,'dddd D MMMM (h:mm a)') : 'Never'),
      // 				// sinceDate: (doc.data().lastAuditDate ? moment(doc.data().lastAuditDate.toDate()).fromNow() : 'no audit'),
      // 				// lastAuditPercent: (doc.data().lastAuditPercent ? doc.data().lastAuditPercent : 0),
      // 				// lastAuditCode: (doc.data().lastAuditCode ? doc.data().lastAuditCode : 0),
      // 				// hasBeenCounted: false,
      // 				vesselOwnerName: (doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?'),
      // 				vesselOwnerID: doc.data().vesselOwnerID,
      //         isBillable: doc.data().isBillable ? doc.data().isBillable : false,
      //         isPaidFor: doc.data().isPaidFor ? doc.data().isPaidFor : false,
      //         lastVesselCharge: doc.data().lastVesselCharge ? doc.data().lastVesselCharge : 0,
      //         nextVesselCharge: doc.data().nextVesselCharge ? doc.data().nextVesselCharge : 0,
      //         nextChargeDate: doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate() : '',
      //         lastChargeDate: doc.data().lastChargeDate ? doc.data().lastChargeDate.toDate() : '',
      //         remaining: doc.data().nextChargeDate ? moment().to(doc.data().nextChargeDate.toDate()) : '',
      //         isPast: doc.data().nextChargeDate ? moment().isAfter(moment(doc.data().nextChargeDate.toDate())) : false,
      //         isDue: doc.data().nextChargeDate ? moment(cutoffDate).isAfter(moment(doc.data().nextChargeDate.toDate())) : false,
      //         isDemo: doc.data().isDemo ? doc.data().isDemo : false,
      //         vesselType: !doc.data().isBillable ? 'free' : doc.data().isDemo ? 'demo' : 'billable',
      //         expiryStatus: moment().isAfter(moment(doc.data().nextChargeDate.toDate())) ? 'expired' : moment(cutoffDate).isAfter(moment(doc.data().nextChargeDate.toDate())) ? 'due' : 'current',
      // 			});
      // 		});
      // 	});
      // },

      formatDate: function(sentDate, format) {
        // let fixDate = sentDate ? sentDate.toDate() : new Date();
        return moment(sentDate).format(format);
      },

      loadVesselData(vessel) {
        let self = this;
        let cutoffDate = moment().add(1, 'months');
        this.isGettingData = true;
        this.selectedVessel.vesselName = vessel.vesselName;
        this.selectedVessel.vesselID = vessel.vesselID;
        this.showVessel = true;
        db.collection('vessels').doc(vessel.vesselID).get().then((doc) => {
          self.selectedVessel.vesselOwnerName = doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?';
          self.selectedVessel.vesselOwnerID = doc.data().vesselOwnerID ? doc.data().vesselOwnerID : '';
          self.selectedVessel.isBillable = doc.data().isBillable ? doc.data().isBillable : false;
          self.selectedVessel.isPaidFor = doc.data().isPaidFor ? doc.data().isPaidFor : false;
          self.selectedVessel.lastVesselCharge = doc.data().lastVesselCharge ? doc.data().lastVesselCharge : 0;
          self.selectedVessel.nextVesselCharge = doc.data().nextVesselCharge ? doc.data().nextVesselCharge : 0;
          self.selectedVessel.nextChargeDate = doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate() : '';
          self.selectedVessel.lastChargeDate = doc.data().lastChargeDate ? doc.data().lastChargeDate.toDate() : '';
          self.selectedVessel.remaining = doc.data().nextChargeDate ? moment().to(doc.data().nextChargeDate.toDate()) : '';
          self.selectedVessel.isPast = doc.data().nextChargeDate ? moment().isAfter(moment(doc.data().nextChargeDate.toDate())) : false;
          self.selectedVessel.isDue = doc.data().nextChargeDate ? moment(cutoffDate).isAfter(moment(doc.data().nextChargeDate.toDate())) : false;
          self.selectedVessel.isDemo = doc.data().isDemo ? doc.data().isDemo : false;
          self.selectedVessel.vesselType = !doc.data().isBillable ? 'free' : doc.data().isDemo ? 'demo' : 'billable';
          self.selectedVessel.expiryStatus = doc.data().nextChargeDate ? moment().isAfter(moment(doc.data().nextChargeDate.toDate())) ? 'expired' : moment(cutoffDate).isAfter(moment(doc.data().nextChargeDate.toDate())) ? 'due' : 'current' : 'unknown';
        })
        .then(() => {
          self.isGettingData = false;
        })
        .catch((err) => {
          console.log('Error',err);
          self.errorMsg = err;
        });
      },

      updateVesselData(selectedVessel) {
        console.log(selectedVessel);
        this.$router.push({
          name: 'UpdateVessel',
          params: {
            sentVessel: selectedVessel
          }
        });
      },






		},

	}



</script> 




<style lang="scss" scoped>
	
  .site-wrapper .container {
    max-width: 850px;
  }
	.button {
		margin-top: 0;
    &.is-skip {
      background-color: #ff7878;
      color: #fff;
      border-color: #fff;
    }
    &.is-light {
      margin-bottom: 10px;
    }
	}
  .expired {
    color: red;
    margin-bottom: 1rem;
  }
  .due {
    color: darkorange;
    margin-bottom: 1rem;
  }
	.traffic-light {
	  position: relative;
	  span {
	  	float: left;
	  	margin-right: 10px;
	  }
	  .light {
	  	border-radius: 3px;
	  	display: inline-block;
	    color: #fff;
	    font-size: 0.8rem;
	    padding: 3px 10px;
	  	&.red {
	  		background: red;
	  		&.active {
	  			background: red;
	  		}
	  	}
	  	&.orange {
	  		background: orange;
	  		&.active {
	  			background: orange;
	  		}
	  	}
	  	&.green {
	  		background: #03d203;
	  		&.active {
	  			background: #03d203;
	  		}
	  	}
	  }
	}
  .table {
    margin-bottom: 3rem;
  }

</style> 