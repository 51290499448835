<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">Fix SMS Hazards</h3>
                <br />

                <div v-if="!isSelected" class="notification is-primary">
                    <input
                        class="input"
                        v-model="findName"
                        id="filtername"
                        autocomplete="off"
                        type="text"
                        placeholder="Start typing vessel name..."
                    />
                </div>

                <div class="buttons">
                    <span v-if="findName != ''" class="button is-warning" @click="clearVessel()">Clear</span>
                </div>

                <hr />

                <div v-if="selectedVessel != ''" class="highlight">{{ selectedVessel.vesselName }} ({{ selectedVessel.vesselOwnerName }})</div>

                <div v-if="findName != '' && !isSelected">
                    <div v-for="(vessel, index) in filteredNames" :key="index">
                        <div class="buttons">
                            <span class="button is-light" @click="selectVessel(vessel)">{{ vessel.vesselName }} ({{ vessel.vesselOwnerName }})</span>
                        </div>
                    </div>
                </div>

                <div class="clear"></div>
                <!--
            <div class="input-wrapper centered">
              <label>Target Collection Path (no trailing slashes)</label>
              <input class="input" type="text" placeholder="Paste target collection path" v-model="targetPath">
            </div>

            <div class="clear"></div>

            <hr>

            <div class="buttons">
              <span :disabled="pathsAreValid" class="button is-warning" @click="displayDocs()">Show Documents</span>
              <span class="button is-light" @click="clearDocs()">Clear</span>
            </div> -->

                <div v-if="findName != '' && isSelected">
                    <h4 class="icon">Modify hazards in this SMS</h4>
                    <div class="clear"></div>

                    <table v-if="smsDocs.length" class="table is-bordered is-fullwidth">
                        <tr>
                            <th>Title</th>
                            <th>Edit</th>
                        </tr>
                        <tr v-for="(item, index) in smsDocs" :key="index">
                            <td>
                                <span>{{ item.title }}</span>
                            </td>
                            <td>
                                <div class="buttons">
                                    <span class="button is-primary" @click="editItem(item)">Update</span>
                                    <span class="button is-danger" @click="deleteItem(item)">Delete</span>
                                </div>
                            </td>
                        </tr>
                    </table>

                    <div class="spacer s30"></div>

                    <h4 class="icon">Add new hazards from Pooled Hazards</h4>
                    <div class="clear"></div>

                    <div class="clear"></div>

                    <table v-if="poolOfDocs.length" class="table is-bordered is-fullwidth">
                        <tr>
                            <th>Title</th>
                            <!--  <th>Details</th> -->
                            <th>Copy?</th>
                        </tr>
                        <tr v-for="(item, index) in poolOfDocs" :key="index">
                            <td>
                                <span class="show-details" @click="showDetails(item)">{{ item.title }}</span>
                            </td>
                            <!--    <td>
                    <div v-for="line in item.details.split('\n')" :key="line">{{line}}<br></div>
                  </td> -->
                            <td>
                                <input class="is-checkradio" type="checkbox" :id="index" :value="item" v-model="selectedItems" />
                            </td>
                        </tr>
                    </table>

                    <!-- {{selectedItems}} -->

                    <div v-if="selectedItems.length > 0" class="buttons">
                        <button :disabled="selectedVessel == ''" class="button is-danger" @click="copyDocs()">Copy Documents</button>
                    </div>

                    <div class="clear"></div>
                </div>

                <div class="clear"></div>
                <div class="spacer s30"></div>

                <div class="buttons">
                    <span v-if="findName != '' && isSelected" class="button is-primary is-medium" @click="addItem()">Add a Hazard</span>
                    <span class="button is-info is-medium" @click="$router.go(-1)"><font-awesome-icon icon="chevron-left" /> &nbsp;Back</span>
                </div>
            </div>
        </div>

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showDetailsModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary" :class="selectedItem.modalTheme">
                    <p class="modal-card-title">{{ selectedItem.title }}</p>
                    <button class="delete" aria-label="close" @click="close"></button>
                </header>
                <section class="modal-card-body">
                    <table class="table clean">
                        <tr>
                            <th>Item</th>
                            <th>Details</th>
                        </tr>
                        <tr>
                            <td>Hazard</td>
                            <td>{{ selectedItem.title }}</td>
                        </tr>
                        <tr>
                            <td>Posted on</td>
                            <td>{{ formatDate(selectedItem.updatedDate, 'D MMMM (h:mm a)') }}</td>
                        </tr>
                        <tr>
                            <td class="bold">Risk Pairs</td>
                            <td>
                                <div v-if="selectedItem.riskPairs && selectedItem.riskPairs.length > 0">
                                    <div v-for="(item, idx) in selectedItem.riskPairs" :key="`riskpair-${idx}`" class="risk-pair">
                                        <div class="risk-pair-item risk">
                                            <div class="risk-pair-title">Risk</div>
                                            <div class="risk-pair-details">
                                                <div v-for="(line, irx) in item.risk.split('\n')" :key="`line1-${irx}`">{{ line }}<br /></div>
                                            </div>
                                        </div>
                                        <div class="risk-pair-item control">
                                            <div class="risk-pair-title control">Control</div>
                                            <div class="risk-pair-details">
                                                <div v-for="(line, irx) in item.control.split('\n')" :key="`line1-${irx}`">{{ line }}<br /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="selectedItem.risks != ''">
                            <td>Risks</td>
                            <td>
                                <div v-if="selectedItem.risks">
                                    <div v-for="line in selectedItem.risks.split('\n')">{{ line }}<br /></div>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="selectedItem.controls != ''">
                            <td>Controls</td>
                            <td>
                                <div v-if="selectedItem.controls">
                                    <div v-for="line in selectedItem.controls.split('\n')">{{ line }}<br /></div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>RISK RATING (after controls in place)</td>
                            <td>
                                <span :class="selectedItem.newRating" class="rating caps">{{ selectedItem.newRating }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Who's responsible</td>
                            <td>
                                <div class="simple" v-for="person in selectedItem.personResponsible" :id="person">{{ person }}</div>
                            </td>
                        </tr>
                    </table>
                </section>
                <footer class="modal-card-foot has-text-centered">
                    <button class="button" :class="selectedItem.modalTheme" @click="close">Done</button>
                    <!-- <button class="button is-danger" @click="deleteHazard(selectedItem.id)">Delete Hazard</button> -->
                    <!-- <button class="button" :class="selectedItem.modalTheme" @click="editHazard(selectedItem)">Edit Hazard</button> -->
                </footer>
            </div>
        </div>

        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': showEditItemModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">{{ selectedEditItem.title }}</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body view-hazard">
                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Title</label>
                            <div class="spacer s10"></div>
                            <input type="text" class="input" v-model="selectedEditItem.title" />
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Risks</label>
                            <div class="spacer s10"></div>
                            <textarea class="textarea" v-model="selectedEditItem.risks"></textarea>
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Controls</label>
                            <div class="spacer s10"></div>
                            <textarea class="textarea" v-model="selectedEditItem.controls"></textarea>
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <div class="select is-primary">
                            <select v-model="selectedEditItem.newRating">
                                <option disabled value="">Risk Rating</option>
                                <option v-for="rating in ratings" :key="rating">{{ rating }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="checkbox-wrapper">
                        <label class="label">Responsible Persons</label>
                        <div class="spacer s10"></div>
                        <div class="field" v-for="person in persons" :key="person">
                            <input class="is-checkradio" type="checkbox" :id="person" :value="person" v-model="selectedEditItem.personResponsible" />
                            <label :for="person">{{ person }}</label>
                        </div>
                    </div>

                    <div class="clear"></div>

                    <div class="spacer s30"></div>

                    <label class="label">Place Index</label>
                    <input
                        class="input"
                        type="tel"
                        placeholder="999"
                        maxlength="4"
                        v-on:keypress="isNumber($event)"
                        v-model="selectedEditItem.index"
                    />

                    <div class="spacer s30"></div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click.prevent="saveEditItem(selectedEditItem)">Save</button>
                    <button class="button is-danger" @click.prevent="close">Cancel</button>
                </footer>
            </div>
        </div>

        <!-- <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': addItemModal }">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
          <header class="modal-card-head is-primary">
            <p class="modal-card-title">Add an Item</p>
            <button class="delete" aria-label="close" @click.prevent="close"></button>
          </header>
          <section class="modal-card-body">

            <input class="input" type="text" placeholder="Type title..." v-model="newItem.title">

            <div class="spacer s10"></div>
            <textarea class="input textarea" placeholder="Type details..." v-model="newItem.details"></textarea>

            <div class="spacer s30"></div>
            <label class="label">Place Index</label>
            <input class="input" type="tel" placeholder="999" maxlength="4" v-on:keypress="isNumber($event)" v-model="newItem.index">

            <div class="spacer s30"></div>


          </section>
          <footer class="modal-card-foot">
            <button :disabled="newItem.details == '' || newItem.title == ''" class="button is-primary" @click.prevent="saveNewItem()">Save New Item</button>
            <button class="button is-danger" @click.prevent="close">Cancel</button>
          </footer>
        </div>
      </div> -->

        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': addItemModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">Add a new Hazard to this SMS</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Title</label>
                            <div class="spacer s10"></div>
                            <input type="text" class="input" v-model="newItem.title" />
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Risks</label>
                            <div class="spacer s10"></div>
                            <textarea class="textarea" v-model="newItem.risks"></textarea>
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Controls</label>
                            <div class="spacer s10"></div>
                            <textarea class="textarea" v-model="newItem.controls"></textarea>
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <div class="select is-primary">
                            <select v-model="newItem.newRating">
                                <option disabled value="">Risk Rating</option>
                                <option v-for="rating in ratings" :key="rating">{{ rating }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="clear"></div>

                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Responsible Persons</label>
                            <div class="field" v-for="person in allpersons" :key="person.id">
                                <input
                                    class="is-checkradio"
                                    type="checkbox"
                                    :id="person.id"
                                    :value="person.name"
                                    v-model="newItem.personResponsible"
                                />
                                <label :for="person.id">{{ person.name }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="clear"></div>

                    <label class="label">Place Index</label>
                    <input class="input" type="tel" placeholder="999" maxlength="4" v-on:keypress="isNumber($event)" v-model="newItem.index" />

                    <div class="spacer s30"></div>
                </section>
                <footer class="modal-card-foot">
                    <button :disabled="newItem.details == '' || newItem.title == ''" class="button is-primary" @click.prevent="saveNewItem()">
                        Save New Item
                    </button>
                    <button class="button is-danger" @click.prevent="close">Cancel</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {
    name: 'FixSmsHazards',
    components: {},
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            poolOfDocs: [],
            sourcePath: '',
            targetPath: '',
            selectedItems: [],

            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            allVessels: [],
            isSelected: false,
            findName: '',
            hideExpired: true,
            selectedVessel: '',
            selectedItem: '',
            showDetailsModal: false,

            smsDocs: [],
            selectedEditItem: { title: '', details: '', index: 0 },
            showEditItemModal: false,
            addItemModal: false,
            newItem: {
                title: '',
                newRating: '',
                risks: '',
                controls: '',
                personResponsible: [],
                codes: [],
                index: 999
            },
            ratings: ['low', 'medium', 'high'],
            persons: ['The vessel Owner', 'The vessel Master', 'The crew', 'Everybody on board'],
            allpersons: [
                { id: 's1', name: 'The vessel Owner' },
                { id: 's2', name: 'The vessel Master' },
                { id: 's3', name: 'The crew' },
                { id: 's4', name: 'Everybody on board' }
            ]
        };
    },

    computed: {
        userData() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.userData
                ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2'
                    ? true
                    : false
                : false;
        },
        pathsAreValid() {
            return this.sourcePath == '' || this.targetPath == '';
        },
        filteredVessels() {
            return this.hideExpired ? this.allVessels.filter(vessel => !vessel.isExpired).map(vess => vess) : this.allVessels;
        },
        filteredNames() {
            let filter = new RegExp(this.findName, 'i');
            return this.filteredVessels.filter(user => user.vesselName.match(filter));
        },
        selectedItemIDs() {
            return this.selectedItems.map(item => item.id);
        }
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                // console.log(user);
                this.loadAllVessels();
                this.displayDocs();
            }
        },

        close: function() {
            this.showDetailsModal = false;
            this.selectedEditItem = { title: '', details: '', index: 0 };
            this.showEditItemModal = false;
            this.addItemModal = false;
            this.newItem = {
                title: '',
                newRating: '',
                risks: '',
                controls: '',
                personResponsible: [],
                codes: [],
                index: 999
            };
        },

        editItem(item) {
            console.log(item);
            this.selectedEditItem = item;
            this.showEditItemModal = true;
        },

        saveEditItem(itemToSave) {
            let self = this;
            console.log(itemToSave);
            NProgress.start();
            var adminSaveClientHazardItem = firebase.functions().httpsCallable('adminSaveClientHazardItem');
            adminSaveClientHazardItem({
                collection: 'smsHazards',
                vesselID: self.selectedVessel.vesselID,
                docID: itemToSave.id,
                title: itemToSave.title,
                index: itemToSave.index,
                risks: itemToSave.risks,
                controls: itemToSave.controls,
                newRating: itemToSave.newRating,
                personResponsible: itemToSave.personResponsible
            })
                .then(function(result) {
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        },

        deleteItem(item) {
            console.log(item);
            let self = this;
            let message;
            message = {
                title: 'Confirm Delete?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES, DELETE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    var adminDeleteClientSmsItem = firebase.functions().httpsCallable('adminDeleteClientSmsItem');
                    adminDeleteClientSmsItem({
                        vesselID: self.selectedVessel.vesselID,
                        docID: item.id,
                        deleteCollection: 'smsHazards'
                    })
                        .then(function(result) {
                            // self.clearDocs();
                            // self.clearVessel();
                            NProgress.done();
                        })
                        .catch(function(error) {
                            console.log('Error', error.text);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        addItem() {
            this.addItemModal = true;
        },

        isNumber: function($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        saveNewItem() {
            console.log(this.newItem);
            let self = this;
            NProgress.start();
            var adminSaveNewClientHazardItem = firebase.functions().httpsCallable('adminSaveNewClientHazardItem');
            adminSaveNewClientHazardItem({
                collection: 'smsHazards',
                vesselID: self.selectedVessel.vesselID,
                title: self.newItem.title,
                risks: self.newItem.risks,
                controls: self.newItem.controls,
                newRating: self.newItem.newRating,
                personResponsible: self.newItem.personResponsible,
                index: parseInt(self.newItem.index)
            })
                .then(function(result) {
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        },

        displaySmsDocs() {
            console.log(this.selectedVessel.vesselID);
            let self = this;
            db.collection('vessels')
                .doc(this.selectedVessel.vesselID)
                .collection('smsHazards')
                .onSnapshot(querySnapshot => {
                    self.smsDocs = [];
                    querySnapshot.forEach(doc => {
                        self.smsDocs.push({
                            id: doc.id,
                            title: doc.data().title ? doc.data().title : 'no title',
                            index: doc.data().index ? doc.data().index : 999,
                            updatedDate: doc.data().updatedDate ? doc.data().updatedDate : '',
                            risks: doc.data().risks ? doc.data().risks : '',
                            controls: doc.data().controls ? doc.data().controls : '',
                            newRating: doc.data().newRating ? doc.data().newRating : '',
                            riskPairs: doc.data().riskPairs ? doc.data().riskPairs : [],
                            personResponsible: doc.data().personResponsible ? doc.data().personResponsible : [],
                            modalTheme:
                                doc.data().newRating == 'low'
                                    ? 'is-success'
                                    : doc.data().newRating == 'medium'
                                    ? 'is-warning'
                                    : doc.data().newRating == 'high'
                                    ? 'is-danger'
                                    : 'is-info'
                        });
                    });
                });
        },

        displayDocs() {
            let self = this;
            NProgress.start();
            db.collection('helperFullHazards')
                .get()
                .then(querySnapshot => {
                    self.poolOfDocs = [];
                    querySnapshot.forEach(doc => {
                        self.poolOfDocs.push({
                            id: doc.id,
                            title: doc.data().title ? doc.data().title : 'no title',
                            index: doc.data().index ? doc.data().index : 999,
                            updatedDate: doc.data().updatedDate ? doc.data().updatedDate : '',
                            risks: doc.data().risks ? doc.data().risks : '',
                            controls: doc.data().controls ? doc.data().controls : '',
                            newRating: doc.data().newRating ? doc.data().newRating : '',
                            riskPairs: doc.data().riskPairs ? doc.data().riskPairs : [],
                            personResponsible: doc.data().personResponsible ? doc.data().personResponsible : [],
                            modalTheme:
                                doc.data().newRating == 'low'
                                    ? 'is-success'
                                    : doc.data().newRating == 'medium'
                                    ? 'is-warning'
                                    : doc.data().newRating == 'high'
                                    ? 'is-danger'
                                    : 'is-info'
                        });
                    });
                })
                .then(() => {
                    NProgress.done();
                });
        },

        clearDocs() {
            // this.poolOfDocs = [];
            // this.sourcePath = '';
            // this.targetPath = '';
            this.selectedItems = [];
        },

        clearVessel: function() {
            this.findName = '';
            this.isSelected = false;
            this.selectedVessel = '';
        },

        selectVessel(vessel) {
            console.log(vessel);
            this.selectedVessel = vessel;
            this.isSelected = true;
            this.displaySmsDocs();
        },

        showDetails(item) {
            console.log(item);
            this.selectedItem = item;
            this.showDetailsModal = true;
        },

        copyDocs() {
            let self = this;

            console.log(this.selectedVessel.vesselID);
            console.log(this.selectedItemIDs);

            let message;
            message = {
                title: 'Confirm Copy Documents?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    var adminFixHazards = firebase.functions().httpsCallable('adminFixHazards');
                    adminFixHazards({
                        vesselID: self.selectedVessel.vesselID,
                        selectedItemIDs: self.selectedItemIDs
                    })
                        .then(function(result) {
                            self.clearDocs();
                            // self.clearVessel();
                            NProgress.done();
                        })
                        .catch(function(error) {
                            console.log('Error', error.text);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        loadAllVessels: function() {
            let self = this;
            db.collection('vessels')
                .orderBy('vesselOwnerName', 'asc')
                .onSnapshot(function(querySnapshot) {
                    self.allVessels = [];
                    querySnapshot.forEach(doc => {
                        //let auditD = (doc.data().lastAuditDate ? doc.data().lastAuditDate.toDate() : 'vvv');
                        //console.log(auditD);
                        self.allVessels.push({
                            vesselID: doc.id,
                            vesselName: doc.data().vesselName,
                            // lastAuditID: (doc.data().lastAuditID ? doc.data().lastAuditID : 'NA'),
                            //lastAuditDate: (doc.data().lastAuditDate ? doc.data().lastAuditDate.toDate() : 'Never'),
                            // lastAuditDate: (doc.data().lastAuditDate ? self.formatDate(doc.data().lastAuditDate,'dddd D MMMM (h:mm a)') : 'Never'),
                            // sinceDate: (doc.data().lastAuditDate ? moment(doc.data().lastAuditDate.toDate()).fromNow() : 'no audit'),
                            // lastAuditPercent: (doc.data().lastAuditPercent ? doc.data().lastAuditPercent : 0),
                            // lastAuditCode: (doc.data().lastAuditCode ? doc.data().lastAuditCode : 0),
                            // hasBeenCounted: false,
                            vesselOwnerName: doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?',
                            vesselOwnerID: doc.data().vesselOwnerID
                            // isCompanyVessel: doc.data().isCompanyVessel ? doc.data().isCompanyVessel : false,

                            // isPaidFor: doc.data().isPaidFor ? doc.data().isPaidFor : false,
                            // isDemo: doc.data().isDemo ? doc.data().isDemo : false,
                            // lastChargeDate: doc.data().lastChargeDate ? doc.data().lastChargeDate.toDate().toISOString() : '',
                            // lastVesselCharge: doc.data().lastVesselCharge ? doc.data().lastVesselCharge : 0,
                            // nextChargeDate: doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate().toISOString() : '',
                            // nextVesselCharge: doc.data().nextVesselCharge ? doc.data().nextVesselCharge : 149,
                            // billingUpdated: doc.data().billingUpdated ? doc.data().billingUpdated : false,
                            // adminNotes: doc.data().adminNotes ? doc.data().adminNotes : false,
                            // isExpired: doc.data().isExpired ? doc.data().isExpired : false,
                            // indexesUpdated: doc.data().indexesUpdated ? doc.data().indexesUpdated.toDate() : '',
                            // sinceIndexesUpdated: (doc.data().indexesUpdated ? moment(doc.data().indexesUpdated.toDate()).fromNow() : '--'),
                        });
                    });
                });
        },

        formatDate: function(sentDate, format) {
            let fixDate = sentDate ? sentDate.toDate() : new Date();
            //console.log("Fix date:",fixDate);
            return moment(fixDate).format(format);
        }
    }
};
</script>

<style lang="scss" scoped>
.site-wrapper .container {
    max-width: 850px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
.traffic-light {
    position: relative;
    span {
        float: left;
        margin-right: 10px;
    }
    .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
            background: red;
            &.active {
                background: red;
            }
        }
        &.orange {
            background: orange;
            &.active {
                background: orange;
            }
        }
        &.green {
            background: #03d203;
            &.active {
                background: #03d203;
            }
        }
    }
}

// input[type="text"] {
//   width: 90%;
//   float: left;
//   display: block;
//   margin-bottom: 2rem;
//   // &:after {
//   //   content: "\f16c";
//   //   font-family: "Font Awesome 5 Brands";
//   //   color: red;
//   // }
//   // &.valid:after {

//   // }
// }
.icon-wrap {
    display: block;
    float: left;
    height: 35px;
    margin-left: 10px;
    font-size: 1.5rem;
    color: red;
    &.valid {
        color: green;
    }
}
label {
    margin-bottom: 10px;
    display: block;
}
.is-checkradio[type='checkbox'] {
    opacity: 1;
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
    padding: 10px;
    margin-top: 10px;
    margin-left: 10px;
}

.site-wrapper .container {
    max-width: 1050px;
}
.buttons {
    margin-bottom: 5px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
input[type='text'] {
    padding: 2rem 1rem;
}
.form-group {
    label {
        top: auto;
        bottom: -25px;
    }
}

.risk-pair {
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .risk-pair-item {
        .risk-pair-title {
            font-size: 0.8rem;
            color: red;
            text-transform: uppercase;
            &.control {
                color: mediumseagreen;
                margin-top: 8px;
            }
        }
        .risk-pair-details {
            font-size: 0.9rem;
        }
    }
    &:last-of-type {
        border: none;
    }
}
.view-hazard {
    text-align: left;
    .item {
        margin-bottom: 5px;
        span {
            text-transform: uppercase;
            font-weight: bold;
            margin-right: 7px;
            &.simple {
                text-transform: none;
                font-weight: normal;
                &:after {
                    content: ', ';
                }
                &:last-of-type {
                    &:after {
                        content: '';
                    }
                }
            }
            &.rating {
                padding: 3px;
                &.low {
                    background-color: #23d160;
                    color: #fff;
                }
                &.medium {
                    background-color: #ffdd57;
                    color: rgba(0, 0, 0, 0.7);
                }
                &.high {
                    background-color: #ff3860;
                    color: #fff;
                }
            }
        }
    }
}
.highlight {
    background: dodgerblue;
    padding: 15px;
    color: #fff;
}
.modal {
    .modal-card {
        .modal-card-head {
            z-index: 999 !important;
        }
        .modal-card-foot {
            z-index: 999 !important;
        }
    }
}
input[type='text'],
input[type='tel'] {
    padding: 2rem 1rem;
}

textarea {
    min-height: 120px;
    height: 400px;
    margin-top: 10px;
    padding: 1.5rem 1rem;
}
table {
    td {
        padding-bottom: 21px;
        &.is-updated {
            color: green;
            font-weight: bold;
        }
        .small {
            font-size: 0.8rem;
            margin-top: 5px;
            text-align: center;
        }
    }
}
.show-details {
    cursor: pointer;
    &:hover {
        color: dodgerblue;
    }
}
</style>
