<template>
    <div class="site-wrapper no-header">
        <div class="container">
            <div class="notification is-info"><h3>Move a Personal Vessel to a Fleet</h3></div>

            <!--  find the target fleet ============================================  -->
            <div v-if="!isSelected" class="notification is-primary">
                <input class="input" v-model="findName" id="filtername" autocomplete="off" type="text" placeholder="Start typing Fleet name..." />
            </div>

            <div v-if="isSelected" class="highlight">{{ selectedFleet.companyName }}</div>

            <div v-if="findName.length > 2 && !isSelected">
                <div v-for="(fleet, index) in filteredNames" :key="index">
                    <div class="buttons results">
                        <span class="button is-light" @click="selectFleet(fleet)">{{ fleet.companyName }} ({{ fleet.primaryUserName }})</span>
                    </div>
                </div>
            </div>

            <!--  find the source vessel ============================================  -->
            <div v-if="isSelected">
                <div v-if="!vesselSelected" class="notification is-primary">
                    <input
                        class="input"
                        v-model="findVessel"
                        id="filtername"
                        autocomplete="off"
                        type="text"
                        placeholder="Start typing vessel name..."
                    />
                </div>

                <div v-if="vesselSelected" class="highlight">{{ selectedVessel.vesselName }}</div>

                <ul v-if="vesselSelected">
                    <li>isCompanyVessel: {{ selectedVessel.isCompanyVessel ? 'YES!' : 'No' }}</li>
                    <li>Owner: {{ selectedVessel.vesselOwnerName }}</li>
                    <li>Owner ID: {{ selectedVessel.vesselOwnerID }}</li>
                    <li>Fleet Admins: {{ selectedFleet.adminUsers.join(', ') }}</li>
                </ul>

                <div v-if="findVessel.length > 2 && !vesselSelected">
                    <div v-for="(vessel, index) in filteredVesselNames" :key="index">
                        <div class="buttons">
                            <span class="button is-light" @click="selectVessel(vessel)"
                                >{{ vessel.vesselName }} ({{ vessel.vesselID }}) - {{ vessel.vesselOwnerName }}</span
                            >
                        </div>
                    </div>
                </div>

                <div class="spacer s30"></div>

                <div v-if="findVessel.length > 2 && vesselSelected" class="buttons">
                    <button :disabled="showSave" class="button is-skip is-medium" @click="moveVessel()">Move Vessel to Fleet</button>
                </div>
            </div>

            <hr />

            <div v-if="isSelected" class="buttons">
                <span class="button is-warning" @click="clearAll()">Clear</span>
            </div>

            <!-- <div class="posi">{{finalCodes}}<br>{{codeGroups}}</div> -->
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as moment from 'moment';
import NProgress from 'nprogress';

export default {
    name: 'AdminAddFleetVessel',

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        filteredNames() {
            let filter = new RegExp(this.findName, 'i');
            return this.fleets.filter(fleet => fleet.companyName.match(filter));
        },
        filteredVessels() {
            return this.hideExpired ? this.allVessels.filter(vessel => !vessel.isExpired).map(vess => vess) : this.allVessels;
        },
        filteredVesselNames() {
            let filter = new RegExp(this.findVessel, 'i');
            return this.filteredVessels.filter(vessel => vessel.vesselName.match(filter));
        },
        showSave() {
            return this.selectedVessel.isCompanyVessel;
        }
    },

    data() {
        return {
            fleets: [],
            isSelected: false,
            findName: '',
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            selectedFleet: {},

            users: [],
            findVessel: '',
            userSelected: false,

            vesselSelected: false,
            selectedVessel: {},
            vesselInfo: {},

            newVesselID: '',
            addFasttrack: false
        };
    },

    mounted() {
        // this.$store.dispatch('setUser');
        // this.$store.dispatch('SETSUBSCRIPTIONINFO');
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                this.firebaseemail = user.email;
                this.firebaseid = user.uid;
                this.firebasedisplayname = this.userData.companyName;
                this.loadFleets();
                this.loadAllVessels();
            }
        },

        loadFleets: function() {
            let self = this;
            db.collection('companies').onSnapshot(function(querySnapshot) {
                self.fleets = [];
                querySnapshot.forEach(function(doc) {
                    // self.fleets.push(doc.data())
                    self.fleets.push({
                        fleetID: doc.id,
                        fleetName: doc.data().companyName,
                        companyID: doc.id,
                        companyName: doc.data().companyName,
                        primaryUserName: doc.data().primaryUserName ? doc.data().primaryUserName : '',
                        primaryUserPhone: doc.data().primaryUserPhone ? doc.data().primaryUserPhone : '',
                        primaryUserEmail: doc.data().primaryUserEmail ? doc.data().primaryUserEmail : '',
                        adminUsers: doc.data().adminUsers ? doc.data().adminUsers : []
                    });
                });
            });
        },

        loadAllVessels: function() {
            let self = this;
            db.collection('vessels')
                .orderBy('vesselOwnerName', 'asc')
                .onSnapshot(function(querySnapshot) {
                    self.allVessels = [];
                    querySnapshot.forEach(doc => {
                        self.allVessels.push({
                            vesselID: doc.id,
                            vesselUVI: doc.data().vesselUVI ? doc.data().vesselUVI : '',
                            vesselName: doc.data().vesselName,
                            vesselOwnerName: doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?',
                            vesselOwnerID: doc.data().vesselOwnerID,
                            isCompanyVessel: doc.data().isCompanyVessel ? doc.data().isCompanyVessel : false
                        });
                    });
                });
        },

        clearAll() {
            this.vesselSelected = false;
            this.findVessel = '';
            this.userSelected = false;
            this.selectedVessel = '';
            this.findName = '';
            this.isSelected = false;
            this.selectedFleet = '';
        },

        selectFleet(fleet) {
            // console.log(fleet);
            this.isSelected = true;
            this.selectedFleet = fleet;
        },

        selectVessel(vessel) {
            // NProgress.start();
            // console.log(vessel);
            let self = this;
            this.selectedVessel = vessel;
            this.vesselSelected = true;
            setTimeout(() => {
                self.displayInfo();
            }, 1000);
        },

        displayInfo() {
            // console.log(this.selectedVessel.vesselID);
            let self = this;
            if (this.vesselSelected)
                db.collection('vessels')
                    .doc(this.selectedVessel.vesselID)
                    .get()
                    .then(doc => {
                        // console.log(doc.data());
                        self.vesselInfo.description = doc.data().description ? doc.data().description : '';
                        self.vesselInfo.homePort = doc.data().homePort ? doc.data().homePort : '';
                        self.vesselInfo.selectedStates = doc.data().vesselStates ? doc.data().vesselStates : [];
                        self.vesselInfo.selectedIndustries = doc.data().vesselIndustries ? doc.data().vesselIndustries : [];
                        self.vesselInfo.selectedClasses = doc.data().surveyClasses ? doc.data().surveyClasses : [];
                        self.vesselInfo.selectedAreas = doc.data().operationalAreas ? doc.data().operationalAreas : [];
                        self.vesselInfo.vesselMaster = doc.data().vesselMaster ? doc.data().vesselMaster : '';
                        self.vesselInfo.shoreContactName = doc.data().shoreContactName ? doc.data().shoreContactName : '';
                        self.vesselInfo.shoreContactNumber = doc.data().shoreContactNumber ? doc.data().shoreContactNumber : '';
                        self.vesselInfo.vesselCrew = doc.data().vesselCrew ? doc.data().vesselCrew : '';
                        self.vesselInfo.appCrewExplanation = doc.data().appCrewExplanation ? doc.data().appCrewExplanation : '';
                        self.vesselInfo.vesselOwnerName = doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '';
                        self.vesselInfo.vesselOwnerPhone = doc.data().vesselOwnerPhone ? doc.data().vesselOwnerPhone : '';
                        self.vesselInfo.vesselOwnerEmail = doc.data().vesselOwnerEmail ? doc.data().vesselOwnerEmail : '';
                    })
                    .then(() => {
                        NProgress.done();
                    });
        },

        moveVessel() {
            let self = this;
            console.log(this.selectedFleet);
            console.log(this.selectedVessel);

            let message;
            message = {
                title: 'Confirm Move',
                body: "You're moving the vessel " + self.selectedVessel.vesselName + ' to a fleet called ' + self.selectedFleet.companyName
            };
            let options = {
                okText: 'Yes, MOVE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function() {
                    NProgress.start();

                    var moveVesselToFleet = firebase.functions().httpsCallable('moveVesselToFleet');
                    moveVesselToFleet({
                        sourceVessel: self.selectedVessel,
                        targetFleet: self.selectedFleet,
                        userName: self.selectedVessel.vesselOwnerName,
                        userID: self.selectedVessel.vesselOwnerID
                    });

                    var addVesselRevision = firebase.functions().httpsCallable('addVesselRevision');
                    addVesselRevision({
                        userName: 'The OffshoreSMS Administrator',
                        userID: '/',
                        vesselName: self.selectedVessel.vesselName,
                        vesselID: self.selectedVessel.vesselID,
                        revisionDetails:
                            'The OffshoreSMS Administrator has moved the vessel ' +
                            self.selectedVessel.vesselName +
                            ' to a fleet called ' +
                            self.selectedFleet.companyName
                    });

                    setTimeout(() => {
                        self.clearAll();
                        NProgress.done();
                    }, 1000);
                })
                .catch(function() {
                    self.clearAll();
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.highlight {
    background: cadetblue;
    padding: 15px;
    color: #fff;
}
.posi {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: #fff;
    z-index: 999;
}
label {
    &.label {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 10px;
        &.tight {
            margin-bottom: 4px;
        }
    }
}
.buttons.results {
    margin-bottom: 4px;
}
</style>
