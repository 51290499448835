<template>
	
	<div>

			<div class="site-wrapper no-header">
			
				<div class="container">

						<h3 class="is-size-5">All OffshoreSMS Companies</h3><br>

            <!-- <p>Billable: {{billableVesselCount}} ({{billableVesselCount * 249}})</p>
            <p>&nbsp;</p> -->

						<span class="button" @click="copyAllSettings()">Copy all QR settings</span>{{selectedVessels}}

						<table v-for="(company, index) in allCompanies" :key="index" class="table is-bordered is-fullwidth">
							<tr>
                <th>Company name</th>
                <td>{{company.companyName}}</td>
              </tr>
              <!-- <tr>
                <th>Company ID</th>
                <td>{{company.id}}</td>
              </tr> -->
              <tr>
                <th>Company State</th>
                <td>{{company.companyState}}</td>
              </tr>
              <tr>
                <th>Primary User</th>
                <td>{{company.primaryUserName}}</td>
              </tr>
              <tr>
                <th>Company Vessels</th>
                <td>
                  <div v-for="(vessel, ind) in company.companyVessels" :key="ind">
                    <span class="button is-light" @click="loadVesselData(vessel)">{{ind+1}} - {{vessel.vesselName}}</span>
                    <input class="is-checkradio" type="checkbox" :id="'index_id_' + vessel.vesselID" :value="vessel.vesselID" v-model="selectedVessels" />
                    <label :for="'index_id_' + vessel.vesselID"> </label>
                    <!-- <div>Due: {{formatDate(vessel.nextChargeDate,'DD MMMM, YYYY')}}</div> -->
                    <!-- <div>{{company.companyVessels.map((v) => v.vesselName).join(',')}}</div> -->
                  </div>
                </td>
              </tr>
              <tr>
                <th>Update Fleet Indexes</th>
                <td>
                  <button class="button is-small is-danger" @click="updateFleetItemIndexes(company.id)">Update Indexes</button>
                  <div class="small" v-if="company.sinceIndexesUpdated != ''">{{company.sinceIndexesUpdated}}</div>
                </td>
              </tr>
						</table>


				</div>

			</div>


			<div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showVessel}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Company Vessel Info &nbsp;<span class="demo" v-if="selectedVessel.isDemo"><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Demo vessel</span></p>
              <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>
            <section class="modal-card-body">   

               <!--  {{selectedVessel}}     -->
            	
            	<div v-if="!isGettingData">

                <div v-if="selectedVessel.isPast">
                  <div class="expired"><font-awesome-icon icon="exclamation-triangle" /> Vessel subscription expired {{selectedVessel.remaining}}</div>
                </div>

                <div v-if="selectedVessel.isDue && !selectedVessel.isPast" class="due"><font-awesome-icon icon="exclamation-triangle" /> Vessel {{selectedVessel.isDemo ? 'trial period' : 'subscription'}} expires {{selectedVessel.remaining}}</div>

							  <table class="table is-fullwidth">
                  <tr>
                    <th>Company</th>
                    <td>{{selectedVessel.vesselOwnerName}}</td>
                  </tr>
                   <tr>
                    <th>Paid For?</th>
                    <td>{{selectedVessel.isPaidFor ? 'Yes' : 'No'}}</td>
                  </tr>
                  <tr>
                    <th>Billable?</th>
                    <td>{{selectedVessel.isBillable ? 'Yes' : 'No'}}</td>
                  </tr>
                  <tr>
                    <th>Demo mode?</th>
                    <td>{{selectedVessel.isDemo ? 'Yes' : 'No'}}</td>
                  </tr>
                  <tr>
                    <th>Last Charge</th>
                    <td>{{selectedVessel.lastVesselCharge}}</td>
                  </tr>
                  <tr>
                    <th>Last charge date</th>
                    <td>{{formatDate(selectedVessel.lastChargeDate,'DD MMM YYYY (H:mm a)')}}</td>
                  </tr>
                  <tr>
                    <th>Next Charge</th>
                    <td>{{selectedVessel.nextVesselCharge}}</td>
                  </tr>
                  <tr>
                    <th>Next charge date</th>
                    <td>{{formatDate(selectedVessel.nextChargeDate,'DD MMM YYYY (H:mm a)')}}</td>
                  </tr>

							  </table>

                <p>Vessel Name: {{selectedVessel.vesselName}}</p>
                <p>Vessel ID: {{selectedVessel.vesselID}}</p>


            	</div>

              <div v-else>
                <font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading vessel data for {{selectedVessel.vesselName}}...
                <div class="spacer s10"></div>
                <p v-if="errorMsg != ''">Error: {{errorMsg}}</p>
              </div>

              <div class="spacer s30">
                
              </div>


            </section>
            <footer class="modal-card-foot">	  
              <button v-if="isSteve" class="button is-warning" @click="updateVesselData(selectedVessel)">Update Vessel</button>
              <button class="button is-primary" @click="close">Done</button>
            </footer>
        </div>
      </div>




	</div>

</template>


<script> 

	import firebase from 'firebase/compat/app';
	import { db } from '../main.js';
	import 'firebase/compat/auth'; 
	import 'firebase/firestore';
	import 'firebase/compat/functions';
	import NProgress from 'nprogress';	
	import * as moment from 'moment';

	// const UpdateAudit = () => import('./UpdateAudit.vue')

	export default {

		name: 'AllCompanies',
		components: {
	  	// UpdateAudit
	  },
		mounted: function() {
			firebase.auth().onAuthStateChanged( this.onUserLogin );	
		},

		data: function() {
			return {
				allVessels: [],
				selectedVessel: {},
        showVessel: false,
        allCompanies: [],
        isGettingData: false,
        errorMsg: '',
        selectedVessels: [],
			}
		},

		computed: {
			user() {
	    	return this.$store.getters.getUser;
	    },
      isSteve() {
        return this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false;
      },
      billableVesselCount() {
        return this.allVessels.filter((vessel) => vessel.isBillable).length;
      }
		},

		methods: {

			onUserLogin: function( user ) {
      	if (user) {      		
          this.loadAllCompanies();
	      }
      },

      close: function() {
        this.showVessel = false;
      	this.selectedVessel = {};
        this.isGettingData = false;
      },

      copyAllSettings() {
        let self = this;
        NProgress.start();
        let updateData = {
          savedCompanyPassengerInductionItems: ['EayDU6fWeI5Cc3W643Zd','WYQz1u5BIZ2aXAFhN3i9','haYynbw0tlxxwdDOpmHC','mtwCO6eJ8m3hMDXZOJYG','qScgF3QM8FlyGJx4kVyu','s7LCpeSQ4OOIY5fh2Ypy','sboLKTlEund8Ccu8WFRN'],
          savedAppInfoSections: ['Safety Information','Visitor Check-in','Passenger Manifests','Vessel Information','General Safety Briefing']
        }
        this.selectedVessels.forEach((vessel) => {
          db.collection('vessels').doc(vessel).update(updateData).catch((error) => console.log('Error!',error));
        });
        setTimeout(() => {
          NProgress.done();
        }, 2000);
      },

      async loadAllCompanies() {
        let self = this;
        NProgress.start();
        db.collection('companies').orderBy('companyName','asc').get()
        .then((querySnapshot) => {
          self.allCompanies = [];
          querySnapshot.forEach((doc) => {
            self.allCompanies.push({
              id: doc.id,
              companyName: doc.data().companyName,
              companyState: doc.data().companyState,
              primaryUserName: doc.data().primaryUserName,
              primaryUserEmail: doc.data().primaryUserEmail,
              companyPhone: doc.data().companyPhone,
              primaryUserPhone: doc.data().primaryUserPhone,
              sinceIndexesUpdated: doc.data().indexesUpdated ? moment(doc.data().indexesUpdated.toDate()).fromNow() : '--',
              companyVessels: [],
            });
          });
        })
        .then(async () => {
          await Promise.all(self.allCompanies.map(async (company, index) => {
            await db.collection('companies').doc(company.id).collection('companyVessels').get().then((snap) => {
              snap.forEach((vessel) => {
                self.allCompanies[index].companyVessels.push({
                  vesselName: vessel.data().vesselName,
                  vesselID: vessel.data().vesselID,
                  vesselUVI: vessel.data().vesselUVI,
                });
              })
            });
          }));
        })
        .then(() => {
          NProgress.done();
        });
      },


      updateFleetItemIndexes: function(companyID) {

        let self = this;
        let message;
        message = {
          title: 'Update all fleet item indexes?',
          body: 'Click YES below to proceed.'
        };
        let options = {
          okText: 'YES',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(function(dialog) {
              
              NProgress.start();
              var AdminUpdateFleetItemIndexes = firebase.functions().httpsCallable('AdminUpdateFleetItemIndexes'); 
              AdminUpdateFleetItemIndexes({
                companyID: companyID,
              })
              .then(function(result) {
                // var sanitizedMessage = result.data.text;
                // console.log('Result',sanitizedMessage);
                console.log('Message',result.data.message);
                NProgress.done();

              })
              .catch(function(error) {
                console.log('Error',error.text);
                NProgress.done();
              }); 


          })
          .catch(function(error) {
            console.log(error); 
            NProgress.done();
          });         

      },


      // loadAllVessels: function() {
      // 	let self = this;
      //   let cutoffDate = moment().add(1, 'months');
      // 	db.collection('vessels').orderBy("vesselName", "asc").where('isCompanyVessel','==',true).onSnapshot(function(querySnapshot) {
      // 		self.allVessels = [];
      // 		querySnapshot.forEach((doc) => {
      // 			//let auditD = (doc.data().lastAuditDate ? doc.data().lastAuditDate.toDate() : 'vvv');
      // 			//console.log(auditD);
      // 			self.allVessels.push({
      // 				vesselID: doc.id,
      // 				vesselName: doc.data().vesselName,
      // 				// lastAuditID: (doc.data().lastAuditID ? doc.data().lastAuditID : 'NA'),
      // 				//lastAuditDate: (doc.data().lastAuditDate ? doc.data().lastAuditDate.toDate() : 'Never'),
      // 				// lastAuditDate: (doc.data().lastAuditDate ? self.formatDate(doc.data().lastAuditDate,'dddd D MMMM (h:mm a)') : 'Never'),
      // 				// sinceDate: (doc.data().lastAuditDate ? moment(doc.data().lastAuditDate.toDate()).fromNow() : 'no audit'),
      // 				// lastAuditPercent: (doc.data().lastAuditPercent ? doc.data().lastAuditPercent : 0),
      // 				// lastAuditCode: (doc.data().lastAuditCode ? doc.data().lastAuditCode : 0),
      // 				// hasBeenCounted: false,
      // 				vesselOwnerName: (doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?'),
      // 				vesselOwnerID: doc.data().vesselOwnerID,
      //         isBillable: doc.data().isBillable ? doc.data().isBillable : false,
      //         isPaidFor: doc.data().isPaidFor ? doc.data().isPaidFor : false,
      //         lastVesselCharge: doc.data().lastVesselCharge ? doc.data().lastVesselCharge : 0,
      //         nextVesselCharge: doc.data().nextVesselCharge ? doc.data().nextVesselCharge : 0,
      //         nextChargeDate: doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate() : '',
      //         lastChargeDate: doc.data().lastChargeDate ? doc.data().lastChargeDate.toDate() : '',
      //         remaining: doc.data().nextChargeDate ? moment().to(doc.data().nextChargeDate.toDate()) : '',
      //         isPast: doc.data().nextChargeDate ? moment().isAfter(moment(doc.data().nextChargeDate.toDate())) : false,
      //         isDue: doc.data().nextChargeDate ? moment(cutoffDate).isAfter(moment(doc.data().nextChargeDate.toDate())) : false,
      //         isDemo: doc.data().isDemo ? doc.data().isDemo : false,
      //         vesselType: !doc.data().isBillable ? 'free' : doc.data().isDemo ? 'demo' : 'billable',
      //         expiryStatus: moment().isAfter(moment(doc.data().nextChargeDate.toDate())) ? 'expired' : moment(cutoffDate).isAfter(moment(doc.data().nextChargeDate.toDate())) ? 'due' : 'current',
      // 			});
      // 		});
      // 	});
      // },

      formatDate: function(sentDate, format) {
        // let fixDate = sentDate ? sentDate.toDate() : new Date();
        return moment(sentDate).format(format);
      },

      loadVesselData(vessel) {
        let self = this;
        let cutoffDate = moment().add(1, 'months');
        this.isGettingData = true;
        this.selectedVessel.vesselName = vessel.vesselName;
        this.selectedVessel.vesselID = vessel.vesselID;
        this.showVessel = true;
        db.collection('vessels').doc(vessel.vesselID).get().then((doc) => {
          self.selectedVessel.vesselOwnerName = doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?';
          self.selectedVessel.vesselOwnerID = doc.data().vesselOwnerID ? doc.data().vesselOwnerID : '';
          self.selectedVessel.isBillable = doc.data().isBillable ? doc.data().isBillable : false;
          self.selectedVessel.isPaidFor = doc.data().isPaidFor ? doc.data().isPaidFor : false;
          self.selectedVessel.lastVesselCharge = doc.data().lastVesselCharge ? doc.data().lastVesselCharge : 0;
          self.selectedVessel.nextVesselCharge = doc.data().nextVesselCharge ? doc.data().nextVesselCharge : 0;
          self.selectedVessel.nextChargeDate = doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate() : '';
          self.selectedVessel.lastChargeDate = doc.data().lastChargeDate ? doc.data().lastChargeDate.toDate() : '';
          self.selectedVessel.remaining = doc.data().nextChargeDate ? moment().to(doc.data().nextChargeDate.toDate()) : '';
          self.selectedVessel.isPast = doc.data().nextChargeDate ? moment().isAfter(moment(doc.data().nextChargeDate.toDate())) : false;
          self.selectedVessel.isDue = doc.data().nextChargeDate ? moment(cutoffDate).isAfter(moment(doc.data().nextChargeDate.toDate())) : false;
          self.selectedVessel.isDemo = doc.data().isDemo ? doc.data().isDemo : false;
          self.selectedVessel.vesselType = !doc.data().isBillable ? 'free' : doc.data().isDemo ? 'demo' : 'billable';
          self.selectedVessel.expiryStatus = doc.data().nextChargeDate ? moment().isAfter(moment(doc.data().nextChargeDate.toDate())) ? 'expired' : moment(cutoffDate).isAfter(moment(doc.data().nextChargeDate.toDate())) ? 'due' : 'current' : 'unknown';
        })
        .then(() => {
          self.isGettingData = false;
        })
        .catch((err) => {
          console.log('Error',err);
          self.errorMsg = err;
        });
      },

      updateVesselData(selectedVessel) {
        console.log(selectedVessel);
        this.$router.push({
          name: 'UpdateVessel',
          params: {
            sentVessel: selectedVessel
          }
        });
      },






		},

	}



</script> 




<style lang="scss" scoped>
	
  .site-wrapper .container {
    max-width: 850px;
  }
	.button {
		margin-top: 0;
    &.is-skip {
      background-color: #ff7878;
      color: #fff;
      border-color: #fff;
    }
    &.is-light {
      margin-bottom: 10px;
    }
	}
  .expired {
    color: red;
    margin-bottom: 1rem;
  }
  .due {
    color: darkorange;
    margin-bottom: 1rem;
  }
	.traffic-light {
	  position: relative;
	  span {
	  	float: left;
	  	margin-right: 10px;
	  }
	  .light {
	  	border-radius: 3px;
	  	display: inline-block;
	    color: #fff;
	    font-size: 0.8rem;
	    padding: 3px 10px;
	  	&.red {
	  		background: red;
	  		&.active {
	  			background: red;
	  		}
	  	}
	  	&.orange {
	  		background: orange;
	  		&.active {
	  			background: orange;
	  		}
	  	}
	  	&.green {
	  		background: #03d203;
	  		&.active {
	  			background: #03d203;
	  		}
	  	}
	  }
	}
  .table {
    margin-bottom: 3rem;
  }

</style> 