<template>
    <div class="site-wrapper no-header">
        <div class="container">
            <h4>Find a Conversation</h4>

            <div class="">
                <input type="text" class="input padded" v-model="searchNumber" placeholder="Enter mobile (+614...)" />
            </div>
            <div class="spacer s20"></div>

            <div v-if="searchNumber != ''">
                <div v-if="loadingConversation"><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Finding conversation ...</div>
                <div v-if="!loadingConversation && searchNumber != '' && convoLoaded" class="box">
                    <div v-if="!hasConversation">No conversation found.</div>
                    <div v-else>
                        <table class="table is-fullwidth">
                            <tr>
                                <th>Name</th>
                                <td>{{ conversationData.contactName }}</td>
                            </tr>
                            <tr>
                                <th>Date Added</th>
                                <td>{{ simpleDate(conversationData.dateAdded.toDate()) }}</td>
                            </tr>
                        </table>

                        <div class="spacer s10"></div>
                    </div>
                </div>

                <div v-if="!loadingConversation && convoLoaded">
                    <div class="">
                        <div v-if="loadingMessages"><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading messages...</div>
                        <div v-if="hasConversation && !loadingMessages">
                            <div v-if="messages.length > 0">
                                <div
                                    @click="selectedMessage = msg"
                                    v-for="(msg, idx) in messages"
                                    :key="`msg-${msg.id}`"
                                    class="message-wrapper"
                                    :class="msg.type"
                                >
                                    <div class="notification">
                                        <!-- <div class="msg-type">{{ msg.type == 'incoming' ? 'received' : 'sent' }}</div> -->
                                        <div class="heading" :class="msg.type">
                                            {{ msg.type == 'incoming' ? 'received' : 'sent' }} {{ simpleDate(msg.timestamp.toDate()) }}
                                        </div>
                                        <div class="message">{{ msg.message }}</div>
                                        <div v-if="msg.allMedia && msg.allMedia.length > 0" class="thumbnails">
                                            <div v-for="(img, imx) in msg.allMedia" class="thumb">
                                                <img :src="img.url" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <p>No messages sent or received yet.</p>
                            </div>
                        </div>
                    </div>
                    <div class="spacer s10"></div>
                </div>
            </div>

            <div v-else>
                <div class="spacer s10"></div>
                <h4>All Customer Conversations</h4>

                <div v-if="loadingAllConversations"><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading conversations...</div>
                <div v-else class="">
                    <div v-for="(convo, idx) in allConversations" class="notification">
                        <div class="heading">
                            <span class="bold">{{ convo.contactName }}:</span> {{ simpleDate(convo.dateAdded.toDate()) }}
                        </div>
                        <div class="buttons tight">
                            <div v-if="convo.webLink" class="button is-small is-info" @click="toWebPage(convo.webLink)">View Welcome Page</div>
                            <div v-if="!convo.webLink && convo.contactName" class="button is-small is-skip" @click="createWebLink(convo)">
                                Create Welcome Page
                            </div>
                            <div class="button is-small is-dark" @click="updateConvo(convo)">&nbsp;<font-awesome-icon icon="edit" /></div>
                            <div class="button is-small is-primary" @click="loadConversation(convo)">Load Conversation</div>
                        </div>
                        <div v-if="!convo.lastMessageRead" class="green"><font-awesome-icon icon="star" />&nbsp; New Messages</div>
                    </div>
                </div>
            </div>

            <div class="spacer s100"></div>

            <div class="buttons floating">
                <span v-if="searchNumber != '' && !loadingConversation" class="button is-info" @click="loadConversationData()">
                    {{ initialConvoLoaded ? 'Reload Conversation' : 'Find Conversation' }}
                </span>
                <button
                    v-if="initialConvoLoaded && searchNumber != '' && !loadingConversation"
                    :disabled="sendingMessage"
                    class="button"
                    :class="[hasConversation ? 'is-skip' : 'is-success']"
                    @click="showNewMessage = true"
                >
                    {{ hasConversation ? 'Add to Conversation' : 'Start a Conversation' }}
                </button>
                <span :disabled="searchNumber == '' || loadingConversation" class="button is-light" @click="reset()">Reset</span>
            </div>

            <simple-modal
                :title="hasConversation ? 'Add to conversation' : 'Start Conversation'"
                theme="is-primary"
                submitText="Cancel"
                @closed="close()"
                v-if="showNewMessage"
            >
                <h4>Target Number: {{ searchNumber }}</h4>
                <div v-if="!hasConversation">
                    <input type="text" placeholder="Type contact name.." maxlength="100" v-model="newMessage.contactName" class="input padded" />
                </div>
                <div class="spacer s10"></div>
                <textarea
                    :disabled="sendingMessage"
                    type="text"
                    class="textarea padded"
                    maxlength="300"
                    placeholder="Type a message..."
                    v-model="newMessage.message"
                >
                </textarea>
                <div class="spacer s10"></div>
                <div class="buttons">
                    <button
                        :disabled="sendingMessage || newMessage.message == '' || (!hasConversation && newMessage.contactName == '')"
                        class="button is-success"
                        @click="sendMessage()"
                    >
                        {{ hasConversation ? 'Send Message' : 'Send first message' }}
                    </button>
                </div>
                <div class="spacer s20"></div>
            </simple-modal>

            <simple-modal title="Edit Contact" theme="is-primary" submitText="Cancel" @closed="close()" v-if="showEditContact">
                <div>
                    <input type="text" placeholder="Type contact name.." maxlength="100" v-model="selectedConvo.contactName" class="input padded" />
                </div>
                <div class="spacer s10"></div>
                <div>
                    <input type="text" placeholder="Type email address.." maxlength="150" v-model="selectedConvo.email" class="input padded" />
                </div>
                <div class="spacer s10"></div>
                <div class="buttons">
                    <button class="button is-success" @click="updateContact()">
                        Update Contact
                    </button>
                </div>
                <div class="spacer s10"></div>
            </simple-modal>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import NProgress from 'nprogress';
const SimpleModal = () => import('../reusables/SimpleModal.vue');

export default {
    components: {
        SimpleModal
    },

    computed: {
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        }
    },

    data() {
        return {
            searchNumber: '',
            // searchNumber: '+61428591288',
            hasConversation: false,
            loadingConversation: false,
            loadingMessages: false,
            conversationData: {},
            convoLoaded: false,
            initialConvoLoaded: false,
            messages: [],
            showNewMessage: false,
            newMessage: {
                message: '',
                contactName: ''
            },
            sendingMessage: false,
            selectedMessage: {},
            allConversations: [],
            loadingAllConversations: false,
            showEditContact: false,
            selectedConvo: {}
        };
    },

    mounted() {
        this.loadConversations();
    },

    methods: {
        close() {
            this.showNewMessage = false;
            this.newMessage = {
                message: '',
                contactName: ''
            };
            this.sendingMessage = false;
            this.showEditContact = false;
            this.selectedConvo = {};
        },

        loadConversations() {
            let self = this;
            this.loadingAllConversations = true;
            db.collection('smsConversations')
                .orderBy('dateAdded', 'desc')
                .onSnapshot(snap => {
                    self.allConversations = [];
                    snap.forEach(doc => {
                        let data = doc.data();
                        console.log('num', data.contactName);
                        data.id = doc.id;
                        self.allConversations.push(data);
                    });
                });
            setTimeout(() => {
                this.loadingAllConversations = false;
            }, 1000);
        },

        toWebPage(webLink) {
            let url = 'https://offshoresms.com.au/welcome?x=' + webLink;
            window.open(url, '_blank', 'noreferrer');
        },

        updateConvo(convo) {
            this.selectedConvo = convo;
            this.showEditContact = true;
        },

        updateContact() {
            console.log(this.selectedConvo);
            NProgress.start();
            let self = this;
            db.collection('smsConversations')
                .doc(this.selectedConvo.id)
                .update({
                    contactName: self.selectedConvo.contactName,
                    email: self.selectedConvo.email
                })
                .then(() => {
                    this.showEditContact = false;
                    NProgress.done();
                });
        },

        loadConversation(convo) {
            this.searchNumber = convo.id;
            this.loadConversationData();
            this.markAsRead(convo);
        },

        markAsRead(convo) {
            db.collection('smsConversations')
                .doc(convo.id)
                .update({
                    lastMessageRead: true
                });
        },

        async createWebLink(convo) {
            NProgress.start();
            let webLink = '';
            await db
                .collection('smsEnquiryCodesPool')
                .limit(3)
                .get()
                .then(snap => {
                    webLink = '';
                    let index = 0;
                    snap.forEach(doc => {
                        if (index == 0) webLink = doc.id;
                        index++;
                    });
                })
                .then(() => {
                    db.collection('smsEnquiryUsedCodes')
                        .doc(webLink)
                        .set({
                            webLink: webLink,
                            phoneNumber: convo.id,
                            userName: convo.contactName
                        })
                        .then(() => {
                            db.collection('smsEnquiryCodesPool')
                                .doc(webLink)
                                .delete();
                        })
                        .then(() => {
                            db.collection('smsConversations')
                                .doc(convo.id)
                                .update({
                                    webLink: webLink
                                });
                        })
                        .then(() => {
                            NProgress.done();
                        });
                });
        },

        reset() {
            this.searchNumber = '';
            this.hasConversation = false;
            this.convoLoaded = false;
            this.loadingMessages = false;
            this.messages = [];
            this.conversationData = {};
            this.initialConvoLoaded = false;
            this.selectedMessage = {};
            this.close();
        },

        simpleDate(date) {
            let dateOptions = { timeZone: 'Australia/Sydney', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
            return date == '' ? '' : new Date(date).toLocaleDateString('en-US', dateOptions);
        },

        async loadConversationData() {
            this.hasConversation = false;
            this.loadingConversation = true;
            NProgress.start();
            db.collection('smsConversations')
                .doc(this.searchNumber)
                .onSnapshot(docSnap => {
                    if (docSnap.exists) {
                        this.conversationData = docSnap.data();
                        this.hasConversation = true;
                        this.loadMessages();
                    }
                });
            setTimeout(() => {
                this.loadingConversation = false;
                this.convoLoaded = true;
                this.initialConvoLoaded = true;
                NProgress.done();
            }, 1000);
        },

        loadMessages() {
            let self = this;
            this.loadingMessages = true;
            db.collection('smsConversations')
                .doc(this.searchNumber)
                .collection('messages')
                .orderBy('timestamp', 'asc')
                .onSnapshot(snap => {
                    self.messages = [];
                    snap.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        self.messages.push(data);
                    });
                });
            setTimeout(() => {
                this.loadingMessages = false;
            }, 2000);
        },

        sendMessage() {
            let self = this;
            this.sendingMessage = true;
            NProgress.start();

            var sendAnyTwilioSmsMessage = firebase.functions().httpsCallable('sendAnyTwilioSmsMessage');
            sendAnyTwilioSmsMessage({
                targetNumber: this.searchNumber,
                message: this.newMessage.message,
                sender: 'Steve from OffshoreSMS'
            });

            let timestamp = new Date();

            if (!this.hasConversation) {
                db.collection('smsConversations')
                    .doc(self.searchNumber)
                    .set({
                        contactName: self.newMessage.contactName,
                        email: '',
                        dateAdded: timestamp,
                        archived: false
                    })
                    .then(() => {
                        db.collection('smsConversations')
                            .doc(self.searchNumber)
                            .collection('messages')
                            .add({
                                message: self.newMessage.message,
                                sender: 'Steve from OffshoreSMS',
                                type: 'outgoing',
                                timestamp: timestamp
                            })
                            .then(docRef => {
                                db.collection('smsConversations')
                                    .doc(self.searchNumber)
                                    .update({
                                        lastMessageRead: true,
                                        lastMessageID: docRef.id,
                                        lastMessageDate: timestamp
                                    });
                            });
                    });
            } else {
                db.collection('smsConversations')
                    .doc(self.searchNumber)
                    .collection('messages')
                    .add({
                        message: self.newMessage.message,
                        sender: 'Steve from OffshoreSMS',
                        type: 'outgoing',
                        timestamp: timestamp
                    })
                    .then(docRef => {
                        db.collection('smsConversations')
                            .doc(self.searchNumber)
                            .update({
                                lastMessageRead: true,
                                lastMessageID: docRef.id,
                                lastMessageDate: timestamp
                            });
                    });
            }

            setTimeout(() => {
                this.close();
                NProgress.done();
            }, 1200);
        }
    }
};
</script>

<style lang="scss" scoped>
h4 {
    margin-bottom: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    color: cadetblue;
}
.message-wrapper {
    margin-bottom: 1rem;
    display: flex;
    .notification {
        width: 90%;
    }
    &.incoming {
        justify-content: flex-end;
        text-align: right;
        .thumbnails {
            justify-content: flex-end;
            .thumb {
                margin: 0 0 12px 12px;
            }
        }
    }
    &.outgoing {
        justify-content: flex-start;
    }
    .msg-type {
        font-size: 0.7rem;
        margin-bottom: 3px;
        text-transform: uppercase;
    }
    .heading {
        &.incoming {
            color: green;
        }
        &.outgoing {
            color: red;
        }
    }
    .message {
        font-weight: bold;
        color: dodgerblue;
        font-size: 1.1rem;
    }
}
.thumbnails {
    display: flex;
    .thumb {
        width: 120px;
        margin: 0 12px 12px 0;
        img {
            max-width: 100%;
        }
    }
}
</style>
