<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <!-- <h3 class="is-size-5">Pooled Contacts</h3><br> -->
                <div class="before-icon"><font-awesome-icon icon="users" /></div>
                <h4 class="icon">Pooled Hazards</h4>
                <div class="clear"></div>

                <table v-if="poolOfDocs.length" class="table is-bordered is-fullwidth">
                    <tr>
                        <th>Title</th>
                        <th>Edit</th>
                    </tr>
                    <tr v-for="(item, index) in poolOfDocs" :key="index">
                        <td>
                            <div>
                                <span class="red" v-if="item.hasLegacy"><font-awesome-icon icon="exclamation-triangle"/></span>
                                {{ item.title }}
                            </div>
                            <div class="spacer s5"></div>
                            <div v-if="showTags" class="tags">
                                <div class="tag is-small is-dark" v-for="(tag, idx) in item.tags">{{ tagMap[tag] }}</div>
                            </div>
                        </td>
                        <td>
                            <div class="buttons">
                                <span class="button is-small is-primary" @click="editItem(item)">Content</span>
                                <span class="button is-small is-warning" @click="editMeta(item)">Meta</span>
                            </div>
                        </td>
                    </tr>
                </table>

                <span v-if="poolOfDocs.length > 0" class="simple-link flex right" @click="enableSortModal">Update order</span>
                <div class="spacer s100"></div>

                <div class="clear"></div>

                <div class="buttons floating">
                    <span class="button is-primary is-medium" @click="addItem()">Add a Hazard</span>
                    <span class="button is-info is-medium" @click="$router.go(-1)"><font-awesome-icon icon="chevron-left" /> &nbsp;Back</span>
                    <span class="button is-dark is-medium" @click="showTags = !showTags">{{ showTags ? 'Hide Tags' : 'Show Tags' }}</span>
                </div>
            </div>
        </div>

        <!--  <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': showDetailsModal }">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
          <header class="modal-card-head is-info">
            <p class="modal-card-title">{{selectedItem.title}}</p>
            <button class="delete" aria-label="close" @click.prevent="close"></button>
          </header>
          <section class="modal-card-body">
            <div v-if="selectedItem.details != ''"><div v-for="line in selectedItem.details.split('\n')" :key="line">{{line}}<br></div></div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-info" @click.prevent="close">Done</button>
          </footer>
        </div>
      </div> -->

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showDetailsModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary" :class="selectedItem.modalTheme">
                    <p class="modal-card-title">{{ selectedItem.title }}</p>
                    <button class="delete" aria-label="close" @click="close"></button>
                </header>
                <section class="modal-card-body">
                    <table class="table clean">
                        <tr>
                            <th>Item</th>
                            <th>Details</th>
                        </tr>
                        <tr>
                            <td>Hazard</td>
                            <td>{{ selectedItem.title }}</td>
                        </tr>
                        <tr>
                            <td>Posted on</td>
                            <td>{{ formatDate(selectedItem.updatedDate, 'D MMMM (h:mm a)') }}</td>
                        </tr>
                        <tr>
                            <td>Risks</td>
                            <td>
                                <div v-if="selectedItem.risks">
                                    <div v-for="line in selectedItem.risks.split('\n')">{{ line }}<br /></div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Controls</td>
                            <td>
                                <div v-if="selectedItem.controls">
                                    <div v-for="line in selectedItem.controls.split('\n')">{{ line }}<br /></div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>RISK RATING (after controls in place)</td>
                            <td>
                                <span :class="selectedItem.newRating" class="rating caps">{{ selectedItem.newRating }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Who's responsible</td>
                            <td>
                                <div class="simple" v-for="person in selectedItem.personResponsible" :id="person">{{ person }}</div>
                            </td>
                        </tr>
                    </table>
                </section>
                <footer class="modal-card-foot has-text-centered">
                    <button class="button" :class="selectedItem.modalTheme" @click="close">Done</button>
                    <!-- <button class="button is-danger" @click="deleteHazard(selectedItem.id)">Delete Hazard</button> -->
                    <!-- <button class="button" :class="selectedItem.modalTheme" @click="editHazard(selectedItem)">Edit Hazard</button> -->
                </footer>
            </div>
        </div>

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showEditItemModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head" :class="selectedEditItem.modalTheme">
                    <p class="modal-card-title">{{ selectedEditItem.title }}</p>
                    <button class="delete" aria-label="close" @click="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Title</label>
                            <div class="spacer s10"></div>
                            <input type="text" class="input" v-model="selectedEditItem.title" />
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div v-if="selectedEditItem.risks != ''" class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Risks</label>
                            <div class="spacer s10"></div>
                            <textarea class="textarea" v-model="selectedEditItem.risks"></textarea>
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div v-if="selectedEditItem.controls != ''" class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Controls</label>
                            <div class="spacer s10"></div>
                            <textarea class="textarea" v-model="selectedEditItem.controls"></textarea>
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div v-if="selectedEditItem.riskPairs && selectedEditItem.riskPairs.length > 0">
                        <draggable v-model="selectedEditItem.riskPairs" class="sorting-lists" group="people" @start="drag = true">
                            <div v-for="(item, idx) in selectedEditItem.riskPairs" :key="`riskpair-${idx}`" class="risk-pair">
                                <div class="risk-pair-item risk">
                                    <div class="risk-pair-title">Risk</div>
                                    <div class="risk-pair-details">
                                        <!-- <div v-for="(line, irx) in item.risk.split('\n')" :key="`line16-${irx}`">{{ line }}<br /></div> -->
                                        <textarea class="textarea short" v-model="item.risk"></textarea>
                                    </div>
                                </div>
                                <div v-show="!allowReorder" class="risk-pair-item control">
                                    <div class="risk-pair-title control">Controls</div>
                                    <div class="risk-pair-details">
                                        <!-- <div v-for="(line, irx) in item.control.split('\n')" :key="`line17-${irx}`">{{ line }}<br /></div> -->
                                        <textarea class="textarea short" v-model="item.control"></textarea>
                                    </div>
                                </div>
                                <div v-show="!allowReorder" class="buttons">
                                    <!-- <span class="button is-small is-primary" @click="editPair(item, false)">Edit</span> -->
                                    <span class="button is-small is-light" @click="selectedEditItem.riskPairs.splice(idx, 1)">Delete</span>
                                </div>
                            </div>
                        </draggable>
                    </div>

                    <div class="spacer s20"></div>
                    <div v-if="allowReorder" class="italic">Drag risk / control pairs to change order</div>
                    <div v-if="allowReorder" class="spacer s10"></div>
                    <div class="buttons left">
                        <span class="button is-small is-primary" @click="selectedEditItem.riskPairs.push({ risk: '', control: '' })">
                            <font-awesome-icon icon="plus" />&nbsp; Add New Risk / Control Pair
                        </span>
                        <span v-if="selectedEditItem.hasLegacy" class="button is-small is-danger" @click="migrateLegacy(selectedEditItem)">
                            <font-awesome-icon icon="copy" />&nbsp; Migrate legacy risks/controls
                        </span>
                        <span
                            v-if="selectedEditItem.riskPairs && selectedEditItem.riskPairs.length > 1"
                            class="button is-small is-light"
                            @click="allowReorder = !allowReorder"
                            ><font-awesome-icon :icon="allowReorder ? 'times' : 'sort'" />&nbsp; {{ allowReorder ? 'End Reorder' : 'Reorder' }}</span
                        >
                    </div>

                    <div class="form-group-reg">
                        <div class="select is-primary caps">
                            <select v-model="selectedEditItem.newRating">
                                <option disabled value="">Risk Rating</option>
                                <option v-for="rating in ratings" :key="rating">{{ rating }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="checkbox-wrapper centered">
                        <label class="label">Responsible Persons</label>
                        <div class="spacer s10"></div>
                        <div class="field" v-for="person in persons" :key="person">
                            <input class="is-checkradio" type="checkbox" :id="person" :value="person" v-model="selectedEditItem.personResponsible" />
                            <label :for="person">{{ person }}</label>
                        </div>
                    </div>

                    <div class="clear"></div>

                    <div class="spacer s30"></div>
                </section>
                <footer class="modal-card-foot has-text-centered">
                    <!-- <button class="button cancel" @click="close">Close</button> -->
                    <!-- <button class="button is-danger" @click="deleteHazard(selectedEditItem.id)">Delete Hazard</button> -->
                    <button class="button" :class="selectedEditItem.modalTheme" @click="saveEditItem()">Save Hazard</button>
                </footer>
            </div>
        </div>

        <filter-tags-modal
            v-if="showEditMetaModal"
            :title="selectedEditMeta.title"
            :tagCategories="tagCategories"
            :sentSelectedTags="selectedEditMeta.tags"
            @closed="showEditMetaModal = false"
            @returnResult="updateTags($event)"
        >
        </filter-tags-modal>

        <div class="modal modal-fx-fadeInScale" :class="{ 'is-active': enableSort }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Drag to change order of items</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <div v-if="poolOfDocs.length">
                        <draggable v-model="poolOfDocs" class="sorting-list" group="people" @start="drag = true" @end="drag = false">
                            <div v-for="(item, index) in poolOfDocs" :key="item.id">{{ index + 1 }} - {{ item.title }} <span>&#8597;</span></div>
                        </draggable>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="saveNewOrder()">
                        Update
                    </button>
                    <button class="button cancel" @click.prevent="close">Cancel</button>
                </footer>
            </div>
        </div>

        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': addItemModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">Add an Item</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Title</label>
                            <div class="spacer s10"></div>
                            <input type="text" class="input" v-model="newItem.title" />
                            <div class="clear"></div>
                        </div>
                    </div>

                    <!-- <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Risks</label>
                            <div class="spacer s10"></div>
                            <textarea class="textarea" v-model="newItem.risks"></textarea>
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Controls</label>
                            <div class="spacer s10"></div>
                            <textarea class="textarea" v-model="newItem.controls"></textarea>
                            <div class="clear"></div>
                        </div>
                    </div> -->

                    <div v-if="newItem.riskPairs && newItem.riskPairs.length > 0">
                        <draggable v-model="newItem.riskPairs" class="sorting-lists" group="people" @start="drag = true">
                            <div v-for="(item, idx) in newItem.riskPairs" :key="`riskpair-${idx}`" class="risk-pair">
                                <div class="risk-pair-item risk">
                                    <div class="risk-pair-title">Risk</div>
                                    <div class="risk-pair-details">
                                        <!-- <div v-for="(line, irx) in item.risk.split('\n')" :key="`line16-${irx}`">{{ line }}<br /></div> -->
                                        <textarea class="textarea short" v-model="item.risk"></textarea>
                                    </div>
                                </div>
                                <div v-show="!allowReorder" class="risk-pair-item control">
                                    <div class="risk-pair-title control">Controls</div>
                                    <div class="risk-pair-details">
                                        <!-- <div v-for="(line, irx) in item.control.split('\n')" :key="`line17-${irx}`">{{ line }}<br /></div> -->
                                        <textarea class="textarea short" v-model="item.control"></textarea>
                                    </div>
                                </div>
                                <div v-show="!allowReorder" class="buttons">
                                    <!-- <span class="button is-small is-primary" @click="editPair(item, false)">Edit</span> -->
                                    <span class="button is-small is-light" @click="newItem.riskPairs.splice(idx, 1)">Delete</span>
                                </div>
                            </div>
                        </draggable>
                    </div>

                    <div class="spacer s20"></div>
                    <div v-if="allowReorder" class="italic">Drag risk / control pairs to change order</div>
                    <div v-if="allowReorder" class="spacer s10"></div>
                    <div class="buttons left">
                        <span class="button is-small is-primary" @click="newItem.riskPairs.push({ risk: '', control: '' })"
                            ><font-awesome-icon icon="plus" />&nbsp; Add New Risk / Control Pair</span
                        >
                        <span
                            v-if="newItem.riskPairs && newItem.riskPairs.length > 1"
                            class="button is-small is-light"
                            @click="allowReorder = !allowReorder"
                            ><font-awesome-icon :icon="allowReorder ? 'times' : 'sort'" />&nbsp; {{ allowReorder ? 'End Reorder' : 'Reorder' }}</span
                        >
                    </div>

                    <div class="form-group-reg">
                        <label class="label">Risk Rating</label>
                        <div class="select is-primary caps">
                            <select class="caps" v-model="newItem.newRating">
                                <!-- <option disabled value="">Risk Rating</option> -->
                                <option v-for="rating in ratings" :key="rating">{{ rating }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="clear"></div>

                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Responsible Persons</label>
                            <div class="field" v-for="person in allpersons" :key="person.id">
                                <input
                                    class="is-checkradio"
                                    type="checkbox"
                                    :id="person.id"
                                    :value="person.name"
                                    v-model="newItem.personResponsible"
                                />
                                <label :for="person.id">{{ person.name }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="clear"></div>

                    <div class="spacer s30"></div>

                    <div class="spacer s30"></div>
                </section>
                <footer class="modal-card-foot">
                    <button :disabled="newItem.details == '' || newItem.title == ''" class="button is-primary" @click.prevent="saveNewItem()">
                        Save New Item
                    </button>
                    <button class="button is-danger" @click.prevent="close">Cancel</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';
import { AisExperimentalDynamicWidgets } from 'vue-instantsearch';
import draggable from 'vuedraggable';
const FilterTagsModal = () => import('@/reusables/FilterTagsModal.vue');

export default {
    name: 'BuildPooledHazards',
    components: {
        FilterTagsModal,
        draggable
    },
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            poolOfDocs: [],
            selectedItem: { title: '', details: '' },
            selectedEditItem: { title: '', details: '' },
            selectedEditMeta: '',
            showDetailsModal: false,
            showEditItemModal: false,
            showEditMetaModal: false,
            showTags: false,
            // allCodes: [
            //   'base', 'keep', 'wrap', 'lump', 'corn', 'wood', 'pest', 'tent', 'wall', 'firm', 'aunt', 'news', 'lazy', 'grow', 'club', 'flex', 'team', 'vote', 'toss', 'pair', 'boom', 'raid', 'slab', 'zone', 'chop', 'lift', 'pipe',
            // ],

            allCodes: [
                { id: 'base', name: 'BASE', checked: false, code: 'base' },
                { id: 'i1', name: 'Fishing', checked: false, code: 'keep' },
                { id: 'i2', name: 'Aquaculture', checked: false, code: 'wrap' },
                { id: 'i3', name: 'Commercial Diving', checked: false, code: 'lump' },
                { id: 'i4', name: 'Marine Construction', checked: false, code: 'corn' },
                { id: 'i5', name: 'Charter Vessel', checked: false, code: 'wood' },
                { id: 'i6', name: 'Passenger Vessel', checked: false, code: 'pest' },
                { id: 'i7', name: 'Other', checked: false, code: 'gate' },
                { id: 'c1', name: '1 - Passenger vessel (13 or more passengers)', code: 'tent' },
                { id: 'c2', name: '2 - Non-passenger vessel (up to 12 passengers)', code: 'wall' },
                { id: 'c3', name: '3 - Fishing vessel', code: 'firm' },
                { id: 'c4', name: '4 - Hire and drive vessel used by the hirer only for recreational purposes', code: 'aunt' },
                { id: 'B', name: 'B - Offshore operations (within 200 nautical miles)', code: 'news' },
                { id: 'Be', name: 'B (extended) - Offshore operations (beyond 200 nautical miles)', code: 'lazy' },
                { id: 'C', name: 'C - Offshore operations (within 30 nautical miles)', code: 'grow' },
                { id: 'Cr', name: 'C (restricted) - Offshore operations - specified areas only', code: 'club' },
                { id: 'D', name: 'D - Partially smooth water operations', code: 'bank' },
                { id: 'E', name: 'E - Smooth water operations', code: 'step' },
                { id: 'Q1', checked: false, name: 'This vessel takes paying passengers', code: 'flex' },
                { id: 'Q2', checked: false, name: 'This vessel is operated single-handedly', code: 'solo' },
                { id: 'Q3', checked: false, name: 'Diving operations are carried out from this vessel', code: 'vote' },
                { id: 'Q4', checked: false, name: 'This is a dry hire or hire-drive vessel', code: 'toss' },
                { id: 'Q5', checked: false, name: 'This vessel has a crane or other lifting equipment', code: 'pair' },
                { id: 'Q6', checked: false, name: 'This vessel has an engine room', code: 'boom' },
                { id: 'Q7', checked: false, name: 'This vessel has a cabin or wheelhouse', code: 'raid' },
                { id: 'Q8', checked: false, name: 'This vessel is has sleeping accommodation', code: 'slab' },
                { id: 'Q9', checked: false, name: 'This operation requires overnight trips', code: 'zone' },
                { id: 'Q10', checked: false, name: 'This vessel is occasionally out of phone range', code: 'chop' },
                { id: 'Q11', checked: false, name: 'This vessel navigates busy traffic routes', code: 'lift' }
                // { id: 'Q12', checked: false, name: 'This vessel is operated single-handedly', code: 'pipe'},
            ],

            ratings: ['low', 'medium', 'high'],
            persons: ['The vessel Owner', 'The vessel Master', 'The crew', 'Everybody on board'],
            allpersons: [
                { id: 's1', name: 'The vessel Owner' },
                { id: 's2', name: 'The vessel Master' },
                { id: 's3', name: 'The crew' },
                { id: 's4', name: 'Everybody on board' }
            ],

            groupBasis: ['base'],
            groupRisky: ['keep', 'burp', 'news', 'lazy', 'grow', 'club', 'chop', 'lift'],
            groupHeavy: ['corn', 'pair'],
            groupTravs: ['wood', 'pest', 'tent', 'flex', 'toss'],
            groupHirer: ['aunt', 'toss', 'pest'],
            groupDiver: ['lump', 'vote'],
            groupFishy: ['keep', 'wrap', 'firm'],
            groupMotor: ['boom'],
            groupAlone: ['solo'],
            groupSleep: ['raid', 'slab', 'zone', 'chop', 'lift'],

            tempSelectedMetaCodes: [],
            addItemModal: false,
            newItem: {
                title: '',
                newRating: 'low',
                risks: '',
                controls: '',
                riskPairs: [],
                personResponsible: [],
                codes: [],
                index: 999
            },
            allTags: [],
            tagCategories: [],
            selectedTags: [],
            enableSort: false,
            allowReorder: false
        };
    },

    computed: {
        userData() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.userData
                ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2'
                    ? true
                    : false
                : false;
        },
        selectedEditMetaCodes() {
            return this.selectedEditMeta.codes.filter(item => item.checked).map(itemB => item);
        },
        tempSelectedCodeNames() {
            return this.allCodes
                .filter(code => this.tempSelectedMetaCodes.includes(code.code))
                .map(item => item.name)
                .join(', ');
            // return this.tempSelectedMetaCodes;
        },

        gBasis() {
            return this.groupBasis.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-basis'] : [];
        },
        gRisky() {
            return this.groupRisky.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-risky'] : [];
        },
        gHeavy() {
            return this.groupHeavy.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-heavy'] : [];
        },
        gTravs() {
            return this.groupTravs.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-travs'] : [];
        },
        gHirer() {
            return this.groupHirer.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-hirer'] : [];
        },
        gDiver() {
            return this.groupDiver.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-diver'] : [];
        },
        gFishy() {
            return this.groupFishy.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-fishy'] : [];
        },
        gMotor() {
            return this.groupMotor.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-motor'] : [];
        },
        gAlone() {
            return this.groupAlone.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-alone'] : [];
        },
        gSleep() {
            return this.groupSleep.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-sleep'] : [];
        },
        tagMap() {
            return this.allTags.reduce((acc, curr) => ((acc[curr.id] = curr.title), acc), {});
        },

        codeGroups() {
            return [
                ...this.gBasis,
                ...this.gRisky,
                ...this.gHeavy,
                ...this.gTravs,
                ...this.gHirer,
                ...this.gDiver,
                ...this.gFishy,
                ...this.gMotor,
                ...this.gAlone,
                ...this.gSleep
            ];
        }
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                this.displayDocs();
                this.loadAllTags();
                setTimeout(() => {
                    this.loadTagCategories();
                }, 600);
            }
        },

        enableSortModal() {
            this.poolOfDocs = this.sorted(this.poolOfDocs);
            this.enableSort = true;
        },

        sorted(arr) {
            // Set slice() to avoid to generate an infinite loop!
            return arr.slice().sort(function(a, b) {
                return a.index - b.index;
            });
        },

        saveNewOrder() {
            var self = this;
            NProgress.start();
            this.poolOfDocs.forEach((item, index) => {
                db.collection('helperFullHazards')
                    .doc(item.id)
                    .update({
                        index: index + 1
                    })
                    .then(() => {
                        self.enableSort = false;
                        NProgress.done();
                    })
                    .catch(error => {
                        console.error('Error updating indexes: ', error);
                        NProgress.done();
                    });
            });
        },

        loadAllTags() {
            let self = this;
            db.collection('tagLibrary')
                .where('assetCategory', '==', 'vessel')
                .orderBy('index', 'asc')
                .onSnapshot(querySnapshot => {
                    self.allTags = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        data.editing = false;
                        data.changed = false;
                        let parentCatID = doc.data().parentCatID ? doc.data().parentCatID : 'xSu8bBuH6RbVsPdE7SWs'; // this cat ID is for "Other" cat
                        data.parentCatID = parentCatID;
                        self.allTags.push(data);
                    });
                });
        },

        loadTagCategories() {
            NProgress.start();
            let self = this;
            db.collection('tagCategories')
                .where('assetCategory', '==', 'vessel')
                .orderBy('index', 'asc')
                .onSnapshot(querySnapshot => {
                    self.tagCategories = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        data.editing = false;
                        data.changed = false;
                        data.tags = self.allTags.filter(n => n.parentCatID == doc.id);
                        self.tagCategories.push(data);
                    });
                });
            setTimeout(() => {
                self.tagsLoaded = true;
            }, 500);
            NProgress.done();
        },

        updateTags(result) {
            console.log(result);
            let self = this;
            this.selectedTags = result;
            console.log(self.selectedEditMeta);
            NProgress.start();
            let saveData = {
                collection: 'helperFullHazards',
                docID: self.selectedEditMeta.id,
                codes: self.selectedEditMeta.codes,
                tags: result,
                codeGroups: self.codeGroups,
                index: parseInt(self.selectedEditMeta.index)
            };
            console.log(saveData);
            var adminSaveSmsItemMeta = firebase.functions().httpsCallable('adminSaveSmsItemMeta');
            adminSaveSmsItemMeta(saveData);
            this.close();
            NProgress.done();
        },

        isNumber($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        close() {
            this.showDetailsModal = false;
            this.showEditItemModal = false;
            this.showEditMetaModal = false;
            this.selectedItem = { title: '', details: '' };
            this.selectedEditItem = { title: '', details: '' };
            this.selectedEditMeta = '';
            this.addItemModal = false;
            this.newItem = {
                title: '',
                newRating: 'low',
                risks: '',
                controls: '',
                riskPairs: [],
                personResponsible: [],
                codes: [],
                index: 999
            };
            this.tempSelectedMetaCodes = [];
            this.enableSort = false;
            this.allowReorder = false;
        },

        displayDocs() {
            let self = this;
            db.collection('helperFullHazards')
                .orderBy('index', 'asc')
                .onSnapshot(querySnapshot => {
                    self.poolOfDocs = [];
                    querySnapshot.forEach(doc => {
                        let risks = doc.data().risks ? doc.data().risks : '';
                        let controls = doc.data().controls ? doc.data().controls : '';
                        self.poolOfDocs.push({
                            id: doc.id,
                            title: doc.data().title ? doc.data().title : 'no title',
                            // details: doc.data().details ? doc.data().details : 'no details',
                            codes: doc.data().codes ? doc.data().codes : [],
                            tags: doc.data().tags ? doc.data().tags : [],
                            index: doc.data().index ? doc.data().index : 999,
                            updatedDate: doc.data().updatedDate ? doc.data().updatedDate : '',
                            risks: risks,
                            controls: controls,
                            newRating: doc.data().newRating ? doc.data().newRating : 'low',
                            riskPairs: doc.data().riskPairs ? doc.data().riskPairs : [],
                            personResponsible: doc.data().personResponsible ? doc.data().personResponsible : [],
                            hasLegacy: risks != '' && controls != '' ? true : false,
                            modalTheme:
                                doc.data().newRating == 'low'
                                    ? 'is-success'
                                    : doc.data().newRating == 'medium'
                                    ? 'is-warning'
                                    : doc.data().newRating == 'high'
                                    ? 'is-danger'
                                    : 'is-info'
                            // if (doc.data().vesselStates) self.updateList(self.allstates, self.selectedStates, doc.data().vesselStates);
                        });
                    });
                });
        },

        async migrateLegacy(selectedEditItem) {
            // console.log(selectedEditItem);
            NProgress.start();
            let riskPair = {
                risk: selectedEditItem.risks,
                control: selectedEditItem.controls
            };
            await db
                .collection('helperFullHazards')
                .doc(selectedEditItem.id)
                .update({
                    riskPairs: [riskPair],
                    risks: '',
                    controls: ''
                })
                .then(() => {
                    console.log('Legacy migrated');
                    NProgress.done();
                    this.close();
                });
        },

        formatDate: function(sentDate, format) {
            let fixDate = sentDate ? sentDate.toDate() : new Date();
            return moment(fixDate).format(format);
        },

        updateList(localList, selectedList, savedList) {
            let tempList = localList.filter(item => savedList.includes(item.name)).map(itemtwo => itemtwo);
            console.log(tempList);
            tempList.forEach(item => selectedList.push(item));
        },

        showDetails(item) {
            this.tempSelectedMetaCodes = item.codes;
            this.selectedItem = item;
            this.showDetailsModal = true;
        },

        editItem(item) {
            this.selectedEditItem = item;
            this.showEditItemModal = true;
        },

        saveEditItem() {
            console.log(this.selectedEditItem);
            let self = this;
            NProgress.start();
            var adminSaveHazardItem = firebase.functions().httpsCallable('adminSaveHazardItem');
            adminSaveHazardItem({
                collection: 'helperFullHazards',
                docID: self.selectedEditItem.id,
                title: self.selectedEditItem.title,
                risks: self.selectedEditItem.risks,
                controls: self.selectedEditItem.controls,
                newRating: self.selectedEditItem.newRating,
                riskPairs: self.selectedEditItem.riskPairs,
                personResponsible: self.selectedEditItem.personResponsible
            })
                .then(function(result) {
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        },

        editMeta(item) {
            this.tempSelectedMetaCodes = item.codes;
            this.selectedEditMeta = item;
            this.showEditMetaModal = true;
        },

        // saveEditMeta(itemToSave, newCodes) {
        //     let self = this;
        //     // console.log(itemToSave);
        //     // console.log(newCodes);
        //     NProgress.start();
        //     var adminSaveSmsItemMeta = firebase.functions().httpsCallable('adminSaveSmsItemMeta');
        //     adminSaveSmsItemMeta({
        //         collection: 'helperFullHazards',
        //         docID: itemToSave.id,
        //         codes: newCodes,
        //         tags: self.selectedTags,
        //         codeGroups: self.codeGroups,
        //         index: parseInt(itemToSave.index)
        //     });
        //     this.close();
        //     NProgress.done();
        // },

        addItem() {
            this.addItemModal = true;
        },

        saveNewItem() {
            console.log(this.newItem);
            let self = this;
            NProgress.start();
            var adminSaveNewHazardItem = firebase.functions().httpsCallable('adminSaveNewHazardItem');
            adminSaveNewHazardItem({
                collection: 'helperFullHazards',
                title: self.newItem.title,
                risks: self.newItem.risks,
                controls: self.newItem.controls,
                newRating: self.newItem.newRating,
                riskPairs: self.newItem.riskPairs,
                personResponsible: self.newItem.personResponsible,
                codes: self.newItem.codes,
                index: parseInt(self.newItem.index)
            })
                .then(function(result) {
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
label {
    margin-bottom: 10px;
    display: block;
}
// .is-checkradio[type=checkbox] {
//   opacity: 1.0;
//   -ms-transform: scale(2); /* IE */
//   -moz-transform: scale(2); /* FF */
//   -webkit-transform: scale(2); /* Safari and Chrome */
//   -o-transform: scale(2); /* Opera */
//   transform: scale(2);
//   padding: 10px;
//   margin-top: 10px;
//   margin-left: 10px;
// }

.checkbox-wrapper {
    .field {
        float: left;
        margin-right: 2rem;
        // min-width: 100px;
        .has-code {
            color: red;
            font-weight: bold;
        }
    }
}

.site-wrapper .container {
    max-width: 1050px;
}
.buttons {
    margin-bottom: 5px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
input[type='text'],
input[type='tel'] {
    padding: 2rem 1rem;
}

textarea {
    min-height: 120px;
    height: 300px;
    margin-top: 10px;
    padding: 1.5rem 1rem;
}

.show-details {
    cursor: pointer;
    &:hover {
        color: dodgerblue;
    }
}

.view-hazard {
    text-align: left;
    .item {
        margin-bottom: 5px;
        span {
            text-transform: uppercase;
            font-weight: bold;
            margin-right: 7px;
            &.simple {
                text-transform: none;
                font-weight: normal;
                &:after {
                    content: ', ';
                }
                &:last-of-type {
                    &:after {
                        content: '';
                    }
                }
            }
            &.rating {
                padding: 3px;
                &.low {
                    background-color: #23d160;
                    color: #fff;
                }
                &.medium {
                    background-color: #ffdd57;
                    color: rgba(0, 0, 0, 0.7);
                }
                &.high {
                    background-color: #ff3860;
                    color: #fff;
                }
            }
        }
    }
}

.textarea {
    &.short {
        max-height: 80px;
        box-shadow: none;
    }
}

.risk-pair {
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    text-align: left;
    .risk-pair-item {
        // border-bottom: 1px solid #e4e4e4;
        padding-bottom: 8px;
        margin-bottom: 1rem;
        .risk-pair-title {
            font-size: 0.8rem;
            color: red;
            text-transform: uppercase;
            &.control {
                color: mediumseagreen;
                margin-top: 8px;
            }
        }
        .risk-pair-details {
            font-size: 0.9rem;
        }
        &:last-of-type {
            border: none;
        }
    }
    .buttons {
        text-align: left;
    }
}

.modal {
    .modal-card-body {
        position: relative;
    }
}
.posi {
    position: absolute;
    // bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: #fff;
    z-index: 999;
}
</style>
