<template>
    <div>
        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head" :class="theme">
                    <p class="modal-card-title">{{ title }}</p>
                    <button class="delete" aria-label="hasSeenNotice" @click="close"></button>
                </header>

                <section class="modal-card-body">
                    <slot>Loading content...</slot>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" :class="theme" @click="close">{{ submitText ? submitText : 'Done' }}</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app';
import { db } from '../main.js';
import NProgress from 'nprogress';

export default {
    props: {
        title: String,
        theme: String,
        submitText: String
    },

    computed: {},

    data: function() {
        return {
            showModal: true
        };
    },

    methods: {
        close: function() {
            this.showModal = false;
            let result = {
                answer: 'Modal closed',
                modal: false
            };
            this.$emit('closed', result);
        }
    }
};
</script>

<style lang="scss" scoped></style>
