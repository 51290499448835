<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">Admin All Vessels</h3>
                <br />

                <div class="form-group">
                    <span>&nbsp;</span>
                    <datetime auto input-id="globalLastChargeDate" v-model="globalLastChargeDate"></datetime>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>Global Last Charge Date</label>
                </div>

                <div class="form-group">
                    <span>&nbsp;</span>
                    <datetime auto input-id="globalNextChargeDate" v-model="globalNextChargeDate"></datetime>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>Global Next Charge Date</label>
                </div>

                <div class="spacer s30"></div>

                <input class="is-checkradio" type="checkbox" id="hideExpired" v-model="hideExpired" />
                <label for="hideExpired"> Hide Expired Vessels</label>

                <input class="is-checkradio" type="checkbox" id="searchHomeport" v-model="searchHomeport" />
                <label for="searchHomeport"> Search Home Port</label>

                <div class="spacer s30"></div>

                <!-- <span class="button" @click="updateVesselOwners">Get Owners</span> -->

                <div v-if="!isSelected" class="notification is-primary">
                    <input
                        class="input"
                        v-model="findName"
                        id="filtername"
                        autocomplete="off"
                        type="text"
                        placeholder="Start typing vessel name..."
                    />
                </div>

                <div class="buttons">
                    <span v-if="findName != ''" class="button is-warning" @click="clearVessel()">Clear</span>
                </div>

                <div class="spacer s20"></div>

                <table class="table is-bordered is-fullwidth">
                    <tr>
                        <th>#</th>
                        <th>Vessel (and owner)</th>
                        <!-- <th>SMS Audit</th> -->
                        <!-- <th v-if="isSteve">Actions</th> -->
                        <!-- <th>View Audit</th> -->
                        <th>Vessel Code</th>
                        <th>View SMS</th>
                        <!-- <th>One-Off Call</th> -->
                        <th>Square</th>
                        <th>Schedules</th>
                    </tr>
                    <tr v-for="(vessel, index) in filteredNames" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td :class="{ 'is-updated': vessel.billingUpdated }">
                            {{ vessel.vesselName }} {{ vessel.isExpired ? '(EXP)' : '' }}<br />{{ vessel.vesselOwnerName }} ({{
                                vessel.homePort
                            }})<br />
                            <span
                                :disabled="vessel.isCompanyVessel"
                                class="button is-light"
                                @click="viewProfile(vessel.vesselOwnerID, vessel.isCompanyVessel)"
                            >
                                {{ vessel.isCompanyVessel ? 'Company Vessel' : 'View Profile' }}
                            </span>
                            <span>{{ vessel.vesselID }}</span>
                            <p class="red" v-if="!vessel.hasCompanyVesselStatus">
                                NO COMPANY STATUS!
                            </p>
                        </td>
                        <!-- <td>
									<div class="traffic-light">
									  <div class="light" :class="vessel.lastAuditCode">{{vessel.lastAuditPercent}}%</div>
									</div>
									<div>{{vessel.sinceDate}}</div>
								</td> -->
                        <!-- <td v-if="isSteve">
									<update-audit :counted="vessel.hasBeenCounted" :index="index" :vesselID="vessel.vesselID" v-on:sendCount="updateCounted"></update-audit>
								</td> -->
                        <!-- <td>
									<button class="button is-link is-small" @click="viewAudit(vessel.vesselID,vessel.lastAuditID,vessel.vesselName,vessel.lastAuditDate,vessel.sinceDate)">View Audit</button>
								</td> -->
                        <td>
                            <button v-if="vessel.vesselManualCode == ''" class="button is-dark is-small" @click="getManualCode(vessel)">
                                Get Code
                            </button>
                            <div v-else>{{ vessel.vesselManualCode }}</div>
                        </td>
                        <td>
                            <button class="button is-small is-dark" @click="viewOnDev(vessel.vesselID, vessel.vesselName, vessel.vesselOwnerID)">
                                View on Dev
                            </button>
                            <div class="spacer s10"></div>
                            <button class="button is-small is-skip" @click="viewSMS(vessel.vesselID, vessel.vesselName, vessel.vesselOwnerID)">
                                View SMS
                            </button>
                            <div class="spacer s10"></div>
                            <button class="button is-small is-info" @click="viewRevisions(vessel)">
                                Revisions
                            </button>
                            <div class="spacer s10"></div>
                            <p v-if="vessel.isLoading"><font-awesome-icon icon="sync-alt" class="fa-spin" />&nbsp;...</p>
                            <button v-if="vessel.noActiveStatus && !vessel.isLoading" class="button is-small is-warning" @click="fixIsActive(vessel)">
                                Fix isActive
                            </button>
                            <!-- <span v-if="!vessel.noActiveStatus && !vessel.isLoading">A: {{ vessel.isActive }}</span> -->
                        </td>
                        <!-- <td>
                  <button class="button is-small is-danger" @click="updateVesselInfo(vessel.vesselName,vessel.vesselID,vessel.vesselOwnerID)">Update Vessel Info</button>
                </td> -->
                        <!-- <td>
                  <button class="button is-small is-danger" @click="updateVesselIndexes(vessel.vesselID,vessel.vesselName)">Update Indexes</button>
                  <div class="small" v-if="vessel.sinceIndexesUpdated != ''">{{vessel.sinceIndexesUpdated}}</div>
                </td> -->
                        <!-- <td>
                  <button class="button is-small is-danger" @click="changeCompanyVessel(vessel)">Fix isCompanyVessel</button>
                </td> -->

                        <td>
                            <div class="caps bold" :class="{ green: vessel.aaaPlans.includes('sms') }">
                                {{ vessel.aaaPlans.join(', ') }}
                            </div>
                            <p>
                                planID:
                                {{ vessel.currentSquarePlanID != '' ? vessel.currentSquarePlanID : 'NO' }}
                            </p>

                            <span
                                class="button is-small"
                                :class="[vessel.currentSquarePlanID == '' ? 'is-danger' : 'is-success']"
                                @click="setPlanID(vessel)"
                                >{{ vessel.currentSquarePlanID == '' ? 'Set Plan ID' : 'Plan Set' }}</span
                            >
                        </td>

                        <td>
                            <div class="buttons no-margin">
                                <button class="button is-small is-primary" @click="showUpdateModal(vessel)">
                                    Update Subscription
                                </button>
                            </div>
                            <div class="buttons no-margin">
                                <button
                                    class="button is-small is-light"
                                    :class="{ 'is-warning': vessel.paymentHistory.length == 0 }"
                                    @click="showPaymentHistoryModal(vessel)"
                                >
                                    Payment History
                                    {{ vessel.paymentHistory.length == 0 ? '' : '(' + vessel.paymentHistory.length + ')' }}
                                </button>
                            </div>
                            <p class="small">DUE: {{ simpleDate(vessel.nextChargeDate) }}</p>
                            <!-- <div>{{vessel.isFreebie}}</div> -->
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showProfile }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">User Details: {{ selectedUser.username }}</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <table class="table is-fullwidth">
                        <tbody>
                            <tr>
                                <td>Name:</td>
                                <td>{{ selectedUser.username }}</td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td>
                                    <a :href="'mailto:' + selectedUser.useremail + '?Subject=Message%20from%20Offshore%20SMS%20Team'">{{
                                        selectedUser.useremail
                                    }}</a>
                                </td>
                            </tr>
                            <tr>
                                <td>Phone:</td>
                                <td>
                                    <a :href="'tel:' + selectedUser.userphone">{{ selectedUser.userphone }}</a>
                                </td>
                            </tr>
                            <tr>
                                <td>Industry:</td>
                                <td>{{ selectedUser.industry }}</td>
                            </tr>
                            <tr>
                                <td>Plan</td>
                                <td>{{ selectedUser.userrole }}</td>
                            </tr>
                            <tr>
                                <td>State:</td>
                                <td>{{ selectedUser.userstate }}</td>
                            </tr>
                            <tr>
                                <td>Signup Date:</td>
                                <td>{{ selectedUser.signUpDate }}</td>
                            </tr>
                            <tr>
                                <td>Anniversary:</td>
                                <td>{{ selectedUser.cleanAnniversaryDate }}</td>
                            </tr>
                            <tr>
                                <td>Max Vessels:</td>
                                <td>{{ selectedUser.maxVessels }}</td>
                            </tr>
                            <tr>
                                <td>Does Diving?</td>
                                <td>{{ selectedUser.doesDiving ? 'Yes' : 'No' }}</td>
                            </tr>
                            <tr>
                                <td>Trainer?</td>
                                <td>{{ selectedUser.isTrainer ? 'Yes' : 'No' }}</td>
                            </tr>
                            <tr>
                                <td>Vessels</td>
                                <!-- <td>{{selectedUser.myVessels}}</td> -->
                                <td>{{ selectedVessels }}</td>
                            </tr>
                            <tr>
                                <td>My Vessel Count</td>
                                <td>{{ selectedUser.myVessels.length }}</td>
                            </tr>
                            <tr>
                                <td>Mastered Vessel Count</td>
                                <td>{{ selectedUser.masteredVessels.length }}</td>
                            </tr>
                            <tr>
                                <td>Crewed Vessel Count</td>
                                <td>{{ selectedUser.crewedVessels.length }}</td>
                            </tr>
                            <tr>
                                <td>Favourite Vessel Name</td>
                                <td>{{ selectedUser.favouriteVesselName }}</td>
                            </tr>
                            <tr>
                                <td>Favourite Vessel ID</td>
                                <td>{{ selectedUser.favouriteVesselID }}</td>
                            </tr>
                            <tr>
                                <td>Logs / Company / Mine</td>
                                <td>
                                    {{ selectedUser.favouriteVesselCanDoLogs }} / {{ selectedUser.favouriteVesselIsCompany }} /
                                    {{ selectedUser.favouriteVesselIsMine }}
                                </td>
                            </tr>
                            <tr>
                                <td>Last Charge:</td>
                                <td>${{ selectedUser.lastCharge }}</td>
                            </tr>
                            <tr>
                                <td>Next Charge:</td>
                                <td>${{ selectedUser.nextCharge }}</td>
                            </tr>
                            <tr>
                                <td>Last Payment:</td>
                                <td>{{ selectedUser.lastPayment }}</td>
                            </tr>
                            <tr>
                                <td>User ID:</td>
                                <td>{{ selectedUser.userid }}</td>
                            </tr>
                            <tr>
                                <td>Admin notes:</td>
                                <td>{{ selectedUser.adminNotes }}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click.prevent="close">Done</button>
                    <!-- <button v-if="isSteve" class="button is-warning" @click.prevent="updateProfile(selectedUser.userid)">Update Profile</button>
              <button v-if="isSteve" class="button is-info" @click.prevent="sendAlert(selectedUser.userid,selectedUser.username)">Send Alert</button>
              <button v-if="isSteve" class="button is-danger" @click.prevent="fixFavourite(selectedUser)">Fix Fave</button> -->
                    <!-- <button v-if="isSteve" class="button is-danger" @click.prevent="simulateConnection(selectedUser)">Connect</button> -->
                </footer>
            </div>
        </div>

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showAudit }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Audit for {{ selectedVesselName }}</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <div v-if="selectedAuditLoaded">
                        <!-- <p v-if="selectedAuditResult">Audit date: {{selectedAuditDate}}</p> -->
                        <p v-if="selectedAuditResult">Audit date: {{ selectedSinceDate }}</p>
                        <div class="traffic-light">
                            <span>Audit result:</span>
                            <div v-if="selectedAuditResult" class="light" :class="selectedAuditResult.auditCode">
                                {{ selectedAuditResult.auditPercent }}%
                            </div>
                        </div>
                        <table class="table is-fullwidth">
                            <tr>
                                <th>Item</th>
                                <th>Details</th>
                            </tr>
                            <tr>
                                <td>Designated Statements</td>
                                <td>
                                    {{ selectedAuditResult.hasDesignatedStatement ? 'Yes' : 'No' }}
                                </td>
                            </tr>
                            <tr>
                                <td>Master Statements</td>
                                <td>
                                    {{ selectedAuditResult.hasMasterStatement ? 'Yes' : 'No' }}
                                </td>
                            </tr>
                            <tr>
                                <td>Hazards</td>
                                <td>{{ selectedAuditResult.countHazards }}</td>
                            </tr>
                            <tr>
                                <td>Emergency Contacts</td>
                                <td>{{ selectedAuditResult.countContacts }}</td>
                            </tr>
                            <tr>
                                <td>Vessel Policies</td>
                                <td>{{ selectedAuditResult.countPolicies }}</td>
                            </tr>
                            <tr>
                                <td>Operating Procedures</td>
                                <td>{{ selectedAuditResult.countSOPS }}</td>
                            </tr>
                            <tr>
                                <td>Emergency Procedures</td>
                                <td>{{ selectedAuditResult.countEOPS }}</td>
                            </tr>
                            <tr>
                                <td>Startup Checklists</td>
                                <td>{{ selectedAuditResult.countStartupItems }}</td>
                            </tr>
                            <tr>
                                <td>Maintenance Checklists</td>
                                <td>{{ selectedAuditResult.countMaintenanceItems }}</td>
                            </tr>
                            <tr>
                                <td>Startup Logs</td>
                                <td>{{ selectedAuditResult.countStartupLogs }}</td>
                            </tr>
                            <tr>
                                <td>Maintenance Logs</td>
                                <td>{{ selectedAuditResult.countMaintenanceLogs }}</td>
                            </tr>
                        </table>
                    </div>
                    <div v-else>
                        <p>Loading last audit...</p>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="close">Done</button>
                </footer>
            </div>
        </div>

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showUpdateSchedule }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Update Payment Schedule for {{ selectedVesselName }}</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <p>Vessel ID: {{ selectedVesselID }}</p>
                    <div class="spacer s20"></div>

                    <div v-if="!loadingVesselData">
                        <div class="form-group-reg">
                            <label class="label-fixed">Vessel Name:</label>
                            <input
                                placeholder="Type name.."
                                autocomplete="false"
                                class="input padded"
                                type="text"
                                v-model="selectedSchedule.vesselName"
                                :maxlength="50"
                                required
                            />
                        </div>
                        <div class="form-group-reg">
                            <label class="label-fixed">Vessel UVI:</label>
                            <input
                                placeholder="Type UVI.."
                                autocomplete="false"
                                class="input padded"
                                type="text"
                                v-model="selectedSchedule.vesselUVI"
                                :maxlength="30"
                                required
                            />
                        </div>
                        <div class="buttons">
                            <span :disabled="!selectedSchedule.isCompanyVessel" class="button is-small is-info" @click="updateKeyData()"
                                >Update Key Data</span
                            >
                        </div>

                        <div class="form-group-reg">
                            <input class="is-checkradio" id="isExpired" type="checkbox" v-model="selectedSchedule.isExpired" />
                            <label for="isExpired">Is Expired?</label>
                        </div>

                        <div class="form-group-reg">
                            <input class="is-checkradio" id="isDemo" type="checkbox" v-model="selectedSchedule.isDemo" />
                            <label for="isDemo">Is Demo?</label>
                        </div>

                        <div class="form-group-reg">
                            <input class="is-checkradio" id="trialActive" type="checkbox" v-model="selectedSchedule.freeTrialActive" />
                            <label for="trialActive">Trial Active?</label>
                        </div>

                        <div class="form-group-reg">
                            <input class="is-checkradio" id="isPaidFor" type="checkbox" v-model="selectedSchedule.isPaidFor" />
                            <label for="isPaidFor">Is Paid For?</label>
                        </div>

                        <div class="form-group-reg">
                            <input class="is-checkradio" id="isFreebie" type="checkbox" v-model="selectedSchedule.isFreebie" />
                            <label for="isFreebie">Is Freebie?</label>
                        </div>

                        <div class="form-group-reg">
                            <input class="is-checkradio" id="isPrivate" type="checkbox" v-model="selectedSchedule.isPrivate" />
                            <label for="isPrivate">Is Private?</label>
                        </div>

                        <hr />

                        <div class="form-group-reg">
                            <label class="label-fixed">Current Plan - {{ selectedSchedule.currentSquarePlanID }}</label>
                            <input
                                type="radio"
                                id="planLogsSms"
                                value="5VQRQGREFHO7WYGDY6H6ATV7"
                                name="currentPlanID"
                                v-model="selectedSchedule.currentSquarePlanID"
                            />
                            <label class="radio-label" for="planLogsSms">Logs+SMS</label>
                            <br />
                            <input
                                type="radio"
                                id="planPro"
                                value="726DXINJQFHRBGCNILXWRQFR"
                                name="currentPlanID"
                                v-model="selectedSchedule.currentSquarePlanID"
                            />
                            <label class="radio-label" for="planPro">PRO</label>
                            <br />
                            <input type="radio" id="planNone" value="" name="currentPlanID" v-model="selectedSchedule.currentSquarePlanID" />
                            <label class="radio-label" for="planNone">None</label>
                            <br />
                        </div>

                        <div class="form-group-reg">
                            <input class="is-checkradio" id="aaaLogs" type="checkbox" value="logs" v-model="selectedSchedule.aaaPlans" />
                            <label for="aaaLogs">LOGS?</label>
                        </div>

                        <div class="form-group-reg">
                            <input class="is-checkradio" id="aaaSms" type="checkbox" value="sms" v-model="selectedSchedule.aaaPlans" />
                            <label for="aaaSms">SMS?</label>
                        </div>

                        <div class="form-group-reg">
                            <input class="is-checkradio" id="aaaPro" type="checkbox" value="pro" v-model="selectedSchedule.aaaPlans" />
                            <label for="aaaPro">PRO?</label>
                        </div>

                        <div class="form-group-reg">
                            <input class="is-checkradio" id="aaaOps" type="checkbox" value="ops" v-model="selectedSchedule.aaaPlans" />
                            <label for="aaaOps">OPS?</label>
                        </div>

                        <div class="form-group-reg">
                            <input
                                placeholder="249"
                                autocomplete="false"
                                class="input"
                                type="tel"
                                v-model="selectedSchedule.lastVesselCharge"
                                :maxlength="4"
                                v-on:keypress="isNumber($event)"
                                required
                            />
                            <label class="label-fixed">Last Vessel Charge ($):</label>
                        </div>

                        <div class="form-group">
                            <span>&nbsp;</span>
                            <datetime auto input-id="lastChargeDate" v-model="selectedSchedule.lastChargeDate"></datetime>
                            <!-- <input class="input" type="text" v-model="newDate.expiry" required> -->
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label>Last Charge Date</label>
                        </div>

                        <div class="form-group-reg">
                            <input
                                class="is-checkradio"
                                id="useGlobalLast"
                                @change="selectedSchedule.lastChargeDate = globalLastChargeDate"
                                type="checkbox"
                            />
                            <label for="useGlobalLast">Use global last charge date</label>
                        </div>

                        <div class="spacer s30"></div>

                        <div class="form-group-reg">
                            <input
                                placeholder="249"
                                autocomplete="false"
                                class="input"
                                type="tel"
                                v-model="selectedSchedule.nextVesselCharge"
                                :maxlength="4"
                                v-on:keypress="isNumber($event)"
                                required
                            />
                            <label class="label-fixed">Next Vessel Charge ($):</label>
                        </div>

                        <div class="form-group">
                            <span>&nbsp;</span>
                            <datetime auto input-id="nextChargeDate" v-model="selectedSchedule.nextChargeDate"></datetime>
                            <!-- <input class="input" type="text" v-model="newDate.expiry" required> -->
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label>Next Charge Date</label>
                        </div>

                        <div class="form-group-reg">
                            <input
                                class="is-checkradio"
                                id="useGlobal"
                                @change="selectedSchedule.nextChargeDate = globalNextChargeDate"
                                type="checkbox"
                            />
                            <label for="useGlobal">Use global next charge date {{ globalNextChargeDate }}</label>
                        </div>

                        <div class="spacer s30"></div>

                        <div class="form-group-reg">
                            <input class="is-checkradio" id="billingUpdated" type="checkbox" v-model="selectedSchedule.billingUpdated" />
                            <label for="billingUpdated">Mark as Billing Updated?</label>
                        </div>

                        <label class="label">Admin Notes:</label>
                        <div v-if="selectedAdminNotes.length > 0">
                            <div class="admin-notes" v-for="note in selectedAdminNotes">
                                <div class="note">{{ note.note }}</div>
                                <div class="note-timestamp">
                                    {{ note.timestamp == '' ? 'No date' : simpleDate(note.timestamp.toDate()) }}
                                </div>
                            </div>
                        </div>
                        <div v-if="selectedAdminNotes.length > 0" class="spacer s20"></div>
                        <!-- <textarea class="input" placeholder="Write admin notes.." v-model="selectedSchedule.adminNotes"></textarea> -->
                        <textarea class="input" placeholder="Write admin notes.." v-model="newAdminNote"></textarea>
                        <div class="spacer s10"></div>
                        <button :disabled="addingAdminNote || newAdminNote == ''" class="button is-info" @click="addAdminNote()">
                            {{ addingAdminNote ? 'Adding note..' : 'Add Note' }}
                        </button>

                        <div class="spacer s30"></div>
                    </div>

                    <div v-else><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading...</div>
                </section>
                <footer class="modal-card-foot column">
                    <div v-if="updateErrorMessage != ''" class="update-message">
                        <font-awesome-icon icon="exclamation-triangle" /> &nbsp;
                        {{ updateErrorMessage }}
                    </div>
                    <div class="buttons">
                        <button class="button is-primary" @click="updateVesselData()">
                            Update Vessel
                        </button>
                        <button class="button is-cancel" @click="close">Cancel</button>
                    </div>
                </footer>
            </div>
        </div>

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showChangeCompanyStatus }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Change isCompanyVessel Status</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="buttons">
                        <button
                            :disabled="!selectedVessel.isCompanyVessel"
                            class="button is-medium is-warning"
                            @click="
                                selectedVessel.isCompanyVessel = false;
                                statusChanged = true;
                            "
                        >
                            {{ selectedVessel.isCompanyVessel ? 'Make Private' : 'Private Vessel' }}
                        </button>
                        <button
                            :disabled="selectedVessel.isCompanyVessel"
                            class="button is-medium is-info"
                            @click="
                                selectedVessel.isCompanyVessel = true;
                                statusChanged = true;
                            "
                        >
                            {{ !selectedVessel.isCompanyVessel ? 'Make Company' : 'Company Vessel' }}
                        </button>
                    </div>
                    <p>
                        {{ selectedVessel.isCompanyVessel ? 'This is a COMPANY vessel' : 'This is a PRIVATE vessel' }}
                    </p>
                </section>
                <footer class="modal-card-foot">
                    <button :disabled="!statusChanged" class="button is-primary" @click="fixCompanyVesselStatus()">
                        Update Vessel
                    </button>
                    <button class="button is-cancel" @click="close">Cancel</button>
                </footer>
            </div>
        </div>

        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showSetPlanID }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Set a current Plan ID on {{ selectedVessel.vesselName }}</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    XX {{ selectedVessel.currentSquarePlanID }}

                    <div class="form-group-reg">
                        <label class="label-fixed">Current Plan - {{ selectedVessel.currentSquarePlanID }}</label>
                        <input
                            class="is-checkradio"
                            type="radio"
                            id="planLogsSms"
                            value="5VQRQGREFHO7WYGDY6H6ATV7"
                            name="currentPlanID"
                            v-model="selectedVessel.currentSquarePlanID"
                            @input="console.log(selectedVessel.currentSquarePlanID)"
                        />
                        <label class="radio-label" for="planLogsSms">Logs+SMS</label>
                        <br />
                        <input
                            class="is-checkradio"
                            type="radio"
                            id="planPro"
                            value="726DXINJQFHRBGCNILXWRQFR"
                            name="currentPlanID"
                            v-model="selectedVessel.currentSquarePlanID"
                        />
                        <label class="radio-label" for="planPro">PRO</label>
                        <br />
                        <input
                            class="is-checkradio"
                            type="radio"
                            id="planNone"
                            value=""
                            name="currentPlanID"
                            v-model="selectedVessel.currentSquarePlanID"
                        />
                        <label class="radio-label" for="planNone">None</label>
                        <br />
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button
                        :disabled="selectedVessel.currentSquarePlanID == '' || selectedVessel.currentSquarePlanID == undefined"
                        class="button is-primary"
                        @click="setSquarePlanID()"
                    >
                        Save Plan ID
                    </button>
                    <button class="button is-cancel" @click="close">Cancel</button>
                </footer>
            </div>
        </div>

        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showPaymentHistory }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Add to Payment History on {{ selectedVessel.vesselName }}</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <div v-if="selectedVessel.paymentHistory && selectedVessel.paymentHistory.length > 0">
                        <h4 class="heading green">Previous Payments:</h4>
                        <div v-for="(pmt, idx) in selectedVessel.paymentHistory" :key="`pmt-${idx}`">
                            <div class="pmt-wrapper">
                                <div class="pmt-date heading">{{ simpleDate(pmt.date.toDate()) }} [{{ pmt.action }}]</div>
                                <div class="pmt-details">
                                    <span v-if="pmt.invoice != ''">{{ pmt.invoice }}: </span><span v-if="pmt.method != ''">{{ pmt.method }}</span>
                                </div>
                                <div class="pmt-notes" v-if="pmt.notes != ''">
                                    {{ pmt.notes }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else><h4 class="heading green">No Previous Payments.</h4></div>

                    <div class="spacer s30"></div>

                    <div class="form-group-">
                        <label class="label">Action</label>
                        <div class="select">
                            <select v-model="newPayment.action">
                                <option v-for="action in paymentActions" :key="action.slug">{{ action }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="spacer s20"></div>

                    <div class="form-group-reg">
                        <label class="label">Date</label>
                        <datetime auto input-id="newPaymentDate" v-model="newPayment.date"></datetime>
                    </div>

                    <div class="form-group-">
                        <label class="label">Amount ($)</label>
                        <input
                            placeholder="249"
                            autocomplete="false"
                            class="input small"
                            type="tel"
                            v-model="newPayment.amount"
                            :maxlength="5"
                            v-on:keypress="isNumber($event)"
                            required
                        />
                    </div>

                    <div class="spacer s20"></div>

                    <div class="form-group-">
                        <label class="label">Invoice number</label>
                        <input
                            placeholder="Type number.."
                            autocomplete="false"
                            class="input"
                            type="text"
                            v-model="newPayment.invoice"
                            :maxlength="30"
                            required
                        />
                    </div>

                    <div class="spacer s20"></div>

                    <div class="form-group-">
                        <label class="label">Payment method</label>
                        <div class="select">
                            <select v-model="newPayment.method">
                                <option v-for="method in paymentMethods" :key="method.slug">{{ method }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="spacer s20"></div>

                    <div class="form-group-">
                        <label class="label">Person name</label>
                        <input
                            placeholder="Type number.."
                            autocomplete="false"
                            class="input"
                            type="text"
                            v-model="newPayment.username"
                            :maxlength="30"
                            required
                        />
                    </div>

                    <div class="spacer s20"></div>

                    <div class="form-group-">
                        <label class="label">Person userID</label>
                        <input
                            placeholder="Type number.."
                            autocomplete="false"
                            class="input"
                            type="text"
                            v-model="newPayment.userID"
                            :maxlength="30"
                            required
                        />
                    </div>

                    <div class="spacer s20"></div>

                    <div class="form-group-">
                        <label class="label">Notes</label>
                        <textarea class="input" placeholder="Write any notes.." v-model="newPayment.notes"></textarea>
                    </div>

                    <div class="spacer s30"></div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="updatePaymentHistory()">
                        Save Payment
                    </button>
                    <button class="button is-cancel" @click="close">Cancel</button>
                </footer>
            </div>
        </div>

        <simple-modal title="Filter Timed Activities" theme="is-primary" v-on:closed="showVesselRevisions = false" v-if="showVesselRevisions">
            <ul>
                <li v-for="(item, idx) in selectedVesselRevisions" :key="`rev-${idx}`">
                    {{ timeSince(item.timestamp) }}: {{ item.revision }} ({{ item.userName }})
                </li>
            </ul>

            <div class="button is-warning" @click="loadNextRevision()">
                {{ loadingNext ? 'Loading..' : 'Load next 25' }}
            </div>
        </simple-modal>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '../main.js';
// import 'firebase/compat/auth';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';
const SimpleModal = () => import('../reusables/SimpleModal.vue');

const UpdateAudit = () => import('./UpdateAudit.vue');

export default {
    name: 'AdminVessels',
    components: {
        // UpdateAudit,
        SimpleModal
    },
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            allVessels: [],
            showAudit: false,
            selectedVessel: {},
            selectedVesselID: '',
            selectedVesselName: '',
            selectedAuditID: '',
            selectedSinceDate: '',
            selectedAuditResult: {
                hasDesignatedStatement: false,
                hasMasterStatement: false,
                countHazards: 0,
                countContacts: 0,
                countPolicies: 0,
                countSOPS: 0,
                countEOPS: 0,
                countStartupItems: 0,
                countStartupLogs: 0,
                countMaintenanceItems: 0,
                countMaintenanceLogs: 0,
                auditScore: 0,
                auditCode: 'red',
                auditPercent: 0
            },
            selectedVesselRevisions: [],
            lastVisible: {},
            showProfile: false,
            selectedAuditLoaded: false,
            globalLastChargeDate: '',
            globalNextChargeDate: '',
            showUpdateSchedule: false,
            selectedSchedule: {
                isPaidFor: false,
                isFreebie: false,
                isPrivate: false,
                isDemo: false,
                freeTrialActive: false,
                lastChargeDate: '',
                aaaPlans: [],
                lastVesselCharge: 249,
                nextChargeDate: '',
                nextVesselCharge: 249,
                billingUpdated: true,
                adminNotes: '',
                currentSquarePlanID: '',
                vesselID: '',
                vesselName: '',
                vesselUVI: '',
                vesselOwnerID: ''
            },
            loadingVesselData: false,

            selectedUserID: '',
            selectedUser: {
                userid: '',
                username: '',
                useremail: '',
                userphone: '',
                industry: '',
                userrole: '',
                userstate: '',
                signUpDate: '',
                anniversaryDate: '',
                maxVessels: '',
                adminNotes: '',
                doesDiving: '',
                isTrainer: '',
                myVessels: [],
                lastCharge: '',
                nextCharge: '',
                lastPayment: '',
                masteredVessels: [],
                crewedVessels: [],
                favouriteVesselID: '',
                favouriteVesselName: '',
                favouriteVesselCanDoLogs: false,
                favouriteVesselIsCompany: false,
                favouriteVesselIsMine: false
            },
            hideExpired: true,

            isSelected: false,
            findName: '',
            // reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

            newCode: '',
            showChangeCompanyStatus: false,
            statusChanged: false,
            showVesselRevisions: false,
            loadingNext: false,
            searchHomeport: false,

            selectedAdminNotes: [],
            newAdminNote: '',
            addingAdminNote: false,
            showSetPlanID: false,

            updateErrorMessage: '',
            showPaymentHistory: false,
            newPayment: {
                action: 'payment-made',
                date: new Date().toISOString(),
                amount: 249,
                invoice: '',
                method: 'Square: Credit Card',
                notes: '',
                username: '',
                userID: ''
            },
            paymentMethods: ['N/A', 'Square: Credit Card', 'Square: EFT', 'Xero: EFT', 'Xero: Credit Card', 'Other'],
            paymentActions: [
                'add-vessel',
                'trial-start',
                'trial-extend',
                'invoice-sent',
                'trial-end',
                'plan-change',
                'expire-vessel',
                'charge-date-change',
                'payment-made',
                'confirmation-sent'
            ]
        };
    },

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false;
        },
        selectedVessels() {
            let self = this;
            if (this.selectedUser.userid != '' && this.selectedUser.myVessels != []) {
                let tempVessels = self.selectedUser.myVessels.map(vessel => vessel.vesselName);
                return tempVessels.join(', ');
            } else {
                return [];
            }
        },
        filteredVessels() {
            return this.hideExpired ? this.allVessels.filter(vessel => !vessel.isExpired).map(vess => vess) : this.allVessels;
        },
        filteredNames() {
            let filter = new RegExp(this.findName, 'i');
            // return this.filteredVessels.filter(vessel => vessel.vesselName.match(filter));
            return this.searchHomeport
                ? this.filteredVessels.filter(vessel => vessel.homePort.match(filter))
                : this.filteredVessels.filter(vessel => vessel.vesselName.match(filter));
        }
    },

    methods: {
        onUserLogin(user) {
            if (user) {
                this.loadAllVessels();
            }
            this.globalLastChargeDate = new Date().toISOString();
            // let tempDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
            // this.globalNextChargeDate = tempDate.toISOString();
            this.globalNextChargeDate = new Date(2025, 10, 18, 0, 0, 0, 0).toISOString();
        },

        // sorted(arr) {
        //   // Set slice() to avoid generating an infinite loop!
        //   return arr.slice().sort(function(a, b) {
        //     // return a.index - b.index;
        //     return b.index - a.index;
        //   });
        // },

        isNumber($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        addAdminNote() {
            // console.log(this.newAdminNote);
            let self = this;
            NProgress.start();
            this.addingAdminNote = true;
            db.collection('vessels')
                .doc(this.selectedVesselID)
                .collection('adminNotes')
                .add({
                    note: self.newAdminNote,
                    timestamp: new Date()
                })
                .then(() => {
                    self.newAdminNote = '';
                    self.addingAdminNote = false;
                    NProgress.done();
                })
                .catch(err => {
                    self.addingAdminNote = false;
                    console.log('Error adding note,', err);
                    NProgress.done();
                });
        },

        clearVessel: function() {
            this.findName = '';
            this.isSelected = false;
        },

        cleanDate: function(date) {
            // console.log('Date: ',date);
            // return moment(date.toDate()).format('MMM Do, h:mm:ss a');
            return moment(date.toDate()).format('D MMM, YYYY');
        },

        timeSince(timestamp) {
            return timestamp.toDate();
        },

        close() {
            this.showAudit = false;
            this.selectedVessel = {};
            this.selectedVesselID = '';
            this.selectedVesselName = '';
            this.selectedAuditID = '';
            this.selectedAuditDate = '';
            this.selectedSinceDate = '';
            this.lastVisible = {};
            this.selectedAuditLoaded = false;
            this.showUpdateSchedule = false;
            this.selectedVesselRevisions = [];
            this.showVesselRevisions = false;
            this.loadingNext = false;
            this.selectedSchedule = {
                isPaidFor: false,
                isFreebie: false,
                isPrivate: false,
                isDemo: false,
                freeTrialActive: false,
                isExpired: false,
                lastChargeDate: '',
                lastVesselCharge: 249,
                aaaPlans: [],
                nextChargeDate: '',
                nextVesselCharge: 249,
                billingUpdated: true,
                adminNotes: '',
                currentSquarePlanID: '',
                vesselID: '',
                vesselName: '',
                vesselUVI: '',
                vesselOwnerID: ''
            };
            this.selectedUserID = '';
            this.selectedUser = {
                userid: '',
                username: '',
                useremail: '',
                userphone: '',
                industry: '',
                userrole: '',
                userstate: '',
                signUpDate: '',
                anniversaryDate: '',
                maxVessels: '',
                adminNotes: '',
                doesDiving: '',
                isTrainer: '',
                myVessels: [],
                lastCharge: '',
                nextCharge: '',
                lastPayment: '',
                masteredVessels: [],
                crewedVessels: [],
                favouriteVesselID: '',
                favouriteVesselName: '',
                favouriteVesselCanDoLogs: false,
                favouriteVesselIsCompany: false,
                favouriteVesselIsMine: false
            };
            this.showProfile = false;
            this.showChangeCompanyStatus = false;
            this.statusChanged = false;
            this.newAdminNote = '';
            this.selectedAdminNotes = [];
            this.addingAdminNote = false;
            this.showSetPlanID = false;
            this.updateErrorMessage = '';
            this.showPaymentHistory = false;
            this.newPayment = {
                action: 'payment-made',
                date: new Date().toISOString(),
                amount: 249,
                invoice: '',
                method: 'Square: Credit Card',
                notes: '',
                username: '',
                userID: ''
            };
        },

        loadAllVessels: function() {
            let self = this;
            db.collection('vessels')
                .orderBy('vesselOwnerName', 'asc')
                .onSnapshot(function(querySnapshot) {
                    self.allVessels = [];
                    querySnapshot.forEach(doc => {
                        //let auditD = (doc.data().lastAuditDate ? doc.data().lastAuditDate.toDate() : 'vvv');
                        // if (doc.id == 'meEJadSE53ukL4MNKsYO') console.log(doc.data());
                        // if (! doc.data().isExpired) {
                        self.allVessels.push({
                            vesselID: doc.id,
                            vesselName: doc.data().vesselName,
                            lastAuditID: doc.data().lastAuditID ? doc.data().lastAuditID : 'NA',
                            //lastAuditDate: (doc.data().lastAuditDate ? doc.data().lastAuditDate.toDate() : 'Never'),
                            lastAuditDate: doc.data().lastAuditDate ? self.formatDate(doc.data().lastAuditDate, 'dddd D MMMM (h:mm a)') : 'Never',
                            sinceDate: doc.data().lastAuditDate ? moment(doc.data().lastAuditDate.toDate()).fromNow() : 'no audit',
                            lastAuditPercent: doc.data().lastAuditPercent ? doc.data().lastAuditPercent : 0,
                            lastAuditCode: doc.data().lastAuditCode ? doc.data().lastAuditCode : 0,
                            hasBeenCounted: false,
                            vesselOwnerName: doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?',
                            vesselOwnerID: doc.data().vesselOwnerID,
                            isCompanyVessel: doc.data().isCompanyVessel ? doc.data().isCompanyVessel : false,
                            hasCompanyVesselStatus: doc.data().isCompanyVessel === undefined ? false : true,

                            isPaidFor: doc.data().isPaidFor ? doc.data().isPaidFor : false,
                            isDemo: doc.data().isDemo ? doc.data().isDemo : false,
                            freeTrialActive: doc.data().freeTrialActive ? doc.data().freeTrialActive : false,
                            isFreebie: doc.data().isFreebie ? doc.data().isFreebie : false,
                            isPrivate: doc.data().isPrivate ? doc.data().isPrivate : false,
                            lastChargeDate: doc.data().lastChargeDate
                                ? doc
                                      .data()
                                      .lastChargeDate.toDate()
                                      .toISOString()
                                : '',
                            lastVesselCharge: doc.data().lastVesselCharge ? doc.data().lastVesselCharge : 249,
                            nextChargeDate:
                                doc.data().nextChargeDate == undefined ||
                                doc.data().nextChargeDate === 'string' ||
                                typeof doc.data().nextChargeDate === 'number'
                                    ? ''
                                    : doc
                                          .data()
                                          .nextChargeDate.toDate()
                                          .toISOString(),
                            nextVesselCharge: doc.data().nextVesselCharge ? doc.data().nextVesselCharge : 249,
                            billingUpdated: doc.data().billingUpdated ? doc.data().billingUpdated : false,
                            adminNotes: doc.data().adminNotes ? doc.data().adminNotes : false,
                            isExpired: doc.data().isExpired ? doc.data().isExpired : false,
                            indexesUpdated: doc.data().indexesUpdated ? doc.data().indexesUpdated.toDate() : '',
                            sinceIndexesUpdated: doc.data().indexesUpdated ? moment(doc.data().indexesUpdated.toDate()).fromNow() : '--',
                            aaaPlans: doc.data().aaaPlans ? doc.data().aaaPlans : [],
                            vesselManualCode: doc.data().vesselManualCode ? doc.data().vesselManualCode : '',
                            currentSquarePlanID: doc.data().currentSquarePlanID ? doc.data().currentSquarePlanID : '',
                            noActiveStatus: doc.data().isActive == null || doc.data().isActive == undefined,
                            isActive: doc.data().isActive ? doc.data().isActive : false,
                            isLoading: false,
                            homePort: doc.data().homePort ? doc.data().homePort : '',
                            paymentHistory: doc.data().paymentHistory ? doc.data().paymentHistory : []
                        });
                        // }
                    });
                });
        },

        formatDate: function(sentDate, format) {
            let fixDate = sentDate ? sentDate.toDate() : new Date();
            //console.log("Fix date:",fixDate);
            return moment(fixDate).format(format);
        },

        simpleDate(date) {
            let dateOptions = {
                timeZone: 'Australia/Sydney',
                month: 'short',
                day: 'numeric',
                year: 'numeric'
            };
            return new Date(date).toLocaleDateString('en-US', dateOptions);
        },

        viewAudit: function(vesselID, auditID, vesselName, auditDate, sinceDate) {
            let self = this;
            this.showAudit = true;
            this.selectedVesselID = vesselID;
            this.selectedVesselName = vesselName;
            this.selectedAuditID = auditID;
            this.selectedSinceDate = sinceDate;
            this.selectedAuditDate = auditDate;

            db.collection('vessels')
                .doc(vesselID)
                .collection('smsAudits')
                .doc(auditID)
                .get()
                .then(function(doc) {
                    self.selectedAuditResult.hasDesignatedStatement = doc.data().hasDesignatedStatement;
                    self.selectedAuditResult.hasMasterStatement = doc.data().hasMasterStatement;
                    self.selectedAuditResult.countHazards = doc.data().hazards;
                    self.selectedAuditResult.countContacts = doc.data().contacts;
                    self.selectedAuditResult.countPolicies = doc.data().policies;
                    self.selectedAuditResult.countSOPS = doc.data().sops;
                    self.selectedAuditResult.countEOPS = doc.data().eops;
                    self.selectedAuditResult.countStartupItems = doc.data().startupItems;
                    self.selectedAuditResult.countStartupLogs = doc.data().startupLogs;
                    self.selectedAuditResult.countMaintenanceItems = doc.data().maintenanceItems;
                    self.selectedAuditResult.countMaintenanceLogs = doc.data().maintenanceLogs;
                    self.selectedAuditResult.auditScore = doc.data().auditScore;
                    self.selectedAuditResult.auditCode = doc.data().auditCode;
                    self.selectedAuditResult.auditPercent = doc.data().auditPercent;
                })
                .then(function() {
                    self.selectedAuditLoaded = true;
                });
        },

        viewProfile: function(userid, isCompanyVessel) {
            // console.log(userid, isCompanyVessel);
            if (!isCompanyVessel) {
                //console.log(userid, isCompanyVessel);
                let self = this;
                NProgress.start();
                db.collection('userdata')
                    .doc(userid)
                    .get()
                    .then(function(doc) {
                        //console.log(doc.data());
                        //console.log(doc.data().myVessels);
                        self.selectedUser.userid = userid;
                        self.selectedUser.username = doc.data().username;
                        self.selectedUser.useremail = doc.data().useremail;
                        self.selectedUser.userphone = doc.data().userphone;
                        self.selectedUser.industry = doc.data().industry;
                        self.selectedUser.userrole = doc.data().userrole;
                        self.selectedUser.userstate = doc.data().userstate;
                        self.selectedUser.signUpDate = self.cleanDate(doc.data().signUpDate);
                        self.selectedUser.cleanAnniversaryDate = self.cleanDate(doc.data().anniversaryDate);
                        self.selectedUser.anniversaryDate = doc.data().anniversaryDate.toDate();
                        self.selectedUser.maxVessels = doc.data().maxVessels;
                        self.selectedUser.adminNotes = doc.data().adminNotes ? doc.data().adminNotes : '';
                        self.selectedUser.doesDiving = doc.data().doesDiving ? doc.data().doesDiving : false;
                        self.selectedUser.isTrainer = doc.data().isTrainer ? doc.data().isTrainer : false;
                        self.selectedUser.lastCharge = doc.data().lastCharge;
                        self.selectedUser.nextCharge = doc.data().nextCharge;
                        self.selectedUser.lastPayment = doc.data().lastPayment;
                        self.selectedUser.userGroup = doc.data().userGroup ? doc.data().userGroup : '';
                        //
                        self.selectedUser.masteredVessels = doc.data().vesselsThatIMaster ? doc.data().vesselsThatIMaster : [];
                        self.selectedUser.crewedVessels = doc.data().vesselsThatICrew ? doc.data().vesselsThatICrew : [];
                        self.selectedUser.myVessels = doc.data().myVessels ? doc.data().myVessels : [];
                        self.selectedUser.favouriteVesselID = doc.data().favouriteVesselID ? doc.data().favouriteVesselID : '';
                        self.selectedUser.favouriteVesselName = doc.data().favouriteVesselName ? doc.data().favouriteVesselName : '';
                        self.selectedUser.favouriteVesselCanDoLogs = doc.data().favouriteVesselCanDoLogs
                            ? doc.data().favouriteVesselCanDoLogs
                            : false;
                        self.selectedUser.favouriteVesselIsCompany = doc.data().favouriteVesselIsCompany
                            ? doc.data().favouriteVesselIsCompany
                            : false;
                        self.selectedUser.favouriteVesselIsMine = doc.data().favouriteVesselIsMine ? doc.data().favouriteVesselIsMine : false;
                    })
                    .then(function() {
                        NProgress.done();
                        self.showProfile = true;
                    })
                    .catch(function(err) {
                        NProgress.done();
                        console.log('Error', err);
                    });
            }
        },

        viewOnDev(vesselID, vesselName, vesselOwnerID) {
            let url = 'https://dev.offshoresms.com.au/sms-snapshot?vid=' + vesselID;
            window.open(url, '_blank', 'noreferrer');
        },

        viewSMS(vesselID, vesselName, vesselOwnerID) {
            // console.log(vesselID,vesselName,vesselOwnerID);
            let url = 'https://app.offshoresms.com.au/fast-track-view-sms?vid=' + vesselID + '&rev=y';
            window.open(url, '_blank', 'noreferrer');
            // this.$router.push({
            //   name: 'ViewSms',
            //   params: {
            //     vesselID: vesselID,
            //     vesselName: vesselName,
            //     ownerID: vesselOwnerID
            //   }
            // });
        },

        viewRevisions(vessel) {
            this.selectedVessel = vessel;
            this.loadFirstRevision();
            this.showVesselRevisions = true;
        },

        fixIsActive(vessel) {
            vessel.isLoading = true;
            db.collection('vessels')
                .doc(vessel.vesselID)
                .update({
                    isActive: false
                });
            setTimeout(() => {
                vessel.isLoading = false;
            }, 500);
        },

        async loadFirstRevision() {
            let self = this;
            // db.collection('vessels').doc(self.selectedVessel.vesselID).collection('vesselRevisions').get().then((snap) => {
            //   self.selectedVesselRevisions = [];
            //   snap.forEach((doc) => {

            //   });
            // });

            //

            // var first = db.collection("cities").orderBy("population").limit(25);
            var first = db
                .collection('vessels')
                .doc(self.selectedVessel.vesselID)
                .collection('vesselRevisions')
                .orderBy('timestamp', 'desc')
                .limit(25);

            return first.get().then(documentSnapshots => {
                // Get the last visible document
                self.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                console.log('last revision', self.lastVisible);

                documentSnapshots.forEach(doc => {
                    console.log(doc.data());
                    self.selectedVesselRevisions.push(doc.data());
                });

                // Construct a new query starting at this document,
                // get the next 25 cities.
                // var first = db.collection("cities").orderBy("population").limit(25);
                // var next = db.collection('vessels').doc(vessel.veselID).collection('vesselRevisions').startAfter(self.lastVisible).limit(25);
            });
        },
        async loadNextRevision() {
            let self = this;
            this.loadingNext = true;
            var next = db
                .collection('vessels')
                .doc(self.selectedVessel.vesselID)
                .collection('vesselRevisions')
                .startAfter(self.lastVisible)
                .orderBy('timestamp', 'desc')
                .limit(25);
            return next
                .get()
                .then(documentSnapshots => {
                    // Get the last visible document
                    self.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                    console.log('last revision', self.lastVisible);

                    documentSnapshots.forEach(doc => {
                        self.selectedVesselRevisions.push(doc.data());
                    });

                    // Construct a new query starting at this document,
                    // get the next 25 cities.
                    // var first = db.collection("cities").orderBy("population").limit(25);
                    // var next = db.collection('vessels').doc(vessel.veselID).collection('vesselRevisions').startAfter(self.lastVisible).limit(25);
                })
                .then(() => {
                    self.loadingNext = false;
                });
        },

        async getManualCode(vessel) {
            let self = this;
            NProgress.start();
            await db
                .collection('vesselManualCodesPool')
                .limit(5)
                .get()
                .then(snap => {
                    self.newCode = '';
                    let index = 0;
                    snap.forEach(doc => {
                        console.log('Index', index);
                        if (index == 0) self.newCode = doc.id;
                        index++;
                    });
                    console.log(self.newCode);
                })
                .then(() => {
                    console.log(self.newCode);
                    db.collection('vesselManualCodes')
                        .doc(self.newCode)
                        .set({
                            manualCode: self.newCode,
                            vesselID: vessel.vesselID,
                            vesselName: vessel.vesselName
                        });
                    db.collection('vessels')
                        .doc(vessel.vesselID)
                        .update({
                            vesselManualCode: self.newCode
                        });
                    db.collection('vesselManualCodesPool')
                        .doc(self.newCode)
                        .delete();
                    NProgress.done();
                    self.close();
                })
                .catch(err => {
                    console.log('Error', err);
                    NProgress.done();
                    self.close();
                });
        },

        updateCounted: function(result) {
            //console.log('Result: ',result);
            //this.allVessels[result.index].hasBeenCounted = true;
        },

        updateVesselInfo: function(vesselName, vesselID, vesselOwnerID) {
            // console.log(vesselName,vesselID, vesselOwnerID,'updated.');

            var updateVesselWithOwnerInfo = firebase.functions().httpsCallable('updateVesselWithOwnerInfo');
            updateVesselWithOwnerInfo({
                vesselID: vesselID,
                vesselOwnerID: vesselOwnerID
            });
        },

        changeCompanyVessel(vessel) {
            this.selectedVessel = vessel;
            this.showChangeCompanyStatus = true;
        },

        fixCompanyVesselStatus() {
            let self = this;
            console.log('New status:', this.selectedVessel.isCompanyVessel);
            NProgress.start();
            db.collection('vessels')
                .doc(self.selectedVessel.vesselID)
                .update({
                    isCompanyVessel: self.selectedVessel.isCompanyVessel
                })
                .then(() => {
                    setTimeout(() => {
                        self.close();
                        NProgress.done();
                    }, 300);
                })
                .catch(error => {
                    setTimeout(() => {
                        console.log('Error', error);
                        // self.close();
                        NProgress.done();
                    }, 300);
                });
        },

        fixShipsLogBug(vessel) {
            let self = this;
            let message;
            message = {
                title: 'Fix Ships Log image bug on ' + vessel.vesselName + '?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    var AdminUpdateVesselCollection = firebase.functions().httpsCallable('AdminUpdateVesselCollection');
                    AdminUpdateVesselCollection({
                        vesselID: vessel.vesselID,
                        collectionName: 'shipsLog',
                        limit: 100,
                        fieldName: 'image',
                        newValue: ''
                    })
                        .then(function(result) {
                            // var sanitizedMessage = result.data.text;
                            // console.log('Result',sanitizedMessage);
                            // vessel.bugFixed = true;
                            console.log('Message', result.data.message);
                            NProgress.done();
                        })
                        .catch(function(error) {
                            console.log('Error', error.text);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        updateVesselIndexes: function(vesselID, vesselName) {
            let self = this;
            let message;
            message = {
                title: 'Update indexes on ' + vesselName + '?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    var AdminUpdateVesselIndexes = firebase.functions().httpsCallable('AdminUpdateVesselIndexes');
                    AdminUpdateVesselIndexes({
                        vesselID: vesselID
                    })
                        .then(function(result) {
                            // var sanitizedMessage = result.data.text;
                            // console.log('Result',sanitizedMessage);
                            console.log('Message', result.data.message);
                            NProgress.done();
                        })
                        .catch(function(error) {
                            console.log('Error', error.text);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        setPlanID(vessel) {
            console.log(vessel);
            this.selectedVessel = vessel;
            this.showSetPlanID = true;
        },

        setSquarePlanID() {
            let self = this;
            NProgress.start();
            db.collection('vessels')
                .doc(self.selectedVessel.vesselID)
                .update({
                    currentSquarePlanID: self.selectedVessel.currentSquarePlanID
                });
            setTimeout(() => {
                self.close();
                NProgress.done();
            }, 500);
        },

        showPaymentHistoryModal(vessel) {
            this.selectedVessel = vessel;
            this.showPaymentHistory = true;
        },

        updatePaymentHistory() {
            let self = this;
            NProgress.start();
            db.collection('vessels')
                .doc(self.selectedVessel.vesselID)
                .update({
                    paymentHistory: firebase.firestore.FieldValue.arrayUnion({
                        action: self.newPayment.action,
                        date: new Date(self.newPayment.date),
                        amount: self.newPayment.amount,
                        invoice: self.newPayment.invoice,
                        method: self.newPayment.method,
                        notes: self.newPayment.notes,
                        username: self.newPayment.username,
                        userID: self.newPayment.userID
                    }),
                    lastPaymentEntryDate: new Date()
                });
            setTimeout(() => {
                self.close();
                NProgress.done();
            }, 500);
        },

        async updateKeyData() {
            let self = this;
            console.log(
                self.selectedSchedule.vesselID,
                self.selectedSchedule.vesselName,
                self.selectedSchedule.vesselUVI,
                self.selectedSchedule.vesselOwnerID
            );
            NProgress.start();
            await db
                .collection('vessels')
                .doc(self.selectedSchedule.vesselID)
                .update({
                    vesselName: self.selectedSchedule.vesselName,
                    vesselUVI: self.selectedSchedule.vesselUVI
                });
            await db
                .collection('companies')
                .doc(self.selectedSchedule.vesselOwnerID)
                .collection('companyVessels')
                .doc(self.selectedSchedule.vesselID)
                .update({
                    vesselName: self.selectedSchedule.vesselName,
                    vesselUVI: self.selectedSchedule.vesselUVI
                });
            setTimeout(() => {
                // self.close();
                NProgress.done();
            }, 500);
        },

        showUpdateModal(vessel) {
            console.log(vessel);
            let self = this;
            this.selectedVesselID = vessel.vesselID;
            this.selectedVesselName = vessel.vesselName;
            this.showUpdateSchedule = true;
            NProgress.start();
            self.loadingVesselData = true;
            db.collection('vessels')
                .doc(vessel.vesselID)
                .get()
                .then(doc => {
                    self.selectedSchedule.isExpired = vessel.isExpired ? vessel.isExpired : false;
                    self.selectedSchedule.isPaidFor = vessel.isPaidFor ? vessel.isPaidFor : false;
                    self.selectedSchedule.isFreebie = vessel.isFreebie ? vessel.isFreebie : false;
                    self.selectedSchedule.isPrivate = vessel.isPrivate ? vessel.isPrivate : false;
                    self.selectedSchedule.isDemo = vessel.isDemo ? vessel.isDemo : false;
                    self.selectedSchedule.freeTrialActive = vessel.freeTrialActive ? vessel.freeTrialActive : false;
                    self.selectedSchedule.lastChargeDate = vessel.lastChargeDate ? vessel.lastChargeDate : self.globalLastChargeDate;
                    self.selectedSchedule.lastVesselCharge = vessel.lastVesselCharge ? vessel.lastVesselCharge : 249;
                    self.selectedSchedule.nextChargeDate = vessel.nextChargeDate ? vessel.nextChargeDate : '';
                    self.selectedSchedule.nextVesselCharge = vessel.nextVesselCharge ? vessel.nextVesselCharge : 0;
                    self.selectedSchedule.adminNotes = vessel.adminNotes ? vessel.adminNotes : '';
                    self.selectedAdminNotes.push({
                        note: vessel.adminNotes ? vessel.adminNotes : '-',
                        timestamp: ''
                    });
                    self.selectedSchedule.aaaPlans = vessel.aaaPlans ? vessel.aaaPlans : [];
                    self.selectedSchedule.currentSquarePlanID = vessel.currentSquarePlanID ? vessel.currentSquarePlanID : '';
                    self.selectedSchedule.vesselName = doc.data().vesselName ? doc.data().vesselName : '';
                    self.selectedSchedule.vesselID = vessel.vesselID ? vessel.vesselID : '';
                    self.selectedSchedule.vesselUVI = doc.data().vesselUVI ? doc.data().vesselUVI : '';
                    self.selectedSchedule.vesselOwnerID = doc.data().vesselOwnerID ? doc.data().vesselOwnerID : '';
                    self.selectedSchedule.isCompanyVessel = doc.data().isCompanyVessel ? doc.data().isCompanyVessel : false;
                })
                .then(() => {
                    db.collection('vessels')
                        .doc(vessel.vesselID)
                        .collection('adminNotes')
                        .orderBy('timestamp', 'desc')
                        .onSnapshot(snap => {
                            self.selectedAdminNotes = [];
                            snap.forEach(docRef => {
                                self.selectedAdminNotes.push(docRef.data());
                            });
                        });
                })
                .then(() => {
                    self.loadingVesselData = false;
                    NProgress.done();
                })
                .catch(err => {
                    console.log('Error', err);
                    NProgress.done();
                });
        },

        updateVesselData() {
            let self = this;
            console.log(self.selectedSchedule);
            let t1 = new Date(self.selectedSchedule.lastChargeDate);
            let t2 = new Date(self.selectedSchedule.nextChargeDate);
            // console.log(t2.getTime());
            // console.log(t2.toUTCString());
            // console.log(t2.toISOString());

            NProgress.start();
            var adminUpdateVesselPaymentSchedule = firebase.functions().httpsCallable('adminUpdateVesselPaymentSchedule');
            adminUpdateVesselPaymentSchedule({
                vesselID: self.selectedVesselID,
                isExpired: self.selectedSchedule.isExpired,
                isPaidFor: self.selectedSchedule.isPaidFor,
                isFreebie: self.selectedSchedule.isFreebie,
                isPrivate: self.selectedSchedule.isPrivate,
                isDemo: self.selectedSchedule.isDemo,
                freeTrialActive: self.selectedSchedule.freeTrialActive,
                // lastChargeDate: new Date(self.selectedSchedule.lastChargeDate),
                lastChargeDate: t1.getTime(),
                lastVesselCharge: parseInt(self.selectedSchedule.lastVesselCharge),
                // nextChargeDate: new Date(self.selectedSchedule.nextChargeDate),
                nextChargeDate: t2.getTime(),
                nextVesselCharge: parseInt(self.selectedSchedule.nextVesselCharge),
                billingUpdated: self.selectedSchedule.billingUpdated,
                adminNotes: self.selectedSchedule.adminNotes,
                aaaPlans: self.selectedSchedule.aaaPlans,
                currentSquarePlanID: self.selectedSchedule.currentSquarePlanID
                // }).then(function(result) {
                //   self.close();
                //   NProgress.done();
            }).catch(function(err) {
                console.log(err);
                // self.close();
                this.updateErrorMessage = 'Something went wrong updating this vessel.';
                NProgress.done();
            });
            setTimeout(() => {
                self.close();
                this.updateErrorMessage = '';
                NProgress.done();
            }, 500);
        }
    }
};
</script>

<style lang="scss" scoped>
.site-wrapper .container {
    max-width: 1050px;
}
.buttons {
    &.no-margin {
        margin-bottom: 3px;
    }
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
input[type='text'],
input[type='tel'] {
    padding: 1rem 1rem;
}
textarea {
    padding: 1rem;
}
.update-message {
    color: red;
    margin-bottom: 1rem;
}
.form-group {
    label {
        top: auto;
        bottom: -25px;
    }
}
.form-group-reg {
    label {
        &.radio-label {
            display: inline-block;
            margin-left: 10px;
        }
    }
    input {
        &.small {
            width: 100%;
            max-width: 200px;
        }
    }
}
label {
    display: block;
}
.admin-notes {
    margin-top: 6px;
    .note {
        font-weight: bold;
    }
    .note-timestamp {
        margin-left: 10px;
        font-size: 0.9rem;
    }
}
.traffic-light {
    position: relative;
    span {
        float: left;
        margin-right: 10px;
    }
    .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
            background: red;
            &.active {
                background: red;
            }
        }
        &.orange {
            background: orange;
            &.active {
                background: orange;
            }
        }
        &.green {
            background: #03d203;
            &.active {
                background: #03d203;
            }
        }
    }
}
.red {
    color: red;
}
.modal {
    .modal-card {
        .modal-card-head {
            z-index: 999 !important;
        }
        .modal-card-foot {
            z-index: 99 !important;
            &.column {
                flex-direction: column;
                align-items: start;
            }
        }
    }
}
textarea {
    min-height: 80px;
    margin-top: 10px;
}
table {
    td {
        &.is-updated {
            color: green;
            font-weight: bold;
        }
        .small {
            font-size: 0.8rem;
            margin-top: 5px;
            text-align: center;
        }
    }
}
.pmt-wrapper {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .pmt-date {
        font-weight: bold;
    }
}
</style>
