<template>
  <div>
    <div class="site-wrapper no-header">
      <div class="container">
        <h3 class="is-size-5">Anything Goes</h3>
        <br />

        <span
            class="button is-warning is-medium"
            @click="runOnce()"
            >Add All Skills</span
          >

        <hr>



        <div class="input-wrapper">
          <label>Target Collection Path (no trailing slashes)</label>
          <input
            class="input"
            type="text"
            placeholder="Paste source doc path"
            v-model="targetPath"
          />
        </div>

        <div class="clear"></div>

        <div>
          <label>Key Field</label>
          <input
            class="input"
            type="text"
            placeholder="Type key field.."
            v-model="keyField"
          />
        </div>

        <div class="clear"></div>

        <div class="buttons">
          <span
            :disabled="showButton"
            class="button is-warning is-medium"
            @click="loadDocuments()"
            >Load All Documents</span
          >
        </div>

        <hr />

        <!-- <div>
          <div v-for="(item, idx) in allData" :key="`item-${idx}`">
            <div>{{ item[keyField] }}</div>
          </div>
        </div> -->

        <!-- {{
          allData.reduce((acc, item) => acc + item.companyHazards, 0).toFixed(2)
        }} -->

        <table class="table fullwidth">
          <tr>
            <th>ID</th>
            <th>REV</th>
            <th>IND</th>
            <th>CUST</th>
            <th>START</th>
            <th>INCID</th>
            <th>SHIP</th>
          </tr>
          <tr v-for="(item, idx) in allData" :key="`item-${idx}`">
            <th>{{ item.vesselID }}</th>
            <th>{{ item.companyHazards }}</th>
            <th>{{ item.crewSelfInductions }}</th>
            <th>{{ item.customLogs }}</th>
            <th>{{ item.logStartups }}</th>
            <th>{{ item.logIncident }}</th>
            <th>{{ item.shipsLog }}</th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import { db } from "../main.js";
import "firebase/compat/auth";
import "firebase/firestore";
import "firebase/compat/functions";
import NProgress from "nprogress";
//import * as moment from 'moment';

export default {
  components: {},
  mounted: function() {
    firebase.auth().onAuthStateChanged(this.onUserLogin);
  },

  data: function() {
    return {
      newTitle: "",
      newDetails: "",
      newIndex: 99,
      // collectionPath: 'helperEmergencyProcedures',
      targetPath: "",
      keyField: "",
      allData: [],
      allVesselIDs: [
        "IxvUqmjoIR7nyi1Zmf7h",
        "YCPVi20DBVb7WN2pWhmp",
        "nhBmu3immGGfpvUa6gDu",
        "p4O92jvKJCef4AuwHGvC",
        "pIgbIKhhudUUYCtzoYgg",
        "zsTqVgTkOEZaE03srpSL",
        "0fPFANgjpkBc1IwN9H03",
        "3mqLwAZPH2QmiixjhVAl",
        "DJimHYhgOHGcx8djzyf7",
        "NmYWc6VbKOmYuUgF0ZYu",
        "aNUt6HLLcrTg64QmkqjO",
        "anBD9Ug8XRlND23rsyWq",
        "gSs6Zgybcwdiwje9BYUv",
        "v8FVZAKrbMcyVrx1ZlB8",
        "yNnEF6y0XVQfV2UYCLg5",
        "1MZfFb0MQ4qeiptt6btw",
        "4mA3d03crlVrcPvNwNFh",
        "KrsyvTXhsCuxzJZMwk7y",
        "PI3SzriUZRHToowWmQKE",
        "RK7dHjsLZMlNdnB8oosS",
        "Re4RQ8WFgb7O5sVRM3hO",
        "eaur4YDqeb5mDDCBkICP",
        "ipCjSswa2XUaXqgSsPyu",
        "j5FwrZ0eJ69GKmlTnW7p",
        "q8Cla66O0jEeovUAYooL",
        "qRHKxnd1xGRHUFn084su",
        "xwfYcg2giydf0ICzxgzp",
        "6A8fNQ41mkiVrSMtdZj4",
        "9a2B54xEFsel2WILapH1",
        "AeYml8cygE3zlPqODHnA",
        "DRD36Wgoc4miEeszcAdB",
        "ReI022nzJAStWasKu3CG",
        "VC5Vrat8NGGvIyZ9RTyu",
        "XixAle2VbxcGhZVX7Uh1",
        "ZgbxlRXei5OhipY3s9z0",
        "gijXdA54JrHISafARpLz",
        "s5KE86FKupgyQ5LYYm2y",
        "aYO8GukifiYzkGA43nTT",
        "cVxr3ZHcdRNWP0uVh7xx",
        "uhN2rSBpD1qtmvks54Af",
        "VvAh41JrE3AOgGfvPiVK",
        "mFSmVlZMTUhe4JxT0c7M",
        "EWt1kshKHvkRfI8xAuIK",
        "LFtn4DEUjbLFNIFMsYEH",
        "U71bt1TPCJe9IL03JReB",
        "aFCvbvb53QanwFTAh7s1",
        "l5yphLZSNUhdhcKfaeQk",
        "leiZG4Mob3t66Vcxl9pq",
      ],
      fleetIDs: [
        "5gVTBE76osre3EjyomzI",
        "4jRxttP0lufRb5K9Dyb9",
        "TaitISYJshLaDjBX4Nqu",
        "XkeHscZartWbfIqYJT6D",
        "43SRCAPEMXM4YfyjiD1e",
        "DdM5kJnKkaIo1hzo3IA7",
        "yCFDxLYWzG9K2XnPXxl0",
      ],
      theList: [
        "Marine regulations knowledge",
        "Navigation proficiency",
        "Seamanship expertise",
        "Chart reading skills",
        "Effective communication",
        "Strong teamwork abilities",
        "Marine technology familiarity",
        "Equipment operation proficiency",
        "Radar systems knowledge",
        "GPS navigation skills",
        "Emergency handling capability",
        "First aid knowledge",
        "Lifesaving techniques proficiency",
        "Vessel maintenance experience",
        "Basic mechanical skills",
        "Electrical skills competency",
        "Weather analysis proficiency",
        "Voyage planning expertise",
        "Calmness under pressure",
        "Problem-solving ability",
        "Decision-making skills",
        "Adaptability to change",
        "Cargo handling knowledge",
        "Stowage procedure understanding",
        "Loading/unloading efficiency",
        "International maritime laws comprehension",
        "Customs procedure awareness",
        "Ship handling expertise",
        "Ship manoeuvering skills",
        "Ship stability knowledge",
        "Propulsion systems understanding",
        "Ship maintenance experience",
        "Boat repair skills",
        "Ship security awareness",
        "Ship firefighting knowledge",
        "Ship pollution prevention understanding",
        "Ship emergency response skills",
        "Ship evacuation procedures knowledge",
        "Ship communication proficiency",
        "Ship navigation equipment operation",
        "Ship safety equipment operation",
        "Firefighting skills",
        "Shipboard safety awareness",
        "Shipboard emergency response",
        "Leadership skills",
        "Teamwork abilities",
        "Shipboard conflict resolution",
        "Shipboard crisis management",
        "Watchkeeping experience",
        "Cargo operations",
        "Shipboard deck operations",
        "Shipboard electrical operations",
        "Shipboard communication systems",
        "Shipboard machinery maintenance",
        "Shipboard electrical maintenance",
        "Shipboard safety drills",
        "Shipboard pollution prevention",
        "Shipboard waste management",
        "Shipboard navigation planning",
        "Shipboard bridge resource management",
        "Bridge watchkeeping",
        "Shipboard bridge leadership",
        "Decision-making",
        "Shipboard bridge situational awareness",
        "Shipboard bridge collision avoidance",
        "Shipboard bridge chart corrections",
        "Shipboard bridge logbook maintenance",
        "Shipboard navigation rules knowledge",
        "Shipboard passage planning",
        "Shipboard anchor handling",
        "Shipboard mooring operations",
        "Shipboard cargo securing",
        "Shipboard ballast operations",
        "Shipboard fuel management",
        "Engine room safety",
        "Engine room emergency response",
        "Engine room resource management",
        "Engine room equipment operation",
        "Engine room troubleshooting",
        "Engine room repairs",
        "Engine room systems knowledge",
        "Engine room pollution prevention",
      ],
      qualsList: [
        "General Purpose Hand NC",
        "Coxswain Grade 3 NC",
        "Coxswain Grade 2 NC",
        "Coxswain Grade 1 NC",
        "Sailing Master Coastal NC",
        "Sailing Master Offshore NC",
        "Master (Inland waters) NC",
        "Master <24m NC",
        "Master <45m NC",
        "Master <100m NC",
        "Marine Engine Driver Grade 3 NC",
        "Marine Engine Driver Grade 2 NC",
        "Marine Engine Driver Grade 1 NC",
        "Engineer Class 3 NC",
        "Mate <80m",
        "Master <35m N",
        "Master <80m N",
      ]
    };
  },

  computed: {
    isSteve() {
      return (
        this.user.uid == "3wTzZecD4QYOPUk4IyWRA2JmDcJ2" ||
        this.user.uid == "M3GGz7qWIxNqAsN2Ojglrv334jm1"
      );
    },
    showButton() {
      return this.keyField == "" || this.targetPath == "";
    },
  },

  methods: {
    onUserLogin: function(user) {
      if (user) {
        // console.log(user);
      }
    },

    async runOnce() {
      let self = this;
      const slugify = str => str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');

      let message;
      message = {
        title: "Sure??",
        body: "Click YES below proceed.",
      };
      let options = {
        okText: "YES, RUN ONCE!",
        backdropClose: true,
        cancelText: "Cancel",
      };
      this.$dialog
        .confirm(message, options)
        .then(async function(dialog) {
          NProgress.start();
          for await (const [index, value] of self.qualsList.entries()) {
            // console.log(index,slugify(value))
            db.collection('publicProfileData/allData/pooledProfileQuals').add({
              index: index,
              title: value,
              slug: slugify(value),
              categories: ["AMSA"]
            });
          }
          console.log('done');
          NProgress.done();
          
        })
        .catch(function(error) {
          console.log(error);
          NProgress.done();
        });
    },

    async loadDocuments() {
      let self = this;
      NProgress.start();
      this.allData = [];
      // let colRef = db.collection(this.targetPath);
      // return colRef.get().then((snap) => {
      //   self.allData = [];
      //   snap.forEach((doc) => {
      //     let data = doc.data();
      //     data.id = doc.id;
      //     self.allData.push(data);
      //   });
      // });
      const sleep = (milliseconds) =>
        new Promise((resolve) => setTimeout(resolve, milliseconds));

      await Promise.all(
        self.fleetIDs.map(async (vessel) => {
          let data = {
            vesselID: vessel,
          };
          db.collection("companies/" + vessel + "/companyHazards")
            .get()
            .then((snap) => {
              console.log(snap.size);
              data.companyHazards = snap.size;
              self.allData.push(data);
            })
            .then(() => {
              NProgress.done();
            })
            .catch((err) => {
              console.log("err", err);
              NProgress.done();
            });
          await sleep(1000);
          // db.collection("vessels/" + vessel + "/crewSelfInductions")
          //   .get()
          //   .then((snap) => {
          //     data.crewSelfInductions = snap.size;
          //   });
          // db.collection("vessels/" + vessel + "/customLogs")
          //   .get()
          //   .then((snap) => {
          //     data.customLogs = snap.size;
          //   });
          // db.collection("vessels/" + vessel + "/logStartups")
          //   .get()
          //   .then((snap) => {
          //     data.logStartups = snap.size;
          //   });
          // db.collection("vessels/" + vessel + "/logIncident")
          //   .get()
          //   .then((snap) => {
          //     data.logIncident = snap.size;
          //   });
          // db.collection("vessels/" + vessel + "/shipsLog")
          //   .get()
          //   .then((snap) => {
          //     data.shipsLog = snap.size;
          //   });
          // db.collection("vessels/" + vessel + "/visitorCheckIns")
          //   .get()
          //   .then((snap) => {
          //     data.visitorCheckIns = snap.size;
          //   });
          // self.allData.push[data];
        })
      );
    },

    saveNewDocument() {
      let self = this;

      let message;
      message = {
        title: "Save new document?",
        body: "Click YES below proceed.",
      };
      let options = {
        okText: "YES, SAVE",
        backdropClose: true,
        cancelText: "Cancel",
      };
      this.$dialog
        .confirm(message, options)
        .then(function(dialog) {
          NProgress.start();

          // let newData = {
          //   type: self.newTitle,
          //   details: self.newDetails,
          //   archived: false,
          // }
          let newData = {
            title: self.newTitle,
            details: self.newDetails,
            index: self.newIndex,
          };

          var adminAddNewDocument = firebase
            .functions()
            .httpsCallable("adminAddNewDocument");
          adminAddNewDocument({
            collectionPath: self.targetPath,
            newData: newData,
          })
            .then(function(result) {
              var sanitizedMessage = result.data.text;
              console.log("Result", sanitizedMessage);
              console.log("Message", result.data.message);
              self.clear();
              NProgress.done();
            })
            .catch(function(error) {
              console.log("Error", error.text);
              NProgress.done();
            });
        })
        .catch(function(error) {
          console.log(error);
          NProgress.done();
        });
    },

    clear() {
      this.newTitle = "";
      this.newDetails = "";
      this.newIndex = "";
      this.targetPath = "";
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="text"],
input[type="tel"] {
  width: 100%;
  float: left;
  display: block;
  margin-bottom: 2rem;
  padding: 15px;
  height: auto;
  // &:after {
  //   content: "\f16c";
  //   font-family: "Font Awesome 5 Brands";
  //   color: red;
  // }
  // &.valid:after {

  // }
}
label {
  margin-bottom: 10px;
  display: block;
}
textarea {
  width: 100%;
  padding: 15px;
  min-height: 200px;
  margin-bottom: 2rem;
}
.icon-wrap {
  display: block;
  float: left;
  height: 35px;
  margin-left: 10px;
  font-size: 1.5rem;
  color: red;
  &.valid {
    color: green;
  }
}
</style>
