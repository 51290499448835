<template>

    <div class="site-wrapper no-header">

  		<div class="container">
  			
  			<div v-if="isAdmin">
          <div class="notification is-primary"><h3>All Action Tags</h3></div>

          <!-- <div v-if="isSteve" class="buttons">
            <span class="button is-danger" @click="deleteAllTags()">Delete All Tags</span>
          </div> -->

          <div class="tag-wrapper has-text-centered">
            <div class="tags has-addons has-text-centered">
              <span class="tag is-danger is-medium"><font-awesome-icon icon="stopwatch"/></span>
              <span class="tag is-success is-medium">{{timeNow}}</span>
            </div>
          </div>
          <div class="spacer s20"></div>

          <!-- <ul>
            <li>a1: {{a1}}</li>
            <li>a2: {{a2}}</li>
            <li>a3: {{a3}}</li>
            <li>b1: {{b1}}</li>
            <li>b2: {{b2}}</li>
            <li>b3: {{b3}}</li>
            <li>b4: {{b4}}</li>
            <li>b5: {{b5}}</li>
            <li>b6: {{b6}}</li>
            <li>b7: {{b7}}</li>
            <li>b8: {{b8}}</li>
            <li>b9: {{b9}}</li>
          </ul>

          <hr>

          <ul>
            <li v-for="result in sorted(results)" :key="result.tag">{{result}}</li>
          </ul> -->

          <table class="table">
            <tr>
              <th>Tag</th>
              <th>Count</th>
            </tr>
            <tr v-for="result in trimmed(sorted(results))" :key="result.tag">
              <td :style="{color: result.color}"><span class="caps bold">{{result.tag}}</span>: {{result.title}}</td>
              <td>{{result.index}}</td>
            </tr>
          </table>

          <!-- {{results}} -->

          <div v-if="actionTags.length">
            <ul class="listed">
              <!-- <li v-for="(item, index) in actionTags" :key="index">{{item.timestamp}}: {{item.tag}}</li> -->
              <li v-for="(item, index) in actionTags" :key="index" :style="{color: tagColors[item.tag]}">{{item.timestamp}}: {{tags[item.tag]}}</li>
            </ul>
          </div>

        </div>

  			<div v-else>This page is restricted.</div>

  		</div>

    </div>

</template>



<script>
// @ is an alias to /src
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; 
import * as moment from 'moment'

export default {
  name: 'Demo',

  data: function() {
    return {
      firebaseid: '',
      firebaseemail: '',
      firebasedisplayname: '',
      actionTags: [],
      timeNow: 'no time',

      tags: {
        a1: 'View app features',
        a2: 'Create a profile',
        a3: 'Login as a user',
        b1: 'View help center options',
        b2: 'View all vessels',
        b3: 'View user profile',
        b4: 'View personal timers (inc sea time)',
        b5: 'Access my notes and todo list',
        b6: 'Open QR code scanner',
        b7: 'Access favourites buttons',
        b8: 'View personal messages',
        b9: 'Open top right slide menu',
        c1: 'View my own vessels',
        c2: 'View vessels I work on',
        d1: 'Refresh vessels on pull down',
        d2: 'Search for a vessel to request crew access',
        e1: 'Refresh vessels on pull down',
        e2: 'Add my own free vessel',
        s1: 'Show QR modal',
        s2: 'Generate new vessel QR code',
        s3: 'View vessel QR code info page',
        s4: 'Show location pin modal',
        s5: 'Save new location',
        s6: 'View subscription / upgrade options',
        s7: 'View all vessel logs screen',
        s8: 'View vessel information',
        s9: 'View subscription / upgrade options',
        s10: 'View all vessel alerts',
        s11: 'View vessel equipment and inventory',
        s12: 'View the vessel SMS',
        s13: 'View the vessel operations manual',
        s14: 'View all vessel crew',
        s15: 'View self inductions screen',
        s16: 'Delete or remove a vessel',
        p1: 'Manage subscription for this vessel',
        p2: 'View Ships Log',
        p3: 'New startup log',
        p4: 'New quick maintenance log',
        p5: 'New incident log',
        p6: 'View past visitor check-ins',
        p7: 'New general safety briefing log',
        p8: 'New crew induction',
        p9: 'View all past trips',
        p10: 'View all vessel defects',
        p11: 'New fluids and engine hours log',
        p12: 'View plant and equipment',
        p13: 'View all schedules and drills',
        p14: 'View all timed activities',
        p15: 'New JSA log',
        p16: 'New mid trip activity log',
        p17: 'New shutdown log',
        p18: 'View passenger manifests',
        p19: 'New passenger safety briefing log',
        p20: 'New head couont log',
      },
      tagColors: {
        a1: 'red',
        a2: 'cadetblue',
        a3: 'grey',
        b1: 'orange',
        b2: 'mediumseagreen',
        b3: 'dodgerblue',
        b4: 'brown',
        b5: 'orangered',
        b6: 'skyblue',
        b7: 'sandybrown',
        b8: 'lightseagreen',
        b9: 'purple',

        c1: 'red',
        c2: 'cadetblue',
        d1: 'grey',
        d2: 'mediumseagreen',
        e1: 'dodgerblue',
        e2: 'cadetblue',
        s1: 'grey',
        s2: 'red',
        s3: 'brown',
        s4: 'orangered',
        s5: 'dodgerblue',
        s6: 'cadetblue',
        s7: 'lightseagreen',
        s8: 'purple',
        s9: 'red',
        s10: 'sandybrown',
        s11: 'dodgerblue',
        s12: 'grey',
        s13: 'brown',
        s14: 'dodgerblue',
        s15: 'orangered',
        s16: 'red',
        p1: 'mediumseagreen',
        p2: 'purple',
        p3: 'cadetblue',
        p4: 'dodgerblue',
        p5: 'purple',
        p6: 'grey',
        p7: 'red',
        p8: 'sandybrown',
        p9: 'brown',
        p10: 'lightseagreen',
        p11: 'dodgerblue',
        p12: 'mediumseagreen',
        p13: 'red',
        p14: 'brown',
        p15: 'orangered',
        p16: 'purple',
        p17: 'sandybrown',
        p18: 'dodgerblue',
        p19: 'lightseagreen',
        p20: 'red',
      },


    }
  },

  watch: {
    firebaseid: function() {
      this.isSteve = this.firebaseid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.firebaseid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false;
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    userData() {
      return this.$store.getters.getSubscriptionInfo;
    },
    isAdmin() {
      return this.$store.getters.getIsAdmin;
    },
    a1() {return this.actionTags.filter((tag) => tag.tag == 'a1').length; },
    a2() {return this.actionTags.filter((tag) => tag.tag == 'a2').length; },
    a3() {return this.actionTags.filter((tag) => tag.tag == 'a3').length; },
    b1() {return this.actionTags.filter((tag) => tag.tag == 'b1').length; },
    b2() {return this.actionTags.filter((tag) => tag.tag == 'b2').length; },
    b3() {return this.actionTags.filter((tag) => tag.tag == 'b3').length; },
    b4() {return this.actionTags.filter((tag) => tag.tag == 'b4').length; },
    b5() {return this.actionTags.filter((tag) => tag.tag == 'b5').length; },
    b6() {return this.actionTags.filter((tag) => tag.tag == 'b6').length; },
    b7() {return this.actionTags.filter((tag) => tag.tag == 'b7').length; },
    b8() {return this.actionTags.filter((tag) => tag.tag == 'b8').length; },
    b9() {return this.actionTags.filter((tag) => tag.tag == 'b9').length; },

    results() {
      return [
        {tag: 'a1', title: this.tags['a1'], index: this.a1, color: 'red' },
        {tag: 'a2', title: this.tags['a2'], index: this.a2, color: 'cadetblue' },
        {tag: 'a3', title: this.tags['a3'], index: this.a3, color: 'grey' },
        {tag: 'b1', title: this.tags['b1'], index: this.b1, color: 'orange' },
        {tag: 'b2', title: this.tags['b2'], index: this.b2, color: 'mediumseagreen' },
        {tag: 'b3', title: this.tags['b3'], index: this.b3, color: 'dodgerblue' },
        {tag: 'b4', title: this.tags['b4'], index: this.b4, color: 'brown' },
        {tag: 'b5', title: this.tags['b5'], index: this.b5, color: 'orangered' },
        {tag: 'b6', title: this.tags['b6'], index: this.b6, color: 'skyblue' },
        {tag: 'b7', title: this.tags['b7'], index: this.b7, color: 'sandybrown' },
        {tag: 'b8', title: this.tags['b8'], index: this.b8, color: 'lightseagreen' },
        {tag: 'b9', title: this.tags['b9'], index: this.b9, color: 'purple' },
      ];
    },

  },

  mounted() {
    this.$store.dispatch('setUser');
    this.$store.dispatch('SETSUBSCRIPTIONINFO');
    firebase.auth().onAuthStateChanged( this.onUserLogin ); 
    this.timeNow = moment().format('D MMMM YYYY (h:mm a)');
  },

  methods: {
    onUserLogin: function( user ) {
      if (user) {         
        this.firebaseemail = user.email;
        this.firebaseid = user.uid;
        this.firebasedisplayname = this.userData.username;
        this.loadActionTags();
      }
    },

    trimmed(array) {
      return array.slice(0, 5);
    },

    sorted: function(arr) {
      // Set slice() to avoid to generate an infinite loop!
      return arr.slice().sort(function(a, b) {
        // return a.index - b.index;
        return b.index - a.index;
      });
    },

    loadActionTags: function() {
      let self = this;
      db.collection('actionTags').orderBy('timestamp','desc').limit(500).onSnapshot(function(querySnapshot) {
      // db.collection('actionTags').limit(500).onSnapshot(function(querySnapshot) {
        self.actionTags = [];
        querySnapshot.forEach(function(doc) {
          // self.actionTags.push(doc.data())
          self.actionTags.push({
            id: doc.id,
            // timestamp: doc.data().timestamp ? doc.data().timestamp.toDate() : '',
            timestamp: moment(doc.data().timestamp.toDate()).fromNow(),
            tag: doc.data().tag ? doc.data().tag : '',
            userid: doc.data().userid ? doc.data().userid : '',
            os: doc.data().os ? doc.data().os : '',
            platform: doc.data().platform ? doc.data().platform : '',
          })
        });
      })
    },

    deleteAllTags() {
      this.actionTags.forEach((tag) => {
        db.collection('actionTags').doc(tag.id).delete();
      });
    },


  }


}
</script>



<style lang="scss" scoped>

  ul.listed {
    margin-left: 8px;
    margin-bottom: 10px;
  }

</style>