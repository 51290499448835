<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">All OffshoreSMS Fleets *NEW</h3>
                <hr />
                <div class="column">
                    <div class="fleet-wrapper">
                        <div class="fleet-item" v-for="(fleet, index) in allFleets" :key="`fleet-${index}`">
                            <div class="fleet-name">{{ fleet.companyName }}</div>
                            <div class="fleet-buttons">
                                <span
                                    class="btn"
                                    :class="[fleet.hasAlgoliaIndex == undefined ? '' : !fleet.hasAlgoliaIndex ? 'red' : 'green']"
                                    @click="
                                        selectedFleet = fleet;
                                        checkAlgolia();
                                    "
                                >
                                    <font-awesome-icon icon="search" />
                                </span>
                                <span
                                    class="btn"
                                    :class="[updatedFleets.includes(fleet.id) ? 'green' : '']"
                                    @click="
                                        selectedFleet = fleet;
                                        updateAlgolia();
                                    "
                                >
                                    <font-awesome-icon :icon="!updatedFleets.includes(fleet.id) ? 'minus' : 'check'" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '../main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
const SimpleModal = () => import('../reusables/SimpleModal.vue');
import algoliasearch from 'algoliasearch';

export default {
    name: 'AllFleetsNew',
    components: {
        // SimpleModal
    },
    mounted() {
        this.loadFleets();
    },

    data() {
        return {
            allFleets: [],
            selectedFleet: {},
            updatedFleets: []
        };
    },
    methods: {
        async loadFleets() {
            NProgress.start();
            db.collection('companies')
                .orderBy('companyName', 'asc')
                .onSnapshot(querySnapshot => {
                    this.allFleets = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        data.hasAlgoliaIndex = doc.data().hasAlgoliaIndex;
                        data.updated = false;
                        // data.algoliaObjectID = doc.data().algoliaObjectID ? doc.data().algoliaObjectID : '';
                        let expired = doc.data().isExpired ? doc.data().isExpired : false;
                        if (!expired) this.allFleets.push(data);
                    });
                    NProgress.done();
                });
        },

        async checkAlgolia() {
            let searchClient = algoliasearch('789GF6G7HW', '0dfd28f6ac249c3ebef88e13f6adbc34');
            let searchIndex = searchClient.initIndex('app_fleets');

            // if (this.selectedFleet.algoliaObjectID == '') return;
            NProgress.start();
            let self = this;
            // this.selectedFleet.hasIndex = false;

            console.log(this.selectedFleet.id);
            setTimeout(() => {
                searchIndex
                    .getObject(self.selectedFleet.id)
                    .then(async object => {
                        await db
                            .collection('companies')
                            .doc(self.selectedFleet.id)
                            .update({
                                hasAlgoliaIndex: true
                            });
                        NProgress.done();
                    })
                    .catch(async err => {
                        console.log(err);
                        console.log('No user index found');
                        await db
                            .collection('companies')
                            .doc(self.selectedFleet.id)
                            .update({
                                hasAlgoliaIndex: false
                            });
                        NProgress.done();
                    });
            }, 200);
        },

        updateAlgolia() {
            let self = this;
            let searchClient = algoliasearch('789GF6G7HW', '0dfd28f6ac249c3ebef88e13f6adbc34');
            let searchIndex = searchClient.initIndex('app_fleets');
            NProgress.start();
            let fleet = {};
            fleet.objectID = this.selectedFleet.id;
            fleet.fleetID = this.selectedFleet.id;
            fleet.fleetName = this.selectedFleet.companyName;
            fleet.fleetLocation = this.selectedFleet.fleetLocation ? this.selectedFleet.fleetLocation : this.selectedFleet.companyLocation;
            fleet.isExpired = this.selectedFleet.isExpired ? this.selectedFleet.isExpired : false;
            fleet.isEnterprise = this.selectedFleet.isEnterprise ? this.selectedFleet.isEnterprise : false;
            searchIndex
                .saveObject(fleet)
                .then(async object => {
                    console.log('object', object);
                    await db
                        .collection('companies')
                        .doc(self.selectedFleet.id)
                        .update({
                            hasAlgoliaIndex: true
                        });
                    self.updatedFleets.push(self.selectedFleet.id);
                    NProgress.done();
                })
                .catch(async err => {
                    console.log(err);
                    console.log('Error creating/updating fleet object');
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.fleet-wrapper {
    .fleet-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border: 1px solid #e4e4e4;
        border-radius: 3px;
        margin-bottom: 5px;
        .fleet-name {
        }
        .fleet-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 80px;
            .btn {
                background-color: #e4e4e4;
                color: #222;
                padding: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                cursor: pointer;
                &.red {
                    background-color: red;
                    color: #fff;
                }
                &.green {
                    background-color: mediumseagreen;
                    color: #fff;
                }
            }
        }
    }
}
</style>
