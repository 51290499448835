<template>
  <main id="app" class="">

    <nprogress-container></nprogress-container>    

        <div class="header">
          <router-link to="/">
            <img src="./assets/img/offshoresms-new-logo.png" alt="Offshore SMS">
          </router-link>
          <mobile-nav></mobile-nav>
        </div>

        <div class="header-spacer"></div>
  

        <router-view></router-view>

        <!-- <div class="notification force-refresh" @click="refreshApp" v-show="showRefreshButton">
          <button @click="showRefreshButton = true" class="delete is-small"></button>
          New content available!<br>Click to refresh.
        </div> -->

        <back-to-top bottom="60px" right="20px" visibleoffset="200">
          <div class="btt">
            <font-awesome-icon icon="chevron-up" />
          </div>
        </back-to-top>

  </main>
</template>


<script>

import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import MobileNav from './components/MobileNav.vue';
import NprogressContainer from 'vue-nprogress/src/NprogressContainer';

import Vue from 'vue';

Vue.config.devtools = true;



// Custom Directive to close modals on click outside
let handleOutsideClick;
Vue.directive('closable', {
  bind (el, binding, vnode) {
    // Here's the click/touchstart handler
    handleOutsideClick = (e) => {
      e.stopPropagation()
      // Get the handler method name and the exclude array from the object used in v-closable
      const { handler, exclude } = binding.value
      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        // We only run this code if we haven't detected any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          const excludedEl = vnode.context.$refs[refName]
          // See if this excluded element is the same element the user just clicked on
          clickedOnExcludedEl = excludedEl.contains(e.target)
        }
      })
      // We check to see if the clicked element is not the modal element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the modal and not the button, then call the outside-click handler from the same component this directive is used in
        vnode.context[handler]()
      }
    }
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unbind () {
    // If the element that has v-closable is removed, then unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
});



export default {
  components: {
    mobileNav : MobileNav,
    NprogressContainer,
  },

  data: function() {
    return {
      registration: '',
    }
  },

  methods: {
    

  }

};

</script>





<style lang="scss">

  .btt {
    border-radius: 5px;
    background: #ddd;
    display: block;
    border: none;
    color: white;
    font-size: 20px;
    padding: 3px 11px;
    transition: 0.3s;
    &:hover {
      opacity: 1;
      background: #00d1b2;
    }
  }

</style>
