<template>

	<div>								

		<div class="container">


      <div class="spacer s20"></div>

			<!-- {{sentUser}} -->

      <div class="notification is-info"><h3>Update {{sentUser.username}}'s profile</h3></div>

      <label>User ID:</label>
			<input class="input" type="text" placeholder="User ID" v-model="userid" />

			<div v-if="userid != ''">

        <label>Set a New Password:</label>
				<input class="input" type="text" placeholder="New password..." v-model="updatedPassword" />

        <label>User Role:</label>
        <div class="select is-primary">
          <select v-model="userrole">
            <option value="free" default>Free</option>
            <option value="starter">Starter</option>
            <option value="fleet">Fleet</option>
          </select>
        </div>

        <label>Next Charge Amount:</label>
        <input class="input" type="number" placeholder="Next charge amount" v-model="nextCharge" />

        <label>Max Vessels:</label>
        <input class="input" type="number" placeholder="Max vessels" v-model="maxVessels" />

        <label>Anniversary Date:</label>
        <div class="form-group-reg">   
          <datetime placeholder="Anniversary Date" auto v-model="anniversaryDate"></datetime>
        </div>
        {{anniversaryDate}}


        <div class="spacer s50"></div>

        <div class="buttons">
  				<span class="button is-primary" @click="updateProfile()">Update Profile</span>
          <button class="button is-info" @click="goBack()">&nbsp;Back &nbsp;</button>
        </div>


        <div class="spacer s50"></div>



			</div>



		</div>

	</div>

</template>



<script>

import firebase from 'firebase/compat/app';
import { db } from '../main.js';
//import 'firebase/compat/auth'; 
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {

	name: 'UpdateProfile',

	data: function() {
		return {
			userid: '',
			updatedPassword: '',
      anniversaryDate: '',
      userrole: '',
      nextCharge: 0,
      maxVessels: 0,
		}
	},

	props: {
      sentUser: Object,
 	},

	mounted() {
		if (this.sentUser) {
			this.userid = this.sentUser.userid;
      this.anniversaryDate = this.sentUser.anniversaryDate == undefined ? '' : this.sentUser.anniversaryDate.toISOString();
      this.nextCharge = this.sentUser.nextCharge ? this.sentUser.nextCharge : 0;
      this.userrole = this.sentUser.userrole ? this.sentUser.userrole : 'free';
      this.maxVessels = this.sentUser.maxVessels ? parseInt(this.sentUser.maxVessels) : 0;
		}
	},

	computed: {
		showButton() {
			return this.updatedPassword == '';
		},
	},

	methods: {

		updateProfile() {
			let self = this;

			let profileArray = {
				password: self.updatedPassword,
			}
      let finalDate = moment(self.anniversaryDate).toDate().toISOString();
      // let finalDateB = firebase.firestore.Timestamp.fromDate(new Date(self.anniversaryDate));
      // let finalDateB = new Date();
      let updateArray = {
        userrole: self.userrole,
        nextCharge: self.nextCharge,
        maxVessels: parseInt(self.maxVessels),
        anniversaryDate: finalDate,
      }
      console.log(updateArray);

      let message = {
        title: 'Update Profile?',
        body: 'Click YES below to proceed.'
      };
      let options = {
        okText: 'YES, UPDATE',
        backdropClose: true,
        cancelText: 'Cancel',
      };
      this.$dialog
        .confirm(message, options)
        .then(function(dialog) {
            
            NProgress.start();
            
            // update password if set
            if (self.updatedPassword != '') {
              console.log('PW changed');

              var AdminUpdateUserPassword = firebase.functions().httpsCallable('AdminUpdateUserPassword');
              AdminUpdateUserPassword({
                  userID: self.userid,
                  updateArray: profileArray
              })
              .then(function(result) {
                var sanitizedMessage = result.data.text;
                console.log('Result',sanitizedMessage);
                console.log('Message',result.data.message);

              })
              .catch(function(error) {
                console.log('Error',error.text);
              }); 

            }

            //update user profile
            var AdminUpdateUserProfile = firebase.functions().httpsCallable('AdminUpdateUserProfile');
            AdminUpdateUserProfile({
              userID: self.userid,
              updateArray: updateArray
            })
            .then(function(result) {
              console.log('Result',result.data.message);

            })
            .catch(function(error) {
              console.log('Error',result.data.message);
            }); 

            setTimeout(function() { 
              self.$router.push({
                name: 'Payments',
              });
              NProgress.done();
            }, 2000);



        })
        .catch(function(error) {
          console.log(error); 
          NProgress.done();
        });  


		},

    changeField(field) {
      console.log('Field changed: ',field);
    },

    goBack: function() {
      this.$router.go(-1);
    },


	},

 

}


</script>

<style lang="scss" scoped>

	input[type="text"],
  input[type="number"] {
		padding: 30px;
		margin-bottom: 1rem;
	}
  label {
    margin-bottom: 5px;
    margin-top: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
  }

</style>

