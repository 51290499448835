<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <div class="buttons">
                    <button class="button is-info" @click="goBack()">
                        <span><font-awesome-icon icon="chevron-left"/></span> &nbsp;Back &nbsp;
                    </button>
                </div>

                <h3 class="is-size-5">Vessel Tools</h3>
                <br />

                <hr />

                <div class="buttons">
                    <router-link class="button is-info is-medium" to="admin-create-user"
                        ><font-awesome-icon icon="user" /> &nbsp; Create a New User</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-primary is-medium" to="admin-add-vessel"
                        ><font-awesome-icon icon="ship" /> &nbsp; Add a New Vessel to a User</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-link is-medium" to="admin-add-existing-vessel"
                        ><font-awesome-icon icon="ship" /> &nbsp; Add an existing Vessel to a User</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-warning is-medium" to="landing-fix-sms"
                        ><font-awesome-icon icon="wrench" /> &nbsp; Update / Fix an SMS</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-skip is-medium" to="create-vessel-invoice"
                        ><font-awesome-icon icon="list-ul" /> &nbsp; Create a Vessel Invoice</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-light is-medium" to="print-vessel-invoice"
                        ><font-awesome-icon icon="list-ul" /> &nbsp; Print a Vessel Invoice</router-link
                    >
                </div>

                <hr />

                <div class="buttons">
                    <router-link class="button is-info is-medium" to="admin-create-fleet"
                        ><font-awesome-icon icon="user-graduate" /> &nbsp;Create a New Fleet</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-link is-medium" to="rename-fleet"
                        ><font-awesome-icon icon="ship" /> &nbsp; Rename a Fleet</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-danger is-medium" to="delete-fleet"
                        ><font-awesome-icon icon="trash-alt" /> &nbsp; Delete a Fleet</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-primary is-medium" to="admin-add-fleet-vessel"
                        ><font-awesome-icon icon="ship" /> &nbsp; Add a Vessel to a Fleet</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-dark is-medium" to="admin-add-fleet-user"
                        ><font-awesome-icon icon="ship" /> &nbsp; Add a User to a Fleet</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-skip is-medium" to="move-vessel-to-fleet"
                        ><font-awesome-icon icon="ship" /> &nbsp; Move a Personal vessel to a Fleet</router-link
                    >
                </div>

                <hr />

                <div class="buttons">
                    <router-link class="button is-info is-medium" to="move-teams-between-fleets">
                        <font-awesome-icon icon="users" /> &nbsp; Move team members between fleets
                    </router-link>
                </div>

                <div class="buttons">
                    <router-link class="button is-info is-medium" to="move-fleet-items-between-fleets">
                        <font-awesome-icon icon="list-ul" /> &nbsp; Move fleet content between fleets
                    </router-link>
                </div>

                <div class="buttons">
                    <router-link class="button is-info is-medium" to="move-vessel-between-fleets">
                        <font-awesome-icon icon="ship" /> &nbsp; Move a vessel between Fleets
                    </router-link>
                </div>

                <hr />

                <div class="buttons">
                    <span class="button is-warning is-medium" @click="updateAllVesselsCollection()"
                        ><font-awesome-icon icon="wrench" /> &nbsp; Fix Ships Log Image Bug</span
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/main.js';
// import 'firebase/compat/auth';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {
    name: 'CustomersVessels',
    components: {},
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {};
    },

    computed: {
        userData() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.userData
                ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2'
                    ? true
                    : false
                : false;
        }
    },

    methods: {
        onUserLogin: function(user) {
            // if (user) {
            // }
        },

        goBack() {
            this.$router.go(-1);
        },

        updateAllVesselsCollection() {
            let self = this;
            let message;
            message = {
                title: 'Update all vessels?',
                body: 'Click YES below to proceed with caution.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    var AdminUpdateAllVesselsCollection = firebase.functions().httpsCallable('AdminUpdateAllVesselsCollection');
                    AdminUpdateAllVesselsCollection({
                        collectionName: 'shipsLog',
                        limit: 1000,
                        updateData: {
                            image: ''
                        }
                    })
                        .then(function(result) {
                            // var sanitizedMessage = result.data.text;
                            // console.log('Result',sanitizedMessage);
                            // vessel.bugFixed = true;
                            console.log('Message', result.data.message);
                            NProgress.done();
                        })
                        .catch(function(error) {
                            console.log('Error', error.text);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.site-wrapper .container {
    max-width: 850px;
}
svg {
    display: inline-block;
    margin-right: 10px;
}
</style>
