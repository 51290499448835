<template>
    <div>
        <div class="container" v-if="isLoggedIn">
            <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
            <div class="spacer s20"></div>
            <p>&nbsp;</p>
            <div class="notification is-light"><h3>Offshore SMS Admin App</h3></div>

            <!-- copy and paste. Modify height and width if desired. -->
            <!-- <iframe class="embeddedObject shadow resizable" name="embedded_content" scrolling="no" frameborder="0" type="text/html"
          style="overflow:hidden;" src="https://www.screencast.com/users/OffshoreSMS/folders/Capture/media/88b075b7-e787-4450-9124-383d9d2a8630/embed" height="495" width="250" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe> -->

            <div v-if="isAdmin">
                <div class="buttons">
                    <router-link class="button is-medium max is-info" to="active-trips">
                        <font-awesome-icon icon="ship" /> &nbsp; Active Vessel Trips
                    </router-link>
                    <router-link class="button max is-skip" to="activity">
                        <font-awesome-icon icon="list-ul" /> &nbsp; Recent User and Vessel Activity
                    </router-link>
                    <router-link v-if="isJosh || isSteve" class="button max is-danger" to="admin-add-fleet-user">
                        <font-awesome-icon icon="pen" /> &nbsp; Add a user to a fleet
                    </router-link>
                    <router-link v-if="isJosh || isSteve" class="button max is-primary" to="landing-build-content"
                        ><font-awesome-icon icon="pen" /> &nbsp; Update SMS Helper Content</router-link
                    >
                    <router-link v-if="isSteve" class="button max is-warning" to="customers-vessels"
                        ><font-awesome-icon icon="wrench" /> &nbsp; Management Mods & Fixes</router-link
                    >
                    <!-- <router-link class="button max is-dark" to="all-crm-timelines"><font-awesome-icon icon="users" /> &nbsp; &nbsp; View All Customer Timelines</router-link>
          <router-link class="button max is-light" to="crm-timelines"><font-awesome-icon icon="user" /> &nbsp; Individual Customer Timeline</router-link> -->
                    <router-link class="button max is-dark" to="all-timelines"
                        ><font-awesome-icon icon="users" /> &nbsp; &nbsp; View All Customer Timelines</router-link
                    >
                    <router-link class="button max is-light" to="user-timelines"
                        ><font-awesome-icon icon="user" /> &nbsp; Individual Customer Timeline</router-link
                    >
                </div>

                <hr v-if="isSteve" />

                <div class="buttons">
                    <!-- <router-link v-if="isSteve" to="find-user" class="button max is-success">
                            <font-awesome-icon icon="search" /> &nbsp; Find a User
                        </router-link> -->
                    <router-link v-if="isSteve || isRob" to="users" class="button max is-info">
                        <font-awesome-icon icon="users" /> &nbsp; All app users
                    </router-link>
                    <router-link v-if="isSteve" to="admin-vessels" class="button max is-primary">View all vessels</router-link>

                    <router-link v-if="isSteve" to="all-fleets" class="button max is-dark">
                        <span><font-awesome-icon icon="ship"/></span>&nbsp; View all fleets only
                    </router-link>

                    <router-link v-if="isSteve" to="all-fleets-new" class="button max is-success">
                        <span><font-awesome-icon icon="star"/></span>&nbsp; All Fleets NEW
                    </router-link>

                    <router-link to="all-fleet-data" class="button max is-dark">
                        <span><font-awesome-icon icon="ship"/></span>&nbsp; All fleet Data
                    </router-link>

                    <router-link v-if="isSteve" to="admin-vessel-status" class="button max is-skip">All Vessel Payments</router-link>
                    <router-link v-if="isSteve" to="admin-payments" class="button max is-success">
                        <font-awesome-icon icon="money-bill" /> &nbsp; All Vessel Payments NEW
                    </router-link>
                </div>

                <div class="buttons">
                    <router-link v-if="isSteve" class="button max is-warning" to="custom-exports-inductions">
                        <font-awesome-icon icon="globe" /> &nbsp; Custom Exports
                    </router-link>
                </div>

                <div class="buttons">
                    <router-link v-if="isSteve" class="button is-medium is-primary" to="conversations">
                        <font-awesome-icon icon="phone" /> &nbsp; SMS Conversations
                    </router-link>
                </div>

                <hr v-if="isSteve" />

                <div v-if="isSteve || isRob" class="buttons">
                    <router-link to="business-data" class="button is-light is-medium">
                        <font-awesome-icon icon="globe" /> &nbsp; Business Data
                    </router-link>
                </div>

                <!-- <div v-if="isSteve" class="buttons">
          <router-link to="all-fleets" class="button is-dark max"><span><font-awesome-icon icon="ship" /></span>&nbsp; View all fleets only</router-link>
        </div> -->

                <!-- <router-link class="button max is-skip is-medium" to="activity"><font-awesome-icon icon="list-ul" /> &nbsp; Recent User and Vessel Activity</router-link>
        <div class="spacer s20"></div>

        <div><router-link v-if="isJosh || isSteve" class="button max is-primary is-medium" to="landing-build-content"><font-awesome-icon icon="pen" /> &nbsp; Update SMS Helper Content</router-link></div>
        <div v-if="isJosh || isSteve" class="spacer s20"></div>

        <div><router-link v-if="isSteve" class="button max is-warning is-medium" to="customers-vessels"><font-awesome-icon icon="wrench" /> &nbsp; Management Mods & Fixes</router-link></div>
        <div v-if="isSteve" class="spacer s20"></div>

        <router-link class="button max is-dark is-medium" to="all-crm-timelines"><font-awesome-icon icon="users" /> &nbsp; &nbsp; View All Customer Timelines</router-link>
        <div class="spacer s20"></div>

        <router-link class="button max is-light is-medium" to="crm-timelines"><font-awesome-icon icon="user" /> &nbsp; Individual Customer Timeline</router-link>
        <div class="spacer s20"></div> -->

                <hr />

                <h3 class="is-size-5">CRM and Customer Follow Ups Due:</h3>

                <ul v-if="allFollowUps.length > 0" class="sms-steps steps is-vertical has-content-centered">
                    <li v-for="(step, index) in allFollowUps" :key="index" class="steps-segment" :class="{ due: step.isDue, past: step.isPast }">
                        <span class="steps-marker" :class="{ due: step.isDue, past: step.isPast }">
                            <span class="icon"><font-awesome-icon :icon="step.icon"/></span>
                        </span>
                        <div class="steps-content">
                            <p class="heading">
                                {{ formatDate(step.date, 'D MMM YYYY (h:mm a)') }}
                            </p>
                            <p @click="showDetails(step)" class="bold upper clickable" :class="{ due: step.isDue, past: step.isPast }">
                                {{ step.action }}
                            </p>
                            <p class="italic">{{ step.username }}</p>
                            <p>---------------------------</p>
                            <div v-for="line in step.notes.split('\n')" :key="line">{{ line }}<br /></div>
                            <div v-if="step.followUpAdded" class="follow-up">
                                Follow up added
                            </div>
                            <div class="spacer s10"></div>
                            <div v-if="isSteve" class="buttons">
                                <span class="button is-light" @click="markCompleted(step)">Mark as Complete</span>
                            </div>
                            <div class="spacer s10"></div>
                        </div>
                    </li>

                    <li class="steps-segment">
                        <span class="steps-marker">
                            <span class="icon"
                                ><span><font-awesome-icon icon="check"/></span
                            ></span>
                        </span>
                        <div class="steps-content">
                            <p class="heading">&nbsp;</p>
                            <div class="spacer s10"></div>
                        </div>
                    </li>
                </ul>

                <div v-else class="">
                    No follow up due today.
                </div>

                <div v-if="isSteve" class="buttons">
                    <router-link class="button is-primary is-medium" to="crm-follow-ups">All Follow Ups</router-link>
                </div>

                <div class="spacer s80"></div>

                <hr />
            </div>

            <div v-else>Access Restricted.</div>

            <div v-if="isSteve">
                <!-- <button class="button is-primary" @click="startNP()">Start NProgress</button>
        <button class="button is-danger" @click="stopNP()">Stop NProgress</button>
        <div class="spacer s30"></div> -->

                <!--  {{allCodes}}<hr>{{newCode}} -->

                <div class="dashtile-wrapper">
                    <router-link class="dashtile info" to="activity-dashboard">
                        <div class="content-wrap">
                            <font-awesome-icon icon="stopwatch" />
                            <p class="title">Activity Dashboard</p>
                        </div>
                    </router-link>

                    <router-link class="dashtile profile" to="all-stats">
                        <div class="content-wrap">
                            <font-awesome-icon icon="ship" />
                            <font-awesome-icon icon="users" />
                            <p class="title">Users &amp; Vessels</p>
                        </div>
                    </router-link>

                    <div class="clear"></div>
                </div>

                <div v-if="isSteve" class="dashtile-wrapper">
                    <router-link class="dashtile primary" to="landing-build-content">
                        <div class="content-wrap">
                            <font-awesome-icon icon="pen" />
                            <p class="title">Build SMS Content</p>
                        </div>
                    </router-link>

                    <router-link class="dashtile warning" to="customers-vessels">
                        <div class="content-wrap">
                            <font-awesome-icon icon="wrench" />
                            <p class="title">Mods &amp; Fixes</p>
                        </div>
                    </router-link>

                    <div class="clear"></div>
                </div>

                <div class="spacer s30"></div>

                <div v-if="isSteve">
                    <h4 class="heading">Add an email address to EmailOctopus</h4>
                    <input type="text" class="input" placeholder="Enter first name" v-model="occyName" />
                    <div class="spacer s10"></div>
                    <input type="text" class="input" placeholder="Enter email address" v-model="occyEmail" />
                    <div class="spacer s10"></div>
                    <div class="button is-dark" @click="updateEmailOctopus()">
                        Add Email Address
                    </div>
                    <div class="spacer s30"></div>
                </div>

                <!-- <div class="buttons">
          <router-link to="users" class="button is-info is-medium">View all app users</router-link>
        </div> -->
                <!--  <div class="buttons">
          <router-link to="activity" class="button is-warning is-medium">Recent user activity</router-link>
        </div> -->
                <!-- <div class="buttons">
          <router-link to="admin-vessel-status" class="button is-medium is-skip">All Vessel Payments</router-link>
        </div> -->

                <!-- <div v-if="isSteve" class="buttons">
          <router-link to="admin-vessels" class="button is-medium is-primary">View all vessels</router-link>
        </div> -->

                <div v-if="isSteve" class="buttons">
                    <router-link to="dev-timeline" class="button is-success is-medium">Update Dev Timeline</router-link>
                </div>

                <div v-if="isSteve" class="buttons">
                    <router-link to="anything-goes" class="button is-warning is-medium"
                        ><font-awesome-icon icon="globe" />&nbsp; Anything Goes</router-link
                    >
                </div>

                <div v-if="isSteve" class="buttons">
                    <router-link to="one-off-function-call" class="button is-dark">One off vessel action</router-link>
                </div>

                <div v-if="isSteve" class="buttons">
                    <router-link to="vessels-loop" class="button is-dark">Loop through all vessels</router-link>
                </div>

                <div v-if="isSteve" class="buttons">
                    <router-link to="audit-vessel" class="button is-light">Find a vessel</router-link>
                </div>

                <hr />

                <div v-if="isSteve" class="buttons">
                    <span @click="showAddCodesModal = true" class="button is-link">Create New Manual Vessel Codes</span>
                </div>

                <div v-if="isSteve" class="buttons">
                    <span @click="showAddWebLinkIDsModal = true" class="button is-link">Create New Web Link IDs</span>
                </div>

                <div v-if="isSteve" class="buttons">
                    <span @click="showAddProfileIDsModal = true" class="button is-success"
                        ><font-awesome-icon icon="users" /> &nbsp;Create New Profile Link Codes</span
                    >
                </div>

                <div v-if="isSteve" class="buttons">
                    <span @click="showAddSupplierPinsModal = true" class="button is-link">Create New Supplier Pin Codes</span>
                </div>

                <div v-if="isSteve" class="buttons">
                    <span
                        @click="
                            tempCodeName = 'Defect Weblink Codes';
                            showAddAnyCodesModal = true;
                            (usedCollection = 'defectWebLinkIDs'), (poolCollection = 'defectWebLinkIDsPool');
                        "
                        class="button is-link"
                        >Create New Defect Weblink Codes</span
                    >
                </div>

                <div v-if="isSteve" class="buttons">
                    <span
                        @click="
                            tempCodeName = 'Sms Enquiry Weblink Codes';
                            showAddAnyCodesModal = true;
                            (usedCollection = 'smsEnquiryUsedCodes'), (poolCollection = 'smsEnquiryCodesPool');
                        "
                        class="button is-skip"
                    >
                        Create New SMS Enquiry Weblink Codes
                    </span>
                </div>

                <hr />

                <!-- <div class="buttons">
          <router-link to="payments" class="button is-success">View payment status</router-link>
        </div> -->

                <!-- <div v-if="isSteve" class="buttons">
          <router-link to="admin-create-user" class="button is-danger">Create a new user</router-link>
        </div> -->

                <!-- <div v-if="isSteve" class="buttons">
          <router-link to="admin-add-vessel" class="button is-danger">Add new vessel to a user</router-link>
        </div> -->

                <!-- <div v-if="isSteve" class="buttons">
          <router-link to="admin-create-company" class="button is-danger"><span><font-awesome-icon icon="check" /></span>&nbsp; Create a new company</router-link>
        </div> -->

                <!-- <div class="buttons">
          <router-link class="button" to="update-profile">Update a user profile</router-link>
        </div> -->

                <div v-if="isSteve" class="buttons">
                    <router-link class="button" to="one-off-function-call">One off Function Call</router-link>
                </div>

                <div v-if="isSteve" class="buttons">
                    <router-link class="button" to="update-user-phone">Update a user phone number</router-link>
                </div>

                <div v-if="isSteve" class="buttons">
                    <router-link class="button is-skip" to="copy-sms">Copy a Vessel SMS</router-link>
                </div>

                <div v-if="isSteve" class="buttons">
                    <router-link class="button is-dark" to="update-counts"
                        ><span><font-awesome-icon icon="chevron-right"/></span>&nbsp; Update Counts</router-link
                    >
                </div>

                <div v-if="isSteve" class="buttons">
                    <router-link class="button is-skip" to="create-invoice">Create an Invoice</router-link>
                </div>

                <!-- <div v-if="isSteve" class="buttons">
          <router-link class="button is-skip" to="create-vessel-invoice">Create a Vessel Invoice</router-link>
        </div> -->

                <!-- <div class="buttons">
          <router-link class="button is-warning is-medium" to="user-timelines">Individual Timelines</router-link>
        </div>

        <div class="buttons">
          <router-link class="button is-success is-medium" to="all-timelines">All Timelines</router-link>
        </div> -->

                <!-- <div class="buttons">
          <router-link to="all-companies" class="button is-dark is-medium">View all Fleets</router-link>
        </div>

        <div class="buttons">
          <router-link to="fleet-vessels" class="button is-dark is-medium">View all Fleet vessels</router-link>
        </div>

        <div v-if="isSteve" class="buttons">
          <router-link to="all-fleet-admins" class="button is-dark">All Fleet Admins</router-link>
        </div> -->

                <div v-if="isSteve" class="buttons">
                    <span @click="showTestFCM = true" class="button is-light is-medium">Send Test FCM</span>
                </div>

                <div v-if="isSteve">
                    <hr />

                    <div class="buttons">
                        <router-link class="button is-info is-medium" to="write-document">Write a new document</router-link>
                    </div>
                    <div class="buttons">
                        <router-link class="button is-info is-medium" to="write-multiple-docs">Add docs from JSON array</router-link>
                    </div>
                    <div class="buttons">
                        <router-link class="button is-warning is-medium" to="write-custom-document">Write Custom Document</router-link>
                    </div>
                    <div class="buttons">
                        <router-link class="button is-skip is-medium" to="copy-document">Copy a document</router-link>
                    </div>
                    <div class="buttons">
                        <router-link class="button is-success is-medium" to="copy-multiple-documents">Copy multiple documents</router-link>
                    </div>
                    <div class="buttons">
                        <router-link class="button is-dark is-medium" to="update-single-document">Update a single document (fields)</router-link>
                    </div>
                    <hr class="dashed" />
                    <div class="buttons">
                        <router-link class="button is-link is-medium" to="copy-document-to-collections">Copy Document to Collections</router-link>
                    </div>
                    <div class="buttons">
                        <router-link class="button is-info is-medium" to="copy-collection-to-documents">Copy Collection to Documents</router-link>
                    </div>
                    <div class="buttons">
                        <router-link class="button is-link is-medium" to="copy-document-fields-to-documents"
                            >Copy Document Fields to Documents</router-link
                        >
                    </div>
                    <hr class="dashed" />
                    <div class="buttons">
                        <router-link class="button is-danger is-medium" to="delete-multiple-documents">Archive multiple documents</router-link>
                    </div>

                    <div class="buttons">
                        <router-link class="button is-dark is-medium" to="add-fleet-to-user">Add Fleet to User</router-link>
                    </div>

                    <!-- <div class="buttons">
              <router-link class="button is-dark is-medium" to="fix-sms-hazards">Update SMS Hazards</router-link>
            </div>

            <div class="buttons">
              <router-link class="button is-dark is-medium" to="fix-sms-contacts">Update SMS Contacts</router-link>
            </div>

            <div class="buttons">
              <router-link class="button is-dark is-medium" to="fix-sms-policies">Update SMS Policies</router-link>
            </div>

            <div class="buttons">
              <router-link class="button is-dark is-medium" to="fix-sms-sops">Update SMS Safe Work Procedures</router-link>
            </div>

            <div class="buttons">
              <router-link class="button is-dark is-medium" to="fix-sms-eops">Update SMS Emergency Procedures</router-link>
            </div>

            <div class="buttons">
              <router-link class="button is-dark is-medium" to="fix-sms-startups">Update SMS Startup Checks</router-link>
            </div>

            <div class="buttons">
              <router-link class="button is-dark is-medium" to="fix-sms-maintenance">Update SMS Maintenance Checks</router-link>
            </div>

            <div class="buttons">
              <router-link class="button is-dark is-medium" to="fix-sms-inductions">Update SMS Induction Checks</router-link>
            </div> -->

                    <!-- <div class="buttons">
              <span class="button is-dark" @click="onceFunction()">ONCE: Simple Userdata</span>
            </div> -->
                    <!-- <div class="buttons">
              <span class="button is-danger" @click="migrateOnce()">Shane's Induction to Wet Edge Enterprises</span>
            </div> -->
                </div>

                <hr />
                <span class="button" @click="windowReload">Refresh app</span>
            </div>

            <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showDetailsModal }">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Follow Up Details</p>
                        <button class="delete" aria-label="close" @click.prevent="close"></button>
                    </header>
                    <section class="modal-card-body">
                        <p>NAME: {{ selectedItem.username }}</p>
                        <p>ACTION: {{ selectedItem.action }}</p>
                        <p>NOTES: {{ selectedItem.notes }}</p>

                        <div v-if="loadingUserData"><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading user info...</div>
                        <div v-else>
                            <p>
                                NUMBER:
                                <a :href="'tel:' + selectedItem.userphone">{{ selectedItem.userphone }}</a>
                            </p>
                            <p>
                                EMAIL:
                                <a :href="'mailto:' + selectedItem.useremail + '?Subject=Message%20from%20Offshore%20SMS%20Team'">{{
                                    selectedItem.useremail
                                }}</a>
                            </p>
                        </div>
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button is-delete" @click.prevent="close">
                            Done
                        </button>
                    </footer>
                </div>
            </div>

            <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showTestFCM }">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Send a Test FCM</p>
                        <button class="delete" aria-label="close" @click.prevent="close"></button>
                    </header>
                    <section class="modal-card-body">
                        <label class="label" for="">Recipient ID:</label>
                        <input type="text" class="input pad" placeholder="Enter user ID.." v-model="fcm.recipientID" />
                        <div class="spacer s10"></div>

                        <label class="label" for="">Recipient Name:</label>
                        <input type="text" class="input pad" placeholder="Enter user name.." v-model="fcm.recipientName" />
                        <div class="spacer s10"></div>

                        <label class="label" for="">FCM Title:</label>
                        <input type="text" class="input pad" placeholder="Type title.." v-model="fcm.msgTitle" />
                        <div class="spacer s10"></div>

                        <label class="label" for="">FCM Message:</label>
                        <textarea class="input pad textarea" placeholder="Type message.." maxlength="140" v-model="fcm.msgBody"></textarea>
                        <div class="spacer s10"></div>
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button is-warning" @click="sendTestFCM()">
                            Send Test FCM
                        </button>
                        <button class="button is-primary" @click="close">Done</button>
                    </footer>
                </div>
            </div>

            <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showAddCodesModal }">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card is-link">
                    <header class="modal-card-head is-link">
                        <p class="modal-card-title">Add New Manual Vessel Codes</p>
                        <button class="delete" aria-label="close" @click.prevent="close"></button>
                    </header>
                    <section class="modal-card-body">
                        <input
                            type="tel"
                            v-model="newCodeNum"
                            placeholder="Enter a number"
                            maxlength="3"
                            @keypress="isNumber($event)"
                            class="input"
                        />
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button is-link" @click="addManualCodes()">Add {{ newCodeNum }} Codes</button>
                        <button class="button is-light" @click="close">Cancel</button>
                    </footer>
                </div>
            </div>

            <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showAddProfileIDsModal }">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card is-link">
                    <header class="modal-card-head is-link">
                        <p class="modal-card-title">Add New Public Profile IDs to Pool</p>
                        <button class="delete" aria-label="close" @click.prevent="close"></button>
                    </header>
                    <section class="modal-card-body">
                        <input
                            type="tel"
                            v-model="newCodeNum"
                            placeholder="Enter a number"
                            maxlength="3"
                            @keypress="isNumber($event)"
                            class="input"
                        />
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button is-success" @click="addNewPublicProfileCodes()">Add {{ newCodeNum }} Public Profile Codes</button>
                        <button class="button is-light" @click="close">Cancel</button>
                    </footer>
                </div>
            </div>

            <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showAddWebLinkIDsModal }">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card is-link">
                    <header class="modal-card-head is-link">
                        <p class="modal-card-title">Add New Vessel Log IDs</p>
                        <button class="delete" aria-label="close" @click.prevent="close"></button>
                    </header>
                    <section class="modal-card-body">
                        <input
                            type="tel"
                            v-model="newCodeNum"
                            placeholder="Enter a number"
                            maxlength="3"
                            @keypress="isNumber($event)"
                            class="input"
                        />
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button is-link" @click="addNewCustomLogCodes()">Add {{ newCodeNum }} WebLink IDs</button>
                        <button class="button is-light" @click="close">Cancel</button>
                    </footer>
                </div>
            </div>

            <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showAddAnyCodesModal }">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card is-link">
                    <header class="modal-card-head is-link">
                        <p class="modal-card-title">Add New {{ tempCodeName }}</p>
                        <button class="delete" aria-label="close" @click.prevent="close"></button>
                    </header>
                    <section class="modal-card-body">
                        <input
                            type="tel"
                            v-model="newCodeNum"
                            placeholder="Enter a number"
                            maxlength="3"
                            @keypress="isNumber($event)"
                            class="input"
                        />
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button is-link" @click="addAnyNewCodes()">Add {{ newCodeNum }} {{ tempCodeName }}</button>
                        <button class="button is-light" @click="close">Cancel</button>
                    </footer>
                </div>
            </div>

            <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showAddSupplierPinsModal }">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card is-link">
                    <header class="modal-card-head is-link">
                        <p class="modal-card-title">Add New Supplier Pin Codes to Pool</p>
                        <button class="delete" aria-label="close" @click.prevent="close"></button>
                    </header>
                    <section class="modal-card-body">
                        <input
                            type="tel"
                            v-model="newCodeNum"
                            placeholder="Enter a number"
                            maxlength="3"
                            @keypress="isNumber($event)"
                            class="input"
                        />
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button is-link" @click="addNewSupplierPins()">Add {{ newCodeNum }} Supplier Pin Codes</button>
                        <button class="button is-light" @click="close">Cancel</button>
                    </footer>
                </div>
            </div>

            <router-link class="quick-add-button" to="quick-vessel-setup">
                <font-awesome-icon icon="ship" />
            </router-link>
            <router-link class="quick-add-button users" to="find-user">
                <font-awesome-icon icon="users" />
            </router-link>
        </div>
        <div class="container" v-else>
            <h2>Access Denied.</h2>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue';
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import NProgress from 'nprogress';
import * as moment from 'moment';
// import cryptoRandomString from 'crypto-random-string';
// var randomString = require('random-string');
import randomString from 'random-string';

export default {
    name: 'home',

    components: {
        // HelloWorld
    },

    data: function() {
        return {
            firebaseid: '',
            firebaseemail: '',
            firebasedisplayname: '',
            showTestFCM: false,
            allFollowUps: [],
            showDetailsModal: false,
            selectedItem: {},
            loadingUserData: false,

            newCodeNum: 10,
            newCode: '',
            showAddCodesModal: false,
            allCodes: [],

            showAddWebLinkIDsModal: false,
            showAddProfileIDsModal: false,
            showAddSupplierPinsModal: false,
            newWebLinkID: '',
            allWebLinkIDs: [],
            allSupplierPins: [],
            tempCodes: [],
            showAddAnyCodesModal: false,
            tempCodeName: '',
            usedCollection: '',
            poolCollection: '',

            occyEmail: '',
            occyName: '',

            fcm: {
                senderID: '',
                senderName: 'OffshoreSMS Team',
                recipientID: '3wTzZecD4QYOPUk4IyWRA2JmDcJ2',
                recipientName: 'A user',
                msgTitle: 'Hello Stevo',
                msgBody: 'This is a test message from OffshoreSMS',
                targetScreen: 'alerts'
            }
        };
    },

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        isLoggedIn() {
            return this.user && this.user.uid;
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        isAdmin() {
            return this.$store.getters.getIsAdmin;
        },
        isSteve() {
            return this.firebaseid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2';
        },
        isJosh() {
            return this.firebaseid == 'kZ0YdR4pk7bOVKaEM2wB6a44Nym2';
        },
        isRob() {
            return this.firebaseid == '9u1j9Bo1QDWU8fSxW1LiIA4qa103';
        }
    },

    mounted() {
        this.$store.dispatch('setUser');
        this.$store.dispatch('SETSUBSCRIPTIONINFO');
        this.$store.dispatch('SETISADMIN');
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                this.firebaseemail = user.email;
                this.firebaseid = user.uid;
                this.firebasedisplayname = this.userData.username;
                this.loadFollowUps();
            }
        },

        windowReload: function() {
            window.location.reload();
        },

        startNP() {
            NProgress.start();
        },

        stopNP() {
            NProgress.done();
        },

        isNumber: function($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        // onceFunction() {
        //   let self = this;
        //   let message;
        //   message = {
        //     title: 'Confirm One-Off Function?',
        //     body: 'Click YES below proceed.'
        //   };
        //   let options = {
        //     okText: 'YES',
        //     backdropClose: true,
        //     cancelText: 'Cancel',
        //   };
        //   this.$dialog
        //     .confirm(message, options)
        //     .then(function(dialog) {

        //         NProgress.start();
        //         var adminUserdataQuery = firebase.functions().httpsCallable('adminUserdataQuery');
        //         adminUserdataQuery()
        //         .then(function(result) {
        //           // var sanitizedMessage = result.data.text;
        //           // console.log('Result',sanitizedMessage);
        //           // console.log('Message',result.data.message);
        //           NProgress.done();

        //         })
        //         .catch(function(error) {
        //           console.log('Error',error.text);
        //           NProgress.done();
        //         });

        //     })
        //     .catch(function(error) {
        //       console.log(error);
        //       NProgress.done();
        //     });
        // },

        generateRandomString(length) {
            let result = '';
            // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
            const characters = 'abcdefghijklmnopqrstuvwxyz1234567890';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },

        close() {
            this.showTestFCM = false;
            this.showDetailsModal = false;
            this.selectedItem = {};
            this.loadingUserData = false;
            this.newCodeNum = 10;
            this.showAddCodesModal = false;
            this.showAddWebLinkIDsModal = false;
            this.showAddSupplierPinsModal = false;
            this.showAddProfileIDsModal = false;

            this.tempCodes = [];
            this.showAddAnyCodesModal = false;
            this.tempCodeName = '';
        },

        updateEmailOctopus() {
            let self = this;
            NProgress.start();
            var updateEmailOctopusListSingleUser = firebase.functions().httpsCallable('updateEmailOctopusListSingleUser');
            updateEmailOctopusListSingleUser({
                action: 'add',
                listUrl: 'https://emailoctopus.com/api/1.6/lists/74f001df-0a88-11ed-9258-0241b9615763/contacts',
                firstName: self.occyName,
                emailAddress: self.occyEmail,
                newEmailAddress: ''
            })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    // console.log('Result',sanitizedMessage);
                    // console.log('Success?',result.data.success);
                    if (result.data.success) {
                        if (sanitizedMessage.customer) {
                            console.log('Customer updated in EmailOctopus');
                            NProgress.done();
                        } else {
                            console.log('Customer update failed in EmailOctopus.');
                            NProgress.done();
                        }
                    } else {
                        console.log('The function updateCustomerByCustomerID returned an error');
                    }
                })
                .catch(function(error) {
                    console.log('Cloud function error: updateEmailOctopusListSingleUser', error);
                    NProgress.done();
                });
        },

        formatDate: function(sentDate, format) {
            return moment(sentDate).format(format);
        },

        loadFollowUps() {
            let self = this;
            db.collection('crmFollowUps')
                .orderBy('date', 'asc')
                .where('complete', '==', false)
                .onSnapshot(querySnapshot => {
                    self.allFollowUps = [];
                    querySnapshot.forEach(doc => {
                        let isDue = moment(doc.data().date.toDate()).isBefore(moment().add(100, 'days'));
                        let isPast = moment(doc.data().date.toDate()).isBefore(moment());
                        if (isDue || isPast) {
                            self.allFollowUps.push({
                                id: doc.id,
                                timestamp: doc.data().timestamp ? doc.data().timestamp.toDate() : '',
                                date: doc.data().date ? doc.data().date.toDate() : '',
                                action: doc.data().action ? doc.data().action : 'No action',
                                notes: doc.data().notes ? doc.data().notes : '',
                                followUpAdded: doc.data().followUpAdded ? doc.data().followUpAdded : false,
                                username: doc.data().userName ? doc.data().userName : 'No Username',
                                userid: doc.data().userID ? doc.data().userID : 'No ID',
                                userphone: doc.data().userphone ? doc.data().userphone : '',
                                useremail: doc.data().useremail ? doc.data().useremail : '',
                                isDue: isDue,
                                isPast: isPast,
                                icon: isPast ? 'exclamation-triangle' : isDue ? 'stopwatch' : 'calendar-check'
                            });
                        }
                    });
                });
        },

        showDetails(step) {
            console.log(step);
            this.selectedItem = step;
            this.showDetailsModal = true;
        },

        markCompleted(item) {
            let self = this;
            let message = {
                title: 'Mark as Complete?',
                body: ''
            };
            let options = {
                okText: 'Yes, Complete',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function() {
                    NProgress.start();
                    db.collection('crmFollowUps')
                        .doc(item.id)
                        .update({
                            complete: true
                        });
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        sendTestFCM() {
            // Josh: kZ0YdR4pk7bOVKaEM2wB6a44Nym2
            // Rob: 9u1j9Bo1QDWU8fSxW1LiIA4qa103
            let self = this;
            NProgress.start();
            var cloudFunctionSendSinglePushMessage = firebase.functions().httpsCallable('cloudFunctionSendSinglePushMessage');
            cloudFunctionSendSinglePushMessage({
                senderID: self.fcm.senderID,
                senderName: self.fcm.senderName,
                recipientID: self.fcm.recipientID,
                recipientName: self.fcm.recipientName,
                msgTitle: self.fcm.msgTitle,
                msgBody: self.fcm.msgBody,
                targetScreen: self.fcm.targetScreen
            }).then(() => {
                NProgress.done();
                self.close();
            });
        },

        migrateOnce() {
            let self = this;
            let message;
            message = {
                title: 'Confirm One-Off Migration?',
                body: 'Click YES below proceed.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    let collection = 'companyInductionItems';

                    let sourceRef = 'userdata/lBQwJxPjkfdjwKVdAjWOYHtIqfx1/' + collection;
                    let targetRef = 'companies/5nQw2R1a14Ukgku4dtQd/' + collection;

                    console.log('Migration from', sourceRef, 'to', targetRef, 'complete');

                    NProgress.start();
                    var adminMigrateOnce = firebase.functions().httpsCallable('adminMigrateOnce');
                    adminMigrateOnce({
                        sourceRef: sourceRef,
                        targetRef: targetRef
                        //targetOwnerName: 'Wet Edge Enterprises'
                    })
                        .then(function(result) {
                            var sanitizedMessage = result.data.text;
                            console.log('Result', sanitizedMessage);
                            console.log('Message', result.data.message);
                            NProgress.done();
                        })
                        .catch(function(error) {
                            console.log('Error', error.text);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        async addManualCodes() {
            let self = this;
            NProgress.start();
            if (this.newCodeNum && this.newCodeNum > 0) {
                db.collection('vesselManualCodes')
                    .get()
                    .then(snap => {
                        self.allCodes = [];
                        snap.forEach(doc => {
                            self.allCodes.push(doc.id);
                        });
                    })
                    .then(() => {
                        for (let i = 0; i < self.newCodeNum; i++) {
                            let set = false;
                            while (!set) {
                                let getCode = Math.floor(Math.random() * (999999 - 100001 + 1)) + 100001;
                                let firstNum = String(getCode).charAt(0);
                                console.log('getCode', getCode, firstNum);
                                if (!self.allCodes.includes(getCode) && firstNum != '4') {
                                    // self.newCode = getCode;
                                    db.collection('vesselManualCodesPool')
                                        .doc(getCode.toString())
                                        .set({ code: getCode.toString() });
                                    set = true;
                                    break;
                                }
                            }
                        }
                        NProgress.done();
                        self.close();
                    });
            } else {
                NProgress.done();
                self.close();
            }
        },

        async addNewPublicProfileCodes() {
            let self = this;
            NProgress.start();
            if (this.newCodeNum && this.newCodeNum > 0) {
                db.collection('profileLinkIDs')
                    .get()
                    .then(snap => {
                        self.allWebLinkIDs = [];
                        snap.forEach(doc => {
                            self.allWebLinkIDs.push(doc.id);
                        });
                    })
                    .then(() => {
                        for (let i = 0; i < self.newCodeNum; i++) {
                            let set = false;
                            while (!set) {
                                // let getCode = cryptoRandomString({length: 10, type: 'alphanumeric'});
                                var getCode = randomString({ length: 30 });

                                console.log('getCode', getCode);
                                if (!self.allWebLinkIDs.includes(getCode)) {
                                    console.log('Adding to pool:', getCode);

                                    db.collection('profileLinkIDsPool')
                                        .doc(getCode.toString())
                                        .set({ code: getCode.toString() });
                                    set = true;
                                    break;
                                }
                            }
                        }
                        NProgress.done();
                        self.close();
                    });
            } else {
                NProgress.done();
                self.close();
            }
        },

        async addNewSupplierPins() {
            let self = this;
            NProgress.start();
            if (this.newCodeNum && this.newCodeNum > 0) {
                db.collection('supplierUsedPinCodes')
                    .get()
                    .then(snap => {
                        self.allSupplierPins = [];
                        snap.forEach(doc => {
                            self.allSupplierPins.push(doc.id);
                        });
                    })
                    .then(() => {
                        for (let i = 0; i < self.newCodeNum; i++) {
                            let set = false;
                            while (!set) {
                                // let getCode = cryptoRandomString({length: 10, type: 'alphanumeric'});
                                // var getCode = randomString({ length: 4 });
                                var getCode = self.generateRandomString(4);

                                console.log('getCode', getCode);
                                if (!self.allSupplierPins.includes(getCode)) {
                                    console.log('Adding to pool:', getCode);

                                    db.collection('supplierPinCodesPool')
                                        .doc(getCode.toString())
                                        .set({ pinCode: getCode.toString() });
                                    set = true;
                                    break;
                                }
                            }
                        }
                        NProgress.done();
                        self.close();
                    });
            } else {
                NProgress.done();
                self.close();
            }
        },

        async addNewCustomLogCodes() {
            let self = this;
            NProgress.start();
            if (this.newCodeNum && this.newCodeNum > 0) {
                db.collection('vesselWebLinkIDs')
                    .get()
                    .then(snap => {
                        self.allWebLinkIDs = [];
                        snap.forEach(doc => {
                            self.allWebLinkIDs.push(doc.id);
                        });
                    })
                    .then(() => {
                        for (let i = 0; i < self.newCodeNum; i++) {
                            let set = false;
                            while (!set) {
                                // let getCode = cryptoRandomString({length: 10, type: 'alphanumeric'});
                                var getCode = randomString({ length: 10 });

                                console.log('getCode', getCode);
                                if (!self.allWebLinkIDs.includes(getCode)) {
                                    console.log('Adding to pool:', getCode);

                                    db.collection('vesselWebLinkIDsPool')
                                        .doc(getCode.toString())
                                        .set({ code: getCode.toString() });
                                    set = true;
                                    break;
                                }
                            }
                        }
                        NProgress.done();
                        self.close();
                    });
            } else {
                NProgress.done();
                self.close();
            }
        },

        async addAnyNewCodes() {
            let self = this;
            console.log(this.usedCollection, this.poolCollection);
            NProgress.start();
            if (this.newCodeNum && this.newCodeNum > 0) {
                db.collection(self.usedCollection)
                    .get()
                    .then(snap => {
                        self.tempCodes = [];
                        snap.forEach(doc => {
                            self.tempCodes.push(doc.id);
                        });
                    })
                    .then(() => {
                        for (let i = 0; i < self.newCodeNum; i++) {
                            let set = false;
                            while (!set) {
                                // let getCode = cryptoRandomString({length: 10, type: 'alphanumeric'});
                                var getCode = randomString({ length: 10 });

                                console.log('getCode', getCode);
                                if (!self.tempCodes.includes(getCode)) {
                                    console.log('Adding to pool:', getCode);

                                    db.collection(self.poolCollection)
                                        .doc(getCode.toString())
                                        .set({ code: getCode.toString() });
                                    set = true;
                                    break;
                                }
                            }
                        }
                        NProgress.done();
                        self.close();
                    });
            } else {
                NProgress.done();
                self.close();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
}
.quick-add-button {
    position: fixed;
    top: 100px;
    right: 1rem;
    padding: 20px;
    background: deepskyblue;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font-size: 2rem;
    transition: 0.25s;
    &.users {
        top: 200px;
        background-color: mediumseagreen;
    }
    &:hover {
        transform: scale(1.1);
    }
}
.dashtile-wrapper {
    .dashtile {
        width: 48%;
        float: left;
        background: #ccc;
        margin-right: 4%;
        margin-bottom: 1rem;
        text-align: center;
        height: 200px;
        color: #fff;
        border-radius: 5px;
        transform-style: preserve-3d;
        transition: 0.3s;
        &:last-of-type {
            margin-right: 0;
        }
        p {
            color: #ffffff;
            margin-bottom: 0;
            font-size: 1.8rem;
            &.count {
                font-size: 22px;
            }
        }
        &.alerts {
            background: #ffcd2e;
        }
        &.profile {
            background: #ff7878;
            p,
            .content-wrap {
                color: #fff;
            }
        }
        &.primary {
            background: #00d1b2;
        }
        &.dark {
            background: #222;
        }
        &.blue {
            background: steelblue;
        }
        &.info {
            background: #209cee;
        }
        &.success {
            background: #23d160;
        }
        &.danger {
            background: #ff3860;
        }
        &.warning {
            background: #ffdd57;
        }
        .content-wrap {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            svg {
                font-size: 20px;
                margin-bottom: 4px;
            }
        }
        // &:hover {
        //  box-shadow: 0px 0px 8px 2px #ddd;
        // }
    }
}

h3 {
    margin: 1rem 0 1.5rem;
}

.steps-content {
    p {
        &.bold {
            font-weight: bold;
        }
        &.upper {
            text-transform: uppercase;
        }
        &.italic {
            font-style: italic;
        }
    }
    .follow-up {
        font-style: italic;
        color: orangered;
    }
    .due {
        color: darkorange;
    }
    .past {
        color: red;
    }
}
.steps.is-vertical {
    .steps-marker {
        &.due {
            background-color: darkorange !important;
        }
        &.past {
            background-color: red !important;
        }
    }
    .steps-segment {
        &:after {
            bottom: 10px;
            width: 4px;
            left: 14px;
            top: 40px;
        }
        &:not(:last-child):after {
            bottom: 10px;
            width: 4px;
            left: 14px;
            top: 40px;
        }
        &.due {
            &:after {
                background-color: darkorange;
            }
        }
        &.past {
            &:after {
                background-color: red;
            }
        }
    }
}

input.input {
    &.pad {
        height: auto;
        padding: 15px;
    }
}

input[type='tel'] {
    padding: 20px;
    height: auto;
}

.buttons {
    flex-direction: column;
    align-items: start;
    .button {
        display: block;
        &.max {
            width: 100%;
            max-width: 400px;
            padding: 12px;
            height: auto;
        }
    }
}

@media only screen and (max-width: 599px) {
    .dashtile-wrapper {
        .dashtile {
            width: 100%;
            margin-right: 0;
            .content-wrap {
                p {
                    font-size: 1.3rem;
                }
            }
        }
    }
}
</style>
