<template>
    <div>
        <div class="container">
            <div class="spacer s20"></div>

            <!-- {{sentUser}} -->

            <div class="notification is-info">
                <h3>Update {{ sentUser.username }}'s Email address</h3>
            </div>

            <label>User ID:</label>
            <input disabled class="input" type="text" placeholder="User ID" v-model="userid" />

            <div v-if="userid != ''">
                <!-- <label>Set a New Password:</label>
				<input class="input" type="text" placeholder="New password..." v-model="updatedPassword" /> -->

                <!-- <label>User Role:</label>
        <div class="select is-primary">
          <select v-model="userrole">
            <option value="free" default>Free</option>
            <option value="starter">Starter</option>
            <option value="fleet">Fleet</option>
          </select>
        </div> -->

                <!-- <label>Next Charge Amount:</label>
        <input class="input" type="number" placeholder="Next charge amount" v-model="nextCharge" />

        <label>Max Vessels:</label>
        <input class="input" type="number" placeholder="Max vessels" v-model="maxVessels" /> -->

                <!-- <label>Anniversary Date:</label>
        <div class="form-group-reg">
          <datetime placeholder="Anniversary Date" auto v-model="anniversaryDate"></datetime>
        </div>
        {{anniversaryDate}}
 -->

                <div class="spacer s10"></div>

                <label>New user name:</label>
                <input class="input" type="text" placeholder="New username.." v-model="newName" />

                <div class="spacer s10"></div>

                <label>New email address:</label>
                <input class="input" type="email" placeholder="New email.." v-model="newEmail" />

                <div class="spacer s50"></div>

                <div class="buttons">
                    <span class="button is-primary" @click="updateEmail()">Update Email</span>
                    <button class="button is-info" @click="goBack()">&nbsp;Back &nbsp;</button>
                </div>

                <div class="spacer s50"></div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '../main.js';
//import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {
    name: 'UpdateEmail',

    data: function() {
        return {
            userid: '',
            useremail: '',
            updatedPassword: '',
            anniversaryDate: '',
            userrole: '',
            nextCharge: 0,
            maxVessels: 0,
            newEmail: '',
            newName: ''
        };
    },

    props: {
        sentUser: Object
    },

    mounted() {
        if (this.sentUser) {
            this.userid = this.sentUser.userid;
            // this.anniversaryDate = this.sentUser.anniversaryDate == undefined ? '' : this.sentUser.anniversaryDate.toISOString();
            // this.nextCharge = this.sentUser.nextCharge ? this.sentUser.nextCharge : 0;
            // this.userrole = this.sentUser.userrole ? this.sentUser.userrole : 'free';
            // this.maxVessels = this.sentUser.maxVessels ? parseInt(this.sentUser.maxVessels) : 0;
            this.useremail = this.sentUser.useremail ? this.sentUser.useremail : '';
            this.newEmail = this.sentUser.useremail ? this.sentUser.useremail : '';
            this.newName = this.sentUser.username ? this.sentUser.username : '';
        }
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    computed: {
        showButton() {
            return this.newEmail == '';
        }
    },

    methods: {
        onUserLogin(user) {
            if (user) {
                console.log('user', user);
            }
        },
        updateEmail() {
            let self = this;

            let updateArray = {
                email: self.newEmail,
                username: self.newName
            };
            // console.log(updateArray, self.userid, self.useremail);

            let message = {
                title: 'Update Email?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES, UPDATE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    if (self.newEmail != '' && self.userid != '') {
                        db.collection('userdata')
                            .doc(self.userid)
                            .update({
                                useremail: self.newEmail,
                                username: self.newName
                            })
                            .then(() => {
                                // console.log(updateArray, self.userid, self.useremail);
                                var AdminUpdateUserEmail = firebase.functions().httpsCallable('AdminUpdateUserEmail');
                                AdminUpdateUserEmail({
                                    userID: self.userid,
                                    userEmail: self.useremail,
                                    updateArray: updateArray
                                });
                                NProgress.done();
                            })
                            .catch(error => {
                                console.log('Error 1', error);
                                NProgress.done();
                            });

                        // var AdminUpdateUserEmail = firebase.functions().httpsCallable('AdminUpdateUserEmail');
                        // AdminUpdateUserEmail({
                        //   userID: self.userid,
                        //   updateArray: updateArray
                        // })
                        // .then(function(result) {
                        //   var sanitizedMessage = result.data.text;
                        //   console.log('Result',sanitizedMessage);
                        //   console.log('Message',result.data.message);

                        // })
                        // .catch(function(error) {
                        //   console.log('Error',error.text);
                        // });
                        // NProgress.done();
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        changeField(field) {
            console.log('Field changed: ', field);
        },

        goBack: function() {
            this.$router.go(-1);
        }
    }
};
</script>

<style lang="scss" scoped>
input[type='text'],
input[type='number'],
input[type='email'] {
    padding: 30px;
    margin-bottom: 1rem;
}
label {
    margin-bottom: 5px;
    margin-top: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
}
</style>
