<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">COPY SMS</h3>
                <br />

                <div class="input-wrapper notification">
                    <label class="label">Source Vessel</label>
                    <div class="flex">
                        <div>
                            <span class="blue bold">{{ sourceVessel.name }}</span> ({{ sourceVessel.id }})
                        </div>
                        <!-- <input class="input" type="text" placeholder="Paste Vessel ID" v-model="sourceVessel"> -->
                        <div v-if="sourceVesselExists" class="icon-wrap valid"><font-awesome-icon icon="check" /></div>
                        <div v-else class="icon-wrap"><font-awesome-icon icon="times" /></div>
                    </div>
                </div>

                <div class="clear"></div>

                <div class="input-wrapper notification">
                    <label class="label">Target Vessel</label>
                    <div class="flex">
                        <div>
                            <span class="blue bold">{{ targetVessel.name }}</span> ({{ targetVessel.id }})
                        </div>
                        <!-- <input class="input" type="text" placeholder="Paste Vessel ID" v-model="targetVessel"> -->
                        <div v-if="targetVesselExists" class="icon-wrap valid"><font-awesome-icon icon="check" /></div>
                        <div v-else class="icon-wrap"><font-awesome-icon icon="times" /></div>
                    </div>
                </div>

                <div class="clear"></div>

                <div class="buttons">
                    <!-- <span v-if="!vesselsExist" :disabled="haveVessels" class="button is-info" @click="checkValidVessels">Check Vessels</span>
              <span v-else class="button is-warning" @click="copyVessels">Copy SMS</span> -->
                    <!-- <span :disabled="vesselsInvalid" class="button is-warning" @click="copyVessels">Copy SMS</span> -->
                    <span :disabled="vesselsInvalid" class="button is-warning is-medium" @click="showClone()">Copy SMS</span>
                </div>

                <hr />

                <!--  {{allVessels}} -->
                <!-- {{ sourceVessel }} <hr> {{ targetVessel }} -->

                <div class="vesselList">
                    <ul>
                        <li v-for="(vessel, index) in allVessels">
                            <div class="flex vessel">
                                <div class="flex column">
                                    <div class="bold">{{ vessel.name }}</div>
                                    <div>{{ vessel.owner }}</div>
                                </div>
                                <div>
                                    <span
                                        class="button is-small is-light"
                                        :class="{ 'is-danger': sourceVessel.id == vessel.id }"
                                        @click="sourceVessel = vessel"
                                        >Source</span
                                    >&nbsp;
                                    <span
                                        class="button is-small is-light"
                                        :class="{ 'is-success': targetVessel.id == vessel.id }"
                                        @click="targetVessel = vessel"
                                        >Target</span
                                    >
                                </div>
                                <!-- <span>{{ index }}: {{ vessel.name }}</span
                                >&nbsp;
                                <span
                                    class="button is-small is-light"
                                    :class="{ 'is-danger': sourceVessel.id == vessel.id }"
                                    @click="sourceVessel = vessel"
                                    >Source</span
                                >&nbsp;
                                <span
                                    class="button is-small is-light"
                                    :class="{ 'is-success': targetVessel.id == vessel.id }"
                                    @click="targetVessel = vessel"
                                    >Target</span
                                > -->
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showCloneOptions }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        Clone the <span class="caps">{{ sourceVessel.vesselName }}</span> SMS
                    </p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="slide-section">
                        <h4>Select which sections to clone</h4>
                        <div class="spacer s20"></div>
                        <div class="checkbox-wrapper">
                            <div class="field">
                                <input class="is-checkradio" @change="updateSelectAll()" type="checkbox" id="all" value="all" v-model="selectAll" />
                                <label class="major" for="all">Clone Everything</label>
                            </div>
                            <div class="field" v-for="item in optionsList" :key="item.slug">
                                <input class="is-checkradio" type="checkbox" :id="item.slug" :value="item.slug" v-model="cloneList" />
                                <label :class="{ active: cloneList.includes(item.slug) }" :for="item.slug">{{ item.title }}</label>
                            </div>
                        </div>

                        <div class="clear"></div>
                        <div class="spacer s30"></div>

                        <!-- {{ cloneList }} -->
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button cancel" @click.prevent="close">Cancel</button>
                    <button :disabled="cloneList.length == 0" class="button is-info" @click="confirmClone()">Clone SMS</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '../main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
//import * as moment from 'moment';

export default {
    components: {},
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
        this.loadVessels();
    },

    data: function() {
        return {
            sourceVessel: {},
            targetVessel: {},
            allVessels: [],
            allVesselIDs: [],
            showCloneOptions: false,
            optionsList: [
                { slug: 'statements', title: 'Responsibility Statements' },
                { slug: 'hazards', title: 'Vessel Hazards' },
                { slug: 'contacts', title: 'Vessel Contacts' },
                { slug: 'policies', title: 'Vessel Policies' },
                { slug: 'sops', title: 'Safe Work Procedures' },
                { slug: 'eops', title: 'Emergency Procedures' },
                { slug: 'startups', title: 'Startup Checklist' },
                { slug: 'jsas', title: 'JSA Checklist' },
                { slug: 'crew-inductions', title: 'Crew Induction Checklist' },
                { slug: 'passenger-inductions', title: 'Passenger Induction Checklist' },
                // {slug: 'activities', title: 'Mid-Trip Activities'},
                { slug: 'shutdowns', title: 'Shutdown Checklist' },
                { slug: 'maintenance', title: 'Quick Maintenance Checklist' },
                { slug: 'custom-checklists', title: 'All Custom Logs' },
                { slug: 'schedules', title: 'Schedules & Drills' },
                { slug: 'plant', title: 'Plant & Equipment' },
                { slug: 'timed-activities', title: 'All Timed Activities' }
            ],
            cloneList: [],
            selectAll: false,
            sourceVesselData: {}
        };
    },

    computed: {
        isSteve() {
            return this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' || this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1';
        },
        haveVessels() {
            return this.sourceVessel.id == '' || this.targetVessel.id == '';
        },
        sourceVesselExists() {
            return this.allVesselIDs.includes(this.sourceVessel.id);
        },
        targetVesselExists() {
            return this.allVesselIDs.includes(this.targetVessel.id);
        },
        vesselsInvalid() {
            return !this.sourceVesselExists || !this.targetVesselExists || this.sourceVessel.id == this.targetVessel.id;
        }
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                // console.log(user);
            }
        },

        loadVessels() {
            let self = this;
            db.collection('vessels')
                .orderBy('vesselName', 'asc')
                .onSnapshot(function(querySnapshot) {
                    self.allVessels = [];
                    querySnapshot.forEach(doc => {
                        self.allVessels.push({
                            name: doc.data().vesselName,
                            owner: doc.data().vesselOwnerName ? doc.data().vesselOwnerName : 'Unknown',
                            id: doc.id
                        });
                        self.allVesselIDs.push(doc.id);
                    });
                });
        },

        showClone() {
            db.collection('vessels')
                .doc(this.sourceVessel.id)
                .get()
                .then(doc => {
                    let data = doc.data();
                    data.ownerStatement = doc.data().ownerStatement ? doc.data().ownerStatement : '';
                    data.designatedStatement = doc.data().designatedStatement ? doc.data().designatedStatement : '';
                    data.lineOfComms = doc.data().lineOfComms ? doc.data().lineOfComms : '';
                    data.masterStatement = doc.data().masterStatement ? doc.data().masterStatement : '';
                    data.roleTwoStatement = doc.data().roleTwoStatement ? doc.data().roleTwoStatement : '';
                    data.crewStatement = doc.data().crewStatement ? doc.data().crewStatement : '';
                    data.customStatement = doc.data().customStatement ? doc.data().customStatement : '';
                    data.savedCompanyHazards = doc.data().savedCompanyHazards ? doc.data().savedCompanyHazards : [];
                    data.savedCompanyContacts = doc.data().savedCompanyContacts ? doc.data().savedCompanyContacts : [];
                    data.savedCompanyPolicies = doc.data().savedCompanyPolicies ? doc.data().savedCompanyPolicies : [];
                    data.savedCompanySOPS = doc.data().savedCompanySOPS ? doc.data().savedCompanySOPS : [];
                    data.savedCompanyEOPS = doc.data().savedCompanyEOPS ? doc.data().savedCompanyEOPS : [];
                    data.savedCompanyStartupItems = doc.data().savedCompanyStartupItems ? doc.data().savedCompanyStartupItems : [];
                    data.savedCompanyJSAItems = doc.data().savedCompanyJSAItems ? doc.data().savedCompanyJSAItems : [];
                    data.savedCompanyInductionItems = doc.data().savedCompanyInductionItems ? doc.data().savedCompanyInductionItems : [];
                    data.savedCompanyPassengerInductionItems = doc.data().savedCompanyPassengerInductionItems
                        ? doc.data().savedCompanyPassengerInductionItems
                        : [];
                    data.savedCompanyActivityItems = doc.data().savedCompanyActivityItems ? doc.data().savedCompanyActivityItems : [];
                    data.savedCompanyMaintenanceItems = doc.data().savedCompanyMaintenanceItems ? doc.data().savedCompanyMaintenanceItems : [];
                    data.savedCompanyShutdownItems = doc.data().savedCompanyShutdownItems ? doc.data().savedCompanyShutdownItems : [];
                    this.sourceVesselData = data;
                })
                .then(() => {
                    this.showCloneOptions = true;
                });
        },

        close() {
            this.showCloneOptions = false;
            this.sourceVesselData = {};
            this.cloneList = [];
            this.selectAll = false;
        },

        updateSelectAll() {
            let self = this;
            this.selectAll == !this.selectAll;
            if (this.selectAll == true) {
                self.cloneList = self.optionsList.map(item => item.slug);
            } else {
                self.cloneList = [];
            }
        },

        confirmClone() {
            // console.log(this.currentVesselID, this.targetVessel.vesselID, this.cloneList, this.vesselData);

            let self = this;
            this.showCloneOptions = false;
            let message;
            message = {
                title: 'Confirm SMS Clone?',
                body:
                    "You're cloning the selected SMS sections from " +
                    self.sourceVessel.name +
                    ' to ' +
                    self.targetVessel.name +
                    '. Please note that it could take up to 11 minutes to complete the clone.'
            };
            let options = {
                okText: 'Yes, CLONE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function() {
                    NProgress.start();
                    let cloneData = {
                        sourceVessel: self.sourceVessel.id,
                        targetVessel: self.targetVessel.id,
                        listOfCloneItems: self.cloneList,
                        vesselData: self.sourceVesselData
                    };
                    console.log('cloneData', cloneData);
                    var fleetCloneSMS = firebase.functions().httpsCallable('fleetCloneSMS');
                    fleetCloneSMS(cloneData);

                    var addVesselRevision = firebase.functions().httpsCallable('addVesselRevision');
                    addVesselRevision({
                        userName: 'OffshoreSMS Admin',
                        userID: '',
                        vesselName: sourceVessel.name,
                        vesselID: self.sourceVessel.id,
                        revisionDetails:
                            'OffshoreSMS Admin' + ' has cloned selected SMS sections from ' + self.sourceVessel.name + ' to ' + self.targetVessel.name
                    });

                    setTimeout(() => {
                        self.close();
                        NProgress.done();
                    }, 1000);
                })
                .catch(function() {
                    self.close();
                    NProgress.done();
                });
        },

        copyVessels() {
            let self = this;

            console.log('source', self.sourceVessel);
            console.log('target', self.targetVessel);

            let message;
            message = {
                title: 'Confirm SMS Migration?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    var adminMigrateSMS = firebase.functions().httpsCallable('adminMigrateSMS');
                    adminMigrateSMS({
                        sourceVessel: self.sourceVessel,
                        targetVessel: self.targetVessel
                    })
                        .then(function(result) {
                            // var sanitizedMessage = result.data.text;
                            // console.log('Result',sanitizedMessage);
                            // console.log('Message',result.data.message);
                            NProgress.done();
                        })
                        .catch(function(error) {
                            console.log('Error', error);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.vesselList {
    ul {
        li {
            margin-bottom: 5px;
            .vessel {
                border-bottom: 1px solid #e4e4e4;
            }
        }
    }
}
input[type='text'] {
    width: 90%;
    float: left;
    display: block;
    margin-bottom: 2rem;
    // &:after {
    //   content: "\f16c";
    //   font-family: "Font Awesome 5 Brands";
    //   color: red;
    // }
    // &.valid:after {

    // }
}
.icon-wrap {
    display: block;
    float: left;
    height: 35px;
    margin-left: 10px;
    font-size: 1.5rem;
    color: red;
    &.valid {
        color: green;
    }
}
</style>
