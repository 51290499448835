<template>
    <div>
        <div class="container">
            <div class="spacer s20"></div>

            <!-- {{sentUser}} -->

            <div class="notification is-info">
                <h3>Update {{ sentUser.username }}'s Phone Number</h3>
            </div>

            <label>User ID:</label>
            <input class="input" type="text" placeholder="User ID" v-model="userid" />

            <div v-if="userid != ''">
                <label>New phone number:</label>
                <input class="input" type="tel" placeholder="New number.." v-model="newPhone" />

                <div class="spacer s50"></div>

                <div class="buttons">
                    <span class="button is-primary" @click="updatePhone()">Update Number</span>
                    <button class="button is-info" @click="goBack()">&nbsp;Back &nbsp;</button>
                </div>

                <div class="spacer s50"></div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '../main.js';
//import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {
    name: 'UpdatePhone',

    data: function() {
        return {
            userid: '',
            // updatedPassword: '',
            // anniversaryDate: '',
            // userrole: '',
            // nextCharge: 0,
            // maxVessels: 0,
            newPhone: ''
        };
    },

    props: {
        sentUser: Object
    },

    mounted() {
        if (this.sentUser) {
            this.userid = this.sentUser.userid;
            // this.anniversaryDate = this.sentUser.anniversaryDate == undefined ? '' : this.sentUser.anniversaryDate.toISOString();
            // this.nextCharge = this.sentUser.nextCharge ? this.sentUser.nextCharge : 0;
            // this.userrole = this.sentUser.userrole ? this.sentUser.userrole : 'free';
            // this.maxVessels = this.sentUser.maxVessels ? parseInt(this.sentUser.maxVessels) : 0;
            this.newPhone = this.sentUser.userphone ? this.sentUser.userphone : '';
        }
    },

    computed: {
        showButton() {
            return this.newPhone == '';
        }
    },

    methods: {
        updatePhone() {
            let self = this;

            let updateArray = {
                phone: self.newPhone
            };
            console.log(updateArray, self.userid);

            let message = {
                title: 'Update Number?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES, UPDATE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    if (self.newPhone != '' && self.userid != '') {
                        db.collection('userdata')
                            .doc(self.userid)
                            .update({
                                userphone: self.newPhone
                            })
                            .then(() => {
                                var AdminUpdateUserPhone = firebase.functions().httpsCallable('AdminUpdateUserPhone');
                                AdminUpdateUserPhone({
                                    userID: self.userid,
                                    updateArray: updateArray
                                });
                                NProgress.done();
                            })
                            .catch(error => {
                                console.log('Error', error.text);
                                NProgress.done();
                            });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        changeField(field) {
            console.log('Field changed: ', field);
        },

        goBack: function() {
            this.$router.go(-1);
        }
    }
};
</script>

<style lang="scss" scoped>
input[type='text'],
input[type='number'],
input[type='email'] {
    padding: 30px;
    margin-bottom: 1rem;
}
label {
    margin-bottom: 5px;
    margin-top: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
}
</style>
