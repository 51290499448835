<template>
  
  <div class="site-wrapper no-header">
    
      <div class="container">

        <div class="buttons">
          <button class="button is-info" @click="goBack()"><span><font-awesome-icon icon="chevron-left" /></span> &nbsp;Back &nbsp;</button>
        </div>

        <h3 class="is-size-5">All User Timelines</h3>

        <ul v-if="allTimelines.length > 0" class="sms-steps steps is-vertical has-content-centered">

          <li v-for="(step, index) in allTimelines" :key="index" class="steps-segment">
            <span class="steps-marker is-primary">
              <span class="icon"><span><font-awesome-icon icon="check" /></span></span>
            </span>
            <div class="steps-content">
                <!-- <p class="heading">{{formatDate(step.timestamp,'DDD MMM YYYY (h:mm a)')}}</p> -->
                <p class="heading">{{formatDate(step.entryDate,'D MMM YYYY (h:mm a)')}}</p>
                <p class="bold upper">{{step.username}}</p>
                <p class="italic">{{step.title}}</p>
                <div v-for="line in step.details.split('\n')" :key="line">{{line}}<br></div>
                <div class="spacer s30">
                  
                </div>
            </div>
          </li>

        </ul>  

        <div v-else class="">
          Loading timelines...
        </div>

        <hr>


        <div class="buttons">
          <span class="button is-info" @click="goBack()"><span><font-awesome-icon icon="chevron-left" /></span> &nbsp;Back &nbsp;</span>
          <!-- <span v-if="findName != '' || !selected" class="button is-warning" @click="clearUser()">Clear</span>
          <span v-if="findName != '' && selected" class="button is-primary" @click="addEntry()">Add Entry</span> -->
        </div>
        

      </div>


  </div>

</template>


<script>
  

  import { db } from '../main.js';
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth'; 
  import 'firebase/firestore';
  import NProgress from 'nprogress';
  import * as moment from 'moment';

  export default {

    name: 'UserTimelines',
    props: {
      isAdding: Boolean,
      sentUser: Object,
    },
    components: {

    },
    computed: {
      filteredNames() {
        let filter = new RegExp(this.findName, 'i')
        return this.allUsers.filter(item => item.userName.match(filter));
      },

    },

    mounted: function() {
      let self = this;
      firebase.auth().onAuthStateChanged( this.onUserLogin );   
    },

    data: function() {
      return {
        // reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        allTimelines: [],

      }
    },

    methods: {

      onUserLogin: function( user ) {
        let self = this;
        if (user) {         
          this.loadTimelines();
        }     
          
      },

      close: function() {
        // this.selectedUser = {};
        this.showAddEntry = false;
      },

      formatDate : function (sentDate,format) {
        return moment(sentDate).format(format);
      },

      loadTimelines() {
        let self = this;
        db.collection('timelines').orderBy('entryDate','desc').onSnapshot((querySnapshot) => {
          self.allTimelines = [];
          querySnapshot.forEach((doc) => {
            self.allTimelines.push({
              id: doc.id,
              timestamp: doc.data().timestamp ? doc.data().timestamp.toDate() : '',
              entryDate: doc.data().entryDate ? doc.data().entryDate.toDate() : '',
              title: doc.data().title ? doc.data().title : 'No title',
              details: doc.data().details ? doc.data().details : '',
              username: doc.data().userName ? doc.data().userName : 'No Username',
              userid: doc.data().userID ? doc.data().userID : 'No ID',
            });
          });
        });
      },

      goBack: function() {
        this.$router.go(-1);
      },


    }

  }


</script>


<style lang="scss" scoped>
  
  h3 {
    margin: 1rem 0 1.5rem;
  }
  .select {
    width: 100%;
    max-width: 400px;
    select {
      width: 100%;
    }
  }

  h4 {
    text-transform: uppercase;
    margin-bottom: 0.8rem;
    font-weight: bold;
  }

  ul.vessel-list {
    max-width: 320px;
    li {
      margin-bottom: 1rem;
      &:last-of-type {
        margin-bottom: 0;
      }
      .vessel-name {
        float: left;
      }
      .button {
        float: right;
      }
    }
  }
  .buttons {
    margin-top: 1rem;
  }
  .button {
    margin-top: 0;
  }

  input.entryTitle {
    padding: 2rem 1rem;
  }

  textarea {
    padding: 1rem;
  }

  .steps-content {
    p {
      &.bold {
        font-weight: bold;
      }
      &.upper {
        text-transform: uppercase;
      }
      &.italic {
        font-style: italic;
      }
    }
  }



</style>