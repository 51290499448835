<template>
    <div class="site-wrapper no-header">
        <div class="container">
            <div class="notification is-info"><h3>Delete an Existing Fleet</h3></div>

            <div v-if="!isSelected" class="notification is-primary">
                <input class="input" v-model="findName" id="filtername" autocomplete="off" type="text" placeholder="Start typing Fleet name..." />
            </div>

            <div v-if="!isSelected" class="buttons">
                <span v-if="findName != ''" class="button is-warning" @click="clearFleet()">Clear</span>
            </div>

            <div v-if="isSelected" class="highlight">{{ selectedFleet.companyName }} [{{ selectedFleet.fleetID }}]</div>

            <div v-if="findName.length > 2 && !isSelected">
                <div v-for="(fleet, index) in filteredNames" :key="index">
                    <div class="buttons results">
                        <span class="button is-light" @click="selectFleet(fleet)"
                            >{{ fleet.companyName }} ({{ fleet.primaryUserName }}) [{{ fleet.fleetID }}]</span
                        >
                    </div>
                </div>
            </div>

            <div v-if="isSelected" class="new-vessel-info">
                <div class="buttons">
                    <span v-if="findName != ''" class="button is-warning is-medium" @click="clearUser()">Clear</span>
                    <button :disabled="showDelete" class="button is-primary is-medium" @click="deleteFleet()">
                        Delete Fleet
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as moment from 'moment';
import NProgress from 'nprogress';

export default {
    name: 'AdminAddFleetVessel',

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        filteredNames() {
            let filter = new RegExp(this.findName, 'i');
            return this.fleets.filter(fleet => fleet.companyName.match(filter));
        },
        showDelete() {
            return false;
        }
    },

    data() {
        return {
            fleets: [],
            isSelected: false,
            findName: '',
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            selectedFleet: '',

            users: [],
            newFleetName: '',
            userSelected: false,
            selectedUser: '',

            newVesselID: '',
            addFasttrack: false
        };
    },

    mounted() {
        // this.$store.dispatch('setUser');
        // this.$store.dispatch('SETSUBSCRIPTIONINFO');
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    methods: {
        onUserLogin(user) {
            if (user) {
                this.firebaseemail = user.email;
                this.firebaseid = user.uid;
                this.firebasedisplayname = this.userData.companyName;
                this.loadFleets();
                // this.loadUsers();
            }
        },

        loadFleets() {
            let self = this;
            db.collection('companies').onSnapshot(function(querySnapshot) {
                self.fleets = [];
                querySnapshot.forEach(function(doc) {
                    // self.fleets.push(doc.data())
                    let isExpired = doc.data().isExpired ? doc.data().isExpired : false;
                    if (!isExpired)
                        self.fleets.push({
                            fleetID: doc.id,
                            // signUpDate: doc.data().signUpDate,
                            companyState: doc.data().companyState ? doc.data().companyState : '',
                            adminUsers: doc.data().adminUsers ? doc.data().adminUsers : [],
                            // industry: doc.data().industry,
                            // userrole: doc.data().userrole,
                            // userGroup: doc.data().userGroup ? doc.data().userGroup : '',
                            companyName: doc.data().companyName,
                            primaryUserName: doc.data().primaryUserName ? doc.data().primaryUserName : '',
                            primaryUserPhone: doc.data().primaryUserPhone ? doc.data().primaryUserPhone : '',
                            primaryUserEmail: doc.data().primaryUserEmail ? doc.data().primaryUserEmail : ''
                            // useremail: doc.data().useremail,
                            // userphone: doc.data().userphone,
                            // favouriteVesselID: doc.data().favouriteVesselID ? doc.data().favouriteVesselID : '',
                            // signupPlatform: doc.data().signupPlatform ? doc.data().signupPlatform : 'not set',
                            // favouriteFixed: doc.data().favouriteFixed ? doc.data().favouriteFixed : false,
                            // profilingInfo: doc.data().profilingInfo ? doc.data().profilingInfo : '',
                        });
                });
            });
        },

        loadUsers() {
            let self = this;
            db.collection('userdata')
                .orderBy('signUpDate', 'desc')
                .onSnapshot(function(querySnapshot) {
                    self.users = [];
                    querySnapshot.forEach(function(doc) {
                        // self.users.push(doc.data())
                        self.users.push({
                            userid: doc.id,
                            signUpDate: doc.data().signUpDate,
                            userstate: doc.data().userstate,
                            industry: doc.data().industry,
                            userrole: doc.data().userrole,
                            userGroup: doc.data().userGroup ? doc.data().userGroup : '',
                            username: doc.data().username,
                            useremail: doc.data().useremail,
                            userphone: doc.data().userphone,
                            favouriteVesselID: doc.data().favouriteVesselID ? doc.data().favouriteVesselID : '',
                            signupPlatform: doc.data().signupPlatform ? doc.data().signupPlatform : 'not set',
                            favouriteFixed: doc.data().favouriteFixed ? doc.data().favouriteFixed : false
                            // profilingInfo: doc.data().profilingInfo ? doc.data().profilingInfo : '',
                        });
                    });
                });
        },

        selectUser(user) {
            // console.log(user);
            this.userSelected = true;
            this.selectedUser = user;
        },

        clearUser() {
            this.newFleetName = '';
            this.userSelected = false;
            this.selectedUser = '';
            this.findName = '';
            this.isSelected = false;
            this.selectedFleet = '';
        },

        selectFleet(fleet) {
            // console.log(fleet);
            this.isSelected = true;
            this.selectedFleet = fleet;
        },

        clearFleet() {
            this.findName = '';
            this.isSelected = false;
            this.selectedFleet = '';
        },

        deleteFleet() {
            let self = this;
            let message;
            message = {
                title: 'Delete this fleet?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES, Delete',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(dialog => {
                    NProgress.start();
                    var deleteMarineFleet = firebase.functions().httpsCallable('deleteMarineFleet');
                    deleteMarineFleet({
                        fleetID: self.selectedFleet.fleetID,
                        userIDs: self.selectedFleet.adminUsers
                    })
                        .then(() => {
                            // var sanitizedMessage = result.data.text;
                            // console.log('Result',sanitizedMessage);
                            // console.log('Message', result.data.message);
                            NProgress.done();
                            self.clearFleet();
                            self.clearUser();
                        })
                        .catch(error => {
                            console.log('Error', error);
                            NProgress.done();
                        });
                })
                .catch(error => {
                    console.log(error);
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.highlight {
    background: cadetblue;
    padding: 15px;
    color: #fff;
}
.posi {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: #fff;
    z-index: 999;
}
label {
    &.label {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 10px;
        &.tight {
            margin-bottom: 4px;
        }
    }
}
.buttons.results {
    margin-bottom: 4px;
}
</style>
