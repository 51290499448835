<template>
  
  <div class="site-wrapper no-header">

    <div class="container">
    
       <div class="notification is-info"><h3>All App Users</h3></div>

        <div v-if="!isSelected" class="notification is-primary">
          <input class="input" v-model="findName" id="filtername" autocomplete="off" type="text" placeholder="Start typing user name..." />
        </div>

        <div class="buttons">
          <span v-if="findName != ''" class="button is-warning" @click="clearUser()">Clear</span>
        </div>

        <div v-if="isSelected" class="highlight">{{selectedUser.username}} </div>

        <div v-if="findName.length > 2 && !isSelected">

          <div v-for="(user, index) in filteredNames" :key="index">

            <div class="buttons results">
              <span class="button is-light" @click="selectUser(user)">{{user.username}}</span>
            </div>

          </div>



        </div>


        <div v-if="isSelected" class="new-vessel-info">


          <div class="spacer s30"></div>

          <div class="form-group-reg">
            <label class="label">Vessel Name</label> 
            <input class="input" type="text" placeholder="The vessel name" v-model="newVessel.vesselName">
          </div>

          <div class="form-group-reg">
            <label class="label">Vessel UVI</label> 
            <input class="input" type="text" placeholder="The 6 digit UVI number" v-model="newVessel.vesselUVI">
          </div>

          <div class="form-group-reg">
            <label class="label">Home Port</label> 
            <input class="input" type="email" placeholder="The vessel's home port" v-model="newVessel.homePort">
          </div>

          <div class="form-group-reg">
            <label class="label">Vessel Description</label> 
            <textarea class="textarea" placeholder="A basic description of your vessel..." v-model="newVessel.description"></textarea>
          </div>

          <div class="spacer s10"></div>

          <div class="form-group-reg">
            <div class="checkbox-wrapper">
            <label class="label">Vessel States</label> 
              <div class="field" v-for="item in allstates" :key="item.id">
                <input class="is-checkradio" type="checkbox" :id="item.id" :value="item" v-model="selectedStates">
                <label :for="item.id">{{item.name}}</label>
              </div>
            </div>
          </div>

          <div class="spacer s10"></div>

          <div class="form-group-reg">
            <div class="checkbox-wrapper">
            <label class="label">Vessel industries</label> 
              <div class="field" v-for="item in industries" :key="item.id">
                <input class="is-checkradio" type="checkbox" :id="item.id" :value="item" v-model="selectedIndustries">
                <label :for="item.id">{{item.name}}</label>
              </div>
            </div>
          </div>

          <div class="spacer s10"></div>

          <div class="form-group-reg">
            <div class="checkbox-wrapper">
            <label class="label">Vessel survey Classes</label> 
              <div class="field" v-for="item in classes" :key="item.id">
                <input class="is-checkradio" type="checkbox" :id="item.id" :value="item" v-model="selectedClasses">
                <label :for="item.id">{{item.name}}</label>
              </div>
            </div>
          </div>

          <div class="form-group-reg">
            <div class="spacer s30"></div>
            <label class="label">Vessel Operational Areas</label> 
            <div class="checkbox-wrapper">
              <div class="field" v-for="item in areas" :key="item.id">
                <input class="is-checkradio" type="checkbox" :id="item.id" :value="item" v-model="selectedAreas">
                <label :for="item.id">{{item.name}}</label>
              </div>
            </div>
          </div>

          <div class="spacer s20"></div>

          <div class="form-group-reg">
            <label class="label">Name of the main vessel Master / Skipper</label> 
            <input class="input" type="text" placeholder="Skipper name" v-model="newVessel.vesselMaster">
          </div>

          <!-- <div class="form-group-reg">
            <label class="label">List one crew or deckhand (optional)</label> 
            <input class="input" type="text" placeholder="Crew name..." v-model="newVessel.crewName">
            <input class="input" type="text" placeholder="Crew phone number..." v-model="newVessel.crewNumber">
          </div> -->

          <div class="form-group-reg">
            <label class="label">List one shore based contact person</label> 
            <input class="input" type="text" placeholder="Shore contact name" v-model="newVessel.shoreContactName">
            <input class="input" type="text" placeholder="Shore contact number" v-model="newVessel.shoreContactNumber">
          </div>

          <div class="form-group-reg">
            <label class="label">The ideal number of people on board on a trip</label> 
            <input class="input" type="tel" placeholder="Number of crew" v-model="newVessel.vesselCrew">
          </div>

          <div class="form-group-reg">
            <label class="label">Crewing Explanation</label> 
            <textarea class="textarea" placeholder="An explanation of the crewing number..." v-model="newVessel.appCrewExplanation"></textarea>
          </div>

          <div class="form-group-reg">
            <div class="spacer s30"></div>
            <label class="label">Other information about your operation</label> 
            <div class="checkbox-wrapper">
              <div class="field" v-for="item in questions" :key="item.id">
                <input class="is-checkradio" type="checkbox" :id="item.id" :value="item" v-model="selectedAnswers">
                <label :for="item.id">{{item.name}}</label>
              </div>
            </div>
          </div>

          <hr>


          <div class="form-group-reg">
            <div class="spacer s30"></div>
            <label class="label">FAST TRACK SMS CONTENT?</label> 
            <div class="checkbox-wrapper">
              <div class="field">
                <input class="is-checkradio" type="checkbox" id="fasttrack" v-model="addFasttrack">
                <label for="fasttrack">Yes, Fast Track this baby!</label>
              </div>
            </div>
          </div>


          <div class="buttons">
            <button :disabled="showSave" class="button is-primary is-medium" @click="addVessel()">Add Vessel</button>
          </div>
          
        </div>

        <!-- <div class="posi">{{finalCodes}}<br>{{codeGroups}}</div> -->



    </div>



  </div>


</template>




<script>
  
  import { db } from '../main.js';
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth'; 
  import * as moment from 'moment';
  import NProgress from 'nprogress';

  export default {
    name: 'AdminAddVessel',

    computed: {
      user() {
        return this.$store.getters.getUser;
      },
      userData() {
        return this.$store.getters.getSubscriptionInfo;
      },
      filteredNames() {
        let filter = new RegExp(this.findName, 'i')
        return this.users.filter(user => user.username.match(filter));
      },
      showSave() {
        return false;
      },

      selectedStateCodes() {
        return this.selectedStates.map(item => item.code);
      },
      selectedStateNames() {
        return this.selectedStates.map(item => item.name);
      },

      selectedIndustryCodes() {
        return this.selectedIndustries.map(item => item.code);
      },
      selectedIndustryNames() {
        return this.selectedIndustries.map(item => item.name);
      },

      selectedClassCodes() {
        return this.selectedClasses.map(item => item.code);
      },
      selectedClassNames() {
        return this.selectedClasses.map(item => item.name);
      },

      selectedAreaCodes() {
        return this.selectedAreas.map(item => item.code);
      },
      selectedAreaNames() {
        return this.selectedAreas.map(item => item.name);
      },
      selectedAnswerCodes() {
        return this.selectedAnswers.map(item => item.code);
      },
      selectedAnswerNames() {
        return this.selectedAnswers.map(item => item.name);
      },

      selectedCodes() {
        return [...['base'],...this.selectedAnswerCodes,...this.selectedClassCodes,...this.selectedAreaCodes,...this.selectedIndustryCodes,...this.selectedStateCodes];
      },
      finalCodes() {
        return [...new Set(this.selectedCodes)];
      },

      gBasis() {
        return this.groupBasis.some(item => this.finalCodes.includes(item)) ? ['g-basis'] : [];
      },
      gRisky() {
        return this.groupRisky.some(item => this.finalCodes.includes(item)) ? ['g-risky'] : [];
      },
      gHeavy() {
        return this.groupHeavy.some(item => this.finalCodes.includes(item)) ? ['g-heavy'] : [];
      },
      gTravs() {
        return this.groupTravs.some(item => this.finalCodes.includes(item)) ? ['g-travs'] : [];
      },
      gHirer() {
        return this.groupHirer.some(item => this.finalCodes.includes(item)) ? ['g-hirer'] : [];
      },
      gDiver() {
        return this.groupDiver.some(item => this.finalCodes.includes(item)) ? ['g-diver'] : [];
      },
      gFishy() {
        return this.groupFishy.some(item => this.finalCodes.includes(item)) ? ['g-fishy'] : [];
      },
      gMotor() {
        return this.groupMotor.some(item => this.finalCodes.includes(item)) ? ['g-motor'] : [];
      },      
      gAlone() {
        return this.groupAlone.some(item => this.finalCodes.includes(item)) ? ['g-alone'] : [];
      },
      gSleep() {
        return this.groupSleep.some(item => this.finalCodes.includes(item)) ? ['g-sleep'] : [];
      },

      codeGroups() {
        return [...this.gBasis, ...this.gRisky, ...this.gHeavy, ...this.gTravs, ...this.gHirer, ...this.gDiver, ...this.gFishy,  ...this.gMotor, ...this.gAlone, ...this.gSleep];
      },



    },

    data() {
      return {
        users: [],
        isSelected: false,
        findName: '',
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        selectedUser: '',
        newVessel: {
          vesselName: '',
          vesselUVI: '',
          nextCharge: 149,
          isCompanyVessel: false,
          takesPassengers: false,
          homePort: '',
          description: '',
          vesselMaster: '',
          vesselCrew: '',
          appCrewExplanation: '',
          shoreContactName: '',
          shoreContactNumber: '',
        },

        allstates: [
          { id: "s1", name: "Tasmania", checked: false, code: 'base'}, //make
          { id: "s2", name: "Victoria", checked: false, code: 'base'}, //deep
          { id: "s3", name: "New South Wales", checked: false, code: 'base'}, //just
          { id: "s4", name: "Queensland", checked: false, code: 'base'}, //wear
          { id: "s5", name: "South Australia", checked: false, code: 'base'}, //snap
          { id: "s6", name: "Western Australia", checked: false, code: 'base'}, //dash
          { id: "s7", name: "Northern Territory", checked: false, code: 'base'}, //slip
        ],
        selectedStates: [],
        industries: [
          { id: "i1", name: "Fishing", checked: false, code: 'keep'},
          { id: "i2", name: "Aquaculture", checked: false, code: 'wrap'},
          { id: "i3", name: "Commercial Diving", checked: false, code: 'lump'},
          { id: "i4", name: "Marine Construction", checked: false, code: 'corn'},
          { id: "i5", name: "Charter Vessel", checked: false, code: 'wood'},
          { id: "i6", name: "Passenger Vessel", checked: false, code: 'pest'},
          { id: "i7", name: "Other", checked: false, code: 'base'}, //gate
        ],
        selectedIndustries: [],
        classes: [
          { id: 'c1', name: '1 - Passenger vessel (13 or more passengers)', code: 'tent'},
          { id: 'c2', name: '2 - Non-passenger vessel (up to 12 passengers)', code: 'wall'},
          { id: 'c3', name: '3 - Fishing vessel', code: 'firm'},
          { id: 'c4', name: '4 - Hire and drive vessel used by the hirer only for recreational purposes', code: 'aunt'},
        ],
        selectedClasses: [],
        areas: [
          { id: 'A', name: 'Unlimited domestic operations', code: 'burp'},
          { id: 'B', name: 'B - Offshore operations (within 200 nautical miles)', code: 'news'},
          { id: 'Be', name: 'B (extended) - Offshore operations (beyond 200 nautical miles)', code: 'lazy'},
          { id: 'C', name: 'C - Offshore operations (within 30 nautical miles)', code: 'grow'},
          { id: 'Cr', name: 'C (restricted) - Offshore operations - specified areas only', code: 'club'},
          { id: 'D', name: 'D - Partially smooth water operations', code: 'bank'}, 
          { id: 'E', name: 'E - Smooth water operations', code: 'step'}, 
        ],
        selectedAreas: [],
        questions: [
          { id: 'Q1', checked: false, name: 'This vessel takes paying passengers', code: 'flex'},
          { id: 'Q2', checked: false, name: 'This vessel is operated single-handedly', code: 'solo'},
          { id: 'Q3', checked: false, name: 'Diving operations are carried out from this vessel', code: 'vote'},
          { id: 'Q4', checked: false, name: 'This is a dry hire or hire-drive vessel', code: 'toss'},
          { id: 'Q5', checked: false, name: 'This vessel has a crane or other lifting equipment', code: 'pair'},
          { id: 'Q6', checked: false, name: 'This vessel has an engine room', code: 'boom'},
          { id: 'Q7', checked: false, name: 'This vessel has a cabin or wheelhouse', code: 'raid'},
          { id: 'Q8', checked: false, name: 'This vessel is has sleeping accommodation', code: 'slab'},
          { id: 'Q9', checked: false, name: 'This operation requires overnight trips', code: 'zone'},
          { id: 'Q10', checked: false, name: 'This vessel is occasionally out of phone range', code: 'chop'},
          { id: 'Q11', checked: false, name: 'This vessel navigates busy traffic routes', code: 'lift'},
          { id: 'Q12', checked: false, name: 'This vessel can be launched from a trailer', code: 'pipe'},
          { id: 'Q13', checked: false, name: 'This carries one or more tender vessels', code: 'snow'},
        ],
        selectedAnswers: [],

        groupBasis: ['base'],
        groupRisky: ['keep','burp','news','lazy','grow','club','chop','lift'],
        groupHeavy: ['corn','pair'],
        groupTravs: ['wood','pest','tent','flex','toss'],
        groupHirer: ['aunt','toss','pest'],
        groupDiver: ['lump','vote'],
        groupFishy: ['keep','wrap','firm'],
        groupMotor: ['boom'],
        groupAlone: ['solo'],
        groupSleep: ['raid','slab','zone','chop','lift'],

        newVesselID: '',
        addFasttrack: false,

      } 

    },

    mounted() {
      // this.$store.dispatch('setUser');
      // this.$store.dispatch('SETSUBSCRIPTIONINFO');
      firebase.auth().onAuthStateChanged( this.onUserLogin ); 
    },

    methods: {
      onUserLogin: function( user ) {
        if (user) {         
          this.firebaseemail = user.email;
          this.firebaseid = user.uid;
          this.firebasedisplayname = this.userData.username;
          this.loadUsers();
        }
      },

      loadUsers: function() {
        let self = this;
        db.collection('userdata').orderBy('signUpDate','desc').onSnapshot(function(querySnapshot) {
          self.users = [];
          querySnapshot.forEach(function(doc) {
            // self.users.push(doc.data())
            self.users.push({
              userid: doc.id,
              signUpDate: doc.data().signUpDate,
              userstate: doc.data().userstate,
              industry: doc.data().industry,
              userrole: doc.data().userrole,
              userGroup: doc.data().userGroup ? doc.data().userGroup : '',
              username: doc.data().username,
              useremail: doc.data().useremail,
              userphone: doc.data().userphone,
              favouriteVesselID: doc.data().favouriteVesselID ? doc.data().favouriteVesselID : '',
              signupPlatform: doc.data().signupPlatform ? doc.data().signupPlatform : 'not set',
              favouriteFixed: doc.data().favouriteFixed ? doc.data().favouriteFixed : false,
              // profilingInfo: doc.data().profilingInfo ? doc.data().profilingInfo : '',
            })
          });
        })
      },

      cleanPhone(number) {
        return "0" + number.slice(3,12);
      },

      selectUser(user) {
        console.log(user);
        this.isSelected = true;
        this.selectedUser = user;

      },

      clearUser: function() {
        this.findName = '';
        this.isSelected = false;
        this.selectedUser = '';
        this.newVessel = {
          vesselName: '',
          vesselUVI: '',
          nextCharge: 149,
          isCompanyVessel: false,
          takesPassengers: false,
          homePort: '',
          description: '',
          vesselMaster: '',
          vesselCrew: '',
          appCrewExplanation: '',
          shoreContactName: '',
          shoreContactNumber: '',
        };
      },

      addVessel() {
        console.log(this.newVessel);

        NProgress.start();
        let self = this;
        let trialEndDate = moment().add(14, 'days');

        let t1 = new Date();
        // let t2 = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
        let t2 = new Date(t1.setDate(t1.getDate() + 14));

        let newVesselData = {
          vesselOwnerID: this.selectedUser.userid,
          vesselName: this.newVessel.vesselName,
          vesselUVI: this.newVessel.vesselUVI,
          allLoadedOnSave: false,
          vesselOwnerName: this.selectedUser.username,
          isDemo: true,
          isPaidFor: false,
          dateAdded: new Date(),
          // nextChargeDate: new Date(trialEndDate),
          // overviewSavedAt: t1.getTime(),
          lastVesselCharge: 0,
          vesselState: '',
          vesselIndustry: '',
          vesselOwnerPhone: this.selectedUser.userphone ? this.cleanPhone(this.selectedUser.userphone) : '',
          vesselOwnerEmail: this.selectedUser.useremail ? this.selectedUser.useremail : '',
          
          nextVesselCharge: this.newVessel.nextCharge,
          isCompanyVessel: this.newVessel.isCompanyVessel,
          takesPassengers: this.codeGroups.includes('g-travs'),
          homePort: this.newVessel.homePort,
          description: this.newVessel.description,
          vesselMaster: this.newVessel.vesselMaster,
          vesselCrew: this.newVessel.vesselCrew,
          appCrewExplanation: this.newVessel.appCrewExplanation,
          shoreContactName: this.newVessel.shoreContactName,
          shoreContactNumber: this.newVessel.shoreContactNumber,

          vesselStates: this.selectedStateNames,
          vesselIndustries: this.selectedIndustryNames,
          surveyClasses: this.selectedClassNames,
          operationalAreas: this.selectedAreaNames,
          fasttrackAnswers: this.selectedAnswerNames,

          codes: this.finalCodes,
          codeGroups: this.codeGroups,
          aaaPlans: ['logs','sms'],

        };

        console.log(newVesselData);

        var adminAddVesselToUser = firebase.functions().httpsCallable('adminAddVesselToUser');
        adminAddVesselToUser({
          newVesselData: newVesselData,
          overviewSavedAt: t1.getTime(),
          nextChargeDate: t2.getTime(),
          userID: self.selectedUser.userid,
          vesselName: self.newVessel.vesselName,
          vesselUVI: self.newVessel.vesselUVI,
          saveFastTrack: self.addFasttrack
        })
        .then(function(result) {
          self.clearUser();
          NProgress.done();

        })
        .catch(function(error) {
          console.error("Error adding vessel: ", error);
          NProgress.done();
        }); 


      }





    }

  }



</script>



<style lang="scss" scoped>
  
  .highlight {
    background: cadetblue;
    padding: 15px;
    color: #fff;
  }
  .posi {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: #fff;
    z-index: 999;
  }
  label {
    &.label {
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 10px;
      &.tight {
        margin-bottom: 4px;
      }
    }
  }
  .buttons.results {
    margin-bottom: 4px;
  }




</style>





