<template>

    <div class="site-wrapper no-header">


        <div class="container">

            <div class="buttons">
                <button class="button is-info" @click="goBack()"><span><font-awesome-icon icon="chevron-left" /></span>
                    &nbsp;Back &nbsp;</button>
            </div>

            <div class="before-icon"><font-awesome-icon icon="calendar-check" /></div>
            <h4 class="icon">New Dev Timeline Entry</h4>

            <div class="">
                <!-- {{ newEntry }} -->

                <input class="input" v-model="newEntry.heading" type="text" placeholder="Entry heading" />

                <div class="form-group">
                    <textarea v-model="newEntry.details" class="textarea" placeholder="Entry Details (optional)"
                        rows="5"></textarea>
                </div>

                <div class="form-group">
                    <span>&nbsp;</span>
                    <datetime v-model="newEntry.entryDate"></datetime>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <!-- <label>Date:</label> -->
                </div>

                <!-- <div class="form-group-reg">
                    <div class="checkbox-wrapperf">
                        <label class="label">Tag this Entry:</label>
                        <div class="spacer s10"></div>
                        <div class="field" v-for="tag in tags" :key="tag">
                            <input type="radio" :id="tag" :value="tag" name="entryTag" v-model="newEntry.selectedTag">
                            <label class="radio-label" :for="tag">{{ tag }}</label>
                        </div>
                    </div>
                </div> -->

                

                <div class="spacer s30"></div>



                <label class="label">Tag this Entry:</label>
                <div class="spacer s10"></div>
                <div class="select">
                    <select v-model="newEntry.selectedTag">>
                        <option v-for="tag in tags" :value="tag" :key="tag">{{ tag }}</option>
                    </select>
                </div>

                <div class="clear"></div>
                <div class="spacer s30"></div>

                <label class="label">Entry Status:</label>
                <div class="spacer s10"></div>
                <div class="select">
                    <select v-model="newEntry.status">>
                        <option v-for="status in statuses" :value="status" :key="status">{{ status }}</option>
                    </select>
                </div>


                <div class="spacer s80"></div>

                <div class="buttons">
                    <button v-if="newEntry.details != '' && newEntry.heading != ''" class="button is-primary"
                        @click="saveNewEntry()">Add to Dev Timeline</button>
                    <button class="button" v-else>Write the timeline entry</button>
                </div>

                <hr>


            </div>


        </div>





    </div>


</template>


<script>

import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/firestore';
import NProgress from 'nprogress';
import * as moment from 'moment'

export default {

    props: {
        currentUser: Object,
    },

    data: function () {
        return {
            newEntry: {
                id: '',
                heading: '',
                details: '',
                entryDate: new Date().toISOString(),
                status: 'static',
                selectedTags: [],
                selectedTag: 'New Features'
            },

            presetActions: ['Call back', 'Send an email', 'Meeting scheduled', 'Arrange meeting'],
            addNotes: false,
            addDate: false,
            addToICal: false,
            addToGoogleCal: false,
            tags: ['New Features','Development','Security','Bug Fixes','Business News','Alerts'],
            statuses: ['static','pending','complete','pre-release','fixed','resolved']

        }
    },

    methods: {

        saveNewEntry() {
            NProgress.start();
            var self = this;
            let tempDate = self.newEntry.entryDate ? self.newEntry.entryDate : new Date();
            let finalDate = moment(tempDate).toDate();
            // console.log(finalDate);
            // let humanDate = this.formatDate(self.newDate.entryDate, "D MMMM YYYY");

            db.collection('devTimeline').add({
                timestamp: new Date(),
                date: finalDate,
                heading: self.newEntry.heading,
                details: self.newEntry.details,
                status: self.newEntry.status,
                // tags: self.newEntry.selectedTags,
                tag: self.newEntry.selectedTag
            })
            .then(() => {
                NProgress.done();
                self.goBack();
            })
            .catch((err) => {
                NProgress.done();
                console.log('Error', err);
            });
        },

        close() {
            
        },

        goBack: function () {
            this.$router.go(-1);
        },

    }



}

</script>



<style lang="scss" scoped>
input[type="text"] {
    padding: 2rem 1rem;
}

textarea {
    padding: 1rem;
}

.before-icon {
    display: inline-block;
    float: left;
    margin-right: 10px;
    color: cadetblue;
    padding-top: 3px;
}
.form-group-reg {
    label {
        &.radio-label {
        display: inline-block;
        margin-left: 10px;
        }
    }
}

h4.icon {
    margin-bottom: 2rem;
    font-size: 1.3rem;
    display: inline-block;
    float: left;
    width: calc(100% - 30px);
    height: auto;
    color: cadetblue;
    text-align: left;
}
</style>
