<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <div class="before-icon"><font-awesome-icon icon="users" /></div>
                <h4 class="icon">Helper Safe Work Procedures</h4>
                <div class="clear"></div>

                <table v-if="poolOfDocs.length" class="table is-bordered is-fullwidth">
                    <tr>
                        <th>Title</th>
                        <th>Edit</th>
                    </tr>
                    <tr v-for="(item, index) in poolOfDocs" :key="index">
                        <td>
                            <div>{{ item.title }}</div>
                            <div class="spacer s5"></div>
                            <div v-if="showTags" class="tags">
                                <div class="tag is-small is-dark" v-for="(tag, idx) in item.tags">{{ tagMap[tag] }}</div>
                            </div>
                        </td>
                        <td>
                            <div class="buttons">
                                <!-- <span class="button is-primary" @click="editItem(item)">Content</span> -->
                                <span class="button is-small is-primary" @click="launchItemWindow(item, 'Edit')">Content</span>
                                <span class="button is-small is-warning" @click="editMeta(item)">Meta</span>
                                <span class="button is-small is-delete" @click="archiveItem(item)">Archive</span>
                            </div>
                        </td>
                    </tr>
                </table>

                <span v-if="poolOfDocs.length > 0" class="simple-link flex right" @click="enableSortModal">Update order</span>
                <div class="spacer s100"></div>

                <div class="clear"></div>

                <div class="buttons floating">
                    <span class="button is-primary is-medium" @click="launchItemWindow({}, 'Write New')">Add a Procedure</span>
                    <span class="button is-info is-medium" @click="$router.go(-1)"><font-awesome-icon icon="chevron-left" /> &nbsp;Back</span>
                    <span class="button is-dark is-medium" @click="showTags = !showTags">{{ showTags ? 'Hide Tags' : 'Show Tags' }}</span>
                </div>
            </div>
        </div>

        <!-- <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': showDetailsModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-info">
                    <p class="modal-card-title">{{ selectedItem.title }}</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <div v-if="selectedItem.details != ''">
                        <div v-for="line in selectedItem.details.split('\n')" :key="line">{{ line }}<br /></div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-info" @click.prevent="close">Done</button>
                </footer>
            </div>
        </div> -->

        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': showEditItemModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">Edit Selected Item</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <input class="input" type="text" placeholder="Type title..." v-model="selectedEditItem.title" />
                    <div class="spacer s10"></div>
                    <textarea class="input textarea" placeholder="Type details..." v-model="selectedEditItem.details"></textarea>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click.prevent="saveEditItem(selectedEditItem)">Save</button>
                    <button class="button is-danger" @click.prevent="close">Cancel</button>
                </footer>
            </div>
        </div>

        <filter-tags-modal
            v-if="showEditMetaModal"
            :title="selectedEditMeta.title"
            :tagCategories="tagCategories"
            :sentSelectedTags="selectedEditMeta.tags"
            @closed="showEditMetaModal = false"
            @returnResult="updateTags($event)"
        >
        </filter-tags-modal>

        <div class="modal modal-fx-fadeInScale" :class="{ 'is-active': enableSort }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Drag to change order of items</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <div v-if="poolOfDocs.length">
                        <draggable v-model="poolOfDocs" class="sorting-list" group="people" @start="drag = true" @end="drag = false">
                            <div v-for="(item, index) in poolOfDocs" :key="item.id">{{ index + 1 }} - {{ item.title }} <span>&#8597;</span></div>
                        </draggable>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="saveNewOrder()">
                        Update
                    </button>
                    <button class="button cancel" @click.prevent="close">Cancel</button>
                </footer>
            </div>
        </div>

        <!-- <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': showEditMetaModal }">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
          <header class="modal-card-head is-warning">
            <p class="modal-card-title">{{selectedEditMeta.title}}</p>
            <button class="delete" aria-label="close" @click.prevent="close"></button>
          </header>
          <section class="modal-card-body">
            <label class="label">Place Index</label>
            <input class="input" type="tel" placeholder="999" maxlength="4" v-on:keypress="isNumber($event)" v-model="selectedEditMeta.index">
            <div class="spacer s30"></div>
            <div v-if="selectedEditMeta != ''" class="form-group-reg">
              <div class="checkbox-wrapper">
              <label class="label">Fast Track Codes</label>
                <div class="spacer s10"></div>
                <div class="field" v-for="code in allCodes" :key="code.id">
                  <input class="is-checkradio" type="checkbox" :id="code.id" :value="code.code" v-model="tempSelectedMetaCodes">
                  <label :class="{'has-code' : tempSelectedMetaCodes.includes(code.code)}" :for="code.id">{{code.name}}</label>
                </div>
                <div class="clear"></div>
              </div>
            </div>

            <div v-if="selectedEditMeta != ''" class="posi">{{tempSelectedMetaCodes}}<br>{{codeGroups}}</div>

          </section>
          <footer class="modal-card-foot">
            <button class="button is-warning" @click.prevent="saveEditMeta(selectedEditMeta,tempSelectedMetaCodes)">Save</button>
            <button class="button is-danger" @click.prevent="close">Cancel</button>
          </footer>
        </div>
      </div> -->

        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': addItemModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">Add a Helper Safe Work Procedure</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <input class="input" type="text" placeholder="Type title..." v-model="newItem.title" />

                    <div class="spacer s10"></div>
                    <textarea class="input textarea" placeholder="Type details..." v-model="newItem.details"></textarea>

                    <!-- <div class="spacer s30"></div>

            <label class="label">Place Index</label>
            <input class="input" type="tel" placeholder="999" maxlength="4" v-on:keypress="isNumber($event)" v-model="newItem.index">

            <div class="spacer s30"></div>
            <div class="form-group-reg">
              <div class="checkbox-wrapper">
              <label class="label">Fast Track Codes</label>
                <div class="spacer s10"></div>
                <div class="field" v-for="code in allCodes" :key="code.id">
                  <input class="is-checkradio" type="checkbox" :id="code.id" :value="code.code" v-model="newItem.codes">
                  <label :class="{'has-code' : newItem.codes.includes(code.code)}" :for="code.id">{{code.name}}</label>
                </div>
                <div class="clear"></div>
              </div>
            </div> -->

                    <div class="spacer s30"></div>
                </section>
                <footer class="modal-card-foot">
                    <button :disabled="newItem.details == '' || newItem.title == ''" class="button is-primary" @click.prevent="saveNewItem()">
                        Save New Item
                    </button>
                    <button class="button is-danger" @click.prevent="close">Cancel</button>
                </footer>
            </div>
        </div>

        <edit-item-window :item="selectedItem" :allowPDFs="false" v-if="showItemWindow" v-on:sendItem="returnedItem"></edit-item-window>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import draggable from 'vuedraggable';
const FilterTagsModal = () => import('@/reusables/FilterTagsModal.vue');
const EditItemWindow = () => import('@/reusables/EditItemWindow.vue');

export default {
    name: 'BuildPooledSops',
    components: {
        FilterTagsModal,
        draggable,
        EditItemWindow
    },
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            poolOfDocs: [],
            selectedItem: { title: '', details: '' },
            selectedEditItem: { title: '', details: '' },
            selectedEditMeta: '',
            showDetailsModal: false,
            showEditItemModal: false,
            showEditMetaModal: false,
            showTags: false,

            allCodes: [
                { id: 'base', name: 'BASE', checked: false, code: 'base' },
                { id: 'i1', name: 'Fishing', checked: false, code: 'keep' },
                { id: 'i2', name: 'Aquaculture', checked: false, code: 'wrap' },
                { id: 'i3', name: 'Commercial Diving', checked: false, code: 'lump' },
                { id: 'i4', name: 'Marine Construction', checked: false, code: 'corn' },
                { id: 'i5', name: 'Charter Vessel', checked: false, code: 'wood' },
                { id: 'i6', name: 'Passenger Vessel', checked: false, code: 'pest' },
                { id: 'i7', name: 'Other', checked: false, code: 'gate' },
                { id: 'c1', name: '1 - Passenger vessel (13 or more passengers)', code: 'tent' },
                { id: 'c2', name: '2 - Non-passenger vessel (up to 12 passengers)', code: 'wall' },
                { id: 'c3', name: '3 - Fishing vessel', code: 'firm' },
                { id: 'c4', name: '4 - Hire and drive vessel used by the hirer only for recreational purposes', code: 'aunt' },
                { id: 'B', name: 'B - Offshore operations (within 200 nautical miles)', code: 'news' },
                { id: 'Be', name: 'B (extended) - Offshore operations (beyond 200 nautical miles)', code: 'lazy' },
                { id: 'C', name: 'C - Offshore operations (within 30 nautical miles)', code: 'grow' },
                { id: 'Cr', name: 'C (restricted) - Offshore operations - specified areas only', code: 'club' },
                { id: 'D', name: 'D - Partially smooth water operations', code: 'bank' },
                { id: 'E', name: 'E - Smooth water operations', code: 'step' },
                { id: 'Q1', checked: false, name: 'This vessel takes paying passengers', code: 'flex' },
                { id: 'Q2', checked: false, name: 'This vessel is operated single-handedly', code: 'solo' },
                { id: 'Q3', checked: false, name: 'Diving operations are carried out from this vessel', code: 'vote' },
                { id: 'Q4', checked: false, name: 'This is a dry hire or hire-drive vessel', code: 'toss' },
                { id: 'Q5', checked: false, name: 'This vessel has a crane or other lifting equipment', code: 'pair' },
                { id: 'Q6', checked: false, name: 'This vessel has an engine room', code: 'boom' },
                { id: 'Q7', checked: false, name: 'This vessel has a cabin or wheelhouse', code: 'raid' },
                { id: 'Q8', checked: false, name: 'This vessel is has sleeping accommodation', code: 'slab' },
                { id: 'Q9', checked: false, name: 'This operation requires overnight trips', code: 'zone' },
                { id: 'Q10', checked: false, name: 'This vessel is occasionally out of phone range', code: 'chop' },
                { id: 'Q11', checked: false, name: 'This vessel navigates busy traffic routes', code: 'lift' }
                // { id: 'Q12', checked: false, name: 'This vessel is operated single-handedly', code: 'pipe'},
            ],

            groupBasis: ['base'],
            groupRisky: ['keep', 'burp', 'news', 'lazy', 'grow', 'club', 'chop', 'lift'],
            groupHeavy: ['corn', 'pair'],
            groupTravs: ['wood', 'pest', 'tent', 'flex', 'toss'],
            groupHirer: ['aunt', 'toss', 'pest'],
            groupDiver: ['lump', 'vote'],
            groupFishy: ['keep', 'wrap', 'firm'],
            groupMotor: ['boom'],
            groupAlone: ['solo'],
            groupSleep: ['raid', 'slab', 'zone', 'chop', 'lift'],

            tempSelectedMetaCodes: [],
            addItemModal: false,
            newItem: {
                title: '',
                details: '',
                codes: [],
                index: 999
            },
            allTags: [],
            tagCategories: [],
            selectedTags: [],
            enableSort: false,
            showItemWindow: false
        };
    },

    computed: {
        userData() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.userData
                ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2'
                    ? true
                    : false
                : false;
        },
        selectedEditMetaCodes() {
            return this.selectedEditMeta.codes.filter(item => item.checked).map(itemB => item);
        },
        tempSelectedCodeNames() {
            return this.allCodes
                .filter(code => this.tempSelectedMetaCodes.includes(code.code))
                .map(item => item.name)
                .join(', ');
        },

        gBasis() {
            return this.groupBasis.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-basis'] : [];
        },
        gRisky() {
            return this.groupRisky.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-risky'] : [];
        },
        gHeavy() {
            return this.groupHeavy.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-heavy'] : [];
        },
        gTravs() {
            return this.groupTravs.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-travs'] : [];
        },
        gHirer() {
            return this.groupHirer.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-hirer'] : [];
        },
        gDiver() {
            return this.groupDiver.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-diver'] : [];
        },
        gFishy() {
            return this.groupFishy.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-fishy'] : [];
        },
        gMotor() {
            return this.groupMotor.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-motor'] : [];
        },
        gAlone() {
            return this.groupAlone.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-alone'] : [];
        },
        gSleep() {
            return this.groupSleep.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-sleep'] : [];
        },
        tagMap() {
            return this.allTags.reduce((acc, curr) => ((acc[curr.id] = curr.title), acc), {});
        },

        codeGroups() {
            return [
                ...this.gBasis,
                ...this.gRisky,
                ...this.gHeavy,
                ...this.gTravs,
                ...this.gHirer,
                ...this.gDiver,
                ...this.gFishy,
                ...this.gMotor,
                ...this.gAlone,
                ...this.gSleep
            ];
        }
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                this.displayDocs();
                this.loadAllTags();
                setTimeout(() => {
                    this.loadTagCategories();
                }, 600);
            }
        },

        enableSortModal() {
            this.poolOfDocs = this.sorted(this.poolOfDocs);
            this.enableSort = true;
        },

        sorted(arr) {
            // Set slice() to avoid to generate an infinite loop!
            return arr.slice().sort(function(a, b) {
                return a.index - b.index;
            });
        },

        saveNewOrder() {
            var self = this;
            NProgress.start();
            this.poolOfDocs.forEach((item, index) => {
                db.collection('pooledSOPS')
                    .doc(item.id)
                    .update({
                        index: index + 1
                    })
                    .then(() => {
                        self.enableSort = false;
                        NProgress.done();
                    })
                    .catch(error => {
                        console.error('Error updating indexes: ', error);
                        NProgress.done();
                    });
            });
        },

        loadAllTags() {
            let self = this;
            db.collection('tagLibrary')
                .where('assetCategory', '==', 'vessel')
                .orderBy('index', 'asc')
                .onSnapshot(querySnapshot => {
                    self.allTags = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        data.editing = false;
                        data.changed = false;
                        let parentCatID = doc.data().parentCatID ? doc.data().parentCatID : 'xSu8bBuH6RbVsPdE7SWs'; // this cat ID is for "Other" cat
                        data.parentCatID = parentCatID;
                        self.allTags.push(data);
                    });
                });
        },

        loadTagCategories() {
            NProgress.start();
            let self = this;
            db.collection('tagCategories')
                .where('assetCategory', '==', 'vessel')
                .orderBy('index', 'asc')
                .onSnapshot(querySnapshot => {
                    self.tagCategories = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        data.editing = false;
                        data.changed = false;
                        data.tags = self.allTags.filter(n => n.parentCatID == doc.id);
                        self.tagCategories.push(data);
                    });
                });
            setTimeout(() => {
                self.tagsLoaded = true;
            }, 500);
            NProgress.done();
        },

        updateTags(result) {
            console.log(result);
            let self = this;
            this.selectedTags = result;
            console.log(self.selectedEditMeta);
            NProgress.start();
            let saveData = {
                collection: 'pooledSOPS',
                docID: self.selectedEditMeta.id,
                codes: [],
                tags: result,
                codeGroups: [],
                index: parseInt(self.selectedEditMeta.index)
            };
            console.log(saveData);
            var adminSaveSmsItemMeta = firebase.functions().httpsCallable('adminSaveSmsItemMeta');
            adminSaveSmsItemMeta(saveData);
            this.close();
            NProgress.done();
        },

        isNumber: function($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        close: function() {
            this.showDetailsModal = false;
            this.showEditItemModal = false;
            this.showEditMetaModal = false;
            this.selectedItem = { title: '', details: '' };
            this.selectedEditItem = { title: '', details: '' };
            this.selectedEditMeta = '';
            this.addItemModal = false;
            this.newItem = {
                title: '',
                details: '',
                codes: [],
                index: 999
            };
            this.tempSelectedMetaCodes = [];
            this.enableSort = false;
            this.showItemWindow = false;
        },

        fixArchived(docID) {
            console.log('Updating archived status for doc', docID);
            db.collection('pooledSOPS')
                .doc(docID)
                .update({
                    archived: false
                });
        },

        displayDocs() {
            let self = this;
            db.collection('pooledSOPS')
                .orderBy('index', 'asc')
                .onSnapshot(querySnapshot => {
                    self.poolOfDocs = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        data.tags = doc.data().tags ? doc.data().tags : [];
                        if (doc.data().archived == undefined) this.fixArchived(doc.id);
                        let archived = doc.data().archived ? doc.data().archived : false;
                        if (!archived) self.poolOfDocs.push(data);
                    });
                });
        },

        launchItemWindow(item, action) {
            console.log('item', item);
            this.selectedItem = {
                id: item.id,
                title: item.title ? item.title : '',
                details: item.details ? item.details : '',
                link: item.link ? item.link : '',
                uploadUrls: item.uploadUrls ? item.uploadUrls : [],
                uploadPDFs: item.uploadPDFs ? item.uploadPDFs : [],
                showLink: true,
                index: item.index ? item.index : 999,
                heading: 'Contact',
                action: action
                // new version control
                // currentVersionID: item.currentVersionID,
                // currentVersionTitle: item.currentVersionTitle,
                // loadVersion: item.loadVersion,
                // versionPath: '/pooledSOPS'
            };
            this.showItemWindow = true;
        },

        returnedItem(returnItem) {
            // console.log(returnItem);

            this.showItemWindow = false;
            this.selectedItem = {};

            if (returnItem.edited) {
                NProgress.start();
                let self = this;
                if (returnItem.isNewItem) {
                    db.collection('pooledSOPS')
                        .add({
                            archived: false,
                            title: returnItem.title ? returnItem.title : 'No title',
                            details: returnItem.details ? returnItem.details : '',
                            link: returnItem.link ? returnItem.link : '',
                            index: returnItem.index ? returnItem.index : 999,
                            uploadUrls: returnItem.uploadUrls ? returnItem.uploadUrls : [],
                            uploadPDFs: returnItem.uploadPDFs ? returnItem.uploadPDFs : [],
                            versionControl: firebase.firestore.FieldValue.arrayUnion(firebase.firestore.Timestamp.fromDate(new Date()))
                        })
                        .then(docRef => {
                            NProgress.done();
                        })
                        .catch(error => {
                            console.error('Error adding new item: ', error);
                            NProgress.done();
                        });
                } else {
                    db.collection('pooledSOPS')
                        .doc(returnItem.id)
                        .update({
                            archived: false,
                            title: returnItem.title ? returnItem.title : 'No title',
                            details: returnItem.details ? returnItem.details : '',
                            link: returnItem.link ? returnItem.link : '',
                            index: returnItem.index ? returnItem.index : 999,
                            uploadUrls: returnItem.uploadUrls ? returnItem.uploadUrls : [],
                            uploadPDFs: returnItem.uploadPDFs ? returnItem.uploadPDFs : [],
                            versionControl: firebase.firestore.FieldValue.arrayUnion(firebase.firestore.Timestamp.fromDate(new Date()))
                        })
                        .then(docRef => {
                            NProgress.done();
                        })
                        .catch(error => {
                            console.error('Error updating item: ', error);
                            NProgress.done();
                        });
                }
            } else {
                NProgress.done();
            }
        },

        archiveItem(item) {
            let message;
            message = {
                title: 'Really archive this helper?',
                body: 'Click YES below to archive.'
            };
            let options = {
                okText: 'YES, ARCHIVE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(dialog => {
                    NProgress.start();
                    db.collection('pooledSOPS')
                        .doc(item.id)
                        .update({
                            archived: true
                        })
                        .then(() => {
                            NProgress.done();
                        });
                })
                .catch(error => {
                    console.log(error);
                    this.close();
                    NProgress.done();
                });
        },

        updateList(localList, selectedList, savedList) {
            let tempList = localList.filter(item => savedList.includes(item.name)).map(itemtwo => itemtwo);
            console.log(tempList);
            tempList.forEach(item => selectedList.push(item));
        },

        showDetails(item) {
            this.tempSelectedMetaCodes = item.codes;
            this.selectedItem = item;
            this.showDetailsModal = true;
        },

        editItem(item) {
            this.selectedEditItem = item;
            this.showEditItemModal = true;
        },

        saveEditItem(itemToSave) {
            let self = this;
            console.log(itemToSave);
            NProgress.start();
            var adminSaveSmsItem = firebase.functions().httpsCallable('adminSaveSmsItem');
            adminSaveSmsItem({
                collection: 'pooledSOPS',
                docID: itemToSave.id,
                title: itemToSave.title,
                details: itemToSave.details
            })
                .then(function(result) {
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        },

        editMeta(item) {
            // console.log(item);
            this.tempSelectedMetaCodes = item.codes;
            this.selectedEditMeta = item;
            this.showEditMetaModal = true;
        },

        // saveEditMeta(itemToSave,newCodes) {
        //   let self = this;
        //   // console.log(itemToSave);
        //   // console.log(newCodes);
        //   NProgress.start();
        //   var adminSaveSmsItemMeta = firebase.functions().httpsCallable('adminSaveSmsItemMeta');
        //   adminSaveSmsItemMeta({
        //     collection: 'pooledSOPS',
        //     docID: itemToSave.id,
        //     codes: newCodes,
        //     codeGroups: self.codeGroups,
        //           tags: self.selectedTags,
        //     index: parseInt(itemToSave.index),
        //   })
        //   .then(function(result) {
        //     self.close();
        //     NProgress.done();

        //   })
        //   .catch(function(error) {
        //     console.log('Error',error);
        //     NProgress.done();
        //   });

        // },

        addItem() {
            this.addItemModal = true;
        },

        saveNewItem() {
            console.log(this.newItem);
            let self = this;
            NProgress.start();
            var adminSaveNewSmsItem = firebase.functions().httpsCallable('adminSaveNewSmsItem');
            adminSaveNewSmsItem({
                collection: 'pooledSOPS',
                title: self.newItem.title,
                details: self.newItem.details,
                codes: self.newItem.codes,
                index: parseInt(self.newItem.index)
            })
                .then(function(result) {
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
label {
    margin-bottom: 10px;
    display: block;
}

.checkbox-wrapper {
    .field {
        float: left;
        margin-right: 2rem;
        .has-code {
            color: red;
            font-weight: bold;
        }
    }
}

.site-wrapper .container {
    max-width: 1050px;
}
.buttons {
    margin-bottom: 5px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
input[type='text'],
input[type='tel'] {
    padding: 2rem 1rem;
}

textarea {
    min-height: 120px;
    height: 400px;
    margin-top: 10px;
    padding: 1.5rem 1rem;
}

.show-details {
    cursor: pointer;
    &:hover {
        color: dodgerblue;
    }
}

.modal {
    .modal-card-body {
        position: relative;
    }
}
.posi {
    position: absolute;
    // bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: #fff;
    z-index: 999;
}
</style>
