<template>
  
  <div>

      <div class="site-wrapper no-header">
      
        <div class="container">

            <h3 class="is-size-5">FIX FAVOURITES</h3><br>


            <div class="clear"></div>

            {{sentUser}}


            <div class="buttons">
              <span class="button is-danger" @click="fixFavourite()">Fix</span>
            </div>

            <hr>




        </div>

      </div>


      




  </div>

</template>


<script> 

  import firebase from 'firebase/compat/app';
  import { db } from '../main.js';
  import 'firebase/compat/auth'; 
  import 'firebase/firestore';
  import 'firebase/compat/functions';
  import NProgress from 'nprogress';  
  //import * as moment from 'moment';

  export default {

    components: {
      
    },

    props: {
      sentUser: Object,
    },

    mounted: function() {
      firebase.auth().onAuthStateChanged( this.onUserLogin ); 
    },

    data: function() {
      return {
        

      }
    },

    computed: {
      isSteve() {
        return this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' || this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1';
      },
      
    },

    methods: {

      onUserLogin: function( user ) {
        if (user) {         
          // console.log(user);
        }
      },

      

      fixFavourite() {
        let self = this;

        // !!!!!!!!!!! check adminMigrateSMS!! Is this right for FixFave??

        // let message;
        // message = {
        //   title: 'Confirm Fix?',
        //   body: 'Click YES below to proceed.'
        // };
        // let options = {
        //   okText: 'YES',
        //   backdropClose: true,
        //   cancelText: 'Cancel',
        // };
        // this.$dialog
        //   .confirm(message, options)
        //   .then(function(dialog) {
              
        //       NProgress.start();
        //       var adminMigrateSMS = firebase.functions().httpsCallable('adminMigrateSMS');
        //       adminMigrateSMS({
        //           sourceVessel: self.sourceVessel,
        //           targetVessel: self.targetVessel,
        //       })
        //       .then(function(result) {
        //         var sanitizedMessage = result.data.text;
        //         console.log('Result',sanitizedMessage);
        //         console.log('Message',result.data.message);
        //         NProgress.done();

        //       })
        //       .catch(function(error) {
        //         console.log('Error',error.text);
        //         NProgress.done();
        //       }); 


        //   })
        //   .catch(function(error) {
        //     console.log(error); 
        //     NProgress.done();
        //   });         






        




      },

      

      

    },

  }



</script> 




<style lang="scss" scoped>
  
  input[type="text"] {
    width: 90%;
    float: left;
    display: block;
    margin-bottom: 2rem;
    // &:after {
    //   content: "\f16c";
    //   font-family: "Font Awesome 5 Brands";
    //   color: red;
    // }
    // &.valid:after {

    // }
  }
  .icon-wrap {
    display: block;
    float: left;
    height: 35px;
    margin-left: 10px;
    font-size: 1.5rem;
    color: red;
    &.valid {
      color: green;
    }
  }
  

</style> 