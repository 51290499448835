<template>

	<div>								

			<div class="buttons">
				<!-- <span class="button is-info is-small" @click="sendAudit(index,vesselID)">Recount</span> -->
				<span class="button is-info is-small" :disabled="isCounted" @click="startCount">Recount</span>
				<span class="button is-warning is-small" :disabled="isAudited" @click="startAudit">Audit</span>
			</div>

	</div>

</template>



<script>

import firebase from 'firebase/compat/app';
import { db } from '../main.js';
//import 'firebase/compat/auth'; 
//import 'firebase/firestore';
//import 'firebase/compat/functions';
import NProgress from 'nprogress';

export default {

	data: function() {
		return {
			count: {
				hasDesignatedStatement: false,
				hasMasterStatement: false,
				maintenanceLogs: 0,
				startupLogs: 0,
				maintenanceChecklists: 0,
				startupChecklists: 0,
				contacts: 0,
				sops: 0,
				eops: 0,
				policies: 0,
				hazards: 0,
			},
			isCounted: false,
			isAudited: false,

			auditResult: {
				hasDesignatedStatement: false,
				hasMasterStatement: false,
				maintenanceLogs: 0,
				startupLogs: 0,
				maintenanceChecklists: 0,
				startupChecklists: 0,
				contacts: 0,
				sops: 0,
				eops: 0,
				policies: 0,
				hazards: 0,
		    auditScore: 0,
		    auditCode: 'red',
		    auditPercent: 0,
		  },

		}
	},

	props: {
    index: Number,
    vesselID: String,
    counted: Boolean,
    audited: Boolean
  },

  mounted() {
  	this.isCounted = this.counted;
  	this.isAudited = this.audited;
  },

  methods: {
  	sendCount: function() {
  		let self = this;
  		//let result = 'Vessel ID ' + vesselID + 'with index ' + index;
  		let result = {
  			index: self.index,
  		}
			this.$emit('sendCount', result);
  	},

  	startCount: function() {
			let self = this;

			async function clearCounts() {
				self.count.hasDesignatedStatement = false;
				self.count.hasMasterStatement = false;
				self.count.maintenanceLogs = 0;
				self.count.startupLogs = 0;
				self.count.maintenanceChecklists = 0;
				self.count.startupChecklists = 0;
				self.count.contacts = 0;
				self.count.sops = 0;
				self.count.eops = 0;
				self.count.policies = 0;
				self.count.hazards = 0;

				self.auditResult.auditScore = 0;
		    self.auditResult.auditCode = 'red';
		    self.auditResult.auditPercent = 0;

				console.log('Cleared: ',self.count);
			}

			async function getCounts() {
				await db.collection('vessels').doc(self.vesselID).collection('logMaintenance').get().then(snap => {
				  self.count.maintenanceLogs = (snap.size ? snap.size : 0) 
				});
				await db.collection('vessels').doc(self.vesselID).collection('logStartups').get().then(snap => {
					self.count.startupLogs = (snap.size ? snap.size : 0) 
				});
				await db.collection('vessels').doc(self.vesselID).collection('smsMaintenanceItems').get().then(snap => {
					self.count.maintenanceChecklists += (snap.size ? snap.size : 0) 
				});
				await db.collection('vessels').doc(self.vesselID).collection('smsCompanyMaintenanceItems').get().then(snap => {
					self.count.maintenanceChecklists += (snap.size ? snap.size : 0) 
				});
				await db.collection('vessels').doc(self.vesselID).collection('smsStartupItems').get().then(snap => {
					self.count.startupChecklists += (snap.size ? snap.size : 0) 
				});
				await db.collection('vessels').doc(self.vesselID).collection('smsCompanyStartupItems').get().then(snap => {
					self.count.startupChecklists += (snap.size ? snap.size : 0) 
				});
				await db.collection('vessels').doc(self.vesselID).collection('smsContacts').get().then(snap => {
					self.count.contacts += (snap.size ? snap.size : 0) 
				});
				await db.collection('vessels').doc(self.vesselID).collection('smsCompanyContacts').get().then(snap => {
					self.count.contacts += (snap.size ? snap.size : 0) 
				});
				await db.collection('vessels').doc(self.vesselID).collection('smsHazards').get().then(snap => {
					self.count.hazards += (snap.size ? snap.size : 0) 
				});
				await db.collection('vessels').doc(self.vesselID).collection('smsPolicies').get().then(snap => {
					self.count.policies += (snap.size ? snap.size : 0) 
				});
				await db.collection('vessels').doc(self.vesselID).collection('smsCompanyPolicies').get().then(snap => {
					self.count.policies += (snap.size ? snap.size : 0) 
				});
				await db.collection('vessels').doc(self.vesselID).collection('smsSOPS').get().then(snap => {
					self.count.sops += (snap.size ? snap.size : 0) 
				});
				await db.collection('vessels').doc(self.vesselID).collection('smsCompanySOPS').get().then(snap => {
					self.count.sops += (snap.size ? snap.size : 0) 
				});
				await db.collection('vessels').doc(self.vesselID).collection('smsEOPS').get().then(snap => {
					self.count.eops += (snap.size ? snap.size : 0) 
				});
				await db.collection('vessels').doc(self.vesselID).collection('smsCompanyEOPS').get().then(snap => {
					self.count.eops += (snap.size ? snap.size : 0) 
				});
				await db.collection('vessels').doc(self.vesselID).get().then(function(doc) {
					self.count.hasDesignatedStatement = (doc.data().designatedStatement ? true : false);
				});
				await db.collection('vessels').doc(self.vesselID).get().then(function(doc) {
					self.count.hasMasterStatement = (doc.data().masterStatement ? true : false);
				});
				console.log('Finished getting counts',self.count);

			}

			async function updateCounts() {
				await db.collection('vessels').doc(self.vesselID).update({
					hasDesignatedStatement: self.count.hasDesignatedStatement,
					hasMasterStatement: self.count.hasMasterStatement,
					countStartupItems: self.count.startupChecklists,
					countMaintenanceItems: self.count.maintenanceChecklists,
					countStartupLogs: self.count.startupLogs,
					countMaintenanceLogs: self.count.maintenanceLogs,
					countHazards: self.count.hazards,
					countContacts: self.count.contacts,
					countPolicies: self.count.policies,
					countSOPS: self.count.sops,
					countEOPS: self.count.eops,
				}).then(function() {
					console.log('Counts updated.');
				});
			}

			async function runCount() {
			  await clearCounts();
			  await getCounts();
			  await updateCounts();
			  self.sendCount();
			  console.log('Count done!');
			  NProgress.done();
			}
			if (!self.isCounted) {
  			NProgress.start();
				console.log('Running..');
				self.isCounted = true;
				runCount();
			}


  	},


  	startAudit: function() {
  		let self = this;

  		async function clearResults() {
				self.auditResult.hasDesignatedStatement = false;
				self.auditResult.hasMasterStatement = false;
				self.auditResult.maintenanceLogs = 0;
				self.auditResult.startupLogs = 0;
				self.auditResult.maintenanceChecklists = 0;
				self.auditResult.startupChecklists = 0;
				self.auditResult.contacts = 0;
				self.auditResult.sops = 0;
				self.auditResult.eops = 0;
				self.auditResult.policies = 0;
				self.auditResult.hazards = 0;
				self.auditResult.auditScore = 0;
		    self.auditResult.auditCode = 'red';
		    self.auditResult.auditPercent = 0;
		    self.auditResult.timestamp = '';

				console.log('Cleared: ',self.count);
			}

  		async function tallyScore() {
  			await db.collection('vessels').doc(self.vesselID).get().then(function(doc) {
					var data = doc.data();

					if (data.countHazards > 0) {
						self.auditResult.hazards = data.countHazards;
						self.auditResult.auditScore += 2;
	        }

					if (data.countContacts > 0) {
						self.auditResult.contacts = data.countContacts;
						self.auditResult.auditScore += 2;
	        }

					if (data.countPolicies > 0) {
						self.auditResult.policies = data.countPolicies;
						self.auditResult.auditScore += 2;
	        }

					if (data.countSOPS > 0) {
						self.auditResult.sops = data.countSOPS;
						self.auditResult.auditScore += 2;
	        }

					if (data.countEOPS > 0) {
						self.auditResult.eops = data.countEOPS;
						self.auditResult.auditScore += 2;
	        }

					if (data.countStartupLogs > 0) {
						self.auditResult.startupLogs = data.countStartupLogs;
						self.auditResult.auditScore += 2;
	        }

					if (data.countMaintenanceLogs > 0) {
						self.auditResult.maintenanceLogs = data.countMaintenanceLogs;
						self.auditResult.auditScore += 2;
	        }

					if (data.countStartupItems > 0) {
						self.auditResult.startupItems = data.countStartupItems;
						self.auditResult.auditScore += 1;
	        }

					if (data.countMaintenanceItems > 0) {
						self.auditResult.maintenanceItems = data.countMaintenanceItems;
						self.auditResult.auditScore += 1;
	        }

					if (data.hasDesignatedStatement) {
						self.auditResult.hasDesignatedStatement = true;
						self.auditResult.auditScore += 1;
	        }

					if (data.hasMasterStatement) {
						self.auditResult.hasMasterStatement = true;
						self.auditResult.auditScore += 1;
	        }

	        console.log('Score Tallied',self.auditResult);

				});
  		}

  		async function saveNewAudit() {
				if (self.auditResult.auditScore > 10) {
					self.auditResult.auditCode = self.auditResult.auditScore > 17 ? 'green' : 'orange';
				}
				else {
					self.auditResult.auditCode = 'red';
				}
				self.auditResult.auditPercent = Math.round(self.auditResult.auditScore / 18 * 100);
				self.auditResult.timestamp = firebase.firestore.FieldValue.serverTimestamp();
				await db.collection('vessels').doc(self.vesselID).collection('smsAudits').add(self.auditResult).then(function(doc) {
					let auditID = doc.id;
					//console.log('Code: ',self.auditResult.auditCode);
		      db.collection('vessels').doc(self.vesselID).update({
		        lastAuditDate: firebase.firestore.FieldValue.serverTimestamp(),
		        lastAuditID: auditID,
		        lastAuditCode: self.auditResult.auditCode,
		        lastAuditScore: self.auditResult.auditScore,
		        lastAuditPercent: self.auditResult.auditPercent
		      });
		    });
		    console.log('audit saved...');
			}

  		async function runAudit() {
  			await clearResults();
  			await tallyScore();
  			await saveNewAudit();
  			//self.sendAudit();
  			console.log('Audit done!');
			  NProgress.done();
  		}
  		
			if (!self.isAudited) {
  			NProgress.start();
				console.log('Running audit..');
				self.isAudited = true;
				runAudit();
			}



  	},

 

  }

}

</script>

<style lang="scss" scoped>

	

</style>