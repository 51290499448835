<template>
    <div>
        <div v-if="isSteve" class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">Create a new user</h3>

                <hr />

                <div class="form-wrapper">
                    <div class="form-group-reg">
                        <label class="label-fixed">Authorising entity:</label>
                        <input class="input padded" type="text" placeholder="Type organisation name" v-model="profile.authority" required />
                    </div>

                    <div class="form-group-reg">
                        <label class="label-fixed">User full name:</label>
                        <input class="input padded" type="text" placeholder="Type name" v-model="profile.username" required />
                    </div>

                    <div class="form-group-reg">
                        <label class="label-fixed">User email address:</label>
                        <input class="input padded" type="text" placeholder="Type email" v-model="profile.email" required />
                    </div>

                    <div class="form-group-reg">
                        <label class="label-fixed">User mobile number:</label>
                        <input
                            placeholder="04.."
                            autocomplete="false"
                            class="input padded"
                            type="tel"
                            v-model="profile.phone"
                            :maxlength="10"
                            v-on:keypress="isNumber($event)"
                            required
                        />
                    </div>

                    <!-- <div class="form-group-reg">
                        <label class="label-fixed">Temporary Password:</label>
                        <input
                            autocomplete="off"
                            autocorrect="off"
                            minlength="6"
                            maxlength="64"
                            autocapitalize="off"
                            spellcheck="false"
                            class="input"
                            type="text"
                            placeholder="Temporary password"
                            v-model="profile.password"
                            required
                        />
                    </div> -->

                    <!-- <div class="form-group-reg">
                        <div class="select is-primary">
                            <select v-model="profile.state">
                                <option disabled value="">State</option>
                                <option v-for="state in allstates" :key="state.id">
                                    {{ state.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <div class="select is-primary">
                            <select v-model="profile.industry">
                                <option disabled value="">Industry</option>
                                <option v-for="industry in industries" :key="industry.id">
                                    {{ industry.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <div class="select is-primary">
                            <select v-model="profile.group">
                                <option disabled value="">Industry Group</option>
                                <option v-for="group in groups" :key="group.id">
                                    {{ group.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <label class="label-fixed">User Role:</label>
                        <div class="select is-primary">
                            <select v-model="profile.role">
                                <option value="free" default>Free</option>
                                <option value="starter">Starter</option>
                                <option value="fleet">Fleet</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <label class="label-fixed">Max Vessels:</label>
                        <div class="select is-primary">
                            <select v-model="profile.maxVessels">
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <datetime placeholder="Anniversary Date" auto v-model="profile.customAnniversary"></datetime>
                    </div>

                    <div class="form-group-reg">
                        <input class="is-checkradio" id="doesDiving" type="checkbox" v-model="profile.doesDiving" />
                        <label for="doesDiving">Engaged in diving operations?</label>
                    </div> -->

                    <div class="form-group-reg">
                        <label class="label-fixed">Temporary password:</label>
                        <input type="text" disabled class="input padded" v-model="randomPassword" />
                        <div class="spacer s10"></div>
                        <div class="button is-light is-small" @click="resetTempPassword()">Regenerate</div>
                    </div>

                    <hr />

                    <div class="buttons">
                        <button class="button is-primary" :disabled="!showButton" @click="createUser()">Create user</button>
                        <button class="button" @click="clearData">Clear</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'AdminCreateUser',

    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            firebaseid: '',
            isSteve: false,
            profile: {
                authority: 'The MRQ Team'
            },
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            allstates: [
                { id: '1', name: 'Tasmania' },
                { id: '2', name: 'Victoria' },
                { id: '3', name: 'New South Wales' },
                { id: '4', name: 'Queensland' },
                { id: '5', name: 'South Australia' },
                { id: '6', name: 'Western Australia' },
                { id: '7', name: 'Northern Territory' }
            ],
            industries: [
                { id: '1', name: 'Fishing' },
                { id: '2', name: 'Aquaculture' },
                { id: '3', name: 'Commercial Diving' },
                { id: '4', name: 'Marine Construction' },
                { id: '5', name: 'Charter Vessel' },
                { id: '6', name: 'Passenger Vessel' },
                { id: '7', name: 'Other' }
            ],
            groups: [
                { id: '1', name: 'TSIC' },
                { id: '2', name: 'Tasmanian Abalone' },
                { id: '3', name: 'Victorian Abalone' },
                { id: '4', name: 'NSW Abalone' },
                { id: '5', name: 'Other' }
            ],
            customAnniversary: '',
            randomPassword: ''
        };
    },

    watch: {
        firebaseid: function() {
            this.isSteve = this.firebaseid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.firebaseid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false;
        }
    },

    computed: {
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        isEmailValid() {
            return this.profile.email == '' ? false : this.reg.test(this.profile.email) ? true : false;
        },
        showButton() {
            return this.profile.username != '' && this.isEmailValid && this.phoneNumberValid && this.profile.authority != '';
        },
        phoneNumberValid() {
            //return true;
            if (this.profile.phone) {
                return this.profile.phone.length == 10 && this.profile.phone.slice(0, 2) == '04';
            } else {
                return false;
            }
        },
        finalNumber() {
            //return true;
            if (this.profile.phone) {
                return '+61' + this.profile.phone.slice(1, 10);
            } else {
                return false;
            }
        }
        // nextCharge() {
        //     if (this.profile.role == 'free' || this.profile.role == undefined) {
        //         return 0;
        //     } else {
        //         return this.profile.role == 'starter' ? 249 : 449;
        //     }
        // },
        // anniversary() {
        //     return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
        // }
    },

    methods: {
        onUserLogin(user) {
            if (user) {
                this.firebaseemail = user.email;
                this.firebasedisplayname = this.userData.username;
                this.firebaseid = user.uid;
            }
            this.randomPassword = uuidv4().slice(0, 23);
        },

        isNumber($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        resetTempPassword() {
            this.randomPassword = uuidv4().slice(0, 23);
        },

        createUser() {
            NProgress.start();

            // let userName = this.profile.username;
            //      let userEmail = this.profile.email;
            //      let userPhone = this.profile.phone;
            //      let tempPassword = this.profile.password;
            //      let userState = this.profile.state;
            //      let userIndustry = this.profile.industry;
            //      let userGroup = this.profile.group;
            //      let userRole = this.profile.role;
            //      let userAnniversaryDate = this.anniversary;
            //      let userDoesDiving = this.profile.doesDiving;
            //      let userLastCharge = 0;
            //      let userNextCharge = this.nextCharge;
            //      let userLastPayment = 0;
            //      let userMaxVessels = this.profile.maxVessels;
            //      let userSignupDate = new Date();

            let self = this;

            // let finalAnniversary = self.profile.customAnniversary == '' ? self.anniversary : self.profile.customAnniversary;

            // console.log(finalAnniversary);

            var createNewUserByAdmin = firebase.functions().httpsCallable('createNewUserByAdmin');
            createNewUserByAdmin({
                userName: self.profile.username,
                userEmail: self.profile.email,
                userPhone: self.finalNumber,
                tempPassword: self.randomPassword,
                userState: '',
                userIndustry: '',
                os: 'not set'
            })
                .then(function(result) {
                    self.clearData();
                    NProgress.done();
                })
                .catch(function(err) {
                    console.log(err);
                    self.clearData();
                    NProgress.done();
                });
        },

        clearData() {
            this.profile = {
                role: 'free',
                state: '',
                industry: '',
                group: '',
                maxVessels: 0,
                customAnniversary: ''
            };
        }
    }
};
</script>

<style lang="scss" scoped>
.is-checkradio[type='checkbox'] + label {
    display: inline-block;
    margin-left: 0;
    padding-top: 0;
}

.form-group-reg {
    .vdatetime {
        input[type='text'] {
            font-size: 1rem !important;
        }
    }
}
</style>
