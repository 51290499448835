<template>
	
	<div>

			<div class="site-wrapper no-header">
			
				<div class="container">

						<h3 class="is-size-5">All Vessel Search &nbsp; <span v-if="loadingSchedules"><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp;Loading...</span></h3><br>

            <div class="spacer s20"></div>

            <!-- <input class="is-checkradio" type="checkbox" id="setRecentfirst" @change="reverseOrder()" v-model="recentFirst">
            <label for="setRecentfirst"> Recent payments first</label> -->

            <!-- <div class="spacer s30"></div> -->

            <!-- <h4>Total Vessel Payments Due: <span class="bold">${{numberWithCommas(totalPayments)}}</span></h4>

            <h4>Selected Vessel Payments Due: <span class="bold">${{numberWithCommas(totalCheckedPayments)}}</span> ({{checkedVessels.length}} vessels)</h4> -->

            <h4>Total vessels: {{allVessels.length}}</h4>

            <div class="spacer s30"></div>

            

             <div class="spacer s30"></div>

            <div class="buttons">
              <button :disabled="checkedVesselIDs.length == 0" class="button is-danger" @click="clearVessels()">Clear {{checkedVesselIDs.length}} Selections</button>
              <button :disabled="checkedVesselIDs.length == 0" class="button is-success" @click="updateVessels()">Update Selected Vessels</button>
            </div>

            <div class="spacer s10"></div>

            <!-- <input class="is-checkradio" type="checkbox" id="showDemo" v-model="showDemo">
            <label for="showDemo"> isDemo Only</label>

            <input class="is-checkradio" type="checkbox" id="showFreebie" v-model="showFreebie">
            <label for="showFreebie"> isFreebie Only</label> -->

            <hr>

            <!-- <h5 class="bold">Vessels to watch - Last Startup ID is missing:</h5>
            <div class="red bold">{{flaggedVessels.join(', ')}}</div> -->


            <!-- <input class="is-checkradio" type="checkbox" id="hideDemo" v-model="hideDemo">
            <label for="hideDemo"> Hide Trial Vessels</label> -->

            <!-- <input class="is-checkradio" type="checkbox" id="hidePast" v-model="hidePast">
            <label for="hidePast"> Hide Past Vessels</label> -->

            <!-- <input class="is-checkradio" type="checkbox" id="hideExpired" v-model="hideExpired">
            <label for="hideExpired"> Hide Expired Vessels</label> -->

            <div class="spacer s30"></div>

						<table class="table is-bordered is-fullwidth">
							<tr>
                <th>Vessel (and owner)</th>
                <th>Expiry Date</th>
                <th>Select</th>
              </tr>
							<!-- <tr v-for="(vessel, index) in allVessels" :key="index">
								<td>{{vessel.vesselName}}<br>{{vessel.vesselOwnerName}}<br>{{vessel.isDemo}}</td>
                <td>{{formatDate(vessel.nextChargeDate,'DD MMMM, YYYY')}}</td>
                <td>${{vessel.nextVesselCharge}}</td>
                <td>
                  <input class="is-checkradio" type="checkbox" :id="'index_' + index" v-model="vessel.tempSelected">
                  <label :for="'index_' + index"> </label>
                </td>
							</tr> -->
              <tr v-for="(vessel, index) in allVessels" :key="index">

                <td>
                  <span @click="setUpdateVessel(vessel)" class="vessel-name">{{vessel.vesselName}} ({{ vessel.fixSchedules.length }}/{{ vessel.totalSchedules }})</span>
                  <br>{{vessel.vesselOwnerName}}<br>
                  <span v-if="vessel.legacySchedulesFixed" class="button is-small is-success">Updated</span>
                  <!-- <div :class="[vessel.isDemo ? 'is-trial' : 'not-trial']">Trial: {{vessel.isDemo}}</div>
                  <div :class="[vessel.isPast ? 'is-past' : 'not-past']">Past: {{vessel.isPast}}</div> -->
                  <!-- <span class="is-trial" v-if="vessel.isDemo">isDemo</span>
                  <span class="is-past" v-if="vessel.isFreebie">isFreebie</span>
                  <span class="red" v-if="vessel.aaaPlans.includes('pro') && vessel.isActive">Last Startup ID: {{vessel.lastStartupID}}.</span> -->
                  <!-- <span class="is-past" v-if="vessel.isPast">Date has past</span>
                  <span class="is-expired" v-if="vessel.isExpired">Vessel Expired</span> -->
                </td>

                <td>{{formatDate(vessel.nextChargeDate,'DD MMMM, YYYY')}}</td>

                <td>
                  <input class="is-checkradio" type="checkbox" :id="'index_' + index" v-model="vessel.tempSelected">
                  <label :for="'index_' + index"> </label>
                </td>

              </tr>
						</table>


				</div>

			</div>




      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showUpdateModal}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Vessel Info for {{selectedVessel.vesselName}}</p>
              <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>
            <section class="modal-card-body">     

              <p>Vessel ID: {{selectedVessel.vesselID}}</p>
              <div class="spacer s20"></div>
              <!-- {{ selectedVessel.fixSchedules }} -->

              <div v-if="selectedVessel.fixSchedules">  

                <div v-if="selectedVessel.fixSchedules.length > 0">
                  <table class="table" v-for="sched in selectedVessel.fixSchedules">
                    <tbody>
                      <tr>
                        <th>Title</th>
                        <td>{{ sched.title }}</td>
                      </tr>
                      <tr>
                        <th>tracks</th>
                        <td><span v-if="sched.trackHours">Hours</span> <span v-if="sched.trackTime">Time</span></td>
                      </tr>
                      <tr>
                        <th>Checklists</th>
                        <td>
                          <div v-if="sched.checklist">One: ({{ sched.checklist.length }})</div>
                          <div v-if="sched.checklistTwo">Two: ({{ sched.checklistTwo.length }})</div>
                          <div v-if="sched.checklistThree">Three: ({{ sched.checklistThree.length }})</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                

                <!-- <div class="form-group-reg">   
                  <input class="is-checkradio" id="isExpired" type="checkbox" v-model="selectedVessel.isExpired">
                  <label for="isExpired">Is Expired?</label>
                </div>  

                <div class="form-group-reg">   
                  <input class="is-checkradio" id="isDemo" type="checkbox" v-model="selectedVessel.isDemo">
                  <label for="isDemo">Is Demo?</label>
                </div>  

                <div class="form-group-reg">   
                  <input class="is-checkradio" id="isPaidFor" type="checkbox" v-model="selectedVessel.isPaidFor">
                  <label for="isPaidFor">Is Paid For?</label>
                </div>  

                <div class="form-group-reg">   
                  <input class="is-checkradio" id="isFreebie" type="checkbox" v-model="selectedVessel.isFreebie">
                  <label for="isFreebie">Is Freebie?</label>
                </div>  

                <div class="form-group-reg">   
                  <input class="is-checkradio" id="isActive" type="checkbox" v-model="selectedVessel.isActive">
                  <label for="isActive">Is Active?</label>
                </div>   -->

                <!-- <h4 class="bold caps">Next Charge Date:</h4>
                <div class="spacer s10"></div>
                <div class="form-group-reg">   
                  <datetime placeholder="Next Charge Date" auto v-model="selectedVesselNCDate"></datetime>
                  <p>{{formatDate(selectedVessel.nextChargeDate,'DD MMMM, YYYY')}}</p>
                </div>

                <div class="form-group-reg">   
                  <input class="is-checkradio" id="useGlobal" @change="selectedVesselNCDate = globalNextChargeDate" type="checkbox" v-model="selectedVesselUseGlobal" >
                  <label for="useGlobal">Use 31 December, 2024</label>
                </div> 

                {{selectedVesselNCDate}} -->

                <!-- <div class="form-group-reg">   
                  <label>aaaPlans</label>
                  <p>{{selectedVessel.aaaPlans}}</p>
                </div> -->

                <!-- <h4 class="bold">AAA PLANS:</h4>
                <div class="spacer s10"></div>
                <div class="form-group-reg">   
                  <div v-for="(plan, index) in allPlans">
                    <input class="is-checkradio" :id="'index_id_' + index" :value="plan" type="checkbox" v-model="selectedVesselPlans">
                    <label :for="'index_id_' + index">{{plan}}</label>
                  </div>
                </div>   -->

                <!-- <input class="is-checkradio" type="checkbox" :id="'index_id_' + index" :value="vessel.vesselID" v-model="selectedIDs"> -->

                <!-- <div class="form-group-reg">     
                  <input placeholder="149" autocomplete="false" class="input" type="tel" v-model="selectedVessel.lastVesselCharge" :maxlength="4" v-on:keypress="isNumber($event)" required>
                  <label class="label-fixed">Last Vessel Charge ($):</label> 
                </div>

                <div class="form-group">   
                  <span>&nbsp;</span>
                  <datetime auto input-id="lastChargeDate" v-model="selectedVessel.lastChargeDate"></datetime>   
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label>Last Charge Date</label>
                </div>

                <div class="spacer s30"></div>

                <div class="form-group-reg">    
                  <input placeholder="149" autocomplete="false" class="input" type="tel" v-model="selectedVessel.nextVesselCharge" :maxlength="4" v-on:keypress="isNumber($event)" required>
                  <label class="label-fixed">Next Vessel Charge ($):</label>  
                </div>

                <div class="form-group">   
                  <span>&nbsp;</span>
                  <datetime auto input-id="nextChargeDate" v-model="selectedVessel.nextChargeDate"></datetime>  
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label>Next Charge Date</label>
                </div>

                <div class="form-group-reg">   
                  <input class="is-checkradio" id="useGlobal" @change="selectedVessel.nextChargeDate = globalNextChargeDate" type="checkbox" >
                  <label for="useGlobal">Use global next charge date</label>
                </div> 

                <div class="spacer s30"></div>

                <div class="form-group-reg">   
                  <input class="is-checkradio" id="billingUpdated" type="checkbox" v-model="selectedVessel.billingUpdated">
                  <label for="billingUpdated">Mark as Billing Updated?</label>
                </div>  -->

                <div class="spacer s30"></div>

              </div>

              <!-- <div v-else><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading...</div> -->
              

            </section>
            <footer class="modal-card-foot">    
              <button class="button is-primary" @click="fixLegacyScheds()">Fix Legacy Schedules</button>
              <button class="button is-cancel" @click="close">Cancel</button>
            </footer>
        </div>
      </div>






	</div>

</template>


<script> 

	import firebase from 'firebase/compat/app';
	import { db } from '../main.js';
  // import 'firebase/compat/auth'; 
  import { getAuth, onAuthStateChanged } from "firebase/auth";
	import 'firebase/compat/firestore';
	import 'firebase/compat/functions';
	import NProgress from 'nprogress';	
	import * as moment from 'moment';

  import { doc, updateDoc, Timestamp } from "firebase/firestore"; 



	export default {

		name: 'AdminVessels',
		components: {

	  },
		mounted: function() {
			firebase.auth().onAuthStateChanged( this.onUserLogin );	
		},

		data: function() {
			return {
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
				allVessels: [],
				hideDemo: false,
        showDemo: false,
        showFreebie: false,
        hidePast: false,
        hideExpired: true,
        selectedVessel: {},
        allPlans: ['logs','sms','pro'],
        selectedVesselPlans: [],
        selectedVesselNCDate: '',
        selectedVesselUseGlobal: false,
        globalNextChargeDate: new Date(2024, 11, 31, 0, 0, 0, 0).toISOString(),
        showUpdateModal: false,
        recentFirst: false,

        loadingSchedules: false,

			}
		},

		computed: {
			user() {
	    	return this.$store.getters.getUser;
	    },
      isSteve() {
        return this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false;
      },
      filteredVessels() {
        let self = this;
        // return this.allVessels.filter(function (vessel) {
        //   if (self.hidePast && self.hideDemo && self.hideExpired) {
        //     return !vessel.isPast && !vessel.isDemo && !vessel.isExpired;
        //   } else if (!self.hidePast && !self.hideDemo && !self.hideExpired) {
        //     return vessel;
        //   } else {
        //     return self.hideExpired ? (self.hidePast ? (self.hideDemo ? !vessel.isPast && !vessel.isDemo && !vessel.isExpired : !vessel.isPast && !vessel.isExpired) : self.hideDemo ? !vessel.isDemo && !vessel.isExpired : !vessel.isExpired) : (self.hidePast ? (self.hideDemo ? !vessel.isPast && !vessel.isDemo : !vessel.isPast) : self.hideDemo ? !vessel.isDemo : vessel);
        //   }
        // });
        return this.allVessels.filter(function (vessel) {
          return self.showDemo ? 
            self.showFreebie ? vessel.isDemo && vessel.isFreebie : vessel.isDemo && !vessel.isFreebie : 
              self.showFreebie ? !vessel.isDemo && vessel.isFreebie : vessel;
        });
      },


      checkedVessels() {
        return this.filteredVessels.filter((vessel) => vessel.tempSelected).map((vess) => vess);
      },
      checkedVesselIDs() {
        return this.filteredVessels.filter((vessel) => vessel.tempSelected).map((vess) => vess.vesselID);
      },
      totalCheckedPayments() {
        return this.checkedVessels.length > 0 ? this.checkedVessels.map((item) => item.nextVesselCharge).reduce((acc, current) => parseInt(acc) + parseInt(current)) : 0;
      },
      totalPayments() {
        return this.filteredVessels.length > 0 ? this.filteredVessels.map((item) => item.nextVesselCharge).reduce((acc, current) => parseInt(acc) + parseInt(current)) : 0;
      },
      flaggedVessels() {
        return this.filteredVessels.filter((vessel) => vessel.aaaPlans.includes('pro') && vessel.isActive && vessel.lastStartupID == '' ).map((v) => v.vesselName);
      },
      allVesselIDs() {
        return this.allVessels.map((v) => v.vesselID);
      },

		},

    watch: {

    },

		methods: {

			onUserLogin( user ) {
      	if (user) {      		
	    		this.loadAllVessels();
	      }
      },

      close() {
      	this.selectedVessel = {};
        this.showUpdateModal = false;
        this.selectedVesselUseGlobal = false;
        this.selectedVesselPlans = [];
        this.selectedVesselNCDate = '';
      },

      async loadAllVessels() {
      	let self = this;
        this.loadingSchedules = true;
        let order = this.recentFirst ? 'desc' : 'asc';
      	// db.collection('vessels').where('aaaPlans','array-contains','pro').orderBy('nextChargeDate',order).onSnapshot(function(querySnapshot) {
        db.collection('vessels').where('aaaPlans','array-contains','pro').orderBy('nextChargeDate',order).get().then((querySnapshot) => {
      		self.allVessels = [];
      		querySnapshot.forEach((doc) => {

            let legacySchedulesFixed = doc.data().legacySchedulesFixed ? doc.data().legacySchedulesFixed : false;

            if (doc.data().vesselOwnerName != 'XYZ Orphan' && !doc.data().isExpired && !legacySchedulesFixed) {
              
              // let scheds = [];
              db.collection('vessels').doc(doc.id).collection('vesselPlantAndEquipment').get().then((snap) => {
                // snap.forEach((docRef) => {
                //   let archived = docRef.data().archived != undefined ? docRef.data().archived : false;
                //   let legacyFixed = docRef.data().legacyFixed != undefined ? docRef.data().legacyFixed : false;
                //   let trackTime = doc.data().trackTime ? doc.data().trackTime : false;
                //   let trackHours = doc.data().trackHours ? doc.data().trackHours : false;
                //   if (!archived && (trackTime || trackHours ) && !legacyFixed) scheds.push(docRef.data());
                // });
                if (snap.docs.length > 0) {
                  // console.log(doc.data().vesselName,snap.docs.length);
                  self.allVessels.push({
                    vesselID: doc.id,
                    totalSchedules: snap.docs.length,
                    fixSchedules: [],
                    vesselName: doc.data().vesselName,
                    isCompanyVessel: doc.data().isCompanyVessel ? doc.data().isCompanyVessel : false,
                    vesselOwnerName: (doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?'),
                    vesselOwnerID: doc.data().vesselOwnerID,
                    isDemo: doc.data().isDemo ? doc.data().isDemo : false,
                    isFreebie: doc.data().isFreebie ? doc.data().isFreebie : false,
                    nextChargeDate: doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate() : '',
                    legacySchedulesFixed: false,
                  });
                } 
              // })
              // .then(() => {
              //   self.allVessels.push({
              //     vesselID: doc.id,
              //     schedules: scheds,
              //     vesselName: doc.data().vesselName,
              //     isCompanyVessel: doc.data().isCompanyVessel ? doc.data().isCompanyVessel : false,
              //     vesselOwnerName: (doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?'),
              //     vesselOwnerID: doc.data().vesselOwnerID,
              //     isDemo: doc.data().isDemo ? doc.data().isDemo : false,
              //     isFreebie: doc.data().isFreebie ? doc.data().isFreebie : false,
              //     nextChargeDate: doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate() : '',
              //   });
              });

              // self.allVessels.push({
              //   vesselID: doc.id,
              //   vesselName: doc.data().vesselName,

              //   vesselOwnerName: (doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?'),
              //   vesselOwnerID: doc.data().vesselOwnerID,
              //   isCompanyVessel: doc.data().isCompanyVessel ? doc.data().isCompanyVessel : false,

              //   isPaidFor: doc.data().isPaidFor ? doc.data().isPaidFor : false,
              //   isDemo: doc.data().isDemo ? doc.data().isDemo : false,
              //   isFreebie: doc.data().isFreebie ? doc.data().isFreebie : false,
              //   // isExpired: doc.data().vesselExpired ? doc.data().vesselExpired : false,
              //   isExpired: doc.data().isExpired ? doc.data().isExpired : false,
              //   // lastChargeDate: doc.data().lastChargeDate ? doc.data().lastChargeDate.toDate().toISOString() : '',
              //   lastChargeDate: doc.data().lastChargeDate ? doc.data().lastChargeDate.toDate() : '',
              //   lastVesselCharge: doc.data().lastVesselCharge ? doc.data().lastVesselCharge : 0,
              //   // nextChargeDate: doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate().toISOString() : '',
              //   nextChargeDate: doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate() : '',
              //   nextVesselCharge: doc.data().nextVesselCharge ? doc.data().nextVesselCharge : 149,
              //   billingUpdated: doc.data().billingUpdated ? doc.data().billingUpdated : false,
              //   aaaPlans: doc.data().aaaPlans ? doc.data().aaaPlans : [],
              //   isPast: doc.data().nextChargeDate ? moment().isAfter(moment(doc.data().nextChargeDate.toDate())) : false,
              //   tempSelected: false,
              //   hasStartupTags: doc.data().startupTags ? true : false,
              //   lastStartupID: doc.data().lastStartupID ? doc.data().lastStartupID : '',
              //   isActive: doc.data().isActive ? doc.data().isActive : false,

              // });

            }

      		});
      	})
        .then(() => {
          setTimeout(() => {
            self.loadFixSchedules();
          }, 3000);
        });
      },

      async loadFixSchedules() {
        let self = this;
        await Promise.all(this.allVessels.map(async (vessel, index) => {
          await db.collection('vessels').doc(vessel.vesselID).collection('vesselPlantAndEquipment').get().then((snap) => {
            snap.forEach((doc) => {
              let data = doc.data();
              let archived = data.archived != undefined ? data.archived : false;
              let legacyFixed = data.legacyFixed != undefined ? data.legacyFixed : false;
              let trackTime = data.trackTime ? data.trackTime : false;
              let trackHours = data.trackHours ? data.trackHours : false;

              let ch1 = data.checklist ? data.checklist : [];
              let ch2 = data.checklistTwo ? data.checklistTwo : [];
              let ch3 = data.checklistThree ? data.checklistThree : [];
              let combinedChecklistItems = [...ch1,...ch2,...ch3];
              data.combinedChecklistItems = combinedChecklistItems;
              
              let lastLogHours = parseInt(data.lastLogHours ? data.lastLogHours : 0);
              data.lastLogHours = lastLogHours;
              let frequency = data.frequency ? data.frequency : 0;
              data.frequency = frequency;
              let currentHours = parseInt(data.currentHours ? data.currentHours : 0);
              data.currentHours = currentHours;
              var dueHours = trackHours ? frequency + lastLogHours : 0;
              data.dueHours = dueHours;

              let frequencyTime = data.frequencyTime ? data.frequencyTime : 90;
              data.frequencyTime = frequencyTime;
              let frequencyNumber = data.frequencyNumber ? data.frequencyNumber : '3';
              data.frequencyNumber = frequencyNumber;
              let frequencyPeriod = data.frequencyPeriod ? data.frequencyPeriod : 'Months';
              data.frequencyPeriod = frequencyPeriod;
              let startDate = data.startDate ? data.startDate.toDate() : new Date();
              data.startDate = startDate;
              let realDueDate = new Date(startDate.setDate(startDate.getDate() + frequencyTime)).getTime();
              data.realDueDate = realDueDate;

              data.id = doc.id;
              data.assetID = doc.id;
              if (!archived && (trackTime || trackHours ) && !legacyFixed) vessel.fixSchedules.push(data);
            });
          });
        }));
        this.loadingSchedules = false;
      },

      async fixLegacyScheds() {
        NProgress.start();
        console.log('fixing',this.selectedVessel);
        let self = this;

        await Promise.all(this.selectedVessel.fixSchedules.map(async (sched, index) => {

          await db.collection('vessels').doc(self.selectedVessel.vesselID).collection('vesselPlantSchedules').add({          
            title: sched.title + ' schedule',
            description: 'The original maintenance schedule',
            frequency: parseInt(sched.frequency ? sched.frequency : 0),
            trackHours: sched.trackHours ? sched.trackHours : false,
            trackTime: sched.trackTime ? sched.trackTime : false,
            openHours: parseInt(sched.openHours ? sched.openHours : 0),
            dueHours: sched.dueHours ? sched.dueHours : 0,
            currentHours: sched.currentHours ? sched.currentHours : 0,
            lastLogHours: sched.lastLogHours ? sched.lastLogHours : 0,
            checklist: sched.combinedChecklistItems ? sched.combinedChecklistItems : [],
            index: sched.index ? sched.index : 999,
            startDate: sched.startDate ? sched.startDate : new Date(),
            dueDate: sched.realDueDate ? sched.realDueDate : new Date(),
            frequencyTime: sched.frequencyTime,
            frequencyNumber: sched.frequencyNumber ? sched.frequencyNumber : '3',
            frequencyPeriod: sched.frequencyPeriod ? sched.frequencyPeriod : 'Months',
            archived: false,
            assetID: sched.assetID,
            lastUpdatedBy: 'OffshoreSMS',
            lastUpdateAt: new Date(),
          })
          .then(() => {
            db.collection('vessels').doc(self.selectedVessel.vesselID).collection('vesselPlantAndEquipment').doc(sched.assetID).update({
              legacyFixed: true
            });
          });

        }));
        setTimeout(() => {
          db.collection('vessels').doc(self.selectedVessel.vesselID).update({
            legacySchedulesFixed: true
          });
          this.selectedVessel.legacySchedulesFixed = true;
          self.showUpdateModal = false;
          NProgress.done();
        }, 2000);

        
        
      },

      reverseOrder() {
        this.loadAllVessels();
        console.log(this.recentFirst);
      },

      clearVessels() {
        this.loadAllVessels();
      },

      updateVessels() {
        let self = this;
        let t1 = new Date(2024, 11, 31, 12, 0, 0, 0);
        // let t1 = new Date("December 31, 2024 12:00:00");
        let t2 = new Date(t1);
        let newTimestamp = firebase.firestore.Timestamp.fromDate(new Date(t1));
        let t4 = firebase.firestore.Timestamp.fromDate(t1).toDate();
        let t3 = Date.parse('31 Dec 2024 00:00:00 GMT');
        let fbDirected = firebase.firestore.Timestamp.fromDate(new Date("December 10, 1815"));
        let t5 = moment().toDate();


        let updateArray = {
          isDemo: false,
          isFreebie: true,
          isPaidFor: true,
          aaaPlans: ['logs'],
          freeTrialActive: false,
          nextChargeDate: newTimestamp,
        };
        console.log(updateArray);

        let message;
        message = {
          title: 'Update these vessels?',
          body: 'Click YES below to proceed.'
        };
        let options = {
          okText: 'YES, FIX',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(async function(dialog) {
              
              NProgress.start();
              const docData = {
                isExpired: false,
                isDemo: false,
                isFreebie: true,
                isPaidFor: true,
                isActive: false,
                aaaPlans: ['logs'],
                freeTrialActive: false,
                nextChargeDate: Timestamp.fromDate(new Date("December 31, 2024")),
              };            
              await Promise.all(self.checkedVesselIDs.map(async (vesselID, index) => {
                await updateDoc(doc(db, "vessels", vesselID), docData);
              }));



              self.close();
              NProgress.done();


              // var adminUpdateMultipleVesselData = firebase.functions().httpsCallable('adminUpdateMultipleVesselData');
              // adminUpdateMultipleVesselData({
              //   listOfVesselIDs: self.checkedVesselIDs,
              //   updateArray: updateArray
              // })
              // .then(function(result) {
              //   self.close();
              //   NProgress.done();

              // })
              // .catch(function(error) {
              //   console.log('Error',error.text);
              //   NProgress.done();
              // }); 


          })
          .catch(function(error) {
            console.log(error); 
            NProgress.done();
          });    
      },

      formatDate : function (sentDate,format) {
          return moment(sentDate).format(format);
      },

      numberWithCommas(x) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },

      setUpdateVessel(vessel) {
        console.log(vessel);
        if (this.isSteve) {
          this.selectedVessel = vessel;
          // this.selectedVesselPlans = vessel.aaaPlans;
          // this.selectedVesselNCDate = new Date(vessel.nextChargeDate).toISOString();
          this.showUpdateModal = true;
        }          
      },

      updateVesselInfo() {
        console.log(this.selectedVessel);
        let self = this;
        this.showUpdateModal = false;
        let message;
        message = {
          title: 'Confirm Update?',
          body: 'Click YES below to proceed.'
        };
        let options = {
          okText: 'YES, UPDATE',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(function(dialog) {
              
              NProgress.start();

              const docData = {
                isExpired: self.selectedVessel.isExpired,
                isDemo: self.selectedVessel.isDemo,
                isFreebie: self.selectedVessel.isFreebie,
                isPaidFor: self.selectedVessel.isPaidFor,
                aaaPlans: self.selectedVesselPlans,
                isActive: self.selectedVessel.isActive,
                freeTrialActive: false,
                // nextChargeDate: Timestamp.fromDate(new Date("December 31, 2024")),
                nextChargeDate: Timestamp.fromDate(new Date(self.selectedVesselNCDate)),
              };    
              console.log(docData);        
              updateDoc(doc(db, "vessels", self.selectedVessel.vesselID), docData);

              NProgress.done();

              // var adminUpdateVesselDataSimple = firebase.functions().httpsCallable('adminUpdateVesselDataSimple');
              // adminUpdateVesselDataSimple({
              //   vesselID: self.selectedVessel.vesselID,
              //   vesselName: self.selectedVessel.vesselName,
              //   updateArray: {
              //     isDemo: self.selectedVessel.isDemo,
              //     isPaidFor: self.selectedVessel.isPaidFor,
              //     isExpired: self.selectedVessel.isExpired,
              //   },
              // })
              // .then(function(result) {
              //   self.close();
              //   NProgress.done();

              // })
              // .catch(function(error) {
              //   console.log('Error',error.text);
              //   NProgress.done();
              // }); 


          })
          .catch(function(error) {
            console.log(error); 
            NProgress.done();
          });         


      }

      

    }

	}



</script> 




<style lang="scss" scoped>
	
  .site-wrapper .container {
    max-width: 1050px;
  }
	.button {
		margin-top: 0;
    &.is-skip {
      background-color: #ff7878;
      color: #fff;
      border-color: #fff;
    }
	}
  input[type="text"] {
    padding: 2rem 1rem;
  }
  .form-group {
    label {
      top: auto;
      bottom: -25px;
    }
  }
	.traffic-light {
	  position: relative;
	  span {
	  	float: left;
	  	margin-right: 10px;
	  }
	  .light {
	  	border-radius: 3px;
	  	display: inline-block;
	    color: #fff;
	    font-size: 0.8rem;
	    padding: 3px 10px;
	  	&.red {
	  		background: red;
	  		&.active {
	  			background: red;
	  		}
	  	}
	  	&.orange {
	  		background: orange;
	  		&.active {
	  			background: orange;
	  		}
	  	}
	  	&.green {
	  		background: #03d203;
	  		&.active {
	  			background: #03d203;
	  		}
	  	}
	  }
	}
  .modal {
    .modal-card {
      .modal-card-head {
        z-index: 999 !important;
      }
      .modal-card-foot {
        z-index: 999 !important;
      }
    }
  }
  table {
    td {
      &.is-updated {
        color: green;
        font-weight: bold;
      }
    }
  }
  .bold {
    font-weight: bold;
  }
  .is-trial {
    color: #fff;
    background: orange;
    padding: 5px 8px;
    margin-top: 6px;
    display: inline-block;
    margin-right: 6px;
    font-size: 0.9rem;
    border-radius: 3px;
  }
  .not-trial {
    color: #fff;
    background: red;
    padding: 8px;
    margin-top: 6px;
  }
  .is-past {
    color: #fff;
    background: limegreen;
    padding: 5px 8px;
    margin-top: 6px;
    display: inline-block;
    margin-right: 6px;
    font-size: 0.9rem;
    border-radius: 3px;
  }
  .not-past {
    color: #fff;
    background: steelblue;
    padding: 8px;
    margin-top: 6px;
  }
  .is-expired {
    color: #fff;
    background: red;
    padding: 5px 8px;
    margin-top: 6px;
    display: inline-block;
    margin-right: 6px;
    font-size: 0.9rem;
    border-radius: 3px;
  }
  // .not-expired {
  //   color: #fff;
  //   background: steelblue;
  //   padding: 8px;
  //   margin-top: 6px;
  // }
  .vessel-name {
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      color: steelblue;
    }
  }
  .is-company {
    font-weight: bold;
    color: green;
  }
  .not-company {
    color: orange;
  }
  .red {
    color: red;
  }

  .form-group-reg {
    .vdatetime {
      input[type="text"] {
        font-size: 1rem !important;
      }
    }
  }

</style> 





