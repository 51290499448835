<template>
    <div class="site-wrapper no-header">
        <div class="container">
            <h4>Custom Induction Exports ({{ allUserIDs.length }} Crew)</h4>
            <hr />
            <div class="buttons">
                <button class="button is-primary is-medium" @click="exportToCsv()">Export to CSV</button>
            </div>
            <div class="table-wrapper">
                <table class="table clean">
                    <tr v-for="row in tableData">
                        <td :class="[col > 0 ? 'valid' : '']" v-for="col in row">
                            {{ col }}
                        </td>
                    </tr>
                </table>
            </div>
            <!-- {{ tableData }} -->
        </div>
    </div>
</template>

<script>
import { db } from '@/main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/firestore';
// import 'firebase/compat/functions';
import NProgress from 'nprogress';
import csvDownload from 'json-to-csv-export';

export default {
    name: 'CustomExportsInductions',
    props: {},
    mounted() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },
    computed: {},
    conponents: {
        csvDownload
    },
    data() {
        return {
            fleetIDs: [
                '5gVTBE76osre3EjyomzI',
                '4jRxttP0lufRb5K9Dyb9',
                'TaitISYJshLaDjBX4Nqu',
                'XkeHscZartWbfIqYJT6D',
                '43SRCAPEMXM4YfyjiD1e',
                'DdM5kJnKkaIo1hzo3IA7',
                'yCFDxLYWzG9K2XnPXxl0'
            ],
            allVessels: [
                { vesselID: 'IxvUqmjoIR7nyi1Zmf7h', vesselName: 'Portland - CG17' },
                { vesselID: 'YCPVi20DBVb7WN2pWhmp', vesselName: 'Port Welshpool - CG20' },
                { vesselID: 'nhBmu3immGGfpvUa6gDu', vesselName: 'Port Fairy - Rescue 202' },
                { vesselID: 'p4O92jvKJCef4AuwHGvC', vesselName: 'MSAR Rescue 1' },
                { vesselID: 'pIgbIKhhudUUYCtzoYgg', vesselName: 'APOLLO BAY - RESCUE 201' },
                { vesselID: 'zsTqVgTkOEZaE03srpSL', vesselName: 'Warrnambool - CG16' },
                { vesselID: '0fPFANgjpkBc1IwN9H03', vesselName: 'St Kilda - Rescue 302' },
                { vesselID: '3mqLwAZPH2QmiixjhVAl', vesselName: 'Queenscliff - CG09' },
                { vesselID: 'DJimHYhgOHGcx8djzyf7', vesselName: 'Southern Peninsula - SP01' },
                { vesselID: 'NmYWc6VbKOmYuUgF0ZYu', vesselName: 'Queenscliff Rescue 303' },
                { vesselID: 'aNUt6HLLcrTg64QmkqjO', vesselName: 'Lakes Entrance - CG18' },
                { vesselID: 'anBD9Ug8XRlND23rsyWq', vesselName: 'Mornington - AK2' },
                { vesselID: 'gSs6Zgybcwdiwje9BYUv', vesselName: 'Hastings - Rescue 301' },
                { vesselID: 'v8FVZAKrbMcyVrx1ZlB8', vesselName: 'Hastings - TX01' },
                { vesselID: 'yNnEF6y0XVQfV2UYCLg5', vesselName: 'Paynesville - CG22' },
                { vesselID: '1MZfFb0MQ4qeiptt6btw', vesselName: 'Port Albert -CG19' },
                { vesselID: '4mA3d03crlVrcPvNwNFh', vesselName: 'Southern Peninsula - SP02' },
                { vesselID: 'KrsyvTXhsCuxzJZMwk7y', vesselName: 'Safety Beach - CG06' },
                { vesselID: 'PI3SzriUZRHToowWmQKE', vesselName: 'Mallacoota - Rescue 306' },
                { vesselID: 'RK7dHjsLZMlNdnB8oosS', vesselName: 'Mornington - Rescue 309' },
                { vesselID: 'Re4RQ8WFgb7O5sVRM3hO', vesselName: 'Torquay - Betty II' },
                { vesselID: 'eaur4YDqeb5mDDCBkICP', vesselName: 'Werribee - Rescue 305' },
                { vesselID: 'ipCjSswa2XUaXqgSsPyu', vesselName: 'Geelong - Rescue 307' },
                { vesselID: 'j5FwrZ0eJ69GKmlTnW7p', vesselName: 'Carrum - CG07' },
                { vesselID: 'q8Cla66O0jEeovUAYooL', vesselName: 'Safety Beach - Rescue 308' },
                { vesselID: 'qRHKxnd1xGRHUFn084su', vesselName: 'Geelong - CG08' },
                { vesselID: 'xwfYcg2giydf0ICzxgzp', vesselName: 'Torquay Rescue 304' },
                { vesselID: '6A8fNQ41mkiVrSMtdZj4', vesselName: 'Lake Eppalock - CG05' },
                { vesselID: '9a2B54xEFsel2WILapH1', vesselName: 'Paynesville - CG222' },
                { vesselID: 'AeYml8cygE3zlPqODHnA', vesselName: 'Waratah - Rescue 402' },
                { vesselID: 'DRD36Wgoc4miEeszcAdB', vesselName: 'Carrum - CG207' },
                { vesselID: 'ReI022nzJAStWasKu3CG', vesselName: 'Marlo - CG221' },
                { vesselID: 'VC5Vrat8NGGvIyZ9RTyu', vesselName: 'Port Welshpool - Rescue 401' },
                { vesselID: 'XixAle2VbxcGhZVX7Uh1', vesselName: 'Port Albert - CG219' },
                { vesselID: 'ZgbxlRXei5OhipY3s9z0', vesselName: 'Westernport - CG204' },
                { vesselID: 'gijXdA54JrHISafARpLz', vesselName: 'Lake Hume - CG11' },
                { vesselID: 's5KE86FKupgyQ5LYYm2y', vesselName: 'Lakes Entrance - CG218' },
                { vesselID: 'aYO8GukifiYzkGA43nTT', vesselName: 'Inverloch - Rescue 531' },
                { vesselID: 'cVxr3ZHcdRNWP0uVh7xx', vesselName: 'Marlo - CG21' },
                { vesselID: 'uhN2rSBpD1qtmvks54Af', vesselName: 'Ocean Grove - CW1' },
                { vesselID: 'VvAh41JrE3AOgGfvPiVK', vesselName: 'Ocean Grove CW02' },
                { vesselID: 'mFSmVlZMTUhe4JxT0c7M', vesselName: 'Lake Eppalock - CG205' },
                { vesselID: 'EWt1kshKHvkRfI8xAuIK', vesselName: 'Torquay Peter' },
                { vesselID: 'LFtn4DEUjbLFNIFMsYEH', vesselName: 'Marlo - PWC2' },
                { vesselID: 'U71bt1TPCJe9IL03JReB', vesselName: 'Marlo - PWC1' },
                { vesselID: 'aFCvbvb53QanwFTAh7s1', vesselName: 'Lakes Entrance - CG418' },
                { vesselID: 'l5yphLZSNUhdhcKfaeQk', vesselName: 'Lakes Entrance - CG318' },
                { vesselID: 'leiZG4Mob3t66Vcxl9pq', vesselName: 'Torquay Patti' }
            ],
            allUsers: [],
            allUserIDs: [],
            tableData: []
        };
    },
    methods: {
        onUserLogin(user) {
            this.loadAllFleetCrews();
            // this.loadAllSelfInductions();
        },
        async loadAllFleetCrews() {
            let self = this;
            NProgress.start();
            await Promise.all(
                this.fleetIDs.map(async fleetID => {
                    await db
                        .collection('companies')
                        .doc(fleetID)
                        .collection('teamMembers')
                        .get()
                        .then(querySnapshot => {
                            querySnapshot.forEach(doc => {
                                console.log(doc.data().crewName);
                                let crewID = doc.data().crewID;
                                if (!self.allUserIDs.includes(crewID)) {
                                    self.allUserIDs.push(crewID);
                                    self.allUsers.push({
                                        userID: crewID,
                                        userName: doc.data().crewName
                                    });
                                }
                            });
                        });
                })
            );
            this.buildTable();
        },
        async buildTable() {
            console.log('build table');
            let self = this;
            let now = new Date();
            let cutoff = new Date(now.setDate(now.getDate() - 365));

            let rowA = ['Crew Name'];
            await Promise.all(
                this.allVessels.map(v => {
                    rowA.push(v.vesselName);
                })
            );
            this.tableData.push(rowA);

            await Promise.all(
                this.allUsers.map(async user => {
                    let row = [user.userName];
                    await db
                        .collection('userdata')
                        .doc(user.userID)
                        .collection('myVesselSelfInductions')
                        .where('timestamp', '>=', cutoff)
                        .get()
                        .then(async snap => {
                            let docData = snap.docs.map(d => d.data());
                            await Promise.all(
                                self.allVessels.map(async vessel => {
                                    let tally = docData.filter(n => n.vesselID == vessel.vesselID).length;
                                    row.push(tally);
                                })
                            );
                        });
                    self.tableData.push(row);
                })
            );
            NProgress.done();
        },
        async exportToCsv() {
            NProgress.start();
            let self = this;
            // let exportData = [];
            // await Promise.all(
            //     this.filteredReportData.map(async itemRow => {
            //         let row = {};
            //         await Promise.all(
            //             self.visibleColumns.map(async field => {
            //                 row[field.slug] = itemRow[field.slug]
            //                     ? self.hideChecklists && field.canBeLong
            //                         ? itemRow[field.slug].split('\r\n').length + ' items'
            //                         : itemRow[field.slug]
            //                     : '';
            //             })
            //         );
            //         exportData.push(row);
            //     })
            // );

            let temp = [...this.tableData];
            let exportData = temp.splice(1);

            const dataToConvert = {
                data: exportData,
                filename: 'MSAR-All-Crew-Self-Induction-Report',
                delimiter: ',',
                headers: self.tableData[0]
            };

            csvDownload(dataToConvert);

            setTimeout(() => {
                NProgress.done();
            }, 1000);
        }
    }
};
</script>

<style lang="scss" scoped>
.table {
    &.clean {
        tr {
            td {
                width: 100px;
                &.valid {
                    background-color: mediumseagreen;
                    color: white;
                }
            }
        }
    }
}
</style>
