<template>
  
    <div class="site-wrapper no-header">

        <div class="container">

            <div class="tabs is-centered is-boxed">
                <ul>

                    <li class="is-active">
                        <a><font-awesome-icon icon="star" /><span>Document Fields to Documents</span></a>
                    </li>

                </ul>
            </div>

        </div>

        
        
        <div class="container tab-content" v-if="tab == 'one'">
  
                <h3 class="is-size-5">Copy one/more document fields to a list of documents</h3><br>
    
                <div class="input-wrapper">      
                    <label>Source Document Path (NO leading or trailing slash)</label>
                    <input class="input tight" type="text" placeholder="This is the document to be copied.." v-model="sourcePath">
                </div>

                <div class="clear"></div>

                <div class="spacer s20"></div>
                
    
                <div class="clear"></div>

                <div class="input-wrapper centered">    
                    <label :class="{'red': setDocID == ''}">Document ID</label>
                    <input class="input short" type="text" placeholder="Common doc ID to use.." v-model="setDocID">
                </div>
    
                <div class="clear"></div>

                <!-- <div class="input-wrapper centered">    
                    <label :class="{'red': fieldName == ''}">Field to Copy</label>
                    <input class="input short" type="text" placeholder="Name of field to copy.." v-model="fieldName">
                </div> -->

                <div class="clear"></div>

                <div class="form-group-reg">
                    <div class="checkbox-wrapper-new">
                    <label class="label">All Fields</label> 
                    <div class="field" v-for="field in fields" :key="field">
                        <input class="is-checkradio" type="checkbox" :id="field" :value="field" v-model="selectedFields">
                        <label :for="field">{{field}}</label>
                    </div>
                    </div>
                </div> 

                {{saveObject}}

                <div class="buttons">
                    <span :disabled="sourcePath == ''" class="button is-warning" @click="displayFields()">Show Document Fields</span>
                </div>


    
                <div class="clear"></div>

                <div class="spacer s20"></div>
    
                <div class="input-wrapper centered">    
                    <label>Parent Collection Path to get list of doc IDs (leading slash only)</label>
                    <input class="input" type="text" placeholder="Get docIDs from this collection.." v-model="targetPath">
                </div>
    
                <div class="clear"></div>
    
                <div class="input-wrapper centered">    
                    <label>Document key field (ie title)</label>
                    <input class="input short" type="text" placeholder="Name of key field.." v-model="keyField">
                </div>

                <div class="clear"></div>

                <div class="input-wrapper centered">    
                    <label>Target path prefix (no leading slash)</label>
                    <input class="input" type="text" placeholder="Type path prefix.." v-model="prefix">
                </div>

                <div class="clear"></div>

                <div class="input-wrapper centered">    
                    <label>Target path suffix</label>
                    <input class="input" type="text" placeholder="Type type path suffix.." v-model="suffix">
                </div>


                <div class="clear"></div>


  
                <div class="buttons">
                    <span :disabled="pathsAreValid" class="button is-warning" @click="displayDocs()">Show Documents</span>
                    <span class="button is-light" @click="resetFields()">Reset Fields</span>
                </div>
    
    
                <hr>
    
                <table v-if="poolOfDocs.length" class="table is-bordered is-fullwidth">
                    <tr>
                    <th>Get Doc IDs from these Documents</th>
                    <!-- <th>Details</th> -->
                    <th v-if="isSteve">Select?</th>
                    </tr>
                    <tr v-for="(value, docID, index) in poolMap" :key="index">                
                    <td>
                        {{value[keyField]}}
                    </td>
                    <td>            
                        <input class="is-checkradio" type="checkbox" :id="index" :value="docID" v-model="selectedItems">
                    </td>
                    </tr>
                    <tr>
                    <td>Select All?</td>
                    <td>
                        <div class="form-group-reg">
                        <input class="is-checkradio" type="checkbox" id="copyAllDocs" @change="updateCopyAll()" v-model="copyAllDocs">
                        </div>
                    </td>
                    </tr>
                </table>

                <div class="spacer s20"></div>


                <table class="table is-bordered is-fullwidth" v-if="selectedItems.length > 0">
                    <tr>
                    <th>List of documents to update field/s</th>
                    </tr>
                    <tr v-for="path in selectedItems" :key="path">
                    <td>{{prefix}}{{path}}{{suffix}}</td>
                    </tr>
                </table>
    
    
                <div v-if="selectedItems.length > 0" class="buttons">
                    <span :disabled="pathsAreValid" class="button is-danger" @click="updateDocsWithSourceField()">Copy Source Field to the Documents</span>
                </div>
  
  
        </div>
  
    </div>
  
  
  
  
  
  </template>
  
  
  <script> 
  
    import firebase from 'firebase/compat/app';
    import { db } from '../main.js';
    import 'firebase/compat/auth'; 
    import 'firebase/firestore';
    import 'firebase/compat/functions';
    import NProgress from 'nprogress';  
    import * as moment from 'moment';
  
  
  
    export default {
  
      name: 'CopyMultipleDocuments',
      components: {
  
      },
      mounted: function() {
        firebase.auth().onAuthStateChanged( this.onUserLogin ); 
      },
  
      data: function() {
        return {
          poolOfDocs: [],
          pooledIDs: [],
          sourcePath: 'vessels/p4O92jvKJCef4AuwHGvC',
          targetPath: '/companies/5gVTBE76osre3EjyomzI/companyVessels',
          selectedItems: [],
          keyField: 'vesselName',
          poolMap: {},
          retainDocIDs: true,
          copyAllDocs: false,
          prefix: 'vessels/',
          suffix: '',
          setDocID: 'p4O92jvKJCef4AuwHGvC',
          fieldName: '',

          tab: 'one',
          fields: [],
          selectedFields: ['accessMatrix'],
          docData: {},

  
        }
      },
  
      computed: {
        user() {
          return this.$store.getters.getUser;
        },
        isSteve() {
          return this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false;
        },
        pathsAreValid() {
          return this.sourcePath == '' || this.targetPath == '';
        },
        listOfDocuments() {
            return this.selectedItems.map((n) => this.prefix + n + this.suffix);
        },
        saveObject() {
            // let self = this;
            let temp = {};
            // return this.selectedFields.map((n) => temp[n] = this.docData[n]);
            this.selectedFields.forEach((n) => {
                temp[n] = this.docData[n]
            });
            return temp;
        }


      },
  
      methods: {
  
        onUserLogin: function( user ) {
          // if (user) {         
  
          // }
        },
  
        displayDocs() {
          let self = this;
          if (this.targetPath != '') {
            NProgress.start();
            console.log(self.targetPath);
            let newTargetPath = self.targetPath.length && self.targetPath[0] === '/' ? self.targetPath.substring(1) : self.targetPath;
            console.log(newTargetPath);
  
            db.collection(newTargetPath).get().then((querySnapshot) => {
              self.poolOfDocs = [];
              self.pooledIDs = [];
              querySnapshot.forEach((doc) => {
                // self.poolOfDocs.push({
                //   id: doc.id,
                //   title: doc.data().title ? doc.data().title : 'no title',
                //   details: doc.data().details ? doc.data().details : 'no details',
                // });
                self.pooledIDs.push(doc.id);
                self.poolOfDocs.push(doc.data());
                self.poolMap[doc.id] = doc.data();
              });
            }).then(() => {
              NProgress.done();
            });
          }
        },

        displayFields() {
            let self = this;
            if (this.sourcePath != '') {
                NProgress.start();
                console.log(self.sourcePath);
                let newSourcePath = self.sourcePath.length && self.sourcePath[0] === '/' ? self.sourcePath.substring(1) : self.sourcePath;
                console.log(newSourcePath);
    
                db.doc(newSourcePath).get().then((doc) => {
                    self.fields = Object.keys(doc.data());
                    self.docData = doc.data();
                }).then(() => {
                    NProgress.done();
                }).catch((err) => {
                    console.log('Error',err);
                    NProgress.done();
                });
            }
        },
  
        updateCopyAll() {
          console.log(this.copyAllDocs);
          this.selectedItems = this.copyAllDocs ? this.pooledIDs : [];
        },
  
        resetFields() { 
          this.poolOfDocs = [];
          this.sourcePath = '';
          this.targetPath = '';
          this.selectedItems = [];
          this.pooledIDs = [];
          this.keyField = '';
          this.poolMap = {};
          this.prefix = '';
          this.suffix = '';
          this.retainDocIDs = true;
          this.setDocID = '';
          this.fields = [];
          this.fieldName = '';
        },

        updateDocsWithSourceField() {
            let self = this;
            console.log(this.saveObject);
            console.log(this.listOfDocuments);

            let message;
            message = {
                title: 'Confirm Copy Document Fields?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel',
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    
                    NProgress.start();
                    var adminUpdateDocsWithSourceField = firebase.functions().httpsCallable('adminUpdateDocsWithSourceField');
                    adminUpdateDocsWithSourceField({
                        saveObject: self.saveObject,
                        listOfDocuments: self.listOfDocuments,
                    })
                    .then(function(result) {
                        // var sanitizedMessage = result.data.text;
                        // console.log('Result',sanitizedMessage);
                        // console.log('Message',result.data.message);
                        self.resetFields();
                        NProgress.done();
        
                    })
                    .catch(function(error) {
                        console.log('Error',error.text);
                        NProgress.done();
                    }); 
    
    
                })
                .catch(function(error) {
                    console.log(error); 
                    NProgress.done();
                });     

        },
  
        copyDocs() {
  
          let self = this;
          self.copyAllDocs = false;
  
          let newSourcePath = self.sourcePath.length && self.sourcePath[0] === '/' ? self.sourcePath.substring(1) : self.sourcePath;
          let newTargetPath = self.targetPath.length && self.targetPath[0] === '/' ? self.targetPath.substring(1) : self.targetPath;
  
          console.log(newSourcePath);
          console.log(newTargetPath);
  
          let message;
          message = {
            title: 'Confirm Copy Documents?',
            body: 'Click YES below to proceed.'
          };
          let options = {
            okText: 'YES',
            backdropClose: true,
            cancelText: 'Cancel',
          };
          this.$dialog
            .confirm(message, options)
            .then(function(dialog) {
                
                NProgress.start();
                var adminCopyAnyDocuments = firebase.functions().httpsCallable('adminCopyAnyDocuments');
                adminCopyAnyDocuments({
                  sourceCollection: newSourcePath,
                  targetCollection: newTargetPath,
                  selectedItems: self.selectedItems,
                  retainDocIDs: self.retainDocIDs
                })
                .then(function(result) {
                  // var sanitizedMessage = result.data.text;
                  // console.log('Result',sanitizedMessage);
                  // console.log('Message',result.data.message);
                  self.resetFields();
                  NProgress.done();
  
                })
                .catch(function(error) {
                  console.log('Error',error.text);
                  NProgress.done();
                }); 
  
  
            })
            .catch(function(error) {
              console.log(error); 
              NProgress.done();
            });         
  
  
  
  
  
  
        },
  
        formatDate : function (sentDate,format) {
            let fixDate = (sentDate ? sentDate.toDate() : new Date());
            //console.log("Fix date:",fixDate);
            return moment(fixDate).format(format);
        },
  
        
  
  
      },
  
    }
  
  
  
  </script> 
  
  
  
  
  <style lang="scss" scoped>
    
    .site-wrapper .container {
      max-width: 850px;
    }
    .form-group {
      label {
        top: auto;
        bottom: -25px;
      }
    }
    .button {
      margin-top: 0;
      &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
      }
    }
    .traffic-light {
      position: relative;
      span {
        float: left;
        margin-right: 10px;
      }
      .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
          background: red;
          &.active {
            background: red;
          }
        }
        &.orange {
          background: orange;
          &.active {
            background: orange;
          }
        }
        &.green {
          background: #03d203;
          &.active {
            background: #03d203;
          }
        }
      }
    }
  
    input[type="text"] {
      width: 90%;
      float: left;
      display: block;
      margin-bottom: 2rem;
      height: auto;
      padding: 12px 8px;
      // &:after {
      //   content: "\f16c";
      //   font-family: "Font Awesome 5 Brands";
      //   color: red;
      // }
      // &.valid:after {
  
      // }
      &.tight {
        margin-bottom: 0;
      }
      &.short {
        max-width: 300px;
      }
    }
    .icon-wrap {
      display: block;
      float: left;
      height: 35px;
      margin-left: 10px;
      font-size: 1.5rem;
      color: red;
      &.valid {
        color: green;
      }
    }
    label {
      margin-bottom: 10px;
      display: block;
    }
    .is-checkradio[type=checkbox] {
      opacity: 1.0;
      -ms-transform: scale(2); /* IE */
      -moz-transform: scale(2); /* FF */
      -webkit-transform: scale(2); /* Safari and Chrome */
      -o-transform: scale(2); /* Opera */
      transform: scale(2);
      padding: 10px;
      margin-top: 10px;
      margin-left: 10px;
    }
  
    .checkbox-wrapper-new {
      .is-checkradio[type=checkbox] + label {
        margin-left: 0;
        position: inherit;
        display: block;
        &:before {
          display: none !important;
        }
        &:after {
          display: none;
        }
      }
    }
  
    table.table {
      td {
        padding: 1rem;
        input {
          margin: 5px;
        }
      }
    }
    .tabs {
        ul {
            li {
                span {
                    margin-left: 10px;
                }
            }
        }
    }
    .input-wrapper {
        clear: both;
    }
  
  
  </style> 