<template>

  <div>               

    <div class="container">

      <!-- {{newInvoice}} -->

      <h3>Create a new invoice for {{vesselName ? vesselName : 'a vessel'}}</h3>
      <input class="input" type="text" placeholder="Paste Vessel ID" v-model="vesselID" />

      <div v-if="vesselID != ''">

        <input class="input" type="text" placeholder="Invoice to..." v-model="newInvoice.invoiceTo" />

        <input class="input" type="text" placeholder="ABN (optional)..." v-model="newInvoice.abn" />

        <input class="input" type="text" placeholder="Description..." v-model="newInvoice.description" />

        <label for="invoiceAmount">Invoice Amount</label>
        <input class="input" type="text" placeholder="Invoice to..." v-model="newInvoice.invoiceAmount" />

        <label for="gstAmount">GST Amount</label>
        <input class="input" type="text" placeholder="Invoice to..." v-model="newInvoice.gstAmount" />

        <div class="form-group-reg">   
          <label for="invoiceDate">Invoice Date</label>
          <datetime input-id="invoiceDate" placeholder="Invoice Date" auto v-model="newInvoice.invoiceDate"></datetime>
        </div>

        <div class="form-group-reg">   
          <label for="datePaid">Date Paid</label>
          <datetime input-id="datePaid" placeholder="Date Paid" auto v-model="newInvoice.datePaid"></datetime>
        </div>

        <div class="form-group-reg">   
          <input class="is-checkradio" id="isPaid" type="checkbox" v-model="newInvoice.isPaid">
          <label for="isPaid">Invoice Paid?</label>
        </div>  

        <div class="buttons">

          <span :disabled="showButton" class="button is-primary" @click="createInvoice()">Create Invoice</span>

        </div>

        <hr>

        <p>{{errMsg}}</p><br>

      </div>

        <span class="button is-cancel" @click="clear()">Clear</span>



    </div>

  </div>

</template>



<script>

import firebase from 'firebase/compat/app';
import { db } from '../main.js';
//import 'firebase/compat/auth'; 
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';

export default {

  name: 'CreateVesselInvoice',

  data: function() {
    return {
      vesselID: '',
      vesselName: '',
      newInvoice: {
        invoiceTo: '',
        abn: '',
        datePaid: '',
        invoiceDate: '',
        description: '',
        invoiceAmount: 0,
        gstAmount: 0,
        isPaid: false,
      },
      errMsg: '',
    }
  },

  props: {
      sentVessel: Object,
  },

  mounted() {
    if (this.sentVessel) {
      this.vesselID = this.sentVessel.vesselID;
      this.vesselName = this.sentVessel.vesselName;
    }
  },

  computed: {
    showButton() {
      return false;
    }
  },

  methods: {

    clear() {
      this.errMsg = '';
      this.vesselID = '';
      this.vesselName = '';
    },

    createInvoice() {
      let self = this;
      let message;
        message = {
          title: 'Create Invoice?',
          body: 'Click YES below to proceed.'
        };
        let options = {
          okText: 'YES',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(function(dialog) {
              
              NProgress.start();
              var adminCreateVesselInvoice = firebase.functions().httpsCallable('adminCreateVesselInvoice');
              adminCreateVesselInvoice({
                  newInvoice: self.newInvoice,
                  vesselID: self.vesselID
              })
              .then(function(result) {
                var sanitizedMessage = result.data.text;
                // console.log('Result',sanitizedMessage);
                console.log('Success Message: ',result.data.message);
                NProgress.done();
                self.clear();
              })
              .catch(function(error) {
                // console.log('Error',error.text);
                console.log('Error Message: ',error.message);
                NProgress.done();
              }); 


          })
          .catch(function(error) {
            console.log(error); 
            NProgress.done();
          });         
    },


  },

 

}


</script>

<style lang="scss" scoped>

  input[type="text"] {
    padding: 30px;
    margin-bottom: 1rem;
  }
  input[type="tel"] {
    padding: 30px;
    margin-bottom: 1rem;
  }
  h3 {
    margin: 1rem 0;
  }

</style>