<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">Fix SMS Emergency Procedures</h3>
                <br />
                <!--    <div class="input-wrapper">
              <label>Source Collection Path (no trailing slashes)</label>
              <input class="input" type="text" placeholder="Paste source doc path" v-model="sourcePath">
            </div> -->

                <div v-if="!isSelected" class="notification is-primary">
                    <input
                        class="input"
                        v-model="findName"
                        id="filtername"
                        autocomplete="off"
                        type="text"
                        placeholder="Start typing vessel name..."
                    />
                </div>

                <div class="buttons">
                    <span v-if="findName != ''" class="button is-warning" @click="clearVessel()">Clear</span>
                </div>

                <hr />

                <div v-if="selectedVessel != ''" class="highlight">{{ selectedVessel.vesselName }} ({{ selectedVessel.vesselOwnerName }})</div>

                <div v-if="findName != '' && !isSelected">
                    <div v-for="(vessel, index) in filteredNames" :key="index">
                        <div class="buttons">
                            <span class="button is-light" @click="selectVessel(vessel)">{{ vessel.vesselName }} ({{ vessel.vesselOwnerName }})</span>
                        </div>
                    </div>
                </div>

                <div class="clear"></div>

                <div v-if="findName != '' && isSelected">
                    <h4 class="icon">Modify procedures in this SMS</h4>
                    <div class="clear"></div>

                    <table v-if="smsDocs.length" class="table is-bordered is-fullwidth">
                        <tr>
                            <th>Title</th>
                            <th>Edit</th>
                        </tr>
                        <tr v-for="(item, index) in smsDocs" :key="index">
                            <td>
                                <span>{{ item.title }}</span>
                            </td>
                            <td>
                                <div class="buttons">
                                    <span class="button is-primary" @click="editItem(item)">Update</span>
                                    <span class="button is-danger" @click="deleteItem(item)">Delete</span>
                                </div>
                            </td>
                        </tr>
                    </table>

                    <div class="spacer s30"></div>

                    <h4 class="icon">Add new procedures from Pooled Emergency Procedures</h4>
                    <div class="clear"></div>

                    <div class="clear"></div>

                    <table v-if="poolOfDocs.length" class="table is-bordered is-fullwidth">
                        <tr>
                            <th>Title</th>
                            <!--  <th>Details</th> -->
                            <th v-if="isSteve">Copy?</th>
                        </tr>
                        <tr v-for="(item, index) in poolOfDocs" :key="index">
                            <td>
                                <span class="show-details" @click="showDetails(item)">{{ item.title }}</span>
                            </td>
                            <td>
                                <input class="is-checkradio" type="checkbox" :id="index" :value="item" v-model="selectedItems" />
                            </td>
                        </tr>
                    </table>

                    <div v-if="selectedItems.length > 0" class="buttons">
                        <button :disabled="selectedVessel == ''" class="button is-danger" @click="copyDocs()">Copy Documents</button>
                    </div>

                    <div class="clear"></div>
                </div>

                <div class="clear"></div>
                <div class="spacer s30"></div>

                <div class="buttons">
                    <span v-if="findName != '' && isSelected" class="button is-primary is-medium" @click="addItem()">Add a Emergency Procedure</span>
                    <span class="button is-info is-medium" @click="$router.go(-1)"><font-awesome-icon icon="chevron-left" /> &nbsp;Back</span>
                </div>
            </div>
        </div>

        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': showEditItemModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">Edit Selected Item</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <input class="input" type="text" placeholder="Type title..." v-model="selectedEditItem.title" />
                    <div class="spacer s10"></div>
                    <textarea class="input textarea" placeholder="Type details..." v-model="selectedEditItem.details"></textarea>

                    <div class="spacer s30"></div>
                    <label class="label">Place Index</label>
                    <input
                        class="input"
                        type="tel"
                        placeholder="999"
                        maxlength="4"
                        v-on:keypress="isNumber($event)"
                        v-model="selectedEditItem.index"
                    />
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click.prevent="saveEditItem(selectedEditItem)">Save</button>
                    <button class="button is-danger" @click.prevent="close">Cancel</button>
                </footer>
            </div>
        </div>

        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': addItemModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">Add an Item</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <input class="input" type="text" placeholder="Type title..." v-model="newItem.title" />

                    <div class="spacer s10"></div>
                    <textarea class="input textarea" placeholder="Type details..." v-model="newItem.details"></textarea>

                    <div class="spacer s30"></div>
                    <label class="label">Place Index</label>
                    <input class="input" type="tel" placeholder="999" maxlength="4" v-on:keypress="isNumber($event)" v-model="newItem.index" />

                    <div class="spacer s30"></div>
                </section>
                <footer class="modal-card-foot">
                    <button :disabled="newItem.title == ''" class="button is-primary" @click.prevent="saveNewItem()">Save New Item</button>
                    <button class="button is-danger" @click.prevent="close">Cancel</button>
                </footer>
            </div>
        </div>

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showDetailsModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-info">
                    <p class="modal-card-title">
                        {{ selectedItem.title }}
                    </p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <div v-if="selectedItem != ''">
                        <div v-for="line in selectedItem.details.split('\n')" :key="line">{{ line }}<br /></div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-info" @click.prevent="close">Done</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {
    name: 'FixSmsEops',
    components: {},
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            poolOfDocs: [],
            sourcePath: '',
            targetPath: '',
            selectedItems: [],

            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            allVessels: [],
            isSelected: false,
            findName: '',
            hideExpired: true,
            selectedVessel: '',
            selectedItem: '',
            showDetailsModal: false,

            smsDocs: [],
            selectedEditItem: { title: '', details: '', index: 0 },
            showEditItemModal: false,
            addItemModal: false,
            newItem: {
                title: '',
                details: '',
                index: 999
            }
        };
    },

    computed: {
        userData() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.userData
                ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2'
                    ? true
                    : false
                : false;
        },
        pathsAreValid() {
            return this.sourcePath == '' || this.targetPath == '';
        },
        filteredVessels() {
            return this.hideExpired ? this.allVessels.filter(vessel => !vessel.isExpired).map(vess => vess) : this.allVessels;
        },
        filteredNames() {
            let filter = new RegExp(this.findName, 'i');
            return this.filteredVessels.filter(user => user.vesselName.match(filter));
        },
        selectedItemIDs() {
            return this.selectedItems.map(item => item.id);
        }
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                // console.log(user);
                this.loadAllVessels();
                this.displayDocs();
            }
        },

        close: function() {
            this.showDetailsModal = false;
            this.selectedEditItem = { title: '', details: '', index: 0 };
            this.showEditItemModal = false;
            this.addItemModal = false;
            this.newItem = {
                title: '',
                details: '',
                index: 999
            };
        },

        editItem(item) {
            console.log(item);
            this.selectedEditItem = item;
            this.showEditItemModal = true;
        },

        saveEditItem(itemToSave) {
            let self = this;
            console.log(itemToSave);
            NProgress.start();
            var adminSaveClientSmsItem = firebase.functions().httpsCallable('adminSaveClientSmsItem');
            adminSaveClientSmsItem({
                collection: 'smsEOPS',
                vesselID: self.selectedVessel.vesselID,
                docID: itemToSave.id,
                title: itemToSave.title,
                details: itemToSave.details,
                index: itemToSave.index
            })
                .then(function(result) {
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        },

        deleteItem(item) {
            console.log(item);
            let self = this;
            let message;
            message = {
                title: 'Confirm Delete?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES, DELETE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    var adminDeleteClientSmsItem = firebase.functions().httpsCallable('adminDeleteClientSmsItem');
                    adminDeleteClientSmsItem({
                        vesselID: self.selectedVessel.vesselID,
                        docID: item.id,
                        deleteCollection: 'smsEOPS'
                    })
                        .then(function(result) {
                            NProgress.done();
                        })
                        .catch(function(error) {
                            console.log('Error', error.text);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        addItem() {
            this.addItemModal = true;
        },

        isNumber: function($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        saveNewItem() {
            console.log(this.newItem);
            let self = this;
            NProgress.start();
            var adminSaveNewClientSmsItem = firebase.functions().httpsCallable('adminSaveNewClientSmsItem');
            adminSaveNewClientSmsItem({
                collection: 'smsEOPS',
                vesselID: self.selectedVessel.vesselID,
                title: self.newItem.title,
                details: self.newItem.details,
                index: parseInt(self.newItem.index)
            })
                .then(function(result) {
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        },

        displaySmsDocs() {
            console.log(this.selectedVessel.vesselID);
            let self = this;
            db.collection('vessels')
                .doc(this.selectedVessel.vesselID)
                .collection('smsEOPS')
                .onSnapshot(querySnapshot => {
                    self.smsDocs = [];
                    querySnapshot.forEach(doc => {
                        self.smsDocs.push({
                            id: doc.id,
                            title: doc.data().title ? doc.data().title : 'no title',
                            details: doc.data().details ? doc.data().details : 'no details',
                            index: doc.data().index ? doc.data().index : 999
                        });
                    });
                });
        },

        displayDocs() {
            let self = this;
            NProgress.start();
            db.collection('pooledEOPS')
                .get()
                .then(querySnapshot => {
                    self.poolOfDocs = [];
                    querySnapshot.forEach(doc => {
                        self.poolOfDocs.push({
                            id: doc.id,
                            title: doc.data().title ? doc.data().title : 'no title',
                            details: doc.data().details ? doc.data().details : 'no details'
                        });
                    });
                })
                .then(() => {
                    NProgress.done();
                });
        },

        clearDocs() {
            this.selectedItems = [];
        },

        clearVessel: function() {
            this.findName = '';
            this.isSelected = false;
            this.selectedVessel = '';
        },

        selectVessel(vessel) {
            console.log(vessel);
            this.selectedVessel = vessel;
            this.isSelected = true;
            this.displaySmsDocs();
        },

        showDetails(item) {
            this.selectedItem = item;
            this.showDetailsModal = true;
        },

        copyDocs() {
            let self = this;

            console.log(this.selectedVessel.vesselID);
            console.log(this.selectedItemIDs);

            let message;
            message = {
                title: 'Confirm Copy Documents?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    var adminFixSmsItems = firebase.functions().httpsCallable('adminFixSmsItems');
                    adminFixSmsItems({
                        vesselID: self.selectedVessel.vesselID,
                        selectedItemIDs: self.selectedItemIDs,
                        sourceCollection: 'pooledEOPS',
                        targetCollection: 'smsEOPS'
                    })
                        .then(function(result) {
                            self.clearDocs();
                            // self.clearVessel();
                            NProgress.done();
                        })
                        .catch(function(error) {
                            console.log('Error', error.text);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        loadAllVessels: function() {
            let self = this;
            db.collection('vessels')
                .orderBy('vesselOwnerName', 'asc')
                .onSnapshot(function(querySnapshot) {
                    self.allVessels = [];
                    querySnapshot.forEach(doc => {
                        self.allVessels.push({
                            vesselID: doc.id,
                            vesselName: doc.data().vesselName,
                            vesselOwnerName: doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?',
                            vesselOwnerID: doc.data().vesselOwnerID
                        });
                    });
                });
        },

        formatDate: function(sentDate, format) {
            let fixDate = sentDate ? sentDate.toDate() : new Date();
            //console.log("Fix date:",fixDate);
            return moment(fixDate).format(format);
        }
    }
};
</script>

<style lang="scss" scoped>
.site-wrapper .container {
    max-width: 850px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
.traffic-light {
    position: relative;
    span {
        float: left;
        margin-right: 10px;
    }
    .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
            background: red;
            &.active {
                background: red;
            }
        }
        &.orange {
            background: orange;
            &.active {
                background: orange;
            }
        }
        &.green {
            background: #03d203;
            &.active {
                background: #03d203;
            }
        }
    }
}

// input[type="text"] {
//   width: 90%;
//   float: left;
//   display: block;
//   margin-bottom: 2rem;
//   // &:after {
//   //   content: "\f16c";
//   //   font-family: "Font Awesome 5 Brands";
//   //   color: red;
//   // }
//   // &.valid:after {

//   // }
// }
.icon-wrap {
    display: block;
    float: left;
    height: 35px;
    margin-left: 10px;
    font-size: 1.5rem;
    color: red;
    &.valid {
        color: green;
    }
}
label {
    margin-bottom: 10px;
    display: block;
}
.is-checkradio[type='checkbox'] {
    opacity: 1;
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
    padding: 10px;
    margin-top: 10px;
    margin-left: 10px;
}

.site-wrapper .container {
    max-width: 1050px;
}
.buttons {
    margin-bottom: 5px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
input[type='text'] {
    padding: 2rem 1rem;
}
.form-group {
    label {
        top: auto;
        bottom: -25px;
    }
}
.traffic-light {
    position: relative;
    span {
        float: left;
        margin-right: 10px;
    }
    .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
            background: red;
            &.active {
                background: red;
            }
        }
        &.orange {
            background: orange;
            &.active {
                background: orange;
            }
        }
        &.green {
            background: #03d203;
            &.active {
                background: #03d203;
            }
        }
    }
}
.highlight {
    background: dodgerblue;
    padding: 15px;
    color: #fff;
}
.modal {
    .modal-card {
        .modal-card-head {
            z-index: 999 !important;
        }
        .modal-card-foot {
            z-index: 999 !important;
        }
    }
}
input[type='text'],
input[type='tel'] {
    padding: 2rem 1rem;
}

textarea {
    min-height: 120px;
    height: 400px;
    margin-top: 10px;
    padding: 1.5rem 1rem;
}
table {
    td {
        padding-bottom: 21px;
        &.is-updated {
            color: green;
            font-weight: bold;
        }
        .small {
            font-size: 0.8rem;
            margin-top: 5px;
            text-align: center;
        }
    }
}
.show-details {
    cursor: pointer;
    &:hover {
        color: dodgerblue;
    }
}
</style>
