<template>
  <section class="section">
    <h1 class="title has-text-centered">Home (404)</h1>
    <article class="notification is-primary">
      <p>Oops. Something went wrong.</p>
    </article>
  </section>
</template>

<script>


	export default {
		

	}


</script>

<style lang="scss">

.counter-wrap {
	margin-bottom: 2rem;
}


</style>
