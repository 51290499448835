<template>
    <div class="site-wrapper no-header">
        <div class="container">
            <div class="notification is-info"><h3>Move team members between fleets</h3></div>

            <!--  find the source fleet ============================================  -->
            <div v-if="!sourceSelected" class="notification is-primary">
                <input class="input" v-model="findSource" id="filtername" autocomplete="off" type="text" placeholder="Source fleet name..." />
            </div>

            <div v-if="sourceSelected" class="highlight">{{ sourceFleet.companyName }} ({{ sourceFleet.companyID }})</div>

            <div v-if="findSource.length > 2 && !sourceSelected">
                <div v-for="(fleet, index) in filteredSource" :key="index">
                    <div class="buttons results">
                        <span class="button is-light" @click="selectSource(fleet)">{{ fleet.companyName }} ({{ fleet.primaryUserName }})</span>
                    </div>
                </div>
            </div>

            <!--  find the source vessel ============================================  -->
            <div v-if="sourceSelected">
                <!--  find the target fleet ============================================  -->
                <div v-if="!targetSelected" class="notification is-primary">
                    <input class="input" v-model="findTarget" id="filtername" autocomplete="off" type="text" placeholder="Target fleet name..." />
                </div>

                <div v-if="targetSelected" class="highlight">{{ targetFleet.companyName }} ({{ targetFleet.companyID }})</div>

                <div v-if="findTarget.length > 2 && !targetSelected">
                    <div v-for="(fleet, index) in filteredTarget" :key="index">
                        <div class="buttons results">
                            <span class="button is-light" @click="selectTarget(fleet)">{{ fleet.companyName }} ({{ fleet.primaryUserName }})</span>
                        </div>
                    </div>
                </div>

                <!-- <div v-if="!vesselSelected" class="notification is-primary">
                    <input
                        class="input"
                        v-model="findTarget"
                        id="filtername"
                        autocomplete="off"
                        type="text"
                        placeholder="Start typing second fleet name..."
                    />
                </div>

                <div v-if="vesselSelected" class="highlight">{{ selectedVessel.vesselName }}</div>

                <ul v-if="vesselSelected">
                    <li>Current Fleet Name: {{ selectedVessel.vesselOwnerName }}</li>
                    <li>Current Fleet ID: {{ selectedVessel.vesselOwnerID }}</li>
                </ul>

                <div v-if="findTarget.length > 2 && !vesselSelected">
                    <div v-for="(vessel, index) in filteredVesselNames" :key="index">
                        <div class="buttons">
                            <span class="button is-light" @click="selectVessel(vessel)"
                                >{{ vessel.vesselName }} ({{ vessel.vesselOwnerName }}) [{{ vessel.vesselID }}]</span
                            >
                        </div>
                    </div>
                </div>

                <div class="spacer s30"></div>

                <div v-if="findTarget.length > 2 && vesselSelected" class="buttons">
                    <button :disabled="!showSave" class="button is-skip is-medium" @click="moveVessel()">
                        Move {{ selectedVessel.vesselName }} to {{ sourceFleet.companyName }}
                    </button>
                </div> -->
            </div>

            <div>
                <div class="spacer s20"></div>
                <input class="is-checkradio" type="checkbox" id="hideExisting" v-model="hideExisting" />
                <label for="hideExisting">Hide Existing</label>
            </div>

            <div v-if="sourceSelected && targetSelected">
                <div class="spacer s30"></div>
                <table v-if="teamMembers.length" class="table is-bordered is-fullwidth">
                    <tr>
                        <th>Team Member ({{ filteredUsers.length }})</th>
                        <th>Copy?</th>
                    </tr>
                    <tr v-for="(user, idx) in filteredUsers" :key="`team-${idx}`">
                        <td>
                            <div>{{ user.crewName }}</div>
                            <div class="tags">
                                <span class="tag" v-for="(tag, idx) in user.roles">{{ fleetTeamTags[tag] }}</span>
                            </div>
                        </td>
                        <td>
                            <input class="is-checkradio" type="checkbox" :id="`team-${idx}`" :value="user" v-model="selectedTeam" />
                            <label :for="`team-${idx}`"></label>
                        </td>
                    </tr>
                    <tr>
                        <td>Select All?</td>
                        <td>
                            <input class="is-checkradio" type="checkbox" id="copyAllDocs" @change="selectAll()" v-model="copyAllDocs" />
                            <label for="copyAllDocs"></label>
                        </td>
                    </tr>
                </table>

                <!-- <div class="spacer s30"></div>
                <table class="table is-bordered is-fullwidth">
                    <tr>
                        <th>Other Collections to Copy</th>
                        <th>Copy?</th>
                    </tr>
                    <tr v-for="(item, idx) in itemsToCopy" :key="`item-${idx}`">
                        <td>{{ item.label }}</td>
                        <td>
                            <input class="is-checkradio" type="checkbox" :id="`item-${idx}`" v-model="item.selected" />
                            <label :for="`item-${idx}`"></label>
                        </td>
                    </tr>
                </table> -->
            </div>

            <hr />

            <!-- <div v-if="sourceSelected && targetSelected">
                <div v-if="tagIDs.length > 0" class="tags">
                    <span class="tag" v-for="(tag, idx) in tagIDs">
                        {{ tag }}
                        <button @click="removeTag(tag)" class="delete is-small"></button>
                    </span>
                </div>
                <input class="input padded" type="text" placeholder="Paste tag ID.." v-model="newTag" />
                <div class="spacer s10"></div>
                <span class="button is-small is-info" @click="addTag()">
                    Add to Tags
                </span>
            </div> -->

            <div v-if="sourceSelected && targetSelected" class="buttons">
                <span class="button is-warning" @click="clearAll()">Clear</span>
                <span class="button is-primary" @click="loadSourceTeam()"><font-awesome-icon icon="users" /> &nbsp; Load Source Team</span>
            </div>

            <div v-if="sourceSelected && targetSelected" class="buttons floating">
                <span class="button is-info is-medium" @click="copyTeam()"
                    ><font-awesome-icon icon="users" /> &nbsp; Migrate {{ selectedTeam.length }} Team Members</span
                >
            </div>

            <!-- {{ selectedTeam }} -->

            <!-- <div v-if="selectedTeam.length > 0" class="buttons">
                <span class="button is-primary is-medium" @click="copyTeam()"><font-awesome-icon icon="users" /> &nbsp; Migrate Team Members</span>
            </div> -->
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { query } from 'firebase/firestore';
import NProgress from 'nprogress';

export default {
    name: 'MoveTeamsBetweenFleets',

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        filteredSource() {
            let filter = new RegExp(this.findSource, 'i');
            return this.fleets.filter(fleet => fleet.companyName.match(filter));
        },
        filteredTarget() {
            let filter = new RegExp(this.findTarget, 'i');
            return this.fleets.filter(fleet => fleet.companyName.match(filter));
        },
        notExisting() {
            return this.hideExisting ? this.teamMembers.filter(m => !this.existing.includes(m.crewID)) : this.teamMembers;
        },
        filteredUsers() {
            return this.sortedByName(this.notExisting);
        }
        // finalItemsToCopy() {
        //     return this.itemsToCopy.filter(m => m.selected);
        // }
    },

    data() {
        return {
            fleets: [],
            sourceSelected: false,
            targetSelected: false,
            findSource: '',
            findTarget: '',
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            sourceFleet: {},
            targetFleet: {},
            teamMembers: [],
            selectedTeam: [],
            copyAllDocs: false,
            newTag: '',
            tagIDs: [],
            fleetTeamTags: {},
            hideExisting: false,
            existing: [
                'lDnJcJA4qCTgqHjpKr5W8cMeoyA3',
                'Bil7yw9f6FRR9IpJCiwQTm7wlAS2',
                'Vn1emg1L1mVAQtBcJOK5XovYFda2',
                'RP14mKN0YKSvgkNPEtIQXhcsAeP2',
                'HUfFXnXuHqXN9ye6oRaqo37NZuj1',
                'YfPdP1RHUCNXKtiX6gGcGaORNEn2',
                'b4ZFmc09HoWqz4NLQXELqIBbkm62',
                'DzKez1u21NcGxZxu3Q2nLJkx2zr1',
                'nubxOhTk6cdCangL1LN17zoLaLE2',
                'ufspr3uvK8fpXGDR1YmW2WMTafH3',
                'kUTxKHeaEbajNCii2dyEmXB0u1j2',
                'zCUbAY8gU1aCdUAFp04Asqhg9v22',
                'SYDFxIG0ush7wrFMqrYyRQMbKww1',
                'SQ6N2m0dRGaZnEMcHKNL3kyuevg2',
                'SXjadgo1U7flzyB1LuF53sRwWgw2',
                'QBortcK3Fvbfj5N9JQ89VLfOscQ2',
                '6ewLVfCVoohjJaBNDyqODC3dNM42',
                'DpDBFM2PM3U72WH7pvRqmZbvEVF2',
                'gtOv7Juu3xRheEf5tDVoDqi5vTh2',
                'X5QqcamadsRPwip5pA1rsJZky7f1',
                'wAo75lY4yabEYmYRNaStPtwkWeh1',
                'q4URivhpIdbDaF22g6z85FzXvev1',
                'euLUNAvttQVdpF4F3PI5N6gl4Fz1',
                'qChMMzOWGXTJ9M4HDFdg77fWiur2',
                'd29ITvvBTXS3HEPlM3rGPrKytpI2',
                'HbEFsUiH5odWzc9kfhU6tsttENR2',
                'ud6nT8XNSNaQf0hRACZsBJ3zAVh2',
                'Omb4nb7jzZWJQeOkbF7axbaKdnt1',
                'nylAzjO64AOv7UGHgu6yC52nBmp1',
                '05hHW0UuY2e0cQlQ7qXkZEwW8S23',
                'JZpZU8dWvMh7WBvMqfELRBfvJMB3',
                'nbnkak32LdQ8nFGwwOoXdvXeGZt2',
                'b1u8FQ5rzWPKycX1kely8BYtlxi2',
                'pNInFJtM2YgLPfl1I5DPEP91kcz1',
                'hQQfmjCtv4SGwY4y4BG4TqR2HKs1',
                'GMYyYE1yvnOt9h5lUQD4vyKVLTw2',
                'OQIbsAFoMfg5pWMcAxWVjwnvJ043',
                '0uNGY3bFt0NCIv8nQ4QRqm66W5u2',
                'QP2bPnEvrVQch3nwS9WHcZqrxul1',
                'igu5i0vpWxMgr0yALLxTLVy4hVx1',
                's6Vcq4ABd7UZyhAqe3DIqYPu6DD3',
                'GSD1xGrf5BbfElU7rTS5ZTee44p2',
                'Tydf7czNohdEGMvfjCEh6bk4fig2',
                '4rFR2svEzhMJgDUQLyrGwXXjAGB2',
                'OuKfuucmNIYl2mX9tzxnIrOxpEg1',
                'WTdJktBXqvQ3I28kvED9p5N1fUg2',
                'Ac2Fhr6EzXYi8lAVGStwBVuYRVx2',
                'eRfXJEocqkhJKVZIT9ahwN576Qf1',
                'aaPowwyej3Q4FDWNeWZz0xUdBlw2',
                'lP6b15YZClXhVUUQIqifpaKPDqL2',
                '3TyLY72jwuhXtmRm1oHFXLdG0ar2',
                'sShFY8GI5INItqX5YcT0ieZEmD93',
                'O7GYZi0VrrPaOvT5xKbjRzkL31z1',
                'LM6iLI2VkzQYdXoX6t4Tfg4nGV93',
                '0OqjnoSG4yd1SP7baE3ozgCChmZ2',
                '6Tk9WzSBlAaZY1fOo2ApH9v0w0B2',
                'tXMIG1hV60dzMYxJPaIUlmiU4wb2',
                'Od303sCRX1T9iBNDh6VEh3bU3GQ2',
                'QSKGVHtAB5Udk1xtcu1TNsBpdeB2',
                'Gei0A3T1WyNFbuBuq8rs4C30Z672',
                '4xHI3YWEFHdtI5gx95TgTdtIML43',
                'j7XSYdvzZmT2Iyln3dObiXzsoPH2',
                'wu63M9em3VTircpwYDSUAOUeMxE3',
                'Z7yxZDjbhQezE30yHgVEVYax6pB2',
                'LXHF0kCabzNO46PJoxioHyergWf1',
                '3iQCM32kWzfLb6tWLwt1HQVyPu82',
                'Fz7EluEa4SMA14ns7DPEwNWiLD92',
                '74Vlo6uYq0YcunhOLWRFvX3Jpyh1',
                'lrqGj3O231R6liYnVRpA2r4ygpg2',
                'r70N7khFgwXZhlyhjDQUV9ssKO12',
                'cB7QMkUSOhV5EtcKQHYVHUNwQOA2',
                'j1627tIge6MB27ikJ6q78ONh4qH3',
                'd9pLHTUA8XZgoapi4ObBWwc80eA3',
                '7E2HAIdBX1WWpPVJ8aa7mZ0Vb8E3',
                'UFJIdDQadCOofzpB1Vpqp6FRqlq1',
                'yJnfgrvSd4dV0ils4IR95naijJu1',
                '7JR4YMsIAXaR5AUJq7Wb3kLkJy32',
                'ChBrLy6iuBffhVlw0H9dyWbRTG02',
                'zLeMWl7Kdhg9oqOpQIBAVfTw8Et2',
                'TtWQ7ofrexReXSg9KvcC7jLDURv2',
                'ozu6U1zJ8Bg9ZU4EFBbHgvLwSLA3',
                'm0d2xS69MwaZdUXdNSUzAceAl1y1',
                'qeJ4s90TOQY8Bqs4VaRBqVcBOZY2',
                'f3keMWuJDVcyaIRjWUOTynNXlZ32',
                'pYYIuRGt31Om4IqVWcVr1tN6PZc2',
                'ZqGNn2AF2UTbticqaEIgk1Fmibr1',
                'UndMTkJcGAfxrzsp4eoKGYkQ9Ig2',
                'kqDGo8KR4pXZAjA23ATBriux94o1',
                'WyfZiysJTZgzyRSfWHjTAmNVLO92',
                'MvElzBZvOdOF1GC6KOTO6aCUQCI2',
                'DMW7bzVItHgBSuu4DeDZRO9Ktz93',
                'pMmuDvqTrbVHNwxyWUHfZyVYBkP2',
                'BmD3DGD5VUVUMr1H8Mf3CJAqoU12',
                'w2GNULAwKmUqaryrLH82XAuRhU72',
                'kNNnfCFYaHZsRfrZdAjsJEoO9Ph2',
                'qC4UdThjGRZHLfRlvRqh5NJBz4d2',
                'vapbWcTTrtVP5LeSSabsfm41rrr2',
                'pVZfGskOZIOWvzxp2SybZ8HfncF2',
                'sY4JRdDO6tZCg3bPrvoHLWLYO8Q2',
                'x1z0z8RsqHMkIPsZTeP4a1p8WO72',
                'oveaf97bAGaIwQx49SaehfVmpvq1',
                'QscJb3nDuDZwPPIlGxdwX73ZcyD3',
                'xDsVVn3WVgV8AavKYpiezIwP9S13',
                'edhc9z0GuWP7j8WHszSi5FiSHR42',
                'ciLdvxW3G7bzxQ9xu2S3GoxvS7M2',
                'mQBIjn1Am2cRIxstai1pl7fxMq02',
                'nfikSgWgoYTbrR562OKMHX5ZRM62',
                'KCxmMNSh3fQ5aSXgdHymnSv9wk42',
                'o3l7kseFXKSDsi3qvtNEdwwjrQn1',
                'bRgIF00SySOC6NCIwDAqJE6Crvs2',
                'YyExKjqfRYgOHQ2iAypn4iZkNWl2',
                'BjJqufOjijMs7AK7qseHXv7oTui2',
                'Pftdu77mpJhLrPXpgeDThtWenAj2',
                'uO0Y6JAc8jTmmQt6394MHvqBbCg2',
                'OOg8ifHN4NaHMWtgXM1JKBIWiHC2',
                'ZLt1HOqum7XdoNdZLzF4LnFQdFt2',
                '6rDXuTv3hUX6Q28GOGMyzT3jNk63',
                'mQ5jQ6FkY2SPnawIhpV1IE8hsNl1',
                'w19RMFLt1idmwVaGusAjZlTX96U2',
                'v7ts55lQkqagdTWtErPMIvCGVw22',
                'Xf0rUojqFVTJsWZ7jkDhHHHQeXF2',
                'f1DcCXkdXvPwiKrvLVN3FjehSem2',
                'tgce7Zrdg5bozhPdElX0UwgvY272',
                'Y0blhzPfzpgVE9LDnYHTaaqIfJD3',
                'eSEYw0sDWAceKoIb3UFVzyw73ij2',
                '4dZWWKk6JBZuZ9iqwTnzgqOOcdH2',
                'Eu3BbZVCFpYmJw97KdUJKcZxFvs2',
                'e9fidKn0joUAnTS8fuIla6g6zG42',
                'BIMMAn5iJnO42rG2XW42omutRNp1',
                'gHHWebM9UdgcbXJlXTmrXSY0wjz1',
                'XN1bdphy3ZYRusDETaE8moz0g2H2',
                '0uwWxoqZTBQ0S1VQlFXSgcWE8Bv1',
                'Tn0besQfpbcbSjJarQNPRwbBN9r2',
                'ofB0lQSTAQZHaXFcek00AgEgBG33',
                'ASLaTBlX7CZisX1mIvadb7DB2Mr1',
                'kCI29YIqvcXAmx3Q4vNsG61TynD3',
                '5Ehu4nxszAachRJgHpj9PCumLbm1',
                'VqT55g5jHhboOZdJr1WXWZimPG83',
                'js0UlyIiBeRxePSmvnHLLpzGArj1',
                'fgkdmsywIiVCtieGEQhIv0hfo332',
                'ZA9XKxzn49WCnzMoyOaotg1kihz2',
                'KqOShWe4tIcCIjX4tAqng8PF3Ad2',
                'fzVNYdcl9XaJHNWD2H7yFVSbrwG3',
                'f3HjNOxCJycODwCyy7kJpGTAD4p2',
                'TnC2WsMpCLVu6yYBffijsvaER1A2',
                'mqBGUL6H7qenU1kswWshsmCLQIn2',
                'MmGRs7utQcMiXH7eyJ3eGKK0Utu1',
                'W4KnOlvoJZW2X38DOQ0lKJhjnzk1',
                'zf32XDl2PdVnykhQDkjuPPlq7pa2',
                'dObXpKF1Z3bEkdxEO0PdBVhPylD2',
                'qn2FxdRjx3dN3anSi2MPBH8vaO52',
                'pm3ZFe3BXVN9ThZrLvaGqThtC4O2',
                'oKYs4yhV6XeVn7lm5E5kyZV8Jfg1',
                'I8CpvqXdk3W3If0wGPact5aSjQ83',
                'pRNejKwNV9htcOgm01GPt4eN5zN2',
                '0z9P6Fb9yaainEFcOcFRIFY8sYY2',
                'y0QTD5FwUvSbhTVlaUnmICHvaqT2',
                'fEBhZ5LDEHXWx5U4X6AD80dM3Jh1',
                '8dw816TzNXbVwrhWEVRejMkBgJx2',
                'Bcm8LEPl1vVBqImbMy3DI1hzEaT2',
                'RHf8MNddJHOQvAbGhcBcINAbiap2',
                'nbOkKGj2MOXfBYtfwqWqRy0f1732',
                'd0Hc5g7IlnTJ3heUdONID7us17P2',
                'zLiHTLt4IyanqNrWlMR2w3kc6dp2',
                'I5oSxro15ubtQXEHD0cTheEycr32',
                'FDrzbP44QkeqSMPjDHm1t2W7ND72',
                'LmjtGtYPvoSHIvnIPsWNntlnXmH3',
                'dKNKC4hmzVT0d0F8gA1CguR6Pbz1',
                'nDdhC182rrTNTSoCx4Vw6uuOwaF3',
                'pJ4EjpzbOIXOQ3AxIjqUTODP8dY2',
                '1R87zAZG41fQM1K70GWzbKi8pXo2',
                'ECWRnBJTDKfplSpFAjbOgxHFJT62',
                'wEMox9cafJhhRJh41iepRLtK56R2',
                'g4T3XFYBCYcLNUVAFSpEm5wji2j2',
                'VzkJsfkJBsONcYj7aew6r9sw3k13',
                'F0FHVudl88cfJ7OuTSF5VyTfBaD2',
                'p8AyVIsrgDW4tHUyuw7vucSIyf72',
                'nO2invz3Y3hz8vc2gyOTHm8nN7y1',
                '0qqqiPcqAidXpjtaAp3gG8WCxhH3',
                'fkU9woHpfmfCczv0eds7uLRcagL2',
                'obgBi3PEGsQAEBs4aTl7XX0ALGC3',
                'FfICmZczQCcxSajM0MwSnfSMAlb2',
                'wfozslX956PfdtY0RJd2mNAbbId2',
                'ya2OEEYUrOO7r3swTN4CIXybwZx2',
                'y3xL4wkpLmgwKzaSGOqGnOPxTzv1',
                '7UqgacO4u7R2JrPSqGYEEMfUJH32',
                'JLKdVqGdzHOL41AO00EqKbZlizj1',
                'qspaPniavLcYu1SgQEaHh74xK7Q2',
                'RuJm3pCjVsPZAu89Kifb00yflhv1',
                'c3yxWgn1Cjh41v7CdpwPw6iWTxi2',
                'v8piYgBNe5g3kU0rVm75uO3JAT22',
                'wWn5TwQX69dTizwvVPvjd7TXxR93',
                'NJGiuB4NeJZ0Nbmt6HriCjfqnM93',
                'EgitdSV9Ivcg8ignWbsr4ZZxJ1g1',
                '625B7DLvH9as2vuxD8FZQbPtSC13',
                'SU4CfWGJ0iUoyRXHFmyOBtLjXxi1',
                'YwFwLKMSKnTP93Bo7KkVZ4vqiF22',
                'NslAP3n2seZGdCenyMv52sMq8VM2',
                'CmlcHa6o7JeetUCaCnVW4TIrptx2',
                'b0qNUZhbrQgMNOeq9Jr6gCNBa333',
                '66oIgO9FAvYuRtXcjrCoZSgOUXu2',
                'tWV5RapDQ1Yz3ebzSaxJ2JfrEPw1',
                'HnWBgg2OtQQcReJKcsZHvz5Mbkj2',
                'AhiBAeGmnGRflEoya6wLM0OuY8i1',
                '7OLPikrquQfKrfJehk9JV5W1avC3',
                'ArEE2tE3UESrnvKX0rKxDGVJgUE2',
                'MHOWimFJd7QW0tQmwtYM4k1abOm2',
                '58ITniRSTHY3KUvxRW2ZiDpxs7y2',
                'aXk952Znr7h0AvNzplptgS6ymjE2',
                'lldw6VioS7UdlnB2HKtsPTa0HDY2',
                'IwoGdzZXEDc132uDuawDj2kt7Mi2',
                '53vKqefzwVbMikIAFzstTmKtBx22',
                'R8r6uxbcajgrcNBFNuw5fFjgJWQ2',
                'qljAu4JU1kSeMlOnI5d1j6dP1ly2',
                'vzef8cDyL1cGG7QsNMp7K29ir903',
                'IfjA3WaEaYhgmBeUhnVxqKxfPeH2',
                'LOh1QZlj9ieAKEV76QVjEnJoInn2',
                'I1W1Hc9T0xdIxHy8NTCp5IUL52r2',
                '9KbrBknYfVYpa43nKwKr101qHpi2',
                'cUmC6YjyKcWtpmVp3erfWQN0wKE3',
                'VAU9eYMVApdkmfXNWLuUmlduyhP2',
                'Qf91PIwqZrSUECXr54w6ZM2Fc3m1',
                'YyOgfRZklrhnZyHU9PvC1zZ5irk1',
                'H2Q9r8Dn7qXXKZj0XWcMbB1DEup1',
                'c8GaDQW0SycHvB6dX10ZezVUR7M2',
                'kVLFKsTUkncyYZMuMSFyNy26Lpx2',
                'FbHFtXZNOYc3FX6yx3Uoym1KGmh1',
                'w7KdfQ2lU1M4QHsAj0BNz8tYFVb2',
                'JZwLVhuMZqhBt2PEP0hfj48Cdsq2',
                '4VUXS6ZDKsejmVpL3ZnZY4dFGuW2',
                'hSAFGLVhr2TdxlEJ0sL60iYA0mr1',
                'ieN33UZDOyTtguz9wF00wJRQFM83',
                '6025TZG1fea82SglFbmdRIHmuEm1',
                '6SDApBtLRfX4FGHH7EDs0hojPsa2',
                'WkDPaUiRKNVwaeVeltiQHjxgqt63',
                'IklNu1zQsAS0IWUb5hTUd3DMGo52',
                '4AWJ0ktOysRLokcgPZ5OZCrU4UF2',
                'qOm3Ir0GfyTdOpcWzroh34uSbxX2',
                '6IiwoUJHqtWtsysKf9EL6RXsYnp1',
                'eWoo94YLlOaIkOXdx95JDJOq6Ph2',
                'CKfid1gLz3YjKkYKE6jVWhpRIdo1',
                'cFeJoDDchOS2RXv9xhVpBbjTSUf1',
                'EJv8D647zgQKJGDgsyStmqXWeyx1',
                'U0WorDtjzkbk1gkmulpukGeT0TY2',
                'o4EDM2KyPVeE5FPRmsj0Jf7xW5q2',
                '551oXRUClPMDLy4v9TsMl2WOZ792',
                'LFiTbWgCZ8UvZxGgtY108rYXLCS2',
                'DMQkDrjof1XKjTZrw45EyWvp75R2',
                'MZNAAuo6YSQ0dWunhwQlELBhQd63',
                'EP5h4BeAOLTU9t5uzj7rn05Nc2j2',
                '8FNv08p21RRMdP5pkD4BYpiBATb2',
                '96r1t3uaQjU9aykchLquBcpY94o1',
                'NYC8vqorbchwJxvclVF7hI3hkd02',
                'Y3bC35XzT2TiRKI99K2DzrIG4kn2',
                '2Sb7fmTay8c5nCZpcB7EmEJi36h2',
                '1lrqpUWrOudpEEHGUWN8hlNpACx1',
                'hrO6Oh69EkNLzcsF1lblR2kpNh63',
                'obrPrKuN1CZpSaqTjl1HNZJkvrk2',
                'UgR9ro0XcEfE9a7XjWSWMZv6hdA2',
                'hTrZ1gB8eyXmNbqtNSYQDcH2jE22',
                'WDeNFSQEf7gEwCpX34xE9WpBvV82',
                'VFMccDMZfUgSjlRezFI3n75FATe2',
                'b5fQFRdzVNMQxxBDPkxfGmBZNxh1',
                'rJgkvN3uzRQidl3D5cFYZAOxfSU2',
                'OYRfwROHFcQF4oweIbocs3pE6mq2',
                'N2t8EvPkucNllPB5yaURLeTl1E13',
                'V4WCwn4NORX1K0nYiQMGvCnOO2B2',
                'VeYePlN0eiatlMP3Xl4aV9XL64a2',
                'HDklBj6JgbO3y0Ha2fihbrJrU7D2',
                '90b37RrktPWe1yOaYzc6U82VqSA3',
                '5gxDvx2FmfcCSkpELGWvKvmoocf1',
                'lE3Lsq0dUEcdcc8C5FS9afjS6eM2',
                'tuk62gbQrgYwawYfBhtZohN24E43',
                'GxbJd4m7ayStZedz6xTKajADMSD3',
                'F9nWb0ASTsYBk66TMtQfyBjhFt53',
                'kupmuIvyAyREZKRLikmg16QU0G33',
                'hci3rVhmAegqsCBD9kGkz4I06Ug1',
                'Ipo254GteUWiLEMLGfIMtn9fNBa2',
                'vy6dNdI7OTQrBIzbkH4FsExgUQk2',
                'EoMxtZQJAoXsBLUuGsKaLMnK4vn2',
                's8Ae6ffsiwgsexevertfi8aaFTo2',
                'ZrgFQWJUNOWKonNyMcXIJXJFoxB3',
                'o53ueEdgqBMy8ti4bKijzEKLndV2',
                'FeGldSBpaJY8jpnR6sdms0Ucq263',
                'Ym6Rya8oRlOqv3OvXu24GkwdENc2',
                'NnG4p5xLRCctM19EH4qcONZqyAJ2',
                'xXABkgsg1Na50V4P7pqKjMcXjuy1',
                'R7BLunrosrScij56OA659sOefgi2',
                'hSgfNswPSwRPCjO2eVQ4suRKOGF2',
                'xCUdA3nWUeNm6jyDMc9rFs4qeN93',
                'Sd1W7R6jIWd2VoMvIqxLQocopki2',
                'Omyvwhoc9hbj96W7ucHgImvTiDF3',
                'DEHX6VxnElcTtme1y5e5PFkbbK22',
                'kQwXydIvpYNFOjl6v0lXOzFJ7Qs2',
                'liAFMdvFigZKhZVKOdTZfoK3AUT2',
                'eRfY4mbbSYZn510amrA6fimg9hC3',
                'lzkLg0C9yIMGZb2BsqJRK9s07ck2',
                '7hBcx9Zza9SwNLotpcmv6UmUXer1',
                'IBPWmYChnVQC48uQtzf0GQozOWC2',
                'jGa0ByvPZEdeOxd8mwsd5JV25Hl2',
                'adQWduOdD1cUGGJndCPUd4JFn9o1',
                'TNKPDdaSMzL7gn1wWrd2mnUVXQB3',
                'QORUsidHQZY3HWF5Nb76NSn9oeY2',
                'WFz8vxy8kPeCbjIVpMhN8ehRwhN2',
                'kAZhBKPoKqNDPRvYnfIan4jnweb2',
                '2avHU2MORJfHy5rAIiOgLdBIsH23',
                'CrN4bQfCQbTqLfc1ULypsukGRsx2',
                'XyuUJrCzQscJZ3Mqf8lyTjSD9BF2',
                'bXcqS3u6gqecYMYK9sailaxoGjn2',
                'UJ3ITQOQAKhZTVLwLjz7aJx6e9j1',
                'gicnUphQtEM3Op6m42f89ilanmE2',
                'wQTGyAaSIwP3lOIOuyzGtJWqnYn1',
                'F4FJWPGXQsSKhkRRDvJiWaiL7qP2',
                'ALh4xDZC7gPGJr5ZYCCyyk4RftB2',
                'LigiCNoCvAUCRBU0h7CfiwfGn322',
                'kObiv6lofFUO6WRTNtSREwErQIg1',
                'ZwLyDdFCoCdNPuaXtzYuW5umHWe2',
                'CP0bVcJY8aMwJyZ04iqirdsQ7cq1',
                'n3ByprIkemUzHZFAOSgHQXrRx092',
                'jWmXTxJ5UfU8hv635ezfWz6MyAT2',
                '7nLo0ib1nlSXGJHwUvwrgGyV7CK2',
                '2FZ2rZECxRRvqVjPLpGd2fEZfBB3',
                'WgprveL7bBbrlf8IZGokLdpwFXe2',
                'vlqR3FKt4GfA8smQj03LHNvYhlw2',
                'uIVv3pm2wPOZshagR4k41I5AYes1',
                'iAFXmspwawcdPdQ1wGSpJJEVtGi2',
                'RO0xibLjS7bmfHzGcv0wVxFLK3G2',
                'yK5VPH58YCOLrJKpyBl2GP0vshY2',
                'oJuxw6pdPEdQRJ9bYuVsKUlA0Nv1',
                'j5Pkx64j4STFb2XcpoSCRa2tGSq1',
                '6doGiCqpgXeHQqWDwuPO5jYWUtt2',
                'k1D5dVfLMkRr342gh0Z0Rrl6M7C3',
                'ufyOSlJ0IpTVFKkYqQX8ZyqISf92',
                'F8aN6ojfcTY39HSFhpdn1CTOknS2',
                'ad7C2VqsltejILWsjWnzR5JKdAl2',
                'hFeURwTsMxYWNcILmzT2VPe0ls82',
                'SGXXNyclNzaTbnbacDA3fuOhEB92',
                'fy5zptoIMPPcUWbEKSZBfSMH8rH3',
                'imhaRUNdYWaD2ypJ6oCyUZxJpkk2',
                'e5CM5BcEsYO3uGeyvVKxJTKD4pq1',
                'fo7wFdBfQ8MXADhBEIznoE2JgNf1',
                'ePpLTq5igQfVJQHEJV7dpJ4pSZy1',
                '70auFVtiEcaM3ydQtt7Lr0wlPeA2',
                'k3CX1zTR1KSRVYhznETLFXVSaCo2',
                'rsQA7R3mY7enI2HMVwlomjS4FWm2',
                'H6sZcsmmYYajmFdxOhXXG7QfsL93',
                'Y3dmS1vv4iQGJ1rOqinIsNdvxOa2',
                '6WDTwruK6ENDBbh9qOKIwuDTSlD3',
                'yHuJrP0AzdRhU8vcBbdfjybDARj2',
                'bEnPbqrZCzMGHFBjWEhyQZ34El93',
                'c1OglGyVHbQzEbPvaZd1Dg8o6Tr2',
                'mEr7WLz15XghxdJZJ6GXkPFNhZj2',
                'p5QshHZHVUTJvUQXP5R4cV8LVOw2',
                '8Iq8uWlvB5eJmZtylxv0xdyWmgj1',
                'h1hP7lEp4RRvenmiTg2QKangP5J3',
                'gy0MXIiJ1AgTlXlnVNxgkD7Wsts2',
                'gT1rW8jnGQTUfc1JFiUAkOq1C5s2',
                'PbY59wDHyfcBVv8cDcEJQuzQZYM2',
                '8xjCmVmB5JZ0pUOIKk7NbRF8YNv2',
                'nNgMPyG57Eh143eQAB6Ni8szG8r1',
                'zc9DClTijHOQ84V2y9pnTf5hhHD3',
                'RjmQWGRtgsZOlOcZ1n9qz6SWcbw2',
                'Z81EwFHzh7bD3ZJjOUd4Xx9Omek2',
                'JdHlafGjuWa20buJNeUSNYDXS6u2',
                'RTuHCR5nveRkbwUagD1QLgwRX3l1',
                'AtkEAAgBSYP0SFQPMKFAdNhJKo93',
                'zdQVQLuKmYZ2YiHZryArMce4kz32',
                'jGLP25Txmea1IO1D3UJz2n4AO7j1',
                'EvfeSX7BiwbilIw7UvYb1hS5rh23',
                'ockWUGf2yQXVs0UCfTlLeBv7cVi1',
                'qjVJpayqwoXNLYVxQCOXKluMEwQ2',
                'GbKXrzmMyfdEjDZ0w9vq3DbttnJ3',
                '4R9hW0gGmsY4t79tndDQZcMyusg1',
                'vezrHF5ERpUTcy42lEvAPIL6el02',
                '6DNO32clWBUc6Tg7SArSbYcvDM13',
                'C70LNg9FfZgCyzm2zwkF9ZI1JKU2',
                'UNKVtXJ5JVVVVIjgATV6ROld4313',
                'DGyVVHFSIoUGcmtzHBWlQL7329p2',
                'r1uAjAugrWYQ3aWYyKplRNSjfRb2',
                'jy11W0IBKjP787y27aZMOy37fGb2',
                'gDuU39TmtxNU1mhU40L7Utmb5T72',
                'PBHrLOqWK1Qj4Cw0eUvKV9qxVVB3',
                'CCQMUq6eDXZCAHeKthlP8sF2VCg1',
                'kuC50IAa7SZH07TCBS7t2QnZ8kz2',
                'TByGPQipXzeJK4RvEwNmI3PQBoP2',
                '1H2t5eICI9ZHMISmwbhRwrne3Ae2',
                '0et4tPX0TvbQtK9dB7caKjp2ofi1',
                '2vUHyfSYIeUAWGdcjAMm63zXgA42',
                '2xMCIsvPEIMF8hNi5I5sUZfZ3eo2',
                'N8WG5diRaeMsKxXaF42L1gVbp3D2',
                'BSEPZAHdCOcKLuzmCZg74h7bzDw1',
                'rf1r5Re56HcYM4wFGyvrW4fXeln2',
                'LmI5LPGJCWWNoxcurbNWX1N2INx1',
                'VnJVAwZcmTUVhSNSePsNShuWhEi1',
                'wWDeeeZVAodoSgwSSPLvUOqh90u2',
                '2GOMkid14MWyYSlq7L3WkOdKdnp1',
                '64OxaOAmZfWnmGgoCRnlikoW08p1',
                'pM2aC6uS6Chwu6tQE9wdbz7x9J52',
                'AxxROGQM4Lbh7tccDnKe5tgRmS32',
                'amUIdE0WSjfCrcVQBh6SyeBkejc2',
                'jQJEVa3Q5PX3X31MvlG9338eQdL2',
                'Uhfyom2HEUdFzG7wadLtvt3Gmrn1',
                'MynSvLambVTV4TSFohxZxf6tLgg2',
                'eIxn284djLObeHqYD23MjJItzhE2',
                'NhtLmyRdEYfGY4EPH78eHKSy4ni1',
                '9L3cFdmzqJN5vSWAPGwPXHkOKD62',
                '5i0x0iNNR3M8zwsE2gb6KAKyuMR2',
                'J8W9vYNiH3TlbxhFEh7CGW66vxq2',
                'A7ZXuQ2tUwhNRbmkeY474WFtq9t1',
                '6hedGQeRTnQVkIeZ5MYA7wHOlXT2',
                'JOaHmDXX3jd6hT3875eJdyuSSHy1',
                'bnfMjbrYyWd9Ht9qUF3s0DYsFU02',
                'fYUb9o1I8iZdxFLIQTD08L3HLWk2',
                '0LvigoosgEcky4VevBxz9FlZrjE3',
                'UsPXm8LMCcfXo39WIbHjvkw2RZB2',
                'pGy48FVXXvWMiDcso5jmqNOgOat1',
                '48Vntgr3cGeruIGoiROvyWwHMgI2',
                '7vqof4KDbmRuRcDe7J0NWAeoYwP2',
                'HhUZJJ6Yp8OEvq7vhBTVStoY54A3',
                'R6vBFJjp3bUuZq6zUAAxiOl2CkY2',
                'DrDiyqPX9dcaKLl5x5x04xBGXlb2',
                'VC8dmCwOmmQWT4tkuBIGVACWvHa2',
                '0FDDS2z3QOOTVbi3SM6m9UgtFjw2',
                'Tg7FhQOMUsSdIUjeRYvkIy3ORhG2',
                'y0m7co8wlJadchZZZ6k3NMhgqTK2',
                'RhXrFA4yTPRpsH2JsgaSVWwCRm32',
                '1uTPqypWGkZpKHNo72E7SaUoXST2',
                '7zYao1ocqOPQBpurOv0eH1tXyYP2',
                '1eJoxeq56Ph5QY5qTWTW4reuf4s1',
                'FCJDpkfDPtbwi7oCn5ExwdmgzJu1',
                '4POobBhj6yVMzhPUjCGdW1Y7A6m2',
                'DpqHnhl0QSRuwqMbRY8rE25zER12',
                'IvMCuiEZLLgaZ7vNit5fctySqnL2',
                '2SLTL6sFo7c8zi9HP6Jd823wUAB2',
                'PciX23x79zfy2LMDPUMnVMIuqYz1',
                'ONlyXNas2jVV1uECqpJDUSCnq0O2',
                'hzYXfvYMUbVSYoBCDv7J8jLbc5y2',
                'GKs6dl0klZd6wDfOSVKbtQdiNAq2',
                'X5WtjKgUGhebVHqmOO3rGqvhg2s2',
                'iSKKJeRf66ZTkooBFdmJZJ7w1t73',
                'sAkEuo8XHOcm0U6LvJXOpnFtuy82',
                'QNjyxdET3Wc9IhxBLfxk14klEPo1',
                'prgRgjNecDbG2m9PmJbZam8szJ23',
                'd5iJXFcpbkO2kHJyIkkcvikzFEP2',
                'uqmGdBrEzSSGj5fFmxufCiYTDVo2',
                'dgDe4jJKswbRdI3xXcRefXEBs7b2',
                'n0ksw4m6rMhOuMUkjadwcBBEJHC2',
                'Mv9uMn1lA3VQXnSnsozEHJ5UNHG2',
                'MBOJEzrx0zVb1GgMFiMxfDPxP6U2',
                'Ym4CkctvDddRvhfqA6L0IddOdoa2',
                'Sjh0bagZWqTn8IQKNCd1SLpFN7n2',
                'sqH0ETepSHZFL8x0XQPvVlDV0Xz2',
                'yNYmdvgKm0XWcs0rPoJpN91wsWq1',
                'bTy9V7nWsiRZ1b4vUI5XCed2ik12',
                'T0IuNvY1SxMtgnBHV5o8DoeMqz83',
                'Oy6wIbwhaEZQojimK1ACXuvDX732',
                'bHHn5FXWcudu80qVh64a6iBrtma2',
                't1tNKGa3mjQJ0gghIv3bmp0ncEw1',
                'Lz8pBe7eF0QDYOj9khkEeJr7WVs1',
                'HjFRwtVHvbWGVS3tPcglnCUSyuw1',
                'So2g2SeTFQNcxp29xPHH0S8rSNq2',
                'SSz2GiagHaMtav96Zl9dffmv0gt2',
                'l3zPJaw6BWVFtbYVBu6JXXHNWz23',
                '9k4DkUVbIONfwSXeqyFbsCTr66h1',
                'vzR64dAlHDhctH9h9I4oiq5qQeT2',
                'dy33iYVEW6Rs5Y3y9zLMQTrrLV83',
                'f2Fl1u2oxybFZ9CCdzutwUR0W5Y2',
                'FINHivsQRrh1qm5hBoeKC2IxZyS2',
                'kWRZQV3yESgaNzvMpmnWqc3OU7F3',
                '5xld7Hw25iXmCfuelIc9LWquVrw1',
                'Kd4KR6Y2QHaX41Wo9q8NMuUS6XH3',
                'Wu1C2ADOFrdi0OMVpkEntutq5Ov2',
                '4GuiGnE93XaCDDDYn7Njjhy1zFb2',
                'BdDy6k912UbZaHhekKN2HQov6p62',
                'zvth528KbFgTxD0srbAVNKELzkg1',
                'eqG9XWngcxQ952NxHIhx3lLBrk12',
                'yiX6P1kjRwUH9GHvoYgI02rwv4t2',
                'veswP1t8xnaQgwwxz5dYWqti1pK2',
                'rwxz7EGIEMVzaurvc6VD3J0gMXK2',
                'D3Azbh9TXcXI0VCW1WRoWBJek2V2',
                'Z76U1Zww8NUGkKbQ82Z6ZDCG6tx2',
                'Vumt89hyYjczAzkqKHNOq3X98xP2',
                'E7i1faTU8JfMEBKT3BAUZr5An2V2',
                'IShJ0ELzhmQ8t3fjS3I0nCIbRTA3',
                'OT975rsCmAelImwlsqbc1e05DSj1',
                'E7kRV0xekYU5u1yKudMfxl2g0s22',
                'mV6nygRTiPYtYJ7DCnyfqsjawCn1',
                'VdN1moRubfTP7vT5YRuQv8m71P72',
                'Vz2DuWhVt4dOTsZM4gig1PZehRv1',
                '29uZG99c4OOCEX1K2wRxt6bOafV2',
                'FKwdQL6o3kcCzdRKPCLjWQpcchC3',
                'zVEZSGNd9XYNcvLKTJTVas3aRT73',
                'LDAGm9aE4LOyTxgo6NhEqEbWqb12',
                'xDtr7DQY87TidKPx3r9SukG8vc42',
                'jzuNrbuF5JdtWZkPfCpi3WNFw5G2',
                'G62kK2IiijhAqC0YqPrToODwMDF2',
                'EJsIWfXCmdhTOtH5VpzanYh0O4S2',
                'mkCY0zH3uMVNqnuaasMZsKlgnVH2',
                'fzRj5jQOMwQAvvPAIiTvFzDsWFH2',
                'WdRxIS4vQHetfAAZt4aBEAxbETl2',
                'L12ubuXyGMZSw0DkDuDmwmppJQc2',
                'z5hddMV3TYb65qr3o7hmrPOmMau2',
                '36ILFyorkaYJb32jGVQDsf1W1Pt2',
                'VPvgPHsMpnahNAdIpVOK3myZ1Lt1',
                'a7pltfvDCuYskoJZp0KcKIRAFJt2',
                'GKA1yoBNvrbbXJ13qukfSq2L2Nz2',
                'TwKam3qqAcP5N8SbEJyW6UCaM1l2',
                'qIt2q692x4RzcS48EjsYd37w0C03',
                'BzzLoXAG9xV9etuvP02rg4gQuD72',
                'USriylcvBLctG3LO5xyHpqK1PZy1',
                'aMzcplJt55axkrGE1en1ybr3bA93',
                'QTjFv2fmVVfpyHa5lQOKbd3Gmo03',
                'yqFsfJy1CLhv0gw6IF2RD4w6bNU2',
                'oAs0RRW3LKRg7FqonyapGk77Ji13',
                '7euU0AO2t3WpymJxv6naVkOCyiA3',
                'glsCmmTmEkfjAeRFxyPX6pjH4hp2',
                'W9tg4XgaZlZLLzZZl99auk38rFu1',
                'MuF2V3cRQDdd3Y7hSrOADF9QIzG3',
                'h9TDQUUY36fLKs6p6gpPQMsuyEx2',
                'sBowzIFcrzb48431q3opLNzVsUX2',
                'KOCrMksrpgaEexgeUiH6GqUoEhf2',
                'obRhDjHT79QpqjzKfNmopBgNIAj1',
                'dYqjPXcaz5XkIiVqCIm001QTn3i1',
                'v15rloxbGuStIDWge9Q5EigcgVz2',
                'XqVkxjnoNFfuqKBhPBHZGZIjk6m1',
                'OsvAoWFGZ1fxAZTm8fQnwBWC3nl2',
                'tXIir5jmIdc7VEIYvKHc4EC69gm1',
                'oW9dmbmbf2WHHJR3SnB0URnKhZz2',
                '5VsbgYdU9wVbbcInFf93PhYpH5l2',
                'kbOL7qv3TRW4Pga5mzLGOPjd1sm1',
                'YUOUBOf7uITjM3Ulmdb7HLCpBke2',
                'kMyK8NjThLSZwr18YM927r4KZDF2',
                'BUYIn8lxGLaNqfm2WRewfSQuLIf2',
                'Gnbnr71uLYNdX5h42GIBFyQl9MH2',
                'NEpUPLjctjRd8kjzrR3pVwfbXm12',
                'iQrtm3lBv6R6LpGDEtYyRtaGsFz2',
                '1VY0XO5AeQhoQc9YoUI0EipGdc42',
                'E11IA2OCdncu0Ho8VuI5uctTP3U2',
                'MuUkh98zTLattU0H5OfMFAnroeo2',
                'In5DKk8SxuhNh9VHfrqIcGI71cl1',
                'fV6l2Fx4S4UdkrgBkG8Vq6fJ3Lp2',
                'Rxh9L47iwegkzBiiHWSRuPkLsSL2',
                'JruyZxYlL2bTL9wqdRSchBFFVQX2',
                '4iMbJ0AtukNXiEBVoKMHfxzOma72',
                'dviMGzHnCvXv91m6eXzog4QVmQ92',
                '0i6up0pveNgfmpmZnncHddKy25z1',
                'waIxxalt8FQCkoMaER7xtcQW55l2',
                'BY5UdvYzWdPI2bCWIMFVy5tJM8E2',
                'ReNV8mDReYMb6evHGcIH2U3y1Vo2',
                '6kcwIbAquMc3Y59WoZYfa0Iwcwx2',
                'r1VGZUn5PSQFcQADccEgfKQDFTO2',
                'HtyfMbIxEZZFOAOFyJXtn4xWp3k2',
                'ZkmedBcZD0NNX2zKbm3Ec5fnGx93',
                'XgbIcBlRDefvwcZZj08U7hRRUIG3',
                'QgdKVfDGqggzO4HLWBdXEQkgFNI2',
                'ANp7Ho0n5PMjL7BWxC3etzGo5gC2',
                '73IjJE3ZHCQ3imS5oImb0wSOqMw1',
                'R2auPgB4o4V1n8qR67cUInKAwem1',
                'chWVKDRReAaxfwf3J1ZrxC76Xvz2',
                'ixjcuVUPIheg1uhOz0c0isUj5w73',
                'kV0H4RjWTMcTiH1nzFpJ3QTjpym1',
                'sHTpD8WFcdcnbGxTSw36fYr2hKC2',
                'EH8CJ9y15Ra3YidDnBEvCVdzFGl1',
                'qvHoGeRqfpSF7wZ8NKCLmGOrKr02',
                'OvNNxNI9cDbJJFbgdjlf7GLXsjr1',
                'esf2LagFtWNhxwbDtja46K32zpM2',
                '1eJnDEzix9ROGk6uK2czhcGKssF2',
                'sSllmRCInFURytFmd0HC2QAOc623',
                'YmIEJejzPfQkVUljH4VhLCA8uxO2',
                '8yFPsPteZbdKmTFbh2CbCRA8U0d2',
                'VRrg8iybzNS14czLAPHUn9l8JwF3',
                'SCq7Ki6rGfflfV0HvgnGWJpOz693',
                'gvAe12gO4iSCPe65o43oS2gmxvH3',
                'XVxxP1NPgSO24b9SDRYoNlf5Tt93',
                'wXoKY6ubGzQuXlhu2OuKBVG7cqh1',
                'YEmkR7yUvZVa8clPBj7mHcC6JMC2',
                'ZqJGwIJhTFh8s1GXuGpxeKwZivq2',
                'XEhYtNoS4ddkuJ1RcZzAuiN742O2',
                'In7tEj5A9tMDkmdx9TpQt6XtrGa2',
                'KtGdwbqR0WXntIGZwAS8GeTkRDm2',
                'i6VgHiKVwNNeaETgNL3bQxasqG13',
                '45YNUSAnKVRSKjtQ0SGwEfJPocH2',
                '9ZgvhhMwhhQPSs5Dqr3QCtHBUzz2',
                'bu7fAjOtx0ZGB7SFrn3EbjDVg453',
                'sCrfPByxHBeIZDBqmyDUykNazAr1',
                '2nkqFmrNruejVo6gnbN9Qoi8Xtv2',
                'hRKZn999wJaigNWg9FLOfGpFQoy2',
                'CHpK218qx2QZ87IKszAZkCX0q1L2',
                'aWS1RMaq3ecrdXYqdn2tszFWRhG2',
                '3wTzZecD4QYOPUk4IyWRA2JmDcJ2',
                'Ux55VbmgzEOkRATV696koyWLz0u1',
                'LJRREQlfGzQkQRvxqs3DYXMQ5pl1',
                '65UlvxfPzkNkmYZEbtmmU9cWiXm2',
                'ik73H5pQVienFGGRfBNXQWHlzvm1',
                'laEd0okRiaRwd0HbYORCTcAJfmP2',
                'xxkDdxycHIMwh1c2ptyhx4kAG8F3',
                'Ff0MbhP6oHS4qCUEUACMJPUKg4v2',
                'wDrzfe2idxgjWXiSDRPRjX6wv1i1',
                'zZKshvlXqIZVQeHOMNBOhNBAoR32',
                'AI6ttdLbTOUxNbcHFyiap5D6L092',
                '1wVdePVSdxasBrwq49I3ltfyxaZ2',
                'ewOVRqBjScZjQLJ7n3ZQTG32ty02',
                'eB1PDo1ObDXQOjKxooeyzRVaEZo2',
                'uYUeiKuGLJdF0dLNce4UIiHz06j1',
                'YTmcVsim7qgChIbnGvTyUmhejHd2',
                'MEhd4j0cBoQ60mXQ3uu1ocvxvWm1',
                '2VZnWE2N2AOukoCb088L4USs3uD3',
                'cM3P35Zl0SSiIBdTAWol36eqKXi1',
                'xnhJ57AYxSREVhPguhkSLwNLmPq2',
                'CPKxhDCk3bhukEODpOElh3avvrq1',
                'F3sHzQY3fPV3qKJwNkghPGC3cSR2',
                'yf0b8ZKqt8Zbp3KqQ4OeJmUfER22',
                'pmioRunEczQHAaI6bPlXvVUsY5B3',
                'usX2XBomLOMXOW9pffII8RI57N82',
                'MRzsw6vc12VtfNN1HDmNZttpXQp1',
                'YJCKU8Yi1jagHOpo6CbGSXAXy2G3',
                'lGVyBSxUpecolvmyytXiD8NB3ey1',
                'SI2dk60wJzNy9SZ39Y29nQyC13i1',
                'tJOHtUcWGJMHbnjZHeoXDEUgSMH2',
                'w9WNR3eTl6eDGFGVFus7gNC5RPC2',
                'TAGkXy557EWHNpMvDAkUpMz1a9v1',
                'nQ3vIzFssWXMzwAFD9FzhGD0gIR2',
                'zFI0RV8MwNMmFWREpQ7iJdjY4I03',
                'xCcJtRnjCIYfekrHuz9OFpCIUc73',
                'XjovEPSoLLah6ZuB6tCtMmHKoZ92',
                'KJba15uar0RNS3nFew2UyiJg40j2',
                'iJ5NWAyjFEec8NJww8IW7dpsR4F3',
                'RFLz46fX5thFgeltbFIAqdMMbw53',
                'a4ys67RH6yXew7z1U1kZqqpf7To1',
                '9X0PryJOVtWylR42e46qLRxt01E3',
                'Q0sJUClA0IZ1nnLNFkKD1Fz7ckE2',
                'LG3iwQOXcwPHVi8bolH4raT9IBB2',
                'VbRSs8w9JIPI74PWQ6aiPOWIQdl2',
                'VjRVDVJbEST2aioVrWGka9A3TkA2',
                'q6XK1wrMFScjHs8V23rMCzDUwED3',
                'olaTLN8g88choOCrrc73cupAkZ63',
                'j0xxoSehPaWpnqoG13oXkTAVacz2',
                'V5j6pVpJQAMBNwtw4NrsD5CfYnf2',
                'SA4GLhtJ0Faj0mUNkJHCZKzYTTg1',
                '7IkdubBIrhMhIvBrylSnlTMvynf1',
                'Aoq21DOMcWcjJiBxzrWz36yMQ9o1',
                'uPJLzAvv9vX7p3Pprxnb1pbQ5Pz2',
                'zokhWddJX7WGZg18L2HtlaIv6pz2',
                'ws7Gdg1hOqVX6AS2zDchG50wJQR2',
                'VMoGGh7HbacIgDtBWBRbRA3PCeG2'
            ]
        };
    },

    mounted() {
        this.loadFleets();
    },

    methods: {
        loadFleets: function() {
            let self = this;
            db.collection('companies').onSnapshot(function(querySnapshot) {
                self.fleets = [];
                querySnapshot.forEach(function(doc) {
                    // self.fleets.push(doc.data())
                    let isExpired = doc.data().isExpired ? doc.data().isExpired : false;
                    if (!isExpired)
                        self.fleets.push({
                            companyID: doc.id,
                            companyName: doc.data().companyName,
                            primaryUserName: doc.data().primaryUserName ? doc.data().primaryUserName : '',
                            primaryUserPhone: doc.data().primaryUserPhone ? doc.data().primaryUserPhone : '',
                            primaryUserEmail: doc.data().primaryUserEmail ? doc.data().primaryUserEmail : '',
                            fleetLocation: doc.data().companyLocation ? doc.data().companyLocation : '',
                            avatarUrl: doc.data().newFleetLogo ? doc.data().newFleetLogo : '',
                            adminUsers: doc.data().adminUsers ? doc.data().adminUsers : []
                        });
                });
            });
        },

        addTag() {
            if (!this.tagIDs.includes(this.newTag)) this.tagIDs.push(this.newTag);
            this.newTag = '';
        },

        removeTag(tag) {
            this.tagIDs = this.tagIDs.filter(m => m != tag);
        },

        // compare(a, b) {
        //     return a.crewName < b.crewName ? -1 : a.crewName > b.crewName ? 1 : 0;
        // },

        sortedByName(arr) {
            // function compare(a, b) {
            //     return a.crewName < b.crewName ? -1 : a.crewName > b.crewName ? 1 : 0;
            // }
            // return arr.sort(compare);
            //
            return arr.slice().sort((a, b) => (a.crewName > b.crewName ? 1 : b.crewName > a.crewName ? -1 : 0));
        },

        clearAll() {
            this.teamMembers = [];
            this.findTarget = '';
            this.findSource = '';
            this.sourceSelected = false;
            this.sourceFleet = '';
            this.targetSelected = false;
            this.targetFleet = '';
            this.selectedTeam = [];
            this.copyAllDocs = false;
            this.fleetTeamTags = {};
        },

        selectAll() {
            this.selectedTeam = this.copyAllDocs ? this.filteredUsers : [];
        },

        async loadSourceTeam() {
            let self = this;
            NProgress.start();
            await db
                .collection('companies')
                .doc(this.sourceFleet.companyID)
                .collection('fleetTeamTags')
                .orderBy('index', 'asc')
                .get()
                .then(async snap => {
                    self.fleetTeamTags = {};
                    await Promise.all(
                        snap.docs.map(async doc => {
                            self.fleetTeamTags[doc.id] = doc.data().title;
                            // self.fleetTeamTags.push({
                            //     role: doc.data().title,
                            //     id: doc.id
                            // });
                        })
                    );
                })
                .then(async () => {
                    await db
                        .collection('companies')
                        .doc(this.sourceFleet.companyID)
                        .collection('teamMembers')
                        .get()
                        .then(querySnapshot => {
                            self.teamMembers = [];
                            querySnapshot.forEach(doc => {
                                let data = doc.data();
                                data.roles = doc.data().roles ? doc.data().roles : [];
                                data.isOffline = doc.data().isOffline ? doc.data().isOffline : false;
                                self.teamMembers.push(data);
                            });
                        })
                        .then(() => {
                            NProgress.done();
                        });
                });
        },

        selectSource(fleet) {
            this.sourceSelected = true;
            this.sourceFleet = fleet;
        },

        selectTarget(fleet) {
            this.targetSelected = true;
            this.targetFleet = fleet;
        },

        copyTeam() {
            let self = this;
            console.log(this.sourceFleet);
            console.log(this.targetFleet);
            console.log(this.selectedTeam);

            const sleep = milliseconds => new Promise(resolve => setTimeout(resolve, milliseconds));

            let message;
            message = {
                title: 'Confirm Move',
                body: "You're moving " + this.selectedTeam.length + ' team members to the fleet ' + this.targetFleet.companyName
            };
            let options = {
                okText: 'Yes, MOVE',
                backdropClose: true,
                cancelText: 'Cancel'
            };

            this.$dialog
                .confirm(message, options)
                .then(async () => {
                    NProgress.start();
                    if (self.selectedTeam.length > 0) {
                        let targetFleetData = {
                            fleetID: self.targetFleet.companyID,
                            fleetName: self.targetFleet.companyName,
                            fleetLocation: self.targetFleet.fleetLocation,
                            avatarUrl: self.targetFleet.avatarUrl,
                            roles: [],
                            isOffline: false,
                            adminStatus: 'none'
                        };
                        var moveTeamMembersBetweenFleets = firebase.functions().httpsCallable('moveTeamMembersBetweenFleets');
                        moveTeamMembersBetweenFleets({
                            targetFleetID: self.targetFleet.companyID,
                            targetFleetData: targetFleetData,
                            teamMembers: self.selectedTeam
                        });
                    }
                    setTimeout(() => {
                        self.clearAll();
                        NProgress.done();
                    }, 1000);
                })
                .catch(() => {
                    // self.clearAll();
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.highlight {
    background: cadetblue;
    padding: 15px;
    color: #fff;
}
.posi {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: #fff;
    z-index: 999;
}
label {
    &.label {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 10px;
        &.tight {
            margin-bottom: 4px;
        }
    }
}
.buttons.results {
    margin-bottom: 4px;
}
</style>
