import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

import NProgress from 'nprogress';

import './assets/css/style.scss';

import BackToTop from 'vue-backtotop';
Vue.use(BackToTop);

// import VueApexCharts from 'vue-apexcharts';
// Vue.use(VueApexCharts);
// Vue.component('apexchart', VueApexCharts);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faChevronUp,
    faChevronDown,
    faChevronRight,
    faEnvelope,
    faPhone,
    faCheck,
    faTimes,
    faChevronLeft,
    faCalendarCheck,
    faStopwatch,
    faSyncAlt,
    faExclamationTriangle,
    faShip,
    faUserGraduate,
    faPen,
    faUsers,
    faUser,
    faListUl,
    faAnchor,
    faLifeRing,
    faCheckCircle,
    faWrench,
    faStar,
    faPlusCircle,
    faCartPlus,
    faMinus,
    faThumbsUp,
    faCopy,
    faCalendar,
    faGlobe,
    faSort,
    faSquare,
    faInfoCircle,
    faCheckSquare,
    faBriefcase,
    faCog,
    faTrashAlt,
    faEdit,
    faTag,
    faTags,
    faChartBar,
    faChartLine,
    faPlus,
    faMoneyBill,
    faMobileAlt,
    faTable,
    faCamera,
    faVideo,
    faSearch,
    faEyeSlash,
    faComment,
    faFileSignature
} from '@fortawesome/free-solid-svg-icons';

import { faAndroid, faApple, faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';

library.add(
    faChevronUp,
    faChevronDown,
    faEnvelope,
    faChevronRight,
    faPhone,
    faFacebookF,
    faCheck,
    faTimes,
    faChevronLeft,
    faCalendarCheck,
    faStopwatch,
    faSyncAlt,
    faExclamationTriangle,
    faUserGraduate,
    faShip,
    faPen,
    faUsers,
    faListUl,
    faAnchor,
    faLifeRing,
    faCheckCircle,
    faWrench,
    faStar,
    faUser,
    faPlusCircle,
    faCartPlus,
    faMinus,
    faThumbsUp,
    faCopy,
    faCalendar,
    faGlobe,
    faSort,
    faSquare,
    faInfoCircle,
    faCheckSquare,
    faBriefcase,
    faCog,
    faTrashAlt,
    faEdit,
    faTags,
    faChartBar,
    faChartLine,
    faMinus,
    faApple,
    faAndroid,
    faGoogle,
    faPlus,
    faMoneyBill,
    faMobileAlt,
    faTable,
    faCamera,
    faVideo,
    faSearch,
    faEyeSlash,
    faComment,
    faFileSignature
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

import ICS from 'vue-ics';
// Vue.use(ICS, options);
Vue.use(ICS);

var AddToCalendar = require('vue-add-to-calendar');
Vue.use(AddToCalendar);

import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
Vue.use(VuejsDialog);

import Datetime from 'vue-datetime';
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css';
Vue.use(Datetime);

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBWQSesEMWOUyu1XfwQGBA3uM4JdD6fGPs',
        libraries: 'places'
    }
});

import InstantSearch from 'vue-instantsearch';

Vue.use(InstantSearch);

import VueSmoothScroll from 'vue2-smooth-scroll';
Vue.use(VueSmoothScroll);

import firebase from 'firebase/compat/app';

import 'firebase/compat/auth'; // for authentication
import 'firebase/compat/storage'; // for storage
//import 'firebase/compat/database';    // for realtime database
import 'firebase/compat/firestore'; // for cloud firestore
//import 'firebase/compat/messaging';   // for cloud messaging
import 'firebase/compat/functions'; // for cloud functions

// Firebase config
// const config = {
//   apiKey: "AIzaSyDCfACjsWTekUlyDrisWs4cktqsD4FS3Dw",
//   authDomain: "dev-offshoresms.firebaseapp.com",
//   databaseURL: "https://dev-offshoresms.firebaseio.com",
//   projectId: "dev-offshoresms",
//   storageBucket: "dev-offshoresms.appspot.com",
//   messagingSenderId: "15976963230"
// };
const config = {
    apiKey: 'AIzaSyAX5rQ__fBkV2Rd-FvtWB7JPnL7-lMvv4s',
    authDomain: 'app-offshoresms.firebaseapp.com',
    projectId: 'app-offshoresms',
    storageBucket: 'app-offshoresms.appspot.com',
    messagingSenderId: '395258391843',
    appId: '1:395258391843:web:d621d56d614be9f9c8b096',
    measurementId: 'G-30MW3C1M6M'
};

// Initialise Cloud Functions
//var functions = firebase.functions();

// Initialize Firebase
firebase.initializeApp(config);

export const db = firebase.firestore();
// date issue fix according to firebase
export const settings = {
    //timestampsInSnapshots: true,
};
db.settings(settings);

//db.enablePersistence({experimentalTabSynchronization:true});
db.enablePersistence({
    synchronizeTabs: true
}).catch(function(err) {
    console.log(err);
    if (err.code == 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
    } else if (err.code == 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
    }
});

Vue.config.productionTip = false;

import VueRouter from 'vue-router';
Vue.use(VueRouter);

router.beforeEach((to, from, next) => {
    const currentUser = firebase.auth().currentUser;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    NProgress.done();

    if (requiresAuth && !currentUser) {
        next('/sign-in');
    } else if (requiresAuth && currentUser) {
        window.scrollTo(0, 0);
        next();
    } else {
        window.scrollTo(0, 0);
        next();
    }
    // window.scrollTo(0, 0);
    // next();
});

firebase.auth().onAuthStateChanged(function(user) {
    new Vue({
        el: '#app',
        store: store,
        router: router,
        render: h => h(App)
    });
});

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
