<template>

    <div class="site-wrapper no-header">

      <div class="container">
        
        <div v-if="isAdmin">

          <div class="notification is-info"><h3>Payment Status</h3></div>
<!-- 
          <div class="select">
            <select v-model="selectedState">
              <option value="all">All States</option>
              <option v-for="state in allstates" :value="state.name" :key="state.id">
                {{ state.name }}
              </option>
            </select>
          </div> -->

          <!--  <div class="select">
            <select v-model="selectedIndustry">
              <option value="all">All Industries</option>
              <option v-for="industry in industries" :value="industry.name" :key="industry.id">
                {{ industry.name }}
              </option>
            </select>
          </div> -->

          <div class="select">
            <select v-model="selectedRole">
              <option value="all">All Plans</option>
              <option v-for="role in roles" :value="role.slug">
                {{ role.name }}
              </option>
            </select>
          </div>
<!-- 
           <div class="select">
            <select v-model="selectedGroup">
              <option value="all">All Groups</option>
              <option v-for="group in groups" :value="group.name">
                {{ group.name }}
              </option>
            </select>
          </div> -->

          <hr>

          <div v-if="users">
            <ol>
              <li :class="{'past':user.isPast}" @click="viewProfile(user.userid)" v-for="(user, index) in filteredUsers">{{user.username}} ({{cleanDate(user.anniversaryDate)}})</li>
            </ol>
          </div>

        </div>

        <div v-else>This page is restricted.</div>

      </div>


      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showProfile}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">User Details: {{selectedUser.username}}</p>
              <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>
            <section class="modal-card-body">       

              <table class="table is-fullwidth">
                <tbody>
                  <tr>
                    <td>Name:</td>
                    <td>{{selectedUser.username}}</td>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <td>{{selectedUser.useremail}}</td>
                  </tr>
                  <tr>
                    <td>Phone:</td>
                    <td>{{selectedUser.userphone}}</td>
                  </tr>
                  <tr>
                    <td>Industry:</td>
                    <td>{{selectedUser.industry}}</td>
                  </tr>
                  <tr>
                    <td>Plan</td>
                    <td>{{selectedUser.userrole}}</td>
                  </tr>
                  <tr>
                    <td>State:</td>
                    <td>{{selectedUser.userstate}}</td>
                  </tr>
                  <tr>
                    <td>Signup Date:</td>
                    <td>{{selectedUser.signUpDate}}</td>
                  </tr>
                  <tr>
                    <td>Anniversary:</td>
                    <td>{{selectedUser.anniversaryDate}}</td>
                  </tr>
                  <tr>
                    <td>Max Vessels:</td>
                    <td>{{selectedUser.maxVessels}}</td>
                  </tr>
                  <tr>
                    <td>Does Diving?</td>
                    <td>{{selectedUser.doesDiving ? 'Yes' : 'No'}}</td>
                  </tr>
                  <tr>
                    <td>Trainer?</td>
                    <td>{{selectedUser.isTrainer ? 'Yes' : 'No'}}</td>
                  </tr>
                  <tr>
                    <td>Vessels</td>
                    <!-- <td>{{selectedUser.myVessels}}</td> -->
                    <td>{{selectedVessels}}</td>
                  </tr>
                  <tr>
                    <td>Last Charge:</td>
                    <td>${{selectedUser.lastCharge}}</td>
                  </tr>
                  <tr>
                    <td>Next Charge:</td>
                    <td>${{selectedUser.nextCharge}}</td>
                  </tr>
                  <tr>
                    <td>Last Payment:</td>
                    <td>{{selectedUser.lastPayment}}</td>
                  </tr>
                  <tr>
                    <td>User ID:</td>
                    <td>{{selectedUser.userid}}</td>
                  </tr>
                  <!-- <tr>
                    <td></td>
                    <td>{{}}</td>
                  </tr> -->
                  <tr>
                    <td>Admin notes:</td>
                    <td>{{selectedUser.adminNotes}}</td>
                  </tr>
                </tbody>
              </table>

            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click.prevent="close">Done</button>
              <button class="button is-warning" @click.prevent="updateProfile(selectedUser.userid)">Update Profile</button>
              <button class="button is-info" @click.prevent="sendAlert(selectedUser.userid,selectedUser.username)">Send Alert</button>
            </footer>
        </div>
      </div>


      




    </div>

</template>




<script>
// @ is an alias to /src
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; 
import * as moment from 'moment';
  import NProgress from 'nprogress';

export default {
  name: 'Demo',

  data: function() {
    return {
      firebaseid: '',
      firebaseemail: '',
      firebasedisplayname: '',
      users: [],
      allstates: [
        { id: "1", name: "Tasmania" },
        { id: "2", name: "Victoria" },
        { id: "3", name: "New South Wales" },
        { id: "4", name: "Queensland" },
        { id: "5", name: "South Australia" },
        { id: "6", name: "Western Australia" },
        { id: "7", name: "Northern Territory" }
      ],
      industries: [
        { id: "1", name: "Fishing"},
        { id: "2", name: "Aquaculture"},
        { id: "3", name: "Commercial Diving"},
        { id: "4", name: "Marine Construction"},
        { id: "5", name: "Charter Vessel"},
        { id: "6", name: "Passenger Vessel"},
        { id: "7", name: "Other"},
      ],
      roles: [
        {slug: 'free', name: 'Free'},
        {slug: 'starter', name: 'Starter'},
        {slug: 'fleet', name: 'Fleet'},
      ],
      groups: [
        {id: '1', name: 'Tasmanian Abalone'},
      ],
      selectedState: 'all',
      selectedIndustry: 'all',
      selectedRole: 'all',
      selectedGroup: 'all',
      showProfile: false,

      selectedUserID: '',
      selectedUser: {
        userid: '',
        username: '',
        useremail: '',
        userphone: '',
        industry: '',
        userrole: '',
        userstate: '',
        signUpDate: '',
        anniversaryDate: '',
        maxVessels: '',
        adminNotes: '',
        doesDiving: '',
        isTrainer: '',
        myVessels: [],
        lastCharge: '',
        nextCharge: '',
        lastPayment: '',
      },

        

    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    userData() {
      return this.$store.getters.getSubscriptionInfo;
    },
    filteredUsers() {
      let self = this;
      let tempArray = this.users;

      if (self.selectedState != 'all') {
        tempArray = tempArray.filter(user => {
          return user.userstate == self.selectedState 
        })
      }

      if (self.selectedIndustry != 'all') {
        tempArray = tempArray.filter(user => {
          return user.industry == self.selectedIndustry 
        })
      }

      if (self.selectedRole != 'all') {
        tempArray = tempArray.filter(user => {
          return user.userrole == self.selectedRole 
        })
      }

      if (self.selectedGroup != 'all') {
        tempArray = tempArray.filter(user => {
          return user.userGroup == self.selectedGroup 
        })
      }

      return tempArray;
    },
    selectedVessels() {
      let self = this;
      let tempVessels = [];
      if (this.selectedUser.userid != '' && this.selectedUser.myVessels != undefined) {
        tempVessels = self.selectedUser.myVessels.map(vessel => vessel.vesselName);
        return tempVessels.join(', ');
      } else {
        return tempVessels;
      }
    },

    isAdmin() {
      return this.$store.getters.getIsAdmin;
    }

  },

  mounted() {
    this.$store.dispatch('setUser');
    this.$store.dispatch('SETSUBSCRIPTIONINFO');
    firebase.auth().onAuthStateChanged( this.onUserLogin ); 
  },

  methods: {
    onUserLogin: function( user ) {
      if (user) {         
        this.firebaseemail = user.email;
        this.firebaseid = user.uid;
        this.firebasedisplayname = this.userData.username;
        this.loadUsers();
      }
    },

    loadUsers: function() {
      let self = this;
      // db.collection('userdata').orderBy('anniversaryDate','asc').where('userrole','==','starter').onSnapshot(function(querySnapshot) {
      db.collection('userdata').orderBy('anniversaryDate','asc').onSnapshot(function(querySnapshot) {
        self.users = [];
        querySnapshot.forEach(function(doc) {
          // self.users.push(doc.data())
          self.users.push({
            userid: doc.id,
            signUpDate: doc.data().signUpDate,
            anniversaryDate: doc.data().anniversaryDate ? doc.data().anniversaryDate.toDate() : new Date(),
            userstate: doc.data().userstate,
            industry: doc.data().industry,
            userrole: doc.data().userrole,
            userGroup: doc.data().userGroup ? doc.data().userGroup : '',
            username: doc.data().username,
            isPast: doc.data().anniversaryDate ? moment().isAfter(moment(doc.data().anniversaryDate.toDate())) : true,
          })
        });
      })
    },

    cleanDate: function(date) {
      return moment(date).format('D MMM, YYYY');
    },

    viewProfile: function(userid) {
      //console.log(userid);
      let self = this;
      NProgress.start();
      db.collection('userdata').doc(userid).get().then(function(doc) {
        //console.log(doc.data());
        //console.log(doc.data().myVessels);
        self.selectedUser.userid = userid;
        self.selectedUser.username = doc.data().username;
        self.selectedUser.useremail = doc.data().useremail;
        self.selectedUser.userphone = doc.data().userphone;
        self.selectedUser.industry = doc.data().industry;
        self.selectedUser.userrole = doc.data().userrole;
        self.selectedUser.userstate = doc.data().userstate;
        self.selectedUser.signUpDate = doc.data().signUpDate ? doc.data().signUpDate.toDate() : '';
        self.selectedUser.anniversaryDate = doc.data().anniversaryDate ? doc.data().anniversaryDate.toDate() : '';
        self.selectedUser.maxVessels = doc.data().maxVessels;
        self.selectedUser.adminNotes = doc.data().adminNotes ? doc.data().adminNotes : '';
        self.selectedUser.doesDiving = doc.data().doesDiving ? doc.data().doesDiving : false;
        self.selectedUser.isTrainer = doc.data().isTrainer ? doc.data().isTrainer : false;
        self.selectedUser.myVessels = doc.data().myVessels;
        self.selectedUser.lastCharge = doc.data().lastCharge;
        self.selectedUser.nextCharge = doc.data().nextCharge;
        self.selectedUser.lastPayment = doc.data().lastPayment;
        self.selectedUser.userGroup = doc.data().userGroup ? doc.data().userGroup : '';
      }).then(function() {
        NProgress.done();
        self.showProfile = true;
      }).catch(function(err) {
        NProgress.done();
        console.log('Error',err);
      });
    },

    close: function() {
      this.selectedUserID = '';
      this.selectedUser = {
        userid: '',
        username: '',
        useremail: '',
        userphone: '',
        industry: '',
        userrole: '',
        userstate: '',
        signUpDate: '',
        anniversaryDate: '',
        maxVessels: '',
        adminNotes: '',
        doesDiving: '',
        isTrainer: '',
        myVessels: [],
        lastCharge: '',
        nextCharge: '',
        lastPayment: '',
      };
      this.showProfile = false;

    },

    updateProfile(id) {
      console.log('Sending UserID:',id);
      this.showProfile = false;
      let self = this;
      this.$router.push({ name: 'UpdateProfile', params: { 
          sentUser: self.selectedUser
        }
      });
    },

    sendAlert(userID, userName) {
      this.$router.push({ name: 'AddAlert', params: { 
          userID: userID,
          userName: userName
        }
      });
    },

    



  }


}
</script>



<style lang="scss" scoped>

  ol {
    li {
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        color: #209cee;
      }
      &.past {
        color: red;
      }
    }
  }

</style>






