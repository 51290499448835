<template>

  <div class="site-wrapper no-header">

    <ul class="scroller">
        <li><router-link to="my-dashboard">Dashboard</router-link></li>
        <li class="is-active"><a aria-current="page">One off call</a></li>

    </ul>
  
    <div class="container">

      <div class="notification is-dark"><h3>Update isActive status on all vessels</h3></div>

      <div class="spacer s30"></div>

      <input class="input" type="text" placeholder="Paste vessel ID.." v-model="inputVesselID">

      <div class="spacer s20"></div>

      <div class="buttons">
        <!-- <button :disabled="inputVesselID == ''" class="button is-danger" @click="runFunction()">Scan for flagged expiry dates</button> -->
        <button class="button is-danger" @click="runFunction()">Update 10 vessels isActive field</button>
        <button class="button is-light" @click="inputVesselID = ''">Clear</button>
      </div>

    </div>


  </div>
  
      

</template>

<script>  

  import { db } from '../main.js';
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth'; 
  import 'firebase/firestore';
  // import 'firebase/compat/functions';
  import NProgress from 'nprogress';
  import * as moment from 'moment'

  export default {

    name: 'OneOffFunctionCall',
    props: {
      

    },
    // mounted: function() {
    //   firebase.auth().onAuthStateChanged( this.onUserLogin );     
    // },
    computed: {
      
    },
    data: function() {
      return {
        inputVesselID: '',
      }
    },
    methods: {
      // onUserLogin: function( user ) {
      //   if (user) {         
      //     this.firebaseid = user.uid;
      //   }
      // },
      
      runFunction() {
        console.log(this.inputVesselID);
        let self = this;
        let message;
        message = {
          title: 'Confirm One-Off Function?',
          body: 'Click YES below proceed.'
        };
        let options = {
          okText: 'YES',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(function(dialog) {

              // NProgress.start();
              // var updateAllVesselsData = firebase.functions().httpsCallable('updateAllVesselsData');
              // updateAllVesselsData({
              //   limit: 1000
              // })
              // .then(function(result) {
              //   // var sanitizedMessage = result.data.text;
              //   // console.log('Result',sanitizedMessage);
              //   // console.log('Message',result.data.message);
              //   NProgress.done();

              // })
              // .catch(function(error) {
              //   console.log('Error',error.text);
              //   NProgress.done();
              // }); 
              // self.simulateCloudFunction();


          })
          .catch(function(error) {
            console.log(error); 
            NProgress.done();
          });      
      },

      async simulateCloudFunction() {
        // const sentVesselID = data.sentVesselID;
        let self = this;
        var alertsFlagged = false;
        var collectionList = ['vesselSchedules','vesselPlantAndEquipment','vesselEquipment','importantDates'];
        // let thirtyDaysFromNow = moment().add(1, 'months');   
        // let warningDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        var now = new Date(); 
        let thirtyDaysFromNow = new Date(now.setDate(now.getDate() + 30));

        await Promise.all([

          await db.collection('vessels').doc(self.inputVesselID).collection('vesselSchedules').get().then((snapOne) => {
            snapOne.forEach((doc) => {
              console.log(doc.data());
              if (doc.data().dueDate.toDate() < thirtyDaysFromNow) {
                alertsFlagged = true;
                console.log('Returned after vesselSchedules');
                return;
              }
            });
          }),

          await db.collection('vessels').doc(self.inputVesselID).collection('vesselPlantAndEquipment').get().then((snapOne) => {
            snapOne.forEach((doc) => {
              let currentHours = doc.data().currentHours ? doc.data().currentHours : 0;
              let dueHours = doc.data().dueHours ? doc.data().dueHours : 0;
              let trackHours = doc.data().trackHours ? doc.data().trackHours : false;
              if (trackHours && currentHours != 0 && currentHours < (dueHours + 50)) {
                alertsFlagged = true;
                console.log('Returned after vesselPlantAndEquipment');
                return;
              }
            });
          }),

          await db.collection('vessels').doc(self.inputVesselID).collection('vesselEquipment').get().then((snapOne) => {
            snapOne.forEach((doc) => {
              if (doc.data().expiryDate.toDate() < thirtyDaysFromNow) {
                alertsFlagged = true;
                console.log('Returned after vesselEquipment');
                return;
              }
            });
          }),

          await db.collection('vessels').doc(self.inputVesselID).collection('importantDates').get().then((snapOne) => {
            snapOne.forEach((doc) => {
              if (doc.data().expiryDate.toDate() < thirtyDaysFromNow) {
                alertsFlagged = true;
                console.log('Returned after importantDates');
                return;
              }
            });
          }),

        ]).finally(() => {
          // using "finally" so even if there are errors, it stops "loading"
          db.collection('vessels').doc(self.inputVesselID).update({
            alertsFlagged: alertsFlagged
          })
          .then(() => {
            NProgress.done();
            self.inputVesselID = '';
            console.log('Flagged function complete.');
          });
        })
        .catch((error) => console.log('Error',error));


        // await Promise.all(collectionList.map(async (coll, index) => {

        //   await db.collection('vessels').doc(self.inputVesselID).collection(coll).get().then((snapOne) => {
        //     snapOne.forEach((doc) => {
        //       console.log(doc.data());
        //       if (doc.data().dueDate.toDate() < thirtyDaysFromNow) {
        //         alertsFlagged;
        //         console.log('Returned after ',coll);
        //         return;
        //       }
        //     });
        //   });

        //   await db.collection('vessels').doc(self.inputVesselID).collection(coll).get().then((snapOne) => {
        //     snapOne.forEach((doc) => {
        //       let currentHours = doc.data().currentHours ? doc.data().currentHours : 0;
        //       let dueHours = doc.data().dueHours ? doc.data().dueHours : 0;
        //       let trackHours = doc.data().trackHours ? doc.data().trackHours : false;
        //       if (trackHours && currentHours != 0 && currentHours < (dueHours + 50)) {
        //         alertsFlagged;
        //         console.log('Returned after ',coll);
        //         return;
        //       }
        //     });
        //   });

        //   await db.collection('vessels').doc(self.inputVesselID).collection(coll).get().then((snapOne) => {
        //     snapOne.forEach((doc) => {
        //       if (doc.data().expiryDate.toDate() < thirtyDaysFromNow) {
        //         alertsFlagged;
        //         console.log('Returned after ',coll);
        //         return;
        //       }
        //     });
        //   });

        //   await db.collection('vessels').doc(self.inputVesselID).collection(coll).get().then((snapOne) => {
        //     snapOne.forEach((doc) => {
        //       if (doc.data().expiryDate.toDate() < thirtyDaysFromNow) {
        //         alertsFlagged;
        //         console.log('Returned after ',coll);
        //         return;
        //       }
        //     });
        //   });

        // }));  

        // await db.collection('vessels').doc(self.inputVesselID).update({
        //   alertsFlagged: alertsFlagged
        // })
        // .then(() => {
        //   console.log('Flagged function complete.');
        // });


      }


    },

  }

</script>



<style lang="scss" scoped>

  .message {
    .message-body {
      background: #fff;
    }
  }
  textarea {
    min-height: 100px !important;
    box-shadow: none;
    padding: 0.6rem 1rem;
    &.notes {
      margin-top: 1rem;
    }
  }
  input[type="text"] {
    padding: 15px 20px;
    height: auto;
  }


</style>