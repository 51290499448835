<template>
    <div class="site-wrapper no-header">
        <div class="container">
            <div class="buttons">
                <button class="button is-info" @click="goBack()">
                    <span><font-awesome-icon icon="chevron-left"/></span> &nbsp;Back &nbsp;
                </button>
            </div>

            <div v-if="isAdmin">
                <div class="notification is-light"><h3>All User Activity</h3></div>

                <div class="tag-wrapper has-text-centered">
                    <div class="tags has-addons has-text-centered">
                        <span class="tag is-danger is-medium"><font-awesome-icon icon="stopwatch"/></span>
                        <span class="tag is-success is-medium">{{ timeNow }}</span>
                    </div>
                </div>
                <div class="spacer s20"></div>

                <div v-if="activity.length">
                    <ul class="listed">
                        <li v-for="(item, index) in activity" :key="index">
                            {{ item.timestamp }}: {{ item.description }}
                            <span v-if="item.userName"> ({{ item.userName }})</span>
                            <span v-if="item.vesselName"> ({{ item.vesselName }})</span>
                            <span v-if="item.postedBy"> ({{ item.postedBy }})</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div v-else>This page is restricted.</div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as moment from 'moment';

export default {
    name: 'Demo',

    data: function() {
        return {
            firebaseid: '',
            firebaseemail: '',
            firebasedisplayname: '',
            activity: [],
            timeNow: 'no time'
        };
    },

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        isAdmin() {
            return this.$store.getters.getIsAdmin;
        }
    },

    mounted() {
        this.$store.dispatch('setUser');
        this.$store.dispatch('SETSUBSCRIPTIONINFO');
        firebase.auth().onAuthStateChanged(this.onUserLogin);
        this.timeNow = moment().format('D MMMM YYYY (h:mm a)');
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                this.firebaseemail = user.email;
                this.firebaseid = user.uid;
                this.firebasedisplayname = this.userData.username;
                this.loadActivity();
            }
        },

        goBack: function() {
            this.$router.go(-1);
        },

        loadActivity: function() {
            let self = this;
            db.collection('revisions')
                .orderBy('revisionDate', 'desc')
                .limit(400)
                .onSnapshot(function(querySnapshot) {
                    self.activity = [];
                    querySnapshot.forEach(function(doc) {
                        //self.activity.push(doc.data())
                        self.activity.push({
                            timestamp: moment(doc.data().revisionDate.toDate()).fromNow(),
                            description: doc.data().description ? doc.data().description : '',
                            vesselName: doc.data().vesselName ? doc.data().vesselName : '',
                            userName: doc.data().userName ? doc.data().userName : '',
                            userID: doc.data().userID ? doc.data().userID : ''
                        });
                    });
                });
        }
    }
};
</script>

<style lang="scss" scoped>
ul.listed {
    margin-left: 8px;
    margin-bottom: 10px;
}
</style>
