<template>
    <div class="site-wrapper no-header">
        <div class="container">
            <div class="notification is-info"><h3>Move fleet content between fleets</h3></div>

            <!--  find the source fleet ============================================  -->
            <div v-if="!sourceSelected" class="notification is-primary">
                <input class="input" v-model="findSource" id="filtername" autocomplete="off" type="text" placeholder="Source fleet name..." />
            </div>

            <div v-if="sourceSelected" class="highlight">{{ sourceFleet.companyName }} ({{ sourceFleet.companyID }})</div>

            <div v-if="findSource.length > 2 && !sourceSelected">
                <div v-for="(fleet, index) in filteredSource" :key="index">
                    <div class="buttons results">
                        <span class="button is-light" @click="selectSource(fleet)">{{ fleet.companyName }} ({{ fleet.primaryUserName }})</span>
                    </div>
                </div>
            </div>

            <div v-if="sourceSelected">
                <!--  find the target fleet ============================================  -->
                <div v-if="!targetSelected" class="notification is-primary">
                    <input class="input" v-model="findTarget" id="filtername" autocomplete="off" type="text" placeholder="Target fleet name..." />
                </div>

                <div v-if="targetSelected" class="highlight">{{ targetFleet.companyName }} ({{ targetFleet.companyID }})</div>

                <div v-if="findTarget.length > 2 && !targetSelected">
                    <div v-for="(fleet, index) in filteredTarget" :key="index">
                        <div class="buttons results">
                            <span class="button is-light" @click="selectTarget(fleet)">{{ fleet.companyName }} ({{ fleet.primaryUserName }})</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="sourceSelected && targetSelected">
                <div class="spacer s30"></div>

                <div class="spacer s30"></div>
                <table class="table is-bordered is-fullwidth">
                    <tr>
                        <th>SMS Collections to Copy</th>
                        <th>Copy?</th>
                    </tr>
                    <tr v-for="(item, idx) in itemsToCopy" :key="`item-${idx}`">
                        <td>{{ item.label }}</td>
                        <td>
                            <input class="is-checkradio" type="checkbox" :id="`item-${idx}`" v-model="item.selected" />
                            <label :for="`item-${idx}`"></label>
                        </td>
                    </tr>
                </table>

                <div class="padded">
                    <input class="is-checkradio" type="checkbox" id="fleetItemTags" v-model="extras.fleetItemTags" />
                    <label for="fleetItemTags">Clone Fleet SMS Item Tags</label>
                </div>

                <div class="padded">
                    <input class="is-checkradio" type="checkbox" id="fleetTeamTags" v-model="extras.fleetTeamTags" />
                    <label for="fleetTeamTags">Clone Fleet Team Tags</label>
                </div>

                <div class="padded">
                    <input disabled class="is-checkradio" type="checkbox" id="fleetRoles" v-model="extras.fleetRoles" />
                    <label for="fleetRoles">Clone Fleet Roles</label>
                </div>
            </div>

            <hr />

            <div v-if="sourceSelected || targetSelected" class="buttons">
                <span class="button is-warning" @click="clearAll()">Clear</span>
            </div>

            <div v-if="sourceSelected && targetSelected" class="buttons">
                <span class="button is-info is-medium" @click="cloneContent()"><font-awesome-icon icon="list-ul" /> &nbsp; Migrate Content</span>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { query } from 'firebase/firestore';
import NProgress from 'nprogress';

const moveCollectionsBetweenFleets = firebase.functions().httpsCallable('moveCollectionsBetweenFleets');

export default {
    name: 'MoveTeamsBetweenFleets',

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        filteredSource() {
            let filter = new RegExp(this.findSource, 'i');
            return this.fleets.filter(fleet => fleet.companyName.match(filter));
        },
        filteredTarget() {
            let filter = new RegExp(this.findTarget, 'i');
            return this.fleets.filter(fleet => fleet.companyName.match(filter));
        },
        finalItemsToCopy() {
            return this.itemsToCopy.filter(m => m.selected);
        }
    },

    data() {
        return {
            fleets: [],
            sourceSelected: false,
            targetSelected: false,
            findSource: '',
            findTarget: '',
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            sourceFleet: {},
            targetFleet: {},
            copyAllDocs: false,
            itemsToCopy: [
                { selected: false, label: 'Fleet Hazards', collection: 'companyHazards', hasVersions: true, versionColl: 'companyHazardsVersions' },
                {
                    selected: false,
                    label: 'Fleet Contacts',
                    collection: 'companyContacts',
                    hasVersions: true,
                    versionColl: 'companyContactsVersions'
                },
                {
                    selected: false,
                    label: 'Fleet Policies',
                    collection: 'companyPolicies',
                    hasVersions: true,
                    versionColl: 'companyPoliciesVersions'
                },
                { selected: false, label: 'Fleet SOPS', collection: 'companySOPS', hasVersions: true, versionColl: 'companySOPSVersions' },
                { selected: false, label: 'Fleet EOPS', collection: 'companyEOPS', hasVersions: true, versionColl: 'companyEOPSVersions' },
                { selected: false, label: 'Fleet Startup Items', collection: 'companyStartupItems', hasVersions: false, versionColl: '' },
                { selected: false, label: 'Fleet Shutdown Items', collection: 'companyShutdownItems', hasVersions: false, versionColl: '' },
                {
                    selected: false,
                    label: 'Fleet Safety Briefing Items',
                    collection: 'companyPassengerInductionItems',
                    hasVersions: false,
                    versionColl: ''
                },
                { selected: false, label: 'Fleet Induction Items', collection: 'companyInductionItems', hasVersions: false, versionColl: '' },
                { selected: false, label: 'Fleet Maintenance Items', collection: 'companyMaintenanceItems', hasVersions: false, versionColl: '' }
                // { selected: false, label: 'Fleet Item Tags', collection: 'fleetItemTags', hasVersions: false, versionColl: '' },
                // { selected: false, label: 'Fleet Team Tags', collection: 'fleetTeamTags', hasVersions: false, versionColl: '' },
                // { selected: false, label: 'Fleet Roles', collection: 'fleetRoles', hasVersions: false, versionColl: '' }
            ],
            extras: {
                fleetItemTags: false,
                fleetTeamTags: false,
                fleetRoles: false
            }
        };
    },

    mounted() {
        this.loadFleets();
    },

    methods: {
        loadFleets: function() {
            let self = this;
            db.collection('companies').onSnapshot(function(querySnapshot) {
                self.fleets = [];
                querySnapshot.forEach(function(doc) {
                    // self.fleets.push(doc.data())
                    let isExpired = doc.data().isExpired ? doc.data().isExpired : false;
                    if (!isExpired)
                        self.fleets.push({
                            companyID: doc.id,
                            companyName: doc.data().companyName,
                            primaryUserName: doc.data().primaryUserName ? doc.data().primaryUserName : '',
                            primaryUserPhone: doc.data().primaryUserPhone ? doc.data().primaryUserPhone : '',
                            primaryUserEmail: doc.data().primaryUserEmail ? doc.data().primaryUserEmail : '',
                            fleetLocation: doc.data().companyLocation ? doc.data().companyLocation : '',
                            avatarUrl: doc.data().newFleetLogo ? doc.data().newFleetLogo : '',
                            adminUsers: doc.data().adminUsers ? doc.data().adminUsers : []
                        });
                });
            });
        },

        clearAll() {
            this.teamMembers = [];
            this.findTarget = '';
            this.findSource = '';
            this.sourceSelected = false;
            this.sourceFleet = '';
            this.targetSelected = false;
            this.targetFleet = '';
            this.copyAllDocs = false;
            this.extras = {
                fleetItemTags: false,
                fleetTeamTags: false,
                fleetRoles: false
            };
        },

        selectSource(fleet) {
            this.sourceSelected = true;
            this.sourceFleet = fleet;
        },

        selectTarget(fleet) {
            this.targetSelected = true;
            this.targetFleet = fleet;
        },

        cloneContent() {
            let self = this;
            console.log(this.sourceFleet);
            console.log(this.targetFleet);
            console.log(this.finalItemsToCopy);

            const sleep = milliseconds => new Promise(resolve => setTimeout(resolve, milliseconds));

            let message;
            message = {
                title: 'Confirm Move',
                body: "You're moving content to the fleet " + this.targetFleet.companyName
            };
            let options = {
                okText: 'Yes, MOVE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(async () => {
                    NProgress.start();
                    if (self.finalItemsToCopy.length > 0) {
                        await Promise.all(
                            self.finalItemsToCopy.map(async item => {
                                // var moveCollectionsBetweenFleets = firebase.functions().httpsCallable('moveCollectionsBetweenFleets');
                                moveCollectionsBetweenFleets({
                                    sourceFleetID: self.sourceFleet.companyID,
                                    targetFleetID: self.targetFleet.companyID,
                                    collectionItem: item
                                });
                                await sleep(500);
                            })
                        );
                    }
                    if (this.extras.fleetItemTags) {
                        let item = { collection: 'fleetItemTags', hasVersions: false };
                        // var moveCollectionsBetweenFleets = firebase.functions().httpsCallable('moveCollectionsBetweenFleets');
                        moveCollectionsBetweenFleets({
                            sourceFleetID: self.sourceFleet.companyID,
                            targetFleetID: self.targetFleet.companyID,
                            collectionItem: item
                        });
                    }
                    if (this.extras.fleetTeamTags) {
                        let item = { collection: 'fleetTeamTags', hasVersions: false };
                        // var moveCollectionsBetweenFleets = firebase.functions().httpsCallable('moveCollectionsBetweenFleets');
                        moveCollectionsBetweenFleets({
                            sourceFleetID: self.sourceFleet.companyID,
                            targetFleetID: self.targetFleet.companyID,
                            collectionItem: item
                        });
                    }
                    if (this.extras.fleetRoles) {
                        // var moveCollectionsBetweenFleets = firebase.functions().httpsCallable('moveCollectionsBetweenFleets');
                        moveCollectionsBetweenFleets({
                            sourceFleetID: self.sourceFleet.companyID,
                            targetFleetID: self.targetFleet.companyID,
                            collectionItem: item
                        });
                    }
                    setTimeout(() => {
                        self.clearAll();
                        NProgress.done();
                    }, 1000);
                })
                .catch(() => {
                    // self.clearAll();
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.highlight {
    background: cadetblue;
    padding: 15px;
    color: #fff;
}
.posi {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: #fff;
    z-index: 999;
}
label {
    &.label {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 10px;
        &.tight {
            margin-bottom: 4px;
        }
    }
}
.buttons.results {
    margin-bottom: 4px;
}
.padded {
    margin-bottom: 1rem;
}
</style>
