<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">Update a Vessel SMS</h3>
                <br />

                <hr />

                <div class="buttons">
                    <router-link class="button is-warning is-medium" to="fix-sms-info">Update Vessel Info</router-link>
                </div>

                <div class="buttons">
                    <router-link class="button is-warning is-medium" to="fix-sms-statements">Update Statements</router-link>
                </div>

                <div class="buttons">
                    <router-link class="button is-warning is-medium" to="fix-sms-hazards">Update Vessel Hazards</router-link>
                </div>

                <div class="buttons">
                    <router-link class="button is-warning is-medium" to="fix-sms-contacts">Update Vessel Contacts</router-link>
                </div>

                <div class="buttons">
                    <router-link class="button is-warning is-medium" to="fix-sms-policies">Update Vessel Policies</router-link>
                </div>

                <div class="buttons">
                    <router-link class="button is-warning is-medium" to="fix-sms-sops">Update Safe Work Procedures</router-link>
                </div>

                <div class="buttons">
                    <router-link class="button is-warning is-medium" to="fix-sms-eops">Update Emergency Procedures</router-link>
                </div>

                <div class="buttons">
                    <router-link class="button is-warning is-medium" to="fix-sms-startups">Update Startup Checks</router-link>
                </div>

                <div class="buttons">
                    <router-link class="button is-warning is-medium" to="fix-sms-maintenance">Update Maintenance Checks</router-link>
                </div>

                <div class="buttons">
                    <router-link class="button is-warning is-medium" to="fix-sms-inductions">Update Induction Checks</router-link>
                </div>

                <div class="buttons">
                    <router-link class="button is-warning is-medium" to="fix-sms-safety-briefings">Update General Safety Briefing Items</router-link>
                </div>

                <div class="buttons">
                    <router-link class="button is-warning is-medium" to="fix-sms-revisions">Add Vessel Revisions</router-link>
                </div>
            </div>
        </div>

        <div class="buttons floating">
            <span class="button is-info is-medium" @click="$router.go(-1)"><font-awesome-icon icon="chevron-left" /> &nbsp;Back</span>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {
    name: 'LandingFixSms',
    components: {},
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {};
    },

    computed: {
        userData() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.userData
                ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2'
                    ? true
                    : false
                : false;
        }
    },

    methods: {
        onUserLogin: function(user) {
            // if (user) {
            // }
        }
    }
};
</script>

<style lang="scss" scoped>
.site-wrapper .container {
    max-width: 850px;
}
</style>
