<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <div class="box">
                    <div class="search-wrapper">
                        <input type="text" class="input" placeholder="Search OffshoreSMS users..." v-model="secureSearchTerm" />
                        <span v-if="attempts > 0" class="tally">{{ attempts }}</span>
                    </div>
                    <div class="spacer s10"></div>
                    <div class="buttons mt-3" v-if="attempts < 5">
                        <button :disabled="secureSearchTerm == ''" class="button is-info" @click="searchUsers()">Search</button>
                        <button v-if="secureSearchTerm != ''" class="button is-light" @click="clearSearch()">Clear</button>
                    </div>
                    <div v-if="attempts < 5">
                        <div v-if="searchResults.length > 0" class="search-results">
                            <hr />
                            <div class="search-result buttons mb-1" v-for="(user, idx) in searchResults">
                                <span class="button is-light" @click="showCrewInfo(user)">
                                    <font-awesome-icon icon="plus" />&nbsp;{{ user.username }}
                                </span>
                            </div>
                        </div>
                        <div v-else>
                            <p v-if="!searching && secureSearchTerm != '' && searchStarted">No users found. Please try another name search.</p>
                        </div>
                    </div>
                    <div v-else class="red mt-4">Too many searches. Please try again later.</div>
                </div>
            </div>
        </div>

        <simple-modal :title="selectedCrew.username" theme="is-primary" @closed="close()" v-if="showCrewModal">
            <div class="basic-info">
                E: {{ selectedCrew.useremail }}<br />
                M: {{ selectedCrew.userphone }}
            </div>
            <div class="spacer s10"></div>
            <div v-if="!localUserData[selectedCrew.objectID]" class="buttons">
                <span class="button is-info" @click="loadCrewData()">Load User Data</span>
            </div>
            <div v-if="loadingCrewData" class="">
                loading...
            </div>
            <div v-else class="">
                <pre>{{ currentUserData }}</pre>
                <hr />
                <!-- <pre>{{ localUserData[selectedCrew.objectID] }}</pre> -->
                <div v-if="localUserData[selectedCrew.objectID]">
                    <table class="table fullwidth">
                        <tbody>
                            <tr>
                                <th>Name:</th>
                                <td>{{ localUserData[selectedCrew.objectID].userdata.username }}</td>
                            </tr>
                            <tr>
                                <th>Voice to Text Tester:</th>
                                <td>
                                    <div class="checkbox-wrapper">
                                        <input
                                            class="is-checkradio"
                                            type="checkbox"
                                            id="searchemails"
                                            v-model="localUserData[selectedCrew.objectID].userdata.showQuickNote"
                                        />
                                        <label for="searchemails">Quick Note Tester</label>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="localUserData[selectedCrew.objectID].adminFleets">
                                <th>Admin Fleets:</th>
                                <td>{{ localUserData[selectedCrew.objectID].adminFleets.map(m => m.companyName).join(', ') }}</td>
                            </tr>
                            <tr v-if="localUserData[selectedCrew.objectID].teamFleets">
                                <th>Team Fleets:</th>
                                <td>{{ localUserData[selectedCrew.objectID].teamFleets.map(m => m.fleetName).join(', ') }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="buttons">
                        <span class="button is-warning" @click="updateCurrentUser()">Update {{ selectedCrew.username }}</span>
                    </div>
                    <div class="spacer s10"></div>
                </div>
                <p v-else class="red">No userdata loaded</p>
            </div>
        </simple-modal>

        <div class="buttons floating">
            <span class="button is-info" @click="$router.go(-1)"><font-awesome-icon icon="chevron-left" /> &nbsp;Back</span>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/firestore';
import NProgress from 'nprogress';
import algoliasearch from 'algoliasearch';
var searchAlgolia = firebase.functions().httpsCallable('searchAlgolia');
var adminRetrieveUserData = firebase.functions().httpsCallable('adminRetrieveUserData');
const SimpleModal = () => import('../reusables/SimpleModal.vue');

export default {
    props: {},
    components: {
        SimpleModal
    },
    mounted() {
        this.searchIndex = this.searchClient.initIndex('app_userdata');
    },
    computed: {
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        }
    },
    data() {
        return {
            selectedUser: {},
            algoliaClient: algoliasearch('789GF6G7HW', 'd62ebfafa89173cbc6de2f213c30cd75'),
            searchClient: algoliasearch('789GF6G7HW', '0dfd28f6ac249c3ebef88e13f6adbc34'),
            showCrewModal: false,
            isAddingCrew: false,
            selectedCrew: {},
            searchTerm: '',
            loadingCrewData: false,
            currentUserData: {},
            showCrewDataModal: false,
            currentCrewData: {},
            errorMessage: '',
            secureSearchTerm: '',
            searching: false,
            searchResults: [],
            searchStarted: false,
            attempts: 0,
            localUserData: {}
        };
    },
    methods: {
        close() {
            this.showCrewModal = false;
            this.selectedCrew = {};
            this.showCrewDataModal = false;
            this.currentCrewData = {};
            this.isAddingCrew = false;
            this.currentUserData = {};
        },
        clearSearch() {
            this.searchResults = [];
            this.searchStarted = false;
            this.secureSearchTerm = '';
        },

        async searchUsers() {
            NProgress.start();
            this.searching = true;
            this.searchStarted = true;
            this.searchResults = [];
            // console.log(this.secureSearchTerm);
            if (this.secureSearchTerm == '') {
                this.searchResults = [];
                this.searching = false;
            } else {
                await searchAlgolia({
                    searchTerm: this.secureSearchTerm,
                    limit: 10
                }).then(result => {
                    // console.log('result', result.data.text);
                    this.searchResults = result.data.text;
                    this.searching = false;
                });
            }
            NProgress.done();
            this.attempts++;
        },

        async forceReloadData() {
            this.loadingCrewData = true;
            await adminRetrieveUserData({
                userID: this.userData.userid,
                crewData: this.selectedCrew.objectID
            })
                .then(result => {
                    // console.log('result', result.data.text);
                    // let crewData = {
                    //     userdata: {},
                    //     adminFleets: [],
                    //     teamFleets: []
                    // };

                    this.currentUserData = result.data.text;
                    this.loadingCrewData = false;
                    this.localUserData[this.selectedCrew.objectID] = result.data.text;
                })
                .catch(err => {
                    console.log(err);
                    this.loadingCrewData = false;
                });
        },

        async loadCrewData() {
            let self = this;
            if (this.localUserData[this.selectedCrew.objectID]) {
                this.currentUserData = this.localUserData[this.selectedCrew.objectID];
            } else {
                this.loadingCrewData = true;
                await adminRetrieveUserData({
                    userID: self.userData.userid,
                    crewData: self.selectedCrew.objectID
                })
                    .then(result => {
                        console.log('result', result.data.text);
                        self.currentUserData = result.data.text;
                        self.loadingCrewData = false;
                        self.localUserData[self.selectedCrew.objectID] = result.data.text;
                    })
                    .catch(err => {
                        console.log(err);
                        self.loadingCrewData = false;
                    });
            }
        },

        maskPhone(num) {
            return 'Ends in "' + num.substring(num.length - 3) + '"';
        },

        maskEmail(email) {
            let start = email.substring(0, 1);
            let domain = email.substring(email.lastIndexOf('@') + 1);
            return start + '------@' + domain;
        },

        showCrewInfo(item) {
            // console.log(item);
            this.selectedCrew = item;
            this.showCrewModal = true;
        },

        makeLetters(displayName) {
            let res = displayName.split(' ');
            if (res.length > 1) {
                return res[0].substring(0, 1) + res[1].substring(0, 1);
            } else {
                return res[0].substring(0, 1);
            }
        },

        async updateCurrentUser() {
            NProgress.start();
            console.log(this.localUserData[this.selectedCrew.objectID]);
            await db
                .collection('userdata')
                .doc(this.localUserData[this.selectedCrew.objectID].userdata.userid)
                .update({
                    showQuickNote: this.localUserData[this.selectedCrew.objectID].userdata.showQuickNote
                })
                .then(() => {
                    NProgress.done();
                    this.close();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.message {
    .message-body {
        background: #fff;
    }
}
textarea {
    min-height: 100px !important;
    box-shadow: none;
    padding: 0.6rem 1rem;
    &.notes {
        margin-top: 1rem;
    }
}
input[type='text'] {
    padding: 15px 20px;
    height: auto;
}
.search-panel__results {
    .searchbox.ais-SearchBox {
        input[type='search'] {
            padding: 35px 20px;
            &.search-input-override {
                height: 4rem;
            }
        }
    }
    ol {
        margin-left: 2rem;
    }
}
.search-panel {
    display: flex;
}

.search-panel__filters {
    flex: 1;
    margin-right: 1em;
}

.search-panel__results {
    flex: 3;
}

.searchbox {
    margin-bottom: 1rem;
    color: red;
}
.ais-SearchBox-form {
    height: 4rem !important;
}
// .ais-SearchBox {
//     input[type='text'] {
//         max-height: none;
//         height: auto;
//         padding: 2rem;
//     }
//     .ais-SearchBox-form {
//         .MySearchBoxInput {
//             max-height: none;
//             height: auto;
//             padding: 2rem;
//         }
//     }
// }
// .ais-SearchBox-input,
// .MySearchBoxInput {
//     max-height: none;
//     height: auto;
//     padding: 2rem;
// }
// .MySearchBox {
//     color: yellow;
//     .MySearchBoxForm {
//         font-size: 2rem;
//         .MySearchBoxInput {
//             border-color: red;
//         }
//     }
// }
.MySearchBox {
    margin-bottom: 1rem;
}

.search-wrapper {
    position: relative;
    .tally {
        position: absolute;
        border-radius: 50%;
        top: 5px;
        right: 5px;
        background: red;
        color: white;
        width: 21px;
        height: 21px;
        font-size: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.crew-data {
    margin-bottom: 6px;
    span {
        font-weight: bold;
        width: 180px;
        display: inline-block;
    }
}
.search-result-user {
    display: flex;
    align-items: center;
    cursor: pointer;
    .search-avatar {
        margin-right: 9px;
        transition: 0.25s;
    }
    .search-name {
        font-size: 1rem;
        color: cadetblue;
        transition: 0.25s;
    }
    &:hover {
        .search-avatar {
            transform: scale(1.1);
        }
        .search-name {
            color: orange;
        }
    }
}
</style>
