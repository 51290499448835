<template>
    <div class="site-wrapper no-header">
        <div class="container">
            <div class="buttons">
                <button class="button is-info" @click="goBack()">
                    <span><font-awesome-icon icon="chevron-left"/></span> &nbsp;Back &nbsp;
                </button>
            </div>

            <h3 class="is-size-5">User Timeline {{ selected ? '- ' + selectedUser.username : '' }}</h3>

            <div v-if="!selected" class="notification is-primary">
                <input class="input" v-model="findName" id="filtername" autocomplete="off" type="text" placeholder="Start typing user name..." />
            </div>

            <div class="box" v-if="findName.length > 1 && !selected">
                <div v-if="filteredNames.length">
                    <ul class="vessel-list">
                        <li v-for="user in filteredNames">
                            <span class="vessel-name">{{ user.userName }}</span>
                            <span @click="loadUserData(user)" class="button is-small is-success">Select</span>
                            <div class="clear"></div>
                        </li>
                    </ul>
                </div>

                <div v-else>
                    <p>No users matched your search.</p>
                </div>
            </div>

            <hr v-if="selected" />

            <!--  <table v-if="selected" class="table is-bordered is-fullwidth">
          <tr>
            <th>Name</th>
            <td>{{selectedUser.username}}</td>
          </tr>
          <tr>
            <th>User ID</th>
            <td>{{selectedUser.userid}}</td>
          </tr>
        </table>

        <div class="spacer s30"></div> -->

            <ul v-if="selected && fullTimeline.length > 0" class="sms-steps steps is-vertical has-content-centered">
                <li v-for="(step, index) in fullTimeline" class="steps-segment">
                    <span class="steps-marker is-primary">
                        <span class="icon"
                            ><span><font-awesome-icon icon="check"/></span
                        ></span>
                    </span>
                    <div class="steps-content">
                        <p class="heading">{{ formatDate(step.entryDate, 'D MMM YYYY (h:mm a)') }}</p>
                        <p class="bold">{{ step.title }}</p>
                        <div v-for="line in step.details.split('\n')">{{ line }}<br /></div>
                    </div>
                </li>
            </ul>

            <div v-if="selected && fullTimeline.length == 0">
                <p>No timeline entries.</p>
            </div>

            <hr />

            <div class="buttons">
                <span v-if="findName != '' || !selected" class="button is-warning" @click="clearUser()">Clear</span>
                <span v-if="findName != '' && selected" class="button is-primary" @click="addEntry()">Add Entry</span>
            </div>
        </div>

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showAddEntry }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">New Timeline Entry</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <input class="input entryTitle" v-model="newEntry.title" type="text" placeholder="Entry title" />

                    <div class="form-group">
                        <textarea v-model="newEntry.details" class="textarea" placeholder="Entry Details (optional)" rows="5"></textarea>
                    </div>

                    <div class="form-group">
                        <span>&nbsp;</span>
                        <datetime v-model="newEntry.entryDate"></datetime>
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Date:</label>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click.prevent="close">Done</button>
                    <!-- <button v-if="isSteve" class="button is-warning" @click.prevent="updateProfile(selectedUser.userid)">Update Profile</button>
              <button v-if="isSteve" class="button is-info" @click.prevent="sendAlert(selectedUser.userid,selectedUser.username)">Send Alert</button> -->
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/firestore';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {
    name: 'UserTimelines',
    props: {
        isAdding: Boolean,
        sentUser: Object
    },
    components: {},
    computed: {
        filteredNames() {
            let filter = new RegExp(this.findName, 'i');
            return this.allUsers.filter(item => item.userName.match(filter));
        },
        isSteve() {
            return this.firebaseid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2';
        }
    },

    mounted: function() {
        let self = this;
        firebase.auth().onAuthStateChanged(this.onUserLogin);
        if (this.isAdding) {
            console.log(self.sentUser);
            self.selectedUser.username = self.sentUser ? self.sentUser.username : '';
            self.selectedUser.userid = self.sentUser ? self.sentUser.userid : '';
            self.selected = true;
            self.findName = self.sentUser ? self.sentUser.username : '';
            self.loadTimeline();
        }
    },

    data: function() {
        return {
            firebaseid: '',
            allUsers: [],
            findName: '',
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            selectedUser: {
                username: '',
                userid: ''
            },
            selected: false,
            fullTimeline: [],
            showAddEntry: false,

            newEntry: {
                id: '',
                title: '',
                details: '',
                entryDate: ''
            }
        };
    },

    methods: {
        onUserLogin: function(user) {
            let self = this;
            if (user) {
                this.firebaseid = user.uid;
                this.loadAllUsers();
            }
        },

        close: function() {
            // this.selectedUser = {};
            this.showAddEntry = false;
        },

        goBack() {
            // this.$router.go(-1);
            this.$router.push({
                name: 'home'
            });
        },

        loadAllUsers: function() {
            let self = this;
            db.collection('userdata').onSnapshot(function(querySnapshot) {
                self.allUsers = [];
                querySnapshot.forEach(function(doc) {
                    self.allUsers.push({
                        userID: doc.id,
                        userName: doc.data().username,
                        userphone: doc.data().userphone ? doc.data().userphone : '',
                        useremail: doc.data().useremail ? doc.data().useremail : ''
                        // owner: doc.data().vesselOwnerName,
                        // lastAuditID: (doc.data().lastAuditID ? doc.data().lastAuditID : 'NA'),
                        // lastAuditDate: (doc.data().lastAuditDate ? self.formatDate(doc.data().lastAuditDate,'dddd D MMMM (h:mm a)') : 'Never'),
                        // sinceDate: (doc.data().lastAuditDate ? moment(doc.data().lastAuditDate.toDate()).fromNow() : 'no audit'),
                        // lastAuditPercent: (doc.data().lastAuditPercent ? doc.data().lastAuditPercent : 0),
                        // lastAuditCode: (doc.data().lastAuditCode ? doc.data().lastAuditCode : 0),
                        // hasBeenCounted: false,
                        // vesselOwnerName: (doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?'),
                        // vesselOwnerID: doc.data().vesselOwnerID,
                    });
                });
            });
        },

        formatDate: function(sentDate, format) {
            // let fixDate = (sentDate ? sentDate.toDate() : new Date());
            // return moment(fixDate).format(format);
            return moment(sentDate).format(format);
        },

        loadUserData: function(user) {
            console.log(user);

            this.selectedUser.username = user.userName;
            this.selectedUser.userid = user.userID;
            this.selectedUser.userphone = user.userphone;
            this.selectedUser.useremail = user.useremail;
            this.selected = true;
            this.loadTimeline();

            // if (!this.isAdding) {
            //   console.log('not adding');
            //   this.selectedUser.username = user.userName;
            //   this.selectedUser.userid = user.userID;
            //   this.selected = true;
            //   this.loadTimeline();
            // } else {
            //   console.log('is adding');
            //   this.selectedUser.username = this.sentUser.username;
            //   this.selectedUser.userid = this.sentUser.userid;
            //   this.selected = true;
            //   this.loadTimeline();
            // }
        },

        loadTimeline() {
            let self = this;
            // console.log(self.selectedUser.userid);
            // db.collection('userdata').doc(self.selectedUser.userid).collection('timeline').get().then((querySnapshot) => {
            db.collection('userdata')
                .doc(self.selectedUser.userid)
                .collection('timeline')
                .orderBy('entryDate', 'desc')
                .onSnapshot(querySnapshot => {
                    self.fullTimeline = [];
                    querySnapshot.forEach(doc => {
                        // console.log(doc.data());
                        self.fullTimeline.push({
                            id: doc.id,
                            timestamp: doc.data().timestamp ? doc.data().timestamp.toDate() : '',
                            entryDate: doc.data().entryDate ? doc.data().entryDate.toDate() : '',
                            title: doc.data().title ? doc.data().title : 'No title',
                            details: doc.data().details ? doc.data().details : ''
                        });
                    });
                });
        },

        clearUser: function() {
            this.findName = '';
            this.selected = false;
            this.selectedUser = {};
        },

        addEntry() {
            let self = this;
            // console.log(this.selectedUser);
            this.$router.push({
                name: 'TimelineEntry',
                params: {
                    currentUser: self.selectedUser
                    // sentLog : log == '' ? {} : log,
                    // editing: log == '' ? false : true
                }
            });
            // this.showAddEntry = true;
        }
    }
};
</script>

<style lang="scss" scoped>
h3 {
    margin: 1rem 0 1.5rem;
}
.select {
    width: 100%;
    max-width: 400px;
    select {
        width: 100%;
    }
}

h4 {
    text-transform: uppercase;
    margin-bottom: 0.8rem;
    font-weight: bold;
}

ul.vessel-list {
    max-width: 320px;
    li {
        margin-bottom: 1rem;
        &:last-of-type {
            margin-bottom: 0;
        }
        .vessel-name {
            float: left;
        }
        .button {
            float: right;
        }
    }
}
.buttons {
    margin-top: 1rem;
}
.button {
    margin-top: 0;
}

input.entryTitle {
    padding: 2rem 1rem;
}

textarea {
    padding: 1rem;
}
</style>
