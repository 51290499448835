<template>
    <div class="site-wrapper no-header">
        <div class="container">
            <h4>All Active Trips</h4>

            <div v-if="allTrips.length > 0">
                <div v-for="(trip, idx) in allTrips" :key="`trip-${idx}`">{{ trip }}</div>
            </div>

            <div class="spacer s100"></div>

            <div class="buttons floating">
                <span class="button is-light" @click="goBack()">Back</span>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import NProgress from 'nprogress';
const SimpleModal = () => import('../reusables/SimpleModal.vue');

export default {
    components: {
        // SimpleModal
    },

    computed: {
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        }
    },

    data() {
        return {
            allTrips: [],
            loadingTrips: false
        };
    },

    mounted() {
        this.loadTrips();
    },

    methods: {
        close() {},

        simpleDate(date) {
            let dateOptions = { timeZone: 'Australia/Sydney', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
            return date == '' ? '' : new Date(date).toLocaleDateString('en-US', dateOptions);
        },

        loadTrips() {
            let self = this;
            this.loadingTrips = true;
            db.collection('activeTripTracker')
                .orderBy('startupTimestamp', 'desc')
                .where('isActive', '==', true)
                .onSnapshot(snap => {
                    self.allTrips = [];
                    snap.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        self.allTrips.push(data);
                    });
                });
            setTimeout(() => {
                this.loadingTrips = false;
            }, 2000);
        }
    }
};
</script>

<style lang="scss" scoped></style>
