<template>

    <div class="modal" v-bind:class="{'is-active':showModal}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-content">
            <button class="delete" aria-label="close" @click.prevent="close"></button>
            <div class="box">
              <!-- <vue-element-loading :active="isLoading" spinner="bar-fade-scale" /> -->
              <p v-if="isLoading"><font-awesome-icon icon="sync-alt" class="fa-spin" />&nbsp;Loading square...</p>
              <div class="slot-content">
                <h5 class="centered">Add a new credit card:</h5>isFleet: {{isFleet}}

                <p>New Token: {{newToken}}</p>
                <p>New Card On File ID: {{newCardOnFileID}}</p>
                <p>Card Holder: {{cardHolder}}</p>
                <p>Msg: {{msg}}</p>

                <form v-if="showForm" id="payment-form">
                    <label class="label">Card Holder Name:</label>
                    <input class="input cardholder-name" type="text" placeholder="Enter card holder name.." maxlength="100" v-model="cardHolder"/>
                    <label class="label">Card Details:</label>
                    <div id="card-container"></div>
                    <button :disabled="payButtonDisabled" class="pay-button button is-primary" id="card-button">Verify Card</button>
                </form>
                <div id="payment-status-container"></div>
              </div>
            </div>
        </div>
        
    </div>


    

</template>


<script>

// import { currentDataStore } from "@/stores/currentdata";
// import { loggedInUserStore } from "@/stores/userdata";

// import { getFirestore, collection, doc, arrayUnion, serverTimestamp, addDoc, setDoc, updateDoc } from "firebase/firestore";
// import { getFunctions, httpsCallable } from "firebase/functions";

// import { db } from '../main.js';
// import firebase from 'firebase/app';
// import 'firebase/auth';

import firebase from 'firebase/compat/app';
import { db } from '../main.js';
import 'firebase/compat/auth'; 
import 'firebase/firestore';
import 'firebase/compat/functions';


import NProgress from 'nprogress';

import { v4 as uuidv4 } from 'uuid';


  export default {
    
    // setup() {
    //     const current_data = currentDataStore();
    //     const user_store = loggedInUserStore();
    //     const db = getFirestore();
    //     const functions = getFunctions();
        
    //     const square_token = import.meta.env.VITE_SQUARE_ACCESS_TOKEN; // sandbox access token (with VITE_ prefix to expose to local client - hide or encode in production)
    //     const locationId = '1GP4THBJTTGA2';  // sandbox test location id
    //     let appid = 'sandbox-sq0idb-LWIOd3UIq_xXYdEeC9Fndg'; // sandbox application id

    //     return {
    //         current_data: current_data,
    //         user_store: user_store,
    //         db: db,
    //         functions: functions,
    //         square_token: square_token,
    //         locationId: locationId,
    //         appid: appid,
    //     }
    // },

    props: {
        title: String,
        theme: String,
        submitText: String,
        isFleet: Boolean,
        squareCustomerID: String,
        userID: String,
        userName: String,
    },
    computed: {
      // currentVesselID() {
      //     return this.current_data.getCurrentVesselID;
      // },
      payButtonDisabled() {
        return this.cardHolder == '' || this.cardHolder == undefined;
      },
      // currentFleetData() {
      //   return this.$store.getters.getCurrentFleetData;
      // },
      // squareCustomerID() {
      //   return this.currentFleetData.squareCustomerID ? this.currentFleetData.squareCustomerID : '';
      // },
      // userData() {
      //   return this.$store.getters.getSubscriptionInfo;
      // },
      currentCompany() {
        return this.$store.getters.getCurrentCompany;
      },
      
    },
    async mounted() {
        // let squareScript = document.createElement('script')
        // squareScript.setAttribute('src', 'https://sandbox.web.squarecdn.com/v1/square.js')
        // document.head.appendChild(squareScript)

        let self = this;

        async function getTokenFromCard(event) {
            event.preventDefault();
            self.attempts++;
            if (self.attempts > 3) {
              self.msg = 'Too many attempts, sorry';
              self.showForm = false;
              setTimeout(() => {
                self.close();
              }, 2500);
            }
            try {
              NProgress.start();
              // const result = await card.tokenize();
              const result = await self.cardItem.tokenize();
              if (result.status === 'OK') {
                self.newToken = result.token;
                console.log(`Payment token is ${result.token}`);
                self.addNewCard();
              }
            } catch (e) {
              NProgress.done();
              console.error(e);
              self.msg = 'Something went wrong. Please check the number or call support.';
            }
        };

        if (window.Square) {
            const payments = Square.payments(this.appid, this.locationId);
            const card = await payments.card();
            console.log('card:',card);
            await card.attach('#card-container');
            self.cardItem = card;
            
            const cardButton = document.getElementById('card-button');
            cardButton.addEventListener('click', getTokenFromCard);
            self.isLoading = false;
        }
        this.cardHolder = this.userName;
    },

    data() {
      return {
        showModal: true,
        showForm: true,
        newToken: '',
        newCardOnFileID: '',
        newCardData: {},
        isLoading: true,
        msg: '',
        attempts: 0,
        cardHolder: '',

        // square_token: import.meta.env.VITE_SQUARE_ACCESS_TOKEN, // sandbox access token (with VITE_ prefix to expose to local client - hide or encode in production)
        square_token: 'EAAAEBDUkzbeE_H0yHMNXArGiW3z0GkneDf5dl4sJy9WiRoPFOCZObi5FqgEi7-s',
        locationId: '1GP4THBJTTGA2',  // sandbox test location id
        appid: 'sandbox-sq0idb-LWIOd3UIq_xXYdEeC9Fndg', // sandbox application 

        cardItem: {},


      }
    },

    methods: {
      
      close() {
  	  	this.showModal = false;
        let result = {
          newCard: {},
          success: false,
          modal: false
        };
        this.$emit('returnResult', result);
      },

      async addNewCard() {
        let self = this;
        this.result = {};
        this.isLoading = true;
        NProgress.start();
        console.log('newToken: ',self.newToken);
        console.log('Square Customer ID',self.squareCustomerID);
        let randomID = uuidv4();
        console.log('randomID',randomID);

        // const addNewCreditCardOnFile = httpsCallable(self.functions, 'addNewCreditCardOnFile');
        // await addNewCreditCardOnFile({
        //   cardFields: {
        //     idempotencyKey: randomID,
        //     sourceId: self.newToken,
        //     card: {
        //       cardholderName: self.cardHolder, // where possible, get the user to input the card holder name
        //       customerId: self.squareCustomerID, // the Square customerId is the OffshoreSMS vesselID
        //       referenceId: self.userData.userid // using the userID of the logged in user as the referenceId
        //     }
        //   }
        // })
        var addNewCreditCardOnFile = firebase.functions().httpsCallable('addNewCreditCardOnFile');
        addNewCreditCardOnFile({
          cardFields: {
            idempotencyKey: randomID,
            sourceId: self.newToken,
            card: {
              cardholderName: self.cardHolder, // where possible, get the user to input the card holder name
              customerId: self.squareCustomerID, // the Square customerId is the OffshoreSMS vesselID
              referenceId: self.userID // using the userID of the logged in user as the referenceId
            }
          }
        })
        .then(async (result) => {
            // handle new card data
            console.log('handling new card');
            let success = result.data.success;
            let text = result.data.text;
            if (success) {
              console.log('returned success');
              let savedCard = JSON.parse(text);
              console.log('Result',savedCard);
              self.newCardData = savedCard.card;
              await self.addNewCardOnFile();
              self.isLoading = false;
              NProgress.done();
              let result = {
                newCard: savedCard,
                success: true,
                modal: false
              };
              this.showModal = false;
              self.$emit('returnResult', result);
            } else {
              console.log('returned fail');
              self.msg = 'We could not add your credit card, sorry. Please check the number or call support.';
              self.isLoading = false;
              NProgress.done();
              // let result = {
              //   newCard: {},
              //   success: false,
              //   modal: false
              // };
              // self.$emit('returnResult', result);
            }
            
        })
        .catch((error) => {
            console.log('Catch Errors:',error);
            self.msg = 'Something went wrong. Please check the number or call support.';
            self.isLoading = false;
            NProgress.done();
        });
        
      },

      async addNewCardOnFile() {
        console.log('saving card with id',this.newCardData.id);
        // const newDoc = await addDoc(collection(this.db, 'vessels/' + this.currentVesselID + '/ccof'),  this.newCardData);
        if (this.isFleet) {
          console.log('saving to fleet');
          db.collection('companies').doc(this.currentCompany.id).collection('ccof').add(this.newCardData);
        } else {
          console.log('saving to userdata');
          db.collection('userdata').doc(this.userID).collection('ccof').add(this.newCardData);
        }
      },



    },
    
  }

</script>



<style lang="scss" scoped>

.slot-content {
  margin-bottom: 1rem;
}
#payment-form {
  margin: 1rem auto;
  max-width: none;
  label {
    margin-bottom: 4px;
  }
  .cardholder-name {
    margin: 0 0 20px;
  }
}
h5 {
  margin-bottom: 1rem;
}
.delete {
  position: absolute;
  right: 10px;
  top: 10px;
}


@media (min-width: 1024px) {
  
  
}


</style>
