<template>
  
  <div>

      <div class="site-wrapper no-header">
      
        <div class="container">

            <h3 class="is-size-5">COPY A DOCUMENT (No forward or trailing slashes)</h3><br>

            <div class="input-wrapper">      
              <label>Source Document Path</label>
              <input class="input" type="text" placeholder="Paste source doc path" v-model="sourcePath">
            </div>

            <div class="clear"></div>

            <div class="input-wrapper">      
              <label>Target Collection Path</label>
              <input class="input" type="text" placeholder="Paste target collection path" v-model="targetPath">
            </div>

            <div class="clear"></div>

            <div>
              <input class="is-checkradio" type="checkbox" id="autoID" v-model="useDocID">
              <label for="autoID">Use Own Doc ID</label>
            </div>
            <!-- <div class="spacer s10"></div> -->

            <div v-if="useDocID">
                <input class="input" type="text" placeholder="Enter doc ID..." v-model="newDocID">
            </div>

            <div class="clear"></div>

            <hr>


            <div class="buttons">
              <span :disabled="pathsAreValid" class="button is-warning" @click="copyVessels">Copy Document</span>
            </div>




        </div>

      </div>


      




  </div>

</template>


<script> 

  import firebase from 'firebase/compat/app';
  import { db } from '../main.js';
  import 'firebase/compat/auth'; 
  import 'firebase/firestore';
  import 'firebase/compat/functions';
  import NProgress from 'nprogress';  
  //import * as moment from 'moment';

  export default {

    components: {
      
    },
    mounted: function() {
      firebase.auth().onAuthStateChanged( this.onUserLogin ); 
    },

    data: function() {
      return {
        sourcePath: '',
        targetPath: '',
        newDocID: '',
        useDocID: false,


      }
    },

    computed: {
      isSteve() {
        return this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' || this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1';
      },
      pathsAreValid() {
        return this.sourcePath == '' || this.targetPath == '';
      }
    },

    methods: {

      onUserLogin: function( user ) {
        if (user) {         
          // console.log(user);
        }
      },

      close: function() {
        
      },

      clear() {
        this.sourcePath = '';
        this.targetPath = '';
        this.newDocID = '';
        this.useDocID = false;
      },

      copyVessels() {
        let self = this;

        console.log(this.sourcePath);
        console.log(this.targetPath);

        let message;
        message = {
          title: 'Confirm Copy Doc?',
          body: 'Click YES below to proceed.'
        };
        let options = {
          okText: 'YES',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(function(dialog) {
              
              NProgress.start();
              var adminCopyDoc = firebase.functions().httpsCallable('adminCopyDoc');
              adminCopyDoc({
                  sourcePath: self.sourcePath,
                  targetPath: self.targetPath,
                  useDocID: self.useDocID,
                  newDocID: self.newDocID,
              })
              .then(function(result) {
                // var sanitizedMessage = result.data.text;
                // console.log('Result',sanitizedMessage);
                // console.log('Message',result.data.message);
                NProgress.done();
                self.clear();

              })
              .catch(function(error) {
                console.log('Error',error.text);
                NProgress.done();
                self.clear();
              }); 


          })
          .catch(function(error) {
            console.log(error); 
            NProgress.done();
          });         






        




      },

      

      

    },

  }



</script> 




<style lang="scss" scoped>

  .container {
    max-width: none;
  }
  
  input[type="text"] {
    width: 90%;
    float: left;
    display: block;
    margin-bottom: 2rem;
    // &:after {
    //   content: "\f16c";
    //   font-family: "Font Awesome 5 Brands";
    //   color: red;
    // }
    // &.valid:after {

    // }
  }
  .icon-wrap {
    display: block;
    float: left;
    height: 35px;
    margin-left: 10px;
    font-size: 1.5rem;
    color: red;
    &.valid {
      color: green;
    }
  }

  input[type="text"] {
    padding: 15px 20px;
    height: auto;
    margin-top: 6px;
  }
  

</style> 