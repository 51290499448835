<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">Fix SMS Revisions</h3>
                <br />

                <div v-if="!isSelected" class="notification is-primary">
                    <input
                        class="input"
                        v-model="findName"
                        id="filtername"
                        autocomplete="off"
                        type="text"
                        placeholder="Start typing vessel name..."
                    />
                </div>

                <div class="buttons">
                    <span v-if="findName != ''" class="button is-warning" @click="clearVessel()">Clear</span>
                </div>

                <hr />

                <div v-if="selectedVessel != ''" class="highlight">{{ selectedVessel.vesselName }} ({{ selectedVessel.vesselOwnerName }})</div>

                <div v-if="findName != '' && !isSelected">
                    <div v-for="(vessel, index) in filteredNames" :key="index">
                        <div class="buttons">
                            <span class="button is-light" @click="selectVessel(vessel)">{{ vessel.vesselName }} ({{ vessel.vesselOwnerName }})</span>
                        </div>
                    </div>
                </div>

                <div class="clear"></div>

                <div v-if="findName != '' && isSelected">
                    <h4 class="icon">All Revisions in this SMS</h4>
                    <div class="clear"></div>

                    <table v-if="smsDocs.length" class="table is-bordered is-fullwidth">
                        <tr>
                            <th>Title</th>
                            <th>Edit</th>
                        </tr>
                        <tr v-for="(item, index) in smsDocs" :key="index">
                            <td>
                                <span>{{ item.revision }}</span>
                            </td>
                            <td>
                                <div class="buttons">
                                    <!-- <span class="button is-primary" @click="editItem(item)">Update</span> -->
                                    <span class="button is-danger" @click="deleteItem(item)">Delete</span>
                                </div>
                            </td>
                        </tr>
                    </table>

                    <div class="spacer s30"></div>
                </div>

                <div class="clear"></div>
                <div class="spacer s30"></div>

                <div class="buttons">
                    <span v-if="findName != '' && isSelected" class="button is-primary is-medium" @click="addItem()">Add a Revision</span>
                    <span class="button is-info is-medium" @click="$router.go(-1)"><font-awesome-icon icon="chevron-left" /> &nbsp;Back</span>
                </div>
            </div>
        </div>

        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': addItemModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">Add an Item</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <input class="input" type="text" placeholder="Type revision..." v-model="newItem.revision" />

                    <div class="spacer s10"></div>
                    <input class="input" type="text" placeholder="Type userName..." v-model="newItem.userName" />

                    <div class="spacer s10"></div>
                    <input class="input" type="text" placeholder="Type userID..." v-model="newItem.userID" />

                    <div class="spacer s20"></div>
                    <div class="form-group-reg">
                        <label for="timestamp">Revision Date</label>
                        <datetime input-id="timestamp" placeholder="Revision date" auto v-model="newItem.timestamp"></datetime>
                    </div>

                    <div class="spacer s30"></div>
                </section>
                <footer class="modal-card-foot">
                    <button :disabled="newItem.revision == ''" class="button is-primary" @click.prevent="saveNewItem()">Save New Item</button>
                    <button class="button is-danger" @click.prevent="close">Cancel</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {
    name: 'FixSmsRevisions',
    components: {},
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            poolOfDocs: [],
            sourcePath: '',
            targetPath: '',
            selectedItems: [],

            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            allVessels: [],
            isSelected: false,
            findName: '',
            hideExpired: true,
            selectedVessel: '',
            selectedItem: '',
            showDetailsModal: false,

            smsDocs: [],
            selectedEditItem: { revision: '', userName: '', userID: '' },
            showEditItemModal: false,
            addItemModal: false,
            newItem: {
                revision: '',
                userName: '',
                userID: '',
                timestamp: ''
            }
        };
    },

    computed: {
        userData() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.userData
                ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2'
                    ? true
                    : false
                : false;
        },
        pathsAreValid() {
            return this.sourcePath == '' || this.targetPath == '';
        },
        filteredVessels() {
            return this.hideExpired ? this.allVessels.filter(vessel => !vessel.isExpired).map(vess => vess) : this.allVessels;
        },
        filteredNames() {
            let filter = new RegExp(this.findName, 'i');
            return this.filteredVessels.filter(user => user.vesselName.match(filter));
        },
        selectedItemIDs() {
            return this.selectedItems.map(item => item.id);
        }
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                this.loadAllVessels();
            }
        },

        close: function() {
            this.addItemModal = false;
            this.newItem = {
                revision: '',
                userName: '',
                userID: '',
                timestamp: ''
            };
        },

        deleteItem(item) {
            console.log(item);
            let self = this;
            let message;
            message = {
                title: 'Confirm Delete?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES, DELETE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    var adminDeleteClientRevision = firebase.functions().httpsCallable('adminDeleteClientRevision');
                    adminDeleteClientRevision({
                        vesselID: self.selectedVessel.vesselID,
                        docID: item.id,
                        deleteCollection: 'vesselRevisions'
                    })
                        .then(function(result) {
                            NProgress.done();
                        })
                        .catch(function(error) {
                            console.log('Error', error.text);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        addItem() {
            this.addItemModal = true;
        },

        isNumber: function($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        saveNewItem() {
            console.log(this.newItem);
            let self = this;
            NProgress.start();
            var adminSaveNewClientRevision = firebase.functions().httpsCallable('adminSaveNewClientRevision');
            adminSaveNewClientRevision({
                collection: 'vesselRevisions',
                vesselID: self.selectedVessel.vesselID,
                vesselName: self.selectedVessel.vesselName,
                revision: self.newItem.revision,
                userName: self.newItem.userName,
                userID: self.newItem.userID,
                timestamp: self.newItem.timestamp
            })
                .then(function(result) {
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        },

        displaySmsDocs() {
            let self = this;
            db.collection('vessels')
                .doc(this.selectedVessel.vesselID)
                .collection('vesselRevisions')
                .orderBy('timestamp', 'desc')
                .onSnapshot(querySnapshot => {
                    self.smsDocs = [];
                    querySnapshot.forEach(doc => {
                        self.smsDocs.push({
                            id: doc.id,
                            revision: doc.data().revision ? doc.data().revision : 'no revision',
                            userID: doc.data().userID ? doc.data().userID : '',
                            userName: doc.data().userName ? doc.data().userName : ''
                        });
                    });
                });
        },

        clearDocs() {
            this.selectedItems = [];
        },

        clearVessel: function() {
            this.findName = '';
            this.isSelected = false;
            this.selectedVessel = '';
        },

        selectVessel(vessel) {
            console.log(vessel);
            this.selectedVessel = vessel;
            this.isSelected = true;
            this.displaySmsDocs();
        },

        showDetails(item) {
            this.selectedItem = item;
            this.showDetailsModal = true;
        },

        loadAllVessels: function() {
            let self = this;
            db.collection('vessels')
                .orderBy('vesselOwnerName', 'asc')
                .onSnapshot(function(querySnapshot) {
                    self.allVessels = [];
                    querySnapshot.forEach(doc => {
                        self.allVessels.push({
                            vesselID: doc.id,
                            vesselName: doc.data().vesselName,
                            vesselOwnerName: doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?',
                            vesselOwnerID: doc.data().vesselOwnerID
                        });
                    });
                });
        },

        formatDate: function(sentDate, format) {
            let fixDate = sentDate ? sentDate.toDate() : new Date();
            //console.log("Fix date:",fixDate);
            return moment(fixDate).format(format);
        }
    }
};
</script>

<style lang="scss" scoped>
.site-wrapper .container {
    max-width: 850px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
.traffic-light {
    position: relative;
    span {
        float: left;
        margin-right: 10px;
    }
    .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
            background: red;
            &.active {
                background: red;
            }
        }
        &.orange {
            background: orange;
            &.active {
                background: orange;
            }
        }
        &.green {
            background: #03d203;
            &.active {
                background: #03d203;
            }
        }
    }
}

// input[type="text"] {
//   width: 90%;
//   float: left;
//   display: block;
//   margin-bottom: 2rem;
//   // &:after {
//   //   content: "\f16c";
//   //   font-family: "Font Awesome 5 Brands";
//   //   color: red;
//   // }
//   // &.valid:after {

//   // }
// }
.icon-wrap {
    display: block;
    float: left;
    height: 35px;
    margin-left: 10px;
    font-size: 1.5rem;
    color: red;
    &.valid {
        color: green;
    }
}
label {
    margin-bottom: 10px;
    display: block;
}
.is-checkradio[type='checkbox'] {
    opacity: 1;
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
    padding: 10px;
    margin-top: 10px;
    margin-left: 10px;
}

.site-wrapper .container {
    max-width: 1050px;
}
.buttons {
    margin-bottom: 5px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
input[type='text'] {
    padding: 2rem 1rem;
}
.form-group {
    label {
        top: auto;
        bottom: -25px;
    }
}
.traffic-light {
    position: relative;
    span {
        float: left;
        margin-right: 10px;
    }
    .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
            background: red;
            &.active {
                background: red;
            }
        }
        &.orange {
            background: orange;
            &.active {
                background: orange;
            }
        }
        &.green {
            background: #03d203;
            &.active {
                background: #03d203;
            }
        }
    }
}
.highlight {
    background: dodgerblue;
    padding: 15px;
    color: #fff;
}
.modal {
    .modal-card {
        .modal-card-head {
            z-index: 999 !important;
        }
        .modal-card-foot {
            z-index: 999 !important;
        }
    }
}
input[type='text'],
input[type='tel'] {
    padding: 2rem 1rem;
}

textarea {
    min-height: 120px;
    height: 400px;
    margin-top: 10px;
    padding: 1.5rem 1rem;
}
table {
    td {
        padding-bottom: 21px;
        &.is-updated {
            color: green;
            font-weight: bold;
        }
        .small {
            font-size: 0.8rem;
            margin-top: 5px;
            text-align: center;
        }
    }
}
.show-details {
    cursor: pointer;
    &:hover {
        color: dodgerblue;
    }
}
</style>
