<template>
    <div class="site-wrapper no-header">
        <div class="container">
            <div v-if="isAdmin">
                <div class="notification is-info">
                    <h3>All App Users</h3>
                </div>

                <div class="select">
                    <select v-model="selectedState">
                        <option value="all">All States</option>
                        <option v-for="state in allstates" :value="state.name" :key="state.id">
                            {{ state.name }}
                        </option>
                    </select>
                </div>

                <div class="select">
                    <select v-model="selectedIndustry">
                        <option value="all">All Industries</option>
                        <option v-for="industry in industries" :value="industry.name" :key="industry.id">
                            {{ industry.name }}
                        </option>
                    </select>
                </div>

                <div class="select">
                    <select v-model="selectedRole">
                        <option value="all">All Plans</option>
                        <option v-for="role in roles" :value="role.slug" :key="role.slug">
                            {{ role.name }}
                        </option>
                    </select>
                </div>

                <div class="select">
                    <select v-model="selectedGroup">
                        <option value="all">All Groups</option>
                        <option v-for="group in groups" :value="group.name" :key="group.name">
                            {{ group.name }}
                        </option>
                    </select>
                </div>

                <hr />

                <div class="form-group-reg">
                    <div class="checkbox-wrapper">
                        <input class="is-checkradio" type="checkbox" id="searchemails" v-model="searchEmails" />
                        <label for="searchemails">Search Emails</label>
                    </div>
                </div>

                <div class="form-group-reg">
                    <div class="checkbox-wrapper">
                        <input class="is-checkradio" type="checkbox" id="searchPhone" v-model="searchPhone" />
                        <label for="searchPhone">Search Phone</label>
                    </div>
                </div>

                <div class="form-group-reg">
                    <div class="checkbox-wrapper">
                        <input class="is-checkradio" type="checkbox" id="searchHomeport" v-model="searchHomeport" />
                        <label for="searchHomeport">Search Home Port</label>
                    </div>
                </div>

                <div v-if="!isSelected" class="notification is-primary">
                    <input class="input" v-model="findName" id="filtername" autocomplete="off" type="text" placeholder="Start typing user name..." />
                </div>

                <div class="buttons">
                    <span v-if="findName != ''" class="button is-warning" @click="clearUser()">Clear</span>
                </div>

                <div v-if="bulkListIDs.length > 0" class="buttons floating">
                    <span class="button is-danger is-medium" @click="bulkFixProfiles()">Bulk Fix {{ bulkListIDs.length }} Users</span>
                </div>

                <!-- <div class="box" v-if="findName.length > 1 && !isSelected">

              <div v-if="filteredNames.length">
                <ul class="vessel-list">
                  <li v-for="(vessel, index) in filteredNames" :key="index">
                    <span class="vessel-name">{{vessel.vesselName}}</span>
                    <span @click="loadVesselData(vessel)" class="button is-small is-success">Select</span>
                    <div class="clear"></div>
                  </li>
                </ul>
              </div>

              <div v-else>
                <p>No vessels matched your search.</p>
              </div>

            </div> -->

                <!-- {{selectedUsers}} -->

                <!-- <div class="buttons" v-if="selectedUsers.length > 0">
            <span class="button is-danger" @click="fixMyVesselsBug(selectedUsers)">Fix My Vessels Bug ({{selectedUsers.length}})</span>
          </div> -->

                <div v-if="users">
                    <ul>
                        <li v-for="(user, index) in filteredNames" :key="index">
                            <div :class="[user.signupPlatform, user.isExpired ? 'red' : '']">
                                <div>
                                    <span @click="viewProfile(user.userid)"
                                        >{{ getUserNum(index) }}.
                                        {{
                                            searchEmails
                                                ? user.useremail
                                                : searchPhone
                                                ? user.username + ' (' + user.userphone + ')'
                                                : searchHomeport
                                                ? user.username + ' (' + user.homeport + ')'
                                                : user.username
                                        }}
                                        ({{ cleanDate(user.signUpDate) }})
                                        {{ user.lastAppLoginVersion == 'not set' ? '' : '[' + user.lastAppLoginVersion + ']' }}</span
                                    >
                                    <span v-if="user.isExpired" class="expired"><font-awesome-icon icon="exclamation-triangle"/></span>
                                    <!-- <span
                                        @click="checkIfCustomerExists(user)"
                                        v-if="user.squareCustomerID == ''"
                                        class="button is-small is-warning square"
                                        ><font-awesome-icon icon="exclamation-triangle"
                                    /></span>
                                    <span v-else @click="checkIfCustomerExists(user)" class="button is-small is-success square"
                                        ><font-awesome-icon icon="check-circle"
                                    /></span>
                                    <span v-if="isSteve" class="button is-info is-small" @click.prevent="loadSquareSettings(user)">
                                        Square
                                    </span> -->

                                    <span
                                        v-if="isSteve"
                                        class="button is-small square"
                                        :class="[user.hasIndex ? 'is-success' : user.indexMissing ? 'is-warning' : 'is-light']"
                                        @click.prevent="checkAlgoliaSingle(user)"
                                    >
                                        <font-awesome-icon :icon="user.hasIndex ? 'check' : user.indexMissing ? 'exclamation-triangle' : 'minus'" />
                                        <!-- {{ !selectedUserHasIndex ? 'Check Algolia' : 'Has Algolia Index' }} -->
                                    </span>
                                    <span class="button is-small is-info" @click="fixIndex(user)">Fix Algolia</span>

                                    <!-- <span
                                        v-if="isSteve && !user.profileFixed"
                                        class="button is-danger is-small square"
                                        @click.prevent="fixPublicProfile(user)"
                                    >
                                        Fix Profile
                                    </span>
                                    <span v-if="isSteve && user.profileFixed" class="button is-light is-small square">
                                        <font-awesome-icon icon="user" />
                                    </span> -->

                                    <span :class="[user.operatingSystem == 'ios' || user.operatingSystem == 'not set' ? 'green' : 'red']">
                                        &nbsp; <font-awesome-icon :icon="['fab', osIcon[user.operatingSystem]]" />
                                    </span>

                                    <span
                                        v-if="isSteve && !bulkListIDs.includes(user.userid) && !user.profileFixed"
                                        class="button is-warning is-small square"
                                        @click.prevent="addToBulkList(user)"
                                    >
                                        Bulk Add
                                    </span>
                                    <span
                                        v-if="isSteve && bulkListIDs.includes(user.userid) && !user.profileFixed"
                                        class="button is-dark is-small square"
                                    >
                                        <font-awesome-icon icon="check-circle" />
                                    </span>
                                </div>
                                <div style="width: 50px;"></div>
                            </div>
                        </li>
                    </ul>
                </div>

                <!-- <div v-if="users">
            <ul>
              <li v-for="(user, index) in filteredNames" :key="index">
                <div v-if="!user.isExpired">
                  <div>{{user.username.split(" ").length == 0 ? user.username : user.username.split(" ")[0]}}, {{user.useremail}}</div>
                </div>
              </li>
            </ul>
          </div>  -->

                <!-- <div v-if="users">
              <ol>
                <li :class="[user.favouriteVesselID != '' ? 'fixed' : '']" :key="index" @click="viewProfile(user.userid)" v-for="(user, index) in filteredUsers">{{user.username}} ({{cleanDate(user.signUpDate)}}) {{user.favouriteVesselID != '' ? ' - Fixed' : ''}}</li>
              </ol>
            </div> -->
            </div>

            <div v-else>This page is restricted.</div>
        </div>

        <simple-modal title="Send an SMS to a User" theme="is-primary" @closed="close()" v-if="showSmsModal">
            <textarea type="text" class="textarea padded" v-model="textMessage"></textarea>

            <div class="spacer s20"></div>

            <div class="buttons">
                <span class="button is-medium is-info" @click="sendSMS()">Send Text Message</span>
            </div>

            <div class="spacer s10"></div>

            <ul v-if="selectedUser.adminTextMessages">
                <li v-for="msg in selectedUser.adminTextMessages">
                    {{ cleanDate(msg.dateSent) }} <br />
                    {{ msg.message }}
                </li>
            </ul>
        </simple-modal>

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showProfile }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">User Details: {{ selectedUser.username }}</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <table class="table is-fullwidth">
                        <tbody>
                            <tr>
                                <td>Name:</td>
                                <td>{{ selectedUser.username }}</td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td v-if="isSteve">
                                    <a :href="'mailto:' + selectedUser.useremail + '?Subject=Message%20from%20Offshore%20SMS%20Team'">{{
                                        selectedUser.useremail
                                    }}</a>
                                </td>
                                <td v-else>
                                    <span>{{ selectedUser.useremail }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>User ID:</td>
                                <td>
                                    <span class="button is-info" @click="copyLink(selectedUser.userid)"
                                        ><font-awesome-icon icon="copy" />&nbsp; {{ selectedUser.userid }}</span
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td>squareCustomerID:</td>
                                <td>
                                    <span>{{ selectedUser.squareCustomerID }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Phone:</td>
                                <td v-if="isSteve">
                                    <a :href="'tel:' + selectedUser.userphone">{{ selectedUser.userphone }}</a>
                                </td>
                                <td v-else>
                                    <span>{{ selectedUser.userphone }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Possible Locations:</td>
                                <td>{{ getCountry(selectedUser.userphone) }}</td>
                            </tr>
                            <tr>
                                <td>Industry:</td>
                                <td>{{ selectedUser.industry }}</td>
                            </tr>
                            <!-- <tr>
                      <td>Plan</td>
                      <td>{{selectedUser.userrole}}</td>
                    </tr> -->
                            <tr>
                                <td>State:</td>
                                <td>{{ selectedUser.userstate }}</td>
                            </tr>
                            <tr>
                                <td>Signup Date:</td>
                                <td>{{ selectedUser.signUpDate }}</td>
                            </tr>
                            <tr>
                                <td>Signup Platform</td>
                                <td>
                                    <span :class="selectedUser.signupPlatform"
                                        >{{ selectedUser.signupPlatform }} ({{ selectedUser.operatingSystem }})</span
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td>Last app version</td>
                                <!-- <td>{{ selectedUser.hasLoggedInOnApp ? "Yes" : "-" }}</td> -->
                                <td>{{ selectedUser.lastAppLoginVersion }}</td>
                            </tr>

                            <!-- profilingInfo: {
                      selectedTypes: self.selectedTypes,
                      selectedIndustries: self.selectedIndustries,
                      numVessels: self.numVessels,
                      numCrew: self.numCrew,
                      appAnswers: self.selectedAppAnswers,
                      findAnswers: self.selectedFindAnswers
                    } -->

                            <!-- <tr>
                      <td>Anniversary:</td>
                      <td>{{selectedUser.cleanAnniversaryDate}}</td>
                    </tr> -->
                            <!-- <tr>
                      <td>Max Vessels:</td>
                      <td>{{selectedUser.maxVessels}}</td>
                    </tr>
                    <tr>
                      <td>Does Diving?</td>
                      <td>{{selectedUser.doesDiving ? 'Yes' : 'No'}}</td>
                    </tr>
                    <tr>
                      <td>Trainer?</td>
                      <td>{{selectedUser.isTrainer ? 'Yes' : 'No'}}</td>
                    </tr> -->

                            <tr>
                                <td colspan="2">
                                    <div class="spacer s10"></div>
                                    <span class="heading">Profiling information from user</span>
                                </td>
                            </tr>
                            <tr>
                                <td>User Segments</td>
                                <td v-if="selectedUser.profilingInfo.selectedTypes">
                                    <div v-for="item in selectedUser.profilingInfo.selectedTypes">
                                        {{ item }}
                                    </div>
                                </td>
                                <td v-else>-</td>
                            </tr>
                            <tr>
                                <td>Industries</td>
                                <td v-if="selectedUser.profilingInfo.selectedIndustries">
                                    <div v-for="item in selectedUser.profilingInfo.selectedIndustries">
                                        {{ item }}
                                    </div>
                                </td>
                                <td v-else>-</td>
                            </tr>
                            <tr>
                                <td>Vessels in Operation</td>
                                <td v-if="selectedUser.profilingInfo.numVessels">
                                    {{ selectedUser.profilingInfo.numVessels }}
                                </td>
                                <td v-else>-</td>
                            </tr>
                            <tr>
                                <td>Crew in Operation</td>
                                <td v-if="selectedUser.profilingInfo.numCrew">
                                    {{ selectedUser.profilingInfo.numCrew }}
                                </td>
                                <td v-else>-</td>
                            </tr>
                            <tr>
                                <td>Downloaded App?</td>
                                <td v-if="selectedUser.profilingInfo.appAnswers">
                                    <div v-for="item in selectedUser.profilingInfo.appAnswers">
                                        {{ item }}
                                    </div>
                                </td>
                                <td v-else>-</td>
                            </tr>
                            <tr>
                                <td>How they found us?</td>
                                <td v-if="selectedUser.profilingInfo.findAnswers">
                                    <div v-for="item in selectedUser.profilingInfo.findAnswers">
                                        {{ item }}
                                    </div>
                                </td>
                                <td v-else>-</td>
                            </tr>

                            <tr>
                                <td colspan="2">
                                    <div class="spacer s10"></div>
                                    <span class="heading">Other information</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Own Vessels</td>
                                <td>
                                    <div v-for="vessel in selectedUser.myVessels">
                                        {{ vessel.vesselName }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Own Vessel Count</td>
                                <td>{{ selectedUser.myVessels.length }}</td>
                            </tr>
                            <tr>
                                <td>Vessels I Master</td>
                                <td>
                                    <div v-for="vessel in selectedUser.masteredVessels">
                                        {{ vessel }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Vessels I Crew</td>
                                <td>
                                    <div v-for="vessel in selectedUser.crewedVessels">
                                        {{ vessel }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>My Fleets</td>
                                <td>
                                    <div v-for="fleet in selectedUser.myCompanies">
                                        {{ fleet }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Favourite Vessel Name</td>
                                <td>{{ selectedUser.favouriteVesselName }}</td>
                            </tr>
                            <tr>
                                <td>Favourite Fixed?</td>
                                <td>{{ selectedUser.favouriteFixed ? 'Yes' : 'NO!' }}</td>
                            </tr>
                            <!-- <tr>
                      <td>Favourite Vessel ID</td>
                      <td>{{selectedUser.favouriteVesselID}}</td>
                    </tr> -->
                            <!-- <tr>
                      <td>Logs / Company / Mine</td>
                      <td>{{selectedUser.favouriteVesselCanDoLogs}} / {{selectedUser.favouriteVesselIsCompany}} / {{selectedUser.favouriteVesselIsMine}}</td>
                    </tr> -->
                            <!-- <tr>
                      <td>Last Charge:</td>
                      <td>${{selectedUser.lastCharge}}</td>
                    </tr>
                    <tr>
                      <td>Next Charge:</td>
                      <td>${{selectedUser.nextCharge}}</td>
                    </tr>
                    <tr>
                      <td>Last Payment:</td>
                      <td>{{selectedUser.lastPayment}}</td>
                    </tr> -->
                            <!-- <tr>
                      <td></td>
                      <td>{{}}</td>
                    </tr> -->
                            <tr>
                                <td>Admin notes:</td>
                                <td>{{ selectedUser.adminNotes }}</td>
                            </tr>

                            <!-- <tr>
                  <td>Actions</td>
                  <td>
                    <span class="button is-success">Send Renewal Email Offer</span>
                  </td>
                </tr> -->
                        </tbody>
                    </table>
                </section>
                <footer class="modal-card-foot">
                    <div>
                        <button class="button is-primary" @click.prevent="close">
                            Done
                        </button>
                    </div>
                    <div v-if="isSteve">
                        <button class="button is-danger" @click.prevent="fixFavourite(selectedUser)">
                            Fix Fave
                        </button>
                        <button class="button is-link" @click.prevent="loadSquareSettings(selectedUser)">
                            SQUARE
                        </button>
                        <button class="button is-warning" @click.prevent="updateProfile(selectedUser.userid)">
                            Update Profile
                        </button>
                        <button class="button is-warning" @click.prevent="updateEmail(selectedUser.userid)">
                            Update Email
                        </button>
                        <button class="button is-warning" @click.prevent="updatePhone(selectedUser.userid)">
                            Update Phone No
                        </button>
                        <button class="button is-info" @click.prevent="addEntry(selectedUser)">
                            Timeline
                        </button>
                        <button class="button is-danger" @click.prevent="expireUser(selectedUser)">
                            Expire!
                        </button>
                        <button
                            class="button"
                            :class="[selectedUserHasIndex ? 'is-success' : 'is-warning']"
                            @click.prevent="checkAlgolia(selectedUser.userid)"
                        >
                            {{ !selectedUserHasIndex ? 'Check Algolia' : 'Has Index' }}
                        </button>
                        <button v-if="!selectedUserHasIndex" class="button is-dark" @click.prevent="addAlgoliaIndex(selectedUser)">
                            Add Index
                        </button>
                        <button v-if="isSteve" class="button is-dark" @click.prevent="showSMS()">
                            Send SMS
                        </button>
                        <button class="button is-danger" @click.prevent="endSessions(selectedUser.userid)">
                            End Sessions
                        </button>
                        <button
                            v-if="mondayStatus != undefined && !mondayStatus.userInMonday"
                            class="button is-dark"
                            @click.prevent="showMondayOptions(selectedUser)"
                        >
                            Add to Monday
                        </button>
                        <button v-else class="button is-danger" @click.prevent="showMondayOptions(selectedUser)">
                            Re-add Monday
                        </button>
                    </div>

                    <div v-if="isSteve" class="buttons">
                        <button v-if="!selectedUser.profileFixed" class="button is-danger" @click.prevent="fixPublicProfile(selectedUser)">
                            Fix Public Profile
                        </button>
                        <button v-else class="button is-light" disabled="true">
                            Profile Fixed
                        </button>
                    </div>
                    <!-- <button v-if="isSteve" class="button is-info" @click.prevent="sendAlert(selectedUser.userid,selectedUser.username)">Send Alert</button> -->
                    <!-- <button v-if="isSteve" class="button is-danger" @click.prevent="simulateConnection(selectedUser)">Connect</button> -->
                </footer>
            </div>
        </div>

        <div class="copy-prompt" :class="{ visible: showCopyPrompt }">
            Link copied to clipboard!
        </div>

        <simple-modal :title="mondayTitle" theme="is-primary" v-on:closed="showMondayModal = false" v-if="showMondayModal">
            <!-- Signup Date: {{ mondayStatus.mondaySignUpDate }} -->

            <label class="label">Customer Type:</label>
            <div class="select">
                <select v-model="mondayStatus.userType">
                    <option v-for="option in userTypeOptions" :value="option" :key="option">{{ option }}</option>
                </select>
            </div>
            <div class="spacer s20"></div>
            <label class="label">Comments:</label>
            <div class="form-group-reg">
                <textarea class="textarea" v-model="mondayStatus.comments" placeholder="Type any comments.."></textarea>
            </div>
            <div class="spacer s20"></div>

            <div class="button is-dark is-medium" @click="addToMonday()"><font-awesome-icon icon="users" /> &nbsp; Add to Monday</div>
            <div class="spacer s30"></div>
            <!-- {{ mondayStatus }} -->
        </simple-modal>
    </div>
</template>

<script>
// @ is an alias to /src
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as moment from 'moment';
import NProgress from 'nprogress';
import algoliasearch from 'algoliasearch';
// import 'instantsearch.css/themes/satellite.css';
const SimpleModal = () => import('../reusables/SimpleModal.vue');
import axios from 'axios';
import randomString from 'random-string';

export default {
    name: 'Demo',

    components: {
        SimpleModal
    },

    data: function() {
        return {
            firebaseid: '',
            firebaseemail: '',
            firebasedisplayname: '',
            users: [],
            allstates: [
                { id: '1', name: 'Tasmania' },
                { id: '2', name: 'Victoria' },
                { id: '3', name: 'New South Wales' },
                { id: '4', name: 'Queensland' },
                { id: '5', name: 'South Australia' },
                { id: '6', name: 'Western Australia' },
                { id: '7', name: 'Northern Territory' }
            ],
            industries: [
                { id: '1', name: 'Fishing' },
                { id: '2', name: 'Aquaculture' },
                { id: '3', name: 'Commercial Diving' },
                { id: '4', name: 'Marine Construction' },
                { id: '5', name: 'Charter Vessel' },
                { id: '6', name: 'Passenger Vessel' },
                { id: '7', name: 'Other' }
            ],
            roles: [
                { slug: 'free', name: 'Free' },
                { slug: 'starter', name: 'Starter' },
                { slug: 'fleet', name: 'Fleet' }
            ],
            groups: [{ id: '1', name: 'Tasmanian Abalone' }],
            selectedState: 'all',
            selectedIndustry: 'all',
            selectedRole: 'all',
            selectedGroup: 'all',
            showProfile: false,

            selectedUserID: '',
            selectedUser: {
                userid: '',
                username: '',
                useremail: '',
                userphone: '',
                squareCustomerID: '',
                industry: '',
                userrole: '',
                userstate: '',
                signUpDate: '',
                anniversaryDate: '',
                maxVessels: '',
                adminNotes: '',
                doesDiving: '',
                isTrainer: '',
                myVessels: [],
                lastCharge: '',
                nextCharge: '',
                lastPayment: '',
                masteredVesselIDs: [],
                crewedVesselIDs: [],
                masteredVessels: [],
                crewedVessels: [],
                favouriteFixed: false,
                favouriteVesselID: '',
                favouriteVesselName: '',
                favouriteVesselCanDoLogs: false,
                favouriteVesselIsCompany: false,
                favouriteVesselIsMine: false,
                signupPlatform: '',
                operatingSystem: '',
                lastAppLoginVersion: '',
                profilingInfo: {},
                hasIndex: false,
                adminTextMessages: []
            },

            isSelected: false,
            findName: '',
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            totalUsers: 0,

            codes: [
                { name: 'Israel', dial_code: '+972', code: 'IL' },
                { name: 'Afghanistan', dial_code: '+93', code: 'AF' },
                { name: 'Albania', dial_code: '+355', code: 'AL' },
                { name: 'Algeria', dial_code: '+213', code: 'DZ' },
                { name: 'AmericanSamoa', dial_code: '+1 684', code: 'AS' },
                { name: 'Andorra', dial_code: '+376', code: 'AD' },
                { name: 'Angola', dial_code: '+244', code: 'AO' },
                { name: 'Anguilla', dial_code: '+1264', code: 'AI' },
                { name: 'Antigua and Barbuda', dial_code: '+1268', code: 'AG' },
                { name: 'Argentina', dial_code: '+54', code: 'AR' },
                { name: 'Armenia', dial_code: '+374', code: 'AM' },
                { name: 'Aruba', dial_code: '+297', code: 'AW' },
                { name: 'Australia', dial_code: '+61', code: 'AU' },
                { name: 'Austria', dial_code: '+43', code: 'AT' },
                { name: 'Azerbaijan', dial_code: '+994', code: 'AZ' },
                { name: 'Bahamas', dial_code: '+1242', code: 'BS' },
                { name: 'Bahrain', dial_code: '+973', code: 'BH' },
                { name: 'Bangladesh', dial_code: '+880', code: 'BD' },
                { name: 'Barbados', dial_code: '+1246', code: 'BB' },
                { name: 'Belarus', dial_code: '+375', code: 'BY' },
                { name: 'Belgium', dial_code: '+32', code: 'BE' },
                { name: 'Belize', dial_code: '+501', code: 'BZ' },
                { name: 'Benin', dial_code: '+229', code: 'BJ' },
                { name: 'Bermuda', dial_code: '+1441', code: 'BM' },
                { name: 'Bhutan', dial_code: '+975', code: 'BT' },
                { name: 'Bosnia and Herzegovina', dial_code: '+387', code: 'BA' },
                { name: 'Botswana', dial_code: '+267', code: 'BW' },
                { name: 'Brazil', dial_code: '+55', code: 'BR' },
                {
                    name: 'British Indian Ocean Territory',
                    dial_code: '+246',
                    code: 'IO'
                },
                { name: 'Bulgaria', dial_code: '+359', code: 'BG' },
                { name: 'Burkina Faso', dial_code: '+226', code: 'BF' },
                { name: 'Burundi', dial_code: '+257', code: 'BI' },
                { name: 'Cambodia', dial_code: '+855', code: 'KH' },
                { name: 'Cameroon', dial_code: '+237', code: 'CM' },
                { name: 'Canada', dial_code: '+1', code: 'CA' },
                { name: 'Cape Verde', dial_code: '+238', code: 'CV' },
                { name: 'Cayman Islands', dial_code: '+ 345', code: 'KY' },
                { name: 'Central African Republic', dial_code: '+236', code: 'CF' },
                { name: 'Chad', dial_code: '+235', code: 'TD' },
                { name: 'Chile', dial_code: '+56', code: 'CL' },
                { name: 'China', dial_code: '+86', code: 'CN' },
                { name: 'Christmas Island', dial_code: '+61', code: 'CX' },
                { name: 'Colombia', dial_code: '+57', code: 'CO' },
                { name: 'Comoros', dial_code: '+269', code: 'KM' },
                { name: 'Congo', dial_code: '+242', code: 'CG' },
                { name: 'Cook Islands', dial_code: '+682', code: 'CK' },
                { name: 'Costa Rica', dial_code: '+506', code: 'CR' },
                { name: 'Croatia', dial_code: '+385', code: 'HR' },
                { name: 'Cuba', dial_code: '+53', code: 'CU' },
                { name: 'Cyprus', dial_code: '+537', code: 'CY' },
                { name: 'Czech Republic', dial_code: '+420', code: 'CZ' },
                { name: 'Denmark', dial_code: '+45', code: 'DK' },
                { name: 'Djibouti', dial_code: '+253', code: 'DJ' },
                { name: 'Dominica', dial_code: '+1767', code: 'DM' },
                { name: 'Dominican Republic', dial_code: '+1849', code: 'DO' },
                { name: 'Ecuador', dial_code: '+593', code: 'EC' },
                { name: 'Egypt', dial_code: '+20', code: 'EG' },
                { name: 'El Salvador', dial_code: '+503', code: 'SV' },
                { name: 'Equatorial Guinea', dial_code: '+240', code: 'GQ' },
                { name: 'Eritrea', dial_code: '+291', code: 'ER' },
                { name: 'Estonia', dial_code: '+372', code: 'EE' },
                { name: 'Ethiopia', dial_code: '+251', code: 'ET' },
                { name: 'Faroe Islands', dial_code: '+298', code: 'FO' },
                { name: 'Fiji', dial_code: '+679', code: 'FJ' },
                { name: 'Finland', dial_code: '+358', code: 'FI' },
                { name: 'France', dial_code: '+33', code: 'FR' },
                { name: 'French Guiana', dial_code: '+594', code: 'GF' },
                { name: 'French Polynesia', dial_code: '+689', code: 'PF' },
                { name: 'Gabon', dial_code: '+241', code: 'GA' },
                { name: 'Gambia', dial_code: '+220', code: 'GM' },
                { name: 'Georgia', dial_code: '+995', code: 'GE' },
                { name: 'Germany', dial_code: '+49', code: 'DE' },
                { name: 'Ghana', dial_code: '+233', code: 'GH' },
                { name: 'Gibraltar', dial_code: '+350', code: 'GI' },
                { name: 'Greece', dial_code: '+30', code: 'GR' },
                { name: 'Greenland', dial_code: '+299', code: 'GL' },
                { name: 'Grenada', dial_code: '+1473', code: 'GD' },
                { name: 'Guadeloupe', dial_code: '+590', code: 'GP' },
                { name: 'Guam', dial_code: '+1671', code: 'GU' },
                { name: 'Guatemala', dial_code: '+502', code: 'GT' },
                { name: 'Guinea', dial_code: '+224', code: 'GN' },
                { name: 'Guinea-Bissau', dial_code: '+245', code: 'GW' },
                { name: 'Guyana', dial_code: '+595', code: 'GY' },
                { name: 'Haiti', dial_code: '+509', code: 'HT' },
                { name: 'Honduras', dial_code: '+504', code: 'HN' },
                { name: 'Hungary', dial_code: '+36', code: 'HU' },
                { name: 'Iceland', dial_code: '+354', code: 'IS' },
                { name: 'India', dial_code: '+91', code: 'IN' },
                { name: 'Indonesia', dial_code: '+62', code: 'ID' },
                { name: 'Iraq', dial_code: '+964', code: 'IQ' },
                { name: 'Ireland', dial_code: '+353', code: 'IE' },
                { name: 'Israel', dial_code: '+972', code: 'IL' },
                { name: 'Italy', dial_code: '+39', code: 'IT' },
                { name: 'Jamaica', dial_code: '+1876', code: 'JM' },
                { name: 'Japan', dial_code: '+81', code: 'JP' },
                { name: 'Jordan', dial_code: '+962', code: 'JO' },
                { name: 'Kazakhstan', dial_code: '+7 7', code: 'KZ' },
                { name: 'Kenya', dial_code: '+254', code: 'KE' },
                { name: 'Kiribati', dial_code: '+686', code: 'KI' },
                { name: 'Kuwait', dial_code: '+965', code: 'KW' },
                { name: 'Kyrgyzstan', dial_code: '+996', code: 'KG' },
                { name: 'Latvia', dial_code: '+371', code: 'LV' },
                { name: 'Lebanon', dial_code: '+961', code: 'LB' },
                { name: 'Lesotho', dial_code: '+266', code: 'LS' },
                { name: 'Liberia', dial_code: '+231', code: 'LR' },
                { name: 'Liechtenstein', dial_code: '+423', code: 'LI' },
                { name: 'Lithuania', dial_code: '+370', code: 'LT' },
                { name: 'Luxembourg', dial_code: '+352', code: 'LU' },
                { name: 'Madagascar', dial_code: '+261', code: 'MG' },
                { name: 'Malawi', dial_code: '+265', code: 'MW' },
                { name: 'Malaysia', dial_code: '+60', code: 'MY' },
                { name: 'Maldives', dial_code: '+960', code: 'MV' },
                { name: 'Mali', dial_code: '+223', code: 'ML' },
                { name: 'Malta', dial_code: '+356', code: 'MT' },
                { name: 'Marshall Islands', dial_code: '+692', code: 'MH' },
                { name: 'Martinique', dial_code: '+596', code: 'MQ' },
                { name: 'Mauritania', dial_code: '+222', code: 'MR' },
                { name: 'Mauritius', dial_code: '+230', code: 'MU' },
                { name: 'Mayotte', dial_code: '+262', code: 'YT' },
                { name: 'Mexico', dial_code: '+52', code: 'MX' },
                { name: 'Monaco', dial_code: '+377', code: 'MC' },
                { name: 'Mongolia', dial_code: '+976', code: 'MN' },
                { name: 'Montenegro', dial_code: '+382', code: 'ME' },
                { name: 'Montserrat', dial_code: '+1664', code: 'MS' },
                { name: 'Morocco', dial_code: '+212', code: 'MA' },
                { name: 'Myanmar', dial_code: '+95', code: 'MM' },
                { name: 'Namibia', dial_code: '+264', code: 'NA' },
                { name: 'Nauru', dial_code: '+674', code: 'NR' },
                { name: 'Nepal', dial_code: '+977', code: 'NP' },
                { name: 'Netherlands', dial_code: '+31', code: 'NL' },
                { name: 'Netherlands Antilles', dial_code: '+599', code: 'AN' },
                { name: 'New Caledonia', dial_code: '+687', code: 'NC' },
                { name: 'New Zealand', dial_code: '+64', code: 'NZ' },
                { name: 'Nicaragua', dial_code: '+505', code: 'NI' },
                { name: 'Niger', dial_code: '+227', code: 'NE' },
                { name: 'Nigeria', dial_code: '+234', code: 'NG' },
                { name: 'Niue', dial_code: '+683', code: 'NU' },
                { name: 'Norfolk Island', dial_code: '+672', code: 'NF' },
                { name: 'Northern Mariana Islands', dial_code: '+1670', code: 'MP' },
                { name: 'Norway', dial_code: '+47', code: 'NO' },
                { name: 'Oman', dial_code: '+968', code: 'OM' },
                { name: 'Pakistan', dial_code: '+92', code: 'PK' },
                { name: 'Palau', dial_code: '+680', code: 'PW' },
                { name: 'Panama', dial_code: '+507', code: 'PA' },
                { name: 'Papua New Guinea', dial_code: '+675', code: 'PG' },
                { name: 'Paraguay', dial_code: '+595', code: 'PY' },
                { name: 'Peru', dial_code: '+51', code: 'PE' },
                { name: 'Philippines', dial_code: '+63', code: 'PH' },
                { name: 'Poland', dial_code: '+48', code: 'PL' },
                { name: 'Portugal', dial_code: '+351', code: 'PT' },
                { name: 'Puerto Rico', dial_code: '+1939', code: 'PR' },
                { name: 'Qatar', dial_code: '+974', code: 'QA' },
                { name: 'Romania', dial_code: '+40', code: 'RO' },
                { name: 'Rwanda', dial_code: '+250', code: 'RW' },
                { name: 'Samoa', dial_code: '+685', code: 'WS' },
                { name: 'San Marino', dial_code: '+378', code: 'SM' },
                { name: 'Saudi Arabia', dial_code: '+966', code: 'SA' },
                { name: 'Senegal', dial_code: '+221', code: 'SN' },
                { name: 'Serbia', dial_code: '+381', code: 'RS' },
                { name: 'Seychelles', dial_code: '+248', code: 'SC' },
                { name: 'Sierra Leone', dial_code: '+232', code: 'SL' },
                { name: 'Singapore', dial_code: '+65', code: 'SG' },
                { name: 'Slovakia', dial_code: '+421', code: 'SK' },
                { name: 'Slovenia', dial_code: '+386', code: 'SI' },
                { name: 'Solomon Islands', dial_code: '+677', code: 'SB' },
                { name: 'South Africa', dial_code: '+27', code: 'ZA' },
                {
                    name: 'South Georgia and the South Sandwich Islands',
                    dial_code: '+500',
                    code: 'GS'
                },
                { name: 'Spain', dial_code: '+34', code: 'ES' },
                { name: 'Sri Lanka', dial_code: '+94', code: 'LK' },
                { name: 'Sudan', dial_code: '+249', code: 'SD' },
                { name: 'Suriname', dial_code: '+597', code: 'SR' },
                { name: 'Swaziland', dial_code: '+268', code: 'SZ' },
                { name: 'Sweden', dial_code: '+46', code: 'SE' },
                { name: 'Switzerland', dial_code: '+41', code: 'CH' },
                { name: 'Tajikistan', dial_code: '+992', code: 'TJ' },
                { name: 'Thailand', dial_code: '+66', code: 'TH' },
                { name: 'Togo', dial_code: '+228', code: 'TG' },
                { name: 'Tokelau', dial_code: '+690', code: 'TK' },
                { name: 'Tonga', dial_code: '+676', code: 'TO' },
                { name: 'Trinidad and Tobago', dial_code: '+1868', code: 'TT' },
                { name: 'Tunisia', dial_code: '+216', code: 'TN' },
                { name: 'Turkey', dial_code: '+90', code: 'TR' },
                { name: 'Turkmenistan', dial_code: '+993', code: 'TM' },
                { name: 'Turks and Caicos Islands', dial_code: '+1649', code: 'TC' },
                { name: 'Tuvalu', dial_code: '+688', code: 'TV' },
                { name: 'Uganda', dial_code: '+256', code: 'UG' },
                { name: 'Ukraine', dial_code: '+380', code: 'UA' },
                { name: 'United Arab Emirates', dial_code: '+971', code: 'AE' },
                { name: 'United Kingdom', dial_code: '+44', code: 'GB' },
                { name: 'United States', dial_code: '+1', code: 'US' },
                { name: 'Uruguay', dial_code: '+598', code: 'UY' },
                { name: 'Uzbekistan', dial_code: '+998', code: 'UZ' },
                { name: 'Vanuatu', dial_code: '+678', code: 'VU' },
                { name: 'Wallis and Futuna', dial_code: '+681', code: 'WF' },
                { name: 'Yemen', dial_code: '+967', code: 'YE' },
                { name: 'Zambia', dial_code: '+260', code: 'ZM' },
                { name: 'Zimbabwe', dial_code: '+263', code: 'ZW' },
                { name: 'land Islands', dial_code: '', code: 'AX' },
                { name: 'Antarctica', dial_code: null, code: 'AQ' },
                {
                    name: 'Bolivia, Plurinational State of',
                    dial_code: '+591',
                    code: 'BO'
                },
                { name: 'Brunei Darussalam', dial_code: '+673', code: 'BN' },
                { name: 'Cocos (Keeling) Islands', dial_code: '+61', code: 'CC' },
                {
                    name: 'Congo, The Democratic Republic of the',
                    dial_code: '+243',
                    code: 'CD'
                },
                { name: "Cote d'Ivoire", dial_code: '+225', code: 'CI' },
                { name: 'Falkland Islands (Malvinas)', dial_code: '+500', code: 'FK' },
                { name: 'Guernsey', dial_code: '+44', code: 'GG' },
                {
                    name: 'Holy See (Vatican City State)',
                    dial_code: '+379',
                    code: 'VA'
                },
                { name: 'Hong Kong', dial_code: '+852', code: 'HK' },
                { name: 'Iran, Islamic Republic of', dial_code: '+98', code: 'IR' },
                { name: 'Isle of Man', dial_code: '+44', code: 'IM' },
                { name: 'Jersey', dial_code: '+44', code: 'JE' },
                {
                    name: "Korea, Democratic People's Republic of",
                    dial_code: '+850',
                    code: 'KP'
                },
                { name: 'Korea, Republic of', dial_code: '+82', code: 'KR' },
                {
                    name: "Lao People's Democratic Republic",
                    dial_code: '+856',
                    code: 'LA'
                },
                { name: 'Libyan Arab Jamahiriya', dial_code: '+218', code: 'LY' },
                { name: 'Macao', dial_code: '+853', code: 'MO' },
                {
                    name: 'Macedonia, The Former Yugoslav Republic of',
                    dial_code: '+389',
                    code: 'MK'
                },
                {
                    name: 'Micronesia, Federated States of',
                    dial_code: '+691',
                    code: 'FM'
                },
                { name: 'Moldova, Republic of', dial_code: '+373', code: 'MD' },
                { name: 'Mozambique', dial_code: '+258', code: 'MZ' },
                {
                    name: 'Palestinian Territory, Occupied',
                    dial_code: '+970',
                    code: 'PS'
                },
                { name: 'Pitcairn', dial_code: '+872', code: 'PN' },
                { name: 'Réunion', dial_code: '+262', code: 'RE' },
                { name: 'Russia', dial_code: '+7', code: 'RU' },
                { name: 'Saint Barthélemy', dial_code: '+590', code: 'BL' },
                {
                    name: 'Saint Helena, Ascension and Tristan Da Cunha',
                    dial_code: '+290',
                    code: 'SH'
                },
                { name: 'Saint Kitts and Nevis', dial_code: '+1869', code: 'KN' },
                { name: 'Saint Lucia', dial_code: '+1758', code: 'LC' },
                { name: 'Saint Martin', dial_code: '+590', code: 'MF' },
                { name: 'Saint Pierre and Miquelon', dial_code: '+508', code: 'PM' },
                {
                    name: 'Saint Vincent and the Grenadines',
                    dial_code: '+1784',
                    code: 'VC'
                },
                { name: 'Sao Tome and Principe', dial_code: '+239', code: 'ST' },
                { name: 'Somalia', dial_code: '+252', code: 'SO' },
                { name: 'Svalbard and Jan Mayen', dial_code: '+47', code: 'SJ' },
                { name: 'Syrian Arab Republic', dial_code: '+963', code: 'SY' },
                { name: 'Taiwan, Province of China', dial_code: '+886', code: 'TW' },
                { name: 'Tanzania, United Republic of', dial_code: '+255', code: 'TZ' },
                { name: 'Timor-Leste', dial_code: '+670', code: 'TL' },
                {
                    name: 'Venezuela, Bolivarian Republic of',
                    dial_code: '+58',
                    code: 'VE'
                },
                { name: 'Viet Nam', dial_code: '+84', code: 'VN' },
                { name: 'Virgin Islands, British', dial_code: '+1284', code: 'VG' },
                { name: 'Virgin Islands, U.S.', dial_code: '+1340', code: 'VI' }
            ],

            searchEmails: false,
            searchPhone: false,
            searchHomeport: false,
            searchClient: algoliasearch('789GF6G7HW', '0dfd28f6ac249c3ebef88e13f6adbc34'),
            searchIndex: '',
            selectedUserHasIndex: false,

            selectedUsers: [],
            showSmsModal: false,
            defaultMessage:
                'Hi there. The OffshoreSMS app has recently had a major update. To avoid any data sync issues, we recommend updating your app to version 4.2.1 as soon as possible.\nPlease contact us if you have any questions.\nThanks, OffshoreSMS Team',
            textMessage: '',

            showCopyPrompt: false,

            //   selectedUsers: [],
            showMondayModal: false,
            userTypeOptions: [
                'Unknown',
                'New Lead',
                'App User',
                'Crew',
                'Skipper',
                'Owner/Operator',
                'Fleet Owner',
                'Consultant',
                'Fleet Manager',
                'Mgmt Team'
            ],
            mondayStatus: {
                userInMonday: false,
                userType: 'New Lead',
                comments: '',
                mondaySignUpDate: new Date()
            },
            bulkList: [],
            bulkListIDs: [],
            osIcon: {
                ios: 'apple',
                android: 'android',
                web: 'google',
                'not set': 'google'
            }
        };
    },

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        mondayTitle() {
            let title = this.selectedUser.username ? this.selectedUser.username : 'this user';
            return 'Add ' + title + ' to Monday';
        },
        filteredUsers() {
            let self = this;
            let tempArray = this.users;

            if (self.selectedState != 'all') {
                tempArray = tempArray.filter(user => {
                    return user.userstate == self.selectedState;
                });
            }

            if (self.selectedIndustry != 'all') {
                tempArray = tempArray.filter(user => {
                    return user.industry == self.selectedIndustry;
                });
            }

            if (self.selectedRole != 'all') {
                tempArray = tempArray.filter(user => {
                    return user.userrole == self.selectedRole;
                });
            }

            if (self.selectedGroup != 'all') {
                tempArray = tempArray.filter(user => {
                    return user.userGroup == self.selectedGroup;
                });
            }

            return tempArray;
        },

        filteredNames() {
            let filter = new RegExp(this.findName, 'i');
            return this.searchEmails
                ? this.filteredUsers.filter(user => user.useremail.match(filter))
                : this.searchPhone
                ? this.filteredUsers.filter(user => user.userphone.match(filter))
                : this.searchHomeport
                ? this.filteredUsers.filter(user => user.homeport.match(filter))
                : this.filteredUsers.filter(user => user.username.match(filter));
        },

        selectedVessels() {
            let self = this;
            let tempVessels = '';
            if (this.selectedUser.userid != '' && this.selectedUser.myVessels != undefined) {
                let tempVessels = self.selectedUser.myVessels.map(vessel => vessel.vesselName);
                tempVessels.join(', ');
            }
            return tempVessels;
        },
        isAdmin() {
            return this.$store.getters.getIsAdmin;
        },
        isSteve() {
            return this.firebaseid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2';
        }
    },

    mounted() {
        this.$store.dispatch('setUser');
        this.$store.dispatch('SETSUBSCRIPTIONINFO');
        firebase.auth().onAuthStateChanged(this.onUserLogin);
        this.searchIndex = this.searchClient.initIndex('app_userdata');
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                this.firebaseemail = user.email;
                this.firebaseid = user.uid;
                this.firebasedisplayname = this.userData.username;
                this.loadUsers();
            }
        },

        getCountry(phone) {
            if (phone) {
                let list = this.codes
                    .filter(code => phone.slice(0, 4) == code.dial_code || phone.slice(0, 3) == code.dial_code || phone.slice(0, 2) == code.dial_code)
                    .map(n => n.name);
                return list.join(', ');
            }
        },

        async bulkFixProfiles() {
            // console.log(this.bulkList);
            let self = this;
            NProgress.start();

            var bulkFixPublicProfiles = firebase.functions().httpsCallable('bulkFixPublicProfiles');
            bulkFixPublicProfiles({
                bulkList: self.bulkList
            }).catch(function(error) {
                console.log('Error', error);
            });
            setTimeout(() => {
                this.bulkList = [];
                this.bulkListIDs = [];
                NProgress.done();
            }, 1000);

            // await Promise.all(
            //     self.bulkList.map(async user => {
            //         let getCode = randomString({ length: 30 });
            //         db.collection('profileLinkIDs')
            //             .doc(getCode)
            //             .set({
            //                 linkID: getCode,
            //                 userid: user.userid,
            //                 username: user.username,
            //                 timestamp: new Date()
            //             })
            //             .then(() => {
            //                 db.collection('userdata')
            //                     .doc(user.userid)
            //                     .update({
            //                         publicProfileData: {
            //                             publicLink: getCode,
            //                             allowPublicProfile: true,
            //                             displaySettings: {
            //                                 basic: true,
            //                                 bio: false,
            //                                 contacts: false,
            //                                 experience: false,
            //                                 institutions: false,
            //                                 quals: false,
            //                                 skills: false
            //                             },
            //                             profileUpdated: new Date()
            //                         }
            //                     });
            //             });
            //     })
            // );
            // this.bulkList = [];
            // this.bulkListIDs = [];
            // NProgress.done();
        },

        addToBulkList(user) {
            if (!this.bulkListIDs.includes(user.userid)) {
                let getCode = randomString({ length: 30 });
                this.bulkListIDs.push(user.userid);
                this.bulkList.push({
                    userid: user.userid,
                    username: user.username,
                    linkCode: getCode
                });
            }
        },

        fixPublicProfile(user) {
            // console.log(user);
            NProgress.start();
            var getCode = randomString({ length: 30 });
            // console.log(getCode);
            db.collection('profileLinkIDs')
                .doc(getCode)
                .set({
                    linkID: getCode,
                    userid: user.userid,
                    username: user.username,
                    timestamp: new Date()
                });
            db.collection('userdata')
                .doc(user.userid)
                .update({
                    publicProfileData: {
                        publicLink: getCode,
                        allowPublicProfile: true,
                        displaySettings: {
                            basic: true,
                            bio: false,
                            contacts: false,
                            experience: false,
                            institutions: false,
                            quals: false,
                            skills: false
                        },
                        profileUpdated: new Date()
                    }
                });
            NProgress.done();
        },

        showMondayOptions(user) {
            console.log('user', user);
            console.log('mondayStatus:', this.mondayStatus);
            this.showProfile = false;
            this.selectedUser = { ...user };
            //   this.mondayStatus.userType = "Customer";
            //   this.mondayStatus.comments = "";
            //   this.mondayStatus.mondaySignUpDate = new Date();
            this.showMondayModal = true;
        },

        async addToMonday() {
            console.log(this.selectedUser);
            let self = this;
            NProgress.start();

            let userID = this.selectedUser.userid;
            let userType = this.mondayStatus.userType;
            let comments = this.mondayStatus.comments;
            let userphone = this.selectedUser.userphone ? this.selectedUser.userphone : '';
            let useremail = this.selectedUser.useremail ? this.selectedUser.useremail : '';

            let d = new Date(this.mondayStatus.mondaySignUpDate);
            let d1 =
                d.getFullYear() +
                '-' +
                (d.getMonth() + 1).toString().padStart(2, '0') +
                '-' +
                d
                    .getDate()
                    .toString()
                    .padStart(2, '0');
            console.log('d', d);
            console.log('d1', d1);

            //   let xx = '{\\"contact_phone\\": { \\"text\\": \\"' + userphone + '\\", \\"phone\\": \\"' + userphone + '\\" },\\"contact_email\\": { \\"text\\": \\"' + useremail + '\\", \\"email\\": \\"' + useremail + '\\" },\\"status\\": \\"' + userType + '\\",\\"date3\\": \\"' + d1 + '\\",\\"long_text4\\": \\"' + comments + '\\"}';

            let colValues =
                '{\\"contact_phone\\": { \\"text\\": \\"' +
                userphone +
                '\\", \\"phone\\": \\"' +
                userphone +
                '\\" },\\"contact_email\\": { \\"text\\": \\"' +
                useremail +
                '\\", \\"email\\": \\"' +
                useremail +
                '\\" },\\"status\\": \\"' +
                userType +
                '\\",\\"date3\\": \\"' +
                d1 +
                '\\",\\"long_text4\\": \\"' +
                comments +
                '\\"}';

            let query =
                'mutation { create_item (board_id: 5009300091, group_id: "topics", item_name: "' +
                this.selectedUser.username +
                '", column_values: "' +
                colValues +
                '") { id }}';

            //   fetch("https://api.monday.com/v2", {
            //     method: "post",
            //     headers: {
            //       "Content-Type": "application/json",
            //       Authorization:
            //         "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjI3NDczMjk3NiwiYWFpIjoxMSwidWlkIjoyOTgzOTEwMiwiaWFkIjoiMjAyMy0wOC0xMlQyMjo1OTozOC44NDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTE5MDcwODcsInJnbiI6InVzZTEifQ.aD_vr66yswbNrCmwe4wjAM3AkVrXrBZ24-P3aj4I8AI",
            //     },
            //     // body: body,
            //     body: JSON.stringify({
            //       query: query,
            //     }),
            //   })
            //     .then((res) => {
            //       //   res.json();
            //       console.log("/// ", res.json());
            //       self.updateMondayStatus();
            //       NProgress.done();
            //     })
            //     .then((res) => console.log(JSON.stringify(res, null, 2)));

            axios({
                method: 'post',
                url: 'https://api.monday.com/v2',
                // responseType: asJson ? "json" : "arraybuffer",
                //   data: body,
                data: JSON.stringify({
                    query: query
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjI3NDczMjk3NiwiYWFpIjoxMSwidWlkIjoyOTgzOTEwMiwiaWFkIjoiMjAyMy0wOC0xMlQyMjo1OTozOC44NDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTE5MDcwODcsInJnbiI6InVzZTEifQ.aD_vr66yswbNrCmwe4wjAM3AkVrXrBZ24-P3aj4I8AI'
                }
            })
                .then(response => {
                    console.log('/// ', response.data);
                    self.updateMondayStatus();
                    self.close();
                })
                .catch(err => {
                    console.log('error occured', err);
                    NProgress.done();
                });

            //
        },

        updateMondayStatus() {
            let self = this;
            db.collection('userdata')
                .doc(this.selectedUser.userid)
                .update({
                    mondayStatus: {
                        userInMonday: true,
                        userType: self.mondayStatus.userType,
                        comments: self.mondayStatus.comments,
                        mondaySignUpDate: self.mondayStatus.mondaySignUpDate
                    }
                })
                .then(() => {
                    self.close();
                    NProgress.done();
                });
        },

        copyLink(text) {
            let self = this;
            this.$copyText(text).then(
                function(e) {
                    console.log('Text copied:', text);
                    self.showCopied();
                },
                function(e) {
                    alert('Link could not be copied to clipboard.');
                    console.log(e);
                }
            );
        },

        showCopied() {
            this.showCopyPrompt = true;
            setTimeout(() => {
                this.showCopyPrompt = false;
            }, 1600);
        },

        getUserNum(index) {
            return this.totalUsers - index;
        },

        checkIfCustomerExists(user) {
            console.log(user);
            if (!user.userid || user.userid == '') return;
            // search customers api by reference id (company id or user id in this case)
            let self = this;
            NProgress.start();

            if (user.squareCustomerID && user.squareCustomerID != '') {
                this.searchCustomersByCustomerID(user);
            } else {
                this.searchCustomersByReferenceID(user);
            }
        },

        searchCustomersByCustomerID(user) {
            let self = this;
            // console.log(user);
            console.log('This user has a Square Customer ID on file. Retrieving from Square...');

            var retrieveCustomerObject = firebase.functions().httpsCallable('retrieveCustomerObject');
            retrieveCustomerObject({
                customerID: user.squareCustomerID
            })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    // console.log('Result',sanitizedMessage);
                    // console.log('Success?',result.data.success);
                    if (result.data.success) {
                        if (sanitizedMessage.customer) {
                            console.log('Customer located in Square with customer ID: ' + sanitizedMessage.customer.id);
                            console.log('Returned referenceId from Square:', sanitizedMessage.customer.referenceId);
                            // NB if the returned square customer ID does not match the saved customer ID then throw alert!
                            // if (user.squareCustomerID != sanitizedMessage.customer.id) {
                            if (user.userid != sanitizedMessage.customer.referenceId) {
                                console.log('User ID mismatched with Square referenceId - fixing in referenceId in Square');
                                self.fixSquareCustomerMismatch(user);
                            } else if (user.squareCustomerID != sanitizedMessage.customer.id) {
                                console.log('squareCustomerID mismatched with Square customerId - fixing in firestore userdata');
                                self.updateUserData(user.userid, {
                                    squareCustomerID: sanitizedMessage.customer.id
                                });
                                NProgress.done();
                            } else {
                                console.log('Everything OK - customer userid matches up with referenceId in Square');
                                NProgress.done();
                            }
                        } else {
                            console.log('Search found no existing customer object in Square');
                            console.log('Running function: createNewSquareCustomer');
                            // self.createNewSquareCustomer(user); // add new customer if not found
                            self.searchCustomersByReferenceID(user);
                        }
                    } else {
                        // console.log('Error locating customer in Square');
                        // window.alert('The function searchCustomersByCustomerID returned an error');
                        // NProgress.done();
                        console.log('The function searchCustomersByCustomerID returned an error');
                        // if (sanitizedMessage.errors && sanitizedMessage.errors[0].code == 'NOT_FOUND') self.createNewSquareCustomer(user);
                        self.searchCustomersByReferenceID(user);
                    }
                })
                .catch(function(result) {
                    console.log('Cloud function error: retrieveCustomerObject', result.data.text);
                    window.alert('The cloud function retrieveCustomerObject returned an error');
                    NProgress.done();
                });
        },

        fixSquareCustomerMismatch(user) {
            console.log('Function Z in flow diagram - fixing the mismatch');
            this.updateCustomerByCustomerID(user);
        },

        updateCustomerByCustomerID(user) {
            let self = this;
            NProgress.start();
            // console.log(user);
            console.log('Updating customer in Square with current user ID as referenceId...');

            var updateCustomerObject = firebase.functions().httpsCallable('updateCustomerObject');
            updateCustomerObject({
                customerID: user.squareCustomerID,
                updateFields: {
                    referenceId: user.userid
                }
            })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    // console.log('Result',sanitizedMessage);
                    // console.log('Success?',result.data.success);
                    if (result.data.success) {
                        if (sanitizedMessage.customer) {
                            console.log('Customer updated in Square with correct user id');
                            NProgress.done();
                        } else {
                            console.log('Customer update failed.');
                            NProgress.done();
                        }
                    } else {
                        console.log('The function updateCustomerByCustomerID returned an error');
                    }
                })
                .catch(function(error) {
                    console.log('Cloud function error: updateCustomerObject', error);
                    NProgress.done();
                });
        },

        searchCustomersByReferenceID(user) {
            let self = this;
            NProgress.start();
            console.log('This user has no squareCustomerID on file - checking Square for a customer referenceId matching userID: ', user.userid);

            var searchCustomerObject = firebase.functions().httpsCallable('searchCustomerObject');
            searchCustomerObject({
                referenceID: user.userid
            })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    if (result.data.success) {
                        if (sanitizedMessage.customers && sanitizedMessage.customers.length > 0) {
                            console.log('Customer objects located in Square: ' + sanitizedMessage.customers.length);
                            console.log(
                                "Updating this user's squareCustomerID with the returned customerId found in Square for this user",
                                sanitizedMessage.customers[0].id
                            );
                            self.updateUserData(user.userid, {
                                squareCustomerID: sanitizedMessage.customers[0].id
                            });
                            NProgress.done();
                        } else {
                            console.log('Search found no existing customer object in Square for this userid');
                            console.log('Running function: createNewSquareCustomer');
                            self.createNewSquareCustomer(user); // add new customer if not found
                        }
                    } else {
                        console.log('Search found no existing customer object in Square for this userid');
                        console.log('Running function: createNewSquareCustomer');
                        if (sanitizedMessage.errors) self.createNewSquareCustomer(user);
                        NProgress.done();
                    }
                })
                .catch(function(result) {
                    console.log('Cloud function error: searchCustomerObject', result.data.text);
                    window.alert('The cloud function searchCustomerObject returned an error');
                    NProgress.done();
                });
        },

        createNewSquareCustomer(user) {
            console.log('Creating new user with referenceID', user.userid);
            NProgress.start();
            let self = this;
            let customerData = {
                givenName: user.username, //
                // familyName: '',
                // companyName: '',
                emailAddress: user.useremail != '' ? user.useremail : '', //
                phoneNumber: user.userphone != '' ? user.userphone : '', //
                referenceId: user.userid, //
                note: user.username + ' (userid: ' + user.userid + ')' //
            };

            var createNewCustomerObject = firebase.functions().httpsCallable('createNewCustomerObject');
            createNewCustomerObject({
                customerData: customerData
            })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    console.log('Result', sanitizedMessage);
                    console.log('Success?', result.data.success);
                    if (result.data.success) {
                        // customer created, save id and object to user data in fb
                        console.log('User successfully created as a customer in Square with customer ID: ' + sanitizedMessage.customer.id);
                        self.updateUserData(user.userid, {
                            squareCustomerID: sanitizedMessage.customer.id
                        });
                        NProgress.done();
                    } else {
                        console.log('Error running function createNewSquareCustomer');
                        NProgress.done();
                    }
                })
                .catch(function(result) {
                    console.log('Error with cloud function: createNewCustomerObject');
                    console.log('result', result);
                    NProgress.done();
                });
        },

        // update vessel in fb database with new data, ie new customer id when created in Square
        async updateUserData(userID, updateData) {
            console.log(userID, updateData);
            db.collection('userdata')
                .doc(userID)
                .update(updateData);
        },

        loadUsers: function() {
            let self = this;
            let count = 0;
            db.collection('userdata')
                .orderBy('signUpDate', 'desc')
                .get()
                .then(function(querySnapshot) {
                    self.users = [];
                    querySnapshot.forEach(function(doc) {
                        // self.users.push(doc.data())
                        count++;
                        self.users.push({
                            counter: count,
                            userid: doc.id,
                            signUpDate: doc.data().signUpDate,
                            userstate: doc.data().userstate,
                            industry: doc.data().industry,
                            userrole: doc.data().userrole,
                            userGroup: doc.data().userGroup ? doc.data().userGroup : '',
                            homeport: doc.data().homeport ? doc.data().homeport : '',
                            username: doc.data().username,
                            useremail: doc.data().useremail ? doc.data().useremail : '',
                            userphone: doc.data().userphone ? doc.data().userphone : '',
                            favouriteVesselID: doc.data().favouriteVesselID ? doc.data().favouriteVesselID : '',
                            signupPlatform: doc.data().signupPlatform ? doc.data().signupPlatform : 'not set',
                            operatingSystem: doc.data().operatingSystem ? doc.data().operatingSystem : 'not set',
                            favouriteFixed: doc.data().favouriteFixed ? doc.data().favouriteFixed : false,
                            isExpired: doc.data().isExpired ? doc.data().isExpired : false,
                            hasIndex: false,
                            indexMissing: false,
                            hasVessels: doc.data().myVessels != undefined,
                            squareCustomerID: doc.data().squareCustomerID ? doc.data().squareCustomerID : '',
                            // profilingInfo: doc.data().profilingInfo ? doc.data().profilingInfo : '',
                            lastAppLoginVersion: doc.data().lastAppLoginVersion ? doc.data().lastAppLoginVersion : 'not set',
                            adminTextMessages: [],
                            profileFixed: doc.data().publicProfileData ? true : false
                        });
                    });
                    self.totalUsers = count;
                });
        },

        cleanDate: function(date) {
            // console.log('Date: ',date);
            // return moment(date.toDate()).format('MMM Do, h:mm:ss a');
            return moment(date.toDate()).format('D MMM, YYYY, h:mm a');
        },

        clearUser: function() {
            this.findName = '';
            this.isSelected = false;
        },

        addEntry(user) {
            let self = this;
            console.log(user);
            this.$router.push({
                name: 'TimelineEntry',
                params: {
                    currentUser: user
                }
            });
        },

        expireUser(user) {
            let self = this;
            this.showProfile = false;
            let message;
            message = {
                title: 'Really expire ' + user.username + '?',
                body: 'Click YES below to expire.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();

                    var AdminExpireUser = firebase.functions().httpsCallable('AdminExpireUser');
                    AdminExpireUser({
                        userid: user.userid
                    })
                        .then(function(result) {
                            // var sanitizedMessage = result.data.text;
                            // console.log('Result',sanitizedMessage);
                            console.log('Message', result.data.message);
                            NProgress.done();
                            self.close();
                        })
                        .catch(function(error) {
                            console.log('Error', error.text);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    self.close();
                    NProgress.done();
                });
        },

        async fixIndex(fixUser) {
            NProgress.start();
            let user = {};
            user.objectID = fixUser.userid;
            user.userid = fixUser.userid;
            user.username = fixUser.username;
            user.userstate = '';
            user.avatarUrl = '';
            await this.searchIndex.saveObject(user);
            fixUser.indexMissing = false;
            NProgress.done();
        },

        async checkAlgoliaSingle(user) {
            NProgress.start();
            let self = this;

            await this.searchIndex
                .getObject(user.userid)
                .then(object => {
                    console.log('Algolia return object:', object);
                    user.hasIndex = true;
                    NProgress.done();
                })
                .catch(err => {
                    console.log(err);
                    user.indexMissing = true;
                    console.log('No user index found');
                    NProgress.done();
                });
        },

        async checkAlgolia(userid) {
            NProgress.start();
            let self = this;
            this.selectedUserHasIndex = false;

            console.log(userid);
            setTimeout(() => {
                self.searchIndex
                    .getObject(userid)
                    .then(object => {
                        self.selectedUserHasIndex = true;
                        NProgress.done();
                    })
                    .catch(err => {
                        console.log(err);
                        console.log('No user index found');
                        NProgress.done();
                    });
            }, 200);
        },

        addAlgoliaIndex(selectedUser) {
            let self = this;
            NProgress.start();
            let user = {};
            user.objectID = selectedUser.userid;
            user.userid = selectedUser.userid;
            user.username = selectedUser.username;
            user.userstate = '';
            user.avatarUrl = '';
            this.searchIndex.saveObject(user);
            NProgress.done();
        },

        endSessions(userID) {
            NProgress.start();
            var signOutUserSessions = firebase.functions().httpsCallable('signOutUserSessions');
            signOutUserSessions({
                userID: userID
            })
                .then(function(result) {
                    // var sanitizedMessage = JSON.parse(result.data.text);
                    console.log('Result', result.data.text);
                    console.log('Success?', result.data.success);
                    if (result.data.success) {
                        // customer created, save id and object to user data in fb
                        console.log(`User sessions terminated for user id: ${userID}`);
                        NProgress.done();
                    } else {
                        console.log('Error terminating user sessions');
                        NProgress.done();
                    }
                })
                .catch(function(result) {
                    console.log('Error with cloud function: signOutUserSessions');
                    console.log('result', result);
                    NProgress.done();
                });
        },

        async showSMS() {
            console.log('step 1');
            let self = this;
            NProgress.start();
            console.log(this.selectedUser);
            this.showProfile = false;
            await db
                .collection('userdata')
                .doc(this.selectedUser.userid)
                .collection('adminTextMessages')
                .get()
                .then(snap => {
                    self.selectedUser.adminTextMessages = [];
                    snap.forEach(doc => {
                        self.selectedUser.adminTextMessages.push(doc.data());
                    });
                    console.log('step 2');
                });
            this.textMessage = this.defaultMessage;
            this.showSmsModal = true;
            NProgress.done();
            console.log('step 3');
        },

        sendSMS() {
            // console.log(selectedUser);
            this.showSmsModal = false;
            if (!this.selectedUser.userphone) return;
            let self = this;
            let message = {
                title: 'Send this SMS?',
                body: 'Charges may apply.'
            };
            let options = {
                okText: 'Yes, SEND',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function() {
                    NProgress.start();
                    var sendTwilioSMS = firebase.functions().httpsCallable('sendTwilioSMS');
                    sendTwilioSMS({
                        toNumber: self.selectedUser.userphone,
                        message: self.textMessage,
                        revisionDescription: 'OffshoreSMS Admin has sent an SMS message to ' + self.selectedUser.username
                    });
                    db.collection('userdata')
                        .doc(self.selectedUser.userid)
                        .collection('adminTextMessages')
                        .add({
                            dateSent: new Date(),
                            message: self.textMessage,
                            sender: 'Admin'
                        });
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        async viewProfile(userid) {
            //console.log(userid);
            let self = this;
            NProgress.start();
            await db
                .collection('userdata')
                .doc(userid)
                .get()
                .then(function(doc) {
                    //console.log(doc.data());
                    //console.log(doc.data().myVessels);
                    self.selectedUser.userid = userid;
                    self.selectedUser.username = doc.data().username;
                    self.selectedUser.useremail = doc.data().useremail;
                    self.selectedUser.userphone = doc.data().userphone;
                    self.selectedUser.squareCustomerID = doc.data().squareCustomerID;
                    self.selectedUser.industry = doc.data().industry;
                    self.selectedUser.userrole = doc.data().userrole;
                    self.selectedUser.userstate = doc.data().userstate;
                    self.selectedUser.signUpDate = self.cleanDate(doc.data().signUpDate);
                    // self.selectedUser.cleanAnniversaryDate = self.cleanDate(doc.data().anniversaryDate);
                    self.selectedUser.anniversaryDate = doc.data().anniversaryDate ? doc.data().anniversaryDate.toDate() : '';
                    self.selectedUser.maxVessels = doc.data().maxVessels;
                    self.selectedUser.adminNotes = doc.data().adminNotes ? doc.data().adminNotes : '';
                    self.selectedUser.doesDiving = doc.data().doesDiving ? doc.data().doesDiving : false;
                    self.selectedUser.isTrainer = doc.data().isTrainer ? doc.data().isTrainer : false;
                    self.selectedUser.lastCharge = doc.data().lastCharge;
                    self.selectedUser.nextCharge = doc.data().nextCharge;
                    self.selectedUser.lastPayment = doc.data().lastPayment;
                    self.selectedUser.userGroup = doc.data().userGroup ? doc.data().userGroup : '';
                    (self.selectedUser.signupPlatform = doc.data().signupPlatform ? doc.data().signupPlatform : 'not set'),
                        (self.selectedUser.operatingSystem = doc.data().operatingSystem ? doc.data().operatingSystem : 'not set'),
                        //
                        (self.selectedUser.masteredVesselIDs = doc.data().vesselsThatIMaster ? doc.data().vesselsThatIMaster : []);
                    self.selectedUser.crewedVesselIDs = doc.data().vesselsThatICrew ? doc.data().vesselsThatICrew : [];
                    self.selectedUser.myVessels = doc.data().myVessels ? doc.data().myVessels : [];
                    self.selectedUser.favouriteVesselID = doc.data().favouriteVesselID ? doc.data().favouriteVesselID : '';
                    self.selectedUser.favouriteVesselName = doc.data().favouriteVesselName ? doc.data().favouriteVesselName : '';
                    self.selectedUser.favouriteVesselCanDoLogs = doc.data().favouriteVesselCanDoLogs ? doc.data().favouriteVesselCanDoLogs : false;
                    self.selectedUser.favouriteVesselIsCompany = doc.data().favouriteVesselIsCompany ? doc.data().favouriteVesselIsCompany : false;
                    self.selectedUser.favouriteVesselIsMine = doc.data().favouriteVesselIsMine ? doc.data().favouriteVesselIsMine : false;
                    self.selectedUser.favouriteFixed = doc.data().favouriteFixed ? doc.data().favouriteFixed : false;
                    self.selectedUser.profilingInfo = doc.data().profilingInfo ? doc.data().profilingInfo : {};
                    self.selectedUser.hasLoggedInOnApp = doc.data().hasLoggedInOnApp ? doc.data().hasLoggedInOnApp : false;
                    self.selectedUser.hasIndex = false;
                    self.selectedUser.lastAppLoginVersion = doc.data().lastAppLoginVersion ? doc.data().lastAppLoginVersion : 'not set';
                    //   self.mondayStatus = {
                    //     userInMonday: doc.data().mondayStatus.userInMonday
                    //       ? doc.data().mondayStatus.userInMonday
                    //       : false,
                    //     userType: doc.data().mondayStatus.userType
                    //       ? doc.data().mondayStatus.userType
                    //       : "Unknown",
                    //     comments: doc.data().mondayStatus.comments
                    //       ? doc.data().mondayStatus.comments
                    //       : "",
                    //     mondaySignUpDate: doc.data().mondayStatus.mondaySignUpDate
                    //       ? doc.data().mondayStatus.mondaySignUpDate.toDate()
                    //       : doc.data().signUpDate.toDate(),
                    //   };

                    self.selectedUser.profileFixed = doc.data().publicProfileData ? true : false;
                    self.mondayStatus = doc.data().mondayStatus
                        ? {
                              userInMonday: doc.data().mondayStatus.userInMonday ? doc.data().mondayStatus.userInMonday : false,
                              userType: doc.data().mondayStatus.userType ? doc.data().mondayStatus.userType : 'New Lead',
                              comments: doc.data().mondayStatus.comments ? doc.data().mondayStatus.comments : '',
                              mondaySignUpDate: doc.data().mondayStatus.mondaySignUpDate
                                  ? doc.data().mondayStatus.mondaySignUpDate.toDate()
                                  : new Date()
                          }
                        : {
                              userInMonday: false,
                              userType: 'New Lead',
                              comments: '',
                              mondaySignUpDate: doc.data().signUpDate.toDate()
                          };
                })
                .then(async function() {
                    await db
                        .collection('userdata')
                        .doc(userid)
                        .collection('myCompanies')
                        .get()
                        .then(snap => {
                            self.selectedUser.myCompanies = [];
                            snap.forEach(docRef => {
                                self.selectedUser.myCompanies.push(docRef.data().companyName);
                            });
                        });
                })
                .then(async function() {
                    self.selectedUser.crewedVesselIDs.forEach(async vesselID => {
                        await db
                            .collection('vessels')
                            .doc(vesselID)
                            .get()
                            .then(docRef => {
                                self.selectedUser.crewedVessels.push(docRef.data().vesselName);
                            });
                    });
                })
                .then(async function() {
                    self.selectedUser.masteredVesselIDs.forEach(async vesselID => {
                        await db
                            .collection('vessels')
                            .doc(vesselID)
                            .get()
                            .then(docRef => {
                                self.selectedUser.masteredVessels.push(docRef.data().vesselName);
                            });
                    });
                })
                .then(async function() {
                    await self.checkAlgolia(userid);
                })
                .then(function() {
                    NProgress.done();
                    self.showProfile = true;
                })
                .catch(function(err) {
                    NProgress.done();
                    console.log('Error', err);
                });
        },

        close() {
            this.selectedUserID = '';
            this.selectedUser = {
                userid: '',
                username: '',
                useremail: '',
                userphone: '',
                industry: '',
                userrole: '',
                userstate: '',
                signUpDate: '',
                anniversaryDate: '',
                maxVessels: '',
                adminNotes: '',
                doesDiving: '',
                isTrainer: '',
                myVessels: [],
                lastCharge: '',
                nextCharge: '',
                lastPayment: '',
                masteredVesselIDs: [],
                crewedVesselIDs: [],
                masteredVessels: [],
                crewedVessels: [],
                favouriteVesselID: '',
                favouriteVesselName: '',
                favouriteVesselCanDoLogs: false,
                favouriteVesselIsCompany: false,
                favouriteVesselIsMine: false,
                signupPlatform: '',
                favouriteFixed: false,
                profilingInfo: {}
            };
            this.showProfile = false;
            this.showSmsModal = false;
            this.textMessage = '';
            this.mondayStatus = {
                userInMonday: false,
                userType: 'New Lead',
                comments: '',
                mondaySignUpDate: new Date()
            };
            this.showMondayModal = false;
        },

        updateProfile(id) {
            console.log('Sending UserID:', id);
            this.showProfile = false;
            let self = this;
            console.log(self.selectedUser);
            this.$router.push({
                name: 'UpdateProfile',
                params: {
                    sentUser: self.selectedUser
                }
            });
        },

        loadSquareSettings(user) {
            console.log(user);
            this.$router.push({
                name: 'UserSquareSettings',
                params: {
                    sentUserID: user.userid
                }
            });
        },

        updateEmail(id) {
            // console.log('Sending UserID:',id);
            this.showProfile = false;
            let self = this;
            // console.log(self.selectedUser);
            this.$router.push({
                name: 'UpdateEmail',
                params: {
                    sentUser: self.selectedUser
                }
            });
        },

        updatePhone(id) {
            // console.log('Sending UserID:',id);
            this.showProfile = false;
            let self = this;
            // console.log(self.selectedUser);
            this.$router.push({
                name: 'UpdatePhone',
                params: {
                    sentUser: self.selectedUser
                }
            });
        },

        sendAlert(userID, userName) {
            this.$router.push({
                name: 'AddAlert',
                params: {
                    userID: userID,
                    userName: userName
                }
            });
        },

        fixMyVesselsBug(usrs) {
            console.log(usrs);
            NProgress.start();
            let self = this;
            var adminUpdateMultipleUsers = firebase.functions().httpsCallable('adminUpdateMultipleUsers');
            adminUpdateMultipleUsers({
                listOfUserIDs: usrs,
                updateData: {
                    myVessels: []
                }
            })
                .then(result => {
                    setTimeout(() => {
                        NProgress.done();
                    }, 2000);
                })
                .catch(error => {
                    console.log('Error');
                    // NProgress.done();
                });
        },

        async fixFavourite(selectedUser) {
            console.log(selectedUser);
            // this.showProfile = false;
            // this.$router.push({ name: 'FixFavourites', params: {
            //     sentUser: selectedUser
            //   }
            // });
            let self = this;
            let newFave = {
                favouriteVesselName: '',
                favouriteVesselID: '',
                favouriteVesselCanDoLogs: false,
                favouriteVesselIsCompany: false,
                favouriteVesselIsMine: false
            };
            let faveVesselName = '';

            if (selectedUser.myVessels.length > 0) {
                newFave = {
                    favouriteVesselName: selectedUser.myVessels[0].vesselName,
                    favouriteVesselID: selectedUser.myVessels[0].vesselID,
                    favouriteVesselCanDoLogs: true,
                    favouriteVesselIsCompany: false,
                    favouriteVesselIsMine: true
                };
            } else {
                if (selectedUser.masteredVesselIDs.length > 0) {
                    await db
                        .collection('vessels')
                        .doc(selectedUser.masteredVesselIDs[0])
                        .get()
                        .then(doc => {
                            faveVesselName = doc.data().vesselName ? doc.data().vesselName : 'Name not found';
                            newFave = {
                                favouriteVesselName: faveVesselName,
                                favouriteVesselID: selectedUser.masteredVesselIDs[0],
                                favouriteVesselCanDoLogs: true,
                                favouriteVesselIsCompany: doc.data().isCompanyVessel ? doc.data().isCompanyVessel : false,
                                favouriteVesselIsMine: false
                            };
                        });
                } else {
                    newFave = {
                        favouriteVesselName: 'No vessel set',
                        favouriteVesselID: ' ',
                        favouriteVesselCanDoLogs: false,
                        favouriteVesselIsCompany: false,
                        favouriteVesselIsMine: false
                    };
                }
            }

            console.log(newFave);

            NProgress.start();
            var adminFixFavourite = firebase.functions().httpsCallable('adminFixFavourite');
            adminFixFavourite({
                sentUser: selectedUser,
                newFave: newFave
            })
                .then(function(result) {
                    var sanitizedMessage = result.data.text;
                    // console.log('Result',sanitizedMessage);
                    console.log('Success Message: ', result.data.message);
                    NProgress.done();
                    self.close();
                })
                .catch(function(error) {
                    // console.log('Error',error.text);
                    console.log('Error Message: ', error.message);
                    NProgress.done();
                });
        },

        simulateConnection() {}
    }
};
</script>

<style lang="scss" scoped>
ol,
ul {
    li {
        margin-bottom: 10px;
        cursor: pointer;

        div {
            display: inline-block;

            // &:hover {
            //   color: #209cee;
            // }
            &.fixed {
                color: limegreen;
            }

            &.app {
                color: dodgerblue;
                // font-weight: bold;
            }

            &.web {
                color: green;
                // font-weight: bold;
            }

            &.red {
                color: red !important;
            }
        }
    }
}

span {
    &.app {
        color: cadetblue;
        font-weight: bold;
    }

    &.web {
        color: green;
        font-weight: bold;
    }
}

.modal {
    .modal-card {
        .modal-card-foot {
            flex-wrap: wrap !important;

            .button {
                margin-bottom: 8px !important;
            }
        }
    }
}

.textarea.padded {
    min-height: 150px;
}

.expired {
    color: red;
}

.warning {
    color: orange;
}
.buttons {
    &.floating {
        position: fixed;
        left: 90px;
        bottom: 30px;
        z-index: 99;
        .button {
            padding: 16px 25px;
            height: auto;
            font-size: 1.25rem;
        }
    }
}
.button {
    &.square {
        margin: 0 10px;
    }
}

.copy-prompt {
    position: fixed;
    top: 80px;
    right: 20px;
    background: #88de88;
    color: #fff;
    padding: 1rem;
    border-radius: 6px;
    opacity: 0;
    transition: 0.2s;
    z-index: 9999;

    &.visible {
        opacity: 1;
    }
}
</style>
