<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5 hideForPrint">Print a Vessel Invoice</h3>
                <br />
                <!--    <div class="input-wrapper">
              <label>Source Collection Path (no trailing slashes)</label>
              <input class="input" type="text" placeholder="Paste source doc path" v-model="sourcePath">
            </div> -->

                <div v-if="!isSelected" class="notification is-primary hideForPrint">
                    <input
                        class="input"
                        v-model="findName"
                        id="filtername"
                        autocomplete="off"
                        type="text"
                        placeholder="Start typing vessel name..."
                    />
                </div>

                <div class="buttons hideForPrint">
                    <span v-if="findName != ''" class="button is-warning" @click="clearVessel()">Clear</span>
                </div>

                <hr class="hideForPrint" />

                <div v-if="selectedVessel != ''" class="highlight hideForPrint">
                    {{ selectedVessel.vesselName }} ({{ selectedVessel.vesselOwnerName }})
                </div>

                <div v-if="findName != '' && !isSelected">
                    <div v-for="(vessel, index) in filteredNames" :key="index">
                        <div class="buttons">
                            <span class="button is-light" @click="selectVessel(vessel)">{{ vessel.vesselName }} ({{ vessel.vesselOwnerName }})</span>
                        </div>
                    </div>
                </div>

                <div class="clear"></div>

                <div v-if="findName != '' && isSelected">
                    <div class="hideForPrint" v-if="allInvoices.length > 0">
                        <div v-for="invoice in allInvoices" :key="invoice.id">
                            <span class="button is-light" @click="previewInvoice(invoice)">
                                {{ invoice.invoiceDate }}
                            </span>
                        </div>
                    </div>

                    <div class="container hideForPrint" v-else>
                        <p>No invoices found.</p>
                        <p>&nbsp;</p>
                    </div>

                    <hr class="hideForPrint" />

                    <vue-html2pdf
                        :show-layout="true"
                        :float-layout="false"
                        :enable-download="true"
                        :preview-modal="false"
                        :paginate-elements-by-height="1400"
                        :manual-pagination="false"
                        pdf-content-width="700px"
                        :html-to-pdf-options="htmlToPdfOptions"
                        @progress="onProgress($event)"
                        @hasStartedGeneration="hasStartedGeneration()"
                        @hasGenerated="hasGenerated($event)"
                        ref="html2Pdf"
                    >
                        <section slot="pdf-content">
                            <div class="invoice-wrapper">
                                <div class="spacer s30"></div>

                                <div v-if="osmsLogo">
                                    <div class="spacer s10"></div>
                                    <img class="osmsLogo" :src="osmsLogo" />
                                </div>

                                <!-- <h1 style="font-family: 'Open Sans', sans-serif;color: #444;font-size: 2rem;">Offshore SMS</h1> -->
                                <h2 style="font-family: 'Open Sans', sans-serif;color: #444;font-size: 1.2rem;">
                                    TAX INVOICE - {{ selectedInvoice.invoiceDate }}
                                </h2>

                                <div class="half left">
                                    <h3
                                        style="font-family: 'Open Sans', sans-serif;color: #444;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;"
                                    >
                                        Invoice To:
                                    </h3>
                                    <p>
                                        {{ selectedInvoice.invoiceTo }}<br />
                                        <span v-if="selectedInvoice.invoiceABN">ABN: {{ selectedInvoice.invoiceABN }}</span>
                                    </p>
                                </div>
                                <div class="half left">
                                    <h3
                                        style="font-family: 'Open Sans', sans-serif;color: #444;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;"
                                    >
                                        Invoice from:
                                    </h3>
                                    <p>
                                        Offshore SMS Pty Ltd<br />
                                        ABN: 96 636 451 217
                                    </p>
                                </div>

                                <table
                                    style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
                                    class="table is-fullwidth"
                                >
                                    <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                                        <tr>
                                            <th
                                                style="font-weight: normal !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;"
                                            >
                                                Description
                                            </th>
                                            <td
                                                style="width: 50% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: right !important;"
                                            >
                                                Amount
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                style="font-weight: normal !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;"
                                            >
                                                {{ selectedInvoice.description }}
                                            </th>
                                            <td
                                                style="width: 50% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: right !important;"
                                            >
                                                ${{ selectedInvoice.invoiceAmount }}
                                            </td>
                                        </tr>
                                        <tr v-if="invoicePaid">
                                            <th
                                                style="font-weight: normal !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important; font-style: italic;"
                                            >
                                                Paid on {{ selectedInvoice.datePaid }}
                                            </th>
                                            <td
                                                style="width: 50% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: right !important;"
                                            ></td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table
                                    style="width: 100%;margin-bottom: 4rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
                                    class="table is-fullwidth"
                                >
                                    <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                                        <tr>
                                            <th
                                                style="width: 50%; border:none;font-weight: normal !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;"
                                            ></th>
                                            <td
                                                style="width: 25% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;"
                                            >
                                                GST
                                            </td>
                                            <td
                                                style="width: 25% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: right !important;"
                                            >
                                                ${{ selectedInvoice.gstAmount }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                style="width: 50%; font-weight: normal !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;"
                                            ></th>
                                            <td
                                                style="width: 25% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;font-weight: bold;"
                                            >
                                                DUE
                                            </td>
                                            <td
                                                v-if="invoicePaid"
                                                style="width: 25% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: right !important;font-weight: bold;"
                                            >
                                                $0
                                            </td>
                                            <td
                                                v-else
                                                style="width: 25% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: right !important;font-weight: bold;"
                                            >
                                                ${{ selectedInvoice.invoiceAmount }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div v-if="selectedInvoice.invoicePaid" class="watermark">PAID</div>
                            </div>
                        </section>
                    </vue-html2pdf>

                    <div v-if="loaded" class="buttons">
                        <button id="pdfButton" class="button is-primary" @click="generateReport()">{{ buttonText }}</button>
                    </div>

                    <!-- <div ref="smsWrapper" class="container" v-if="invoiceSelected">

                <div class="invoice-wrapper">

                      <div class="spacer s30"></div>

                      <h1 style="font-family: 'Open Sans', sans-serif;color: #444;font-size: 2rem;">Offshore SMS</h1>
                      <h2 style="font-family: 'Open Sans', sans-serif;color: #444;font-size: 1.2rem;">TAX INVOICE - {{selectedInvoice.invoiceDate}}</h2>

                      <div class="half left">
                        <h3 style="font-family: 'Open Sans', sans-serif;color: #444;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">Invoice To:</h3>
                        <p>{{selectedInvoice.invoiceTo}}<br>
                          {{selectedInvoice.invoiceABN}}
                        </p>
                      </div>
                      <div class="half left">
                        <h3 style="font-family: 'Open Sans', sans-serif;color: #444;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">Invoice from:</h3>
                        <p>Offshore SMS Pty Ltd<br>
                          ABN: 96 636 451 217
                        </p>
                      </div>

                      <table style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;" class="table is-fullwidth">
                        <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                          <tr>
                            <th style="font-weight: normal !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">Description</th>
                            <td style="width: 50% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: right !important;">Amount</td>
                          </tr>
                          <tr>
                            <th style="font-weight: normal !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">{{selectedInvoice.description}}</th>
                            <td style="width: 50% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: right !important;">${{selectedInvoice.invoiceAmount}}</td>
                          </tr>
                          <tr v-if="invoicePaid">
                            <th style="font-weight: normal !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important; font-style: italic;">Paid on {{selectedInvoice.datePaid}}</th>
                            <td style="width: 50% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: right !important;"></td>
                          </tr>
                        </tbody>
                      </table>

                      <table style="width: 100%;margin-bottom: 4rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;" class="table is-fullwidth">
                        <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                          <tr>
                            <th style="width: 50%; border:none;font-weight: normal !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;"></th>
                            <td style="width: 25% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">GST</td>
                            <td style="width: 25% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: right !important;">${{selectedInvoice.gstAmount}}</td>
                          </tr>
                          <tr>
                            <th style="width: 50%; font-weight: normal !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;"></th>
                            <td style="width: 25% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;font-weight: bold;">DUE</td>
                            <td v-if="invoicePaid" style="width: 25% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: right !important;font-weight: bold;">$0</td>
                            <td v-else style="width: 25% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: right !important;font-weight: bold;">${{selectedInvoice.invoiceAmount}}</td>
                          </tr>
                        </tbody>
                      </table>

                      <div v-if="selectedInvoice.invoicePaid" class="watermark">PAID</div>

                </div>



                <div v-if="loaded" class="buttons">
                  <button id="pdfButton" class="button is-primary" @click="savePDF()">{{buttonText}}</button>
                </div>




              </div> -->

                    <div class="clear"></div>
                </div>

                <div class="clear"></div>
                <div class="spacer s30"></div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

import VueHtml2pdf from 'vue-html2pdf';

export default {
    name: 'PrintVesselInfo',
    components: {
        VueHtml2pdf
    },
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
        // this.finalVesselID = this.$route.query.vid ? this.$route.query.vid : this.vesselID ? this.vesselID : '';
        // this.finalInvoiceID = this.$route.query.iid ? this.$route.query.iid : this.invoiceID ? this.invoiceID : '';
    },

    data: function() {
        return {
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            allVessels: [],
            isSelected: false,
            findName: '',
            hideExpired: true,
            selectedVessel: '',
            fleetLogo: '',

            firebaseid: '',
            buttonText: 'Download PDF',

            invoiceTo: '',
            invoiceDate: '',
            description: '',
            invoiceAmount: 0,
            gstAmount: 0,
            invoiceABN: '',
            loaded: false,
            invoicePaid: false,
            datePaid: '',
            finalVesselID: '',
            finalInvoiceID: '',

            allInvoices: [],
            invoiceSelected: false,
            selectedInvoice: '',

            htmlToPdfOptions: {
                margin: 0.3,

                filename: `Tax Invoice.pdf`,

                image: {
                    type: 'jpeg',
                    quality: 2
                },

                enableLinks: false,

                html2canvas: {
                    scale: 1,
                    useCORS: true
                },

                jsPDF: {
                    unit: 'in',
                    format: 'a4',
                    orientation: 'portrait'
                }
            }
        };
    },

    computed: {
        userData() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.userData
                ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2'
                    ? true
                    : false
                : false;
        },
        pathsAreValid() {
            return this.sourcePath == '' || this.targetPath == '';
        },
        filteredVessels() {
            return this.hideExpired ? this.allVessels.filter(vessel => !vessel.isExpired).map(vess => vess) : this.allVessels;
        },
        filteredNames() {
            let filter = new RegExp(this.findName, 'i');
            return this.filteredVessels.filter(user => user.vesselName.match(filter));
        },
        invoiceName() {
            return 'Offshore SMS Invoice ' + this.invoiceTo;
        },
        osmsLogo() {
            return require('@/assets/img/offshoresms-new-logo.png');
        }
        // selectedItemIDs() {
        //   return this.selectedItems.map((item) => item.id);
        // }
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                // console.log(user);
                this.loadAllVessels();
                this.firebaseid = user.uid;
                // if (this.finalInvoiceID != '') {
                //   this.loadInvoiceData();
                // }
            }
        },

        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        onProgress($event) {
            console.log($event);
        },

        loadAllVessels: function() {
            let self = this;
            db.collection('vessels')
                .orderBy('vesselOwnerName', 'asc')
                .onSnapshot(function(querySnapshot) {
                    self.allVessels = [];
                    querySnapshot.forEach(doc => {
                        self.allVessels.push({
                            vesselID: doc.id,
                            vesselUVI: doc.data().vesselUVI ? doc.data().vesselUVI : '',
                            vesselName: doc.data().vesselName,
                            vesselOwnerName: doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?',
                            vesselOwnerID: doc.data().vesselOwnerID
                        });
                    });
                });
        },

        loadInvoices() {
            let self = this;
            NProgress.start();
            db.collection('vessels')
                .doc(this.selectedVessel.vesselID)
                .collection('vesselInvoices')
                .get()
                .then(function(querySnapshot) {
                    self.allInvoices = [];
                    querySnapshot.forEach(doc => {
                        self.allInvoices.push({
                            id: doc.id,
                            invoiceTo: doc.data().invoiceTo ? doc.data().invoiceTo : 'No name',
                            invoiceABN: doc.data().abn ? doc.data().abn : '',
                            invoiceDate: doc.data().invoiceDate ? moment(doc.data().invoiceDate.toDate()).format('D MMMM YYYY') : '',
                            datePaid: doc.data().datePaid ? moment(doc.data().datePaid.toDate()).format('dddd D MMMM YYYY') : '',
                            description: doc.data().description ? doc.data().description : 'No description',
                            invoiceAmount: doc.data().invoiceAmount ? doc.data().invoiceAmount : 0,
                            gstAmount: doc.data().gstAmount ? doc.data().gstAmount : 0,
                            invoicePaid: doc.data().isPaid ? doc.data().isPaid : false
                        });
                    });
                })
                .then(function() {
                    self.loaded = true;
                    NProgress.done();
                });
        },

        previewInvoice(invoice) {
            console.log(invoice);
            this.invoiceSelected = true;
            this.selectedInvoice = invoice;
        },

        // loadInvoiceData: function() {
        //   let self = this;

        //   NProgress.start();
        //   db.collection('vessels').doc(this.finalVesselID).collection('vesselInvoices').doc(this.finalInvoiceID).get()
        //   .then(function(doc) {
        //     self.invoiceTo = doc.data().invoiceTo ? doc.data().invoiceTo : 'No name';
        //     self.invoiceABN = doc.data().abn ? doc.data().abn : 'ABN:';
        //     self.invoiceDate = doc.data().invoiceDate ? moment(doc.data().invoiceDate.toDate()).format('D MMMM YYYY') : '';
        //     self.datePaid = doc.data().datePaid ? moment(doc.data().datePaid.toDate()).format('dddd D MMMM YYYY') : '';
        //     self.description = doc.data().description ? doc.data().description : 'No description';
        //     self.invoiceAmount = doc.data().invoiceAmount ? doc.data().invoiceAmount : 0;
        //     self.gstAmount = doc.data().gstAmount ? doc.data().gstAmount : 0;
        //     self.invoicePaid = doc.data().isPaid ? doc.data().isPaid : false;

        //   })
        //   .then(function(){
        //     self.loaded = true;
        //     NProgress.done();
        //   });

        // },

        savePDF: function() {
            //console.log(this.$refs.smsWrapper);

            this.buttonText = 'Preparing PDF. Please wait...';

            //NProgress.start();

            // let pdfName = 'test';
            // var doc = new jsPDF();
            // //doc.text(this.vesselID, 10, 10);
            // doc.text(this.$refs.smsWrapper, 10, 10);
            // doc.save(pdfName + '.pdf');

            //

            var self = this;

            // First we take the HTML of the page
            var html = document.documentElement.outerHTML;

            // Now we prepare the data that we pass to the API
            // In this example we want to hide the section with the example so we pass custom CSS
            // Note that you don't need to pass any other parameters if your defaults in the members area are already good
            var css = '.header { display:none !important;} .buttons {display:none;} .hideForPrint {display:none !important;}';
            var data = { html: html, license: 'E197E92RdrKd', css: css };
            var serialized = Object.keys(data)
                .map(function(k) {
                    return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
                })
                .join('&');

            //console.log(serialized);

            // You can insert an "in progress" message here

            // We now prepare the API call
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function() {
                var a;
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // The PDF is now generated
                    // You can remove the "in progress" message here

                    // Now we show the PDF to the user
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    a.download = self.invoiceName + '.pdf';
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    //console.log("One");
                    self.buttonText = 'Download PDF';
                }
            };

            // This is the actual call to our API
            xhttp.open('POST', 'https://pdfmyurl.com/api', true);
            xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhttp.responseType = 'blob';
            xhttp.send(serialized);
            //console.log("Two");
        },

        isNumber: function($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        clearVessel: function() {
            this.findName = '';
            this.isSelected = false;
            this.selectedVessel = '';
            this.invoiceSelected = false;
            this.selectedInvoice = '';
        },

        selectVessel(vessel) {
            NProgress.start();
            console.log(vessel);
            let self = this;
            this.selectedVessel = vessel;
            this.isSelected = true;
            setTimeout(() => {
                self.loadInvoices();
            }, 1000);
        },

        formatDate: function(sentDate, format) {
            let fixDate = sentDate ? sentDate.toDate() : new Date();
            //console.log("Fix date:",fixDate);
            return moment(fixDate).format(format);
        }
    }
};
</script>

<style lang="scss" scoped>
.site-wrapper .container {
    max-width: 850px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
.traffic-light {
    position: relative;
    span {
        float: left;
        margin-right: 10px;
    }
    .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
            background: red;
            &.active {
                background: red;
            }
        }
        &.orange {
            background: orange;
            &.active {
                background: orange;
            }
        }
        &.green {
            background: #03d203;
            &.active {
                background: #03d203;
            }
        }
    }
}

// input[type="text"] {
//   width: 90%;
//   float: left;
//   display: block;
//   margin-bottom: 2rem;
//   // &:after {
//   //   content: "\f16c";
//   //   font-family: "Font Awesome 5 Brands";
//   //   color: red;
//   // }
//   // &.valid:after {

//   // }
// }
.icon-wrap {
    display: block;
    float: left;
    height: 35px;
    margin-left: 10px;
    font-size: 1.5rem;
    color: red;
    &.valid {
        color: green;
    }
}
label {
    margin-bottom: 10px;
    display: block;
}

.osmsLogo {
    max-width: 220px;
}
// .is-checkradio[type=checkbox] {
//   opacity: 1.0;
//   -ms-transform: scale(2); /* IE */
//   -moz-transform: scale(2); /* FF */
//   -webkit-transform: scale(2); /* Safari and Chrome */
//   -o-transform: scale(2); /* Opera */
//   transform: scale(2);
//   padding: 10px;
//   margin-top: 10px;
//   margin-left: 10px;
// }

.site-wrapper .container {
    max-width: 1050px;
}
.buttons {
    margin-bottom: 5px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
input[type='text'] {
    padding: 2rem 1rem;
}
.form-group {
    label {
        top: auto;
        bottom: -25px;
    }
}
.traffic-light {
    position: relative;
    span {
        float: left;
        margin-right: 10px;
    }
    .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
            background: red;
            &.active {
                background: red;
            }
        }
        &.orange {
            background: orange;
            &.active {
                background: orange;
            }
        }
        &.green {
            background: #03d203;
            &.active {
                background: #03d203;
            }
        }
    }
}
.highlight {
    background: dodgerblue;
    padding: 15px;
    color: #fff;
}
.modal {
    .modal-card {
        .modal-card-head {
            z-index: 999 !important;
        }
        .modal-card-foot {
            z-index: 999 !important;
        }
    }
}
input[type='text'],
input[type='tel'] {
    padding: 2rem 1rem;
}

textarea {
    min-height: 120px;
    height: 400px;
    margin-top: 10px;
    padding: 1.5rem 1rem;
}
table {
    td {
        padding-bottom: 21px;
        &.is-updated {
            color: green;
            font-weight: bold;
        }
        .small {
            font-size: 0.8rem;
            margin-top: 5px;
            text-align: center;
        }
    }
}
.show-details {
    cursor: pointer;
    &:hover {
        color: dodgerblue;
    }
}

hr {
    margin-bottom: 4rem !important;
}
h3 {
    font-size: 1.2rem !important;
    text-transform: uppercase !important;
    // margin-top: 2.5rem !important;
}
table {
    margin-top: 1rem !important;
    table-layout: fixed !important;
    width: 100% !important;
    // th {
    //  font-weight: normal !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;
    // }
    // td {
    //  width: 50% !important;border-bottom: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;
    // }
}
.pagebreak {
    display: block !important;
    clear: both !important;
    page-break-after: always !important;
}

.invoice-wrapper {
    position: relative;
}
.watermark {
    position: absolute;
    color: red;
    opacity: 0.2;
    text-transform: uppercase;
    font-size: 7rem;
    font-weight: bold;
    z-index: 99;
    background: transparent;
    text-align: center;
    top: 60%;
    left: 55%;
    transform-origin: left;
    transform: translate(-50%, -50%) rotate(330deg);
}

.half {
    width: 50%;
    margin-bottom: 1.5rem;

    &.left {
        float: left;
    }

    &.right {
        float: right;
    }

    h3 {
        margin-top: 1rem;
    }
}
</style>
