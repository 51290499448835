<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">Write a new Document</h3>
                <br />

                <div class="input-wrapper">
                    <label>Target Collection Path (no trailing slashes)</label>
                    <input class="input" type="text" placeholder="Paste source doc path" v-model="targetPath" />

                    <!-- <label>Title</label>
                    <input class="input" type="text" placeholder="Write title..." v-model="newTitle" /> -->
                </div>
                <!--
                <div class="clear"></div>

                <div class="input-wrapper">
                    <label>Details</label>
                    <textarea class="input" placeholder="Write details" v-model="newDetails"></textarea>
                </div>

                <div class="clear"></div>

                <div class="input-wrapper">
                    <label>Index</label>
                    <input class="input" type="tel" v-model="newIndex" />
                </div> -->

                <div class="input-wrapper">
                    <label>New Doc ID</label>
                    <input class="input" type="text" v-model="newDocID" />
                </div>

                <div class="clear"></div>

                <div class="spacer s10"></div>

                <pre>{{ documentData }}</pre>

                <div class="spacer s30"></div>

                <div class="buttons">
                    <span :disabled="hideButton" class="button is-warning is-medium" @click="saveNewDocument">Save New Doc</span>
                </div>

                <hr />
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '../main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
//import * as moment from 'moment';

var adminAddNewDocument = firebase.functions().httpsCallable('adminAddNewDocument');
var adminSetNewDocument = firebase.functions().httpsCallable('adminSetNewDocument');

export default {
    components: {},
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            newTitle: '',
            newDetails: '',
            newIndex: 99,
            targetPath: '/companies/rjaE1N7kTgAw1DccYPF1/nightlyLiveAudits',
            documentData: {
                snapDefects: 0,
                snapDatesDue: 2,
                snapDatesOverdue: 0,
                totalAssets: 2
            },
            newDocID: '04102024'
        };
    },

    computed: {
        isSteve() {
            return this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' || this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1';
        },
        hideButton() {
            return this.targetPath == '' || Object.keys(this.documentData).length == 0;
        }
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                // console.log(user);
            }
        },

        saveNewDocument() {
            let self = this;

            let message;
            message = {
                title: 'Save new document?',
                body: 'Click YES below proceed.'
            };
            let options = {
                okText: 'YES, SAVE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(async dialog => {
                    NProgress.start();
                    let result;

                    if (self.newDocID == '') {
                        result = await adminAddNewDocument({
                            collectionPath: self.targetPath,
                            newData: self.documentData
                        });
                    } else {
                        result = await adminSetNewDocument({
                            collectionPath: self.targetPath,
                            docID: self.newDocID,
                            newData: self.documentData
                        });
                    }

                    var sanitizedMessage = result.data.text;
                    console.log('Result', sanitizedMessage);
                    console.log('Message', result.data.message);
                    // self.clear();
                    NProgress.done();
                })
                .catch(error => {
                    console.log(error);
                    NProgress.done();
                });
        },

        clear() {
            this.newTitle = '';
            this.newDetails = '';
            this.newIndex = '';
            this.targetPath = '';
        }
    }
};
</script>

<style lang="scss" scoped>
input[type='text'],
input[type='tel'] {
    width: 100%;
    float: left;
    display: block;
    margin-bottom: 2rem;
    padding: 15px;
    height: auto;
    // &:after {
    //   content: "\f16c";
    //   font-family: "Font Awesome 5 Brands";
    //   color: red;
    // }
    // &.valid:after {

    // }
}
label {
    margin-bottom: 10px;
    display: block;
}
textarea {
    width: 100%;
    padding: 15px;
    min-height: 200px;
    margin-bottom: 2rem;
}
.icon-wrap {
    display: block;
    float: left;
    height: 35px;
    margin-left: 10px;
    font-size: 1.5rem;
    color: red;
    &.valid {
        color: green;
    }
}
</style>
