<template>
    <div id="mobilenavwrapper">
        <div id="nav-icon" ref="nav-icon" v-bind:class="{ active: isActive }" v-on:click="toggle">
            <span></span>
            <span></span>
            <span></span>
        </div>

        <div class="version">v16.12.8</div>

        <router-link v-if="isSteve" class="map-link" to="active-vessels-map"><font-awesome-icon icon="ship"/></router-link>

        <div v-if="seen" class="dropmenu" v-closable="{ exclude: ['nav-icon'], handler: 'closeModal' }">
            <ul>
                <li><router-link to="/">Home</router-link></li>
                <li v-if="isSteve"><router-link to="users" v-if="user">All Users</router-link></li>
                <li><router-link to="activity" v-if="user">All Activity</router-link></li>
                <li><router-link to="sign-in" v-if="!user">Sign-in</router-link></li>
                <li><a v-on:click="signOut" v-if="user">Sign-out</a></li>
            </ul>
        </div>

        <div class="clear"></div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// import 'firebase/firestore';
// import 'firebase/compat/functions';
//import { store } from '../store/store';

export default {
    data: function() {
        return {
            isActive: false,
            seen: false,
            firebaseid: '',
            firebasedisplayname: ''
        };
    },

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.firebaseid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2';
        }
    },

    mounted() {},

    watch: {
        $route(to, from) {
            this.isActive = false;
            this.seen = false;
        }
    },
    methods: {
        signOut: function() {
            var self = this;

            firebase
                .auth()
                .signOut()
                .then(() => {
                    this.$store.dispatch('resetStates');
                    this.$router.replace('sign-in');
                });
        },

        toggle: function(event) {
            this.isActive = !this.isActive;
            this.seen = !this.seen;
        },

        closeModal: function() {
            this.isActive = !this.isActive;
            this.seen = !this.seen;
        }
    }
};
</script>

<style lang="scss">
@import '././node_modules/bulma/sass/utilities/_all.sass';

#mobilenavwrapper {
    .user-status {
        position: absolute;
        right: 120px;
        top: 17px;
        font-size: 0.9rem;
        @include mobile {
            display: none;
        }
    }
}

.version {
    float: revert;
    position: absolute;
    right: 70px;
    top: 17px;
    color: #245e8b;
}

.map-link {
    display: inline-block;
    vertical-align: middle;
    height: 50px;
    /* line-height: 50px; */
    font-size: 21px;
    color: mediumseagreen;
    padding: 10px;
}
</style>
