<template>
  
  <div>

      <div class="site-wrapper no-header">
      
        <div class="container">

            <h3 class="is-size-5">Latest Business Data</h3><br>

            <div class="tabs is-boxed is-centered">
              <ul>
                <li :class="{'active': tab == 'stats'}">
                  <a @click="tab = 'stats'"><font-awesome-icon icon="globe" />&nbsp; <span>Stats</span></a>
                </li>
                <li :class="{'active': tab == 'activity'}">
                  <a @click="tab = 'activity'"><font-awesome-icon icon="list-ul" />&nbsp; <span>Activity</span></a>
                </li>
                <li :class="{'active': tab == 'trials'}">
                  <a @click="tab = 'trials'"><font-awesome-icon icon="calendar-check" />&nbsp; <span>Trials</span></a>
                </li>
              </ul>
            </div>

            <div class="tab-content" v-if="tab == 'stats'">

                <!-- User data history ======================================================================= -->
                <hr class="dashed">

                <table v-if="!loadingUsers" class="table clean fullwidth">
                    <tr>
                        <th>Total Users</th>
                        <td>{{ userCount }}</td>
                    </tr>
                    <tr>
                        <th>Users with own vessels</th>
                        <td>{{ usersWithOwnVessels }} ({{ Math.round(usersWithOwnVessels/userCount * 100) }}%)</td>
                    </tr>
                    <tr>
                        <th>Users that crew other vessels</th>
                        <td>{{ usersAsCrew }} ({{ Math.round(usersAsCrew/userCount * 100) }}%)</td>
                    </tr>
                </table>
                <div v-else>
                    <p><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading user data...</p>
                    <div class="spacer 30"></div>
                </div>

                <div class="buttons">
                    <span class="button is-info" @click="loadUserData()">Load User Data</span>
                </div>

                <!-- Payment history ======================================================================= -->
                <hr class="dashed">

                <table v-if="!loadingPayments" class="table clean fullwidth">
                    <tr>
                        <th>Vessel name</th>
                        <td>Payment history</td>
                    </tr>
                    <tr v-for="(vessel, idx) in vesselPayments" :key="`vessel-${idx}`">
                        <th>{{ vessel.vesselName }}</th>
                        <td>
                            <div v-for="(pmt, inx) in vessel.paymentHistory" :key="`pmt-${inx}`">
                                <span class="green bold">{{ pmt.action }}</span> &nbsp; {{ simpleDate(pmt.date.toDate()) }} (${{ pmt.amount }})
                                <!-- ${{ pmt.amount }} ({{ pmt.invoice }}) -->
                            </div>    
                        </td>
                    </tr>
                </table>
                <div v-else>
                    <p><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading user data...</p>
                    <div class="spacer 30"></div>
                </div>

                <div class="buttons">
                    <span class="button is-skip" @click="loadVesselPayments()">Load Payments History</span>
                </div>
                

                <!-- Vessel data ======================================================================= -->
                <hr class="dashed">

                <table v-if="!loadingVessels" class="table clean fullwidth">
                    <tr>
                        <th>Total Vessels</th>
                        <td>{{ vesselCount }} (+ {{ expiredVessels }} expired)</td>
                    </tr>
                    <tr>
                        <th>Personal vessels</th>
                        <td>{{ personalVessels }} ({{ Math.round(personalVessels/vesselCount * 100) }}%)</td>
                    </tr>
                    <tr>
                        <th>Fleet vessels</th>
                        <td>{{ fleetVessels }} ({{ Math.round(fleetVessels/vesselCount * 100) }}%)</td>
                    </tr>
                    <tr>
                        <th>Vessels on any plan</th>
                        <td>{{ vesselsWithPlanCount }} ({{ Math.round(vesselsWithPlanCount/vesselCount * 100) }}%)</td>
                    </tr>
                    <tr>
                        <th>Vessels on Logs only plan</th>
                        <td>{{ vesselsOnLogsPlan }} ({{ Math.round(vesselsOnLogsPlan/vesselCount * 100) }}%)</td>
                    </tr>
                    <tr>
                        <th>Vessels on Logs+SMS plan</th>
                        <td>{{ vesselsOnSmsPlan }} ({{ Math.round(vesselsOnSmsPlan/vesselCount * 100) }}%)</td>
                    </tr>
                    <tr>
                        <th>Vessels on PRO plan</th>
                        <td>{{ vesselsOnProPlan }} ({{ Math.round(vesselsOnProPlan/vesselCount * 100) }}%)</td>
                    </tr>
                    <tr>
                        <th>Vessels currently paid for</th>
                        <td>{{ currentlyPaidVesselsCount }} ({{ Math.round(currentlyPaidVesselsCount/vesselCount * 100) }}%)</td>
                    </tr>
                </table>
                <div v-else>
                    <p><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading vessel data...</p>
                    <div class="spacer 30"></div>
                </div>

                <div class="buttons">
                    <span class="button is-primary" @click="loadVesselData()">Load Vessel Data</span>
                </div>

                <hr class="dashed">
                
                <h4>Weeks with subscriptions due</h4>

                <table class="table clean fullwidth tally">
                  <tr v-for="(n, index) in 52" @click="showVesselsThisWeek(index + thisWeekNum)">
                    <th :class="{'past': weekIsPast(index + thisWeekNum)}">Week {{ index }}<br>{{ getEndWeekDate(index) }}</th>
                    <td>
                      <div class="counter">{{ showVesselsPerWeek(index + thisWeekNum) }} <span>({{ showVesselsPerWeekTrio(index + thisWeekNum) }})</span></div>
                      <div class="progress-outer">
                        <div class="progress-inner" :class="[showVesselsPerWeek(index + thisWeekNum) < 10 ? 'low' : showVesselsPerWeek(index + thisWeekNum) < 20 ? 'medium' : 'high']" :style="{'width': Math.min(showVesselsPerWeek(index + thisWeekNum),20)/20 * 100 + '%'}"></div>
                      </div>
                    </td>
                  </tr>
                </table>

            </div>

            <div class="tab-content" v-if="tab == 'activity'">

                <!-- Activity history ======================================================================= -->
                <hr class="dashed">

                <h3 class="green">Rebuild for Cron function: <pre>scheduledFunctionRollingActivityUpdate</pre></h3>

                <table v-if="!loadingActivity" class="table clean fullwidth">
                    <tr>
                        <th>Vessel name</th>
                        <td>Revision Activity</td>
                    </tr>
                    <tr v-for="(item, idx) in vesselActivity" :key="`item-${idx}`">
                        <th>{{ item.vesselName }} <br>{{ item.vesselOwnerName }} <br>DUE: {{ simpleDate(item.nextChargeDate.toDate()) }}</th>
                        <td>
                            [{{item.count}}] {{ simpleDate(item.lastRevision.timestamp.toDate()) }}
                        </td>
                    </tr>
                </table>
                <div v-else>
                    <p><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading activity...</p>
                    <div class="spacer 30"></div>
                </div>

                <div class="buttons">
                    <span class="button is-skip" @click="loadVesselActivity()">Load Activity</span>
                </div>

            </div>


            <div class="tab-content" v-if="tab == 'trials'">
            
                <!-- Trials ======================================================================= -->
                <hr class="dashed">

                <table v-if="!loadingTrials" class="table clean fullwidth">
                    <tr>
                        <th>Vessel name</th>
                        <td>Trial Info</td>
                    </tr>
                    <tr v-for="(vessel, idx) in vesselTrials" :key="`vessel-${idx}`">
                        <th>
                          <div>
                            <span @click="vessel.showDetails = !vessel.showDetails">{{ vessel.vesselName }}</span> <br>{{ vessel.vesselOwnerName }} <br>DUE: {{ simpleDate(vessel.nextChargeDate.toDate()) }}
                          </div>
                          <div v-if="vessel.showDetails">
                            {{ vessel.vesselID }}
                          </div>
                        </th>
                        <td>
                            <div class="buttons">
                              <div class="button is-small is-danger" @click="revokeTrial(vessel)">Revoke Trial</div>
                            </div>
                        </td>
                    </tr>
                </table>
                <div v-else>
                    <p><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading trial vessels...</p>
                    <div class="spacer 30"></div>
                </div>

                <div class="buttons">
                    <span class="button is-skip" @click="loadVesselTrials()">Load Trials</span>
                </div>
            
            </div>
            



        </div>

      </div>


      <simple-modal title="This week's vessels" theme="is-primary" v-on:closed="showVesselsModal = false"  v-if="showVesselsModal">
          
          <div v-for="(v, inx) in selectedVessels" :key="`v-${inx}`">
            <div class="single-vessel" :class="{'pro': v.aaaPlans.includes('pro')}">{{ v.vesselName }} - {{ v.aaaPlans.includes('pro') ? 'PRO Plan' : v.aaaPlans.includes('sms') ? 'Logs+SMS' : 'Logs' }}</div>
          </div>

      </simple-modal>





  </div>

</template>


<script> 

    import firebase from 'firebase/compat/app';
    import { db } from '@/main.js';
    // import 'firebase/compat/auth'; 
    import { getAuth, onAuthStateChanged } from "firebase/auth";
    import 'firebase/firestore';
    import 'firebase/compat/functions';
    import NProgress from 'nprogress';  
    import * as moment from 'moment';
    import { QuerySnapshot } from 'firebase/firestore';
    import { settings } from '../main';
    const SimpleModal = () => import('../reusables/SimpleModal.vue');



  export default {

    components: {
      SimpleModal
    },
    mounted() {
      firebase.auth().onAuthStateChanged( this.onUserLogin ); 
    },

    computed: {
      userData() {
        return this.$store.getters.getUser;
      },
      isSteve() {
        return this.userData ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false : false;
      },
      thisWeekNum() {
        let currentDate = new Date();
        let startDate = new Date(currentDate.getFullYear(), 0, 1);
        let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
        return Math.ceil(days / 7);
      },
      userCount() {
        return this.allUsers.length;
      },
      usersWithOwnVessels() {
        return this.allUsers.filter((u) => u.myVessels && u.myVessels.length > 0).length;
      },
      usersAsCrew() {
        return this.allUsers.filter((u) => (u.vesselsThatICrew && u.vesselsThatICrew.length > 0) || (u.vesselsThatImLevelTwo && u.vesselsThatImLevelTwo.length > 0) || (u.vesselsThatIMaster && u.vesselsThatIMaster.length > 0)).length;
      },
      vesselCount() {
        return this.allVessels.filter((v) => !v.isExpired).length;
      },
      currentVessels() {
        return this.allVessels.filter((v) => !v.isExpired);
      },
      personalVessels() {
        return this.currentVessels.filter((v) => !v.isCompanyVessel).length;
      },
      fleetVessels() {
        return this.currentVessels.filter((v) => v.isCompanyVessel).length;
      },
      vesselsWithPlan() {
        return this.currentVessels.filter((v) => v.aaaPlans && v.aaaPlans.length > 0);
      },
      vesselsWithPlanCount() {
        return this.currentVessels.filter((v) => v.aaaPlans && v.aaaPlans.length > 0).length;
      },
      vesselsOnLogsPlan() {
        return this.currentVessels.filter((v) => v.aaaPlans && v.aaaPlans.length > 0 && v.aaaPlans.includes('logs') && !v.aaaPlans.includes('sms')).length;
      },
      vesselsOnSmsPlan() {
        return this.currentVessels.filter((v) => v.aaaPlans && v.aaaPlans.length > 0 && v.aaaPlans.includes('sms') && !v.aaaPlans.includes('pro')).length;
      },
      vesselsOnProPlan() {
        return this.currentVessels.filter((v) => v.aaaPlans && v.aaaPlans.length > 0 && v.aaaPlans.includes('pro')).length;
      },
      expiredVessels() {
        return this.allVessels.filter((v) => v.isExpired).length;
      },
      currentlyPaidVessels() {
        return this.currentVessels.filter((v) => !v.isDemo && !v.isFreebie && v.nextChargeDate && v.nextChargeDate.toDate().getTime() > this.today);
      },
      currentlyPaidVesselsCount() {
        return this.currentVessels.filter((v) => !v.isDemo && !v.isFreebie && v.nextChargeDate && v.nextChargeDate.toDate().getTime() > this.today).length;
      },

    },

    data() {
      return {
        tab: 'stats',
        allUsers: [],
        loadingUsers: false,
        allVessels: [],
        loadingVessels: false,
        vesselPayments: [],
        loadingPayments: false,
        today: new Date(),
        selectedVessels: [],
        showVesselsModal: false,
        loadingActivity: false,
        vesselActivity: [],
        loadingTrials: false,
        vesselTrials: [],
      }
    },

    methods: {

      onUserLogin( user ) {
        // if (user) {         

        // }
      },

      close() {
        this.showVesselsModal = false;
      },

      loadUserData() {
        let self = this;
        this.loadingUsers = true;
        db.collection('userdata').get().then((querySnapshot) => {
            this.allUsers = [];
            querySnapshot.forEach((doc) => {
                if (!doc.data().isExpired) this.allUsers.push(doc.data());
            });
        });
        setTimeout(() => {
            this.loadingUsers = false;
        }, 1000);
      },

      showVesselsPerWeek(weekNum) {
        return this.currentlyPaidVessels.filter((v) => v.weekNumber == weekNum).length;
      },

      showVesselsPerWeekTrio(weekNum) {
        return this.currentlyPaidVessels.filter((v) => v.weekNumber == weekNum - 1 || v.weekNumber == weekNum || v.weekNumber == weekNum + 1).length;
      },

      showVesselsThisWeek(weekNum) {
        this.selectedVessels = this.currentlyPaidVessels.filter((v) => v.weekNumber == weekNum);
        this.showVesselsModal = true;
      },

      weekIsPast(weekNum) {
        // let date = new Date('January 1, 2023');
        let date = new Date();
        date.setDate(date.getDate() + (weekNum * 7));
        // console.log('date',date,this.today);
        return date < this.today; 
      },

      getEndWeekDate(weekNum) {
        // let date = new Date('January 1, 2023');
        let date = new Date();
        let dateOptions = { timeZone: 'Australia/Sydney', month: 'short', day: 'numeric', year: 'numeric' };
        date.setDate(date.getDate() + (weekNum * 7));
        return new Date(date).toLocaleDateString('en-US', dateOptions); 
      },

      simpleDate(date) {
        // let date = new Date('January 1, 2023');
        // let date = new Date();
        let dateOptions = { timeZone: 'Australia/Sydney', month: 'short', day: 'numeric', year: 'numeric' };
        // date.setDate(date.getDate() + (weekNum * 7));
        return date == '' ? '' : new Date(date).toLocaleDateString('en-US', dateOptions); 
      },

      loadVesselData() {
        let self = this;
        let currentDate = new Date();
        let startDate = new Date(currentDate.getFullYear(), 0, 1);
        // console.log('startDate',startDate)
        this.loadingVessels = true;

        db.collection('vessels').get().then((querySnapshot) => {
            this.allVessels = [];
            querySnapshot.forEach((doc) => {

              let data = doc.data();
              let nextChargeDate = doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate() : '';
              if (nextChargeDate != '') {
                let days = Math.floor((nextChargeDate - startDate) / (24 * 60 * 60 * 1000));
                data.weekNumber = Math.ceil(days / 7);
              } else {
                data.weekNumber = -1;
              }                
              this.allVessels.push(data);
              
            });
        });

        setTimeout(() => {
            this.loadingVessels = false;
        }, 1000);

      },

      loadVesselPayments() {
        let self = this;
        // console.log('startDate',startDate)
        this.loadingPayments = true;

        db.collection('vessels').orderBy('lastPaymentEntryDate','desc').get().then((querySnapshot) => {
            this.vesselPayments = [];
            querySnapshot.forEach((doc) => {
              let data = doc.data();    
              if (data.paymentHistory) this.vesselPayments.push({
                vesselName: data.vesselName,
                paymentHistory: data.paymentHistory
              });              
            });
        });

        setTimeout(() => {
            this.loadingPayments = false;
        }, 1000);

      },

      loadVesselActivity() {
        let self = this;
        let today = new Date();
        this.loadingActivity = true;

        db.collection('vessels').orderBy('nextChargeDate','desc').where("nextChargeDate", ">=", today).get().then((querySnapshot) => {
            this.vesselActivity = [];
            querySnapshot.forEach((vessel) => {
              let data = vessel.data();    
              // console.log(data.vesselName)
              if (!data.isExpired && data.vesselOwnerName != 'XYZ Orphan' && !data.isFreebie) {
                db.collection('vessels').doc(vessel.id).collection('vesselRevisions').orderBy('timestamp','desc').get().then((snap) => {
                  if (snap.docs.length > 0) {
                    self.vesselActivity.push({
                      vesselName: data.vesselName,
                      vesselOwnerName: data.vesselOwnerName,
                      nextChargeDate: data.nextChargeDate ? data.nextChargeDate : '',
                      count: snap.size,
                      lastRevision: snap.docs[0].data()
                    });
                  }
                });          
              }
            });
        });

        setTimeout(() => {
            this.loadingActivity = false;
        }, 1000);

      },

      loadVesselTrials() {
        let self = this;
        let today = new Date();
        this.loadingTrials = true;

        db.collection('vessels').orderBy('nextChargeDate','desc').where("isDemo", "==", true).onSnapshot((querySnapshot) => {
            this.vesselTrials = [];
            querySnapshot.forEach((vessel) => {
              let data = vessel.data();    
              // console.log(data.vesselName)
              if (!data.isExpired && data.vesselOwnerName != 'XYZ Orphan') {
                self.vesselTrials.push({
                  vesselName: data.vesselName,
                  vesselID: vessel.id,
                  showDetails: false,
                  vesselOwnerName: data.vesselOwnerName,
                  nextChargeDate: data.nextChargeDate ? data.nextChargeDate : '',
                });      
              }
            });
        });

        setTimeout(() => {
            this.loadingTrials = false;
        }, 1000);

      },

      revokeTrial(vessel) {
        console.log(vessel);
        NProgress.start();
        db.collection('vessels').doc(vessel.vesselID).update({
          freeTrialActivated: false,
          freeTrialEndedBy: 'OffshoreSMS Team',
          freeTrialEndDate: new Date(),
          nextChargeDate: new Date('December 31, 2026'),
          isFreebie: true,
          isDemo: false,
          aaaPlans: ['logs'],
          isPaidFor: false
        }).then(() => {
          var addVesselRevision = firebase.functions().httpsCallable('addVesselRevision');
          addVesselRevision({
            userName: '',
            userID: '',
            vesselName: vessel.vesselName,
            vesselID: vessel.vesselID,
            revisionDetails: 'Free trial completed.',
          });
          let addToVesselPaymentHistory = firebase.functions().httpsCallable('addToVesselPaymentHistory');
          addToVesselPaymentHistory({
            vesselID: vessel.vesselID,
            action: 'trial-end',
            date: new Date().getTime(),
            amount: 0,
            invoice: '',
            method: '',
            notes: 'Free trial completed by OffshoreSMS',
            username: 'OffshoreSMS Team',
            userID: '',
          });  
          NProgress.done();
        })
        .catch((error) => {
          console.log('error',error);
          NProgress.done();
        });
      },

      

      


    },

  }



</script> 




<style lang="scss" scoped>
  
  .site-wrapper .container {
    max-width: 850px;
  }
  svg {
    display: inline-block;
    margin-right: 10px;
  }
  table.clean tr th {
    text-transform: none !important;
  }
  table {
    tr {
      th {
        &.past {
          background: #ffeeee;
        }
      }
    }
    &.tally {
      tr {
        cursor: pointer;    
        background: #fff;
        transition: 0.25s;
        td {
          .counter {
            span {
              display: none;
              
            }
          }
        }
        &:hover {
          background: #f5f5f5;
          td {
            .counter {
              span {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
  
  .progress-outer {
    width: 100%;
    .progress-inner {
      width: 0;
      transition: 0.35s;
      height: 10px;
      background: #ddd;
      &.low {
        background: indianred;
      }
      &.medium {
        background: orange;
      }
      &.high {
        background: mediumseagreen;
      }
    }
  }

  .single-vessel {
    color: steelblue;
    &.pro {
      color: mediumseagreen;
    }
  }

.tabs {
  ul {
    li {
      margin: 0 2px;
      a {
        span {
          margin-left: 10px;
        }
      }
      &.active {
        background-color: whitesmoke;
        border-bottom-color: #dbdbdb;
      }
    }
  }
}



</style> 