<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <div class="before-icon"><font-awesome-icon icon="users" /></div>
                <h4 class="icon">Pooled Visitor Check-in Items</h4>
                <div class="clear"></div>

                <table v-if="poolOfDocs.length" class="table is-bordered is-fullwidth">
                    <tr>
                        <th>Title</th>
                        <th>Edit</th>
                    </tr>
                    <tr v-for="(item, index) in poolOfDocs" :key="index">
                        <td>
                            <span class="show-details" @click="showDetails(item)">{{ item.title }}</span>
                        </td>
                        <td>
                            <div class="buttons">
                                <span class="button is-primary" @click="editItem(item)">Content</span>
                                <span class="button is-warning" @click="editMeta(item)">Meta</span>
                            </div>
                        </td>
                    </tr>
                </table>

                <div class="clear"></div>

                <div class="buttons">
                    <span class="button is-primary is-medium" @click="addItem()">Add a Checklist Item</span>
                    <span class="button is-info is-medium" @click="$router.go(-1)"><font-awesome-icon icon="chevron-left" /> &nbsp;Back</span>
                </div>
            </div>
        </div>

        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': showDetailsModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-info">
                    <p class="modal-card-title">{{ selectedItem.title }}</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <div v-if="selectedItem.details != ''">
                        <div v-for="line in selectedItem.details.split('\n')" :key="line">{{ line }}<br /></div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-info" @click.prevent="close">Done</button>
                </footer>
            </div>
        </div>

        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': showEditItemModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">Edit Selected Item</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <input class="input" type="text" placeholder="Type title..." v-model="selectedEditItem.title" />
                    <div class="spacer s10"></div>
                    <textarea class="input textarea" placeholder="Type details..." v-model="selectedEditItem.details"></textarea>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click.prevent="saveEditItem(selectedEditItem)">
                        Save
                    </button>
                    <button class="button is-danger" @click.prevent="close">
                        Cancel
                    </button>
                </footer>
            </div>
        </div>

        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': showEditMetaModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-warning">
                    <p class="modal-card-title">{{ selectedEditMeta.title }}</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <label class="label">Place Index</label>
                    <input
                        class="input"
                        type="tel"
                        placeholder="999"
                        maxlength="4"
                        v-on:keypress="isNumber($event)"
                        v-model="selectedEditMeta.index"
                    />
                    <div class="spacer s30"></div>
                    <div v-if="selectedEditMeta != ''" class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Fast Track Codes</label>
                            <div class="spacer s10"></div>
                            <div class="field" v-for="code in allCodes" :key="code.id">
                                <input class="is-checkradio" type="checkbox" :id="code.id" :value="code.code" v-model="tempSelectedMetaCodes" />
                                <label
                                    :class="{
                                        'has-code': tempSelectedMetaCodes.includes(code.code)
                                    }"
                                    :for="code.id"
                                    >{{ code.name }}</label
                                >
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                    <div class="spacer s30"></div>

                    <div v-if="selectedEditMeta != ''" class="posi">{{ tempSelectedMetaCodes }}<br />{{ codeGroups }}</div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-warning" @click.prevent="saveEditMeta(selectedEditMeta, tempSelectedMetaCodes)">
                        Save
                    </button>
                    <button class="button is-danger" @click.prevent="close">
                        Cancel
                    </button>
                </footer>
            </div>
        </div>

        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': addItemModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">Add an Item</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <input class="input" type="text" placeholder="Type title..." v-model="newItem.title" />

                    <div class="spacer s10"></div>
                    <textarea class="input textarea" placeholder="Type details..." v-model="newItem.details"></textarea>

                    <div class="spacer s30"></div>

                    <label class="label">Place Index</label>
                    <input class="input" type="tel" placeholder="999" maxlength="4" v-on:keypress="isNumber($event)" v-model="newItem.index" />

                    <div class="spacer s30"></div>
                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Fast Track Codes</label>
                            <div class="spacer s10"></div>
                            <div class="field" v-for="code in allCodes" :key="code.id">
                                <input class="is-checkradio" type="checkbox" :id="code.id" :value="code.code" v-model="newItem.codes" />
                                <label :class="{ 'has-code': newItem.codes.includes(code.code) }" :for="code.id">{{ code.name }}</label>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div class="spacer s30"></div>
                </section>
                <footer class="modal-card-foot">
                    <button :disabled="newItem.details == '' || newItem.title == ''" class="button is-primary" @click.prevent="saveNewItem()">
                        Save New Item
                    </button>
                    <button class="button is-danger" @click.prevent="close">
                        Cancel
                    </button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {
    name: 'BuildPooledInduction',
    components: {},
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            poolOfDocs: [],
            selectedItem: { title: '', details: '' },
            selectedEditItem: { title: '', details: '' },
            selectedEditMeta: '',
            showDetailsModal: false,
            showEditItemModal: false,
            showEditMetaModal: false,

            allCodes: [
                { id: 'base', name: 'BASE', checked: false, code: 'base' },
                { id: 'i1', name: 'Fishing', checked: false, code: 'keep' },
                { id: 'i2', name: 'Aquaculture', checked: false, code: 'wrap' },
                { id: 'i3', name: 'Commercial Diving', checked: false, code: 'lump' },
                { id: 'i4', name: 'Marine Construction', checked: false, code: 'corn' },
                { id: 'i5', name: 'Charter Vessel', checked: false, code: 'wood' },
                { id: 'i6', name: 'Passenger Vessel', checked: false, code: 'pest' },
                { id: 'i7', name: 'Other', checked: false, code: 'gate' },
                {
                    id: 'c1',
                    name: '1 - Passenger vessel (13 or more passengers)',
                    code: 'tent'
                },
                {
                    id: 'c2',
                    name: '2 - Non-passenger vessel (up to 12 passengers)',
                    code: 'wall'
                },
                { id: 'c3', name: '3 - Fishing vessel', code: 'firm' },
                {
                    id: 'c4',
                    name: '4 - Hire and drive vessel used by the hirer only for recreational purposes',
                    code: 'aunt'
                },
                {
                    id: 'B',
                    name: 'B - Offshore operations (within 200 nautical miles)',
                    code: 'news'
                },
                {
                    id: 'Be',
                    name: 'B (extended) - Offshore operations (beyond 200 nautical miles)',
                    code: 'lazy'
                },
                {
                    id: 'C',
                    name: 'C - Offshore operations (within 30 nautical miles)',
                    code: 'grow'
                },
                {
                    id: 'Cr',
                    name: 'C (restricted) - Offshore operations - specified areas only',
                    code: 'club'
                },
                {
                    id: 'D',
                    name: 'D - Partially smooth water operations',
                    code: 'bank'
                },
                { id: 'E', name: 'E - Smooth water operations', code: 'step' },
                {
                    id: 'Q1',
                    checked: false,
                    name: 'This vessel takes paying passengers',
                    code: 'flex'
                },
                {
                    id: 'Q2',
                    checked: false,
                    name: 'This vessel is operated single-handedly',
                    code: 'solo'
                },
                {
                    id: 'Q3',
                    checked: false,
                    name: 'Diving operations are carried out from this vessel',
                    code: 'vote'
                },
                {
                    id: 'Q4',
                    checked: false,
                    name: 'This is a dry hire or hire-drive vessel',
                    code: 'toss'
                },
                {
                    id: 'Q5',
                    checked: false,
                    name: 'This vessel has a crane or other lifting equipment',
                    code: 'pair'
                },
                {
                    id: 'Q6',
                    checked: false,
                    name: 'This vessel has an engine room',
                    code: 'boom'
                },
                {
                    id: 'Q7',
                    checked: false,
                    name: 'This vessel has a cabin or wheelhouse',
                    code: 'raid'
                },
                {
                    id: 'Q8',
                    checked: false,
                    name: 'This vessel is has sleeping accommodation',
                    code: 'slab'
                },
                {
                    id: 'Q9',
                    checked: false,
                    name: 'This operation requires overnight trips',
                    code: 'zone'
                },
                {
                    id: 'Q10',
                    checked: false,
                    name: 'This vessel is occasionally out of phone range',
                    code: 'chop'
                },
                {
                    id: 'Q11',
                    checked: false,
                    name: 'This vessel navigates busy traffic routes',
                    code: 'lift'
                }
                // { id: 'Q12', checked: false, name: 'This vessel is operated single-handedly', code: 'pipe'},
            ],

            groupBasis: ['base'],
            groupRisky: ['keep', 'burp', 'news', 'lazy', 'grow', 'club', 'chop', 'lift'],
            groupHeavy: ['corn', 'pair'],
            groupTravs: ['wood', 'pest', 'tent', 'flex', 'toss'],
            groupHirer: ['aunt', 'toss', 'pest'],
            groupDiver: ['lump', 'vote'],
            groupFishy: ['keep', 'wrap', 'firm'],
            groupMotor: ['boom'],
            groupAlone: ['solo'],
            groupSleep: ['raid', 'slab', 'zone', 'chop', 'lift'],

            tempSelectedMetaCodes: [],
            addItemModal: false,
            newItem: {
                title: '',
                details: '',
                codes: [],
                index: 999
            }
        };
    },

    computed: {
        userData() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.userData
                ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2'
                    ? true
                    : false
                : false;
        },
        selectedEditMetaCodes() {
            return this.selectedEditMeta.codes.filter(item => item.checked).map(itemB => item);
        },
        tempSelectedCodeNames() {
            return this.allCodes
                .filter(code => this.tempSelectedMetaCodes.includes(code.code))
                .map(item => item.name)
                .join(', ');
        },

        gBasis() {
            return this.groupBasis.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-basis'] : [];
        },
        gRisky() {
            return this.groupRisky.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-risky'] : [];
        },
        gHeavy() {
            return this.groupHeavy.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-heavy'] : [];
        },
        gTravs() {
            return this.groupTravs.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-travs'] : [];
        },
        gHirer() {
            return this.groupHirer.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-hirer'] : [];
        },
        gDiver() {
            return this.groupDiver.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-diver'] : [];
        },
        gFishy() {
            return this.groupFishy.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-fishy'] : [];
        },
        gMotor() {
            return this.groupMotor.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-motor'] : [];
        },
        gAlone() {
            return this.groupAlone.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-alone'] : [];
        },
        gSleep() {
            return this.groupSleep.some(item => this.tempSelectedMetaCodes.includes(item)) ? ['g-sleep'] : [];
        },

        codeGroups() {
            return [
                ...this.gBasis,
                ...this.gRisky,
                ...this.gHeavy,
                ...this.gTravs,
                ...this.gHirer,
                ...this.gDiver,
                ...this.gFishy,
                ...this.gMotor,
                ...this.gAlone,
                ...this.gSleep
            ];
        }
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                this.displayDocs();
            }
        },

        isNumber($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        close: function() {
            this.showDetailsModal = false;
            this.showEditItemModal = false;
            this.showEditMetaModal = false;
            this.selectedItem = { title: '', details: '' };
            this.selectedEditItem = { title: '', details: '' };
            this.selectedEditMeta = '';
            this.addItemModal = false;
            this.newItem = {
                title: '',
                details: '',
                codes: [],
                index: 999
            };
            this.tempSelectedMetaCodes = [];
        },

        displayDocs() {
            let self = this;
            db.collection('pooledCheckInItems').onSnapshot(querySnapshot => {
                self.poolOfDocs = [];
                querySnapshot.forEach(doc => {
                    self.poolOfDocs.push({
                        id: doc.id,
                        title: doc.data().title ? doc.data().title : 'no title',
                        details: doc.data().details ? doc.data().details : 'no details',
                        codes: doc.data().codes ? doc.data().codes : [],
                        index: doc.data().index ? doc.data().index : 999
                    });
                });
            });
        },

        updateList(localList, selectedList, savedList) {
            let tempList = localList.filter(item => savedList.includes(item.name)).map(itemtwo => itemtwo);
            console.log(tempList);
            tempList.forEach(item => selectedList.push(item));
        },

        showDetails(item) {
            this.tempSelectedMetaCodes = item.codes;
            this.selectedItem = item;
            this.showDetailsModal = true;
        },

        editItem(item) {
            this.selectedEditItem = item;
            this.showEditItemModal = true;
        },

        saveEditItem(itemToSave) {
            let self = this;
            // console.log(itemToSave);
            NProgress.start();
            var adminSaveSmsItem = firebase.functions().httpsCallable('adminSaveSmsItem');
            adminSaveSmsItem({
                collection: 'pooledCheckInItems',
                docID: itemToSave.id,
                title: itemToSave.title,
                details: itemToSave.details
            })
                .then(function(result) {
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        },

        editMeta(item) {
            // console.log(item);
            this.tempSelectedMetaCodes = item.codes;
            this.selectedEditMeta = item;
            this.showEditMetaModal = true;
        },

        saveEditMeta(itemToSave, newCodes) {
            let self = this;
            // console.log(itemToSave);
            // console.log(newCodes);
            NProgress.start();
            var adminSaveSmsItemMeta = firebase.functions().httpsCallable('adminSaveSmsItemMeta');
            adminSaveSmsItemMeta({
                collection: 'pooledCheckInItems',
                docID: itemToSave.id,
                codes: newCodes,
                codeGroups: self.codeGroups,
                index: parseInt(itemToSave.index)
            })
                .then(function(result) {
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error);
                    NProgress.done();
                });
        },

        addItem() {
            this.addItemModal = true;
        },

        saveNewItem() {
            // console.log(this.newItem);
            let self = this;
            NProgress.start();
            var adminSaveNewSmsItem = firebase.functions().httpsCallable('adminSaveNewSmsItem');
            adminSaveNewSmsItem({
                collection: 'pooledCheckInItems',
                title: self.newItem.title,
                details: self.newItem.details,
                codes: self.newItem.codes,
                index: parseInt(self.newItem.index)
            })
                .then(function(result) {
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
label {
    margin-bottom: 10px;
    display: block;
}

.checkbox-wrapper {
    .field {
        float: left;
        margin-right: 2rem;
        .has-code {
            color: red;
            font-weight: bold;
        }
    }
}

.site-wrapper .container {
    max-width: 1050px;
}
.buttons {
    margin-bottom: 5px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
input[type='text'],
input[type='tel'] {
    padding: 2rem 1rem;
}

textarea {
    min-height: 120px;
    height: 400px;
    margin-top: 10px;
    padding: 1.5rem 1rem;
}

.show-details {
    cursor: pointer;
    &:hover {
        color: dodgerblue;
    }
}
</style>
