<template>
  <div>
    <div class="site-wrapper no-header">
      <div class="container">
        <h3 class="is-size-5">Write a Custom Document</h3>
        <br />

        <div class="input-wrapper">
          <label
            >Target Collection Path (forward but no trailing slashes)</label
          >
          <input
            class="input"
            type="text"
            placeholder="Paste target collection path"
            v-model="targetPath"
          />
        </div>

        <div v-if="targetPath != ''">
          <input
            class="is-checkradio"
            type="checkbox"
            id="autoID"
            v-model="autoID"
          />
          <label for="autoID">Use Auto ID</label>
          <div class="spacer s20"></div>

          <div v-if="!autoID">
            <label>Doc ID</label>
            <input
              class="input"
              type="text"
              placeholder="Enter doc ID..."
              v-model="newDocID"
            />
          </div>

          <div class="clear"></div>
          <!-- {{newFields}} -->

          <div class="spacer s20"></div>

          <div class="file-metadata">
            <div
              class="meta-row"
              v-for="(item, index) in newFields"
              :key="`metadata-${index}`"
            >
              <label class="label">Field {{ index + 1 }}</label>
              <!-- <label class="label">Field Key</label> -->
              <input
                class="input meta-key"
                type="text"
                placeholder="Enter key"
                v-model="item.key"
              />
              <!-- <label class="label">Field Value</label> -->
              <textarea
                class="textarea"
                placeholder="Enter value"
                v-model="item.value"
              ></textarea>
              <div
                class="meta-delete button is-small is-light"
                @click="deleteMetaRow(index)"
              >
                Delete
              </div>
            </div>
          </div>

          <div class="buttons">
            <button
              class="button is-info"
              :disabled="newFields.length > 3"
              @click="addNewField()"
            >
              Add a Field
            </button>
          </div>

          <div class="spacer s20"></div>

          <!-- <div class="select">
                            <select v-model="selectedCategory">
                            <option disabled value="">Choose a Category</option>
                            <option v-for="category in categories" :key="category">{{category}}</option>
                            </select>
                        </div>

                        <div class="spacer s20"></div>

                        <div class="select">
                            <select v-model="selectedLocation">
                            <option disabled value="">Choose a Location</option>
                            <option v-for="location in locations" :key="location">{{location}}</option>
                            </select>
                        </div> -->

          <div class="spacer s20"></div>

          <div class="clear"></div>

          <!-- <div class="input-wrapper">      
                            <label>Details</label>
                            <textarea class="input" placeholder="Write details" v-model="newDetails"></textarea>
                        </div>

                        <div class="clear"></div> -->

          <input
            class="is-checkradio"
            type="checkbox"
            id="useIndex"
            v-model="useIndex"
          />
          <label for="useIndex">Use Index</label>
          <div class="spacer s20"></div>

          <div v-if="useIndex" class="input-wrapper">
            <label>Index</label>
            <input class="input" type="tel" v-model="newIndex" />
          </div>

          <div class="clear"></div>

          <div class="spacer s20"></div>

          <!-- <div class="input-wrapper">      
                            <label>Quantity</label>
                            <input class="input" type="tel" v-model="quantity">
                        </div>

                        <div class="clear"></div>

                        <div class="spacer s30"></div> -->

          <div class="buttons">
            <span
              :disabled="showButton"
              class="button is-warning is-medium"
              @click="saveNewDocument()"
              >Write New Doc</span
            >
          </div>
        </div>

        <hr />

        <!-- {{targetPath}} <br>{{finalDocID}} <br> {{updateData}} <br> {{newIndex}} -->
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import { db } from "../main.js";
import "firebase/compat/auth";
import "firebase/firestore";
import "firebase/compat/functions";
import NProgress from "nprogress";
import { v4 as uuidv4 } from "uuid";
//import * as moment from 'moment';

export default {
  components: {},
  mounted: function() {
    firebase.auth().onAuthStateChanged(this.onUserLogin);
  },

  data: function() {
    return {
      newTitle: "",
      newDetails: "",
      newIndex: 999,
      // collectionPath: 'helperEmergencyProcedures',
      targetPath: "/publicProfileData/allData/pooledProfileInstitutions",
      newDocID: "xyz123",
      autoID: true,
      useIndex: true,
      // newFields: [],
      newFields: [
        {key: "slug", value: ""},
        {key: "title", value: "" },
        {key: "location", value: "New South Wales" },
      ],
      quantity: 0,
      selectedCategory: "",
      selectedLocation: "",
      categories: [
        "Main Engines",
        "Generators",
        "Desal Plant",
        "Yamaha",
        "Oils",
        "Waste Management",
        "Plumbing",
        "Electrical",
        "Aircon",
        "Anodes",
        "Shackles",
        "Thimbles",
        "Lifting Hook",
      ],
      locations: [
        "Forepeak",
        "Forepeak Filter Box Blue",
        "Shed",
        "Forepeak MAN Box Blue",
        "Forepeak MAN Box Black",
        "Forepeak Generator Box Orange",
        "Forepeak Desalinator Box Grey",
        "Forepeak Desalinator Box Blue",
        "Tank Void",
        "Under Skipper Bed",
        "Under Engineer Bed",
        "Forepeak Yamaha Box",
        "Midships Tank Void / Forepeak",
        "Midships Tank Void",
        "Flybridge Seat",
        "Engine Room",
        "Engineers Cabin",
      ],
      updateData: {},
      finalDocID: "",
    };
  },

  computed: {
    isSteve() {
      return (
        this.user.uid == "3wTzZecD4QYOPUk4IyWRA2JmDcJ2" ||
        this.user.uid == "M3GGz7qWIxNqAsN2Ojglrv334jm1"
      );
    },
    showButton() {
      return this.targetPath == "";
    },
  },

  methods: {
    onUserLogin: function(user) {
      if (user) {
        // console.log(user);
      }
    },

    addNewField() {
      this.newFields.push({
        key: "",
        value: "",
      });
    },

    deleteMetaRow(index) {
      this.newFields.splice(index, 1);
    },

    saveNewDocument() {
      let self = this;
      let newTargetPath =
        self.targetPath.length > 0 && self.targetPath[0] === "/"
          ? self.targetPath.substring(1)
          : self.targetPath;
      this.finalDocID = this.autoID ? uuidv4() : this.newDocID;

      this.updateData = {};
      this.newFields.forEach((field) => {
        self.updateData[field.key] = field.value;
      });

      if (this.useIndex) this.updateData["index"] = parseInt(this.newIndex);
      // temp for inventory:
      // this.updateData['quantity'] = parseInt(this.quantity);
      // this.updateData['category'] = this.selectedCategory;
      // this.updateData['location'] = this.selectedLocation;

      let message;
      message = {
        title: "Save new document?",
        body: "Click YES below proceed.",
      };
      let options = {
        okText: "YES, SAVE",
        backdropClose: true,
        cancelText: "Cancel",
      };
      this.$dialog
        .confirm(message, options)
        .then(function(dialog) {
          NProgress.start();

          // var adminAddNewDocument = firebase.functions().httpsCallable('adminAddNewDocument');
          // adminAddNewDocument({
          //     collectionPath: self.targetPath,
          //     newData: newData
          // })
          // .then(function(result) {
          //     var sanitizedMessage = result.data.text;
          //     console.log('Result',sanitizedMessage);
          //     console.log('Message',result.data.message);
          //     self.clear();
          //     NProgress.done();

          // })
          // .catch(function(error) {
          //     console.log('Error',error.text);
          //     NProgress.done();
          // });

          db.collection(newTargetPath)
            .doc(self.finalDocID)
            .set(self.updateData)
            .then(() => {
              self.clear();
              NProgress.done();
            })
            .catch((err) => {
              console.log("error", err);
              self.clear();
              NProgress.done();
            });
        })
        .catch(function(error) {
          console.log(error);
          NProgress.done();
        });
    },

    clear() {
      this.newTitle = "";
      this.newDetails = "";
      this.newIndex = "999";
      this.targetPath = "/publicProfileData/allData/pooledProfileInstitutions";
      this.finalDocID = "";
      this.updateData = {};
      this.newDocID = "xyz123";
      this.autoID = true;
      this.useIndex = true;
      // this.newFields = [];
      // temp inventory:
      this.newFields = [
        {key: "slug", value: ""},
        {key: "title", value: "" },
        {key: "location", value: "New South Wales" },
      ];
      this.selectedCategory = "";
      this.selectedLocation = "";
      this.quantity = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="text"],
input[type="tel"] {
  width: 100%;
  float: left;
  display: block;
  margin-bottom: 2rem;
  padding: 15px;
  height: auto;
  // &:after {
  //   content: "\f16c";
  //   font-family: "Font Awesome 5 Brands";
  //   color: red;
  // }
  // &.valid:after {

  // }
}
label {
  margin-bottom: 10px;
  display: block;
}
textarea {
  width: 100%;
  padding: 15px;
  min-height: 200px;
  margin-bottom: 2rem;
}
.icon-wrap {
  display: block;
  float: left;
  height: 35px;
  margin-left: 10px;
  font-size: 1.5rem;
  color: red;
  &.valid {
    color: green;
  }
}

.file-metadata {
  .meta-row {
    margin-bottom: 1.5rem;
    input[type="text"] {
      margin-bottom: 5px;
    }
    textarea {
      margin-bottom: 5px;
    }
  }
}
</style>
