<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">Write a new Document</h3>
                <br />

                <div class="input-wrapper">
                    <label>Target Collection Path (no trailing slashes)</label>
                    <input class="input" type="text" placeholder="Paste source doc path" v-model="targetPath" />
                </div>

                <!-- <div class="input-wrapper">
                    <label>New Doc ID</label>
                    <input class="input" type="text" v-model="newDocID" />
                </div> -->

                <div class="clear"></div>

                <div class="spacer s10"></div>

                <pre>{{ documentData }}</pre>

                <div class="spacer s30"></div>

                <div class="buttons">
                    <span :disabled="hideButton" class="button is-warning is-medium" @click="addNewDocs">Add Docs</span>
                </div>

                <hr />
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '../main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
//import * as moment from 'moment';

var adminAddNewDocument = firebase.functions().httpsCallable('adminAddNewDocument');
var adminSetNewDocument = firebase.functions().httpsCallable('adminSetNewDocument');

export default {
    components: {},
    mounted() {},

    data() {
        return {
            newTitle: '',
            newDetails: '',
            newIndex: 99,
            targetPath: '/vessels/1h0K6AO0mbtJ758O5P7W/plantItemTags',
            documentData: [
                { index: 0, title: 'Vehicles' },
                { index: 1, title: 'Generators' },
                { index: 2, title: 'Forklift' },
                { index: 3, title: 'MOB Infrastructure' },
                { index: 4, title: '240v Equipment' },
                { index: 5, title: 'MAIN WORKSHED' },
                { index: 6, title: 'Pollution Equipment' },
                { index: 7, title: 'Misc Trailers' },
                { index: 8, title: 'SMALL SHED' },
                { index: 9, title: 'SHARK SHED' },
                { index: 10, title: 'POLLUTION SHED' },
                { index: 11, title: 'Chemicals' },
                { index: 12, title: 'Battery Operated Equipment' },
                { index: 13, title: 'Safety Equipment' },
                { index: 14, title: 'Petrol Powered Equipment' },
                { index: 15, title: 'Lifting Equipment' },
                { index: 16, title: 'AtoNs and equipment' },
                { index: 17, title: 'Fire Safety' },
                { index: 18, title: 'Pallet racking' },
                { index: 19, title: 'MEZZANINE FLOOR' },
                { index: 20, title: 'Diesel powered equipment' },
                { index: 21, title: 'POWER TOOL CUPBOARD' },
                { index: 22, title: 'MO OFFICE' },
                { index: 23, title: 'MAIN OFFICE' },
                { index: 24, title: 'Battery charger' },
                { index: 25, title: 'Extension lead 240v' },
                { index: 26, title: 'Palm Is Safety Grab Bag' },
                { index: 27, title: 'First Aid Kit' },
                { index: 28, title: 'BOWEN BOAT HARBOUR' },
                { index: 29, title: 'NELLY BAY' },
                { index: 30, title: 'Compressors' },
                { index: 31, title: 'Compliance equipment' }
            ]
        };
    },

    computed: {
        isSteve() {
            return this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' || this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1';
        },
        hideButton() {
            return this.targetPath == '' || Object.keys(this.documentData).length == 0;
        }
    },

    methods: {
        addNewDocs() {
            let self = this;

            let message;
            message = {
                title: 'Add new documents?',
                body: 'Click YES below proceed.'
            };
            let options = {
                okText: 'YES, ADD',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(async dialog => {
                    NProgress.start();

                    await Promise.all(
                        self.documentData.map((doc, idx) => {
                            let data = { ...doc };
                            // data.quantity = data.quantity == '' ? 0 : parseInt(data.quantity);
                            // data.index = idx;
                            // data.hasDate = false;
                            // data.archived = false;
                            // data.threshold = 0;
                            // data.photo = '';
                            // data.tags = [data.tags];
                            db.collection(self.targetPath).add(data);
                        })
                    );

                    // let result;
                    // if (self.newDocID == '') {
                    //     result = await adminAddNewDocument({
                    //         collectionPath: self.targetPath,
                    //         newData: self.documentData
                    //     });
                    // } else {
                    //     result = await adminSetNewDocument({
                    //         collectionPath: self.targetPath,
                    //         docID: self.newDocID,
                    //         newData: self.documentData
                    //     });
                    // }

                    // var sanitizedMessage = result.data.text;
                    // console.log('Result', sanitizedMessage);
                    // console.log('Message', result.data.message);
                    NProgress.done();
                })
                .catch(error => {
                    console.log(error);
                    NProgress.done();
                });
        },

        clear() {
            this.newTitle = '';
            this.newDetails = '';
            this.newIndex = '';
            this.targetPath = '';
        }
    }
};
</script>

<style lang="scss" scoped>
input[type='text'],
input[type='tel'] {
    width: 100%;
    float: left;
    display: block;
    margin-bottom: 2rem;
    padding: 15px;
    height: auto;
    // &:after {
    //   content: "\f16c";
    //   font-family: "Font Awesome 5 Brands";
    //   color: red;
    // }
    // &.valid:after {

    // }
}
label {
    margin-bottom: 10px;
    display: block;
}
textarea {
    width: 100%;
    padding: 15px;
    min-height: 200px;
    margin-bottom: 2rem;
}
.icon-wrap {
    display: block;
    float: left;
    height: 35px;
    margin-left: 10px;
    font-size: 1.5rem;
    color: red;
    &.valid {
        color: green;
    }
}
</style>
