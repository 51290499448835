<template>
  
  <div>

      <div class="site-wrapper no-header">
      
        <div class="container">

            <h3 class="is-size-5">Update a Collection Index</h3><br>

            <div class="input-wrapper">      
              <label>Top Collection Path (ie: vessels)</label>
              <input class="input" type="text" placeholder="Paste source doc path" v-model="rootPath">
            </div>

            <div class="clear"></div>

            <div class="input-wrapper">      
              <label>Sub Collection Name</label>
              <input class="input" type="text" placeholder="Paste target collection path" v-model="subPath">
            </div>

            <div class="clear"></div>

            <hr>


            <div class="buttons">
              <span :disabled="pathsAreValid" class="button is-warning" @click="updateCounts">Update Counts</span>
            </div>




        </div>

      </div>


      




  </div>

</template>


<script> 

  import firebase from 'firebase/compat/app';
  import { db } from '../main.js';
  import 'firebase/compat/auth'; 
  import 'firebase/firestore';
  import 'firebase/compat/functions';
  import NProgress from 'nprogress';  
  //import * as moment from 'moment';

  export default {

    components: {
      
    },
    mounted: function() {
      firebase.auth().onAuthStateChanged( this.onUserLogin ); 
    },

    data: function() {
      return {
        rootPath: '',
        subPath: '',

      }
    },

    computed: {
      isSteve() {
        return this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' || this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1';
      },
      pathsAreValid() {
        return this.rootPath == '' || this.subPath == '';
      }
    },

    methods: {

      onUserLogin: function( user ) {
        if (user) {         
          // console.log(user);
        }
      },

      close: function() {
        
      },

      updateCounts() {
        let self = this;

        console.log(this.rootPath);
        console.log(this.subPath);

        // let message;
        // message = {
        //   title: 'Confirm Update?',
        //   body: 'Click YES below to proceed.'
        // };
        // let options = {
        //   okText: 'YES',
        //   backdropClose: true,
        //   cancelText: 'Cancel',
        // };
        // this.$dialog
        //   .confirm(message, options)
        //   .then(function(dialog) {
              
        //       NProgress.start();
        //       var adminUpdateCollectionCounts = firebase.functions().httpsCallable('adminUpdateCollectionCounts');
        //       adminUpdateCollectionCounts({
        //           rootPath: self.rootPath,
        //           subPath: self.subPath,
        //       })
        //       .then(function(result) {
        //         // var sanitizedMessage = result.data.text;
        //         // console.log('Result',sanitizedMessage);
        //         // console.log('Message',result.data.message);
        //         NProgress.done();

        //       })
        //       .catch(function(error) {
        //         console.log('Error',error.text);
        //         NProgress.done();
        //       }); 


        //   })
        //   .catch(function(error) {
        //     console.log(error); 
        //     NProgress.done();
        //   });         






        




      },

      

      

    },

  }



</script> 




<style lang="scss" scoped>

  .container {
    max-width: none;
  }
  
  input[type="text"] {
    width: 90%;
    float: left;
    display: block;
    margin-bottom: 2rem;
  }
  .icon-wrap {
    display: block;
    float: left;
    height: 35px;
    margin-left: 10px;
    font-size: 1.5rem;
    color: red;
    &.valid {
      color: green;
    }
  }
  

</style> 