<template>

  <div class="site-wrapper no-header">

    <ul class="scroller">
        <li><router-link to="my-dashboard">Dashboard</router-link></li>
        <li class="is-active"><a aria-current="page">One off call</a></li>

    </ul>
  
    <div class="container">

      <div class="notification is-dark"><h3>Loop through all Vessels and execute function</h3></div>

      <div class="spacer s30"></div>

      <!-- <p>Function: Add an index to any vessel with customChecklists.</p> -->

      <p>Function: Add "startupTags" to every vessel that doesn't already have them.</p>

     <!--  <input class="input" type="text" placeholder="Paste vessel ID.." v-model="inputVesselID"> -->

      <div class="spacer s20"></div>

      <div class="buttons">
        <button class="button is-danger" @click="runFunction()">Run Function</button>
      </div>

    </div>


  </div>
  
      

</template>

<script>  

  import { db } from '../main.js';
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth'; 
  import 'firebase/firestore';
  // import 'firebase/compat/functions';
  import NProgress from 'nprogress';
  import * as moment from 'moment'

  export default {

    name: 'VesselsLoop',
    props: {
      

    },
    // mounted: function() {
    //   firebase.auth().onAuthStateChanged( this.onUserLogin );     
    // },
    computed: {
      
    },
    data: function() {
      return {

      }
    },
    methods: {
      // onUserLogin: function( user ) {
      //   if (user) {         
      //     this.firebaseid = user.uid;
      //   }
      // },
      
      runFunction() {

        let self = this;
        let message;
        message = {
          title: 'Confirm Run Function?',
          body: 'Click YES below proceed.'
        };
        let options = {
          okText: 'YES',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(function(dialog) {

            NProgress.start();
            var adminLoopAllVessels = firebase.functions().httpsCallable('adminLoopAllVessels');
            adminLoopAllVessels({
              // sentVesselID: self.inputVesselID,
            })
            .then(function(result) {
              // var sanitizedMessage = result.data.text;
              // console.log('Result',sanitizedMessage);
              // console.log('Message',result.data.message);
              NProgress.done();

            })
            .catch(function(error) {
              console.log('Error',error.text);
              NProgress.done();
            }); 

          })
          .catch(function(error) {
            console.log(error); 
            NProgress.done();
          });      
      },

    },

  }

</script>



<style lang="scss" scoped>

  .message {
    .message-body {
      background: #fff;
    }
  }
  textarea {
    min-height: 100px !important;
    box-shadow: none;
    padding: 0.6rem 1rem;
    &.notes {
      margin-top: 1rem;
    }
  }
  input[type="text"] {
    padding: 15px 20px;
    height: auto;
  }


</style>