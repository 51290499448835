<template>
	
	<div class="site-wrapper no-header">
		
			<div class="container">

				<h3 class="is-size-5">Vessel SMS Audit {{selected ? '- ' + selectedVessel.vesselName : ''}}</h3>

				<div v-if="!selected" class="notification is-primary">
			    <input class="input" v-model="findName" id="filtername" type="text" placeholder="Start typing vessel name..." />
			  </div>

			  <div class="box" v-if="findName.length > 1 && !selected">

			  	<div v-if="filteredNames.length">
						<ul class="vessel-list">
					    <li v-for="(vessel, index) in filteredNames" :key="index">
					      <span class="vessel-name">{{vessel.vesselName}}</span>
					      <span @click="loadVesselData(vessel)" class="button is-small is-success">Select</span>
					      <div class="clear"></div>
					    </li>
					  </ul>
					</div>

					<div v-else>
						<p>No vessels matched your search.</p>
					</div>

				</div>


				<hr>

				<table v-if="selected" class="table is-bordered is-fullwidth">
					<tr><th>Vessel</th><th>SMS Audit</th><th>Actions</th><th>View Audit</th></tr>
					<tr>
						<td>{{selectedVessel.vesselName}}<br>{{selectedVessel.vesselOwnerName}}</td>
						<td>
							<div class="traffic-light">
							  <div class="light" :class="selectedVessel.lastAuditCode">{{selectedVessel.lastAuditPercent}}%</div>
							</div>
							<div>{{selectedVessel.sinceDate}}</div>
						</td>
						<td>
							<update-audit :counted="selectedVessel.hasBeenCounted" :vesselID="selectedVessel.vesselID" v-on:sendCount="updateCounted"></update-audit>
						</td>
						<td>
							<button class="button is-link is-small" @click="viewAudit(selectedVessel.vesselID,selectedVessel.lastAuditID,selectedVessel.vesselName,selectedVessel.lastAuditDate,selectedVessel.sinceDate)">View Audit</button>
						</td>
					</tr>
				</table>


				<div class="buttons">
					<span v-if="findName != '' || !selected" class="button is-warning" @click="clearVessel()">Clear</span>
				</div>


					
				<div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showAudit}">
	        <div class="modal-background" @click="close"></div>
	        <div class="modal-card">
	            <header class="modal-card-head">
	              <p class="modal-card-title">Audit for {{selectedVesselName}}</p>
	              <button class="delete" aria-label="close" @click.prevent="close"></button>
	            </header>
	            <section class="modal-card-body">       
	            	
	            	<div v-if="selectedAuditLoaded">
	            		<!-- <p v-if="selectedAuditResult">Audit date: {{selectedAuditDate}}</p> -->
	            		<p v-if="selectedAuditResult">Audit date: {{selectedSinceDate}}</p>
									<div class="traffic-light">
				        		<span>Audit result:</span>
									  <div v-if="selectedAuditResult" class="light" :class="selectedAuditResult.auditCode">{{selectedAuditResult.auditPercent}}%</div>
									</div>
								  <table class="table is-fullwidth">
								  	<tr>
								  		<th>Item</th>
								  		<th>Details</th>
								  	</tr>
								  	<tr>
								  		<td>Designated Statements</td>
								  		<td>{{selectedAuditResult.hasDesignatedStatement ? 'Yes' : 'No'}}</td>
								  	</tr>
								  	<tr>
								  		<td>Master Statements</td>
								  		<td>{{selectedAuditResult.hasMasterStatement ? 'Yes' : 'No'}}</td>
								  	</tr>
								  	<tr>
								  		<td>Hazards</td>
								  		<td>{{selectedAuditResult.countHazards}}</td>
								  	</tr>
								  	<tr>
								  		<td>Emergency Contacts</td>
								  		<td>{{selectedAuditResult.countContacts}}</td>
								  	</tr>
								  	<tr>
								  		<td>Vessel Policies</td>
								  		<td>{{selectedAuditResult.countPolicies}}</td>
								  	</tr>
								  	<tr>
								  		<td>Operating Procedures</td>
								  		<td>{{selectedAuditResult.countSOPS}}</td>
								  	</tr>
								  	<tr>
								  		<td>Emergency Procedures</td>
								  		<td>{{selectedAuditResult.countEOPS}}</td>
								  	</tr>
								  	<tr>
								  		<td>Startup Checklists</td>
								  		<td>{{selectedAuditResult.countStartupItems}}</td>
								  	</tr>
								  	<tr>
								  		<td>Maintenance Checklists</td>
								  		<td>{{selectedAuditResult.countMaintenanceItems}}</td>
								  	</tr>
								  	<tr>
								  		<td>Startup Logs</td>
								  		<td>{{selectedAuditResult.countStartupLogs}}</td>
								  	</tr>
								  	<tr>
								  		<td>Maintenance Logs</td>
								  		<td>{{selectedAuditResult.countMaintenanceLogs}}</td>
								  	</tr>
								  </table>
	            	</div>
	            	<div v-else>
	            		<p>Loading last audit...</p>
	            	</div>

	            </section>
	            <footer class="modal-card-foot">	  
	              <button class="button is-primary" @click="close">Done</button>
	            </footer>
	        </div>
	      </div>






			</div>

	</div>

</template>


<script>
	

	import { db } from '../main.js';
	import firebase from 'firebase/compat/app';
	import 'firebase/compat/auth'; 
	import 'firebase/firestore';
	import NProgress from 'nprogress';
	import * as moment from 'moment';

	const UpdateAudit = () => import('./UpdateAudit.vue');

	export default {

		name: 'AuditVessel',
	  components: {
	  	UpdateAudit
	  },
		computed: {
			filteredNames() {
	      let filter = new RegExp(this.findName, 'i')
	      return this.allVessels.filter(item => item.vesselName.match(filter));
	    },

		},

		mounted: function() {
			firebase.auth().onAuthStateChanged( this.onUserLogin );			
		},

		data: function() {
			return {
				allVessels: [],
				findName: '',
				reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
				selectedVessel: [],
				selected: false,

				showAudit: false,
				selectedVesselID: '',
				selectedVesselName: '',
				selectedAuditID: '',
				selectedSinceDate: '',
				selectedAuditResult: {
			    hasDesignatedStatement: false,
			    hasMasterStatement: false,
			    countHazards: 0,
			    countContacts: 0,
			    countPolicies: 0,
			    countSOPS: 0,
			    countEOPS: 0,
			    countStartupItems: 0,
			    countStartupLogs: 0,
			    countMaintenanceItems: 0,
			    countMaintenanceLogs: 0,
			    auditScore: 0,
			    auditCode: 'red',
			    auditPercent: 0,
			  },
			  selectedAuditLoaded: false,

			}
		},

		methods: {

			onUserLogin: function( user ) {
      	if (user) {      		
	      	this.loadAllVessels();
	      	
	      }     
      },

      close: function() {
      	this.showAudit = false;
      	this.selectedVesselID = '';
				this.selectedVesselName = '';
				this.selectedAuditID = '';
				this.selectedAuditDate = '';
				this.selectedSinceDate = '';
				this.selectedAuditLoaded = false;
      },

			loadAllVessels: function() {
				let self = this;
				db.collection("vessels").onSnapshot(function(querySnapshot) {
		        self.allVessels = [];
		        querySnapshot.forEach(function(doc) {
		      			self.allVessels.push({
		      				vesselID: doc.id,
      						vesselName: doc.data().vesselName,
		      				owner: doc.data().vesselOwnerName,
		      				lastAuditID: (doc.data().lastAuditID ? doc.data().lastAuditID : 'NA'),
		      				lastAuditDate: (doc.data().lastAuditDate ? self.formatDate(doc.data().lastAuditDate,'dddd D MMMM (h:mm a)') : 'Never'),
		      				sinceDate: (doc.data().lastAuditDate ? moment(doc.data().lastAuditDate.toDate()).fromNow() : 'no audit'),
		      				lastAuditPercent: (doc.data().lastAuditPercent ? doc.data().lastAuditPercent : 0),
		      				lastAuditCode: (doc.data().lastAuditCode ? doc.data().lastAuditCode : 0),
		      				hasBeenCounted: false,
		      				vesselOwnerName: (doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?'),
		      				vesselOwnerID: doc.data().vesselOwnerID,
		      			});	
		        });
		    });
			},

      formatDate : function (sentDate,format) {
          let fixDate = (sentDate ? sentDate.toDate() : new Date());
          //console.log("Fix date:",fixDate);
          return moment(fixDate).format(format);
      },

			loadVesselData: function(vessel) {
				console.log(vessel);
				this.selectedVessel = vessel;
				this.selected = true;
			},

			clearVessel: function() {
				this.findName = '';
				this.selected = false;
				this.selectedVessel = [];
			},

			viewAudit: function(vesselID,auditID,vesselName,auditDate,sinceDate) {
	      let self = this;
	      this.showAudit = true;
	      this.selectedVesselID = vesselID;
				this.selectedVesselName = vesselName;
				this.selectedAuditID = auditID;
				this.selectedSinceDate = sinceDate;
				this.selectedAuditDate = auditDate;

				async function getVesselInfo() {
					await db.collection('vessels').doc(vesselID).collection('smsAudits').doc(auditID).onSnapshot(function(doc) {
						self.selectedAuditResult.hasDesignatedStatement = doc.data().hasDesignatedStatement;
				    self.selectedAuditResult.hasMasterStatement = doc.data().hasMasterStatement;
				    self.selectedAuditResult.countHazards = doc.data().hazards;
				    self.selectedAuditResult.countContacts = doc.data().contacts;
				    self.selectedAuditResult.countPolicies = doc.data().policies;
				    self.selectedAuditResult.countSOPS = doc.data().sops;
				    self.selectedAuditResult.countEOPS = doc.data().eops;
				    self.selectedAuditResult.countStartupItems = doc.data().startupItems;
				    self.selectedAuditResult.countStartupLogs = doc.data().startupLogs;
				    self.selectedAuditResult.countMaintenanceItems = doc.data().maintenanceItems;
				    self.selectedAuditResult.countMaintenanceLogs = doc.data().maintenanceLogs;
				    self.selectedAuditResult.auditScore = doc.data().auditScore;
				    self.selectedAuditResult.auditCode = doc.data().auditCode;
				    self.selectedAuditResult.auditPercent = doc.data().auditPercent;
					// }).then(function() {
					// 	self.selectedAuditLoaded = true;
						console.log('One');
						self.selectedAuditLoaded = true;
						console.log('Two');
					});
				}

				getVesselInfo();
						
					 

      },

      updateCounted: function(result) {
      	//console.log('Result: ',result);
      	//this.allVessels[result.index].hasBeenCounted = true;
      	this.selectedVessel.hasBeenCounted = true;
      },


		}

	}


</script>


<style lang="scss" scoped>
	
	h3 {
		margin: 1rem 0 1.5rem;
	}
	.select {
 		width: 100%;
 		max-width: 400px;
	 	select {
	 		width: 100%;
	 	}
	}

	h4 {
		text-transform: uppercase;
		margin-bottom: 0.8rem;
		font-weight: bold;
	}

	ul.vessel-list {
		max-width: 320px;
		li {
			margin-bottom: 1rem;
			&:last-of-type {
				margin-bottom: 0;
			}
			.vessel-name {
				float: left;
			}
			.button {
				float: right;
			}
		}
	}
	.buttons {
		margin-top: 1rem;
	}
	.button {
		margin-top: 0;
	}

</style>