<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">{{ permanentlyDelete ? 'Delete' : 'Archive' }} Multiple Documents in a Collection</h3>
                <br />

                <div class="input-wrapper">
                    <label>Target Collection Path (must include forward but no trailing slashes)</label>
                    <input class="input" type="text" placeholder="Paste source doc path" v-model="sourcePath" />
                </div>

                <div class="clear"></div>

                <!-- <div class="input-wrapper centered">
              <label>Target Collection Path (must include forward but no trailing slashes)</label>
              <input class="input" type="text" placeholder="Paste target collection path" v-model="targetPath">
            </div> -->

                <div class="clear"></div>

                <div class="input-wrapper centered">
                    <label>Document key field (ie title)</label>
                    <input class="input" type="text" placeholder="Type key field name (case sensitive)" v-model="keyField" />
                </div>

                <div class="clear"></div>

                <div class="input-wrapper centered">
                    <label>Order by field (ie timestamp)</label>
                    <input class="input" type="text" placeholder="Type orderBy field name (case sensitive)" v-model="orderBy" />
                </div>

                <div class="clear"></div>

                <div class="checkbox-wrapper-new">
                    <input class="is-checkradio" type="checkbox" id="orderAsc" v-model="orderAsc" />
                    <label for="orderAsc"> Ascending order?</label>
                </div>

                <div class="clear"></div>

                <div class="checkbox-wrapper-new">
                    <input class="is-checkradio" type="checkbox" id="showArchived" v-model="showArchived" />
                    <label for="showArchived"> Show archived?</label>
                </div>

                <div class="clear"></div>

                <div class="checkbox-wrapper-new">
                    <input class="is-checkradio" type="checkbox" id="useSubColl" v-model="includeSubCollection" />
                    <label for="useSubColl"> Include a sub collection?</label>
                </div>

                <div v-if="includeSubCollection" class="input-wrapper centered">
                    <div class="spacer s10"></div>
                    <label :class="{ red: /\s/.test(subCollName) || subCollName == '' }">Name of Sub Collection</label>
                    <input class="input" type="text" placeholder="Type sub collection name.." v-model="subCollName" />
                </div>
                <div class="clear"></div>

                <div class="checkbox-wrapper-new">
                    <input class="is-checkradio" type="checkbox" id="permanentlyDelete" v-model="permanentlyDelete" />
                    <label for="permanentlyDelete"> Delete Permanently</label>
                </div>
                <div class="spacer s10"></div>
                <div v-if="permanentlyDelete" class="red">
                    <span><font-awesome-icon icon="exclamation-triangle"/></span> Documents will be deleted permanently!
                </div>

                <hr />

                <div class="buttons">
                    <span
                        v-if="poolOfDocs.length == 0 && keyField != '' && orderBy != ''"
                        :disabled="pathsAreValid"
                        class="button is-warning"
                        @click="displayDocs()"
                        >Show Documents</span
                    >
                    <span class="button is-light" @click="clearDocs()">Clear</span>
                </div>

                <hr />

                <table v-if="poolOfDocs.length" class="table is-bordered is-fullwidth">
                    <tr>
                        <th>Title</th>
                        <!-- <th>Details</th> -->
                        <th v-if="isSteve">Copy?</th>
                    </tr>
                    <tr v-for="(value, docID, index) in poolMap" :key="index">
                        <td :class="{ red: showArchived && value['archived'] }" v-if="showArchived || !value['archived']">
                            <!-- {{ value[keyField].toDate().toLocaleDateString() }} -->
                            {{ value[keyField] }}
                        </td>
                        <td v-if="showArchived || !value['archived']">
                            <input class="is-checkradio" type="checkbox" :id="index" :value="docID" v-model="selectedItems" />
                        </td>
                    </tr>
                    <tr>
                        <td>{{ permanentlyDelete ? 'Delete' : 'Archive' }} All?</td>
                        <td>
                            <div class="form-group-reg">
                                <input class="is-checkradio" type="checkbox" id="copyAllDocs" @change="updateCopyAll()" v-model="copyAllDocs" />
                            </div>
                        </td>
                    </tr>
                </table>

                <div v-if="selectedItems.length > 0" class="buttons">
                    <span :disabled="pathsAreValid" class="button is-danger" @click="deleteOrArchiveDocs()"
                        >{{ permanentlyDelete ? 'Delete' : 'Archive' }} Documents</span
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '../main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {
    name: 'CopyMultipleDocuments',
    components: {},
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            poolOfDocs: [],
            pooledIDs: [],
            sourcePath: '',
            // targetPath: '',
            selectedItems: [],
            keyField: 'title',
            orderBy: 'index',
            orderAsc: true,
            poolMap: {},
            showArchived: true,
            // retainDocIDs: true,
            copyAllDocs: false,
            includeSubCollection: false,
            permanentlyDelete: false,
            subCollName: 'customFields'
        };
    },

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false;
        },
        pathsAreValid() {
            // return this.sourcePath == '' || this.targetPath == '';
            return this.sourcePath == '';
        }
    },

    methods: {
        onUserLogin: function(user) {
            // if (user) {
            // }
        },

        close: function() {},

        displayDocs() {
            let self = this;
            if (this.sourcePath != '') {
                NProgress.start();
                console.log(self.sourcePath);
                let newSourcePath = self.sourcePath.length && self.sourcePath[0] === '/' ? self.sourcePath.substring(1) : self.sourcePath;
                console.log(newSourcePath);
                let isAscending = this.orderAsc ? 'asc' : 'desc';

                db.collection(newSourcePath)
                    .orderBy(self.orderBy, isAscending)
                    .get()
                    .then(querySnapshot => {
                        self.poolOfDocs = [];
                        self.pooledIDs = [];
                        querySnapshot.forEach(doc => {
                            // self.poolOfDocs.push({
                            //   id: doc.id,
                            //   title: doc.data().title ? doc.data().title : 'no title',
                            //   details: doc.data().details ? doc.data().details : 'no details',
                            // });
                            let data = doc.data();
                            data.archived = doc.data().archived ? doc.data().archived : false;
                            self.pooledIDs.push(doc.id);
                            self.poolOfDocs.push(data);
                            self.poolMap[doc.id] = data;
                        });
                    })
                    .then(() => {
                        NProgress.done();
                    });
            }
        },

        updateCopyAll() {
            console.log(this.copyAllDocs);
            this.selectedItems = this.copyAllDocs ? this.pooledIDs : [];
        },

        clearDocs() {
            this.poolOfDocs = [];
            this.sourcePath = '';
            // this.targetPath = '';
            this.selectedItems = [];
            this.pooledIDs = [];
            this.keyField = 'checklistName';
            this.poolMap = {};
            this.includeSubCollection = false;
            this.permanentlyDelete = false;
            this.subCollName = 'customFields';
            this.copyAllDocs = false;
        },

        deleteOrArchiveDocs() {
            let self = this;
            self.copyAllDocs = false;

            let newPath = self.sourcePath.length && self.sourcePath[0] === '/' ? self.sourcePath.substring(1) : self.sourcePath;
            // let newTargetPath = self.targetPath.length && self.targetPath[0] === '/' ? self.targetPath.substring(1) : self.targetPath;

            console.log(newPath);
            console.log(self.selectedItems);
            // console.log(newTargetPath);
            let confirmTitle = this.permanentlyDelete ? 'Confirm Delete All Docs' : 'Confirm Archive Docs';

            let message;
            message = {
                title: confirmTitle,
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();

                    if (self.permanentlyDelete) {
                        console.log('Deleting ' + self.selectedItems.length + ' documents');
                        var adminDeleteMultipleDocuments = firebase.functions().httpsCallable('adminDeleteMultipleDocuments');
                        adminDeleteMultipleDocuments({
                            deleteCollection: newPath,
                            selectedItems: self.selectedItems,
                            includeSubCollection: self.includeSubCollection,
                            subCollName: self.subCollName
                        })
                            .then(function(result) {
                                console.log('result: ', result);
                                console.log('result.data: ', result.data);
                                var sanitizedMessage = result.data.text;
                                console.log('Result', sanitizedMessage);
                                console.log('Message', result.data.message);
                                self.clearDocs();
                                NProgress.done();
                            })
                            .catch(function(error) {
                                console.log('Error', error);
                                NProgress.done();
                            });
                    } else {
                        console.log('Archiving ' + self.selectedItems.length + ' documents');
                        var adminArchiveMultipleDocuments = firebase.functions().httpsCallable('adminArchiveMultipleDocuments');
                        adminArchiveMultipleDocuments({
                            archiveCollection: newPath,
                            selectedItems: self.selectedItems
                        })
                            .then(function(result) {
                                console.log('result: ', result);
                                console.log('result.data: ', result.data);
                                var sanitizedMessage = result.data.text;
                                console.log('Result', sanitizedMessage);
                                console.log('Message', result.data.message);
                                self.clearDocs();
                                NProgress.done();
                            })
                            .catch(function(error) {
                                console.log('Error', error);
                                NProgress.done();
                            });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        formatDate: function(sentDate, format) {
            let fixDate = sentDate ? sentDate.toDate() : new Date();
            //console.log("Fix date:",fixDate);
            return moment(fixDate).format(format);
        }
    }
};
</script>

<style lang="scss" scoped>
.site-wrapper .container {
    max-width: 850px;
}
.form-group {
    label {
        top: auto;
        bottom: -25px;
    }
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
.traffic-light {
    position: relative;
    span {
        float: left;
        margin-right: 10px;
    }
    .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
            background: red;
            &.active {
                background: red;
            }
        }
        &.orange {
            background: orange;
            &.active {
                background: orange;
            }
        }
        &.green {
            background: #03d203;
            &.active {
                background: #03d203;
            }
        }
    }
}

input[type='text'] {
    width: 90%;
    float: left;
    display: block;
    margin-bottom: 2rem;
    // &:after {
    //   content: "\f16c";
    //   font-family: "Font Awesome 5 Brands";
    //   color: red;
    // }
    // &.valid:after {

    // }
}
.icon-wrap {
    display: block;
    float: left;
    height: 35px;
    margin-left: 10px;
    font-size: 1.5rem;
    color: red;
    &.valid {
        color: green;
    }
}
label {
    margin-bottom: 10px;
    display: block;
}
.is-checkradio[type='checkbox'] {
    opacity: 1;
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
    padding: 10px;
    margin-top: 10px;
    margin-left: 10px;
}

.checkbox-wrapper-new {
    .is-checkradio[type='checkbox'] + label {
        margin-left: 0;
        position: inherit;
        display: block;
        &:before {
            display: none !important;
        }
        &:after {
            display: none;
        }
    }
}

table.table {
    td {
        padding: 1rem;
        input {
            margin: 5px;
        }
    }
}
</style>
