<template>

  <div class="site-wrapper no-header">

    
    <div class="container">

      <div class="buttons">
        <button class="button is-info" @click="goBack()"><span><font-awesome-icon icon="chevron-left" /></span> &nbsp;Back &nbsp;</button>
      </div>

        <div class="before-icon"><font-awesome-icon icon="calendar-check" /></div><h4 class="icon">New CRM Entry</h4>  

        <div class="">

            <input class="input" v-model="newEntry.title" type="text" placeholder="Entry title" />
            
            <div class="form-group">      
              <textarea v-model="newEntry.details" class="textarea" placeholder="Entry Details (optional)" rows="5"></textarea>
            </div>

            <div class="form-group">   
              <span>&nbsp;</span>
              <datetime input-id="entryDate" v-model="newEntry.entryDate"></datetime>   
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>Date:</label>
            </div>
            
            <div class="buttons">
              <button :disabled="newEntry.title == ''" class="button is-primary" @click="saveNewEntry()">Save Entry</button>
              <!-- <button class="button" v-else>Write the CRM entry</button> -->
              <button class="button is-warning" @click="addFollowUp()">Add a Follow Up</button>
              <!-- <button v-if="editing && archived" class="button is-success" @click="restoreLog()">Restore Log</button>
              <button v-if="editing" class="button is-danger" @click="deleteLog()">Delete Permanently</button> -->
            </div>

            <hr>

            <div v-if="followUpAdded" class="notification">
              <label class="label">Follow Up Ready to Save</label>
              <p>Follow Up Action: {{followup.action}}</p>
              <p>Due Date: {{followup.date}}</p>
              <p>Notes: {{followup.notes}}</p>
              <hr>
              <div class="buttons">
                <span class="button is-info" @click="justAddFollowUp()">Just Add Follow Up</span>
              </div>
            </div>


        </div>
        

    </div>


    <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showFollowUpModal}">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
          <header class="modal-card-head is-warning">
            <p class="modal-card-title">New Follow Up Action</p>
            <button class="delete" aria-label="close" @click.prevent="close"></button>
          </header>
          <section class="modal-card-body">  

            <div>
                <div class="form-group-reg">   
                  <label class="label">Follow Up Action:</label>   
                  <input class="input thin" type="text" placeholder="Type an action..." maxlength="100" v-model="followup.action" required>
                </div>
            </div>   

            <div class="form-group-reg">
              <label class="label">Or choose a preset action</label>
              <div class="control">
                <div class="select">
                  <select v-model="followup.action">
                    <option disabled value="">Choose an action</option>
                    <option v-for="action in presetActions" :value="action">{{action}}</option>
                  </select>
                </div>
              </div>
            </div> 

            <!-- <div class="form-group-reg">
              <div class="checkbox-wrapper">
                <div class="field">
                  <input class="is-checkradio" type="checkbox" id="add-date" v-model="addDate">
                  <label class="label" for="add-date">Add a Due Date</label>
                </div>
              </div>
            </div> -->

            <div class="form-group-reg"> 
            <label class="label">Add a Follow up or due date</label> 
              <datetime input-id="followUpDate" class="date-input" placeholder="Follow Up Date" auto v-model="followup.date"></datetime>
            </div>


            <!-- <div class="form-group-reg">
              <div class="checkbox-wrapper">
                <div class="field">
                  <input class="is-checkradio" type="checkbox" id="add-notes" v-model="addNotes">
                  <label class="label" for="add-notes">Add some notes</label>
                </div>
              </div>
            </div> -->

            <div class="form-group-reg">
              <label class="label">Add any notes</label>
              <textarea class="textarea" v-model="followup.notes"></textarea>
            </div>

            <div class="spacer s10"></div>

            <!-- <div class="form-group-reg">
              <div class="checkbox-wrapper">
                <div class="field">
                  <input class="is-checkradio" type="checkbox" id="add-to-ical" v-model="addToICal">
                  <label class="label" for="add-to-ical">Add to iCal</label>
                </div>
              </div>
            </div>

            <div class="form-group-reg">
              <div class="checkbox-wrapper">
                <div class="field">
                  <input class="is-checkradio" type="checkbox" id="add-to-google" v-model="addToGoogleCal">
                  <label class="label" for="add-to-google">Add to Google Calendar</label>
                </div>
              </div>
            </div> -->

            <!-- <div class="buttons">
              <span class="button is-danger" @click="testCalAdd()">test Cal</span>
            </div> -->

            <div class="spacer s10"></div>


          </section>
          <footer class="modal-card-foot">
            <button class="button is-warning" :disabled="followup.action == ''" @click="saveFollowUp()">Save Follow Up</button>
            <button class="button cancel" @click.prevent="close">Cancel</button>
          </footer>
      </div>
    </div>





  </div>

  
</template>


<script>

  import { db } from '../main.js';
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth'; 
  import 'firebase/firestore';
  import NProgress from 'nprogress';
  import * as moment from 'moment'

  export default {

    props: {
      currentUser: Object,
    },

    data: function() {
      return {
        newEntry: {
          id: '',
          title: '',
          details: '',
          entryDate: '',
        },
        followUpAdded: false,
        showFollowUpModal: false,

        presetActions: ['Call back','Send an email','Meeting scheduled','Arrange meeting','Arrange Zoom Chat'],
        followup: {
          action: '',
          notes: '',
          date: '',
        },
        addNotes: false,
        addDate: false,
        addToICal: false,
        addToGoogleCal: false,


      }
    },

    methods: {

      saveNewEntry() {
        NProgress.start();
        var self = this;
        let tempDate = self.newEntry.entryDate ? self.newEntry.entryDate : new Date();
        let finalDate = moment(tempDate).toDate();
        console.log(finalDate);
        // let humanDate = this.formatDate(self.newDate.entryDate, "D MMMM YYYY");

        db.collection('crmDatabase').doc(self.currentUser.userid).collection('timeline').add({
          timestamp: new Date(),
          entryDate: finalDate,
          title: self.newEntry.title,
          details: self.newEntry.details,
          followUpAdded: self.followUpAdded,
        })
        .then(() => {
          db.collection('crmTimelines').add({
            timestamp: new Date(),
            entryDate: finalDate,
            title: self.newEntry.title,
            details: self.newEntry.details,
            followUpAdded: self.followUpAdded,
            userName: self.currentUser.userName,
            userID: self.currentUser.userid,
          });
        })
        .then(() => {
          if (self.followUpAdded) {
            let tempDate = self.followup.date != '' ? self.followup.date : new Date();
            let dueDate = moment(tempDate).toDate();
            db.collection('crmDatabase').doc(self.currentUser.userid).collection('followUps').add({
              timestamp: new Date(),
              date: dueDate,
              action: self.followup.action,
              notes: self.followup.notes,
            })
            .then(() => {
              db.collection('crmFollowUps').add({
                timestamp: new Date(),
                date: dueDate,
                action: self.followup.action,
                notes: self.followup.notes,
                userName: self.currentUser.userName,
                userID: self.currentUser.userid,
                userphone: self.currentUser.phoneNumber ? self.currentUser.phoneNumber : '',
                useremail: self.currentUser.emailAddress ? self.currentUser.emailAddress : '',
                complete: false,
              });
            });
          }
        })
        .then(() => {
          self.goBack();
          NProgress.done();
        })
        .catch((err) => {
          NProgress.done();
          console.log('Error',err);
        });
      },

      // testCalAdd() {
      //   console.log('testing cal');
      //   let self = this;
      //   this.$ics.addEvent('en-us', self.followup.action, self.followup.notes, 'AUSTRALIA', new Date(self.followup.date).toISOString(), new Date(self.followup.date).toISOString(), );
      // },

      close() {
        this.showFollowUpModal = false;
      },

      goBack: function() {
        // this.$router.go(-1);
        let self = this;
        this.$router.push({ 
          name: 'CrmTimelines', 
          params: { 
            isAdding: true,
            sentUser: self.currentUser,
          } 
        });
      },


      addFollowUp() {
        let self = this;
        this.showFollowUpModal = true;
      },

      saveFollowUp() {
        this.followUpAdded = true;
        this.showFollowUpModal = false;

      },

      justAddFollowUp() {
        let self = this;
        NProgress.start();
        let tempDate = self.followup.date != '' ? self.followup.date : new Date();
        let dueDate = moment(tempDate).toDate();
        db.collection('crmDatabase').doc(self.currentUser.userid).collection('followUps').add({
          timestamp: new Date(),
          date: dueDate,
          action: self.followup.action,
          notes: self.followup.notes,
        })
        .then(() => {
          db.collection('crmFollowUps').add({
            timestamp: new Date(),
            date: dueDate,
            action: self.followup.action,
            notes: self.followup.notes,
            userName: self.currentUser.userName,
            userID: self.currentUser.userid,
            userphone: self.currentUser.phoneNumber ? self.currentUser.phoneNumber : '',
            useremail: self.currentUser.emailAddress ? self.currentUser.emailAddress : '',
            complete: false,
          });
        })
        .then(() => {
          self.goBack();
          NProgress.done()
        });

      }

    }



  }
  
</script>



<style lang="scss" scoped>

  input[type="text"] {
    padding: 2rem 1rem;
    &.thin {
      padding: 1rem;
    }
  }

  textarea {
    padding: 1rem;
  }
  
  .before-icon {
    display: inline-block;
    float: left;
    margin-right: 10px;
    color: cadetblue;
    padding-top: 3px;
  }

  h4.icon {
    margin-bottom: 2rem;
    font-size: 1.3rem;
    display: inline-block;
    float: left;
    width: calc(100% - 30px);
    height: auto;
    color: cadetblue;
    text-align: left;
  }
  

</style>
