<template>
    <div class="site-wrapper no-header">
        <div class="container">
            <h4 class="icon">All Asset Subscriptions Due</h4>
            <div class="clear"></div>
            <hr />

            <div>
                <input class="is-checkradio" type="checkbox" id="showIgnored" v-model="showIgnored" />
                <label for="showIgnored">Show Ignored Assets</label>
                <input class="is-checkradio" type="checkbox" id="hidePast" v-model="hidePast" />
                <label for="hidePast">Hide Past Due</label>
                <div class="spacer s20"></div>
            </div>

            <div v-if="filteredAssets.length > 0">
                <div
                    v-for="(asset, idx) in filteredAssets"
                    :key="`asset-${idx}`"
                    class="asset"
                    :class="[
                        asset.isPast ? 'past' : asset.isDue ? 'due' : 'current',
                        asset.aaaPlans.includes('pro') ? 'box' : 'notification',
                        { 'is-success': asset.isFreebie }
                    ]"
                >
                    <span v-if="asset.ignored"><font-awesome-icon icon="eye-slash" /> &nbsp;</span>
                    {{ simpleDate(asset.nextChargeDate) }}: <span class="bold">{{ asset.vesselName }} </span>: {{ asset.vesselOwnerName }} [
                    <!-- {{ asset.isCompanyVessel ? 'Fleet' : 'Personal' }}, -->
                    {{ asset.aaaPlans.includes('pro') ? 'PRO' : asset.aaaPlans.includes('sms') ? 'SMS' : 'Free' }}
                    {{ asset.autoRenew ? ', Auto Renew' : '' }}
                    {{ asset.isDemo ? ', isDemo' : '' }}]
                    <span v-if="asset.autoRenew" class="green"><font-awesome-icon icon="check" /> &nbsp;</span>

                    <div class="spacer s10"></div>
                    <div class="buttons">
                        <span class="button is-small is-light" @click="loadAssetInfo(asset, true)">
                            <font-awesome-icon icon="info-circle" /> &nbsp;View Info
                        </span>
                        <span class="button is-small is-light" @click="ignoreAsset(asset)"><font-awesome-icon icon="eye-slash" /> &nbsp;Ignore</span>
                        <span
                            class="button is-small"
                            @click="emailOptions(asset)"
                            :class="[daysAgo(asset.lastCommunicationDate) == 'Never' ? 'red' : 'is-light']"
                        >
                            <font-awesome-icon icon="envelope" /> &nbsp;Comm Time ({{ daysAgo(asset.lastCommunicationDate) }})
                        </span>
                    </div>

                    <!-- <div class="blue">
                        <font-awesome-icon icon="money-bill" /> &nbsp;Invoice Status:
                        <span class="tag" :class="[asset.lastInvoiceStatus == 'sent' ? 'is-success' : 'is-dark']">{{ asset.lastInvoiceStatus }}</span>
                    </div> -->
                    <div class="tags">
                        <div class="tag" :class="[asset.lastInvoiceStatus == 'sent' ? 'is-success' : 'is-danger']">
                            <font-awesome-icon icon="money-bill" /> &nbsp;Last invoice: <span class="caps"> &nbsp;{{ asset.lastInvoiceStatus }}</span>
                        </div>
                        <div v-if="asset.hasDiscount" class="tag is-link"><font-awesome-icon icon="star" /> &nbsp;Discount Applied</div>
                    </div>
                </div>
            </div>

            <div class="spacer s100"></div>

            <div class="buttons floating">
                <span class="button is-light" @click="goBack()">Back</span>
            </div>
        </div>

        <simple-modal :title="selectedAsset.vesselName" theme="is-primary" v-on:closed="close()" v-if="showEmailOptions">
            <div v-if="assetAdminsLoaded.includes(selectedAsset.vesselID)">
                <div class="button is-small is-info" @click="showEmails = !showEmails">
                    <font-awesome-icon :icon="showEmails ? 'chevron-up' : 'chevron-down'" /> Selected Admin Emails ({{ selectedRecipients.length }})
                </div>
                <div v-if="showEmails" class="">
                    <div class="spacer s10"></div>
                    <div v-for="(recipient, idx) in selectedAsset.adminRecipients" :key="`recipients-${idx}`">
                        <input class="is-checkradio" type="checkbox" :id="`recipient-1-${idx}`" :value="recipient" v-model="selectedRecipients" />
                        <label :for="`recipient-1-${idx}`">{{ recipient.email }}</label>
                    </div>
                </div>
            </div>
            <div v-else><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading admin emails...</div>
            <div class="spacer s10"></div>
            <div class="button is-small is-info" @click="showAssets = !showAssets">
                <font-awesome-icon :icon="showAssets ? 'chevron-up' : 'chevron-down'" /> Selected Assets ({{ allSelectedAssets.length }})
            </div>
            <div v-if="showAssets">
                <div class="spacer s10"></div>
                <div>
                    <input
                        class="is-checkradio"
                        type="checkbox"
                        :id="selectedAsset.id"
                        :value="{ id: selectedAsset.id, vesselName: selectedAsset.vesselName }"
                        v-model="allSelectedAssets"
                    />
                    <label :for="selectedAsset.id">
                        {{ selectedAsset.vesselName }}
                    </label>
                </div>
                <div v-if="otherFleetAssets.length > 0">
                    <div
                        v-for="asset in otherFleetAssets"
                        class="other-asset"
                        :class="[asset.isExpired ? 'red' : asset.isDue ? 'orange' : asset.isPast ? 'red' : 'green']"
                    >
                        <span>
                            <input
                                class="is-checkradio"
                                type="checkbox"
                                :id="asset.id"
                                :value="{ id: asset.id, vesselName: asset.vesselName }"
                                v-model="allSelectedAssets"
                            />
                            <label :for="asset.id">
                                {{ asset.vesselName }} [{{
                                    asset.isExpired ? 'Expired' : asset.isDue ? 'Due' : asset.isPast ? 'Past Due' : 'Current'
                                }}
                                {{ daysAgo(asset.nextChargeDate) }}]
                            </label>
                        </span>
                    </div>
                </div>
            </div>
            <!-- <div v-else>None found.</div> -->
            <div class="spacer s20"></div>
            <div class="tabs is-boxed">
                <ul>
                    <li :class="{ bold: tab == 'history' }">
                        <a @click="tab = 'history'"><font-awesome-icon icon="comment" />&nbsp; <span>History</span></a>
                    </li>
                    <li :class="{ bold: tab == 'offer' }">
                        <a @click="tab = 'offer'"><font-awesome-icon icon="list-ul" />&nbsp; <span>Offer</span></a>
                    </li>
                    <li :class="{ bold: tab == 'invoice' }">
                        <a @click="tab = 'invoice'"><font-awesome-icon icon="calendar-check" />&nbsp; <span>Invoice</span></a>
                    </li>
                    <li :class="{ bold: tab == 'notices' }">
                        <a @click="tab = 'notices'"><font-awesome-icon icon="envelope" />&nbsp; <span>Fleet Notices</span></a>
                    </li>
                </ul>
            </div>

            <div class="tab-content">
                <div v-if="tab == 'history'" class="">
                    <div v-if="selectedAsset.allComms.length > 0">
                        <div class="heading" v-for="(item, idx) in selectedAsset.allComms" :key="`comms-${idx}`">
                            {{ simpleDate(item.timestamp.toDate()) }}:<br />
                            <span class="bold">[{{ item.type }}</span
                            >] {{ item.note }}
                        </div>
                    </div>
                    <div v-else class="heading red">
                        No communications found.
                    </div>
                    <hr />
                    <div class="spacer s10"></div>
                    <div class="">
                        <textarea class="textarea" v-model="newCommsNote" placeholder="Add a communication note..."></textarea>
                    </div>
                    <div class="spacer s10"></div>
                    <button :disabled="newCommsNote == ''" class="button is-small is-info" @click="addCommsNote()">Save Comms Note</button>
                </div>

                <div v-if="tab == 'offer'" class="">
                    <div>
                        <input class="input padded" type="text" placeholder="Customer first name" v-model="renewalEmailInfo.firstName" />
                    </div>
                    <div class="spacer s10"></div>
                    <!-- <div>
                        <input class="input padded" type="text" placeholder="Customer email" v-model="renewalEmailInfo.userEmail" />
                    </div> -->
                    <div class="spacer s20"></div>
                    <button
                        :disabled="selectedRecipients.length == 0 || renewalEmailInfo.firstName == ''"
                        class="button is-warning"
                        @click="sendRenewalOffer()"
                    >
                        Send Renewal Offer to {{ selectedRecipients.length > 1 ? 'Team' : renewalEmailInfo.firstName }} ({{
                            selectedRecipients.length
                        }})
                    </button>
                    <div class="spacer s20"></div>
                </div>

                <div v-if="tab == 'invoice'" class="">
                    <div>
                        Fleet: &nbsp;<span class="bold green">{{ selectedAsset.vesselOwnerName }}</span>
                    </div>
                    <div>
                        Square Customer ID: &nbsp;<span class="bold green">{{ selectedAsset.squares[0] }}</span>
                    </div>
                    <div>
                        Invoice Amount: &nbsp;<span class="bold green">${{ currentInvoiceTotal - renewalEmailInfo.discountAmount }}</span>
                    </div>
                    <div class="spacer s20"></div>
                    <input class="is-checkradio" type="checkbox" id="hasDiscount" v-model="renewalEmailInfo.hasDiscount" />
                    <label for="hasDiscount">Offer a Discount</label>
                    <div v-if="renewalEmailInfo.hasDiscount">
                        <div>
                            <div class="spacer s10"></div>
                            <label class="label">Discount Name:</label>
                            <input type="text" class="input padded" placeholder="Discount name.." v-model="renewalEmailInfo.discountName" />
                        </div>
                        <div>
                            <div class="spacer s10"></div>
                            <label class="label">Discount Amount:</label>
                            <input type="tel" class="input padded" placeholder="100" v-model="renewalEmailInfo.discountAmount" />
                        </div>
                    </div>

                    <div class="spacer s20"></div>
                    <label class="label">Renewal Message:</label>
                    <textarea class="textarea" v-model="renewalEmailInfo.renewalMessage" placeholder="Renewal message..."></textarea>
                    <div class="spacer s10"></div>
                    <div class="buttons">
                        <span class="button is-info is-small" @click="renewalEmailInfo.renewalMessage = welcomeMsg">Demo to Invoice Text</span>
                    </div>
                    <div class="buttons">
                        <button
                            :disabled="allSelectedAssets.length == 0 || selectedRecipients.length == 0"
                            class="button is-success"
                            @click="createNewAssetsInvoice()"
                        >
                            Create new invoice for {{ allSelectedAssets.length }} Assets
                        </button>
                    </div>
                    <div class="" v-if="invoiceSteps.length > 0">
                        <div v-for="(msg, idx) in invoiceSteps" :key="`msg-${idx}`">{{ msg }}</div>
                    </div>
                    <div class="spacer s20"></div>
                </div>

                <div v-if="tab == 'notices'" class="">
                    ability to add new fleet notice from here..
                </div>
            </div>
            <div class="spacer s20"></div>
        </simple-modal>

        <simple-modal
            :title="selectedAsset.vesselName"
            :theme="selectedAsset.isPast ? 'is-danger' : selectedAsset.isDue ? 'is-warning' : 'is-primary'"
            v-on:closed="showAssetInfo = false"
            v-if="showAssetInfo"
        >
            <table class="table is-fullwidth">
                <tr>
                    <th>Asset ID</th>
                    <td>
                        <span class="tag">{{ selectedAsset.vesselID }}</span>
                    </td>
                </tr>
                <tr>
                    <th>Due Date</th>
                    <td>
                        <div v-if="selectedAsset.nextChargeDate">
                            {{ simpleDate(selectedAsset.nextChargeDate) }} ({{ daysAgo(selectedAsset.nextChargeDate) }})
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>Open Invoice</th>
                    <td>{{ selectedAsset.lastInvoiceNumber }} ({{ selectedAsset.lastInvoiceStatus }})</td>
                </tr>
                <tr>
                    <th>Owner</th>
                    <td>
                        {{
                            selectedAsset.vesselOwnerName && selectedAsset.vesselOwnerName != ''
                                ? selectedAsset.vesselOwnerName
                                : selectedAsset.vesselOwner
                        }}
                        <span class="tag" :class="[selectedAsset.isCompanyVessel ? 'is-success' : 'is-light']">{{
                            selectedAsset.isCompanyVessel ? 'Fleet' : 'Private'
                        }}</span>
                    </td>
                </tr>
                <tr>
                    <th>Select this asset</th>
                    <td>
                        <input
                            class="is-checkradio"
                            type="checkbox"
                            :id="selectedAsset.id"
                            :value="{ id: selectedAsset.id, vesselName: selectedAsset.vesselName }"
                            v-model="allSelectedAssets"
                        />
                        <label :for="selectedAsset.id">
                            {{ selectedAsset.vesselName }}
                        </label>
                    </td>
                </tr>
                <tr>
                    <th>Other Fleet Assets</th>
                    <td>
                        <div v-if="otherFleetAssets.length > 0">
                            <div
                                v-for="asset in otherFleetAssets"
                                class="other-asset"
                                :class="[asset.isExpired ? 'red' : asset.isDue ? 'orange' : asset.isPast ? 'red' : 'green']"
                            >
                                <span>
                                    <input
                                        class="is-checkradio"
                                        type="checkbox"
                                        :id="asset.id"
                                        :value="{ id: asset.id, vesselName: asset.vesselName }"
                                        v-model="allSelectedAssets"
                                    />
                                    <label :for="asset.id">
                                        {{ asset.vesselName }} [{{
                                            asset.isExpired ? 'Expired' : asset.isDue ? 'Due' : asset.isPast ? 'Past Due' : 'Current'
                                        }}
                                        {{ daysAgo(asset.nextChargeDate) }}]
                                    </label>
                                </span>
                                <!-- <span>
                                    {{ asset.vesselName }} [{{
                                        asset.isExpired ? 'Expired' : asset.isDue ? 'Due' : asset.isPast ? 'Past Due' : 'Current'
                                    }}
                                    {{ daysAgo(asset.nextChargeDate) }}]
                                </span> -->
                            </div>
                            <!-- {{ allSelectedAssets }} -->
                        </div>
                        <div v-else>None found.</div>
                    </td>
                </tr>
                <tr>
                    <th>New Expiry Date</th>
                    <td>
                        <div class="form-group-reg">
                            <datetime auto input-id="newChargeDate" v-model="newChargeDate"></datetime>
                        </div>
                        <button :disabled="allSelectedAssets.length == 0" class="button is-info" @click="bulkUpdateAssets()">
                            Update Charge Date on {{ allSelectedAssets.length }} Assets
                        </button>
                    </td>
                </tr>
                <tr>
                    <th>Admin Notes</th>
                    <td>
                        <div v-if="selectedAdminNotes.length > 0">
                            <div class="admin-notes" v-for="note in selectedAdminNotes">
                                <div class="note-timestamp">
                                    {{ note.timestamp == '' ? 'No date' : simpleDate(note.timestamp.toDate()) }}
                                </div>
                                <div class="note">{{ note.note }}</div>
                            </div>
                        </div>
                        <div v-else>No notes.</div>
                    </td>
                </tr>
                <tr>
                    <th>Plans</th>
                    <td>
                        <div class="buttons" v-if="!selectedAsset.showSettings">
                            <span class="button is-small is-dark" @click="selectedAsset.showSettings = true">Show Plan Info</span>
                        </div>
                        <div class="settings" v-else>
                            <div class="setting" v-for="(setting, index) in settings">
                                <div v-if="setting.type == 'radio'">
                                    <label class="label">{{ setting.label }}</label>
                                    <div class="field" v-for="(option, idx) in setting.options" :key="`opt-${idx}`">
                                        <input
                                            class="is-checkradio"
                                            type="radio"
                                            :id="`opt-${idx}`"
                                            :value="option.val"
                                            :name="setting.label"
                                            v-model="setting.answer"
                                        />
                                        <label class="radio-label" :for="`opt-${idx}`">{{ option.label }}</label>
                                    </div>
                                </div>
                                <div v-if="setting.type == 'checkboxes'">
                                    <div class="checkbox-wrapper">
                                        <label class="label">{{ setting.label }}</label>
                                        <div class="field" v-for="(option, idx) in setting.options" :key="`check-${index}-${idx}`">
                                            <input
                                                class="is-checkradio"
                                                type="checkbox"
                                                :id="`check-${index}-${idx}`"
                                                :value="option"
                                                v-model="setting.answer"
                                            />
                                            <label :for="`check-${index}-${idx}`">{{ option }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="setting.type == 'checkbox'" class="checkbox">
                                    <input class="is-checkradio" type="checkbox" :id="`check-${index}`" v-model="setting.answer" />
                                    <label :for="`check-${index}`">{{ setting.label }}</label>
                                </div>

                                <div v-if="setting.type == 'select'">
                                    <div class="form-group-reg">
                                        <label class="label">{{ setting.label }}</label>
                                        <div class="select">
                                            <select v-model="setting.answer">
                                                <option disabled value="">Select One</option>
                                                <option v-for="(option, idx) in setting.options" :key="`status-${idx}`">{{ option }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="setting.type == 'text'">
                                    <div class="form-group-reg">
                                        <label class="label">{{ setting.label }}</label>
                                        <input class="input" type="text" v-model="setting.answer" placeholder="Answer..." />
                                    </div>
                                </div>

                                <div v-if="setting.type == 'tel'">
                                    <div class="form-group-reg">
                                        <label class="label">{{ setting.label }}</label>
                                        <input class="input" type="tel" v-model="setting.answer" placeholder="Answer..." />
                                    </div>
                                </div>
                            </div>
                            <div class="spacer s10"></div>
                            <div class="buttons">
                                <button :disabled="allSelectedAssets.length == 0" class="button is-info" @click="updateSelectedAssets()">
                                    Update Settings on {{ allSelectedAssets.length }} Assets
                                </button>
                            </div>
                            <div class="spacer s10"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>System Comms</th>
                    <td>
                        <div class="buttons" v-if="!selectedAsset.showComms">
                            <span class="button is-small is-dark" @click="loadFleetComms()">Load Fleet Comms</span>
                        </div>
                        <div v-else>
                            <div v-for="(comm, idx) in selectedAsset.systemComms" :key="`comm-${idx}`">
                                <div class="comm">
                                    <div class="heading">{{ simpleDate(comm.timestamp.toDate()) }}</div>
                                    <div class="tag">{{ comm.type }}</div>
                                    <div class="bold green">{{ comm.details }}</div>
                                    <hr />
                                </div>
                            </div>
                            <label class="label">Details:</label>
                            <textarea class="textarea" cols="30" rows="5" v-model="newNotification.details"></textarea>
                            <div class="spacer s20"></div>

                            <label class="label">Type of Notice:</label>
                            <div class="select">
                                <select v-model="newNotification.type">
                                    <option v-for="option in notificationTypes" :value="option.slug" :key="option.slug">{{ option.label }}</option>
                                </select>
                            </div>

                            <div class="form-group-reg">
                                <input class="is-checkradio" type="checkbox" id="nnSendEmails" v-model="newNotification.sendEmails" />
                                <label for="nnSendEmails"> Send emails to relevant fleet admins</label>
                            </div>

                            <div class="form-group-reg">
                                <input class="is-checkradio" type="checkbox" id="nnUpdatedate" v-model="newNotification.updateDate" />
                                <label for="nnUpdatedate"> Mark as most recent notification date</label>
                            </div>

                            <div class="spacer s10"></div>
                            <div class="buttons">
                                <span class="button is-success" :disabled="newNotification.details == ''" @click="postNotification()">
                                    <font-awesome-icon icon="envelope" /> &nbsp;Post Notification
                                </span>
                            </div>
                            <div class="spacer s10"></div>
                        </div>
                    </td>
                </tr>
            </table>

            <div class="buttons">
                <span class="button is-danger" @click="expireVessel()">Expire this Asset</span>
            </div>
        </simple-modal>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import NProgress from 'nprogress';
const SimpleModal = () => import('../reusables/SimpleModal.vue');
import { v4 as uuidv4 } from 'uuid';

export default {
    components: {
        SimpleModal
    },

    computed: {
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        preFilteredAssets() {
            return this.hidePast ? this.allAssets.filter(asset => !asset.isPast) : this.allAssets;
        },
        filteredAssets() {
            return this.showIgnored ? this.preFilteredAssets : this.preFilteredAssets.filter(asset => !asset.ignored);
        },
        currentInvoiceTotal() {
            return (
                (this.selectedAsset.aaaPlans.includes('pro') ? 249 : this.selectedAsset.aaaPlans.includes('sms') ? 149 : 0) *
                this.allSelectedAssets.length
            );
        }
    },

    data() {
        return {
            tab: 'history',
            allAssets: [],
            loadingTrips: false,
            selectedAsset: {},
            showAssetInfo: false,
            selectedAdminNotes: [],
            otherFleetAssets: [],
            allSelectedAssets: [],
            newChargeDate: new Date().toISOString(),
            settings: [
                { type: 'checkbox', label: 'Is Expired', slug: 'isExpired', answer: false },
                { type: 'checkbox', label: 'Is Freebie', slug: 'isFreebie', answer: false },
                { type: 'checkbox', label: 'In Demo / Trial Mode', slug: 'isDemo', answer: false },
                { type: 'checkbox', label: 'Parent Fleet is Enterprise', slug: 'parentFleetIsEnterprise', answer: false },
                { type: 'checkboxes', options: ['logs', 'sms', 'pro', 'ops'], label: 'AAA Plan', slug: 'aaaPlans', answer: ['pro'] },
                {
                    type: 'radio',
                    label: 'Square Plan ID',
                    slug: 'currentSquarePlanID',
                    options: [
                        { label: 'Logs+SMS', val: '5VQRQGREFHO7WYGDY6H6ATV7' },
                        { label: 'PRO', val: '726DXINJQFHRBGCNILXWRQFR' }
                    ],
                    answer: '726DXINJQFHRBGCNILXWRQFR'
                },
                { type: 'tel', label: 'Next Charge Amount', slug: 'nextVesselCharge', answer: '249' },
                { type: 'text', label: 'Last invoice Number', slug: 'lastInvoiceNumber', answer: '' },
                {
                    type: 'select',
                    options: ['paid', 'not set', 'pending', 'cancelled', 'sent', 'failed', 'complete'],
                    label: 'Last invoice status',
                    slug: 'lastInvoiceStatus',
                    answer: 'not set'
                },
                { type: 'checkbox', label: 'Has Discount', slug: 'hasDiscount', answer: false }
            ],
            systemNotifications: [],
            newNotification: {
                details: '',
                type: 'recommendation',
                sendEmails: false,
                updateDate: true
            },
            notificationTypes: [
                { slug: 'notice', label: 'General Notice' },
                { slug: 'alert', label: 'OffshoreSMS Alert' },
                { slug: 'tip', label: 'Helpful Tip' },
                { slug: 'recommendation', label: 'Recommendation' },
                { slug: 'update', label: 'Development Update' }
            ],
            showIgnored: false,
            hidePast: false,
            showEmailOptions: false,
            welcomeMsg:
                "Hi team. The free trial on your vessel is about to end and we have created a new invoice for you to turn this into a full subscription on OffshoreSMS.\nThanks for joining Australia's fastest growing digital app for commercial vessels - we look forward to having you on board.\nThanks from the OffshoreSMS Team.",
            renewalEmailInfo: {
                firstName: '',
                userEmail: '',
                vesselName: '',
                vesselID: '',
                price: '',
                hasDiscount: false,
                discountName: '',
                discountAmount: 0,
                vesselOwnerID: '',
                vesselOwnerName: '',
                isCompanyVessel: true,
                checkoutLink: '',
                recipients: [],
                addEmail: '',
                adminUsers: [],
                renewalMessage:
                    "Hi team. It's that time of year and the subscription on your vessel/s is due for renewal.\nThanks as always from the OffshoreSMS Team.",
                invoiceLink: '',
                autoRenew: false
            },
            selectedRecipients: [],
            showEmails: false,
            showAssets: false,
            assetAdminsLoaded: [],
            newCommsNote: '',
            invoiceSteps: [],
            currentSquareCustomerID: '',
            newestSquareOrder: {},
            newestSquareInvoice: {}
        };
    },

    mounted() {
        this.loadAssets();
    },

    methods: {
        close() {
            this.selectedAsset = {};
            this.selectedAdminNotes = [];
            this.otherFleetAssets = [];
            this.allSelectedAssets = [];
            this.showAssetInfo = false;
            this.systemNotifications = [];
            this.newNotification = {
                details: '',
                type: 'recommendation',
                sendEmails: false,
                updateDate: true
            };
            this.selectedRecipients = [];
            this.showEmails = false;
            this.showAssets = false;
            this.showEmailOptions = false;
            this.tab = 'history';
            this.newCommsNote = '';
            this.invoiceSteps = [];
            this.renewalEmailInfo = {
                firstName: '',
                userEmail: '',
                vesselName: '',
                vesselID: '',
                price: '',
                hasDiscount: false,
                discountName: '',
                discountAmount: 0,
                vesselOwnerID: '',
                vesselOwnerName: '',
                isCompanyVessel: true,
                checkoutLink: '',
                recipients: [],
                addEmail: '',
                adminUsers: [],
                renewalMessage:
                    "Hi team. It's that time of year and the subscription on your vessel/s is due for renewal.\nThanks as always from the OffshoreSMS Team.",
                invoiceLink: '',
                autoRenew: false
            };
            // this.currentSquareCustomerID = '';
        },

        goBack() {
            this.$router.go(-1);
        },

        simpleDate(date) {
            let dateOptions = {
                // timeZone: 'Australia/Sydney',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            };
            return date == '' ? '' : new Date(date).toLocaleDateString('en-US', dateOptions);
        },

        daysAgo(date1) {
            if (date1 == undefined || date1 == '') return 'Never';
            let diff = new Date().getTime() - date1.getTime();
            let days = Math.round(diff / (1000 * 3600 * 24));
            // return days == 1 ? days + ' day ago' : days == 0 ? 'today' : days + ' days ago';
            return days == 0 ? 'today' : days < 0 ? 'in ' + Math.abs(days) + ' days' : days + ' days ago';
        },

        // getDaysLeft(sentDate) {
        //     if (sentDate == undefined || sentDate == '') return '-';
        //     let t = new Date(sentDate).getTime();
        //     let diff = Math.round((t * 1000 * 60 * 60 * 24 - this.today) / (1000 * 60 * 60 * 24));
        //     // console.log('diff',t)
        //     return diff < 0 ? 'OVERDUE' : diff + ' days ago';
        // },

        loadAssets() {
            let self = this;
            this.loadingTrips = true;
            let now = new Date();
            let now2 = new Date();
            let now3 = new Date();
            let oldest = new Date(now.setDate(now.getDate() - 40));
            let newest = new Date(now2.setDate(now2.getDate() + 30));
            let due = new Date(now3.setDate(now3.getDate() + 15));

            // console.log(oldest, newest);

            db.collection('vessels')
                .orderBy('nextChargeDate', 'asc')
                .where('nextChargeDate', '>=', oldest)
                .where('nextChargeDate', '<=', newest)
                .onSnapshot(snap => {
                    self.allAssets = [];
                    snap.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        data.vesselID = doc.id;
                        data.isCompanyVessel = doc.data().isCompanyVessel ? doc.data().isCompanyVessel : false;
                        data.autoRenew = doc.data().autoRenew ? doc.data().autoRenew : false;
                        data.nextChargeDate = doc.data().nextChargeDate.toDate();
                        data.isDue =
                            doc
                                .data()
                                .nextChargeDate.toDate()
                                .getTime() < due.getTime();
                        data.isPast =
                            doc
                                .data()
                                .nextChargeDate.toDate()
                                .getTime() < new Date().getTime();
                        let expired = doc.data().expired ? doc.data().expired : false;
                        let orphan = doc.data().vesselOwnerName == 'XYZ Orphan';
                        data.lastInvoiceNumber = doc.data().lastInvoiceNumber ? doc.data().lastInvoiceNumber : '';
                        data.lastInvoiceStatus = doc.data().lastInvoiceStatus ? doc.data().lastInvoiceStatus : 'not set';
                        data.lastInvoiceActionDate = doc.data().lastInvoiceActionDate
                            ? doc
                                  .data()
                                  .lastInvoiceActionDate.toDate()
                                  .toISOString()
                            : new Date().toISOString();
                        data.vesselOwnerSquareCustomerID = doc.data().vesselOwnerSquareCustomerID ? doc.data().vesselOwnerSquareCustomerID : '';
                        data.fleetSquareCustomerID = '';
                        data.squares = [];
                        data.showSettings = false;
                        data.showComms = false;
                        data.systemComms = [];
                        data.aaaPlans = doc.data().aaaPlans ? doc.data().aaaPlans : [];
                        let ignored = doc.data().ignored ? doc.data().ignored : false;
                        data.hasDiscount = doc.data().hasDiscount ? doc.data().hasDiscount : false;
                        data.ignored = ignored;
                        data.adminRecipients = [];
                        data.adminRecipientIDs = [];
                        data.allComms = [];
                        data.lastCommunicationDate = doc.data().lastCommunicationDate ? doc.data().lastCommunicationDate.toDate() : '';
                        if (!expired && !orphan) self.allAssets.push(data);
                    });
                });
            setTimeout(() => {
                this.loadingTrips = false;
            }, 2000);
        },

        async addCommsNote() {
            let self = this;
            NProgress.start();
            await db
                .collection('vessels')
                .doc(this.selectedAsset.vesselID)
                .collection('assetCommunication')
                .add({
                    note: self.newCommsNote,
                    type: 'communication',
                    sentBy: 'OffshoreSMS Team',
                    timestamp: new Date()
                })
                .then(async () => {
                    await db
                        .collection('vessels')
                        .doc(self.selectedAsset.vesselID)
                        .update({
                            lastCommunicationDate: new Date()
                        });
                });
            NProgress.done();
            this.newCommsNote = '';
        },

        async sendRenewalOffer() {
            let self = this;
            NProgress.start();
            console.log(this.selectedRecipients);

            let price = this.selectedAsset.aaaPlans.includes('pro') ? 249 : 149;
            let checkoutLink = this.selectedAsset.aaaPlans.includes('pro') ? 'https://square.link/u/Bo2ufTD1' : 'https://square.link/u/qshAbMEF';

            let sendRenewalInvitation = firebase.functions().httpsCallable('sendRenewalInvitation');
            sendRenewalInvitation({
                useremail: this.selectedRecipients.map(m => m.email),
                // useremail: ['steve@offshoresms.com.au', 'josh.offshoresms@outlook.com'],
                username: this.selectedRecipients.length > 1 ? 'Team' : this.renewalEmailInfo.firstName,
                vesselName: this.selectedAsset.vesselName,
                price: price,
                checkoutLink: checkoutLink
            });
            await db
                .collection('vessels')
                .doc(this.selectedAsset.vesselID)
                .update({
                    lastCommunicationDate: new Date()
                })
                .then(async () => {
                    await db
                        .collection('vessels')
                        .doc(self.selectedAsset.vesselID)
                        .collection('assetCommunication')
                        .add({
                            note: self.selectedAsset.isCompanyVessel
                                ? 'Renewal offer sent to fleet admins: ' + self.selectedRecipients.map(m => m.email).join(', ')
                                : 'Renewal offer sent to vessel owner',
                            type: 'renewal-offer',
                            sentBy: 'OffshoreSMS Team',
                            timestamp: new Date()
                        })
                        .then(async () => {
                            await db
                                .collection('vessels')
                                .doc(self.selectedAsset.vesselID)
                                .update({
                                    lastCommunicationDate: new Date()
                                });
                        });
                });
            this.renewalEmailInfo.firstName = '';
            this.selectedRecipients = [];
            NProgress.done();
            // this.close();
        },

        async emailOptions(asset) {
            await this.loadAssetInfo(asset, false);
            let self = this;
            this.selectedAsset = { ...asset };
            this.selectedAsset.loading = true;
            this.showEmailOptions = true;
            let adminIDs = [];
            if (!this.assetAdminsLoaded.includes(asset.vesselID)) {
                console.log('fire');
                if (asset.isCompanyVessel) {
                    await db
                        .collection('companies')
                        .doc(asset.vesselOwnerID)
                        .get()
                        .then(async doc => {
                            self.selectedAsset.fleetSquareCustomerID = doc.data().squareCustomerID ? doc.data().squareCustomerID : '';
                            let square = doc.data().squareCustomerID ? doc.data().squareCustomerID : '';
                            if (!self.selectedAsset.squares.includes(square)) self.selectedAsset.squares.push(square);
                            adminIDs = doc.data().adminUsers ? doc.data().adminUsers : [];
                            if (adminIDs.length > 0) {
                                await Promise.all(
                                    adminIDs.map(async adminID => {
                                        await db
                                            .collection('userdata')
                                            .doc(adminID)
                                            .get()
                                            .then(docRef => {
                                                let useremail = docRef.data().useremail ? docRef.data().useremail : '';
                                                if (!self.selectedAsset.adminRecipientIDs.includes(adminID)) {
                                                    self.selectedAsset.adminRecipientIDs.push(adminID);
                                                    self.selectedAsset.adminRecipients.push({
                                                        id: adminID,
                                                        email: useremail
                                                    });
                                                }
                                            });
                                    })
                                );
                            }
                        });
                } else {
                    await db
                        .collection('userdata')
                        .doc(this.selectedAsset.vesselOwnerID)
                        .get()
                        .then(docRef => {
                            self.selectedAsset.fleetSquareCustomerID = docRef.data().squareCustomerID ? docRef.data().squareCustomerID : '';
                            self.currentSquareCustomerID = docRef.data().squareCustomerID ? docRef.data().squareCustomerID : '';
                            let square = docRef.data().squareCustomerID ? docRef.data().squareCustomerID : '';
                            if (!self.selectedAsset.squares.includes(square)) self.selectedAsset.squares.push(square);
                            let useremail = docRef.data().useremail ? docRef.data().useremail : '';
                            self.selectedAsset.adminRecipients.push({
                                id: self.selectedAsset.vesselOwnerID,
                                email: useremail
                            });
                            self.selectedAsset.adminRecipientIDs.push(self.selectedAsset.vesselOwnerID);
                        });
                }
                db.collection('vessels')
                    .doc(asset.vesselID)
                    .collection('assetCommunication')
                    .orderBy('timestamp', 'desc')
                    .onSnapshot(snap => {
                        self.selectedAsset.allComms = [];
                        snap.forEach(doc => {
                            let data = doc.data();
                            self.selectedAsset.allComms.push(data);
                        });
                    });
                this.assetAdminsLoaded.push(asset.vesselID);
            }
            this.selectedAsset.loading = false;
        },

        async ignoreAsset(asset) {
            let message;
            message = {
                title: 'Really ignore ' + asset.vesselName + '?',
                body: 'Click YES below to ignore.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(dialog => {
                    NProgress.start();
                    db.collection('vessels')
                        .doc(asset.vesselID)
                        .update({
                            ignored: true
                        })
                        .then(() => NProgress.done());
                })
                .catch(error => {
                    console.log(error);
                    NProgress.done();
                });
        },

        async loadAdminNotes(assetID) {
            let self = this;
            db.collection('vessels')
                .doc(assetID)
                .collection('adminNotes')
                .orderBy('timestamp', 'desc')
                .onSnapshot(snap => {
                    self.selectedAdminNotes = [];
                    snap.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        self.selectedAdminNotes.push(data);
                    });
                });
        },

        loadOtherFleetAssets(vesselOwnerID, assetID) {
            let now = new Date();
            let due = new Date(now.setDate(now.getDate() + 30));
            db.collection('vessels')
                .where('vesselOwnerID', '==', vesselOwnerID)
                .orderBy('nextChargeDate', 'asc')
                .onSnapshot(snap => {
                    this.otherFleetAssets = [];
                    snap.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        data.isDue =
                            doc
                                .data()
                                .nextChargeDate.toDate()
                                .getTime() < due.getTime();
                        data.isPast =
                            doc
                                .data()
                                .nextChargeDate.toDate()
                                .getTime() < new Date().getTime();
                        data.expired = doc.data().expired ? doc.data().expired : false;
                        data.nextChargeDate = doc.data().nextChargeDate.toDate();
                        if (doc.id != assetID) this.otherFleetAssets.push(data);
                    });
                });
        },

        loadFleetSystemNotifications(vesselOwnerID, assetID) {
            let self = this;
            db.collection('companies')
                .doc(vesselOwnerID)
                .collection('systemNotifications')
                .orderBy('timestamp', 'desc')
                .onSnapshot(snap => {
                    snap.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        self.systemNotifications.push(data);
                    });
                });
        },

        async loadAssetInfo(asset, showInfo) {
            this.selectedAdminNotes = [];
            this.systemNotifications = [];
            this.otherFleetAssets = [];
            this.allSelectedAssets = [];
            NProgress.start();
            let vesselOwnerID = asset.vesselOwnerID;
            let isCompanyVessel = asset.isCompanyVessel;
            this.selectedAsset = { ...asset };

            // console.log(this.settings);

            this.settings[0].answer = asset.isExpired ? asset.isExpired : false;
            this.settings[2].answer = asset.isFreebie ? asset.isFreebie : false;
            this.settings[1].answer = asset.isDemo ? asset.isDemo : false;
            this.settings[3].answer = asset.parentFleetIsEnterprise ? asset.parentFleetIsEnterprise : false;
            this.settings[4].answer = asset.aaaPlans ? asset.aaaPlans : ['logs'];
            this.settings[5].answer = asset.currentSquarePlanID ? asset.currentSquarePlanID : '5VQRQGREFHO7WYGDY6H6ATV7';
            this.settings[7].answer = asset.lastInvoiceNumber ? asset.lastInvoiceNumber : '';
            this.settings[8].answer = asset.lastInvoiceStatus ? asset.lastInvoiceStatus : 'not set';
            this.settings[9].answer = asset.hasDiscount ? asset.hasDiscount : false;

            if (isCompanyVessel) {
                // load other fleet assets
                this.loadOtherFleetAssets(vesselOwnerID, asset.vesselID);
                this.loadFleetSystemNotifications(vesselOwnerID, asset.vesselID);

                // need to set settings for this asset to the settings array
                // record and orderBy nextChargeDate for all other assets
                // need option to select other assets for the renewal communication
                // need to check notification options - start with primary admins, and add emails for others who get system notifications
                // options to send renewal alerts or payment confirmation
                // what about offer to renew?
                // flag in modal if auto renew is an option
                // need to add system notification if fleet as well as email, and maybe in app and sms?
            }
            this.loadAdminNotes(asset.vesselID);
            if (showInfo) this.showAssetInfo = true;
            NProgress.done();
        },

        expireVessel() {
            let self = this;
            this.showAssetInfo = false;
            let message;
            message = {
                title: 'Really expire ' + this.selectedAsset.vesselName + '?',
                body: 'Click YES below to expire.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(dialog => {
                    NProgress.start();
                    let now = new Date();
                    let expiry = new Date(now.setDate(now.getDate() - 1));
                    db.collection('vessels')
                        .doc(self.selectedAsset.vesselID)
                        .update({
                            nextChargeDate: expiry,
                            listOfCrewIDs: [],
                            listOfMasterIDs: [],
                            isPaidFor: false,
                            isDemo: false,
                            isFreebie: true,
                            hasDiscount: false,
                            vesselOwnerID: '7uPVEGZgalaJOrUSZT8VzdteZ4g1',
                            vesselOwnerName: 'XYZ Orphan',
                            isActive: false,
                            isExpired: true
                        })
                        .then(() => NProgress.done());
                })
                .catch(error => {
                    console.log(error);
                    self.close();
                    NProgress.done();
                });
        },

        async postNotification() {
            let self = this;
            console.log(this.newNotification);
            NProgress.start();
            db.collection('companies')
                .doc(this.selectedAsset.vesselOwnerID)
                .collection('systemNotifications')
                .add({
                    details: self.newNotification.details,
                    type: self.newNotification.type,
                    sendEmails: self.newNotification.sendEmails,
                    updateDate: self.newNotification.updateDate,
                    viewedIDs: [],
                    archived: false,
                    timestamp: new Date()
                })
                .then(docRef => {
                    if (self.newNotification.updateDate) {
                        db.collection('companies')
                            .doc(self.selectedAsset.vesselOwnerID)
                            .update({
                                lastSystemNotificationDate: new Date(),
                                lastSystemNotificationID: docRef.id
                            });
                    }
                    if (self.newNotification.sendEmails) {
                        // send emails to relevant fleet admins
                        var notificationAlertAllAdmins = firebase.functions().httpsCallable('notificationAlertAllAdmins');
                        notificationAlertAllAdmins({
                            fleetID: self.selectedAsset.vesselOwnerID,
                            notificationTypes: ['updates'],
                            subject: 'New system notification',
                            sender: 'OffshoreSMS Updates',
                            body: 'New Fleet Notice: ' + self.newNotification.details
                        });
                    }
                })
                .then(() => {
                    this.newNotification = {
                        details: '',
                        type: 'recommendation',
                        sendEmails: false,
                        updateDate: true
                    };
                    NProgress.done();
                });
        },

        async loadFleetComms() {
            NProgress.start();
            db.collection('companies')
                .doc(this.selectedAsset.vesselOwnerID)
                .collection('systemNotifications')
                .where('archived', '==', false)
                .onSnapshot(snap => {
                    this.selectedAsset.systemComms = [];
                    snap.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        this.selectedAsset.systemComms.push(data);
                    });
                });
            setTimeout(() => {
                this.selectedAsset.showComms = true;
                NProgress.done();
            }, 1000);
        },

        async updateSelectedAssets() {
            console.log(this.allSelectedAssets);
            console.log('settings', this.settings);
            // let updateData = this.settings.map(setting => {
            //     return {
            //         [setting.slug]: setting.answer
            //     };
            // });
            let updateData = {};
            await Promise.all(
                this.settings.map(setting => {
                    updateData[setting.slug] = setting.type == 'tel' ? parseInt(setting.answer) : setting.answer;
                })
            );
            console.log(updateData);

            NProgress.start();
            await Promise.all(
                this.allSelectedAssets.map(asset => {
                    db.collection('vessels')
                        .doc(asset.id)
                        .update(updateData);
                })
            );
            NProgress.done();
        },

        async createNewAssetsInvoice() {
            let self = this;
            this.invoiceSteps = [];
            console.log(this.allSelectedAssets, this.selectedAsset);
            // return;
            NProgress.start();

            const sleep = milliseconds => new Promise(resolve => setTimeout(resolve, milliseconds));

            // plan:
            // call ordersApi.createOrder to create a new order for X assets on plan Y in fleet Z (cf: createNewSquareOrder)
            // call invoicesApi.createInvoice to create new invoice from the order
            // call invoicesApi.publishInvoice to publish the newly created invoice
            // send SendGrid email alerting all selected admins of the new invoice in the fleet dashboard
            // post new system notification in the fleet dashboard

            let randomID = uuidv4();
            let lineItems = [];
            let plan = this.selectedAsset.aaaPlans.includes('pro')
                ? 'Pro Plan Annual Subscription'
                : this.selectedAsset.aaaPlans.includes('sms')
                ? 'Logs+SMS Annual Subscription'
                : 'Free Digital Logs';
            let price = this.selectedAsset.aaaPlans.includes('pro') ? 24900 : this.selectedAsset.aaaPlans.includes('sms') ? 14900 : 0;
            let squareCustomerID = this.selectedAsset.squares.length > 0 ? this.selectedAsset.squares[0] : '';
            if (squareCustomerID == '') {
                console.log('No square customer ID found');
                return;
            }
            // await Promise.all(
            //     this.allSelectedAssets.map(async asset => {
            //         lineItems.push({
            //             uid: asset.id,
            //             name: plan,
            //             quantity: '1',
            //             metadata: {
            //                 vesselID: asset.id
            //             },
            //             basePriceMoney: {
            //                 amount: price,
            //                 currency: 'AUD'
            //             }
            //         });
            //     })
            // );

            let singleLineItem = plan + ' (' + this.allSelectedAssets.map(m => m.vesselName).join(', ') + ')';
            lineItems.push({
                uid: this.allSelectedAssets[0].id,
                name: singleLineItem,
                quantity: '1',
                metadata: {
                    vesselID: this.allSelectedAssets[0].id
                },
                basePriceMoney: {
                    amount: this.currentInvoiceTotal * 100,
                    currency: 'AUD'
                }
            });

            console.log(lineItems);
            await sleep(1000);
            this.invoiceSteps.push('Creating new order with ' + this.allSelectedAssets.length + ' line items...');

            let ticketName = this.selectedAsset.vesselOwnerName.slice(0, Math.min(this.selectedAsset.vesselOwnerName, 29));

            var createNewSquareOrder = firebase.functions().httpsCallable('createNewSquareOrder');
            createNewSquareOrder({
                customerID: squareCustomerID,
                lineItems: lineItems,
                ticketName: ticketName,
                hasDiscount: this.renewalEmailInfo.hasDiscount && this.renewalEmailInfo.discountAmount > 0 ? true : false,
                discountAmount: this.renewalEmailInfo.discountAmount * 100,
                discountName: this.renewalEmailInfo.discountName,
                idempotencyKey: randomID,
                locationId: '40ZFX2VT9XGE3' // PRODUCTION
            })
                .then(async result => {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    console.log(sanitizedMessage);
                    if (result.data.success) {
                        if (sanitizedMessage.order && sanitizedMessage.order.id) {
                            console.log('New order created in Square: ' + sanitizedMessage.order.id);
                            // create a new invoice based on this order:
                            self.newestSquareOrder = sanitizedMessage.order;
                            self.createSquareInvoiceFromOrder();
                            // NProgress.done();
                        } else {
                            console.log("Something didn't work");
                            NProgress.done();
                        }
                    } else {
                        console.log('Function returned an error when trying to create a new order');
                        NProgress.done();
                    }
                })
                .catch(result => {
                    console.log('Cloud function error: createNewSquareOrder', result);
                    window.alert('The cloud function createNewSquareOrder returned an error');
                    NProgress.done();
                });

            // invoiceSteps

            // NProgress.done();
        },

        async createSquareInvoiceFromOrder() {
            let self = this;
            const sleep = milliseconds => new Promise(resolve => setTimeout(resolve, milliseconds));
            await sleep(1000);
            this.invoiceSteps.push('Creating a new invoice from the order...');
            let squareCustomerID = this.selectedAsset.squares.length > 0 ? this.selectedAsset.squares[0] : '';
            let randomID = uuidv4();

            if (Object.keys(this.newestSquareOrder).length) {
                console.log('creating new invoice');
                const scheduleDate = new Date(this.selectedAsset.nextChargeDate);

                var createNewSquareInvoice = firebase.functions().httpsCallable('createNewSquareInvoice');
                createNewSquareInvoice({
                    customerID: squareCustomerID,
                    scheduleDate: scheduleDate,
                    orderID: this.newestSquareOrder.id,
                    idempotencyKey: randomID,
                    locationId: '40ZFX2VT9XGE3' // PRODUCTION
                })
                    .then(async result => {
                        var sanitizedMessage = JSON.parse(result.data.text);
                        console.log(sanitizedMessage);
                        if (result.data.success) {
                            if (sanitizedMessage.invoice && sanitizedMessage.invoice.id) {
                                console.log('New invoice created in Square: ' + sanitizedMessage.invoice.id);
                                // publish this new invoice:
                                self.newestSquareInvoice = sanitizedMessage.invoice;
                                console.log('newestSquareInvoice', self.newestSquareInvoice);
                                // self.publishSquareInvoice(); // testing published in cloud function at same time
                                self.sendAminNotifications();
                                // NProgress.done();
                            } else {
                                console.log("Something didn't work");
                                NProgress.done();
                            }
                        } else {
                            console.log('Function returned an error when trying to create a new invoice');
                            NProgress.done();
                        }
                    })
                    .catch(result => {
                        console.log('Cloud function error: createSquareInvoiceFromOrder', result);
                        window.alert('The cloud function createSquareInvoiceFromOrder returned an error');
                        NProgress.done();
                    });

                // if invoice published successfully: notify admins and create system notification
            }
        },

        // async publishSquareInvoice() {
        //     let self = this;
        //     const sleep = milliseconds => new Promise(resolve => setTimeout(resolve, milliseconds));
        //     await sleep(1000);
        //     this.invoiceSteps.push('Publishing new invoice...');

        //     if (Object.keys(this.newestSquareInvoice).length) {
        //         console.log('publishing invoice');
        //         let randomID = uuidv4();

        //         var publishNewSquareInvoice = firebase.functions().httpsCallable('publishNewSquareInvoice');
        //         publishNewSquareInvoice({
        //             invoiceID: this.newestSquareInvoice.id,
        //             idempotencyKey: randomID
        //         }).then(async result => {
        //             var sanitizedMessage = JSON.parse(result.data.text);
        //             console.log(sanitizedMessage);
        //             if (result.data.success) {
        //                 if (sanitizedMessage.invoice && sanitizedMessage.invoice.id) {
        //                     console.log('Invoice published in Square: ' + sanitizedMessage.invoice.id);
        //                     // publish this new invoice:
        //                     self.sendAminNotifications();
        //                     // NProgress.done();
        //                 } else {
        //                     console.log("Something didn't work");
        //                     NProgress.done();
        //                 }
        //             } else {
        //                 console.log('Function returned an error when trying to publish an invoice');
        //                 NProgress.done();
        //             }
        //         });
        //     }
        // },

        async sendAminNotifications() {
            let self = this;
            const sleep = milliseconds => new Promise(resolve => setTimeout(resolve, milliseconds));
            await sleep(1000);
            this.invoiceSteps.push('Sending email notifications to ' + this.selectedRecipients.length + ' admins...');
            // Send admin emails with from SendGrid

            let invoiceLink = this.newestSquareInvoice.publicUrl ? this.newestSquareInvoice.publicUrl : '';
            console.log(invoiceLink);
            let emailData = {
                renewalMessage: this.renewalEmailInfo.renewalMessage,
                invoiceLink: invoiceLink,
                autoRenew: false,
                recipients: this.selectedRecipients.map(m => m.email),
                emailSubject: 'A new invoice is ready',
                fcmTitle: 'A new invoice is ready',
                fcmBody: 'Hi team. A new annual invoice for your vessel/s has been created. Please check your email for details.',
                adminUsers: this.selectedRecipients.map(m => m.id),
                hasRedirect: false,
                redirectTarget: 'allFleets',
                assetID: '',
                assetNames: ''
            };
            console.log(emailData);

            var sendFleetRenewalAlerts = firebase.functions().httpsCallable('sendFleetRenewalAlerts');
            sendFleetRenewalAlerts(emailData).catch(function(error) {
                console.log('Error', error.text);
                NProgress.done();
            });

            await sleep(1000);
            this.invoiceSteps.push('Creating new system notification to the fleet ' + this.selectedAsset.vesselOwnerName + '...');
            // Create new system notification in the fleet dashboard
            await db
                .collection('companies')
                .doc(this.selectedAsset.vesselOwnerID)
                .collection('systemNotifications')
                .add({
                    details:
                        'A new invoice has been created for your vessel/s. Please go to Manage Billing to view and pay the latest invoice. Thanks from the OffshoreSMS team.',
                    type: 'notice',
                    sendEmails: false,
                    updateDate: true,
                    viewedIDs: [],
                    archived: false,
                    timestamp: new Date()
                })
                .then(async docRef => {
                    await db
                        .collection('companies')
                        .doc(self.selectedAsset.vesselOwnerID)
                        .update({
                            lastSystemNotificationDate: new Date(),
                            lastSystemNotificationID: docRef.id
                        });
                });

            // TODO: update all assets with lastInvoiceNumber, lastInvoiceStatus, lastInvoiceActionDate, NB: lastInvoiceStatus should be 'sent'
            await Promise.all(
                this.allSelectedAssets.map(async asset => {
                    await db
                        .collection('vessels')
                        .doc(asset.id)
                        .update({
                            lastInvoiceNumber: self.newestSquareInvoice.invoiceNumber,
                            lastInvoiceStatus: 'sent',
                            lastInvoiceActionDate: new Date(),
                            lastCommunicationDate: new Date(),
                            discountApplied: self.renewalEmailInfo.hasDiscount
                        });
                    await db
                        .collection('vessels')
                        .doc(asset.id)
                        .collection('assetCommunication')
                        .add({
                            note: 'New invoice created: ' + self.newestSquareInvoice.invoiceNumber,
                            type: 'renewal',
                            sentBy: 'OffshoreSMS Team',
                            timestamp: new Date()
                        });
                })
            );
            this.close();
            NProgress.done();
        },

        async bulkUpdateAssets() {
            this.showAssetInfo = false;
            console.log(this.allSelectedAssets);
            var now = new Date();
            let newDate = new Date(this.newChargeDate);

            let self = this;
            let message;
            message = {
                title: 'Update the nextChargeDate on ' + this.allSelectedAssets.length + ' assets to ' + this.simpleDate(newDate),
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES, UPDATE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(async dialog => {
                    NProgress.start();
                    await Promise.all(
                        self.allSelectedAssets.map(asset => {
                            db.collection('vessels')
                                .doc(asset.id)
                                .update({
                                    nextChargeDate: newDate
                                });
                        })
                    );
                    NProgress.done();
                    this.close();
                })
                .catch(error => {
                    console.log(error);
                    NProgress.done();
                    this.close();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.asset {
    margin-bottom: 12px;
    &.past {
        color: red;
    }
    &.due {
        color: orange;
    }
    // &.current {
    //     color: green;
    // }
    .buttons {
        margin-bottom: 4px;
    }
}
.admin-notes {
    margin-top: 6px;
    .note {
        font-weight: bold;
        margin-left: 10px;
    }
    .note-timestamp {
        font-size: 0.8rem;
    }
}
.form-group-reg {
    margin-bottom: 10px;
}
.checkbox {
    margin: 8px 0;
}
.settings {
    .setting {
        margin-bottom: 10px;
        border-bottom: 1px solid #f4f4f4;
        padding-bottom: 10px;
    }
}
.tabs {
    margin-bottom: 1rem !important;
    a,
    a:visited {
        border: none;
        padding: 12px 20px;
    }
    ul {
        li {
            &.is-active {
                background: #f4f4f4;
            }
        }
    }
}
</style>
