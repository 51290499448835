<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <div class="buttons">
                    <button class="button is-info" @click="goBack()">
                        <span><font-awesome-icon icon="chevron-left"/></span> &nbsp;Back &nbsp;
                    </button>
                </div>

                <div class="tabs is-boxed is-centered">
                    <ul>
                        <li :class="{ 'is-active': tab == '1' }">
                            <a @click="tab = '1'"><font-awesome-icon icon="tags" />&nbsp; <span>All Tags</span></a>
                        </li>
                        <li :class="{ 'is-active': tab == '2' }">
                            <a @click="tab = '2'"><font-awesome-icon icon="list-ul" />&nbsp; <span>All Categories</span></a>
                        </li>
                    </ul>
                </div>

                <div class="tab-content" v-if="tab == '2'">
                    <div class="spacer s50"></div>
                    <div class="box" v-if="tagCategories.length > 0">
                        <draggable v-model="tagCategories" class="sorting-list-clean" group="people" @start="drag = true" @end="onReorder()">
                            <div v-for="(cat, idx) in tagCategories">
                                <div class="tag-cat flex">
                                    <span v-if="!cat.editing" class="cat-title tag is-medium">{{ cat.title }}</span>
                                    <span v-else>
                                        <input
                                            type="text"
                                            placeholder="Type category name.."
                                            class="input"
                                            v-model="cat.title"
                                            @input="cat.changed = true"
                                        />
                                    </span>
                                    <span v-if="cat.editing && cat.changed" @click="updateCat(cat)" class="button is-small is-success">
                                        &nbsp;<font-awesome-icon icon="check-square"
                                    /></span>
                                    <span class="button is-small is-light" @click="cat.editing = !cat.editing" :class="{ 'is-warning': cat.editing }">
                                        &nbsp;<font-awesome-icon :icon="cat.editing ? 'times' : 'pen'"
                                    /></span>
                                </div>
                            </div>
                        </draggable>

                        <div v-if="catOrderChanged" class="buttons">
                            <span class="button is-info" @click="updateCategoryOrder()">
                                &nbsp;<font-awesome-icon icon="list-ul" />Save New Order</span
                            >
                        </div>
                    </div>
                    <div v-else class="box"><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading tag categories...</div>

                    <div v-if="!showAddCat" class="buttons">
                        <span class="button is-success" @click="showAddCat = true">Add a Category</span>
                    </div>

                    <div v-if="showAddCat">
                        <input type="text" placeholder="Type category name.." class="input pad s15" v-model="newCatTitle" />
                    </div>
                    <div class="spacer s10"></div>
                    <div v-if="showAddCat" class="buttons">
                        <button :disabled="newCatTitle == ''" class="button is-success" @click="saveNewCat()">
                            &nbsp;<font-awesome-icon icon="check" />Save Category
                        </button>
                        <button
                            class="button is-danger"
                            @click="
                                showAddCat = false;
                                newCatTitle = '';
                            "
                        >
                            &nbsp;<font-awesome-icon icon="trash-alt" />Cancel
                        </button>
                    </div>
                </div>

                <div class="tab-content" v-if="tab == '1'">
                    <div class="spacer s50"></div>
                    <!-- {{ allTags.map(n => n.title) }} -->

                    <div class="box" v-if="!tagsLoaded"><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading tags...</div>

                    <div v-else>
                        <div v-for="(cat, idx) in tagCategories">
                            <div class="heading">{{ cat.title }}</div>
                            <div class="spacer s10"></div>
                            <div class="box" v-if="cat.tags.length > 0">
                                <draggable
                                    v-model="cat.tags"
                                    class="sorting-list-clean"
                                    :group="`tags-${idx}`"
                                    @start="drag = true"
                                    @end="onReorderTags(cat)"
                                >
                                    <div v-for="(tag, ind) in cat.tags" :key="`list-${ind}`">
                                        <div class="tag-cat flex">
                                            <span v-if="!tag.editing" class="cat-title tag is-medium">{{ tag.title }}</span>
                                            <span v-else>
                                                <input
                                                    type="text"
                                                    placeholder="Type tag name.."
                                                    class="input"
                                                    v-model="tag.title"
                                                    @input="tag.changed = true"
                                                />
                                            </span>
                                            <span v-if="tag.editing && tag.changed" @click="updateTag(tag)" class="button is-small is-success">
                                                &nbsp;<font-awesome-icon icon="check-square"
                                            /></span>
                                            <span
                                                class="button is-small is-light"
                                                @click="tag.editing = !tag.editing"
                                                :class="{ 'is-warning': tag.editing }"
                                            >
                                                &nbsp;<font-awesome-icon icon="edit"
                                            /></span>
                                            <span v-if="tag.editing" @click="deleteTag(tag)" class="button is-small is-danger">
                                                &nbsp;<font-awesome-icon icon="trash-alt"
                                            /></span>
                                        </div>
                                    </div>
                                </draggable>

                                <div v-if="cat.changed" class="buttons">
                                    <span class="button is-info is-small" @click="updateTagOrder(cat)">
                                        &nbsp;<font-awesome-icon icon="list-ul" />Save New Order</span
                                    >
                                </div>
                            </div>
                            <div v-else class="box">No tags in this category yet.</div>
                            <div class="spacer s30"></div>
                        </div>

                        <div v-if="!showAddTag" class="buttons">
                            <span class="button is-success" @click="showAddTagModal = true">
                                Add a Tag
                            </span>
                        </div>

                        <div v-if="showAddTag && tagCategories.length > 0">
                            <label class="label">Select a Tag Category</label>
                            <div class="select">
                                <select v-model="newTagCategory">
                                    <option v-for="(cat, idx) in tagCategories" :value="cat.id" :key="`newcat-${idx}`">{{ cat.title }}</option>
                                </select>
                            </div>
                            <div>
                                <input type="text" placeholder="Type tag name.." class="input pad s15" v-model="newTagTitle" />
                            </div>
                            <div class="spacer s10"></div>
                            <div class="buttons">
                                <button :disabled="newTagTitle == ''" class="button is-success" @click="saveNewTag()">
                                    &nbsp;<font-awesome-icon icon="check" />Save Tag
                                </button>
                                <button
                                    class="button is-danger"
                                    @click="
                                        showAddTag = false;
                                        newTagTitle = '';
                                        newTagCategory = 'xSu8bBuH6RbVsPdE7SWs';
                                    "
                                >
                                    &nbsp;<font-awesome-icon icon="trash-alt" />Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <simple-modal title="Add New Tag" theme="is-primary" submitText="Cancel" v-on:closed="close()" v-if="showAddTagModal">
                    <label class="label">Select a Tag Category</label>
                    <div class="select">
                        <select v-model="newTagCategory">
                            <option v-for="(cat, idx) in tagCategories" :value="cat.id" :key="`newcat-${idx}`">{{ cat.title }}</option>
                        </select>
                    </div>
                    <div>
                        <input type="text" placeholder="Type tag name.." class="input pad s15" v-model="newTagTitle" />
                    </div>
                    <div class="spacer s10"></div>
                    <div class="buttons">
                        <button :disabled="newTagTitle == ''" class="button is-success" @click="saveNewTag()">
                            &nbsp;<font-awesome-icon icon="check" />Save Tag
                        </button>
                        <button
                            :disabled="newTagTitle == ''"
                            class="button is-danger"
                            @click="
                                showAddTag = false;
                                newTagTitle = '';
                                newTagCategory = 'xSu8bBuH6RbVsPdE7SWs';
                            "
                        >
                            &nbsp;<font-awesome-icon icon="trash-alt" />Clear
                        </button>
                    </div>
                </simple-modal>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import draggable from 'vuedraggable';
const SimpleModal = () => import('@/reusables/SimpleModal.vue');

export default {
    name: 'LandingBuildContent',
    components: {
        draggable,
        SimpleModal
    },
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            tagCategories: [],
            allTags: [],
            showAddCat: false,
            showAddTag: false,
            newCatTitle: '',
            newTagTitle: '',
            newTagCategory: 'xSu8bBuH6RbVsPdE7SWs',
            catOrderChanged: false,
            tagOrderChanged: false,
            currentCat: '',
            categoryMapTags: {},
            categoryMapTagIDs: {},
            tagsLoaded: false,
            tab: '1',
            showAddTagModal: false
        };
    },

    computed: {
        userData() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.userData
                ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2'
                    ? true
                    : false
                : false;
        },
        listOfIndexes() {
            return this.tagCategories.map(item => item.index);
        },
        maxIndex() {
            return this.listOfIndexes.length == 0 ? 0 : Math.max(...this.listOfIndexes);
        },
        listOfTagIndexes() {
            return this.allTags.map(item => item.index);
        },
        maxTagIndex() {
            return this.listOfTagIndexes.length == 0 ? 0 : Math.max(...this.listOfIndexes);
        }
    },

    methods: {
        onUserLogin(user) {
            this.loadAllTags();
            setTimeout(() => {
                this.loadTagCategories();
            }, 600);
        },

        close() {
            this.showAddTagModal = false;
            this.showAddTag = false;
            this.newTagTitle = '';
            this.newTagCategory = 'xSu8bBuH6RbVsPdE7SWs';
        },

        sorted: function(arr) {
            // Set slice() to avoid to generate an infinite loop!
            return arr.slice().sort(function(a, b) {
                return a.index - b.index;
            });
        },

        filteredTags(cat) {
            return this.allTags.filter(tag => (tag.subCat = cat));
        },

        slugify(text) {
            return text
                .toLowerCase()
                .trim()
                .replace(/[^\w\s-]/g, '')
                .replace(/[\s_-]+/g, '-')
                .replace(/^-+|-+$/g, '');
        },

        loadAllTags() {
            let self = this;
            // console.log(self.categoryMapTags);
            db.collection('tagLibrary')
                .where('assetCategory', '==', 'vessel')
                .orderBy('index', 'asc')
                .onSnapshot(querySnapshot => {
                    self.allTags = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        // console.log(doc.data());
                        data.id = doc.id;
                        data.editing = false;
                        data.changed = false;
                        let parentCatID = doc.data().parentCatID ? doc.data().parentCatID : 'xSu8bBuH6RbVsPdE7SWs'; // this cat ID is for "Other" cat
                        data.parentCatID = parentCatID;
                        // console.log('parentCatID', parentCatID);
                        self.allTags.push(data);
                        // if (!self.categoryMapTagIDs[parentCatID].includes(doc.id)) {
                        //     self.categoryMapTagIDs[parentCatID].push(doc.id);
                        //     self.categoryMapTags[parentCatID].push(data);
                        // }
                    });
                });
        },

        loadTagCategories() {
            NProgress.start();
            let self = this;
            db.collection('tagCategories')
                .where('assetCategory', '==', 'vessel')
                .orderBy('index', 'asc')
                .onSnapshot(querySnapshot => {
                    self.tagCategories = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        data.editing = false;
                        data.changed = false;
                        data.tags = self.allTags.filter(n => n.parentCatID == doc.id);
                        self.tagCategories.push(data);
                        // self.categoryMapTags[doc.id] = [];
                        // self.categoryMapTagIDs[doc.id] = [];
                    });
                });
            setTimeout(() => {
                self.tagsLoaded = true;
            }, 500);
            NProgress.done();
        },

        onReorder() {
            this.catOrderChanged = true;
        },

        updateCategoryOrder() {
            let self = this;
            NProgress.start();
            this.tagCategories.forEach((item, index) => {
                db.collection('tagCategories')
                    .doc(item.id)
                    .update({
                        index: index + 1
                    })
                    .then(docRef => {
                        self.catOrderChanged = false;
                        NProgress.done();
                    })
                    .catch(error => {
                        console.error('Error updating cat indexes: ', error);
                        NProgress.done();
                    });
            });
        },

        onReorderTags(cat) {
            console.log(cat);
            cat.changed = true;
        },

        deleteTag(tag) {
            let self = this;
            let message;
            message = {
                title: 'Really delete this tag?',
                body: 'Click YES below to expire.'
            };
            let options = {
                okText: 'YES, DELETE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    db.collection('tagLibrary')
                        .doc(tag.id)
                        .delete()
                        .then(() => {
                            self.loadTagCategories();
                            self.close();
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    self.close();
                    NProgress.done();
                });
        },

        updateTagOrder(cat) {
            let self = this;
            NProgress.start();
            cat.tags.forEach((item, index) => {
                db.collection('tagLibrary')
                    .doc(item.id)
                    .update({
                        index: index + 1
                    })
                    .then(docRef => {
                        cat.changed = false;
                        NProgress.done();
                    })
                    .catch(error => {
                        console.error('Error updating tag indexes: ', error);
                        NProgress.done();
                    });
            });
        },

        saveNewCat() {
            let self = this;
            // const slugify = str =>
            //     str
            //         .toLowerCase()
            //         .trim()
            //         .replace(/[^\w\s-]/g, '')
            //         .replace(/[\s_-]+/g, '-')
            //         .replace(/^-+|-+$/g, '');
            NProgress.start();
            db.collection('tagCategories')
                .add({
                    assetCategory: 'vessel',
                    title: self.newCatTitle,
                    slug: self.slugify(self.newCatTitle),
                    index: self.maxIndex + 1
                })
                .then(() => {
                    setTimeout(() => {
                        self.newCatTitle = '';
                        self.showAddCat = false;
                        NProgress.done();
                    }, 500);
                });
        },

        updateCat(cat) {
            let self = this;
            NProgress.start();
            db.collection('tagCategories')
                .doc(cat.id)
                .update({
                    title: cat.title
                })
                .then(() => {
                    setTimeout(() => {
                        cat.editing = false;
                        cat.changed = false;
                        NProgress.done();
                    }, 500);
                });
        },

        saveNewTag() {
            let self = this;
            let cats = this.tagCategories.filter(cat => cat.id == self.newTagCategory);
            let tagData = {
                assetCategory: 'vessel',
                title: self.newTagTitle,
                parentCatID: self.newTagCategory,
                slug: self.slugify(self.newTagTitle),
                index: self.maxTagIndex + 1
            };
            // cats.forEach(cat => {
            //     cat.tags.push(tagData);
            // });
            NProgress.start();
            db.collection('tagLibrary')
                .add(tagData)
                .then(() => {
                    self.loadTagCategories();
                    setTimeout(() => {
                        self.close();
                        NProgress.done();
                    }, 500);
                });
        },

        updateTag(tag) {
            let self = this;
            NProgress.start();
            db.collection('tagLibrary')
                .doc(tag.id)
                .update({
                    title: tag.title
                })
                .then(() => {
                    setTimeout(() => {
                        tag.editing = false;
                        tag.changed = false;
                        NProgress.done();
                    }, 500);
                });
        },

        goBack() {
            this.$router.go(-1);
        }
    }
};
</script>

<style lang="scss" scoped>
.site-wrapper .container {
    max-width: 850px;
}
.tag-cat {
    margin-bottom: 15px;
    cursor: pointer;
    .cat-title {
        transition: 0.3s;
    }
    &:hover {
        .cat-title {
            color: mediumseagreen;
        }
    }
    span {
        margin-right: 5px;
    }
}
.heading {
    font-size: 14px;
    color: cadetblue;
    font-weight: bold;
}
</style>
