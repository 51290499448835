<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <div class="two-thirds">
                    <div class="spacer s20"></div>
                    <h4 class="heading bold green">Helper Content and Categories</h4>
                    <div class="spacer s10"></div>
                    <div class="list-group-item box" v-for="(list, iax) in helperCats" :key="`helperCats-1-${iax}`">
                        <div class="heading bold"><font-awesome-icon icon="list-ul" /> &nbsp;{{ list.title }}</div>
                        <div class="spacer s10"></div>
                        <draggable class="list-group" :list="helperCats[iax].helpers" group="helpers" @change="updateHelperCatOrder()">
                            <div
                                class="list-group-item notification"
                                v-for="(element, index) in helperCats[iax].helpers"
                                :key="element.name"
                                @click="showHelpers(element)"
                            >
                                <div>{{ index + 1 }}. {{ element.heading }}</div>
                            </div>
                        </draggable>
                    </div>
                    <div class="spacer s100"></div>
                    <div class="spacer s100"></div>
                </div>
                <div class="one-third">
                    <div class="spacer s20"></div>
                    <h4 class="heading bold orange">Categories</h4>
                    <div class="spacer s10"></div>
                    <draggable v-model="helperCats" group="cats" @start="drag = true" @end="updateCats()">
                        <div v-for="(item, index) in helperCats" :key="`cat-${index}`" class="notification cats" @click="editCat(item)">
                            <div class="">{{ item.title }}</div>
                            <div class="slug">{{ item.slug }}</div>
                        </div>
                    </draggable>
                    <div class="buttons">
                        <span class="button is-info" @click="showAddCat = true">Add a Category</span>
                    </div>
                </div>
                <div class="spacer s100"></div>
            </div>
            <div class="spacer s100"></div>
        </div>
        <div class="spacer s100"></div>

        <div class="buttons floating">
            <span class="button is-success" @click="showAddHelper = true"><font-awesome-icon icon="plus" /> &nbsp;Add a Helper</span>
        </div>

        <simple-modal title="Add a Category" theme="is-info" submitText="Cancel" v-on:closed="close()" v-if="showAddCat">
            <div class="field">
                <label class="label">Category Title</label>
                <div class="control">
                    <input class="input padded" type="text" v-model="newCat.title" />
                </div>
            </div>
            <div class="field">
                <label class="label">Category Slug</label>
                <div class="control">
                    <input class="input padded" type="text" v-model="newCat.slug" />
                </div>
            </div>
            <div class="field">
                <label class="label">Category Description</label>
                <div class="control">
                    <textarea class="textarea padded" type="textarea" v-model="newCat.description"></textarea>
                </div>
            </div>
            <div class="buttons">
                <button :disabled="newCat.title == '' || newCat.slug == ''" class="button is-primary" @click="saveNewCat()">Save New Category</button>
            </div>
        </simple-modal>

        <simple-modal title="Add a New Helper Page" theme="is-info" submitText="Cancel" v-on:closed="close()" v-if="showAddHelper">
            <div class="field">
                <label class="label">Heading</label>
                <div class="control">
                    <input class="input padded" placeholder="Name of this helper section.." type="text" v-model="newHelper.heading" />
                </div>
            </div>
            <draggable v-model="newHelper.helpers" group="cats" @start="drag = true">
                <div v-for="(item, idx) in newHelper.helpers" :key="`h-${idx}`" class="notification helpers">
                    <div v-if="!item.edit" class="" @click="item.show = !item.show">
                        <span><font-awesome-icon :icon="icons[item.icon].icon" /> &nbsp;</span>{{ item.content }}
                    </div>
                    <div v-else>
                        <textarea
                            class="textarea"
                            v-model="item.content"
                            placeholder="Type a line of helper content.."
                            maxlength="1000"
                            cols="30"
                            rows="6"
                        ></textarea>
                        <div class="icon-list">
                            <div
                                class="icon-item"
                                v-for="(icon, icx) in icons"
                                :class="[{ selected: item.icon == icon.slug }, { red: icon.slug == 'none' }]"
                                @click="item.icon = icon.slug"
                            >
                                <span> <font-awesome-icon :icon="icons[icx].icon"/></span>
                            </div>
                        </div>
                    </div>
                    <div v-if="item.show" class="spacer s10"></div>
                    <div v-if="item.show" class="buttons">
                        <span v-if="!item.deleting && !item.edit" class="button is-info is-small" @click="item.edit = true">Edit</span>
                        <span v-if="!item.deleting && item.edit" class="button is-info is-small" @click="item.edit = false">Done</span>
                        <!-- <span v-if="!item.deleting && item.edit" class="button is-success is-small" @click="saveEdits(item)">Save</span> -->
                        <span v-if="!item.deleting && !item.edit" class="button is-light is-small" @click="item.deleting = true">Delete</span>
                        <span v-if="item.deleting && !item.edit" class="button is-light is-small" @click="item.deleting = false">Cancel</span>
                        <span v-if="item.deleting && !item.edit" class="button is-danger is-small" @click="deleteSubHelper(item, idx)">
                            Confirm Delete
                        </span>
                    </div>
                </div>
            </draggable>
            <div class="buttons">
                <span class="button is-small is-success" @click="addNewSubHelper()">Add Helper Content</span>
            </div>
            <div class="field">
                <label class="label">Video Link ID</label>
                <div class="control">
                    <input class="input padded" type="text" placeholder="Leave blank if no video!" v-model="newHelper.videoID" />
                </div>
            </div>
            <div class="select">
                <select v-model="newHelper.cat">
                    <option value="" disabled>Select a Category</option>
                    <option v-for="(cat, inx) in simpleHelperCats" :value="cat" :key="`cat-2-${inx}`">{{ cat.title }}</option>
                </select>
            </div>

            <hr />
            <div class="buttons">
                <button
                    :disabled="newHelper.heading == '' || newHelper.helpers.length == 0 || newHelper.cat == ''"
                    class="button is-primary"
                    @click="saveNewHelper()"
                >
                    Save New Helper
                </button>
            </div>
            <div class="spacer s10"></div>
        </simple-modal>

        <simple-modal :title="selectedCloudHelper.heading" theme="is-primary" submitText="Close" v-on:closed="close()" v-if="showHelperDetails">
            <!-- <pre>{{ selectedCloudHelper }}</pre> -->
            <draggable v-model="selectedCloudHelper.helpers" group="cats" @start="drag = true" @end="updateHelpers()">
                <div class="buttons">
                    <span class="button is-light" @click="copyLink(selectedCloudHelper.id)">
                        <font-awesome-icon icon="copy" /> &nbsp; {{ selectedCloudHelper.id }}
                    </span>
                </div>
                <div v-for="(item, idx) in selectedCloudHelper.helpers" :key="`h-${idx}`" class="notification helpers">
                    <div v-if="!item.edit" class="" @click="item.show = !item.show">
                        <span><font-awesome-icon :icon="icons[item.icon].icon" /> &nbsp;</span>{{ item.content }}
                    </div>
                    <div v-else>
                        <textarea
                            class="textarea"
                            v-model="item.content"
                            placeholder="Type a line of helper content.."
                            maxlength="1000"
                            cols="30"
                            rows="6"
                        ></textarea>
                        <div class="icon-list">
                            <div
                                class="icon-item"
                                v-for="(icon, icx) in icons"
                                :class="[{ selected: item.icon == icon.slug }, { red: icon.slug == 'none' }]"
                                @click="item.icon = icon.slug"
                            >
                                <span> <font-awesome-icon :icon="icons[icx].icon"/></span>
                            </div>
                        </div>
                    </div>
                    <div v-if="item.show" class="spacer s10"></div>
                    <div v-if="item.show" class="buttons">
                        <span v-if="!item.deleting && !item.edit" class="button is-info is-small" @click="item.edit = true">Edit</span>
                        <span v-if="!item.deleting && item.edit" class="button is-info is-small" @click="item.edit = false">Cancel</span>
                        <span v-if="!item.deleting && item.edit" class="button is-success is-small" @click="saveEdits(item)">Save</span>
                        <span v-if="!item.deleting && !item.edit" class="button is-light is-small" @click="item.deleting = true">Delete</span>
                        <span v-if="item.deleting && !item.edit" class="button is-light is-small" @click="item.deleting = false">Cancel</span>
                        <span v-if="item.deleting && !item.edit" class="button is-danger is-small" @click="deleteHelperItem(item, idx)"
                            >Confirm Delete</span
                        >
                    </div>
                </div>
            </draggable>
            <div class="buttons">
                <span class="button is-small is-info" @click="addNewSubHelperExisting()">Add Another Content Section</span>
                <span
                    v-if="addingHelper"
                    class="button is-small is-success"
                    @click="
                        updateHelpers();
                        close();
                    "
                >
                    Save Changes
                </span>
            </div>
            <hr />
            <div v-if="showEditTitle">
                <input class="input padded" type="text" placeholder="Type helper title" v-model="tempHeading" maxlength="120" />
                <div class="spacer s10"></div>
            </div>
            <div v-if="showEditTitle" class="buttons">
                <button :disabled="tempHeading == ''" v-if="showEditTitle" class="button is-primary" @click="updateHelperTitle()">
                    <font-awesome-icon icon="pen" /> Update Title
                </button>
                <span class="button is-light" @click="showEditTitle = false"> <font-awesome-icon icon="times" /> Cancel </span>
            </div>
            <div class="buttons" v-else>
                <span
                    class="button is-primary"
                    @click="
                        showEditTitle = true;
                        tempHeading = selectedCloudHelper.heading;
                    "
                >
                    <font-awesome-icon icon="pen" /> Edit Title
                </span>
                <span class="button is-danger" @click="deleteMainHelper()">
                    <font-awesome-icon icon="exclamation-triangle" /> Delete this entire helper
                </span>
            </div>
            <div class="spacer s10"></div>
        </simple-modal>

        <simple-modal
            :title="'Edit Category (' + selectedCat.slug + ')'"
            theme="is-primary"
            submitText="Close"
            v-on:closed="close()"
            v-if="showEditCat"
        >
            <div class="field">
                <label class="label">Category Title</label>
                <input class="input padded" type="text" placeholder="Type category name" v-model="selectedCat.title" maxlength="120" />
            </div>
            <div class="field">
                <label class="label">Category Description</label>
                <div class="control">
                    <textarea class="textarea padded" type="textarea" v-model="selectedCat.description"></textarea>
                </div>
            </div>
            <div class="spacer s20"></div>
            <div class="buttons">
                <button :disabled="selectedCat.title == ''" class="button is-info" @click="updateCatTitle()">
                    <font-awesome-icon icon="check" />Update Category Details
                </button>
            </div>
        </simple-modal>
        <div class="copy-prompt" :class="{ visible: showCopyPrompt }">
            Link copied to clipboard!
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import draggable from 'vuedraggable';
const SimpleModal = () => import('@/reusables/SimpleModal.vue');

export default {
    name: 'LandingBuildContent',
    components: {
        draggable,
        SimpleModal
    },
    mounted() {
        this.loadCloudHelpers();
    },

    computed: {
        catSlugs() {
            return this.helperCats.map(cat => cat.slug);
        },
        simpleHelperCats() {
            return this.helperCats.map(cat => {
                return {
                    title: cat.title,
                    slug: cat.slug
                };
            });
        }
    },

    data() {
        return {
            appCloudHelpers: [],
            helperCats: [],
            showAddCat: false,
            selectedCloudHelper: {},
            newCat: {
                title: '',
                slug: '',
                description: ''
            },
            showHelperDetails: false,
            icons: {
                none: { slug: 'none', icon: 'times' },
                info: { slug: 'info', icon: 'info-circle' },
                warning: { slug: 'warning', icon: 'exclamation-triangle' },
                image: { slug: 'image', icon: 'camera' },
                video: { slug: 'video', icon: 'video' }
            },
            showAddHelper: false,
            newHelper: {
                heading: '',
                helpers: [],
                cat: '',
                catName: '',
                catSlug: '',
                videoID: ''
            },
            addingHelper: false,
            showEditCat: false,
            selectedCat: {},
            tempHeading: '',
            showEditTitle: false,
            showCopyPrompt: false
        };
    },

    // watch: {
    //     helperCats() {
    //         this.loadCloudHelperContent();
    //     }
    // },

    methods: {
        goBack() {
            this.$router.go(-1);
        },
        close() {
            this.showAddCat = false;
            this.newCat = {
                title: '',
                slug: '',
                description: ''
            };
            this.showHelperDetails = false;
            this.showAddHelper = false;
            this.newHelper = {
                heading: '',
                helpers: [],
                cat: '',
                catName: '',
                catSlug: '',
                videoID: ''
            };
            this.selectedCloudHelper = {};
            this.addingHelper = false;
            this.showEditCat = false;
            this.selectedCat = {};
            this.tempHeading = '';
            this.showEditTitle = false;
        },
        async loadCloudHelpers() {
            await db
                .collection('appInfo')
                .doc('cloudHelpers')
                .get()
                .then(async doc => {
                    let cats = doc.data().categories;
                    this.helperCats = [];
                    await Promise.all(
                        cats.map(async cat => {
                            this.helperCats.push({
                                title: cat.title,
                                slug: cat.slug,
                                description: cat.description ? cat.description : '',
                                helpers: []
                            });
                        })
                    );
                })
                .then(() => {
                    this.loadCloudHelperContent();
                });
        },
        async clearHelpers() {
            await Promise.all(
                this.helperCats.map(async cat => {
                    cat.helpers = [];
                })
            );
        },

        copyLink(text) {
            let self = this;
            this.$copyText(text).then(
                function(e) {
                    console.log('Text copied:', text);
                    self.showCopied();
                },
                function(e) {
                    alert('Link could not be copied to clipboard.');
                    console.log(e);
                }
            );
        },

        showCopied() {
            this.showCopyPrompt = true;
            setTimeout(() => {
                this.showCopyPrompt = false;
            }, 1600);
        },

        loadCloudHelperContent() {
            console.log('loading helper content');
            let self = this;
            db.collection('appInfo')
                .doc('cloudHelpers')
                .collection('appCloudHelpers')
                .orderBy('index', 'asc')
                .onSnapshot(async querySnapshot => {
                    await this.clearHelpers();
                    querySnapshot.forEach(doc => {
                        if (this.catSlugs.includes(doc.data().catSlug)) {
                            let data = doc.data();
                            data.id = doc.id;
                            data.helpers = doc.data().helpers.map(m => {
                                return {
                                    content: typeof m == 'string' ? m : m.content,
                                    icon: typeof m == 'string' ? 'info' : m.icon,
                                    show: false,
                                    edit: false,
                                    deleting: false
                                };
                            });
                            self.helperCats.filter(cat => cat.slug == doc.data().catSlug)[0].helpers.push(data);
                        }
                    });
                });
        },
        saveNewCat() {
            NProgress.start();
            db.collection('appInfo')
                .doc('cloudHelpers')
                .update({
                    categories: firebase.firestore.FieldValue.arrayUnion({
                        title: this.newCat.title,
                        slug: this.newCat.slug,
                        description: this.newCat.description ? this.newCat.description : ''
                    })
                })
                .then(() => {
                    this.showAddCat = false;
                    this.loadCloudHelperContent();
                    NProgress.done();
                });
        },
        editCat(cat) {
            console.log(cat);
            this.selectedCat = {
                title: cat.title,
                slug: cat.slug,
                description: cat.description
            };
            this.showEditCat = true;
        },
        async updateHelperTitle() {
            console.log(this.tempHeading);
            console.log(this.selectedCloudHelper);
            NProgress.start();
            db.collection('appInfo')
                .doc('cloudHelpers')
                .collection('appCloudHelpers')
                .doc(this.selectedCloudHelper.id)
                .update({
                    heading: this.tempHeading
                })
                .then(() => {
                    NProgress.done();
                    this.close();
                });
        },
        deleteMainHelper() {
            // console.log(this.selectedCloudHelper);
            this.showHelperDetails = false;
            let self = this;
            let message;
            message = {
                title: 'Delete this entire helper?',
                body: 'Click DELETE below to delete the helper called ' + this.selectedCloudHelper.heading + '.'
            };
            let options = {
                okText: 'YES, Delete',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    db.collection('appInfo')
                        .doc('cloudHelpers')
                        .collection('appCloudHelpers')
                        .doc(self.selectedCloudHelper.id)
                        .delete()
                        .then(() => {
                            NProgress.done();
                        });
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },
        async updateCatTitle() {
            NProgress.start();
            // console.log(this.selectedCat);
            await db
                .collection('appInfo')
                .doc('cloudHelpers')
                .update({
                    categories: this.helperCats.map(cat => {
                        if (cat.slug == this.selectedCat.slug) {
                            return {
                                title: this.selectedCat.title,
                                slug: this.selectedCat.slug,
                                description: this.selectedCat.description
                            };
                        } else {
                            return {
                                title: cat.title,
                                slug: cat.slug,
                                description: cat.description
                            };
                        }
                    })
                });
            this.loadCloudHelpers();
            NProgress.done();
            this.showEditCat = false;
        },
        updateCats() {
            NProgress.start();
            db.collection('appInfo')
                .doc('cloudHelpers')
                .update({
                    categories: this.helperCats.map(cat => {
                        return {
                            title: cat.title,
                            slug: cat.slug,
                            description: cat.description
                        };
                    })
                })
                .then(() => {
                    NProgress.done();
                });
        },
        updateHelperCatOrder() {
            NProgress.start();
            this.helperCats.forEach(cat => {
                cat.helpers.forEach((helper, index) => {
                    db.collection('appInfo')
                        .doc('cloudHelpers')
                        .collection('appCloudHelpers')
                        .doc(helper.id)
                        .update({
                            index: index,
                            catSlug: cat.slug
                        });
                });
            });
            NProgress.done();
        },
        saveEdits(item) {
            item.edit = false;
            this.updateHelpers();
        },
        deleteHelperItem(item, index) {
            console.log(this.selectedCloudHelper, index);
            NProgress.start();
            this.selectedCloudHelper.helpers.splice(index, 1);
            this.updateHelpers();
        },
        updateHelpers() {
            // selectedCloudHelper
            NProgress.start();
            let cleanHelpers = this.selectedCloudHelper.helpers.map(h => {
                return {
                    content: h.content,
                    icon: h.icon
                };
            });
            db.collection('appInfo')
                .doc('cloudHelpers')
                .collection('appCloudHelpers')
                .doc(this.selectedCloudHelper.id)
                .update({
                    helpers: cleanHelpers
                })
                .then(() => {
                    NProgress.done();
                });
        },
        showHelpers(item) {
            this.selectedCloudHelper = item;
            this.showHelperDetails = true;
        },
        addNewSubHelper() {
            this.newHelper.helpers.push({
                content: 'Click to edit content...',
                icon: 'info',
                show: false,
                edit: false,
                deleting: false
            });
        },
        addNewSubHelperExisting() {
            this.addingHelper = true;
            console.log(this.selectedCloudHelper);
            this.selectedCloudHelper.helpers.push({
                content: 'Click to edit content...',
                icon: 'info',
                show: false,
                edit: false,
                deleting: false
            });
        },
        deleteSubHelper(item, idx) {
            console.log(item, idx);
            this.newHelper.helpers.splice(idx, 1);
        },
        saveNewHelper() {
            console.log(this.newHelper);
            NProgress.start();
            db.collection('appInfo')
                .doc('cloudHelpers')
                .collection('appCloudHelpers')
                .add({
                    catSlug: this.newHelper.cat.slug,
                    catName: this.newHelper.cat.title,
                    heading: this.newHelper.heading,
                    helpers: this.newHelper.helpers.map(h => {
                        return {
                            content: h.content,
                            icon: h.icon
                        };
                    }),
                    videoID: this.newHelper.videoID,
                    index: 99
                })
                .then(() => {
                    this.showAddHelper = false;
                    this.close();
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.site-wrapper .container {
    max-width: 1200px;
}
.notification {
    margin-bottom: 8px;
    cursor: pointer;
    &.cats {
        text-align: left;
        .slug {
            color: dodgerblue;
            font-size: 0.9rem;
            font-style: italic;
        }
    }
}
.icon-list {
    display: flex;
    margin: 10px 0;
    .icon-item {
        span {
            padding: 8px;
            border-radius: 2px;
            border: 1px solid #bbb;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45px;
            height: 40px;
            margin: 0 5px 5px 0;
            color: #666;
            border-color: #666;
        }
        &.selected {
            span {
                border-color: dodgerblue;
                color: dodgerblue;
            }
            &.red {
                span {
                    border-color: red;
                    color: red;
                }
            }
        }
    }
}
</style>
