<template>
	
	<div>

			<div class="site-wrapper no-header">
			
				<div class="container">

						<h3 class="is-size-5">Admin All Vessels</h3><br>

            <p>Billable: {{billableVesselCount}} ({{billableVesselCount * 249}})</p>
            <p>&nbsp;</p>

						<!-- <span class="button" @click="updateVesselOwners">Get Owners</span> -->

						<table class="table is-bordered is-fullwidth">
							<tr>
                <th>No.</th>
                <th>Vessel (and owner)</th>
                <th>Company</th>
                <!-- <th>Billable?</th> -->
              </tr>
							<tr v-for="(vessel, index) in allVessels">
                <td>{{index+1}}</td>
								<td>
                  <span class="button is-light" :class="vessel.expiryStatus" @click="editVessel(vessel)">{{vessel.vesselName}}</span>
                  <!-- <div><span v-if="vessel.isDue">(Due {{vessel.remaining}})</span></div> -->
                </td>
								<td>{{vessel.vesselOwnerName}}
                  <div class="vessel-type">({{vessel.vesselType}} vessel<span v-if="vessel.isDue"> - Due {{vessel.remaining}}</span>)</div>
                </td>
                <!-- <td>
                  <input class="is-checkradio" type="checkbox" :id="'index_' + index" v-model="vessel.isBillable">
                  <label :for="'index_' + index"> </label>
                </td> -->
							</tr>
						</table>


				</div>

			</div>


			<div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showVessel}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Editing {{selectedVessel.vesselName}}  &nbsp;<span class="demo" v-if="selectedVessel.isDemo"><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Demo vessel</span></p>
              <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>
            <section class="modal-card-body">       
            	
            	<div>

                <div v-if="selectedVessel.isPast">
                  <div class="expired"><font-awesome-icon icon="exclamation-triangle" /> Vessel subscription expired {{selectedVessel.remaining}}</div>
                </div>

                <div v-if="selectedVessel.isDue && !selectedVessel.isPast" class="due"><font-awesome-icon icon="exclamation-triangle" /> Vessel {{selectedVessel.isDemo ? 'trial period' : 'subscription'}} expires {{selectedVessel.remaining}}</div>

							  <table class="table is-fullwidth">
                  <tr>
                    <th>Company</th>
                    <td>{{selectedVessel.vesselOwnerName}}</td>
                  </tr>
                  <tr>
                    <th>Paid For?</th>
                    <td>{{selectedVessel.isPaidFor ? 'Yes' : 'No'}}</td>
                  </tr>
                  <tr>
                    <th>Billable?</th>
                    <td>{{selectedVessel.isBillable ? 'Yes' : 'No'}}</td>
                  </tr>
                  <tr>
                    <th>Demo mode?</th>
                    <td>{{selectedVessel.isDemo ? 'Yes' : 'No'}}</td>
                  </tr>
                  <tr>
                    <th>Last Charge</th>
                    <td>{{selectedVessel.lastVesselCharge}}</td>
                  </tr>
                  <tr>
                    <th>Last charge date</th>
                    <td>{{formatDate(selectedVessel.lastChargeDate,'DD MMM YYYY (H:mm a)')}}</td>
                  </tr>
                  <tr>
                    <th>Next Charge</th>
                    <td>{{selectedVessel.nextVesselCharge}}</td>
                  </tr>
                  <tr>
                    <th>Next charge date</th>
                    <td>{{formatDate(selectedVessel.nextChargeDate,'DD MMM YYYY (H:mm a)')}}</td>
                  </tr>

							  </table>
            	</div>

              <div class="spacer s30">
                
              </div>

              <!-- {{selectedVessel}} -->

            </section>
            <footer class="modal-card-foot">	  
              <button v-if="isSteve" class="button is-warning" @click="updateVesselData(selectedVessel)">Update Vessel</button>
              <button class="button is-primary" @click="close">Done</button>
            </footer>
        </div>
      </div>




	</div>

</template>


<script> 

	import firebase from 'firebase/compat/app';
	import { db } from '../main.js';
	import 'firebase/compat/auth'; 
	import 'firebase/firestore';
	import 'firebase/compat/functions';
	import NProgress from 'nprogress';	
	import * as moment from 'moment';

	// const UpdateAudit = () => import('./UpdateAudit.vue')

	export default {

		name: 'FleetVessels',
		components: {
	  	// UpdateAudit
	  },
		mounted: function() {
			firebase.auth().onAuthStateChanged( this.onUserLogin );	
		},

		data: function() {
			return {
				allVessels: [],
				selectedVessel: {},
        showVessel: false,
			}
		},

		computed: {
			user() {
	    	return this.$store.getters.getUser;
	    },
      isSteve() {
        return this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false;
      },
      billableVesselCount() {
        return this.allVessels.filter((vessel) => vessel.isBillable).length;
      }
		},

		methods: {

			onUserLogin: function( user ) {
      	if (user) {      		
	    		this.loadAllVessels();
	      }
      },

      close: function() {
        this.showVessel = false;
      	this.selectedVessel = {};
      },

      loadAllVessels: function() {
      	let self = this;
        let cutoffDate = moment().add(1, 'months');
      	db.collection('vessels').orderBy("vesselName", "asc").where('isCompanyVessel','==',true).onSnapshot(function(querySnapshot) {
      		self.allVessels = [];
      		querySnapshot.forEach((doc) => {
      			//let auditD = (doc.data().lastAuditDate ? doc.data().lastAuditDate.toDate() : 'vvv');
      			//console.log(auditD);
      			self.allVessels.push({
      				vesselID: doc.id,
      				vesselName: doc.data().vesselName,
      				// lastAuditID: (doc.data().lastAuditID ? doc.data().lastAuditID : 'NA'),
      				//lastAuditDate: (doc.data().lastAuditDate ? doc.data().lastAuditDate.toDate() : 'Never'),
      				// lastAuditDate: (doc.data().lastAuditDate ? self.formatDate(doc.data().lastAuditDate,'dddd D MMMM (h:mm a)') : 'Never'),
      				// sinceDate: (doc.data().lastAuditDate ? moment(doc.data().lastAuditDate.toDate()).fromNow() : 'no audit'),
      				// lastAuditPercent: (doc.data().lastAuditPercent ? doc.data().lastAuditPercent : 0),
      				// lastAuditCode: (doc.data().lastAuditCode ? doc.data().lastAuditCode : 0),
      				// hasBeenCounted: false,
      				vesselOwnerName: (doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?'),
      				vesselOwnerID: doc.data().vesselOwnerID,
              isBillable: doc.data().isBillable ? doc.data().isBillable : false,
              isPaidFor: doc.data().isPaidFor ? doc.data().isPaidFor : false,
              lastVesselCharge: doc.data().lastVesselCharge ? doc.data().lastVesselCharge : 0,
              nextVesselCharge: doc.data().nextVesselCharge ? doc.data().nextVesselCharge : 0,
              lastChargeDate: doc.data().lastChargeDate ? doc.data().lastChargeDate.toDate() : '',
              nextChargeDate: doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate() : '',
              remaining: doc.data().nextChargeDate ? moment().to(doc.data().nextChargeDate.toDate()) : '',
              isPast: doc.data().nextChargeDate ? moment().isAfter(moment(doc.data().nextChargeDate.toDate())) : false,
              isDue: doc.data().nextChargeDate ? moment(cutoffDate).isAfter(moment(doc.data().nextChargeDate.toDate())) : false,
              isDemo: doc.data().isDemo ? doc.data().isDemo : false,
              vesselType: !doc.data().isBillable ? 'free' : doc.data().isDemo ? 'demo' : 'billable',
              expiryStatus: doc.data().nextChargeDate ? moment().isAfter(moment(doc.data().nextChargeDate.toDate())) ? 'expired' : moment(cutoffDate).isAfter(moment(doc.data().nextChargeDate.toDate())) ? 'due' : 'current' : 'nil',
      			});
      		});
      	});
      },

      // formatDate : function (sentDate,format) {
      //   let fixDate = (sentDate ? sentDate.toDate() : new Date());
      //   return moment(fixDate).format(format);
      // },

      formatDate: function(sentDate, format) {
        // let fixDate = sentDate ? sentDate.toDate() : new Date();
        return moment(sentDate).format(format);
      },

      editVessel(vessel) {
        this.selectedVessel = vessel;
        this.showVessel = true;
      },

      updateVesselData(selectedVessel) {
        console.log(selectedVessel);
        this.$router.push({
          name: 'UpdateVessel',
          params: {
            sentVessel: selectedVessel
          }
        });
      }

    //   viewAudit: function(vesselID,auditID,vesselName,auditDate,sinceDate) {
	   //    let self = this;
	   //    this.showAudit = true;
	   //    this.selectedVesselID = vesselID;
				// this.selectedVesselName = vesselName;
				// this.selectedAuditID = auditID;
				// this.selectedSinceDate = sinceDate;
				// this.selectedAuditDate = auditDate;

				// db.collection('vessels').doc(vesselID).collection('smsAudits').doc(auditID).get().then(function(doc) {
				// 	self.selectedAuditResult.hasDesignatedStatement = doc.data().hasDesignatedStatement;
			 //    self.selectedAuditResult.hasMasterStatement = doc.data().hasMasterStatement;
			 //    self.selectedAuditResult.countHazards = doc.data().hazards;
			 //    self.selectedAuditResult.countContacts = doc.data().contacts;
			 //    self.selectedAuditResult.countPolicies = doc.data().policies;
			 //    self.selectedAuditResult.countSOPS = doc.data().sops;
			 //    self.selectedAuditResult.countEOPS = doc.data().eops;
			 //    self.selectedAuditResult.countStartupItems = doc.data().startupItems;
			 //    self.selectedAuditResult.countStartupLogs = doc.data().startupLogs;
			 //    self.selectedAuditResult.countMaintenanceItems = doc.data().maintenanceItems;
			 //    self.selectedAuditResult.countMaintenanceLogs = doc.data().maintenanceLogs;
			 //    self.selectedAuditResult.auditScore = doc.data().auditScore;
			 //    self.selectedAuditResult.auditCode = doc.data().auditCode;
			 //    self.selectedAuditResult.auditPercent = doc.data().auditPercent;
				// }).then(function() {
				// 	self.selectedAuditLoaded = true;
				// });
					 

    //   },

      // viewSMS: function(vesselID,vesselName,vesselOwnerID) {
      //   console.log(vesselID,vesselName,vesselOwnerID);
      //   this.$router.push({
      //     name: 'ViewSms',
      //     params: {
      //       vesselID: vesselID,
      //       vesselName: vesselName,
      //       ownerID: vesselOwnerID
      //     }
      //   });
      // },


      // updateCounted: function(result) {
      // 	//console.log('Result: ',result);
      // 	//this.allVessels[result.index].hasBeenCounted = true;
      // },

      // updateVesselInfo: function(vesselName,vesselID, vesselOwnerID) {

      //   console.log(vesselName,vesselID, vesselOwnerID,'updated.');

      //   var updateVesselWithOwnerInfo = firebase.functions().httpsCallable('updateVesselWithOwnerInfo');
      //   updateVesselWithOwnerInfo({
      //     vesselID: vesselID,
      //     vesselOwnerID: vesselOwnerID
      //   }); 

      // },

		},

	}



</script> 




<style lang="scss" scoped>
	
  .site-wrapper .container {
    max-width: 850px;
  }
	.button {
		margin-top: 0;
    &.is-skip {
      background-color: #ff7878;
      color: #fff;
      border-color: #fff;
    }
    &.expired {
      color: white;
      background: red;
    }
    &.due {
      color: white;
      background: orange;
    }
    &.current {
      color: white;
      background: green;
    }
	}
  .expired {
    color: red;
    margin-bottom: 1rem;
  }
  .due {
    color: darkorange;
    margin-bottom: 1rem;
  }
	.traffic-light {
	  position: relative;
	  span {
	  	float: left;
	  	margin-right: 10px;
	  }
	  .light {
	  	border-radius: 3px;
	  	display: inline-block;
	    color: #fff;
	    font-size: 0.8rem;
	    padding: 3px 10px;
	  	&.red {
	  		background: red;
	  		&.active {
	  			background: red;
	  		}
	  	}
	  	&.orange {
	  		background: orange;
	  		&.active {
	  			background: orange;
	  		}
	  	}
	  	&.green {
	  		background: #03d203;
	  		&.active {
	  			background: #03d203;
	  		}
	  	}
	  }
	}
  .vessel-type {
    text-transform: uppercase;
    margin: 4px 10px 4px 0;
    color: steelblue;
  }

</style> 