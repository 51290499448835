<template>
    <div>
        <div class="modal modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': isActiveWindow }">
            <div class="modal-background" @click="cancelItem"></div>
            <div class="modal-card">
                <button class="delete" aria-label="hasSeenNotice" @click="cancelItem"></button>

                <!-- <header class="modal-card-head">
              <p class="modal-card-title">Edit Item</p>
              <button class="delete" aria-label="hasSeenNotice" @click="saveItem"></button>
            </header> -->

                <section class="modal-card-body">
                    <!-- {{item}}<hr>{{myUploads}} -->

                    <div v-if="item.action == 'Read'">
                        <div class="title">{{ item.title }}</div>
                        <div class="details">
                            <div v-for="line in item.details.split('\n')">{{ line }}<br /></div>
                        </div>
                        <div v-show="item.showLink && item.link" class="link">
                            <a :href="item.link" target="_blank" class="simple-link">Link:&nbsp;{{ item.link }}</a>
                        </div>
                        <div class="spacer s30"></div>
                        <div v-if="myUploads.length > 0" class="image-div">
                            <label class="label">UPLOADED IMAGES:</label>
                            <ul class="uploads">
                                <li
                                    :class="{ show: shownImageIndex == index }"
                                    @click="zoomInOut(index)"
                                    class="image-wrap"
                                    v-for="(image, index) in myUploads"
                                    :key="image.id"
                                >
                                    <img :src="image" />
                                </li>
                            </ul>
                            <div class="clear"></div>
                        </div>

                        <div class="clear"></div>
                        <hr />
                        <div class="buttons">
                            <span class="button is-medium is-info" @click="cancelItem">Done</span>
                        </div>
                    </div>

                    <div v-else>
                        <h4>{{ item.action }} {{ item.heading }} Title</h4>
                        <div class="spacer s10"></div>
                        <input class="input" type="text" v-model="title" placeholder="Write title..." />

                        <h4>{{ item.action }} {{ item.heading }} Details</h4>
                        <div class="spacer s10"></div>
                        <textarea ref="detailsbox" class="input" v-model="details"></textarea>
                        <ul class="helper-icons">
                            <li @click="addIcon(' \u2705  ')">&#9989;</li>
                            <li @click="addIcon(' \u274C  ')">&#10060;</li>
                            <li @click="addIcon(' \u2605  ')">&#9733;</li>
                            <!-- <li @click="addIcon(' \u26A0  ')">&#9888;</li> -->
                            <!-- <li @click="addIcon(' \u26D4  ')">&#9940;</li> -->
                            <li @click="addIcon(' \u2757  ')">&#10071;</li>
                            <li @click="addIcon(' \u25C9  ')">&#9673;</li>
                            <li @click="addIcon(' \u270B  ')">&#9995;</li>
                            <li @click="addIcon(' \u{1F4A1}  ')">&#128161;</li>
                        </ul>

                        <div v-show="item.showLink" class="link">
                            <h4>Web Link</h4>
                            <div class="spacer s10"></div>
                            <input class="input" type="text" v-model="link" :placeholder="'Link to external ' + item.heading + '...'" />
                        </div>

                        <div class="spacer s30"></div>
                        <h4>PHOTOS</h4>
                        <div class="spacer s10"></div>
                        <div v-if="myUploads.length > 0" class="image-div">
                            <ul class="uploads">
                                <draggable v-model="myUploads" class="sorting-list" group="people" @start="drag = true" @end="orderChanged()">
                                    <li class="image-wrap" v-for="(image, index) in myUploads" :key="image.id">
                                        <img :src="image" />
                                        <span class="cross" @click="deleteImage(index)"><font-awesome-icon icon="times"/></span>
                                    </li>
                                </draggable>
                            </ul>
                        </div>

                        <div v-if="myUploads.length < 3">
                            <vue-dropzone
                                ref="imgDropZone"
                                id="customdropzone"
                                :options="dropzoneOptions"
                                @vdropzone-complete="afterComplete"
                            ></vue-dropzone>
                        </div>

                        <div class="clear"></div>

                        <div class="spacer s10"></div>

                        <div class="spacer s30"></div>

                        <div class="buttons">
                            <span
                                :disabled="!somethingChanged"
                                class="button is-medium"
                                :class="[item.action == 'Edit' ? 'is-success' : 'is-primary']"
                                @click="saveItem()"
                                >Save</span
                            >
                            <span class="button is-medium is-light" @click="cancelItem">Cancel</span>
                        </div>

                        <div class="spacer s40"></div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
let uuid = require('uuid');
import firebase from 'firebase/compat/app';
import NProgress from 'nprogress';
import draggable from 'vuedraggable';
// const ImageUploader = () => import("../reusables/ImageUploader.vue");

export default {
    async mounted() {
        console.log(this.item);
        if (this.item) {
            this.title = this.item.title;
            if (this.item.details) this.details = this.item.details;
            if (this.item.showLink) this.showLink = this.item.showLink;
            if (this.item.link) this.link = this.item.link;
            if (this.item.index) this.index = this.item.index;
            if (this.item.uploadUrls) {
                this.item.uploadUrls.forEach(img => {
                    this.myUploads.push(img);
                });
            }
        }
    },

    data: function() {
        return {
            title: '',
            details: '',
            link: '',
            showLink: false,
            isActiveWindow: true,
            index: 999,
            temp: [],

            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                thumbnailWidth: 150,
                thumbnailHeight: 150,
                addRemoveLinks: false,
                acceptedFiles: '.jpg, .jpeg, .png',
                dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Click here to add a photo</p>
            <p class="form-text">Allowed Files: .jpg, .jpeg, .png (Up to 2MB)</p>`,
                maxFilesize: 2
            },

            myUploads: [],
            newUploads: [],
            saveImageModal: false,
            fileType: '',
            currentUpload: '',
            tempLabel: '',
            showPDF: false,
            selectedUrl: '',
            selectedPDF: '',

            uploads: [],
            logoChanged: false,
            imageChanged: false,
            shownImageIndex: 10,

            myPDFs: [],
            pdfChanged: false
        };
    },

    components: {
        vueDropzone: vue2Dropzone,
        draggable
        // ImageUploader
    },

    props: {
        item: Object,
        allowPDFs: Boolean
    },

    computed: {
        somethingChanged() {
            return (
                this.item.title != this.title ||
                this.item.details != this.details ||
                this.item.link != this.link ||
                this.imageChanged ||
                this.pdfChanged
            );
        },
        currentVesselID() {
            return this.$store.getters.getCurrentVesselID;
        }
    },

    methods: {
        preventDiv($evt) {
            if (event.key === 'Enter') {
                // document.execCommand("insertLineBreak");
                $evt.preventDefault();
            }
        },

        saveItem() {
            let self = this;
            let returnItem = {
                id: self.item.id,
                edited: self.somethingChanged ? true : false,
                title: self.title ? self.title : 'No title',
                details: self.details ? self.details : '',
                link: self.link ? self.link : '',
                isNewItem: self.item.action == 'Write New' ? true : false,
                index: self.index ? self.index : 999,
                uploadUrls: self.myUploads,
                uploadPDFs: self.myPDFs
            };
            this.$emit('sendItem', returnItem);
            this.isActiveWindow = false;
        },

        cancelItem() {
            let self = this;
            console.log('cancelled', this.somethingChanged);
            if (this.somethingChanged) {
                let message = {
                    title: 'Leave without saving?',
                    body: 'Any changes made may be lost if not saved'
                };
                let options = {
                    okText: 'Yes, Leave',
                    backdropClose: true,
                    cancelText: 'Cancel',
                    customClass: 'dialogue'
                };
                this.$dialog.confirm(message, options).then(function(dialog) {
                    let returnItem = {
                        id: self.item.id,
                        edited: false,
                        title: self.title ? self.title : 'No title',
                        details: self.details ? self.details : '',
                        link: self.link ? self.link : '',
                        index: self.index ? self.index : 999,
                        uploadUrls: self.myUploads ? self.myUploads : [],
                        uploadPDFs: self.myPDFs ? self.myPDFs : [],
                        isNewItem: false
                    };
                    self.$emit('sendItem', returnItem);
                    self.isActiveWindow = false;
                });
            } else {
                let returnItem = {
                    id: self.item.id,
                    edited: false,
                    title: self.title ? self.title : 'No title',
                    details: self.details ? self.details : '',
                    link: self.link ? self.link : '',
                    index: self.index ? self.index : 999,
                    uploadUrls: self.myUploads ? self.myUploads : [],
                    uploadPDFs: self.myPDFs ? self.myPDFs : [],
                    isNewItem: false
                };
                this.$emit('sendItem', returnItem);
                this.isActiveWindow = false;
            }
        },

        addIcon(text) {
            let textArea = this.$refs.detailsbox;
            let cursorPosition = textArea.selectionStart;
            this.addTextAtCursorPosition(textArea, cursorPosition, text);
            this.updateCursorPosition(cursorPosition, text, textArea);
        },
        addTextAtCursorPosition(textArea, cursorPosition, text) {
            var front = textArea.value.substring(0, cursorPosition);
            var back = textArea.value.substring(cursorPosition, textArea.value.length);
            textArea.value = front + text + back;
        },
        updateCursorPosition(cursorPosition, text, textArea) {
            cursorPosition = cursorPosition + text.length;
            textArea.selectionStart = cursorPosition;
            textArea.selectionEnd = cursorPosition;
            textArea.focus();
        },

        setImage: function(output) {
            this.hasImage = true;
            this.logoChanged = true;
            this.uploads.push({
                url: output.dataUrl
            });
        },

        orderChanged() {
            // drag = false;
            this.imageChanged = true;
        },

        zoomInOut(index) {
            this.shownImageIndex = this.shownImageIndex == index ? 10 : index;
        },

        previewPDF(pdf) {
            this.selectedPDF = pdf;
            this.showPDF = true;
        },
        cancelUpload() {
            this.saveImageModal = false;
            this.$refs.imgDropZone.removeFile(this.currentUpload);
            this.currentUpload = '';
            this.fileType = '';
            this.tempLabel = '';
        },

        async afterComplete(upload) {
            NProgress.start();
            let self = this;
            console.log('currentUpload', upload);
            this.currentUpload = upload;
            this.saveImageModal = true;
            this.isLoading = true;
            this.imageChanged = true;

            var imageName = uuid.v1();
            try {
                //save image
                let file = upload;
                var metadata = {
                    // contentType: "image/png"
                    contentType: upload.type ? upload.type : 'image/png'
                };
                var storageRef = firebase.storage().ref();
                var imageRef = storageRef.child(`helper-uploads/pooled/${imageName}.png`); // use upload.type to infer the suffix? ie .png or .pdf?
                console.log(imageRef);
                await imageRef.put(file, metadata);
                var downloadURL = await imageRef.getDownloadURL();
                // self.myUploads.push({ imageUrl: downloadURL });
                self.myUploads.push(downloadURL);
                NProgress.done();
            } catch (error) {
                console.log(error);
                NProgress.done();
            }
            this.$refs.imgDropZone.removeFile(upload);
        },

        deleteImage(index) {
            console.log('delete');
            this.imageChanged = true;
            this.myUploads.splice(index, 1);
        }
    }
};
</script>

<style lang="scss" scoped>
*[contenteditable='true'] {
    display: inline-block;
}
// .modal {
//   z-index: 4999 !important;
// }
.modal-card {
    width: 95%;
    height: 95%;
    max-width: 1200px;
    border-radius: 6px;
    position: relative;
    .modal-card-body {
        max-height: none;
        padding: 4rem 3rem 3rem;
        text-align: left;
    }
    .delete {
        position: absolute;
        right: 20px;
        top: 20px;
    }
    .title {
        font-size: 1.5rem;
        margin-bottom: 12px;
    }
    .link {
        font-size: 1rem;
        margin-top: 1rem;
    }
    // .details {
    //   background: #f9f9f9;
    //   padding: 2rem;
    //   border-radius: 6px;
    // }
}

input[type='text'] {
    padding: 25px;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 2rem;
    // border-width: 2px;
    &:focus {
        outline: none;
        box-shadow: none;
        border-color: green;
    }
}
textarea {
    //overflow: hidden;
    min-height: 300px;
    min-height: 40vh;
    padding: 20px 25px;
    box-shadow: none;
    // margin-bottom: 2rem;
    margin-bottom: 0;
    border-radius: 0;
    overflow: scroll;
    // border-width: 2px;
    &:focus {
        outline: none;
        box-shadow: none;
        border-color: green;
    }
}

.helper-icons {
    border: 1px solid #ddd;
    display: inline-block;
    border-top: 0;
    margin-bottom: 2rem;
    li {
        display: inline-block;
        padding: 8px 20px;
        cursor: pointer;
        font-size: 1.5rem;
    }
}

ul.uploads {
    list-style: none;
    // height: 250px;
    height: auto;
    margin-bottom: 2rem;
    .sorting-list {
        display: inline-flex;
        height: 100%;
    }
    li {
        display: inline-flex;
        max-width: 200px;
        min-width: 100px;
        margin: 0 1rem 1rem 0;
        height: 100%;
        float: left;
        // padding: 15px;
        border: 1px solid #ddd;
        position: relative;
        cursor: pointer;
        img {
            width: 100%;
            align-self: center;
        }
        &.show {
            width: 100%;
            max-width: none;
            display: inline-table;
            img {
                max-width: none;
            }
        }
        .caption {
            border: none;
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: 0.9rem;
            width: 100%;
            padding: 8px;
            background: #f4f4f4;
            text-align: center;
            border: none;
            border-radius: 0;
            input {
                border: none;
                background: none;
                padding: 0;
                height: auto;
                min-height: 0;
                margin: 0;
                border-radius: 0;
                font-size: 0.9rem;
                line-height: 1rem;
                text-align: center;
            }
            textarea {
                min-height: 0;
                border: none;
                background: none;
                padding: 0;
                height: auto;
                min-height: 0;
                margin: 0;
                border-radius: 0;
                font-size: 0.9rem;
                line-height: 1rem;
                text-align: center;
            }
        }
        .cross {
            justify-content: center;
            position: absolute;
            top: -12px;
            right: -12px;
            z-index: 99999;
            background: #dedede;
            padding: 10px;
            width: 35px;
            height: 35px;
            display: flex;
            border-radius: 50%;
            transition: 0.2s;
            cursor: pointer;
            &:hover {
                color: red;
            }
        }
    }
}

iframe {
    width: 100%;
    height: 100%;
    min-height: 500px;
}
.dialogue {
    z-index: 9999999;
    .dg-backdrop {
        z-index: 9999999 !important;
    }
    .dg-container {
        z-index: 9999999 !important;
    }
}

@media only screen and (max-width: 600px) {
    .modal-card {
        .modal-card-body {
            padding: 3rem 1rem 1rem;
        }
    }
}
</style>
