<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">Copy Multiple Documents to a Collection</h3>
                <br />

                <div class="input-wrapper">
                    <label>Source Collection Path (must include forward but no trailing slashes)</label>
                    <input class="input" type="text" placeholder="Paste source doc path" v-model="sourcePath" />
                </div>

                <div class="clear"></div>

                <div class="input-wrapper centered">
                    <label>Target Collection Path (must include forward but no trailing slashes)</label>
                    <input class="input" type="text" placeholder="Paste target collection path" v-model="targetPath" />
                </div>

                <div class="clear"></div>

                <div class="input-wrapper centered">
                    <label>Document key field (ie title)</label>
                    <input class="input" type="text" placeholder="Type key field name (case sensitive)" v-model="keyField" />
                </div>

                <div class="clear"></div>

                <div class="checkbox-wrapper-new">
                    <input class="is-checkradio" type="checkbox" id="retainIDs" v-model="retainDocIDs" />
                    <label for="retainIDs"> Use existing doc IDs?</label>
                </div>

                <div class="checkbox-wrapper-new">
                    <input class="is-checkradio" type="checkbox" id="useSubColl" v-model="includeSubCollection" />
                    <label for="useSubColl"> Include a sub collection?</label>
                </div>

                <div v-if="includeSubCollection" class="input-wrapper centered">
                    <div class="spacer s20"></div>
                    <label :class="{ red: /\s/.test(subCollName) || subCollName == '' }">Name of Source Sub Collection</label>
                    <input class="input" type="text" placeholder="Type sub collection name.." v-model="subCollName" />
                </div>
                <div class="clear"></div>
                <div v-if="includeSubCollection" class="input-wrapper centered">
                    <!-- <div class="spacer s20"></div> -->
                    <label :class="{ red: /\s/.test(newSubCollName) || newSubCollName == '' }">Name of Target Sub Collection</label>
                    <input class="input" type="text" placeholder="Type new sub collection name.." v-model="newSubCollName" />
                </div>
                <div class="clear"></div>
                <div v-if="subCollName != newSubCollName" class="red">
                    <span><font-awesome-icon icon="exclamation-triangle"/></span> Target sub collection doesn't match source.
                </div>

                <div class="clear"></div>

                <hr />

                <div class="checkbox-wrapper-new">
                    <input class="is-checkradio" type="checkbox" id="hideArchived" v-model="hideArchived" @change="clearDocs()" />
                    <label for="hideArchived"> Hide Archived Docs</label>
                </div>
                <div class="spacer s10"></div>

                <div class="buttons">
                    <span :disabled="pathsAreValid" class="button is-warning" @click="displayDocs()">Show Documents</span>
                    <span class="button is-light" @click="clearDocs()">Clear</span>
                </div>

                <!-- {{selectedItems}}<hr>{{pooledIDs}} -->

                <!-- <ul v-if="poolOfDocs.length">
              <li v-for="(value, propertyName, index) in poolMap">
                {{ propertyName }}: {{ value[keyField] }} ({{ index }})
              </li>
            </ul>
 -->

                <hr />
                <!-- {{ selectedItems.length }} -->

                <table v-if="poolOfDocs.length" class="table is-bordered is-fullwidth">
                    <tr>
                        <th>Title</th>
                        <!-- <th>Details</th> -->
                        <th v-if="isSteve">Copy?</th>
                    </tr>
                    <tr v-for="(value, docID, index) in poolMap" :key="index">
                        <!-- <td v-if="(hideArchived && !value.archived) || !hideArchived">{{ value[keyField] }} ({{ value.archived }})</td> -->
                        <td>{{ value[keyField] }}</td>
                        <td>
                            <input class="is-checkradio" type="checkbox" :id="index" :value="docID" v-model="selectedItems" />
                        </td>
                    </tr>
                    <tr>
                        <td>Copy All?</td>
                        <td>
                            <div class="form-group-reg">
                                <input class="is-checkradio" type="checkbox" id="copyAllDocs" @change="updateCopyAll()" v-model="copyAllDocs" />
                            </div>
                        </td>
                    </tr>
                </table>

                <!-- <table v-if="poolOfDocs.length" class="table is-bordered is-fullwidth">
              <tr>
                <th>Title</th>
                <th v-if="isSteve">Copy?</th>
              </tr>
              <tr v-for="(item, index) in poolOfDocs" :key="index">
                <td>
                  {{item[keyField]}}
                </td>
                <td>
                  <input class="is-checkradio" type="checkbox" :id="index" :value="item" v-model="selectedItems">
                </td>
              </tr>
            </table> -->

                <!-- {{selectedItems}} -->

                <div v-if="selectedItems.length > 0" class="buttons floating">
                    <span :disabled="pathsAreValid" class="button is-danger" @click="copyDocs()">Copy Documents</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '../main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {
    name: 'CopyMultipleDocuments',
    components: {},
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            poolOfDocs: [],
            pooledIDs: [],
            sourcePath: '/companies/MnIzVx0NVTcfLnkWVMPD/',
            targetPath: '/companies/401891e5-0273-4604-89f9-cda0b93193e0/',
            selectedItems: [],
            keyField: 'checklistName',
            poolMap: {},
            retainDocIDs: true,
            copyAllDocs: false,
            includeSubCollection: false,
            subCollName: 'customFields',
            newSubCollName: 'customFields',
            hideArchived: true
        };
    },

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false;
        },
        pathsAreValid() {
            return this.sourcePath == '' || this.targetPath == '';
        }
    },

    methods: {
        onUserLogin: function(user) {
            // if (user) {
            // }
        },

        close: function() {},

        displayDocs() {
            let self = this;
            if (this.sourcePath != '') {
                NProgress.start();
                console.log(self.sourcePath);
                let newSourcePath = self.sourcePath.length && self.sourcePath[0] === '/' ? self.sourcePath.substring(1) : self.sourcePath;
                console.log(newSourcePath);

                db.collection(newSourcePath)
                    .get()
                    .then(querySnapshot => {
                        self.poolOfDocs = [];
                        self.pooledIDs = [];
                        self.poolMap = {};
                        querySnapshot.forEach(doc => {
                            // self.poolOfDocs.push({
                            //   id: doc.id,
                            //   title: doc.data().title ? doc.data().title : 'no title',
                            //   details: doc.data().details ? doc.data().details : 'no details',
                            // });
                            let data = doc.data();
                            data.id = doc.id;
                            data.archived = doc.data().archived ? doc.data().archived : false;
                            let archived = doc.data().archived ? doc.data().archived : false;
                            // console.log(doc.id, archived);
                            // self.pooledIDs.push(doc.id);
                            // self.poolOfDocs.push(data);
                            // self.poolMap[doc.id] = data;

                            if (self.hideArchived) {
                                if (!archived) {
                                    self.pooledIDs.push(doc.id);
                                    self.poolOfDocs.push(data);
                                    self.poolMap[doc.id] = data;
                                }
                            } else {
                                self.pooledIDs.push(doc.id);
                                self.poolOfDocs.push(data);
                                self.poolMap[doc.id] = data;
                            }
                        });
                    })
                    .then(() => {
                        NProgress.done();
                    });
            }
        },

        updateCopyAll() {
            console.log(this.copyAllDocs);
            this.selectedItems = this.copyAllDocs ? this.pooledIDs : [];
        },

        clearDocs() {
            this.poolOfDocs = [];
            this.sourcePath = '/companies/MnIzVx0NVTcfLnkWVMPD/';
            this.targetPath = '/companies/401891e5-0273-4604-89f9-cda0b93193e0/';
            this.selectedItems = [];
            this.pooledIDs = [];
            this.keyField = 'checklistName';
            this.poolMap = {};
            this.subCollName = 'customFields';
            this.newSubCollName = 'customFields';
            this.includeSubCollection = false;
            this.copyAllDocs = false;
        },

        copyDocs() {
            let self = this;
            self.copyAllDocs = false;

            let newSourcePath = self.sourcePath.length && self.sourcePath[0] === '/' ? self.sourcePath.substring(1) : self.sourcePath;
            let newTargetPath = self.targetPath.length && self.targetPath[0] === '/' ? self.targetPath.substring(1) : self.targetPath;

            console.log(newSourcePath);
            console.log(newTargetPath);

            let message;
            message = {
                title: 'Confirm Copy Documents?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();

                    if (self.includeSubCollection) {
                        let data = {
                            sourceCollection: newSourcePath,
                            targetCollection: newTargetPath,
                            selectedItems: self.selectedItems,
                            retainDocIDs: self.retainDocIDs,
                            subCollName: self.subCollName,
                            newSubCollName: self.newSubCollName
                        };
                        console.log('data', data);
                        var adminCopyAnyDocumentsWithSubColl = firebase.functions().httpsCallable('adminCopyAnyDocumentsWithSubColl');
                        adminCopyAnyDocumentsWithSubColl(data)
                            .then(function(result) {
                                // var sanitizedMessage = result.data.text;
                                // console.log('Result',sanitizedMessage);
                                // console.log('Message',result.data.message);
                                self.clearDocs();
                                NProgress.done();
                            })
                            .catch(function(error) {
                                console.log('Error', error.text);
                                NProgress.done();
                            });
                    } else {
                        let data2 = {
                            sourceCollection: newSourcePath,
                            targetCollection: newTargetPath,
                            selectedItems: self.selectedItems,
                            retainDocIDs: self.retainDocIDs
                        };
                        console.log('data2', data2);
                        var adminCopyAnyDocuments = firebase.functions().httpsCallable('adminCopyAnyDocuments');
                        adminCopyAnyDocuments(data2)
                            .then(function(result) {
                                // var sanitizedMessage = result.data.text;
                                // console.log('Result',sanitizedMessage);
                                // console.log('Message',result.data.message);
                                self.clearDocs();
                                NProgress.done();
                            })
                            .catch(function(error) {
                                console.log('Error', error.text);
                                NProgress.done();
                            });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        formatDate: function(sentDate, format) {
            let fixDate = sentDate ? sentDate.toDate() : new Date();
            //console.log("Fix date:",fixDate);
            return moment(fixDate).format(format);
        }
    }
};
</script>

<style lang="scss" scoped>
.site-wrapper .container {
    max-width: 850px;
}
.form-group {
    label {
        top: auto;
        bottom: -25px;
    }
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
.traffic-light {
    position: relative;
    span {
        float: left;
        margin-right: 10px;
    }
    .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
            background: red;
            &.active {
                background: red;
            }
        }
        &.orange {
            background: orange;
            &.active {
                background: orange;
            }
        }
        &.green {
            background: #03d203;
            &.active {
                background: #03d203;
            }
        }
    }
}

input[type='text'] {
    width: 90%;
    float: left;
    display: block;
    margin-bottom: 2rem;
    height: auto;
    padding: 12px;
    // &:after {
    //   content: "\f16c";
    //   font-family: "Font Awesome 5 Brands";
    //   color: red;
    // }
    // &.valid:after {

    // }
}
.icon-wrap {
    display: block;
    float: left;
    height: 35px;
    margin-left: 10px;
    font-size: 1.5rem;
    color: red;
    &.valid {
        color: green;
    }
}
label {
    margin-bottom: 10px;
    display: block;
}
.is-checkradio[type='checkbox'] {
    opacity: 1;
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
    padding: 10px;
    margin-top: 10px;
    margin-left: 10px;
}

.checkbox-wrapper-new {
    .is-checkradio[type='checkbox'] + label {
        margin-left: 0;
        position: inherit;
        display: block;
        &:before {
            display: none !important;
        }
        &:after {
            display: none;
        }
    }
}

table.table {
    td {
        padding: 1rem;
        input {
            margin: 5px;
        }
    }
}
</style>
