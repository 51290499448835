import { render, staticRenderFns } from "./BuildMicroSops.vue?vue&type=template&id=07154e27&scoped=true"
import script from "./BuildMicroSops.vue?vue&type=script&lang=js"
export * from "./BuildMicroSops.vue?vue&type=script&lang=js"
import style0 from "./BuildMicroSops.vue?vue&type=style&index=0&id=07154e27&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07154e27",
  null
  
)

export default component.exports