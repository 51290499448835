<template>
    <div>
        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': showModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-warning">
                    <p class="modal-card-title">{{ title }}</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="checkbox-wrapper">
                        <label class="label"><font-awesome-icon icon="tags" /> &nbsp;New Filter Tags</label>
                        <!-- <div class="spacer s10"></div> -->

                        <div class="box" v-if="tagCategories.length == 0">
                            <font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; No tags found...
                        </div>

                        <div v-else>
                            <div v-for="(cat, idx) in tagCategories" :key="`cat-${idx}`">
                                <div class="heading">{{ cat.title }}</div>
                                <div class="box" v-if="cat.tags.length > 0">
                                    <div class="field" v-for="(tag, ind) in cat.tags" :key="`tag-${ind}`">
                                        <input class="is-checkradio" type="checkbox" :id="tag.id" :value="tag.id" v-model="selectedTags" />
                                        <label :class="{ 'has-code': selectedTags.includes(tag.id) }" :for="tag.id">{{ tag.title }}</label>
                                    </div>
                                </div>
                                <div v-else class="box">No tags in this category yet.</div>
                                <div class="spacer s10"></div>
                            </div>
                        </div>

                        <div class="clear"></div>
                    </div>
                    <div class="spacer s30"></div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-warning" @click.prevent="saveTags()">Save and Close</button>
                    <button class="button is-danger" @click.prevent="close">Cancel</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app';
import { db } from '../main.js';
import NProgress from 'nprogress';

export default {
    props: {
        title: String,
        tagCategories: Array,
        sentSelectedTags: Array
    },

    mounted() {
        // console.log(this.tagCategories);
        if (this.sentSelectedTags) {
            this.selectedTags = [...this.sentSelectedTags];
        } else {
            this.selectedTags = [];
        }
    },

    computed: {
        currentCatTags() {
            let t = this.tagCategories.map(m => m.tags.map(n => n.id));
            return [].concat(...t);
        },
        cleanTags() {
            return this.selectedTags.filter(tag => this.currentCatTags.includes(tag));
        }
    },

    data() {
        return {
            selectedTags: [],
            showModal: true
        };
    },

    methods: {
        close() {
            this.showModal = false;
            this.$emit('closed');
        },
        // async cleanTags() {
        //     return this.selectedTags.filter((tag) => this.currentCatTags.includes(tag));
        // },
        async saveTags() {
            // let finalTags = await this.cleanTags();
            this.$emit('returnResult', this.cleanTags);
        }
    }
};
</script>

<style lang="scss" scoped>
.heading {
    margin-bottom: 10px;
}
</style>
