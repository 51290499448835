<template>
    <div class="site-wrapper no-header">
        <div class="container">
            <div class="notification is-info"><h3>Create a New Fleet</h3></div>

            <div v-if="!isSelected" class="notification is-primary">
                <input class="input" v-model="findName" id="filtername" autocomplete="off" type="text" placeholder="Start typing user name..." />
            </div>

            <div class="buttons">
                <span v-if="findName != ''" class="button is-warning" @click="clearUser()">Clear</span>
            </div>

            <div v-if="isSelected" class="highlight">{{ selectedUser.username }}</div>

            <div v-if="findName.length > 2 && !isSelected">
                <div v-for="(user, index) in filteredNames" :key="index">
                    <div class="buttons results">
                        <span class="button is-light" @click="selectUser(user)">{{ user.username }} ({{ user.userphone }})</span>
                    </div>
                </div>
            </div>

            <!-- {{newFleet}}<br>{{selectedUser}} -->

            <div v-if="isSelected" class="new-vessel-info">
                <div class="spacer s30"></div>

                <div class="form-group-reg">
                    <label class="label">Fleet Name</label>
                    <input class="input" type="text" placeholder="Type fleet name" v-model="newFleet.fleetName" />
                </div>

                <div class="spacer s10"></div>

                <!-- <div class="form-group-reg">
            <div class="checkbox-wrapper">
            <label class="label">Primary Fleet State</label>
              <div class="field" v-for="item in allstates" :key="item.id">
                <input class="is-checkradio" type="checkbox" :id="item.id" :value="item" v-model="newFleet.fleetState">
                <label :for="item.id">{{item.name}}</label>
              </div>
            </div>
          </div> -->

                <!-- <div class="form-group-reg">
            <label class="label">Primary Fleet State</label>
            <div class="select is-primary">
              <select v-model="newFleet.fleetState">
                <option disabled value="">Select a State</option>
                <option v-for="state in allstates" :key="state.id">
                  {{ state.name }}
                </option>
              </select>
            </div>
          </div> -->

                <div class="form-group-reg">
                    <label class="label">Fleet Location</label>
                    <input class="input" type="text" placeholder="Type fleet name" v-model="newFleet.fleetState" />
                </div>

                <!-- <div class="form-group-reg">
            <div class="checkbox-wrapper">
            <label class="label">Primary Fleet State</label>
              <div class="field" v-for="item in allstates" :key="item.id">
                <input class="is-checkradio" type="checkbox" :id="item.id" :value="item" v-model="selectedStates">
                <label :for="item.id">{{item.name}}</label>
              </div>
            </div>
          </div> -->

                <div class="spacer s10"></div>

                <div class="buttons">
                    <button :disabled="showSave" class="button is-primary is-medium" @click="addFleet()">Add Fleet to User</button>
                </div>
            </div>

            <!-- <div class="posi">{{finalCodes}}<br>{{codeGroups}}</div> -->
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as moment from 'moment';
import NProgress from 'nprogress';
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'AdminAddFleet',

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        filteredNames() {
            let filter = new RegExp(this.findName, 'i');
            return this.users.filter(user => user.username.match(filter));
        },
        showSave() {
            return this.newFleet.fleetName == '' || this.newFleet.fleetState == '';
        },

        selectedStateCodes() {
            return this.selectedStates.map(item => item.code);
        },
        selectedStateNames() {
            return this.selectedStates.map(item => item.name);
        }
    },

    data() {
        return {
            users: [],
            isSelected: false,
            findName: '',
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            selectedUser: '',
            newFleet: {
                fleetName: '',
                fleetState: ''
            },

            allstates: [
                { id: 's1', name: 'Tasmania', checked: false, code: 'base' }, //make
                { id: 's2', name: 'Victoria', checked: false, code: 'base' }, //deep
                { id: 's3', name: 'New South Wales', checked: false, code: 'base' }, //just
                { id: 's4', name: 'Queensland', checked: false, code: 'base' }, //wear
                { id: 's5', name: 'South Australia', checked: false, code: 'base' }, //snap
                { id: 's6', name: 'Western Australia', checked: false, code: 'base' }, //dash
                { id: 's7', name: 'Northern Territory', checked: false, code: 'base' } //slip
            ],
            selectedStates: [],
            newFleetID: ''
        };
    },

    mounted() {
        // this.$store.dispatch('setUser');
        // this.$store.dispatch('SETSUBSCRIPTIONINFO');
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                this.firebaseemail = user.email;
                this.firebaseid = user.uid;
                this.firebasedisplayname = this.userData.username;
                this.loadUsers();
            }
        },

        loadUsers: function() {
            let self = this;
            db.collection('userdata')
                .orderBy('signUpDate', 'desc')
                .onSnapshot(function(querySnapshot) {
                    self.users = [];
                    querySnapshot.forEach(function(doc) {
                        // self.users.push(doc.data())
                        self.users.push({
                            userid: doc.id,
                            // signUpDate: doc.data().signUpDate,
                            // userstate: doc.data().userstate,
                            // industry: doc.data().industry,
                            // userrole: doc.data().userrole,
                            // userGroup: doc.data().userGroup ? doc.data().userGroup : '',
                            username: doc.data().username,
                            useremail: doc.data().useremail ? doc.data().useremail : '',
                            userphone: doc.data().userphone ? doc.data().userphone : '',
                            avatarUrl: doc.data().avatarUrl ? doc.data().avatarUrl : ''
                            // favouriteVesselID: doc.data().favouriteVesselID ? doc.data().favouriteVesselID : '',
                            // signupPlatform: doc.data().signupPlatform ? doc.data().signupPlatform : 'not set',
                            // favouriteFixed: doc.data().favouriteFixed ? doc.data().favouriteFixed : false,
                            // profilingInfo: doc.data().profilingInfo ? doc.data().profilingInfo : '',
                        });
                    });
                });
        },

        // cleanPhone(number) {
        //   return "0" + number.slice(3,12);
        // },

        selectUser(user) {
            console.log(user);
            this.isSelected = true;
            this.selectedUser = user;
        },

        clearUser: function() {
            this.findName = '';
            this.isSelected = false;
            this.selectedUser = '';
            this.newFleet = {
                fleetName: '',
                fleetState: ''
            };
        },

        addFleet() {
            console.log(this.newFleet);

            NProgress.start();
            let self = this;
            // let trialEndDate = moment().add(14, 'days');

            // let t1 = new Date();
            // let t2 = new Date(t1.setDate(t1.getDate() + 14));

            // let newFleetData = {
            //   fleetOwnerID: this.selectedUser.userid,
            //   fleetOwnerName: this.userData.username,
            //   fleetName: this.newFleet.fleetName,
            //   // vesselUVI: this.newFleet.vesselUVI,
            //   // allLoadedOnSave: false,
            //   // vesselOwnerName: this.selectedUser.username,
            //   // isDemo: true,
            //   // isPaidFor: false,
            //   // nextChargeDate: new Date(trialEndDate),
            //   // overviewSavedAt: t1.getTime(),
            //   // lastVesselCharge: 0,
            //   companyState: this.newFleet.fleetState,
            //   // vesselIndustry: '',
            //   // vesselOwnerPhone: this.selectedUser.userphone ? this.cleanPhone(this.selectedUser.userphone) : '',
            //   // vesselOwnerEmail: this.selectedUser.useremail ? this.selectedUser.useremail : '',

            //   // nextVesselCharge: this.newFleet.nextCharge,
            //   // isCompanyVessel: this.newFleet.isCompanyVessel,
            //   // takesPassengers: this.codeGroups.includes('g-travs'),
            //   // homePort: this.newFleet.homePort,
            //   // description: this.newFleet.description,
            //   // vesselMaster: this.newFleet.vesselMaster,
            //   // vesselCrew: this.newFleet.vesselCrew,
            //   // shoreContactName: this.newFleet.shoreContactName,
            //   // shoreContactNumber: this.newFleet.shoreContactNumber,

            //   // vesselStates: this.selectedStateNames,
            //   // vesselIndustries: this.selectedIndustryNames,
            //   // surveyClasses: this.selectedClassNames,
            //   // operationalAreas: this.selectedAreaNames,
            //   // fasttrackAnswers: this.selectedAnswerNames,

            //   // codes: this.finalCodes,
            //   // codeGroups: this.codeGroups,

            // };

            // console.log(newFleetData);

            let newCompanyID = uuidv4();

            var adminCreateCompany = firebase.functions().httpsCallable('adminCreateCompany');
            adminCreateCompany({
                companyName: self.newFleet.fleetName,
                companyState: self.newFleet.fleetState,
                companyEmail: '',
                maxVessels: 0,
                primaryUserEmail: self.selectedUser.useremail,
                primaryUserID: self.selectedUser.userid,
                primaryUserName: self.selectedUser.username,
                primaryUserPhone: self.selectedUser.userphone,
                fleetAvatarUrl: '',
                userAvatarUrl: self.selectedUser.avatarUrl ? self.selectedUser.avatarUrl : '',
                newCompanyID: newCompanyID,
                companyLocation: self.newFleet.fleetState,
                createdBy: 'OffshoreSMS Admin Team',
                platform: 'OffshoreSMS Web Admin'
            })
                .then(function(result) {
                    self.clearUser();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.error('Error adding vessel: ', error);
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.highlight {
    background: cadetblue;
    padding: 15px;
    color: #fff;
}
.posi {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: #fff;
    z-index: 999;
}
label {
    &.label {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 10px;
        &.tight {
            margin-bottom: 4px;
        }
    }
}
.buttons.results {
    margin-bottom: 4px;
}
</style>
