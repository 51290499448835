<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <div class="buttons  floating">
                    <button class="button is-info" @click="goBack()">
                        <span><font-awesome-icon icon="chevron-left"/></span> &nbsp;Back &nbsp;
                    </button>
                </div>

                <h3 class="is-size-5">Build New Helper Content</h3>
                <br />

                <div class="buttons">
                    <router-link class="button is-skip is-medium" to="tag-library"><font-awesome-icon icon="edit" /> &nbsp;Tag Library</router-link>
                </div>

                <hr />

                <!-- <div class="buttons">
          <router-link
            class="button is-light is-medium"
            to="build-pooled-all-statements"
            >Build Helper All
            Statements</router-link
          >
        </div> -->

                <!-- <div class="buttons">
                    <router-link class="button is-light is-medium" to="build-pooled-statements">Build Helper Individual Statements</router-link>
                </div> -->

                <div class="buttons">
                    <router-link class="button is-primary is-medium" to="build-pooled-hazards"
                        ><font-awesome-icon icon="star" /> &nbsp;Build Helper Full Hazards</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-primary is-medium" to="build-pooled-contacts"
                        ><font-awesome-icon icon="star" /> &nbsp;Build Helper Contacts</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-primary is-medium" to="build-pooled-policies"
                        ><font-awesome-icon icon="star" /> &nbsp;Build Helper Policies</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-skip is-medium" to="build-micro-policies"
                        ><font-awesome-icon icon="globe" /> &nbsp;New Micro Policies</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-primary is-medium" to="build-pooled-sops"
                        ><font-awesome-icon icon="star" /> &nbsp;Build Helper Safe Work Procedures</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-skip is-medium" to="build-micro-sops"
                        ><font-awesome-icon icon="globe" /> &nbsp;New Micro Safe Work Procedures</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-primary is-medium" to="build-pooled-eops"
                        ><font-awesome-icon icon="star" /> &nbsp;Build Helper Emergency Procedures</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-skip is-medium" to="build-micro-eops"
                        ><font-awesome-icon icon="globe" /> &nbsp;New Micro Emergency Procedures</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-primary is-medium" to="build-pooled-startups"
                        ><font-awesome-icon icon="star" /> &nbsp;Build Helper Startup Checks</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-primary is-medium" to="build-pooled-maintenance"
                        ><font-awesome-icon icon="star" /> &nbsp;Build Helper Maintenance Checks</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-primary is-medium" to="build-pooled-inductions"
                        ><font-awesome-icon icon="star" /> &nbsp;Build Helper Induction Checks</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-primary is-medium" to="build-pooled-passenger-inductions"
                        ><font-awesome-icon icon="star" /> &nbsp;Build Pooled Passenger Induction Checks</router-link
                    >
                </div>

                <div class="buttons">
                    <router-link class="button is-primary is-medium" to="build-helper-statements">
                        <font-awesome-icon icon="star" /> &nbsp;Build Helper Statements
                    </router-link>
                </div>

                <!-- <div class="buttons">
          <router-link
            class="button is-primary is-medium"
            to="build-pooled-checkin-items"
            >Build Helper Visitor Check-in Items</router-link
          >
        </div> -->

                <div class="buttons">
                    <router-link class="button is-primary is-medium" to="build-pooled-shutdowns"
                        ><font-awesome-icon icon="star" /> &nbsp;Build Helper Shutdown Checks</router-link
                    >
                </div>

                <hr />

                <div class="buttons">
                    <router-link class="button is-warning is-medium" to="build-cloud-helpers"
                        ><font-awesome-icon icon="mobile-alt" /> &nbsp;Build App Helper Library</router-link
                    >
                </div>

                <!-- <div class="buttons">
          <router-link
            class="button is-primary is-medium"
            to="build-pooled-jsas"
            >Build Pooled JSA Checks</router-link
          >
        </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {
    name: 'LandingBuildContent',
    components: {},
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {};
    },

    computed: {
        userData() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.userData
                ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2'
                    ? true
                    : false
                : false;
        }
    },

    methods: {
        onUserLogin(user) {
            // if (user) {
            // }
        },

        goBack() {
            this.$router.go(-1);
        }
    }
};
</script>

<style lang="scss" scoped>
.site-wrapper .container {
    max-width: 850px;
}
</style>
