<template>
    <div class="site-wrapper no-header">
        <div class="container">
            <div v-show="tab == 'add'" class="notification is-info"><h3>Quick vessel setup with QR code</h3></div>
            <div v-show="tab == 'update'" class="notification is-primary"><h3>Update an Existing Vessel</h3></div>

            <div class="tabs is-boxed is-centered">
                <ul>
                    <li :class="{ active: tab == 'add' }">
                        <a
                            @click="
                                tab = 'add';
                                clearUser(), stopProgress();
                            "
                            ><font-awesome-icon icon="plus-circle" />&nbsp; <span>Add Vessel</span></a
                        >
                    </li>
                    <li :class="{ active: tab == 'update' }">
                        <a
                            @click="
                                tab = 'update';
                                clearUser(), stopProgress();
                            "
                            ><font-awesome-icon icon="list-ul" />&nbsp; <span>Update Vessel</span></a
                        >
                    </li>
                </ul>
            </div>

            <!-- add a new vessel ========================================================================================  -->
            <div v-if="tab == 'add'" class="new-vessel-info">
                <div class="spacer s30"></div>

                <!-- <div @click="clickToCopy('something else')">Click to copy</div> -->

                <h3>Adding a {{ isDemo ? 'DEMO' : 'NEW' }} vessel to {{ isSelected ? selectedUser.username : 'OffshoreSMS Team User' }}</h3>
                <div class="spacer s20"></div>
                <div v-if="!showUserSearch" class="buttons">
                    <button class="button is-warning" @click="showLoadUsers()">Assign to another user</button>
                </div>

                <div v-if="showUserSearch" class="user-search">
                    <div v-if="!isSelected" class="notification is-primary">
                        <input
                            class="input"
                            v-model="findName"
                            id="filtername"
                            autocomplete="off"
                            type="text"
                            placeholder="Start typing user name..."
                        />
                    </div>
                    <div class="buttons">
                        <span v-if="findName != ''" class="button is-danger" @click="clearUser()">Clear User</span>
                    </div>
                    <div v-if="findName.length > 2 && !isSelected">
                        <div v-for="(user, index) in filteredNames" :key="index">
                            <div class="buttons results">
                                <span class="button is-light" @click="selectUser(user)">{{ user.username }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="spacer s30"></div>

                <div class="form-group-reg">
                    <label class="label">Vessel Name</label>
                    <input class="input" type="text" placeholder="The vessel name" v-model="newVessel.vesselName" />
                </div>

                <div class="form-group-reg">
                    <label class="label">Distinguishing Marks / UVI</label>
                    <input class="input" type="text" placeholder="Any identification numbers.." v-model="newVessel.vesselUVI" />
                </div>

                <div class="form-group-reg">
                    <label class="label">Home Port</label>
                    <input class="input" type="email" placeholder="The vessel's home port" v-model="newVessel.homePort" />
                </div>

                <div class="buttons">
                    <div class="button is-light" @click="showExtras = !showExtras">{{ showExtras ? 'Hide' : 'Show' }} Extra Options</div>
                </div>

                <div v-if="showExtras" class="extra-info">
                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <input class="is-checkradio" type="checkbox" id="isDemo" v-model="isDemo" />
                            <label for="isDemo">Demo Vessel</label>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <input class="is-checkradio" type="checkbox" id="takesPassengers" v-model="takesPassengers" />
                            <label for="takesPassengers">Vessel takes passengers</label>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <input class="is-checkradio" type="checkbox" id="isPrivate" v-model="isPrivate" />
                            <label for="isPrivate">Vessel is Private</label>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Subscription Plans</label>
                            <div class="field" v-for="plan in allPlans" :key="plan">
                                <input class="is-checkradio" type="checkbox" :id="plan" :value="plan" v-model="aaaPlans" />
                                <label :for="plan">{{ plan }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <input class="is-checkradio" type="checkbox" id="isPaidfor" v-model="isPaidfor" />
                            <label for="isPaidfor">Vessel has been paid for</label>
                        </div>
                    </div>

                    <div v-if="isPaidfor" class="form-group-reg">
                        <label class="label">Last Vessel Charge</label>
                        <input
                            class="input"
                            type="tel"
                            maxlength="3"
                            placeholder="Amount"
                            v-model="lastVesselCharge"
                            v-on:keypress="isNumber($event)"
                        />
                    </div>

                    <div class="form-group-reg">
                        <label class="label">Next Vessel Charge</label>
                        <input
                            class="input"
                            type="tel"
                            maxlength="3"
                            placeholder="Amount"
                            v-model="nextVesselCharge"
                            v-on:keypress="isNumber($event)"
                        />
                    </div>

                    <div class="form-group-reg">
                        <label class="label">Clone a Vessel</label>
                        <div class="select is-primary">
                            <select v-model="selectedCloneVessel">
                                <option value="none">Clone a Vessel</option>
                                <option v-for="vessel in cloneVessels" :value="vessel" :key="vessel.vesselID">
                                    {{ vessel.vesselName }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- <div class="form-group-reg">
              <label class="label">Days to Expiry</label>
              <input class="input" type="tel" maxlength="3" placeholder="Enter days" v-model="daysLeft" v-on:keypress="isNumber($event)" />
            </div> -->

                    <!-- <div class="button" @click="tester()">Test</div>{{testDate}} -->
                </div>

                <div class="buttons">
                    <button :disabled="showSave" class="button is-primary is-medium" @click="addVessel()">Add Vessel</button>
                </div>
            </div>

            <!-- search for and update a vessel ========================================================================================  -->
            <div v-if="tab == 'update'" class="update-vessel">
                <div class="spacer s30"></div>

                <div v-if="findMessage != ''" class="notification">
                    <p>{{ findMessage }}</p>
                </div>

                <div class="form-group-reg">
                    <div class="checkbox-wrapper">
                        <input class="is-checkradio" type="checkbox" id="searchByCode" v-model="searchByCode" />
                        <label for="searchByCode">Search by vessel name</label>
                    </div>
                </div>

                <div v-if="!searchByCode">
                    <div class="form-group-reg">
                        <label class="label">Enter Manual Code</label>
                        <input class="input" type="tel" maxlength="6" placeholder="Type 6 digit code.." v-model="enteredCode" />
                    </div>

                    <hr />

                    <div class="buttons">
                        <button :disabled="enteredCode == ''" class="button is-primary is-medium" @click="findVesselFromCode()">Find Vessel</button>
                    </div>
                </div>

                <div v-else>
                    <button v-if="showLoadVessels" class="button is-primary is-medium" @click="loadVessels()">Load Vessels</button>

                    <div v-if="!showLoadVessels">
                        <div v-if="!vesselSelected" class="notification is-primary">
                            <input
                                class="input"
                                v-model="findVessel"
                                id="filtername"
                                autocomplete="off"
                                type="text"
                                placeholder="Start typing vessel name..."
                            />
                        </div>

                        <div v-if="findVessel.length > 2 && !vesselSelected">
                            <div v-for="(vessel, index) in filteredVesselNames" :key="index">
                                <div class="buttons results">
                                    <span class="button is-light" @click="selectVessel(vessel)"
                                        >{{ vessel.vesselName }} ({{ vessel.vesselOwnerName }})</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div class="buttons">
                    <button v-if="enteredCode != '' || vesselSelected" class="button is-warning is-medium" @click="clearCode()">Clear</button>
                </div>

                <div class="spacer s30"></div>

                <div v-if="vesselSelected" class="highlight">
                    <table>
                        <tbody>
                            <tr>
                                <th>NAME:</th>
                                <td>{{ selectedVessel.vesselName }}</td>
                            </tr>
                            <tr>
                                <th>MARKS:</th>
                                <td>{{ selectedVessel.vesselUVI }}</td>
                            </tr>
                            <tr>
                                <th>EXPIRY DATE:</th>
                                <td>{{ selectedVessel.vesselName }}</td>
                            </tr>
                            <tr>
                                <th>DAYS LEFT:</th>
                                <td>{{ selectedVessel.vesselName }}</td>
                            </tr>
                            <tr>
                                <th>ID <em>(Click to copy)</em> :</th>
                                <td ref="vesselID" class="copy" @click="clickToCopy(selectedVessel.vesselID)">{{ selectedVessel.vesselID }}</td>
                            </tr>
                            <tr>
                                <th>OWNER NAME:</th>
                                <td>{{ selectedVessel.vesselOwnerName }}</td>
                            </tr>
                            <tr>
                                <th>OWNER ID <em>(Click to copy)</em> :</th>
                                <td ref="ownerID" class="copy" @click="clickToCopy(selectedVessel.vesselOwnerID)">
                                    {{ selectedVessel.vesselOwnerID }}
                                </td>
                            </tr>
                            <tr>
                                <th>IS DEMO?</th>
                                <td>
                                    <input class="is-checkradio" type="checkbox" id="isDemo" v-model="selectedVessel.isDemo" />
                                    <label for="isDemo">{{ selectedVessel.isDemo ? 'Yes' : 'No' }}</label>
                                </td>
                            </tr>
                            <tr>
                                <th>IS EXPIRED?</th>
                                <td>
                                    <input class="is-checkradio" type="checkbox" id="isExpired" v-model="selectedVessel.isExpired" />
                                    <label for="isExpired">{{ selectedVessel.isExpired ? 'Yes' : 'No' }}</label>
                                </td>
                            </tr>
                            <tr>
                                <th>IS PRIVATE?</th>
                                <td>
                                    <input class="is-checkradio" type="checkbox" id="isPrivate" v-model="selectedVessel.isPrivate" />
                                    <label for="isPrivate">{{ selectedVessel.isPrivate ? 'Yes' : 'No' }}</label>
                                </td>
                            </tr>
                            <tr>
                                <th>NEXT CHARGE $:</th>
                                <td>{{ selectedVessel.nextVesselCharge }}</td>
                            </tr>
                            <tr>
                                <th>PLANS:</th>
                                <td>
                                    <span v-if="selectedVessel.aaaPlans">{{ selectedVessel.aaaPlans.join(', ') }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>FLEET VESSEL?</th>
                                <td>
                                    <input class="is-checkradio" type="checkbox" id="isCompanyVessel" v-model="selectedVessel.isCompanyVessel" />
                                    <label for="isCompanyVessel">{{ selectedVessel.isCompanyVessel ? 'Yes' : 'No' }}</label>
                                </td>
                            </tr>
                            <tr>
                                <th>IS PAID FOR?</th>
                                <td>
                                    <input class="is-checkradio" type="checkbox" id="isPaidFor" v-model="selectedVessel.isPaidFor" />
                                    <label for="isPaidFor">{{ selectedVessel.isPaidFor ? 'Yes' : 'No' }}</label>
                                </td>
                            </tr>
                            <tr>
                                <th>TAKES PASSENGERS?</th>
                                <td>
                                    <input class="is-checkradio" type="checkbox" id="takesPassengers" v-model="selectedVessel.takesPassengers" />
                                    <label for="takesPassengers">{{ selectedVessel.takesPassengers ? 'Yes' : 'No' }}</label>
                                </td>
                            </tr>
                            <tr>
                                <th>IS FREEBIE</th>
                                <td>
                                    <input class="is-checkradio" type="checkbox" id="isFreebie" v-model="selectedVessel.isFreebie" />
                                    <label for="isFreebie">{{ selectedVessel.isFreebie ? 'Yes' : 'No' }}</label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="vesselSelected" class="actions">
                    <div class="buttons centered">
                        <div class="button is-primary is-medium" @click="saveVesselChanges()">Save Changes</div>
                    </div>

                    <div class="spacer s30"></div>

                    <div class="buttons">
                        <div class="button is-info" @click="showUserSearchSwap = true">Change Vessel Owner</div>
                        <div class="button is-light">Add Notes</div>
                    </div>
                </div>

                <div v-if="showUserSearchSwap" class="user-search">
                    <div class="spacer s30"></div>
                    <div v-if="!isSelected" class="notification is-primary">
                        <input
                            class="input"
                            v-model="findName"
                            id="filtername"
                            autocomplete="off"
                            type="text"
                            placeholder="Start typing user name..."
                        />
                    </div>
                    <div class="buttons">
                        <span v-if="findName != ''" class="button is-danger" @click="clearUser()">Clear User</span>
                    </div>
                    <div v-if="findName.length > 2 && !isSelected">
                        <div v-for="(user, index) in filteredNames" :key="index">
                            <div class="buttons results">
                                <span class="button is-light" @click="selectUser(user)">{{ user.username }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {{ selectedUser }}
            </div>
            <!-- // update tab ================= -->

            <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showCodeModal }">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Result</p>
                        <button class="delete" aria-label="close" @click.prevent="close"></button>
                    </header>
                    <section class="modal-card-body">
                        <div class="modal-result">
                            {{ modalResult }}
                        </div>

                        <div v-if="modalError" class="red">
                            <p>Error: {{ modalResult }}</p>
                        </div>
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button is-primary" @click="jobDone()">Job Done</button>
                        <!-- <button class="button is-cancel" @click="close">Abort</button> -->
                    </footer>
                </div>
            </div>

            <!-- <div class="posi">{{finalCodes}}<br>{{codeGroups}}</div> -->
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as moment from 'moment';
import NProgress from 'nprogress';

export default {
    mounted() {
        // this.$store.dispatch('setUser');
        // this.$store.dispatch('SETSUBSCRIPTIONINFO');
        this.today = new Date();
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },
    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        filteredNames() {
            let filter = new RegExp(this.findName, 'i');
            return this.users.filter(user => user.username.match(filter));
        },
        showSave() {
            return this.newVessel.vesselName == '' || this.newVessel.vesselUVI == '' || this.newVessel.homePort == '';
        },

        filteredVessels() {
            return this.hideExpired ? this.allVessels.filter(vessel => !vessel.isExpired).map(vess => vess) : this.allVessels;
        },
        filteredVesselNames() {
            let filter = new RegExp(this.findVessel, 'i');
            return this.filteredVessels.filter(vessel => vessel.vesselName.match(filter));
        },

        selectedStateCodes() {
            return this.selectedStates.map(item => item.code);
        },
        selectedStateNames() {
            return this.selectedStates.map(item => item.name);
        },

        selectedIndustryCodes() {
            return this.selectedIndustries.map(item => item.code);
        },
        selectedIndustryNames() {
            return this.selectedIndustries.map(item => item.name);
        },

        selectedClassCodes() {
            return this.selectedClasses.map(item => item.code);
        },
        selectedClassNames() {
            return this.selectedClasses.map(item => item.name);
        },

        selectedAreaCodes() {
            return this.selectedAreas.map(item => item.code);
        },
        selectedAreaNames() {
            return this.selectedAreas.map(item => item.name);
        },
        selectedAnswerCodes() {
            return this.selectedAnswers.map(item => item.code);
        },
        selectedAnswerNames() {
            return this.selectedAnswers.map(item => item.name);
        },

        selectedCodes() {
            return [
                ...['base'],
                ...this.selectedAnswerCodes,
                ...this.selectedClassCodes,
                ...this.selectedAreaCodes,
                ...this.selectedIndustryCodes,
                ...this.selectedStateCodes
            ];
        },
        finalCodes() {
            return [...new Set(this.selectedCodes)];
        },

        gBasis() {
            return this.groupBasis.some(item => this.finalCodes.includes(item)) ? ['g-basis'] : [];
        },
        gRisky() {
            return this.groupRisky.some(item => this.finalCodes.includes(item)) ? ['g-risky'] : [];
        },
        gHeavy() {
            return this.groupHeavy.some(item => this.finalCodes.includes(item)) ? ['g-heavy'] : [];
        },
        gTravs() {
            return this.groupTravs.some(item => this.finalCodes.includes(item)) ? ['g-travs'] : [];
        },
        gHirer() {
            return this.groupHirer.some(item => this.finalCodes.includes(item)) ? ['g-hirer'] : [];
        },
        gDiver() {
            return this.groupDiver.some(item => this.finalCodes.includes(item)) ? ['g-diver'] : [];
        },
        gFishy() {
            return this.groupFishy.some(item => this.finalCodes.includes(item)) ? ['g-fishy'] : [];
        },
        gMotor() {
            return this.groupMotor.some(item => this.finalCodes.includes(item)) ? ['g-motor'] : [];
        },
        gAlone() {
            return this.groupAlone.some(item => this.finalCodes.includes(item)) ? ['g-alone'] : [];
        },
        gSleep() {
            return this.groupSleep.some(item => this.finalCodes.includes(item)) ? ['g-sleep'] : [];
        },

        codeGroups() {
            return [
                ...this.gBasis,
                ...this.gRisky,
                ...this.gHeavy,
                ...this.gTravs,
                ...this.gHirer,
                ...this.gDiver,
                ...this.gFishy,
                ...this.gMotor,
                ...this.gAlone,
                ...this.gSleep
            ];
        }
    },

    data() {
        return {
            today: '',
            users: [],
            isSelected: false,
            findName: '',
            findVessel: '',
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            selectedUser: '',
            newVessel: {
                vesselName: '',
                vesselUVI: '',
                nextCharge: 149,
                isCompanyVessel: false,
                takesPassengers: false,
                homePort: '',
                description: '',
                vesselMaster: '',
                vesselCrew: '',
                appCrewExplanation: '',
                shoreContactName: '',
                shoreContactNumber: ''
            },
            tab: 'add',

            allstates: [
                { id: 's1', name: 'Tasmania', checked: false, code: 'base' }, //make
                { id: 's2', name: 'Victoria', checked: false, code: 'base' }, //deep
                { id: 's3', name: 'New South Wales', checked: false, code: 'base' }, //just
                { id: 's4', name: 'Queensland', checked: false, code: 'base' }, //wear
                { id: 's5', name: 'South Australia', checked: false, code: 'base' }, //snap
                { id: 's6', name: 'Western Australia', checked: false, code: 'base' }, //dash
                { id: 's7', name: 'Northern Territory', checked: false, code: 'base' } //slip
            ],
            selectedStates: [],
            industries: [
                { id: 'i1', name: 'Fishing', checked: false, code: 'keep' },
                { id: 'i2', name: 'Aquaculture', checked: false, code: 'wrap' },
                { id: 'i3', name: 'Commercial Diving', checked: false, code: 'lump' },
                { id: 'i4', name: 'Marine Construction', checked: false, code: 'corn' },
                { id: 'i5', name: 'Charter Vessel', checked: false, code: 'wood' },
                { id: 'i6', name: 'Passenger Vessel', checked: false, code: 'pest' },
                { id: 'i7', name: 'Other', checked: false, code: 'base' } //gate
            ],
            selectedIndustries: [],
            classes: [
                { id: 'c1', name: '1 - Passenger vessel (13 or more passengers)', code: 'tent' },
                { id: 'c2', name: '2 - Non-passenger vessel (up to 12 passengers)', code: 'wall' },
                { id: 'c3', name: '3 - Fishing vessel', code: 'firm' },
                { id: 'c4', name: '4 - Hire and drive vessel used by the hirer only for recreational purposes', code: 'aunt' }
            ],
            selectedClasses: [],
            areas: [
                { id: 'A', name: 'Unlimited domestic operations', code: 'burp' },
                { id: 'B', name: 'B - Offshore operations (within 200 nautical miles)', code: 'news' },
                { id: 'Be', name: 'B (extended) - Offshore operations (beyond 200 nautical miles)', code: 'lazy' },
                { id: 'C', name: 'C - Offshore operations (within 30 nautical miles)', code: 'grow' },
                { id: 'Cr', name: 'C (restricted) - Offshore operations - specified areas only', code: 'club' },
                { id: 'D', name: 'D - Partially smooth water operations', code: 'bank' },
                { id: 'E', name: 'E - Smooth water operations', code: 'step' }
            ],
            selectedAreas: [],
            questions: [
                { id: 'Q1', checked: false, name: 'This vessel takes paying passengers', code: 'flex' },
                { id: 'Q2', checked: false, name: 'This vessel is operated single-handedly', code: 'solo' },
                { id: 'Q3', checked: false, name: 'Diving operations are carried out from this vessel', code: 'vote' },
                { id: 'Q4', checked: false, name: 'This is a dry hire or hire-drive vessel', code: 'toss' },
                { id: 'Q5', checked: false, name: 'This vessel has a crane or other lifting equipment', code: 'pair' },
                { id: 'Q6', checked: false, name: 'This vessel has an engine room', code: 'boom' },
                { id: 'Q7', checked: false, name: 'This vessel has a cabin or wheelhouse', code: 'raid' },
                { id: 'Q8', checked: false, name: 'This vessel is has sleeping accommodation', code: 'slab' },
                { id: 'Q9', checked: false, name: 'This operation requires overnight trips', code: 'zone' },
                { id: 'Q10', checked: false, name: 'This vessel is occasionally out of phone range', code: 'chop' },
                { id: 'Q11', checked: false, name: 'This vessel navigates busy traffic routes', code: 'lift' },
                { id: 'Q12', checked: false, name: 'This vessel can be launched from a trailer', code: 'pipe' },
                { id: 'Q13', checked: false, name: 'This carries one or more tender vessels', code: 'snow' }
            ],
            selectedAnswers: [],

            groupBasis: ['base'],
            groupRisky: ['keep', 'burp', 'news', 'lazy', 'grow', 'club', 'chop', 'lift'],
            groupHeavy: ['corn', 'pair'],
            groupTravs: ['wood', 'pest', 'tent', 'flex', 'toss'],
            groupHirer: ['aunt', 'toss', 'pest'],
            groupDiver: ['lump', 'vote'],
            groupFishy: ['keep', 'wrap', 'firm'],
            groupMotor: ['boom'],
            groupAlone: ['solo'],
            groupSleep: ['raid', 'slab', 'zone', 'chop', 'lift'],

            newVesselID: '',
            addFasttrack: false,

            allPlans: ['logs', 'sms', 'pro', 'ops'],
            showUserSearch: false,
            showUserSearchSwap: false,
            isTeamUser: true,
            teamUser: {
                userid: 'rWlzr3viZohnyspfellhe5XPQ4J3',
                username: 'OffshoreSMS Team'
            },
            isDemo: true,
            isPaidfor: false,
            showExtras: false,
            isPrivate: false,
            lastVesselCharge: 0,
            nextVesselCharge: 249,
            isCompanyVessel: false,
            takesPassengers: true,
            aaaPlans: ['logs', 'sms', 'pro'],
            daysLeft: 90,

            testDate: '',
            showCodeModal: false,
            modalResult: '',
            modalError: false,
            newVesselCode: '',
            newDocID: '',

            searchByCode: false,
            enteredCode: '',
            foundVessel: '',
            selectedVessel: '',
            findMessage: '',
            showLoadVessels: true,

            allVessels: [],
            vesselSelected: false,

            cloneVessels: [
                { vesselID: 'jV0LeVwhQUjzNiEbryoQ', vesselName: 'Fishing Vessel (Non Trailerable)' },
                { vesselID: '74D17ourQXoE6fgiqi5O', vesselName: 'Marine Construction' },
                { vesselID: '2mwyvBMPoDFbmvD7ARse', vesselName: 'Non-Propelled Barge' },
                { vesselID: 'S58UWEqPY6waWPYj2ubp', vesselName: 'Charter Vessel' },
                { vesselID: 'tVqca9teMgBjT7RUizvQ', vesselName: 'Fishing Vessel (Trailerable)' },
                { vesselID: 'wqPZlKIvpPtV2w7aiYOz', vesselName: 'Hire and Drive' }
            ],
            selectedCloneVessel: 'none'
        };
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                this.firebaseemail = user.email;
                this.firebaseid = user.uid;
                this.firebasedisplayname = this.userData.username;
                this.loadUsers();
            }
        },

        showLoadUsers() {
            this.loadUsers();
            this.showUserSearch = true;
            this.enteredCode = '';
            this.findMessage = '';
        },

        clickToCopy(content) {
            // content.select();
            // content.setSelectionRange(0, 99999); /* For mobile devices */

            /* Copy the text inside the text field */
            navigator.clipboard.writeText(content);

            // Legacy API
            // this.$refs[ref].focus();
            // document.execCommand(content);

            // var Url = this.$refs[ref];
            // console.log(Url);
            // Url.select();
            // document.execCommand(content);

            /* Alert the copied text */
            alert('Copied the text: ' + content);
        },

        loadUsers: function() {
            let self = this;
            db.collection('userdata')
                .orderBy('signUpDate', 'desc')
                .onSnapshot(function(querySnapshot) {
                    self.users = [];
                    querySnapshot.forEach(function(doc) {
                        // self.users.push(doc.data())
                        self.users.push({
                            userid: doc.id,
                            signUpDate: doc.data().signUpDate,
                            userstate: doc.data().userstate,
                            industry: doc.data().industry,
                            userrole: doc.data().userrole,
                            userGroup: doc.data().userGroup ? doc.data().userGroup : '',
                            username: doc.data().username,
                            useremail: doc.data().useremail,
                            userphone: doc.data().userphone,
                            favouriteVesselID: doc.data().favouriteVesselID ? doc.data().favouriteVesselID : '',
                            signupPlatform: doc.data().signupPlatform ? doc.data().signupPlatform : 'not set',
                            favouriteFixed: doc.data().favouriteFixed ? doc.data().favouriteFixed : false
                            // profilingInfo: doc.data().profilingInfo ? doc.data().profilingInfo : '',
                        });
                    });
                });
        },

        loadVessels() {
            NProgress.start();
            this.showLoadVessels = false;
            this.clearCode();
            this.findMessage = 'Loading all vessels..';
            let self = this;
            db.collection('vessels')
                .orderBy('vesselOwnerName', 'asc')
                .onSnapshot(function(querySnapshot) {
                    self.allVessels = [];
                    querySnapshot.forEach(doc => {
                        self.allVessels.push({
                            vesselID: doc.id,
                            vesselUVI: doc.data().vesselUVI ? doc.data().vesselUVI : '',
                            vesselName: doc.data().vesselName,
                            vesselOwnerName: doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?',
                            vesselOwnerID: doc.data().vesselOwnerID
                        });
                    });
                });
            setTimeout(() => {
                self.findMessage = '';
                NProgress.done();
            }, 1000);
        },

        selectVessel(vessel) {
            let self = this;
            // this.selectedVessel = vessel;
            this.loadVesselData(vessel.vesselID);
            this.vesselSelected = true;
            this.findMessage = '';
            this.enteredCode = '';
        },

        isNumber: function($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        async loadVesselData(vesselID) {
            let self = this;
            await db
                .collection('vessels')
                .doc(vesselID)
                .onSnapshot(doc => {
                    let data = doc.data();
                    data.vesselID = doc.id;
                    self.selectedVessel = data;
                });
        },

        clearCode() {
            this.selectedVessel = '';
            this.findMessage = '';
            this.foundVessel = '';
            this.enteredCode = '';
            this.vesselSelected = false;
            this.findVessel = '';
        },

        async findVesselFromCode() {
            let self = this;
            this.selectedVessel = '';
            this.vesselSelected = false;
            this.findMessage = 'Looking up code..';
            this.foundVessel = '';
            this.findVessel = '';
            NProgress.start();
            db.collection('vesselManualCodes')
                .doc(this.enteredCode)
                .get()
                .then(async doc => {
                    if (doc.exists) {
                        self.foundVessel = doc.data();
                        self.findMessage = 'Vessel ' + self.foundVessel.vesselName + ' located. Loading data..';
                        await self.loadVesselData(self.foundVessel.vesselID);
                        self.vesselSelected = true;
                        self.findMessage = '';
                        NProgress.done();
                    } else {
                        self.vesselSelected = false;
                        self.findMessage = 'No vessel found';
                        NProgress.done();
                    }
                });
        },

        cleanPhone(number) {
            return '0' + number.slice(3, 12);
        },

        selectUser(user) {
            console.log(user);
            this.isSelected = true;
            this.isTeamUser = false;
            this.selectedUser = user;
        },

        async saveVesselChanges() {
            console.log(this.selectedVessel);
            NProgress.start();
            await db
                .collection('vessels')
                .doc(this.selectedVessel.vesselID)
                .update(this.selectedVessel);
            NProgress.done();
        },

        stopProgress() {
            NProgress.done();
        },

        clearUser: function() {
            this.findName = '';
            this.isSelected = false;
            this.selectedUser = '';
            this.showUserSearch = false;
            this.showUserSearchSwap = false;
            this.isTeamUser = true;
            this.newVessel = {
                vesselName: '',
                vesselUVI: '',
                nextCharge: 149,
                isCompanyVessel: false,
                takesPassengers: false,
                homePort: '',
                description: '',
                vesselMaster: '',
                vesselCrew: '',
                appCrewExplanation: '',
                shoreContactName: '',
                shoreContactNumber: ''
            };
        },

        // tester() {
        //   // this.testDate = this.today;
        //   // const today = new Date();
        //   // const tomorrow = new Date();
        //   // console.log(today);
        //   // console.log(tomorrow);
        //   // this.testDate = tomorrow.setDate(today.getDate() + 10);
        //   //
        //   // this.testDate = new Date().setDate( new Date().getDate() + 3);
        //   //
        //   const t = this.daysLeft;
        //   const d = new Date();
        //   console.log(d);
        //   // const t = 13;
        //   d.setDate(d.getDate() + t);
        //   this.testDate = d;
        // },

        jobDone() {
            this.showCodeModal = false;
            this.clearUser();
            this.$router.back();
        },

        close() {},

        async getCode() {
            let self = this;
            let result = false;
            await db
                .collection('vesselManualCodesPool')
                .limit(3)
                .get()
                .then(snap => {
                    self.newVesselCode = '';
                    let index = 0;
                    snap.forEach(doc => {
                        console.log('Index', index);
                        if (index == 0) {
                            self.newVesselCode = doc.id;
                            console.log('New vessel code found:', self.newVesselCode);
                        }
                        index++;
                    });
                })
                .then(() => {
                    db.collection('vesselManualCodes')
                        .doc(self.newVesselCode)
                        .set({
                            manualCode: self.newVesselCode,
                            vesselID: self.newDocID,
                            vesselName: self.newVessel.vesselName
                        });
                    db.collection('vessels')
                        .doc(self.newDocID)
                        .update({
                            vesselManualCode: self.newVesselCode
                        });
                    db.collection('vesselManualCodesPool')
                        .doc(self.newVesselCode)
                        .delete();
                    result = true;
                })
                .catch(err => {
                    console.log('Error', err);
                    self.modalResult = 'ERROR GETTING CODE';
                    self.modalError = true;
                    self.showCodeModal = true;
                    NProgress.done();
                    result = false;
                });
            return result;
        },

        addVessel() {
            let self = this;

            let slug = this.isTeamUser ? 'OffshoreSMS Team' : this.selectedUser.username;
            let message = {
                title: 'Add a vessel to ' + slug + '?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    let t1 = new Date();
                    let t2 = new Date();
                    t2.setDate(t2.getDate() + 90);
                    let userID = self.isTeamUser ? self.teamUser.userid : self.selectedUser.userid;

                    let newVesselData = {
                        vesselOwnerID: userID,
                        vesselName: self.newVessel.vesselName,
                        vesselUVI: self.newVessel.vesselUVI,
                        allLoadedOnSave: false,
                        vesselOwnerName: self.isTeamUser ? self.teamUser.username : self.selectedUser.username,
                        isDemo: self.isDemo,
                        isPaidFor: self.isPaidfor,
                        isPrivate: self.isPrivate,
                        isFreebie: true, // needed?
                        // dateAdded: t1.getTime(),
                        // overviewSavedAt: t1.getTime(),
                        // nextChargeDate: t2.getTime(),
                        dateAdded: t1,
                        overviewSavedAt: t1,
                        nextChargeDate: t2,
                        savedAppInfoSections: ['Vessel Information', 'Visitor Check-in', 'Safety Information', 'General Safety Briefing'],
                        lastVesselCharge: parseInt(self.lastVesselCharge),
                        vesselOwnerPhone: '',
                        vesselOwnerEmail: '',
                        nextVesselCharge: parseInt(self.nextVesselCharge),
                        isCompanyVessel: self.isCompanyVessel,
                        takesPassengers: self.takesPassengers,
                        homePort: self.newVessel.homePort,
                        description: '',
                        vesselMaster: '',
                        vesselCrew: '',
                        appCrewExplanation: '',
                        shoreContactName: '',
                        shoreContactNumber: '',
                        vesselStates: [],
                        vesselIndustries: [],
                        surveyClasses: [],
                        operationalAreas: [],
                        fasttrackAnswers: [],
                        codes: self.finalCodes,
                        codeGroups: self.codeGroups,
                        aaaPlans: self.aaaPlans
                    };

                    db.collection('vessels')
                        .add(newVesselData)
                        .then(async doc => {
                            self.newDocID = doc.id;
                            let hasCode = await self.getCode();
                            console.log('hasCode: ', hasCode);
                            if (hasCode) {
                                self.modalResult = self.newVesselCode;
                                self.modalError = false;
                                self.showCodeModal = true;
                                // send text
                                var sendTwilioSMS = firebase.functions().httpsCallable('sendTwilioSMS');
                                sendTwilioSMS({
                                    toNumber: '+61409709596',
                                    message:
                                        'New Quick Setup vessel called ' +
                                        self.newVessel.vesselName +
                                        ' added with 6 digit QR code number: ' +
                                        self.newVesselCode,
                                    revisionDescription: 'OffshoreSMS Admin has created a new quick setup vessel called ' + self.newVessel.vesselName
                                });
                                NProgress.done();
                            } else {
                                self.modalResult = 'CODE FAILURE!';
                                self.modalError = true;
                                self.showCodeModal = true;
                                NProgress.done();
                            }
                            db.collection('userdata')
                                .doc(userID)
                                .update({
                                    myVessels: firebase.firestore.FieldValue.arrayUnion({
                                        vesselName: self.newVessel.vesselName,
                                        vesselID: self.newDocID,
                                        vesselUVI: self.newVessel.vesselUVI
                                    })
                                });
                            // fast track
                            if (self.selectedCloneVessel != 'none') {
                                var adminMigrateSMS = firebase.functions().httpsCallable('adminMigrateSMS');
                                adminMigrateSMS({
                                    sourceVessel: self.selectedCloneVessel.vesselID,
                                    targetVessel: self.newDocID
                                });
                            }
                        })
                        .catch(error => {
                            console.error('Error adding vessel: ', error);
                            self.modalResult = 'ERROR ADDING VESSEL';
                            self.modalError = true;
                            self.showCodeModal = true;
                            NProgress.done();
                        });

                    // show modal with new code
                    // fasttrack from list??
                    // send text to steve

                    // let cloudData = {
                    //   newVesselData: newVesselData,
                    //   dateAdded: t1.getTime(),
                    //   overviewSavedAt: t1.getTime(),
                    //   nextChargeDate: t2.getTime(),
                    //   userID: self.isTeamUser ? self.teamUser.userid : self.selectedUser.userid,
                    //   vesselName: self.newVessel.vesselName,
                    //   vesselUVI: self.newVessel.vesselUVI,
                    //   saveFastTrack: self.addFasttrack,
                    // }

                    // var adminAddVesselToUser = firebase.functions().httpsCallable("adminAddVesselToUser");
                    // adminAddVesselToUser(cloudData)
                    // .then(async function (result) {
                    //   let hasCode = await self.getCode();
                    //   // send text with code to steve
                    //   self.clearUser();
                    //   self.showCodeModal = true;
                    //   NProgress.done();
                    // })
                    // .catch(function (error) {
                    //   console.error("Error adding vessel: ", error);
                    //   NProgress.done();
                    // });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.highlight {
    // background: cadetblue;
    background: #f4f4f4;
    padding: 1.3rem;
    // color: #fff;
    border-radius: 5px;
    table {
        th {
            padding: 8px;
        }
        td {
            padding: 8px;
            &.copy {
                cursor: pointer;
                &:hover {
                    background: #eee;
                }
            }
        }
    }
}
.posi {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: #fff;
    z-index: 999;
}

.tabs {
    ul {
        li {
            margin: 0 2px;
            a {
                span {
                    margin-left: 10px;
                }
            }
            &.active {
                background-color: whitesmoke;
                border-bottom-color: #dbdbdb;
            }
        }
    }
}

.modal-result {
    text-align: center;
    margin: 1rem auto;
    font-size: 2rem;
    color: mediumseagreen;
    letter-spacing: 2px;
}

.extra-info {
    background: #fafafa;
    padding: 2rem;
    margin-bottom: 3rem;
}

label {
    &.label {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 10px;
        &.tight {
            margin-bottom: 4px;
        }
    }
}
.buttons.results {
    margin-bottom: 4px;
    .button {
        margin-bottom: 10px;
        height: auto;
        padding: 12px;
    }
}
</style>
