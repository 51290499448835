<template>

  <div>               

    <div class="container">

      {{sentUser}}

      <h3>Update {{userName ? userName + '\'s' : 'a user'}} phone number</h3>
      <input class="input" type="text" placeholder="Paste User ID" v-model="userid" />

      <div v-if="userid != ''">


        <input class="input" type="text" placeholder="+61 mobile number" v-model="updatedPhone" />

        <div class="buttons">

          <span class="button is-info" @click="getPhone()">Get Phone Number</span>

          <span :disabled="showButton" class="button is-primary" @click="updatePhoneNumber()">Update Number</span>

        </div>

        <hr>

        <p>{{errMsg}}</p><br>

      </div>

        <span class="button is-cancel" @click="clear()">Clear</span>



    </div>

  </div>

</template>



<script>

import firebase from 'firebase/compat/app';
import { db } from '../main.js';
//import 'firebase/compat/auth'; 
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';

export default {

  name: 'UpdatePhoneNumber',

  data: function() {
    return {
      userid: '',
      updatedPhone: '',
      userName: '',
      errMsg: '',
    }
  },

  props: {
      sentUser: Object,
  },

  mounted() {
    if (this.sentUser) {
      this.userid = this.sentUser.userid;
    }
  },

  computed: {
    showButton() {
      return this.updatedPhone == '';
    }
  },

  methods: {

    clear() {
      this.errMsg = '';
      this.userid = '';
      this.updatedPhone = '';
      this.userName = '';
    },

    getPhone() {
      let self = this;
      NProgress.start();
      db.collection('userdata').doc(this.userid).get().then((doc) => {
        if (doc.exists) {
          self.userName = doc.data().username ? doc.data().username : 'no numbername';
          self.updatedPhone = doc.data().userphone ? doc.data().userphone : 'no number';
          self.errMsg = '';
        } else {
          self.errMsg = 'No number found.';
        }
        NProgress.done();
      })
      .catch((err) => {
        NProgress.done();
      });
    },

    updatePhoneNumber() {
      let self = this;
      let updateArray = {
        phone: self.updatedPhone,
      }
      console.log(self.userid,updateArray);

      var AdminUpdateUserPhone = firebase.functions().httpsCallable('AdminUpdateUserPhone');
      AdminUpdateUserPhone({
          userID: self.userid,
          updateArray: updateArray
      })
      .then(function(result) {
        var sanitizedMessage = result.data.text;
        console.log('Result',sanitizedMessage);
        console.log('Message',result.data.message);

      })
      .catch(function(error) {
        console.log('Error',error.text);
      }); 


    },


    },

 

}


</script>

<style lang="scss" scoped>

  input[type="text"] {
    padding: 30px;
    margin-bottom: 1rem;
  }
  h3 {
    margin: 1rem 0;
  }

</style>