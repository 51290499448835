<template>
    <div class="site-wrapper no-header">
        <div class="container">
            <div class="notification is-info"><h3>Add an Existing User to a Fleet as Admin</h3></div>

            <div v-if="!isSelected" class="notification is-primary">
                <input class="input" v-model="findName" id="filtername" autocomplete="off" type="text" placeholder="Start typing Fleet name..." />
            </div>

            <div v-if="!isSelected" class="buttons">
                <span v-if="findName != ''" class="button is-warning" @click="clearFleet()">Clear</span>
            </div>

            <div v-if="isSelected" class="highlight">{{ selectedFleet.companyName }}</div>

            <div v-if="findName.length > 2 && !isSelected">
                <div v-for="(fleet, index) in filteredNames" :key="index">
                    <div class="buttons results">
                        <span class="button is-light" @click="selectFleet(fleet)"
                            >{{ fleet.companyName }} ({{ fleet.primaryUserName }}) / {{ fleet.fleetid }}</span
                        >
                    </div>
                </div>
            </div>

            <div v-if="isSelected" class="new-vessel-info">
                <div v-if="!userSelected" class="notification is-primary">
                    <input class="input" v-model="findUser" id="filtername" autocomplete="off" type="text" placeholder="Start typing user name..." />
                </div>

                <div v-if="userSelected" class="highlight">{{ selectedUser.username }}</div>

                <div v-if="findUser.length > 2 && !userSelected">
                    <div v-for="(user, index) in filteredUsers" :key="index">
                        <div class="buttons results">
                            <span class="button is-light" @click="selectUser(user)">{{ user.username }}</span>
                        </div>
                    </div>
                </div>

                <div class="buttons">
                    <span v-if="findUser != ''" class="button is-warning" @click="clearUser()">Clear</span>
                </div>

                <div class="buttons">
                    <button :disabled="showSave" class="button is-primary is-medium" @click="addUserToFleet()">
                        Add {{ selectedUser.username }} to {{ selectedFleet.companyName }}
                    </button>
                </div>
            </div>

            <!-- <div class="posi">{{finalCodes}}<br>{{codeGroups}}</div> -->
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as moment from 'moment';
import NProgress from 'nprogress';

export default {
    name: 'AdminAddFleetVessel',

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        filteredNames() {
            let filter = new RegExp(this.findName, 'i');
            return this.fleets.filter(fleet => fleet.companyName.match(filter));
        },
        filteredUsers() {
            let filter = new RegExp(this.findUser, 'i');
            return this.users.filter(user => user.username.match(filter));
        },
        showSave() {
            return false;
        }
    },

    data() {
        return {
            fleets: [],
            isSelected: false,
            findName: '',
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            selectedFleet: '',

            users: [],
            findUser: '',
            userSelected: false,
            selectedUser: '',

            newVesselID: '',
            addFasttrack: false
        };
    },

    mounted() {
        // this.$store.dispatch('setUser');
        // this.$store.dispatch('SETSUBSCRIPTIONINFO');
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                this.firebaseemail = user.email;
                this.firebaseid = user.uid;
                this.firebasedisplayname = this.userData.companyName;
                this.loadFleets();
                this.loadUsers();
            }
        },

        loadFleets: function() {
            let self = this;
            db.collection('companies').onSnapshot(querySnapshot => {
                self.fleets = [];
                querySnapshot.forEach(function(doc) {
                    // self.fleets.push(doc.data())
                    let expired = doc.data().isExpired ? doc.data().isExpired : false;
                    if (!expired)
                        self.fleets.push({
                            fleetid: doc.id,
                            // signUpDate: doc.data().signUpDate,
                            companyState: doc.data().companyState ? doc.data().companyState : '',
                            // industry: doc.data().industry,
                            // userrole: doc.data().userrole,
                            // userGroup: doc.data().userGroup ? doc.data().userGroup : '',
                            companyName: doc.data().companyName,
                            primaryUserName: doc.data().primaryUserName ? doc.data().primaryUserName : '',
                            primaryUserPhone: doc.data().primaryUserPhone ? doc.data().primaryUserPhone : '',
                            primaryUserEmail: doc.data().primaryUserEmail ? doc.data().primaryUserEmail : '',
                            avatarUrl: doc.data().avatarUrl ? doc.data().avatarUrl : ''
                            // useremail: doc.data().useremail,
                            // userphone: doc.data().userphone,
                            // favouriteVesselID: doc.data().favouriteVesselID ? doc.data().favouriteVesselID : '',
                            // signupPlatform: doc.data().signupPlatform ? doc.data().signupPlatform : 'not set',
                            // favouriteFixed: doc.data().favouriteFixed ? doc.data().favouriteFixed : false,
                            // profilingInfo: doc.data().profilingInfo ? doc.data().profilingInfo : '',
                        });
                });
            });
        },

        loadUsers: function() {
            let self = this;
            db.collection('userdata')
                .orderBy('signUpDate', 'desc')
                .onSnapshot(function(querySnapshot) {
                    self.users = [];
                    querySnapshot.forEach(function(doc) {
                        // self.users.push(doc.data())
                        self.users.push({
                            userid: doc.id,
                            signUpDate: doc.data().signUpDate,
                            userstate: doc.data().userstate,
                            industry: doc.data().industry,
                            userrole: doc.data().userrole,
                            userGroup: doc.data().userGroup ? doc.data().userGroup : '',
                            username: doc.data().username,
                            useremail: doc.data().useremail,
                            userphone: doc.data().userphone,
                            favouriteVesselID: doc.data().favouriteVesselID ? doc.data().favouriteVesselID : '',
                            signupPlatform: doc.data().signupPlatform ? doc.data().signupPlatform : 'not set',
                            favouriteFixed: doc.data().favouriteFixed ? doc.data().favouriteFixed : false,
                            avatarUrl: doc.data().avatarUrl ? doc.data().avatarUrl : ''
                            // profilingInfo: doc.data().profilingInfo ? doc.data().profilingInfo : '',
                        });
                    });
                });
        },

        selectUser(user) {
            // console.log(user);
            this.userSelected = true;
            this.selectedUser = user;
        },

        clearUser: function() {
            this.findUser = '';
            this.userSelected = false;
            this.selectedUser = '';
            this.findName = '';
            this.isSelected = false;
            this.selectedFleet = '';
        },

        selectFleet(fleet) {
            // console.log(fleet);
            this.isSelected = true;
            this.selectedFleet = fleet;
        },

        clearFleet: function() {
            this.findName = '';
            this.isSelected = false;
            this.selectedFleet = '';
        },

        addUserToFleet() {
            console.log(this.selectedUser);
            console.log(this.selectedFleet);

            let fleetData = {
                fleetID: this.selectedFleet.fleetid,
                fleetName: this.selectedFleet.companyName,
                avatarUrl: this.selectedFleet.avatarUrl,
                roles: [],
                isOffline: false
            };
            let crewData = {
                crewID: this.selectedUser.userid,
                crewName: this.selectedUser.username,
                avatarUrl: this.selectedUser.avatarUrl,
                roles: [],
                isOffline: false
            };

            let self = this;
            let message;
            message = {
                title: 'Add ' + this.selectedUser.username + ' to ' + this.selectedFleet.companyName + '?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    var AdminAddUserToFleetAdmins = firebase.functions().httpsCallable('AdminAddUserToFleetAdmins');
                    AdminAddUserToFleetAdmins({
                        user: self.selectedUser,
                        fleet: self.selectedFleet,
                        fleetData: fleetData,
                        crewData: crewData
                    })
                        .then(function(result) {
                            // var sanitizedMessage = result.data.text;
                            // console.log('Result',sanitizedMessage);
                            console.log('Message', result.data.message);
                            NProgress.done();
                            self.clearUser();
                        })
                        .catch(function(error) {
                            console.log('Error', error.text);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.highlight {
    background: cadetblue;
    padding: 15px;
    color: #fff;
}
.posi {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: #fff;
    z-index: 999;
}
label {
    &.label {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 10px;
        &.tight {
            margin-bottom: 4px;
        }
    }
}
.buttons.results {
    margin-bottom: 4px;
}
</style>
