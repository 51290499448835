<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">Fix SMS Contacts</h3>
                <br />
                <!--    <div class="input-wrapper">
              <label>Source Collection Path (no trailing slashes)</label>
              <input class="input" type="text" placeholder="Paste source doc path" v-model="sourcePath">
            </div> -->

                <div v-if="!isSelected" class="notification is-primary">
                    <input
                        class="input"
                        v-model="findName"
                        id="filtername"
                        autocomplete="off"
                        type="text"
                        placeholder="Start typing vessel name..."
                    />
                </div>

                <div class="buttons">
                    <span v-if="findName != ''" class="button is-warning" @click="clearVessel()">Clear</span>
                </div>

                <hr />

                <div v-if="selectedVessel != ''" class="highlight">{{ selectedVessel.vesselName }} ({{ selectedVessel.vesselOwnerName }})</div>

                <div v-if="findName != '' && !isSelected">
                    <div v-for="(vessel, index) in filteredNames" :key="index">
                        <div class="buttons">
                            <span class="button is-light" @click="selectVessel(vessel)">{{ vessel.vesselName }} ({{ vessel.vesselOwnerName }})</span>
                        </div>
                    </div>
                </div>

                <div class="clear"></div>

                <div v-if="findName != '' && isSelected">
                    <h4 class="icon">Owner Responsibility Statement</h4>
                    <div class="clear"></div>

                    <div class="form-group-reg">
                        <!-- <label class="label">Vessel Owner Statement</label>  -->
                        <textarea class="textarea" type="text" placeholder="Vessel owner statement.." v-model="vesselInfo.ownerStatement"></textarea>
                        <div class="spacer s30"></div>
                        <label class="label">Vessel Owner Name</label>
                        <input class="input" type="text" placeholder="The vessel owner name" v-model="vesselInfo.vesselOwnerName" />
                    </div>
                    <div class="spacer s10"></div>

                    <!-- <h4 class="icon">Copy an Owner statement</h4>
              <div class="clear"></div> -->
                    <label class="label">Copy an Owner statement</label>

                    <div class="clear"></div>

                    <table v-if="pooledOwnerStatements.length" class="table is-bordered is-fullwidth">
                        <tr>
                            <th>Title</th>
                            <th>Copy?</th>
                        </tr>
                        <tr v-for="(item, index) in pooledOwnerStatements" :key="index">
                            <td>
                                <span class="show-details" @click="showDetails(item)">{{ item.title }}</span>
                            </td>
                            <td>
                                <span class="button is-info" @click="copyStatement('ownerStatement', item.details)">Copy</span>
                            </td>
                        </tr>
                    </table>

                    <div class="clear"></div>

                    <div class="spacer s80"></div>

                    <h4 class="icon">Master Responsibility Statement</h4>
                    <div class="clear"></div>

                    <div class="form-group-reg">
                        <!-- <label class="label">Vessel Master Statement</label>  -->
                        <textarea class="textarea" type="text" placeholder="Vessel master statement.." v-model="vesselInfo.masterStatement">
}</textarea
                        >
                        <div class="spacer s30"></div>
                        <label class="label">Vessel Master Name</label>
                        <input class="input" type="text" placeholder="The vessel master..." v-model="vesselInfo.vesselMaster" />
                    </div>
                    <div class="spacer s10"></div>

                    <!-- <h4 class="icon">Copy a Master statement</h4>
              <div class="clear"></div> -->
                    <label class="label">Copy a Master statement</label>

                    <div class="clear"></div>

                    <table v-if="pooledMasterStatements.length" class="table is-bordered is-fullwidth">
                        <tr>
                            <th>Title</th>
                            <th>Copy?</th>
                        </tr>
                        <tr v-for="(item, index) in pooledMasterStatements" :key="index">
                            <td>
                                <span class="show-details" @click="showDetails(item)">{{ item.title }}</span>
                            </td>
                            <td>
                                <span class="button is-info" @click="copyStatement('masterStatement', item.details)">Copy</span>
                            </td>
                        </tr>
                    </table>

                    <div class="clear"></div>
                    <div class="spacer s80"></div>

                    <h4 class="icon">Designated Person (DPO) Statement</h4>
                    <div class="clear"></div>

                    <div class="form-group-reg">
                        <!-- <label class="label">Designated Person Statement</label>  -->
                        <textarea
                            class="textarea"
                            type="text"
                            placeholder="Designated person ashore statement.."
                            v-model="vesselInfo.designatedStatement"
                        ></textarea>
                        <div class="spacer s30"></div>
                        <label class="label">Shore Contact Name</label>
                        <input class="input" type="text" placeholder="The vessel name" v-model="vesselInfo.shoreContactName" />
                    </div>
                    <div class="spacer s10"></div>

                    <!-- <h4 class="icon">Copy a DPO statement</h4>
              <div class="clear"></div> -->
                    <label class="label">Copy a DPO statement</label>

                    <div class="clear"></div>

                    <table v-if="pooledDesignatedStatements.length" class="table is-bordered is-fullwidth">
                        <tr>
                            <th>Title</th>
                            <th>Copy?</th>
                        </tr>
                        <tr v-for="(item, index) in pooledDesignatedStatements" :key="index">
                            <td>
                                <span class="show-details" @click="showDetails(item)">{{ item.title }}</span>
                            </td>
                            <td>
                                <span class="button is-info" @click="copyStatement('designatedStatement', item.details)">Copy</span>
                            </td>
                        </tr>
                    </table>

                    <div class="clear"></div>

                    <div class="spacer s80"></div>

                    <h4 class="icon">Line of Communication</h4>
                    <div class="clear"></div>

                    <div class="form-group-reg">
                        <!-- <label class="label">Line of Communication</label>  -->
                        <textarea
                            class="textarea"
                            type="text"
                            placeholder="Designated person ashore statement.."
                            v-model="vesselInfo.lineOfComms"
                        ></textarea>
                        <div class="spacer s10"></div>
                    </div>

                    <div class="spacer s10"></div>

                    <!-- <h4 class="icon">Copy Line of Comms</h4>
              <div class="clear"></div> -->
                    <label class="label">Copy Line of Comms</label>

                    <div class="clear"></div>

                    <table v-if="pooledLineOfComms.length" class="table is-bordered is-fullwidth">
                        <tr>
                            <th>Title</th>
                            <th>Copy?</th>
                        </tr>
                        <tr v-for="(item, index) in pooledLineOfComms" :key="index">
                            <td>
                                <span class="show-details" @click="showDetails(item)">{{ item.title }}</span>
                            </td>
                            <td>
                                <span class="button is-info" @click="copyStatement('lineOfComms', item.details)">Copy</span>
                            </td>
                        </tr>
                    </table>

                    <div class="clear"></div>

                    <div class="spacer s80"></div>
                </div>

                <div class="clear"></div>
                <div class="spacer s30"></div>

                <div class="buttons">
                    <span class="button is-info is-medium" @click="$router.go(-1)"><font-awesome-icon icon="chevron-left" /> &nbsp;Back</span>
                    <span class="button is-primary is-medium" @click="updateStatements()">Update</span>
                </div>
            </div>

            <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showDetailsModal }">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card">
                    <header class="modal-card-head is-info">
                        <p class="modal-card-title">
                            {{ selectedItem.title }}
                        </p>
                        <button class="delete" aria-label="close" @click.prevent="close"></button>
                    </header>
                    <section class="modal-card-body">
                        <div v-if="selectedItem != ''">
                            <div v-for="line in selectedItem.details.split('\n')" :key="line">{{ line }}<br /></div>
                        </div>
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button is-info" @click.prevent="close">Done</button>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {
    name: 'FixSmsStatements',
    components: {},
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            pooledOwnerStatements: [],
            pooledMasterStatements: [],
            pooledDesignatedStatements: [],
            pooledLineOfComms: [],
            sourcePath: '',
            targetPath: '',

            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            allVessels: [],
            isSelected: false,
            findName: '',
            hideExpired: true,
            selectedVessel: '',
            selectedItem: '',
            showDetailsModal: false,

            vesselInfo: {
                ownerStatement: '',
                masterStatement: '',
                designatedStatement: '',
                vesselOwnerName: '',
                vesselMaster: '',
                shoreContactName: '',
                lineOfComms: ''
            }
        };
    },

    computed: {
        userData() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.userData
                ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2'
                    ? true
                    : false
                : false;
        },
        pathsAreValid() {
            return this.sourcePath == '' || this.targetPath == '';
        },
        filteredVessels() {
            return this.hideExpired ? this.allVessels.filter(vessel => !vessel.isExpired).map(vess => vess) : this.allVessels;
        },
        filteredNames() {
            let filter = new RegExp(this.findName, 'i');
            return this.filteredVessels.filter(user => user.vesselName.match(filter));
        }
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                // console.log(user);
                this.loadAllVessels();
                this.displayOwnerStatements();
                this.displayMasterStatements();
                this.displayDesignatedStatements();
                this.displayLineOfComms();
            }
        },

        close: function() {
            this.showDetailsModal = false;
            this.selectedEditItem = { title: '', details: '', index: 0 };
        },

        showDetails(item) {
            this.selectedItem = item;
            this.showDetailsModal = true;
        },

        isNumber: function($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        updateStatements() {
            console.log(this.vesselInfo);
            let self = this;
            let savedAt = new Date();
            let message;
            message = {
                title: 'Confirm Update?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES, Update',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    db.collection('vessels')
                        .doc(self.selectedVessel.vesselID)
                        .update({
                            ownerStatement: self.vesselInfo.ownerStatement,
                            masterStatement: self.vesselInfo.masterStatement,
                            designatedStatement: self.vesselInfo.designatedStatement,
                            lineOfComms: self.vesselInfo.lineOfComms,
                            vesselOwnerName: self.vesselInfo.vesselOwnerName,
                            vesselMaster: self.vesselInfo.vesselMaster,
                            shoreContactName: self.vesselInfo.shoreContactName,
                            lineOfCommsAcceptedAt: savedAt,
                            ownerStatementSavedAt: savedAt,
                            masterStatementSavedAt: savedAt,
                            designatedStatementSavedAt: savedAt
                        })
                        .then(() => {
                            NProgress.done();
                            self.clearVessel();
                        })
                        .catch(err => {
                            console.log(err);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        displayOwnerStatements() {
            let self = this;
            NProgress.start();
            db.collection('pooledOwnerStatements')
                .get()
                .then(querySnapshot => {
                    self.pooledOwnerStatements = [];
                    querySnapshot.forEach(doc => {
                        self.pooledOwnerStatements.push({
                            id: doc.id,
                            title: doc.data().title ? doc.data().title : 'no title',
                            details: doc.data().details ? doc.data().details : 'no statement'
                        });
                    });
                })
                .then(() => {
                    NProgress.done();
                });
        },

        displayMasterStatements() {
            let self = this;
            NProgress.start();
            db.collection('pooledMasterStatements')
                .get()
                .then(querySnapshot => {
                    self.pooledMasterStatements = [];
                    querySnapshot.forEach(doc => {
                        self.pooledMasterStatements.push({
                            id: doc.id,
                            title: doc.data().title ? doc.data().title : 'no title',
                            details: doc.data().details ? doc.data().details : 'no statement'
                        });
                    });
                })
                .then(() => {
                    NProgress.done();
                });
        },

        displayDesignatedStatements() {
            let self = this;
            NProgress.start();
            db.collection('pooledDesignatedStatements')
                .get()
                .then(querySnapshot => {
                    self.pooledDesignatedStatements = [];
                    querySnapshot.forEach(doc => {
                        self.pooledDesignatedStatements.push({
                            id: doc.id,
                            title: doc.data().title ? doc.data().title : 'no title',
                            details: doc.data().details ? doc.data().details : 'no statement'
                        });
                    });
                })
                .then(() => {
                    NProgress.done();
                });
        },

        displayLineOfComms() {
            let self = this;
            NProgress.start();
            db.collection('pooledLineOfComms')
                .get()
                .then(querySnapshot => {
                    self.pooledLineOfComms = [];
                    querySnapshot.forEach(doc => {
                        self.pooledLineOfComms.push({
                            id: doc.id,
                            title: doc.data().title ? doc.data().title : 'no title',
                            details: doc.data().details ? doc.data().details : 'no statement'
                        });
                    });
                })
                .then(() => {
                    NProgress.done();
                });
        },

        clearVessel: function() {
            this.findName = '';
            this.isSelected = false;
            this.selectedVessel = '';
            this.vesselInfo = {
                ownerStatement: '',
                masterStatement: '',
                designatedStatement: '',
                vesselOwnerName: '',
                vesselMaster: '',
                shoreContactName: '',
                lineOfComms: ''
            };
        },

        loadVesselStatements() {
            let self = this;
            db.collection('vessels')
                .doc(this.selectedVessel.vesselID)
                .get()
                .then(doc => {
                    self.vesselInfo.ownerStatement = doc.data().ownerStatement ? doc.data().ownerStatement : '';
                    self.vesselInfo.masterStatement = doc.data().masterStatement ? doc.data().masterStatement : '';
                    self.vesselInfo.designatedStatement = doc.data().designatedStatement ? doc.data().designatedStatement : '';
                    self.vesselInfo.lineOfComms = doc.data().lineOfComms ? doc.data().lineOfComms : '';
                    self.vesselInfo.vesselOwnerName = doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '';
                    self.vesselInfo.vesselMaster = doc.data().vesselMaster ? doc.data().vesselMaster : '';
                    self.vesselInfo.shoreContactName = doc.data().shoreContactName ? doc.data().shoreContactName : '';
                    self.vesselInfo.lineOfComms = doc.data().lineOfComms ? doc.data().lineOfComms : '';
                })
                .then(() => {
                    NProgress.done();
                });
        },

        selectVessel(vessel) {
            let self = this;
            console.log(vessel);
            this.selectedVessel = vessel;
            this.isSelected = true;
            NProgress.start();
            setTimeout(() => {
                self.loadVesselStatements();
            }, 1000);
        },

        copyStatement(field, statement) {
            this.vesselInfo[field] = statement;
        },

        copyDocs() {
            let self = this;

            console.log(this.selectedVessel.vesselID);
            console.log(this.selectedItemIDs);

            let message;
            message = {
                title: 'Confirm Copy Documents?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    var adminFixSmsItems = firebase.functions().httpsCallable('adminFixSmsItems');
                    adminFixSmsItems({
                        vesselID: self.selectedVessel.vesselID,
                        selectedItemIDs: self.selectedItemIDs,
                        sourceCollection: 'pooledContacts',
                        targetCollection: 'smsContacts'
                    })
                        .then(function(result) {
                            self.clearDocs();
                            // self.clearVessel();
                            NProgress.done();
                        })
                        .catch(function(error) {
                            console.log('Error', error.text);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        loadAllVessels: function() {
            let self = this;
            db.collection('vessels')
                .orderBy('vesselOwnerName', 'asc')
                .onSnapshot(function(querySnapshot) {
                    self.allVessels = [];
                    querySnapshot.forEach(doc => {
                        //let auditD = (doc.data().lastAuditDate ? doc.data().lastAuditDate.toDate() : 'vvv');
                        //console.log(auditD);
                        self.allVessels.push({
                            vesselID: doc.id,
                            vesselName: doc.data().vesselName,
                            vesselOwnerName: doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?',
                            vesselOwnerID: doc.data().vesselOwnerID
                        });
                    });
                });
        },

        formatDate: function(sentDate, format) {
            let fixDate = sentDate ? sentDate.toDate() : new Date();
            //console.log("Fix date:",fixDate);
            return moment(fixDate).format(format);
        }
    }
};
</script>

<style lang="scss" scoped>
.site-wrapper .container {
    max-width: 850px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
.traffic-light {
    position: relative;
    span {
        float: left;
        margin-right: 10px;
    }
    .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
            background: red;
            &.active {
                background: red;
            }
        }
        &.orange {
            background: orange;
            &.active {
                background: orange;
            }
        }
        &.green {
            background: #03d203;
            &.active {
                background: #03d203;
            }
        }
    }
}

// input[type="text"] {
//   width: 90%;
//   float: left;
//   display: block;
//   margin-bottom: 2rem;
//   // &:after {
//   //   content: "\f16c";
//   //   font-family: "Font Awesome 5 Brands";
//   //   color: red;
//   // }
//   // &.valid:after {

//   // }
// }
.icon-wrap {
    display: block;
    float: left;
    height: 35px;
    margin-left: 10px;
    font-size: 1.5rem;
    color: red;
    &.valid {
        color: green;
    }
}
label {
    margin-bottom: 10px;
    display: block;
}
.is-checkradio[type='checkbox'] {
    opacity: 1;
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
    padding: 10px;
    margin-top: 10px;
    margin-left: 10px;
}

.site-wrapper .container {
    max-width: 1050px;
}
.buttons {
    margin-bottom: 5px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
input[type='text'] {
    padding: 2rem 1rem;
}
.form-group {
    label {
        top: auto;
        bottom: -25px;
    }
}
.traffic-light {
    position: relative;
    span {
        float: left;
        margin-right: 10px;
    }
    .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
            background: red;
            &.active {
                background: red;
            }
        }
        &.orange {
            background: orange;
            &.active {
                background: orange;
            }
        }
        &.green {
            background: #03d203;
            &.active {
                background: #03d203;
            }
        }
    }
}
.highlight {
    background: dodgerblue;
    padding: 15px;
    color: #fff;
}
.modal {
    .modal-card {
        .modal-card-head {
            z-index: 999 !important;
        }
        .modal-card-foot {
            z-index: 999 !important;
        }
    }
}
input[type='text'],
input[type='tel'] {
    padding: 2rem 1rem;
}

textarea {
    min-height: 120px;
    height: 400px;
    margin-top: 10px;
    padding: 1.5rem 1rem;
}
table {
    td {
        padding-bottom: 21px;
        &.is-updated {
            color: green;
            font-weight: bold;
        }
        .small {
            font-size: 0.8rem;
            margin-top: 5px;
            text-align: center;
        }
    }
}
.show-details {
    cursor: pointer;
    &:hover {
        color: dodgerblue;
    }
}
</style>
