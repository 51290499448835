<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">Update a Single Existing Document</h3>
                <br />

                <div class="input-wrapper">
                    <label>Source Document Path (no trailing slashes)</label>
                    <input class="input" type="text" placeholder="Paste source doc path" v-model="sourcePath" />
                    <div class="clear"></div>
                </div>

                <div class="buttons">
                    <button :disabled="sourcePath == ''" class="button is-medium is-primary" @click="getDocData()">Get Document Data</button>
                    <button :disabled="sourcePath == ''" class="button is-medium is-light" @click="reset()">
                        Reset
                    </button>
                </div>

                <!-- <div class="spacer s30"></div> -->

                <!-- <div class="clear"></div>

                <div class="input-wrapper">
                    <label>Details</label>
                    <textarea class="input" placeholder="Write details" v-model="newDetails"></textarea>
                </div>

                <div class="clear"></div>

                <div class="input-wrapper">
                    <label>Index</label>
                    <input class="input" type="tel" v-model="newIndex">
                </div> -->

                <pre v-if="allDocData != ''" class="new-data">{{ allDocData }}</pre>

                <hr />

                <div class="input-wrapper">
                    <label>Target Document Path (no trailing slashes)</label>
                    <input class="input" type="text" placeholder="Paste target doc path" v-model="targetPath" />
                    <div class="clear"></div>
                </div>

                <div class="clear"></div>

                <h3 class="is-size-6 bold">Document Data to Update:</h3>
                <div class="spacer s10"></div>
                <div>
                    <pre class="new-data">{{ newDocData }}</pre>
                </div>

                <div class="spacer s30"></div>

                <div class="buttons">
                    <span
                        :disabled="Object.keys(newDocData).length == 0 || targetPath == ''"
                        class="button is-warning is-medium"
                        @click="saveNewDocument"
                    >
                        Update Doc
                    </span>
                </div>

                <hr />
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '../main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
//import * as moment from 'moment';

export default {
    components: {},
    mounted: function() {},

    data: function() {
        return {
            newTitle: '',
            newDetails: '',
            newIndex: 99,

            sourcePath: '/vessels/UP9XK6CDnZxO6MJp3XLj',
            targetPath: '',

            newDocData: {
                users: [
                    'oaKsxo3jU0OyS63SpIwtyr8f11b2',
                    '8BSav4h5fNfiwUONVwBy5awlrmv2',
                    'cZZ2m3kmYLXTk2s6jvXFZRF83eI2',
                    '48t1jXOc3reZDqybsfgyV55Hgvu2',
                    'MlKiD3maaBNWhRUb2NucKHGM9SG3',
                    'IYvwenWNSMbQLEOz2rvFbLN6p6A2',
                    'yavNwMS5HlZeUpUUTJHXahDByvj2',
                    'vqvGwcP6cQQUlAJ3OLltwcSFrMB3',
                    'InlsP2hgoYWMJ5elQ0wezIkR7wn1',
                    'lJDgGoPIE1Vy4XUJAeUWXHg1O7K2',
                    '00E0Cd88oxYffoOuJJBPMlOYy5K2',
                    'fq4Tnj4v0mZRSvnym4UfW2K59af2',
                    '23Fgq5S54eNQ6wpUJb1Sty1KLEk2',
                    'MlFF1PNc1rOpVy6DNt7GhsuF1NC2',
                    '0Ekez2HN7vSHipGii8nQPzuuD5h1',
                    'VYEIyZRLLNTSFe74ClMDp4v1vph2',
                    'LPsiSvUpE2P5l5Qx3tZBtfYjR6B2',
                    'WOU5VCkcsxafCqdeFutqFJBxBsM2',
                    '4Iw1qTPRMQMrEB73Gl96YFSkDUz1',
                    'UL6QcQOhNlY0oyeKkgVSLQmy7PE2',
                    'v3BNTX2027R8BnxtzEMMPT31raq2',
                    'SRCNkdgUKHTKpvMBaYjIPYMAx7M2',
                    'I0CciFVEd0VYOSLtyCGm7FkbHqJ3',
                    'SbW8tpPe90Rnq5vCxaE0vU1Xebq1',
                    'XAjdxrD33BRBCX1l0J0L1QoXe153',
                    'RemCP3wvzffctM1j9fJiJGRY6g63',
                    '9ASw0U5HjZZaJ79ak1iT8lllMep1',
                    'qXn3EtXLlJgiCrtsisqSG0AdoHx1',
                    'zB0bHLGDzVg8dyLC9BwXK6OkR0K3',
                    'vdn9YmrcSMVeAAcrmk7omqadHSs1',
                    'FmIzxgywefPwiJzmELU6YlZVmmJ2',
                    'fzxdpw7y6aWkPmmL0XJLKhJHzTd2',
                    '7mNHWpdjJGOaQiZJLxuWktqv1lo2',
                    'rcUSanZr2zNBH7Ww2qdFu3EnGkF3',
                    'co3fFqvUXYQvmkEXEyiwCr6nt1n2',
                    'qbudVanh6eVO3plOM48WikMB7c52',
                    'mCCwcEaNpDY8rpKTN8cDxsUqRvy1',
                    'atzT2CKvfLg09uLM8W1yI5I14vR2',
                    'swb6UqoGvgURSxPZnkNa514wktf1',
                    'W50OZqcPMiYgAdy1b3m3HyK2Pzn1',
                    'QtnHY6cWmqPbpfHtZ1NsffQgaij2',
                    'opvlXRw1mjga1qZL7urxJeN097q2',
                    'K966p9s0fMchsEYaeuQPMjC2ixm1',
                    '71hohOLZzzN0QdXVykE4WCut0UY2',
                    'UvjhnDBAy0NZakHxGLKsYioP2yB2',
                    'JnYS1YarZ1UjtuGGjYlpNM7M2cW2'
                ]
            },
            allDocData: ''
        };
    },

    computed: {
        isSteve() {
            return this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' || this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1';
        },
        showButton() {
            return this.newTitle == '' || this.targetPath == '';
        }
    },

    methods: {
        getDocData() {
            console.log('sourcePath', this.sourcePath);
            let self = this;
            NProgress.start();
            db.doc(this.sourcePath)
                .get()
                .then(doc => {
                    if (doc.exists) {
                        console.log('doc', doc.data());
                        self.allDocData = doc.data();
                    } else {
                        self.allDocData = 'No such document!';
                    }
                    NProgress.done();
                })
                .catch(error => {
                    console.log('error', error);
                    NProgress.done();
                });
        },

        reset() {
            this.targetPath = '';
            this.sourcePath = '';
            this.allDocData = '';
        },

        saveNewDocument() {
            let self = this;

            let message;
            message = {
                title: 'Update document?',
                body: 'Click YES below proceed.'
            };
            let options = {
                okText: 'YES, UPDATE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    db.doc(self.targetPath)
                        .update(self.newDocData)
                        .then(() => {
                            NProgress.done();
                            self.reset();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        clear() {
            this.newTitle = '';
            this.newDetails = '';
            this.newIndex = '';
            this.targetPath = '';
        }
    }
};
</script>

<style lang="scss" scoped>
input[type='text'],
input[type='tel'] {
    width: 100%;
    float: left;
    display: block;
    margin-bottom: 2rem;
    padding: 15px;
    height: auto;
    // &:after {
    //   content: "\f16c";
    //   font-family: "Font Awesome 5 Brands";
    //   color: red;
    // }
    // &.valid:after {

    // }
}

label {
    margin-bottom: 10px;
    display: block;
}

textarea {
    width: 100%;
    padding: 15px;
    min-height: 200px;
    margin-bottom: 2rem;
}

.icon-wrap {
    display: block;
    float: left;
    height: 35px;
    margin-left: 10px;
    font-size: 1.5rem;
    color: red;

    &.valid {
        color: green;
    }
}
</style>
