<template>

  <div class="site-wrapper no-header">

    <ul class="scroller">
        <li><router-link to="my-dashboard">Dashboard</router-link></li>
        <li><router-link to="my-alerts">My Alerts</router-link></li>
        <li class="is-active"><a aria-current="page">{{msg}}</a></li>

    </ul>
  
    <div class="container">

      <article class="message is-primary">
        <div class="message-header">
          <p>Add an Alert for {{userName}}</p>
        </div>
        <div class="message-body">

          <form id="vesselForm" @submit.prevent="saveAlert">  
            <div class="form-group-reg">   
              <!-- <label class="label-fixed">Alert Title:</label>    -->
              <input class="input" type="text" placeholder="Alert title..." v-model="newalert.title">
            </div>
            <div class="form-group">      
              <textarea v-model="newalert.details" class="textarea" placeholder="Details..." rows="5"></textarea>
            </div>


            <div class="form-group">   
              <span>&nbsp;</span>
              <datetime v-model="newalert.expiry"></datetime>  
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>Expiry date:</label>
            </div>
            
            <button class="button is-primary" :disabled="showAddAlertButton" type="submit">Save new alert</button>
          </form>

        </div>
        
      </article>

    </div>


  </div>
  
      

</template>

<script>  

  import { db } from '../main.js';
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth'; 
  import 'firebase/firestore';
  // import 'firebase/compat/functions';
  import NProgress from 'nprogress';
  import * as moment from 'moment'

  export default {

    name: 'AddAlert',
    props: {
      userID: String,
      userName: String,
    },
    // mounted: function() {
    //   firebase.auth().onAuthStateChanged( this.onUserLogin );     
    // },
    computed: {
      showAddAlertButton() {
        // return this.newalert.title && this.newalert.details;
        return this.newalert.title == '' || this.newalert.title ==  undefined;
      },
    },
    data: function() {
      return {
        msg: 'Add Alert',
        newalert: [],
      }
    },
    methods: {
      // onUserLogin: function( user ) {
      //   if (user) {         
      //     this.firebaseid = user.uid;
      //   }
      // },
      saveAlert: function() {
        NProgress.start();
        var self = this;
        let tempDate = (self.newalert.expiry ? self.newalert.expiry : new Date());
        let finalDate = moment(tempDate).format('D MMMM YYYY');
        db.collection('userdata').doc(this.userID).collection('myAlerts').add({
            title: self.newalert.title ? self.newalert.title : '',
            details: self.newalert.details ? self.newalert.details : '',
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            expiryDate: finalDate,
            alertSeen: false,
        })
        .then(docRef => {
            console.log("Alert sent to user: ", self.userName, ": ", self.newalert.title,self.newalert.details);
            NProgress.done();
            self.$router.replace('users');
        })
        .catch(error => {
            console.error("Error adding alert: ", error);
            NProgress.done();
            self.$router.replace('/');
        });
      },


    },

  }

</script>



<style lang="scss" scoped>

  .message {
    .message-body {
      background: #fff;
    }
  }
  textarea {
    min-height: 100px !important;
    box-shadow: none;
    padding: 0.6rem 1rem;
    &.notes {
      margin-top: 1rem;
    }
  }


</style>