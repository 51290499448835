<template>
  
  <div>

      <div class="site-wrapper no-header">
      
        <div class="container">

            <h3 class="is-size-5">All Stats Page</h3><br>

            <hr class="dashed">

            <div class="buttons">
              <router-link to="users" class="button is-info is-medium"><span><font-awesome-icon icon="users" /></span>&nbsp; View all app users</router-link>
            </div>

            <div class="buttons">
              <router-link to="all-fleets" class="button is-dark is-medium"><span><font-awesome-icon icon="ship" /></span>&nbsp; View all fleets only</router-link>
            </div>

            <hr class="dashed">

            <div v-if="isSteve" class="buttons">
              <router-link to="admin-vessel-status" class="button is-medium is-skip">All Vessel Payments</router-link>
            </div>

            <div v-if="isSteve" class="buttons">
              <router-link to="admin-vessels" class="button is-medium is-primary">View all vessels</router-link>
            </div>

            <div v-if="isSteve" class="buttons">
              <router-link to="manage-all-vessels" class="button is-medium is-warning">Manage all vessels</router-link>
            </div>

            <div v-if="isSteve" class="buttons">
               <router-link to="admin-create-company" class="button is-danger"><span><font-awesome-icon icon="check" /></span>&nbsp; Create a new company</router-link>
            </div>

            <div class="buttons">
              <router-link to="all-companies" class="button is-dark is-medium">View all Fleets</router-link>
            </div>

            <div class="buttons">
              <router-link to="fleet-vessels" class="button is-dark is-medium">View all Fleet vessels</router-link>
            </div>

            <div v-if="isSteve" class="buttons">
              <router-link to="all-fleet-admins" class="button is-dark">All Fleet Admins</router-link>
            </div>



        </div>

      </div>





  </div>

</template>


<script> 

  import firebase from 'firebase/compat/app';
  import { db } from '@/main.js';
  // import 'firebase/compat/auth'; 
  import { getAuth, onAuthStateChanged } from "firebase/auth";
  import 'firebase/firestore';
  import 'firebase/compat/functions';
  import NProgress from 'nprogress';  
  import * as moment from 'moment';



  export default {

    name: 'AllStats',
    components: {

    },
    mounted: function() {
      firebase.auth().onAuthStateChanged( this.onUserLogin ); 
    },

    data: function() {
      return {
        
      }
    },

    computed: {
      userData() {
        return this.$store.getters.getUser;
      },
      isSteve() {
        return this.userData ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false : false;
      },
    },

    methods: {

      onUserLogin: function( user ) {
        // if (user) {         

        // }
      },
      


    },

  }



</script> 




<style lang="scss" scoped>
  
  .site-wrapper .container {
    max-width: 850px;
  }
  svg {
    display: inline-block;
    margin-right: 10px;
  }



</style> 