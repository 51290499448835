<template>
  
  <div class="site-wrapper no-header">
    
      <div class="container">

        <div class="buttons">
          <span class="button is-info" @click="goBack()"><span><font-awesome-icon icon="chevron-left" /></span> &nbsp;Back &nbsp;</span>
          <!-- <span v-if="findName != '' || !selected" class="button is-warning" @click="clearUser()">Clear</span>
          <span v-if="findName != '' && selected" class="button is-primary" @click="addEntry()">Add Entry</span> -->
        </div>

        <h3 class="is-size-5">All Customers in CRM {{selected ? '- ' + selectedUser.userName : ''}}</h3>

        <div v-if="!selected" class="notification is-primary">
          <input class="input" v-model="findName" id="filtername" autocomplete="off" type="text" placeholder="Start typing user name..." />
        </div>
        
        <div class="box" v-if="findName.length > 1 && !selected">

          <div v-if="filteredNames.length">
            <ul class="vessel-list">
              <li v-for="user in filteredNames">
                <span class="vessel-name">{{user.userName}}</span>
                <span @click="loadUserData(user)" class="button is-small is-success">Select</span>
                <div class="clear"></div>
              </li>
            </ul>
          </div>

          <div v-else>
            <p>No clients matched your search.</p>
            <div class="buttons">
              <span class="button is-primary" @click="addClient()">Add a Client</span>
            </div>
          </div>

        </div>


        <hr v-if="selected">

       <!--  <table v-if="selected" class="table is-bordered is-fullwidth">
          <tr>
            <th>Name</th>
            <td>{{selectedUser.userName}}</td>
          </tr>
          <tr>
            <th>User ID</th>
            <td>{{selectedUser.userid}}</td>
          </tr>
        </table>

        <div class="spacer s30"></div> -->

        <div class="buttons" v-if="selected">
          <span class="button is-primary" @click="viewClientModal = true">View Profile</span>
        </div>

        <div class="spacer s30"></div>

        <ul v-if="selected && fullTimeline.length > 0" class="sms-steps steps is-vertical has-content-centered">

          <li v-for="(step, index) in fullTimeline" class="steps-segment">
            <span class="steps-marker is-primary">
              <span class="icon"><span><font-awesome-icon icon="check" /></span></span>
            </span>
            <div class="steps-content">
                <p class="heading">{{formatDate(step.entryDate,'D MMM YYYY (h:mm a)')}}</p>
                <p class="bold upper">{{step.title}}</p>
                <div v-for="line in step.details.split('\n')">{{line}}<br></div>
                <div v-if="step.followUpAdded" class="follow-up">Follow up added</div>
            </div>
          </li>

        </ul>  

        <div v-if="selected && fullTimeline.length == 0">
          <p>No timeline entries.</p>
        </div>

        <hr>


        <div class="buttons">
          <span v-if="findName != '' || !selected" class="button is-warning" @click="clearUser()">Clear</span>
          <span v-if="findName != '' && selected" class="button is-primary" @click="addEntry()">Add Entry</span>
        </div>
        





      </div>


      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':viewClientModal}">
        <div class="modal-background" @click="viewClientModal = false"></div>
        <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">{{selectedUser.userName}}</p>
              <button class="delete" aria-label="close" @click.prevent="viewClientModal = false"></button>
            </header>
            <section class="modal-card-body">    

              <div class="form-group-reg">
                <label class="label" for="">Name</label>
                <input :disabled="!isSteve" class="input entryTitle" v-model="selectedUser.userName" type="text" placeholder="Client name" />
              </div>

              <div class="form-group-reg">
                <label class="label" for="">Email address</label>
                <input :disabled="!isSteve" class="input entryTitle" v-model="selectedUser.emailAddress" type="text" placeholder="Client email" />
              </div>

              <div class="form-group-reg">
                <label class="label" for="">Phone number</label>
                <input :disabled="!isSteve" class="input entryTitle" v-model="selectedUser.phoneNumber" type="text" placeholder="Client phone" />
              </div>

              <div class="form-group-reg">
                <label class="label" for="">Fleet or Business</label>
                <input :disabled="!isSteve" class="input entryTitle" v-model="selectedUser.company" type="text" placeholder="Client business or organisation" />
              </div>
              
              <div class="form-group-reg">  
                <label class="label" for="">Admin Notes</label>    
                <textarea :disabled="!isSteve" v-model="selectedUser.notes" class="textarea" placeholder="Entry Notes (optional)" rows="5"></textarea>
              </div>

            </section>
            <footer class="modal-card-foot">
              <button class="button is-delete" @click.prevent="viewClientModal = false">Done</button>
              <button v-if="isSteve" class="button is-primary" @click.prevent="updateClient()">Update Client</button>
            </footer>
        </div>
      </div>


      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':addClientModal}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">New CRM Client</p>
              <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>
            <section class="modal-card-body">    

              <div class="form-group-reg">
                <input class="input entryTitle" v-model="newClient.name" type="text" placeholder="Client name" />
              </div>

              <div class="form-group-reg">
                <input class="input entryTitle" v-model="newClient.email" type="text" placeholder="Client email" />
              </div>

              <div class="form-group-reg">
                <input class="input entryTitle" v-model="newClient.phone" type="text" placeholder="Client phone" />
              </div>

              <div class="form-group-reg">
                <input class="input entryTitle" v-model="newClient.company" type="text" placeholder="Client business or organisation" />
              </div>
              
              <div class="form-group-reg">      
                <textarea v-model="newClient.notes" class="textarea" placeholder="Entry Notes (optional)" rows="5"></textarea>
              </div>

            </section>
            <footer class="modal-card-foot">
              <button class="button is-delete" @click.prevent="close">Done</button>
              <button class="button is-primary" @click.prevent="saveNewClient()">Save New Client</button>
            </footer>
        </div>
      </div>


  </div>

</template>


<script>
  

  import { db } from '../main.js';
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth'; 
  import 'firebase/firestore';
  import NProgress from 'nprogress';
  import * as moment from 'moment';

  export default {

    name: 'UserTimelines',
    props: {
      isAdding: Boolean,
      sentUser: Object,
    },
    components: {

    },
    computed: {
      filteredNames() {
        let filter = new RegExp(this.findName, 'i')
        return this.allUsers.filter(item => item.userName.match(filter));
      },
      showUpdate() {
        return !this.isSteve;
      },
      isSteve() {
        return this.firebaseid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2';
      }

    },

    mounted: function() {
      let self = this;
      firebase.auth().onAuthStateChanged( this.onUserLogin );   
      if (this.isAdding) {
        console.log(self.sentUser);
        self.selectedUser.userName = self.sentUser ? self.sentUser.userName : '';
        self.selectedUser.userid = self.sentUser ? self.sentUser.userid : '';
        self.selected = true;
        self.findName = self.sentUser ? self.sentUser.userName : '';
        self.loadTimeline();
      } 
    },

    data: function() {
      return {
        firebaseid: '',
        allUsers: [],
        findName: '',
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        selectedUser: {},
        selected: false,
        fullTimeline: [],
        showAddEntry: false,

        newClient: {
          id: '',
          name: '',
          email: '',
          phone: '',
          company: '',
          notes: '',
        },
        addClientModal: false,
        viewClientModal: false,

      }
    },

    methods: {

      onUserLogin: function( user ) {
        let self = this;
        if (user) {         
          this.firebaseid = user.uid;
          this.loadAllUsers();
        };        
          
      },

      close: function() {
        // this.selectedUser = {};
        this.showAddEntry = false;
        this.addClientModal = false;
        this.newClient = {};
        this.findName = '';
        this.selected = false;
        this.selectedUser = {};
      },

      goBack: function() {
        this.$router.go(-1);
      },

      loadAllUsers: function() {
        let self = this;
        db.collection("crmDatabase").onSnapshot(function(querySnapshot) {
          self.allUsers = [];
          querySnapshot.forEach(function(doc) {
            self.allUsers.push({
              userid: doc.id,
              userName: doc.data().userName,
              emailAddress: doc.data().emailAddress ? doc.data().emailAddress : '',
              phoneNumber: doc.data().phoneNumber ? doc.data().phoneNumber : '',
              notes: doc.data().notes ? doc.data().notes : '',
              company: doc.data().company ? doc.data().company : '',
              dateAdded: doc.data().dateAdded ? doc.data().dateAdded.toDate() : '',
            }); 
          });
        });
      },

      formatDate : function (sentDate,format) {
        // let fixDate = (sentDate ? sentDate.toDate() : new Date());
        // return moment(fixDate).format(format);
        return moment(sentDate).format(format);
      },

      loadUserData: function(user) {
        console.log(user);

        // this.selectedUser.userName = user.userName;
        // this.selectedUser.userid = user.userid;
        this.selectedUser = user;
        this.selected = true;
        this.loadTimeline();
        
      },

      loadTimeline() {
        let self = this;
        // console.log(self.selectedUser.userid);
        // db.collection('userdata').doc(self.selectedUser.userid).collection('timeline').get().then((querySnapshot) => {
        db.collection('crmDatabase').doc(self.selectedUser.userid).collection('timeline').orderBy('entryDate','desc').onSnapshot((querySnapshot) => {
          self.fullTimeline = [];
          querySnapshot.forEach((doc) => {
            // console.log(doc.data());
            self.fullTimeline.push({
              id: doc.id,
              timestamp: doc.data().timestamp ? doc.data().timestamp.toDate() : '',
              entryDate: doc.data().entryDate ? doc.data().entryDate.toDate() : '',
              title: doc.data().title ? doc.data().title : 'No title',
              details: doc.data().details ? doc.data().details : '',
              followUpAdded: doc.data().followUpAdded ? doc.data().followUpAdded : false,
            });
          });
        });
      },

      clearUser: function() {
        this.findName = '';
        this.selected = false;
        this.selectedUser = {};
      },

      addClient() {
        let self = this;
        this.addClientModal = true;
      },

      saveNewClient() {
        console.log(this.newClient);
        let self = this;
        NProgress.start();
        db.collection('crmDatabase').add({
          userName: self.newClient.name,
          emailAddress: self.newClient.email,
          phoneNumber: self.newClient.phone,
          company: self.newClient.company,
          notes: self.newClient.notes,
          dateAdded: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
          NProgress.done();
          self.close();
        });
      },

      addEntry() {
        let self = this;
        console.log(this.selectedUser);
        this.$router.push({ 
          name: 'CrmEntry', 
          params: { 
            currentUser: self.selectedUser,
           // sentLog : log == '' ? {} : log,
           // editing: log == '' ? false : true
          } 
        });
        // this.showAddEntry = true;
      }


    }

  }


</script>


<style lang="scss" scoped>
  
  h3 {
    margin: 1rem 0 1.5rem;
  }
  .select {
    width: 100%;
    max-width: 400px;
    select {
      width: 100%;
    }
  }

  h4 {
    text-transform: uppercase;
    margin-bottom: 0.8rem;
    font-weight: bold;
  }

  ul.vessel-list {
    max-width: 320px;
    li {
      margin-bottom: 1rem;
      &:last-of-type {
        margin-bottom: 0;
      }
      .vessel-name {
        float: left;
      }
      .button {
        float: right;
      }
    }
  }
  .buttons {
    margin-top: 1rem;
  }
  .button {
    margin-top: 0;
  }

  input.entryTitle {
    padding: 2rem 1rem;
  }

  textarea {
    padding: 1rem;
  }

  .form-group-reg {
    input {
      padding: 18px 20px;
    }
  }

  .steps-content {
    p {
      &.bold {
        font-weight: bold;
      }
      &.upper {
        text-transform: uppercase;
      }
      &.italic {
        font-style: italic;
      }
    }
    .follow-up {
      font-style: italic;
      color: orangered;
    }
  }



</style>