<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">All Vessel Payments</h3>
                <br />

                <div class="spacer s20"></div>

                <input class="is-checkradio" type="checkbox" id="setRecentfirst" @change="reverseOrder()" v-model="recentFirst" />
                <label for="setRecentfirst"> Recent payments first</label>

                <div class="spacer s30"></div>

                <!-- <div class="">
              {{rightNow}}
            </div> -->

                <!-- {{filteredVessels}} -->

                <h4>
                    Total Vessel Payments Due: <span class="bold">${{ numberWithCommas(totalPayments) }}</span>
                </h4>

                <h4>
                    Selected Vessel Payments Due: <span class="bold">${{ numberWithCommas(totalCheckedPayments) }}</span> ({{ checkedVessels.length }}
                    vessels)
                </h4>

                <h4>Total vessels: {{ filteredVessels.length }}</h4>

                <div class="spacer s30"></div>

                <!-- <span class="button" @click="updateVesselOwners">Get Owners</span> -->

                <!-- {{selectedIDs}}<br>{{selectedVessels}} -->

                <div class="buttons">
                    <span class="button is-danger" @click="clearVessels()">Clear Selections</span>
                </div>

                <div class="buttons">
                    <span class="button is-danger" @click="fixTags()">Fix StartupTags</span>
                </div>

                <div class="buttons">
                    <button :disabled="selectedVessels.length == 0" class="button is-success" @click="sendRenewalEmails()">
                        Send Expiry Email ({{ selectedVessels.length }})
                    </button>
                </div>

                <div class="form-group-reg">
                    <label class="bold caps">Category:</label>
                    <div class="select">
                        <select v-model="emailType">
                            <option v-for="t in emailTypes" :value="t.slug">{{ t.title }}</option>
                        </select>
                    </div>
                </div>

                <hr />

                <input class="is-checkradio" type="checkbox" id="hideDemo" v-model="hideDemo" />
                <label for="hideDemo"> Hide Trial Vessels</label>

                <input class="is-checkradio" type="checkbox" id="hidePast" v-model="hidePast" />
                <label for="hidePast"> Hide > 30 days</label>

                <input class="is-checkradio" type="checkbox" id="hideExpired" v-model="hideExpired" />
                <label for="hideExpired"> Hide Expired Vessels</label>

                <div class="spacer s20"></div>

                <div>
                    <p>&nbsp; &nbsp; <font-awesome-icon icon="star" /> &nbsp; Company owned vessel</p>
                </div>

                <blockquote>
                    <p class="green"><font-awesome-icon icon="star" /> &nbsp; <span>Renewal Alert: </span> Working well!</p>
                    <p class="blue">
                        <font-awesome-icon icon="star" /> &nbsp; <span>Renewal Ofer: </span> Works but very basic - only $149 or $249 options
                    </p>
                    <p class="green"><font-awesome-icon icon="star" /> &nbsp; <span>Payment Confirmation: </span> Working well!</p>
                </blockquote>

                <div class="spacer s30"></div>

                <table class="table is-bordered is-fullwidth">
                    <tr>
                        <th>Vessel (and owner)</th>
                        <th>Expiry Date</th>
                        <th>Amout Due</th>
                        <th>Select</th>
                        <!-- <th>Expiry Due</th> -->
                        <th>Square</th>
                    </tr>
                    <!-- <tr v-for="(vessel, index) in allVessels" :key="index">
								<td>{{vessel.vesselName}}<br>{{vessel.vesselOwnerName}}<br>{{vessel.isDemo}}</td>
                <td>{{formatDate(vessel.nextChargeDate,'DD MMMM, YYYY')}}</td>
                <td>${{vessel.nextVesselCharge}}</td>
                <td>
                  <input class="is-checkradio" type="checkbox" :id="'index_' + index" v-model="vessel.tempSelected">
                  <label :for="'index_' + index"> </label>
                </td>
							</tr> -->
                    <tr v-for="(vessel, index) in filteredVessels" :key="index">
                        <td style="width: 30%;">
                            <span @click="setUpdateVessel(vessel)" class="bold">{{ vessel.vesselName }}</span> <br /><span
                                v-if="vessel.isCompanyVessel"
                                ><font-awesome-icon icon="star"
                            /></span>
                            &nbsp;{{ vessel.vesselOwnerName }}<br />
                            <span><font-awesome-icon icon="envelope"/></span> &nbsp;{{ vessel.vesselOwnerEmail }}<br />
                            <!-- <div :class="[vessel.isDemo ? 'is-trial' : 'not-trial']">Trial: {{vessel.isDemo}}</div>
                  <div :class="[vessel.isPast ? 'is-past' : 'not-past']">Past: {{vessel.isPast}}</div> -->
                            <span class="is-trial" v-if="vessel.isDemo">In trial mode</span>
                            <span class="is-past" v-if="vessel.isPast">Date has past</span>
                            <span class="is-expired" v-if="vessel.isExpired">Vessel Expired</span>
                            <!-- <div class="red">{{vessel.hasStartupTags ? '' : 'NO TAGS!'}}</div> -->
                            <div class="spacer s10"></div>
                            <div class="buttons">
                                <span
                                    @click="checkIfCustomerExists(vessel)"
                                    v-if="vessel.vesselOwnerSquareCustomerID == ''"
                                    class="button is-small is-warning square"
                                    ><font-awesome-icon icon="exclamation-triangle"
                                /></span>
                                <span v-else @click="checkIfCustomerExists(vessel)" class="button is-small is-success square"
                                    ><font-awesome-icon icon="check-circle"
                                /></span>
                            </div>
                        </td>
                        <td style="width: 20%;">
                            <div>
                                {{ formatDate(vessel.nextChargeDate, 'DD MMMM, YYYY') }}
                                <br />
                                <!-- {{new Date(rightNow - vessel.nextChargeDate.getTime())}} -->
                                {{ getNumberOfDays(vessel.nextChargeDate, rightNow) }} days left
                            </div>
                            <div class="spacer s10"></div>
                            <div class="button is-small is-danger" @click="expireAsset(vessel.vesselID)">
                                <font-awesome-icon icon="trash-alt" /> &nbsp; Expire Asset
                            </div>
                        </td>
                        <!-- <td style="width: 10%;" :class="[vessel.isCompanyVessel ? 'is-company' : 'not-company']">${{vessel.nextVesselCharge}}</td> -->
                        <td style="width: 10%;">
                            <div>${{ vessel.nextVesselCharge }}</div>
                            <div class="buttons">
                                <span
                                    class="button is-small"
                                    :class="[vessel.currentSquarePlanID == '' ? 'is-danger' : 'is-light']"
                                    @click="setPlanID(vessel)"
                                    >{{ vessel.currentSquarePlanID == '' ? 'Set Plan ID' : 'Square Plan Set' }}</span
                                >
                            </div>
                        </td>
                        <!-- <td>
                            <input class="is-checkradio" type="checkbox" :id="'index_' + index" v-model="vessel.tempSelected" />
                            <label :for="'index_' + index"> </label>
                        </td> -->
                        <td>
                            <input class="is-checkradio" type="checkbox" :id="'index_id_' + index" :value="vessel" v-model="selectedVessels" />
                            <label :for="'index_id_' + index"> </label>
                            <div class="green" v-if="vessel.expiryEmailSentOn != ''">
                                Prompt sent: {{ formatDate(vessel.expiryEmailSentOn, 'DD MMM, YY') }}
                            </div>
                            <div class="red" v-if="vessel.expiredRenewalEmailSentOn != ''">
                                Offer sent: {{ formatDate(vessel.expiredRenewalEmailSentOn, 'DD MMM, YY') }}
                            </div>
                        </td>

                        <td>
                            <!-- <div class="buttons">
                    <button :disabled="vessel.squareCustomerID != ''" class="button is-small is-primary" @click="createNewSquareCustomer(vessel)">Set Customer</button>
                    <button :disabled="vessel.squareCustomerID == '' || vessel.currentSquarePlanID != ''" class="button is-small is-warning" @click="chooseSquarePlanToAdd(vessel)">Add a Plan</button>
                    <button :disabled="vessel.squareCustomerID == ''" class="button is-small is-danger" @click="createNewPlanSubscription(vessel)">Create Subscription</button>
                  </div> -->
                            <!-- <div v-if="vessel.squareCustomerID != ''">
                    SQ ID: {{vessel.squareCustomerID}}
                  </div> -->
                            <!-- <div v-if="vessel.currentSquarePlanID != ''">
                    SQ Plan ID: {{vessel.currentSquarePlanID}}
                  </div> -->
                            <!-- <div>{{vessel.isPast ? 'PAST' : 'GTG'}}</div> -->
                            <!-- <span v-if="vessel.currentSquarePlanID != ''" class="button is-success is-small">{{vessel.currentSquarePlanID}}</span> -->
                            <!-- <span class="button is-small" :class="[vessel.currentSquarePlanID == '' ? 'is-danger' : 'is-success']" @click="setPlanID(vessel)">{{vessel.currentSquarePlanID == '' ? 'Set Plan ID' : vessel.currentSquarePlanID}}</span> -->

                            <div class="buttons">
                                <!-- <span class="button is-small" :class="[vessel.currentSquarePlanID == '' ? 'is-danger' : 'is-light']" @click="setPlanID(vessel)">{{vessel.currentSquarePlanID == '' ? 'Set Plan ID' : 'Square Plan Set'}}</span> -->
                                <button
                                    class="button is-small is-dark"
                                    :class="{ 'is-warning': vessel.paymentHistory.length == 0 }"
                                    @click="showPaymentHistoryModal(vessel)"
                                >
                                    {{
                                        vessel.paymentHistory.length == 0
                                            ? 'No Payment History'
                                            : 'Payment History (' + vessel.paymentHistory.length + ')'
                                    }}
                                </button>
                                <span
                                    v-if="vessel.lastInvoiceStatus == 'not set'"
                                    class="button is-small is-warning"
                                    @click="showInvoiceStatus(vessel)"
                                    >Check Status</span
                                >
                                <span
                                    v-else
                                    class="button is-small caps"
                                    :class="[vessel.lastInvoiceStatus == 'paid' ? 'is-success' : 'is-danger']"
                                    @click="showInvoiceStatus(vessel)"
                                    >{{ vessel.lastInvoiceStatus }} {{ timeSinceActionDate(vessel.lastInvoiceActionDate) }} days ago</span
                                >
                                <!-- <span v-else class="button is-small is-danger caps" :class="{'is-success': vessel.lastInvoiceStatus == 'paid'}" @click="showInvoiceStatus(vessel)">{{vessel.lastInvoiceStatus}} {{vessel.lastInvoiceStatus == 'sent' ? '(' + timeSinceActionDate(vessel.lastInvoiceActionDate) + ' days ago)' : ''}}</span> -->
                            </div>

                            <div class="buttons">
                                <span
                                    class="button is-small is-light"
                                    :class="[!vessel.renewalAlertSent ? 'is-light' : 'is-primary']"
                                    @click="startRenewalAlert(vessel)"
                                    >{{ vessel.renewalAlertSent ? 'Resend Alert' : 'Send Renewal Alert' }}</span
                                >
                                <span
                                    class="button is-small is-light"
                                    :class="[!vessel.renewalOfferSent ? 'is-light' : 'is-success']"
                                    @click="startRenewalOffer(vessel)"
                                    >{{ vessel.renewalOfferSent ? 'Resend Offer' : 'Send Renewal Offer' }}</span
                                >
                                <span
                                    class="button is-small is-light"
                                    :class="[!vessel.paymentConfirmationSent ? 'is-light' : 'is-info']"
                                    @click="startPaymentConfirmation(vessel)"
                                    >{{ vessel.paymentConfirmationSent ? 'Resend Confirmation' : 'Send Payment Confirmation' }}</span
                                >
                            </div>

                            <!-- <div class="buttons">
                                <span class="button is-small is-success" @click="bulkChangeDate(vessel)">
                                    Change Date
                                </span>
                            </div> -->
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showUpdateModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Update Vessel Info for {{ selectedVessel.vesselName }}</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <p>Vessel ID: {{ selectedVessel.vesselID }}</p>
                    <div class="spacer s20"></div>

                    <div>
                        <div class="form-group-reg">
                            <input class="is-checkradio" id="isExpired" type="checkbox" v-model="selectedVessel.isExpired" />
                            <label for="isExpired">Is Expired?</label>
                        </div>

                        <div class="form-group-reg">
                            <input class="is-checkradio" id="isDemo" type="checkbox" v-model="selectedVessel.isDemo" />
                            <label for="isDemo">Is Demo?</label>
                        </div>

                        <div class="form-group-reg">
                            <input class="is-checkradio" id="isPaidFor" type="checkbox" v-model="selectedVessel.isPaidFor" />
                            <label for="isPaidFor">Is Paid For?</label>
                        </div>

                        <!-- <div class="form-group-reg">
                  <input placeholder="149" autocomplete="false" class="input" type="tel" v-model="selectedVessel.lastVesselCharge" :maxlength="4" v-on:keypress="isNumber($event)" required>
                  <label class="label-fixed">Last Vessel Charge ($):</label>
                </div>

                <div class="form-group">
                  <span>&nbsp;</span>
                  <datetime auto input-id="lastChargeDate" v-model="selectedVessel.lastChargeDate"></datetime>
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label>Last Charge Date</label>
                </div>

                <div class="spacer s30"></div>

                <div class="form-group-reg">
                  <input placeholder="149" autocomplete="false" class="input" type="tel" v-model="selectedVessel.nextVesselCharge" :maxlength="4" v-on:keypress="isNumber($event)" required>
                  <label class="label-fixed">Next Vessel Charge ($):</label>
                </div>

                <div class="form-group">
                  <span>&nbsp;</span>
                  <datetime auto input-id="nextChargeDate" v-model="selectedVessel.nextChargeDate"></datetime>
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label>Next Charge Date</label>
                </div>

                <div class="form-group-reg">
                  <input class="is-checkradio" id="useGlobal" @change="selectedVessel.nextChargeDate = globalNextChargeDate" type="checkbox" >
                  <label for="useGlobal">Use global next charge date</label>
                </div>

                <div class="spacer s30"></div>

                <div class="form-group-reg">
                  <input class="is-checkradio" id="billingUpdated" type="checkbox" v-model="selectedVessel.billingUpdated">
                  <label for="billingUpdated">Mark as Billing Updated?</label>
                </div>  -->

                        <div class="spacer s30"></div>
                    </div>

                    <!-- <div v-else><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading...</div> -->
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="updateVesselInfo()">Update Vessel</button>
                    <button class="button is-cancel" @click="close">Cancel</button>
                </footer>
            </div>
        </div>

        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showPlans }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Choose a Plan {{ selectedVessel.vesselName }}</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    {{ selectedVessel }}

                    <p>Vessel ID: {{ selectedVessel.vesselName }}</p>
                    <div class="spacer s20"></div>

                    <ul>
                        <li v-for="plan in allPlans" :key="plan.id">
                            <div>
                                <button class="button" :class="[selectedPlan == plan ? 'is-info' : '']" @click="selectedPlan = plan">
                                    {{ plan.title }} (${{ plan.price }})
                                </button>
                                <div class="spacer s10"></div>
                            </div>
                        </li>
                    </ul>
                </section>
                <footer class="modal-card-foot">
                    <button
                        :disabled="selectedPlan.id == '' || selectedPlan.id == undefined"
                        class="button is-primary"
                        @click="addNewPlanSubscription()"
                    >
                        Set Plan ID to Vessel
                    </button>
                    <button class="button is-cancel" @click="close">Cancel</button>
                </footer>
            </div>
        </div>

        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showSetPlanID }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Set a current Plan ID on {{ selectedVessel.vesselName }}</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="form-group-reg">
                        <label class="label">Current Plan - {{ selectedVessel.currentSquarePlanID }}</label>
                        <input
                            class="is-checkradio"
                            type="radio"
                            id="planLogsSms"
                            value="5VQRQGREFHO7WYGDY6H6ATV7"
                            name="currentPlanID"
                            v-model="selectedVessel.currentSquarePlanID"
                        />
                        <label class="radio-label" for="planLogsSms">Logs+SMS</label>
                        <br />
                        <input
                            class="is-checkradio"
                            type="radio"
                            id="planPro"
                            value="726DXINJQFHRBGCNILXWRQFR"
                            name="currentPlanID"
                            v-model="selectedVessel.currentSquarePlanID"
                        />
                        <label class="radio-label" for="planPro">PRO</label>
                        <br />
                        <input
                            class="is-checkradio"
                            type="radio"
                            id="planNone"
                            value=""
                            name="currentPlanID"
                            v-model="selectedVessel.currentSquarePlanID"
                        />
                        <label class="radio-label" for="planNone">None</label>
                        <div class="clear"></div>
                        <div class="spacer s20"></div>
                        <label class="label" for="price">Next Charge $:</label>
                        <input class="input padded" id="price" type="tel" placeholder="$249.." v-model="selectedVessel.nextVesselCharge" />
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button
                        :disabled="selectedVessel.currentSquarePlanID == '' || selectedVessel.currentSquarePlanID == undefined"
                        class="button is-primary"
                        @click="setSquarePlanID()"
                    >
                        Save Plan ID
                    </button>
                    <button class="button is-cancel" @click="close">Cancel</button>
                </footer>
            </div>
        </div>

        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showInvoiceDetails }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Set invoice status on {{ selectedVessel.vesselName }}</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="form-group-reg">
                        <!-- <label class="label-fixed">Status - {{selectedVessel.lastInvoiceStatus}}</label>    -->
                        <input
                            class="is-checkradio"
                            type="radio"
                            id="statusOne"
                            value="paid"
                            name="invoiceStatus"
                            v-model="selectedVessel.lastInvoiceStatus"
                        />
                        <label class="radio-label" for="statusOne"><font-awesome-icon icon="thumbs-up" /> &nbsp; Invoice Paid</label>
                        <br />
                        <input
                            class="is-checkradio"
                            type="radio"
                            id="statusTwo"
                            value="sent"
                            name="invoiceStatus"
                            v-model="selectedVessel.lastInvoiceStatus"
                        />
                        <label class="radio-label" for="statusTwo"><font-awesome-icon icon="envelope" /> &nbsp; Invoice Sent</label>
                        <br />
                        <input
                            class="is-checkradio"
                            type="radio"
                            id="statusThree"
                            value="not set"
                            name="invoiceStatus"
                            v-model="selectedVessel.lastInvoiceStatus"
                        />
                        <label class="radio-label" for="statusThree">Not set</label>
                        <br />
                    </div>

                    <div class="form-group-reg">
                        <label for="invoiceDate">Invoice number</label>
                        <input type="text" v-model="selectedVessel.lastInvoiceNumber" placeholder="Last invoice number.." />
                    </div>

                    <div class="form-group-reg">
                        <label for="invoiceDate">Last Action Date</label>
                        <datetime input-id="invoiceDate" placeholder="Invoice Date" auto v-model="selectedVessel.lastInvoiceActionDate"></datetime>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="setInvoiceStatus()">Save Status</button>
                    <button class="button is-cancel" @click="close">Cancel</button>
                </footer>
            </div>
        </div>

        <simple-modal title="Send Renewal offer" theme="is-primary" @closed="close()" v-if="showGetUserName">
            <div>
                <input class="input" type="text" placeholder="Customer first name" v-model="renewalEmailInfo.firstName" />
            </div>
            <div class="spacer s20"></div>
            <div class="button is-warning" @click="sendRenewalOffer()">Send Renewal Offer</div>
        </simple-modal>

        <simple-modal
            :title="'Send Renewal Alert: ' + renewalEmailInfo.vesselName"
            theme="is-primary"
            submitText="Cancel"
            @closed="close()"
            v-if="showRenewalAlertModal"
        >
            <!-- {{ renewalEmailInfo }} -->

            <div>
                <div class="bold" :class="[renewalEmailInfo.isCompanyVessel ? 'green' : 'blue']">
                    <font-awesome-icon :icon="renewalEmailInfo.isCompanyVessel ? 'users' : 'user'" /> &nbsp;Owner:
                    {{ renewalEmailInfo.vesselOwnerName }}
                </div>
                <div class="spacer s10"></div>
            </div>

            <div v-for="(email, idx) in renewalEmailInfo.recipients" :key="`email-${idx}`" class="buttons">
                <div class="button is-cancel">{{ email }}</div>
                <div class="button is-light" @click="dropRenewalEmail(idx)"><font-awesome-icon icon="trash-alt" /></div>
            </div>
            <div v-if="renewalEmailInfo.recipients.length == 0">
                <div class="bold red"><font-awesome-icon icon="exclamation-triangle" /> &nbsp;No email recipients!</div>
                <div class="spacer s10"></div>
            </div>
            <div>
                <input class="input" type="email" placeholder="Add an email.." v-model="renewalEmailInfo.addEmail" />
            </div>
            <div class="buttons">
                <div class="button is-small is-skip" :disabled="!validateEmail(renewalEmailInfo.addEmail)" @click="addRenewalEmail()">Add Email</div>
            </div>
            <div class="spacer s20"></div>

            <div>
                <textarea class="input textarea" placeholder="Renewal message" v-model="renewalEmailInfo.renewalMessage"></textarea>
            </div>
            <div class="spacer s20"></div>
            <div>
                <input class="input" type="text" placeholder="Square invoice link" v-model="renewalEmailInfo.invoiceLink" />
            </div>
            <div class="spacer s20"></div>
            <input class="is-checkradio" type="checkbox" id="autoRenewal" v-model="renewalEmailInfo.autoRenew" />
            <label for="autoRenewal"> This is an auto renewal alert</label>
            <div class="spacer s20"></div>
            <div class="buttons">
                <div class="button is-success" @click="sendRenewalAlert()"><font-awesome-icon icon="envelope" /> &nbsp;Send Renewal Alert Email</div>
                <div class="button is-info" @click="markRenewalAsSent()"><font-awesome-icon icon="check" /> &nbsp;Just Mark as Sent</div>
            </div>
        </simple-modal>

        <simple-modal
            :title="'Send Payment Confirmation: ' + renewalEmailInfo.vesselName"
            theme="is-primary"
            submitText="Cancel"
            @closed="close()"
            v-if="showPaymentAlertMessage"
        >
            <!-- {{ renewalEmailInfo }} -->

            <div>
                <div class="bold" :class="[renewalEmailInfo.isCompanyVessel ? 'green' : 'blue']">
                    <font-awesome-icon :icon="renewalEmailInfo.isCompanyVessel ? 'users' : 'user'" /> &nbsp;Owner:
                    {{ renewalEmailInfo.vesselOwnerName }}
                </div>
                <div class="spacer s10"></div>
            </div>

            <div v-for="(email, idx) in renewalEmailInfo.recipients" :key="`email-${idx}`" class="buttons">
                <div class="button is-cancel">{{ email }}</div>
                <div class="button is-light" @click="dropRenewalEmail(idx)"><font-awesome-icon icon="trash-alt" /></div>
            </div>
            <div v-if="renewalEmailInfo.recipients.length == 0">
                <div class="bold red"><font-awesome-icon icon="exclamation-triangle" /> &nbsp;No email recipients!</div>
                <div class="spacer s10"></div>
            </div>
            <div>
                <input class="input" type="email" placeholder="Add an email.." v-model="renewalEmailInfo.addEmail" />
            </div>
            <div class="buttons">
                <div class="button is-small is-skip" :disabled="!validateEmail(renewalEmailInfo.addEmail)" @click="addRenewalEmail()">Add Email</div>
            </div>
            <div class="spacer s20"></div>

            <div>
                <textarea class="input textarea" placeholder="Renewal message" v-model="renewalEmailInfo.renewalMessage"></textarea>
            </div>
            <div class="spacer s20"></div>
            <!-- <div>
                <input class="input" type="text" placeholder="Square invoice number" v-model="renewalEmailInfo.invoiceLink" />
            </div>
            <div class="spacer s20"></div> -->
            <div class="buttons">
                <div class="button is-success" @click="sendPaymentConfirmationEmails()">
                    <font-awesome-icon icon="envelope" /> &nbsp;Send Payment Confirmation Email
                </div>
                <div class="button is-info" @click="markConfirmationAsSent()"><font-awesome-icon icon="check" /> &nbsp;Just Mark as Sent</div>
            </div>
        </simple-modal>

        <simple-modal v-if="showAdminNotes">
            <label class="label">Admin Notes:</label>
            <div v-if="selectedAdminNotes.length > 0">
                <div class="admin-notes" v-for="note in selectedAdminNotes">
                    <div class="note">{{ note.note }}</div>
                    <div class="note-timestamp">
                        {{ note.timestamp == '' ? 'No date' : simpleDate(note.timestamp.toDate()) }}
                    </div>
                </div>
            </div>
            <div v-if="selectedAdminNotes.length > 0" class="spacer s20"></div>
            <textarea class="input" placeholder="Write admin notes.." v-model="newAdminNote"></textarea>
            <div class="spacer s10"></div>
            <button :disabled="addingAdminNote || newAdminNote == ''" class="button is-info" @click="addAdminNote()">
                {{ addingAdminNote ? 'Adding note..' : 'Add Note' }}
            </button>
        </simple-modal>

        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showPaymentHistory }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Add to Payment History on {{ selectedVessel.vesselName }}</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <!-- <div v-if="selectedVessel.paymentHistory && selectedVessel.paymentHistory.length > 0">
                <h4 class="heading green">Previous Payments:</h4>
                <div v-for="(pmt, idx) in selectedVessel.paymentHistory" :key="`pmt-${idx}`">
                  <div class="pmt-wrapper">
                    <div class="pmt-date heading">{{ simpleDate(pmt.date.toDate()) }}</div>
                    <div class="pmt-details">{{ pmt.invoice }}: {{ pmt.method }}</div>
                    <div class="pmt-notes" v-if="pmt.notes != ''">{{ pmt.notes }}</div>
                  </div>
                </div>
              </div> -->
                    <div v-if="selectedVessel.paymentHistory && selectedVessel.paymentHistory.length > 0">
                        <h4 class="heading green">Previous Payments:</h4>
                        <div v-for="(pmt, idx) in selectedVessel.paymentHistory" :key="`pmt-${idx}`">
                            <div class="pmt-wrapper">
                                <div class="pmt-date heading">{{ simpleDate(pmt.date.toDate()) }} [{{ pmt.action }}]</div>
                                <div class="pmt-details">
                                    <span v-if="pmt.invoice != ''">{{ pmt.invoice }}: </span><span v-if="pmt.method != ''">{{ pmt.method }}</span>
                                </div>
                                <div class="pmt-notes" v-if="pmt.notes != ''">{{ pmt.notes }}</div>
                            </div>
                        </div>
                    </div>

                    <div v-else><h4 class="heading green">No Previous Payments.</h4></div>

                    <div class="spacer s30"></div>

                    <div class="form-group-">
                        <label class="label">Action</label>
                        <div class="select">
                            <select v-model="newPayment.action">
                                <option v-for="action in paymentActions" :key="action.slug">{{ action }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="spacer s20"></div>

                    <div class="form-group-reg">
                        <label class="label">Date</label>
                        <datetime auto input-id="newPaymentDate" v-model="newPayment.date"></datetime>
                    </div>

                    <div class="form-group-">
                        <label class="label">Amount ($)</label>
                        <input
                            placeholder="249"
                            autocomplete="false"
                            class="input small"
                            type="tel"
                            v-model="newPayment.amount"
                            :maxlength="5"
                            v-on:keypress="isNumber($event)"
                            required
                        />
                    </div>

                    <div class="spacer s20"></div>

                    <div class="form-group-">
                        <label class="label">Invoice number</label>
                        <input
                            placeholder="Type number.."
                            autocomplete="false"
                            class="input"
                            type="text"
                            v-model="newPayment.invoice"
                            :maxlength="30"
                            required
                        />
                    </div>

                    <div class="spacer s20"></div>

                    <div class="form-group-">
                        <label class="label">Payment method</label>
                        <div class="select">
                            <select v-model="newPayment.method">
                                <option v-for="method in paymentMethods" :key="method.slug">{{ method }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="spacer s20"></div>

                    <div class="form-group-">
                        <label class="label">Person name</label>
                        <input
                            placeholder="Type number.."
                            autocomplete="false"
                            class="input"
                            type="text"
                            v-model="newPayment.username"
                            :maxlength="30"
                            required
                        />
                    </div>

                    <div class="spacer s20"></div>

                    <div class="form-group-">
                        <label class="label">Person userID</label>
                        <input
                            placeholder="Type number.."
                            autocomplete="false"
                            class="input"
                            type="text"
                            v-model="newPayment.userID"
                            :maxlength="30"
                            required
                        />
                    </div>

                    <div class="spacer s20"></div>

                    <div class="form-group-">
                        <label class="label">Notes</label>
                        <textarea class="input" placeholder="Write any notes.." v-model="newPayment.notes"></textarea>
                    </div>

                    <div class="spacer s30"></div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="updatePaymentHistory()">Save Payment</button>
                    <button class="button is-cancel" @click="close">Cancel</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '../main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
const SimpleModal = () => import('../reusables/SimpleModal.vue');

export default {
    name: 'AdminVessels',
    components: {
        SimpleModal
    },
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);

        let squareScript = document.createElement('script');
        squareScript.setAttribute('src', 'https://sandbox.web.squarecdn.com/v1/square.js');
        document.head.appendChild(squareScript);

        let self = this;
        setTimeout(() => {
            // loadSavedCards();
            loadSquarePlans();
            // self.daysLeft = self.daysLeftInPeriod();
            // self.updateDaysLeftInPeriod();
        }, 1000);

        // function loadSavedCards() {
        //     // const q = query(collection(getFirestore(), "vessels/" + self.currentVesselID + '/ccof'));
        //     // const cards = onSnapshot(q, (querySnapshot) => {
        //     //     self.savedCards = [];
        //     //     querySnapshot.forEach((doc) => {
        //     //         // console.log(doc.data());
        //     //         let data = doc.data();
        //     //         data.docID = doc.id;
        //     //         data.selected = false;

        //     //         let d = new Date();
        //     //         let thisMonth = d.getMonth() + 1;
        //     //         let thisYear = d.getFullYear();
        //     //         let yearPast = parseInt(thisYear) > parseInt(data.expYear);
        //     //         let yearCurrent = parseInt(thisYear) == parseInt(data.expYear);
        //     //         let monthPast = parseInt(thisMonth) > parseInt(data.expMonth);
        //     //         data.isExpired = yearPast || (yearCurrent && monthPast);

        //     //         self.savedCards.push(data);
        //     //     });
        //     // });
        //     //
        //     // let self = this;
        //     //
        //     db.collection('vessels').doc(self.currentVesselID).collection('ccof').onSnapshot((querySnapshot) => {
        //         self.savedCards = [];
        //         querySnapshot.forEach((doc) => {
        //             // console.log(doc.data());
        //             let data = doc.data();
        //             data.docID = doc.id;
        //             data.selected = false;

        //             let d = new Date();
        //             let thisMonth = d.getMonth() + 1;
        //             let thisYear = d.getFullYear();
        //             let yearPast = parseInt(thisYear) > parseInt(data.expYear);
        //             let yearCurrent = parseInt(thisYear) == parseInt(data.expYear);
        //             let monthPast = parseInt(thisMonth) > parseInt(data.expMonth);
        //             data.isExpired = yearPast || (yearCurrent && monthPast);

        //             self.savedCards.push(data);
        //         });
        //     });

        // }

        function loadSquarePlans() {
            // const q = query(collection(getFirestore(), "square/plans/subscriptions"), where("isSandbox","==",true), where("active","==",true));
            // const cards = onSnapshot(q, (querySnapshot) => {
            //     self.allPlans = [];
            //     querySnapshot.forEach((doc) => {
            //         let data = doc.data();
            //         // console.log(data);
            //         data.id = doc.id;
            //         data.selected = false;
            //         self.allPlans.push(data);
            //     });
            // });
            //
            // let self = this;
            db.collection('square/plans/subscriptions')
                .where('isSandbox', '==', false)
                .where('active', '==', true)
                .onSnapshot(querySnapshot => {
                    self.allPlans = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        // data.selected = self.vesselData.currentSquarePlanID == doc.id ? true : false;
                        self.allPlans.push(data);
                    });
                });
        }

        setTimeout(() => {
            // this.checkSquareCustomerStatus();
        }, 1500);
    },

    data: function() {
        return {
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            allVessels: [],
            hideDemo: true,
            hidePast: true,
            hideExpired: true,
            selectedVessel: {},
            showUpdateModal: false,
            selectedIDs: [],
            selectedVessels: [],
            recentFirst: false,

            // emailType: 'logs-sms',
            // emailTypes: [
            //   {slug: 'logs-sms', title: '$149 - Logs + SMS'},
            //   {slug: 'pro', title: '$249 - Pro'},
            // ],
            emailType: 'due',
            emailTypes: [
                { slug: 'due', title: 'Expiry is due - send renewal reminder' },
                { slug: 'expired', title: 'Subscription expired - send email with offer' }
            ],

            allPlans: [],
            showPlans: false,
            selectedPlan: {},
            showSetPlanID: false,
            showInvoiceDetails: false,
            today: new Date().getTime(),

            showGetUserName: false,
            renewalEmailInfo: {
                firstName: '',
                userEmail: '',
                vesselName: '',
                vesselID: '',
                price: '',
                vesselOwnerID: '',
                vesselOwnerName: '',
                isCompanyVessel: true,
                checkoutLink: '',
                recipients: [],
                addEmail: '',
                adminUsers: [],
                renewalMessage: "Hi team. It's that time of year and the subscription on your vessels are due for renewal.",
                invoiceLink: '',
                autoRenew: false
            },
            showRenewalAlertModal: false,
            showPaymentAlertMessage: false,
            alertEmailInfo: {
                firstName: '',
                userEmail: '',
                renewalMessage: "It's that time of year and the subscription on your vessels are due for renewal.",
                confirmationMessage: 'Your vessel has now been renewed and is good to go.',
                invoiceLink: '',
                autoRenew: false
            },

            showPaymentHistory: false,
            newPayment: {
                action: 'payment-made',
                date: new Date().toISOString(),
                amount: 0,
                invoice: '',
                method: 'N/A',
                notes: '',
                username: '',
                userID: ''
            },
            paymentMethods: ['N/A', 'Square: Credit Card', 'Square: EFT', 'Xero: EFT', 'Xero: Credit Card', 'Other'],
            paymentActions: [
                'add-vessel',
                'trial-start',
                'trial-extend',
                'invoice-sent',
                'trial-end',
                'plan-change',
                'expire-vessel',
                'charge-date-change',
                'payment-made',
                'confirmation-sent'
            ],
            futureDate: new Date('2025-09-30'),
            selectedAdminNotes: [],
            newAdminNote: '',
            addingAdminNote: false,
            showAdminNotes: false
        };
    },

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false;
        },
        filteredVessels() {
            let self = this;
            // return this.allVessels.filter((vessel) => vessel.tempSelected).map((vess) => vess);
            return this.allVessels.filter(function(vessel) {
                // return self.hidePast ? (self.hideDemo ? !vessel.isPast && !vessel.isDemo : !vessel.isPast) : self.hideDemo ? !vessel.isDemo : vessel;
                if (self.hidePast && self.hideDemo && self.hideExpired) {
                    return !vessel.isPast && !vessel.isDemo && !vessel.isExpired;
                } else if (!self.hidePast && !self.hideDemo && !self.hideExpired) {
                    return vessel;
                } else {
                    return self.hideExpired
                        ? self.hidePast
                            ? self.hideDemo
                                ? !vessel.isPast && !vessel.isDemo && !vessel.isExpired
                                : !vessel.isPast && !vessel.isExpired
                            : self.hideDemo
                            ? !vessel.isDemo && !vessel.isExpired
                            : !vessel.isExpired
                        : self.hidePast
                        ? self.hideDemo
                            ? !vessel.isPast && !vessel.isDemo
                            : !vessel.isPast
                        : self.hideDemo
                        ? !vessel.isDemo
                        : vessel;
                }
            });
        },
        rightNow() {
            return new Date().getTime();
        },

        checkedVessels() {
            return this.filteredVessels.filter(vessel => vessel.tempSelected).map(vess => vess);
        },
        totalCheckedPayments() {
            return this.checkedVessels.length > 0
                ? this.checkedVessels.map(item => item.nextVesselCharge).reduce((acc, current) => parseInt(acc) + parseInt(current))
                : 0;
        },
        totalPayments() {
            return this.filteredVessels.length > 0
                ? this.filteredVessels.map(item => item.nextVesselCharge).reduce((acc, current) => parseInt(acc) + parseInt(current))
                : 0;
        }
    },

    watch: {},

    methods: {
        onUserLogin: function(user) {
            if (user) {
                this.loadAllVessels();
            }
        },

        bulkChangeDate(vessel) {
            let self = this;
            console.log(this.futureDate);
            NProgress.start();
            db.collection('vessels')
                .doc(vessel.vesselID)
                .update({
                    nextChargeDate: new Date(self.futureDate),
                    nextVesselCharge: 249,
                    aaaPlans: ['logs', 'sms', 'pro']
                });
            setTimeout(() => {
                NProgress.done();
                console.log('done');
            }, 500);
        },

        validateEmail(email) {
            return email == '' ? false : this.reg.test(email);
        },

        addRenewalEmail() {
            this.renewalEmailInfo.recipients.push(this.renewalEmailInfo.addEmail);
            this.renewalEmailInfo.addEmail = '';
        },

        dropRenewalEmail(index) {
            this.renewalEmailInfo.recipients.splice(index, 1);
        },

        close() {
            this.selectedVessel = {};
            this.selectedPlan = {};
            this.showUpdateModal = false;
            this.showPlans = false;
            this.showSetPlanID = false;
            this.showInvoiceDetails = false;
            this.showGetUserName = false;
            this.renewalEmailInfo = {
                firstName: '',
                userEmail: '',
                vesselName: '',
                vesselID: '',
                price: '',
                vesselOwnerID: '',
                vesselOwnerName: '',
                isCompanyVessel: true,
                checkoutLink: '',
                recipients: [],
                addEmail: '',
                adminUsers: [],
                renewalMessage: "It's that time of year and the subscription on your vessels are due for renewal.",
                invoiceLink: '',
                autoRenew: false
            };
            this.showRenewalAlertModal = false;
            this.showPaymentAlertMessage = false;
            this.alertEmailInfo = {
                firstName: '',
                userEmail: '',
                renewalMessage: "It's that time of year and the subscription on your vessels are due for renewal.",
                confirmationMessage: 'Your vessel has now been renewed and is good to go.',
                invoiceLink: '',
                autoRenew: false
            };
            this.showPaymentHistory = false;
            this.newPayment = {
                action: 'payment-made',
                date: new Date().toISOString(),
                amount: 0,
                invoice: '',
                method: 'N/A',
                notes: '',
                username: '',
                userID: ''
            };
        },

        getNumberOfDays(start, end) {
            const date1 = new Date(start);
            // const date2 = new Date(end);
            const date2 = new Date();

            // One day in milliseconds
            const oneDay = 1000 * 60 * 60 * 24;

            // Calculating the time difference between two dates
            const diffInTime = date2.getTime() - date1.getTime();

            // Calculating the no. of days between two dates
            const diffInDays = Math.round(diffInTime / oneDay) * -1;

            return diffInDays;
        },

        loadAllVessels: function() {
            let self = this;
            let order = this.recentFirst ? 'desc' : 'asc';
            db.collection('vessels')
                .orderBy('nextChargeDate', order)
                .onSnapshot(function(querySnapshot) {
                    self.allVessels = [];
                    querySnapshot.forEach(doc => {
                        if (doc.data().vesselOwnerName != 'XYZ Orphan') {
                            // let cutoffDate = doc.data().nextChargeDate.toDate();
                            // cutoffDate.setDate(cutoffDate.getDate() - 30);

                            let cutoffDate = new Date();
                            cutoffDate.setDate(cutoffDate.getDate() - 30);

                            self.allVessels.push({
                                vesselID: doc.id,
                                vesselName: doc.data().vesselName,

                                vesselOwnerName: doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?',
                                vesselOwnerEmail: doc.data().vesselOwnerEmail ? doc.data().vesselOwnerEmail : '-',
                                vesselOwnerID: doc.data().vesselOwnerID,
                                isCompanyVessel: doc.data().isCompanyVessel ? doc.data().isCompanyVessel : false,

                                isPaidFor: doc.data().isPaidFor ? doc.data().isPaidFor : false,
                                isDemo: doc.data().isDemo ? doc.data().isDemo : false,
                                isExpired: doc.data().isExpired ? doc.data().isExpired : false,
                                // isExpired: doc.data().isExpired ? doc.data().vesselExpired ? doc.data().isExpired : doc.data().isExpired : false,
                                // lastChargeDate: doc.data().lastChargeDate ? doc.data().lastChargeDate.toDate().toISOString() : '',
                                lastChargeDate: doc.data().lastChargeDate ? doc.data().lastChargeDate.toDate() : '',
                                lastVesselCharge: doc.data().lastVesselCharge ? doc.data().lastVesselCharge : 0,

                                // nextChargeDate: doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate().toISOString() : '',
                                // nextChargeDate: doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate() : '',

                                nextChargeDate:
                                    doc.data().nextChargeDate == undefined ||
                                    doc.data().nextChargeDate === 'string' ||
                                    typeof doc.data().nextChargeDate === 'number'
                                        ? ''
                                        : doc.data().nextChargeDate.toDate(),

                                nextVesselCharge: doc.data().nextVesselCharge ? doc.data().nextVesselCharge : 149,
                                billingUpdated: doc.data().billingUpdated ? doc.data().billingUpdated : false,
                                // isPast: doc.data().nextChargeDate ? moment().isAfter(moment(doc.data().nextChargeDate.toDate())) : false,
                                // isPast: doc.data().nextChargeDate == undefined || doc.data().nextChargeDate === "string" || typeof doc.data().nextChargeDate === "number" ? false : moment().isAfter(moment(doc.data().nextChargeDate.toDate())),
                                isPast:
                                    doc.data().nextChargeDate == undefined ||
                                    doc.data().nextChargeDate === 'string' ||
                                    typeof doc.data().nextChargeDate === 'number'
                                        ? false
                                        : doc
                                              .data()
                                              .nextChargeDate.toDate()
                                              .getTime() < cutoffDate.getTime(),

                                tempSelected: false,

                                hasStartupTags: doc.data().startupTags ? true : false,
                                expiryEmailSentOn: doc.data().expiryEmailSentOn ? doc.data().expiryEmailSentOn.toDate() : '',
                                expiredRenewalEmailSentOn: doc.data().expiredRenewalEmailSentOn ? doc.data().expiredRenewalEmailSentOn.toDate() : '',
                                squareCustomerID: doc.data().squareCustomerID ? doc.data().squareCustomerID : '',
                                currentSquarePlanID: doc.data().currentSquarePlanID ? doc.data().currentSquarePlanID : '',
                                lastInvoiceNumber: doc.data().lastInvoiceNumber ? doc.data().lastInvoiceNumber : '',
                                lastInvoiceStatus: doc.data().lastInvoiceStatus ? doc.data().lastInvoiceStatus : 'not set',
                                lastInvoiceActionDate: doc.data().lastInvoiceActionDate
                                    ? doc
                                          .data()
                                          .lastInvoiceActionDate.toDate()
                                          .toISOString()
                                    : new Date().toISOString(),
                                vesselOwnerSquareCustomerID: doc.data().vesselOwnerSquareCustomerID ? doc.data().vesselOwnerSquareCustomerID : '',
                                renewalOfferSent: doc.data().renewalOfferSent ? doc.data().renewalOfferSent : false,
                                paymentConfirmationSent: doc.data().paymentConfirmationSent ? doc.data().paymentConfirmationSent : false,
                                renewalAlertSent: doc.data().renewalAlertSent ? doc.data().renewalAlertSent : false,
                                lastRenewalAlertDate: doc.data().lastRenewalAlertDate ? doc.data().lastRenewalAlertDate.toDate() : '',
                                paymentHistory: doc.data().paymentHistory ? doc.data().paymentHistory : []
                            });
                        }
                    });
                });
        },

        setPlanID(vessel) {
            console.log(vessel);
            this.selectedVessel = vessel;
            this.showSetPlanID = true;
        },

        showInvoiceStatus(vessel) {
            this.selectedVessel = vessel;
            this.showInvoiceDetails = true;
        },

        isNumber: function($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        simpleDate(date) {
            let dateOptions = { timeZone: 'Australia/Sydney', month: 'short', day: 'numeric', year: 'numeric' };
            return date == '' ? '' : new Date(date).toLocaleDateString('en-US', dateOptions);
        },

        showPaymentHistoryModal(vessel) {
            this.selectedVessel = vessel;
            this.showPaymentHistory = true;
        },

        updatePaymentHistory() {
            let self = this;
            NProgress.start();
            db.collection('vessels')
                .doc(this.selectedVessel.vesselID)
                .update({
                    paymentHistory: firebase.firestore.FieldValue.arrayUnion({
                        action: self.newPayment.action,
                        date: new Date(self.newPayment.date),
                        amount: self.newPayment.amount,
                        invoice: self.newPayment.invoice,
                        method: self.newPayment.method,
                        notes: self.newPayment.notes,
                        username: self.newPayment.username,
                        userID: self.newPayment.userID
                    }),
                    lastPaymentEntryDate: new Date()
                });
            setTimeout(() => {
                self.close();
                NProgress.done();
            }, 500);
        },

        async startRenewalOffer(vessel) {
            console.log(vessel);
            let self = this;
            let useremail = '';
            if (vessel.isCompanyVessel) {
                await db
                    .collection('companies')
                    .doc(vessel.vesselOwnerID)
                    .get()
                    .then(doc => {
                        useremail = doc.data().primaryUserEmail ? doc.data().primaryUserEmail : '';
                    });
            } else {
                await db
                    .collection('userdata')
                    .doc(vessel.vesselOwnerID)
                    .get()
                    .then(doc => {
                        useremail = doc.data().useremail ? doc.data().useremail : '';
                    });
            }
            console.log(useremail);
            this.renewalEmailInfo.vesselName = vessel.vesselName;
            this.renewalEmailInfo.price = '$' + vessel.nextVesselCharge.toString();
            this.renewalEmailInfo.userEmail = useremail;
            this.renewalEmailInfo.vesselOwnerID = vessel.vesselOwnerID;
            this.renewalEmailInfo.vesselOwnerName = vessel.vesselOwnerName;
            this.renewalEmailInfo.isCompanyVessel = vessel.isCompanyVessel;
            this.renewalEmailInfo.vesselID = vessel.vesselID;
            this.renewalEmailInfo.checkoutLink = vessel.nextVesselCharge == 249 ? 'https://square.link/u/Bo2ufTD1' : 'https://square.link/u/qshAbMEF';
            if (useremail != '') {
                this.showGetUserName = true;
            }
        },

        sendRenewalOffer() {
            let self = this;
            NProgress.start();
            this.showGetUserName = false;
            console.log(this.renewalEmailInfo);
            let sendRenewalInvitation = firebase.functions().httpsCallable('sendRenewalInvitation');
            sendRenewalInvitation({
                useremail: this.renewalEmailInfo.userEmail,
                // useremail: 'steve@offshoresms.com.au',
                username: this.renewalEmailInfo.firstName,
                vesselName: this.renewalEmailInfo.vesselName,
                price: this.renewalEmailInfo.price,
                checkoutLink: this.renewalEmailInfo.checkoutLink
            });
            db.collection('vessels')
                .doc(self.renewalEmailInfo.vesselID)
                .update({
                    renewalOfferSent: true
                });
            setTimeout(() => {
                NProgress.done();
                self.close();
            }, 1500);
        },

        async startRenewalAlert(vessel) {
            NProgress.start();
            console.log(vessel);
            let useremail = '';
            let self = this;
            this.renewalEmailInfo.recipients = [];
            this.renewalEmailInfo.adminUsers = [];
            if (vessel.isCompanyVessel) {
                await db
                    .collection('companies')
                    .doc(vessel.vesselOwnerID)
                    .get()
                    .then(async doc => {
                        let temp = [];
                        let primaryEmail = doc.data().primaryUserEmail ? doc.data().primaryUserEmail : '';
                        let adminEmails = doc.data().adminEmails ? doc.data().adminEmails : [];
                        self.renewalEmailInfo.adminUsers = doc.data().adminUsers ? doc.data().adminUsers : [];
                        if (primaryEmail != '') self.renewalEmailInfo.recipients.push(primaryEmail);
                        await Promise.all(
                            adminEmails.map(async email => {
                                if (!self.renewalEmailInfo.recipients.includes(email)) self.renewalEmailInfo.recipients.push(email);
                            })
                        );
                        // self.renewalEmailInfo.recipients = [...temp, ...adminEmails];
                    });
            } else {
                await db
                    .collection('userdata')
                    .doc(vessel.vesselOwnerID)
                    .get()
                    .then(doc => {
                        useremail = doc.data().useremail ? doc.data().useremail : '';
                        if (useremail != '') self.renewalEmailInfo.recipients = [useremail];
                        self.renewalEmailInfo.adminUsers = [vessel.vesselOwnerID];
                    });
            }
            console.log(this.renewalEmailInfo.recipients);
            // this.alertEmailInfo.userEmail = useremail;
            this.renewalEmailInfo.vesselOwnerID = vessel.vesselOwnerID;
            this.renewalEmailInfo.vesselOwnerName = vessel.vesselOwnerName;
            this.renewalEmailInfo.isCompanyVessel = vessel.isCompanyVessel;
            this.renewalEmailInfo.vesselID = vessel.vesselID;
            this.renewalEmailInfo.vesselName = vessel.vesselName;
            if (this.renewalEmailInfo.recipients.length > 0) {
                this.showRenewalAlertModal = true;
            }
            setTimeout(() => {
                NProgress.done();
            }, 500);
        },

        sendRenewalAlert() {
            console.log(this.renewalEmailInfo);
            let self = this;
            NProgress.start();
            this.showRenewalAlertModal = false;

            let emailData = {
                renewalMessage: this.renewalEmailInfo.renewalMessage,
                invoiceLink: this.renewalEmailInfo.invoiceLink,
                autoRenew: this.renewalEmailInfo.autoRenew,
                recipients: this.renewalEmailInfo.recipients,
                emailSubject: 'Vessel subscription reminder: ' + this.renewalEmailInfo.vesselName,
                adminUsers: this.renewalEmailInfo.adminUsers,
                vesselName: this.renewalEmailInfo.vesselName
            };
            console.log(emailData);

            var sendRenewalAlerts = firebase.functions().httpsCallable('sendRenewalAlerts');
            sendRenewalAlerts(emailData)
                // .then(() => {
                //   console.log('Success!');
                //   db.collection('vessels').doc(self.renewalEmailInfo.vesselID).update({
                //     renewalAlertSent: true,
                //     lastRenewalAlertDate: new Date()
                //   });
                // })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });

            db.collection('vessels')
                .doc(self.renewalEmailInfo.vesselID)
                .update({
                    renewalAlertSent: true,
                    lastRenewalAlertDate: new Date()
                });

            setTimeout(() => {
                NProgress.done();
                self.close();
            }, 1000);
        },

        markRenewalAsSent() {
            NProgress.start();
            db.collection('vessels')
                .doc(this.renewalEmailInfo.vesselID)
                .update({
                    renewalAlertSent: true,
                    lastRenewalAlertDate: new Date()
                });
            setTimeout(() => {
                NProgress.done();
                this.close();
            }, 500);
        },

        async startPaymentConfirmation(vessel) {
            NProgress.start();
            console.log(vessel);
            let useremail = '';
            let self = this;
            this.renewalEmailInfo.recipients = [];
            this.renewalEmailInfo.adminUsers = [];
            this.renewalEmailInfo.renewalMessage = 'Thank you for your recent payment. We are stoked to have you on board!';
            if (vessel.isCompanyVessel) {
                await db
                    .collection('companies')
                    .doc(vessel.vesselOwnerID)
                    .get()
                    .then(async doc => {
                        let temp = [];
                        let primaryEmail = doc.data().primaryUserEmail ? doc.data().primaryUserEmail : '';
                        let adminEmails = doc.data().adminEmails ? doc.data().adminEmails : [];
                        self.renewalEmailInfo.adminUsers = doc.data().adminUsers ? doc.data().adminUsers : [];
                        if (primaryEmail != '') self.renewalEmailInfo.recipients.push(primaryEmail);
                        await Promise.all(
                            adminEmails.map(async email => {
                                if (!self.renewalEmailInfo.recipients.includes(email)) self.renewalEmailInfo.recipients.push(email);
                            })
                        );
                        // self.renewalEmailInfo.recipients = [...temp, ...adminEmails];
                    });
            } else {
                await db
                    .collection('userdata')
                    .doc(vessel.vesselOwnerID)
                    .get()
                    .then(doc => {
                        useremail = doc.data().useremail ? doc.data().useremail : '';
                        if (useremail != '') self.renewalEmailInfo.recipients = [useremail];
                        self.renewalEmailInfo.adminUsers = [vessel.vesselOwnerID];
                    });
            }
            console.log(this.renewalEmailInfo.recipients);
            // this.alertEmailInfo.userEmail = useremail;
            this.renewalEmailInfo.vesselOwnerID = vessel.vesselOwnerID;
            this.renewalEmailInfo.vesselOwnerName = vessel.vesselOwnerName;
            this.renewalEmailInfo.isCompanyVessel = vessel.isCompanyVessel;
            this.renewalEmailInfo.vesselID = vessel.vesselID;
            this.renewalEmailInfo.vesselName = vessel.vesselName;
            if (this.renewalEmailInfo.recipients.length > 0) {
                this.showPaymentAlertMessage = true;
            }
            setTimeout(() => {
                NProgress.done();
            }, 500);
        },

        sendPaymentConfirmationEmails() {
            console.log(this.renewalEmailInfo);
            let self = this;
            NProgress.start();
            this.showPaymentAlertMessage = false;

            let emailData = {
                renewalMessage: this.renewalEmailInfo.renewalMessage,
                invoiceNumber: this.renewalEmailInfo.invoiceLink,
                autoRenew: this.renewalEmailInfo.autoRenew,
                recipients: this.renewalEmailInfo.recipients,
                emailSubject: 'Payment confirmation: ' + this.renewalEmailInfo.vesselName,
                adminUsers: this.renewalEmailInfo.adminUsers,
                vesselName: this.renewalEmailInfo.vesselName
            };
            console.log(emailData);

            var sendPaymentConfirmation = firebase.functions().httpsCallable('sendPaymentConfirmation');
            sendPaymentConfirmation(emailData).catch(function(error) {
                console.log('Error', error.text);
                NProgress.done();
            });

            db.collection('vessels')
                .doc(self.renewalEmailInfo.vesselID)
                .update({
                    paymentConfirmationSent: true
                });

            let addToVesselPaymentHistory = firebase.functions().httpsCallable('addToVesselPaymentHistory');
            addToVesselPaymentHistory({
                vesselID: this.renewalEmailInfo.vesselID,
                action: 'confirmation-sent',
                date: new Date().getTime(),
                amount: 0,
                invoice: this.renewalEmailInfo.invoiceLink,
                method: '',
                notes: 'Payment confirmation sent by OffshoreSMS',
                username: 'OffshoreSMS Team',
                userID: ''
            });

            setTimeout(() => {
                NProgress.done();
                self.close();
            }, 1000);
        },

        markConfirmationAsSent() {
            NProgress.start();
            db.collection('vessels')
                .doc(this.renewalEmailInfo.vesselID)
                .update({
                    paymentConfirmationSent: true
                });
            setTimeout(() => {
                NProgress.done();
                this.close();
            }, 500);
        },

        expireAsset(vesselID) {
            let self = this;
            let message;
            message = {
                title: 'Expire this asset?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES, EXPIRE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    db.collection('vessels')
                        .doc(vesselID)
                        .update({
                            isExpired: true
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        addAdminNote() {},

        timeSinceActionDate(actionDate) {
            if (actionDate == undefined || actionDate == '') return 0;
            let t = new Date(actionDate).getTime();
            // let diff = Math.round((t + (frequency*1000*60*60*24) - this.today)/(1000*60*60*24));
            let diff = Math.round((this.today - t) / (1000 * 60 * 60 * 24));
            return diff;
        },

        setSquarePlanID() {
            console.log(this.selectedVessel);
            let self = this;
            NProgress.start();
            db.collection('vessels')
                .doc(self.selectedVessel.vesselID)
                .update({
                    currentSquarePlanID: self.selectedVessel.currentSquarePlanID,
                    nextVesselCharge: parseInt(self.selectedVessel.nextVesselCharge)
                });
            setTimeout(() => {
                self.close();
                NProgress.done();
            }, 200);
        },

        setInvoiceStatus() {
            let self = this;
            NProgress.start();
            db.collection('vessels')
                .doc(self.selectedVessel.vesselID)
                .update({
                    lastInvoiceNumber: self.selectedVessel.lastInvoiceNumber,
                    lastInvoiceStatus: self.selectedVessel.lastInvoiceStatus,
                    lastInvoiceActionDate: new Date(self.selectedVessel.lastInvoiceActionDate)
                });
            setTimeout(() => {
                self.close();
                NProgress.done();
            }, 1000);
            // .then(() => {
            //   self.close();
            //   NProgress.done();
            // })
            // .catch((error) => {
            //   console.log(error);
            // });
        },

        reverseOrder() {
            this.loadAllVessels();
            console.log(this.recentFirst);
        },

        clearVessels() {
            this.loadAllVessels();
        },

        fixTags() {
            let self = this;
            let message;
            message = {
                title: 'Fix these Tags?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES, FIX',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    var adminFixStartupTags = firebase.functions().httpsCallable('adminFixStartupTags');
                    adminFixStartupTags({
                        listOfVesselIDs: self.selectedIDs
                    })
                        .then(function(result) {
                            self.close();
                            NProgress.done();
                        })
                        .catch(function(error) {
                            console.log('Error', error.text);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        sendRenewalEmails() {
            // console.log(this.emailType)
            // console.log(this.selectedVessels)
            if (this.emailType == 'expired') {
                this.sendVesselsExpiredEmail();
            } else {
                this.sendExpiriesDueEmail();
            }
        },

        async sendExpiriesDueEmail() {
            console.log('sending due emails');
            // vessel must still be subscribed, but due to expire soon
            NProgress.start();
            let self = this;
            let sendList = [];
            await Promise.all(
                this.selectedVessels.map(async (vessel, index) => {
                    let ownerEmails = [];
                    let ownerIDs = [];
                    let vesselOwnerID = vessel.vesselOwnerID;
                    let vesselName = vessel.vesselName;
                    let expiryDate = self.formatDate(vessel.nextChargeDate, 'DD MMMM, YYYY');
                    let daysLeft = this.getNumberOfDays(vessel.nextChargeDate, this.rightNow);
                    // await db.collection('vessels').doc(vessel.vesselID).get().then(async (doc) => {
                    //   if (doc.data().isCompanyVessel) {
                    //     await db.collection('companies').doc(doc.data().vesselOwnerID).get().then((comp) => {
                    //       ownerEmails = comp.data().adminEmails ? comp.data().adminEmails : [];
                    //       ownerIDs = comp.data().adminUsers ? comp.data().adminUsers : [];
                    //     });
                    //   } else {
                    //     await db.collection('userdata').doc(ownerID).get().then(async (doc) => {
                    //       ownerEmail = doc.data().useremail ? doc.data().useremail : 'noreply@offshoresms.com.au';
                    //     });
                    //     //
                    //     ownerEmails.push(doc.data().vesselOwnerEmail ? doc.data().vesselOwnerEmail : 'noreply@offshoresms.com.au');
                    //     ownerIDs.push(doc.data().vesselOwnerID ? doc.data().vesselOwnerID : '');
                    //   }
                    // });
                    if (vessel.isCompanyVessel) {
                        await db
                            .collection('companies')
                            .doc(vesselOwnerID)
                            .get()
                            .then(comp => {
                                ownerEmails = comp.data().adminEmails ? comp.data().adminEmails : [];
                                ownerIDs = comp.data().adminUsers ? comp.data().adminUsers : [];
                            });
                    } else {
                        await db
                            .collection('userdata')
                            .doc(vesselOwnerID)
                            .get()
                            .then(async doc => {
                                ownerEmails.push(doc.data().useremail ? doc.data().useremail : 'noreply@offshoresms.com.au');
                                ownerIDs.push(vesselOwnerID);
                            });
                        //
                        // ownerEmails.push(doc.data().vesselOwnerEmail ? doc.data().vesselOwnerEmail : 'noreply@offshoresms.com.au');
                        // ownerIDs.push(doc.data().vesselOwnerID ? doc.data().vesselOwnerID : '');
                    }
                    console.log(ownerEmails);
                    console.log(`Emailing ${ownerEmails.join(', ')} to say that ${vesselName} expires in ${daysLeft} days on ${expiryDate}`);
                    sendList.push({
                        vesselName: vesselName,
                        vesselID: vessel.vesselID,
                        expiryDate: expiryDate,
                        daysLeft: daysLeft,
                        ownerEmails: ownerEmails,
                        ownerIDs: ownerIDs,
                        period: daysLeft
                    });
                })
            );
            console.log('sendList', sendList);
            var sendRenewalNotices = firebase.functions().httpsCallable('sendRenewalNotices');
            sendRenewalNotices({
                sendList: sendList
            })
                .then(() => {
                    console.log('Success!');
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        },

        async sendVesselsExpiredEmail() {
            console.log('sending expired emails');
            // vessel must have already expired
            NProgress.start();
            let self = this;
            let sendList = [];
            await Promise.all(
                this.selectedVessels.map(async (vessel, index) => {
                    let ownerEmails = [];
                    let ownerIDs = [];
                    let vesselName = vessel.vesselName;
                    let nextVesselCharge = vessel.nextVesselCharge;
                    let ownerName = vessel.vesselOwnerName;
                    let vesselOwnerID = vessel.vesselOwnerID;
                    if (vessel.isCompanyVessel) {
                        await db
                            .collection('companies')
                            .doc(vesselOwnerID)
                            .get()
                            .then(comp => {
                                ownerEmails = comp.data().adminEmails ? comp.data().adminEmails : [];
                                ownerIDs = comp.data().adminUsers ? comp.data().adminUsers : [];
                            });
                    } else {
                        await db
                            .collection('userdata')
                            .doc(vesselOwnerID)
                            .get()
                            .then(async doc => {
                                ownerEmails.push(doc.data().useremail ? doc.data().useremail : 'noreply@offshoresms.com.au');
                                ownerIDs.push(vesselOwnerID);
                            });
                    }
                    console.log(ownerEmails);
                    console.log(`Emailing ${ownerEmails.join(', ')} to offer 3 months free to renew`);
                    sendList.push({
                        vesselName: vesselName,
                        vesselID: vessel.vesselID,
                        nextVesselCharge: nextVesselCharge,
                        ownerName: ownerName,
                        ownerEmails: ownerEmails,
                        ownerIDs: ownerIDs
                    });
                })
            );
            console.log('sendList', sendList);
            var sendExpiredVesselRenewalEmail = firebase.functions().httpsCallable('sendExpiredVesselRenewalEmail');
            sendExpiredVesselRenewalEmail({
                sendList: sendList
            })
                .then(() => {
                    console.log('Success!');
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        },

        formatDate: function(sentDate, format) {
            return moment(sentDate).format(format);
        },

        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },

        setUpdateVessel(vessel) {
            console.log(vessel);
            if (this.isSteve) {
                this.selectedVessel = vessel;
                this.showUpdateModal = true;
            }
        },

        updateVesselInfo() {
            // console.log(this.selectedVessel);
            let self = this;
            this.showUpdateModal = false;
            let message;
            message = {
                title: 'Confirm Update?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES, UPDATE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    var adminUpdateVesselDataSimple = firebase.functions().httpsCallable('adminUpdateVesselDataSimple');
                    adminUpdateVesselDataSimple({
                        vesselID: self.selectedVessel.vesselID,
                        vesselName: self.selectedVessel.vesselName,
                        updateArray: {
                            isDemo: self.selectedVessel.isDemo,
                            isPaidFor: self.selectedVessel.isPaidFor,
                            isExpired: self.selectedVessel.isExpired
                        }
                    })
                        .then(function(result) {
                            self.close();
                            NProgress.done();
                        })
                        .catch(function(error) {
                            console.log('Error', error.text);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        // start new square functions =====

        chooseSquarePlanToAdd(vessel) {
            this.selectedVessel = vessel;
            this.showPlans = true;
        },

        addNewPlanSubscription() {
            console.log(this.selectedPlan.id, this.selectedVessel.vesselID);
            let self = this;
            if (this.selectedPlan.id && this.selectedVessel.vesselID) {
                NProgress.start();
                self.showPlans = false;
                db.collection('vessels')
                    .doc(self.selectedVessel.vesselID)
                    .update({
                        currentSquarePlanID: self.selectedPlan.id
                    })
                    .then(() => {
                        console.log('currentSquarePlanID Updated');
                        self.close();
                        NProgress.done();
                    })
                    .catch(error => {
                        console.log('Error', error);
                        self.close();
                        NProgress.done();
                    });
            }
        },

        async createNewSquareCustomerChildFunction(vessel, userphone, useremail) {
            let self = this;
            let customerData = {
                givenName: 'Vessel: ',
                familyName: vessel.vesselName ? vessel.vesselName : 'No name',
                companyName: vessel.vesselOwnerName ? vessel.vesselOwnerName : 'OffshoreSMS',
                emailAddress: useremail ? useremail : 'help@offshoresms.com.au',
                phoneNumber: userphone ? userphone : '+61447139382',
                referenceId: vessel.vesselID,
                note: 'OffshoreSMS vessel called ' + vessel.vesselName + ' (vesselID: ' + vessel.vesselID + ')'
            };

            var createNewCustomerObject = firebase.functions().httpsCallable('createNewCustomerObject');
            createNewCustomerObject({
                vesselID: vessel.vesselID,
                customerData: customerData
            })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    console.log('Result', sanitizedMessage);
                    console.log('Success?', result.data.success);
                    if (result.data.success) {
                        // customer created, save id and object to vessel data in fb
                        console.log('Vessel successfully created as a customer in Square with customer ID: ' + sanitizedMessage.customer.id);
                        self.notice = '<span style="color: green">&nbsp; Vessel details updated.</span>';
                        // self.squareCustomerID = sanitizedMessage.customer.id;
                        self.updateVesselData(vessel.vesselID, {
                            squareCustomerID: sanitizedMessage.customer.id
                        });
                        // self.showNotice = false;
                        // self.notice = '';
                    } else {
                        // self.notice = '<span style="color: red">&nbsp; Something went wrong! Please call our team on <br><a href="tel:+61-447-139-382">+61 447 139 382</a>.</span>';
                    }
                    NProgress.done();
                    self.close();
                })
                .catch(function(result) {
                    console.log('result', result);
                    // console.log('Success?',result.success);
                    // console.log(result.data);
                    // const sanitizedMessage = result.data.text;
                    // console.log('sanitizedMessage:',sanitizedMessage);
                    // self.notice = '<span style="color: red">&nbsp; Something went wrong! Please call our team on <br><a href="tel:+61-447-139-382">+61 447 139 382</a>.</span>';

                    NProgress.done();
                    self.close();
                });
        },

        // // if no customer exists in square, either because no saved squareCustomerID or if the saved one is a mismatch, then create a new customerID for this vessel
        // async createNewSquareCustomer(vessel) {
        //   console.log(vessel);
        //   let self = this;
        //   let userphone = '';
        //   let useremail = '';

        //   NProgress.start();

        //   if (vessel.isCompanyVessel) {
        //     await db.collection('companies').doc(vessel.vesselOwnerID).get().then((doc) => {
        //       userphone = doc.data().primaryUserPhone ? doc.data().primaryUserPhone : '+61447139382';
        //       useremail = doc.data().primaryUserEmail ? doc.data().primaryUserEmail : 'help@offshoresms.com.au';
        //       console.log(`vesselID: ${vessel.vesselID}, vesselName: ${vessel.vesselName}, useremail: ${useremail},userphone: ${userphone} `);
        //       self.createNewSquareCustomerChildFunction(vessel,userphone,useremail);
        //     });
        //   } else {
        //     await db.collection('userdata').doc(vessel.vesselOwnerID).get().then((doc) => {
        //       userphone = doc.data().userphone ? doc.data().userphone : '+61447139382';
        //       useremail = doc.data().useremail ? doc.data().useremail : 'help@offshoresms.com.au';
        //       console.log(`vesselID: ${vessel.vesselID}, vesselName: ${vessel.vesselName}, useremail: ${useremail},userphone: ${userphone} `);
        //       self.createNewSquareCustomerChildFunction(vessel,userphone,useremail);
        //     });
        //   }

        // },

        // update vessel in fb database with new data, ie new customer id when created in Square
        async updateVesselData(vesselID, updateData) {
            console.log(vesselID, updateData);
            db.collection('vessels')
                .doc(vesselID)
                .update(updateData);
        },

        getStartDate(date) {
            var mm = date.getMonth() + 1; // getMonth() is zero-based
            var dd = date.getDate();

            return [date.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-');
        },

        createNewPlanSubscription(vessel) {
            let idempotencyKey = uuidv4();
            let startDate = this.getStartDate(vessel.nextChargeDate);

            let subscriptionData = {
                idempotencyKey: idempotencyKey,
                locationId: '40ZFX2VT9XGE3',
                planId: vessel.currentSquarePlanID,
                customerId: vessel.squareCustomerID,
                // cardId: data.cardID, // not set here - creates subscription and customer gets invoiced
                tax_percentage: '10',
                start_date: startDate
            };
            console.log(subscriptionData);

            // var addNewPlanSubscription = firebase.functions().httpsCallable('addNewPlanSubscription');
            // addNewPlanSubscription(subscriptionData)
            // .then(function(result) {
            //     var sanitizedMessage = JSON.parse(result.data.text);
            //     console.log('Result',sanitizedMessage);
            //     console.log('Success?',result.data.success);
            //     if (result.data.success) {
            //         console.log('Vessel subscription created successfully: ' + sanitizedMessage.customer.id);

            //         // update vessel with new subscription:
            //         // self.updateVesselData(vessel.vesselID, {

            //         // });

            //     } else {
            //         console.log('Success but still a fail - why?');
            //     }
            //     NProgress.done();
            //     self.close();
            // })
            // .catch(function(result) {
            //     console.log('result',result);
            //     // console.log('Success?',result.success);
            //     // console.log(result.data);
            //     // const sanitizedMessage = result.data.text;
            //     // console.log('sanitizedMessage:',sanitizedMessage);
            //     // self.notice = '<span style="color: red">&nbsp; Something went wrong! Please call our team on <br><a href="tel:+61-447-139-382">+61 447 139 382</a>.</span>';

            //     NProgress.done();
            //     self.close();

            // });
        },

        // NEW SQUARE CUSTOMER FUNCTIONS: ===========================================

        async getCompanySquareID() {
            let self = this;
            NProgress.start();
            console.log('Getting company square customer ID');
            await db
                .collection('companies')
                .doc(this.selectedVessel.vesselOwnerID)
                .get()
                .then(doc => {
                    if (doc.data().squareCustomerID) {
                        console.log('Fleet SquareCustomerID is ', doc.data().squareCustomerID);
                        self.updateVesselWithSquareCustomerID({
                            vesselOwnerSquareCustomerID: doc.data().squareCustomerID
                        });
                        NProgress.done();
                    } else {
                        console.log('No company square customer ID found');
                        NProgress.done();
                    }
                });
        },

        async checkIfCustomerExists(vessel) {
            this.selectedVessel = vessel;

            if (vessel.isCompanyVessel) {
                this.getCompanySquareID();
                return;
            }

            let vesselOwnerID = vessel.vesselOwnerID;
            if (!vesselOwnerID || vesselOwnerID == '') return;

            let user = {
                userid: vessel.vesselOwnerID,
                squareCustomerID: '',
                username: '',
                useremail: '',
                userphone: ''
            };

            NProgress.start();
            console.log('vesselOwnerID', vesselOwnerID);
            await db
                .collection('userdata')
                .doc(vesselOwnerID)
                .get()
                .then(doc => {
                    console.log(doc.data());
                    user.squareCustomerID = doc.data().squareCustomerID ? doc.data().squareCustomerID : '';
                    user.username = doc.data().username ? doc.data().username : '';
                    user.useremail = doc.data().useremail ? doc.data().useremail : '';
                    user.userphone = doc.data().userphone ? doc.data().userphone : '';
                });
            console.log('User', user);

            if (user.squareCustomerID && user.squareCustomerID != '') {
                this.searchCustomersByCustomerID(user);
            } else {
                this.searchCustomersByReferenceID(user);
            }
        },

        searchCustomersByCustomerID(user) {
            let self = this;
            // console.log(user);
            console.log('This user has a Square Customer ID on file. Retrieving from Square...');

            var retrieveCustomerObject = firebase.functions().httpsCallable('retrieveCustomerObject');
            retrieveCustomerObject({
                customerID: user.squareCustomerID
            })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    // console.log('Result',sanitizedMessage);
                    // console.log('Success?',result.data.success);
                    if (result.data.success) {
                        if (sanitizedMessage.customer) {
                            console.log('Customer located in Square with customer ID: ' + sanitizedMessage.customer.id);
                            console.log('Returned referenceId from Square:', sanitizedMessage.customer.referenceId);
                            // NB if the returned square customer ID does not match the saved customer ID then throw alert!
                            // if (user.squareCustomerID != sanitizedMessage.customer.id) {
                            if (user.userid != sanitizedMessage.customer.referenceId) {
                                console.log('User ID mismatched with Square referenceId - fixing in referenceId in Square');
                                self.fixSquareCustomerMismatch(user);
                            } else if (user.squareCustomerID != sanitizedMessage.customer.id) {
                                console.log('squareCustomerID mismatched with Square customerId - fixing in firestore userdata');
                                self.updateUserData(user.userid, {
                                    squareCustomerID: sanitizedMessage.customer.id
                                });
                                self.updateVesselWithSquareCustomerID({ vesselOwnerSquareCustomerID: sanitizedMessage.customer.id });
                                NProgress.done();
                            } else {
                                console.log('Everything OK - customer userid matches up with referenceId in Square');
                                self.updateVesselWithSquareCustomerID({ vesselOwnerSquareCustomerID: user.squareCustomerID });
                                NProgress.done();
                            }
                        } else {
                            console.log('Search found no existing customer object in Square');
                            console.log('Running function: createNewSquareCustomer');
                            // self.createNewSquareCustomer(user); // add new customer if not found
                            self.searchCustomersByReferenceID(user);
                        }
                    } else {
                        // console.log('Error locating customer in Square');
                        // window.alert('The function searchCustomersByCustomerID returned an error');
                        // NProgress.done();
                        console.log('The function searchCustomersByCustomerID returned an error');
                        // if (sanitizedMessage.errors && sanitizedMessage.errors[0].code == 'NOT_FOUND') self.createNewSquareCustomer(user);
                        self.searchCustomersByReferenceID(user);
                    }
                })
                .catch(function(result) {
                    console.log('Cloud function error: retrieveCustomerObject', result.data.text);
                    window.alert('The cloud function retrieveCustomerObject returned an error');
                    NProgress.done();
                });
        },

        fixSquareCustomerMismatch(user) {
            console.log('Function Z in flow diagram - fixing the mismatch');
            this.updateCustomerByCustomerID(user);
        },

        updateCustomerByCustomerID(user) {
            let self = this;
            NProgress.start();
            // console.log(user);
            console.log('Updating customer in Square with current user ID as referenceId...');

            var updateCustomerObject = firebase.functions().httpsCallable('updateCustomerObject');
            updateCustomerObject({
                customerID: user.squareCustomerID,
                updateFields: {
                    referenceId: user.userid
                }
            })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    // console.log('Result',sanitizedMessage);
                    // console.log('Success?',result.data.success);
                    if (result.data.success) {
                        if (sanitizedMessage.customer) {
                            console.log('Customer updated in Square with correct user id');
                            NProgress.done();
                        } else {
                            console.log('Customer update failed.');
                            NProgress.done();
                        }
                    } else {
                        console.log('The function updateCustomerByCustomerID returned an error');
                    }
                })
                .catch(function(error) {
                    console.log('Cloud function error: updateCustomerObject', error);
                    NProgress.done();
                });
        },

        searchCustomersByReferenceID(user) {
            let self = this;
            NProgress.start();
            console.log('This user has no squareCustomerID on file - checking Square for a customer referenceId matching userID: ', user.ownerID);

            var searchCustomerObject = firebase.functions().httpsCallable('searchCustomerObject');
            searchCustomerObject({
                referenceID: user.userid
            })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    if (result.data.success) {
                        if (sanitizedMessage.customers && sanitizedMessage.customers.length > 0) {
                            console.log('Customer objects located in Square: ' + sanitizedMessage.customers.length);
                            console.log(
                                "Updating this user's squareCustomerID with the returned customerId found in Square for this user",
                                sanitizedMessage.customers[0].id
                            );
                            self.updateUserData(user.userid, {
                                squareCustomerID: sanitizedMessage.customers[0].id
                            });
                            self.updateVesselWithSquareCustomerID({ vesselOwnerSquareCustomerID: sanitizedMessage.customers[0].id });
                            NProgress.done();
                        } else {
                            console.log('Search found no existing customer object in Square for this userid');
                            console.log('Running function: createNewSquareCustomer');
                            self.createNewSquareCustomer(user); // add new customer if not found
                        }
                    } else {
                        console.log('Search found no existing customer object in Square for this userid');
                        console.log('Running function: createNewSquareCustomer');
                        if (sanitizedMessage.errors) self.createNewSquareCustomer(user);
                        NProgress.done();
                    }
                })
                .catch(function(result) {
                    console.log('Cloud function error: searchCustomerObject', result.data.text);
                    window.alert('The cloud function searchCustomerObject returned an error');
                    NProgress.done();
                });
        },

        createNewSquareCustomer(user) {
            console.log('Creating new user with referenceID', user.userid);
            NProgress.start();
            let self = this;
            let customerData = {
                givenName: user.username, //
                // familyName: '',
                // companyName: '',
                emailAddress: user.useremail != '' ? user.useremail : '', //
                phoneNumber: user.userphone != '' ? user.userphone : '', //
                referenceId: user.userid, //
                note: user.username + ' (userid: ' + user.userid + ')' //
            };

            var createNewCustomerObject = firebase.functions().httpsCallable('createNewCustomerObject');
            createNewCustomerObject({
                customerData: customerData
            })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    console.log('Result', sanitizedMessage);
                    console.log('Success?', result.data.success);
                    if (result.data.success) {
                        // customer created, save id and object to user data in fb
                        console.log('User successfully created as a customer in Square with customer ID: ' + sanitizedMessage.customer.id);
                        self.updateUserData(user.userid, {
                            squareCustomerID: sanitizedMessage.customer.id
                        });
                        self.updateVesselWithSquareCustomerID({ vesselOwnerSquareCustomerID: sanitizedMessage.customer.id });
                        NProgress.done();
                    } else {
                        console.log('Error running function createNewSquareCustomer');
                        NProgress.done();
                    }
                })
                .catch(function(result) {
                    console.log('Error with cloud function: createNewCustomerObject');
                    console.log('result', result);
                    NProgress.done();
                });
        },

        // update vessel in fb database with new data, ie new customer id when created in Square
        async updateUserData(userID, updateData) {
            console.log(userID, updateData);
            db.collection('userdata')
                .doc(userID)
                .update(updateData);
        },

        async updateVesselWithSquareCustomerID(updateData) {
            db.collection('vessels')
                .doc(this.selectedVessel.vesselID)
                .update(updateData);
        }
    }
};
</script>

<style lang="scss" scoped>
.site-wrapper .container {
    max-width: 1050px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
input[type='text'],
input[type='tel'] {
    padding: 12px;
    max-width: none;
    width: 100%;
    height: auto;
    font-size: 1.1rem;
}
input[type='email'] {
    padding: 10px;
    max-width: none;
    width: 100%;
    height: auto;
    font-size: 1.1rem;
    margin-bottom: 10px;
}
.form-group {
    label {
        top: auto;
        bottom: -25px;
    }
}
.traffic-light {
    position: relative;
    span {
        float: left;
        margin-right: 10px;
    }
    .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
            background: red;
            &.active {
                background: red;
            }
        }
        &.orange {
            background: orange;
            &.active {
                background: orange;
            }
        }
        &.green {
            background: #03d203;
            &.active {
                background: #03d203;
            }
        }
    }
}
.modal {
    .modal-card {
        .modal-card-head {
            z-index: 999 !important;
        }
        .modal-card-foot {
            z-index: 999 !important;
        }
    }
}
table {
    td {
        &.is-updated {
            color: green;
            font-weight: bold;
        }
    }
}

blockquote {
    background: #f5f5f5;
    padding: 2rem;
    margin-top: 1rem;
    border-radius: 4px;
}
.bold {
    font-weight: bold;
}
.is-trial {
    color: #fff;
    background: orange;
    padding: 5px 8px;
    margin-top: 6px;
    display: inline-block;
    margin-right: 6px;
    font-size: 0.9rem;
    border-radius: 3px;
}
.not-trial {
    color: #fff;
    background: red;
    padding: 8px;
    margin-top: 6px;
}
.is-past {
    color: #fff;
    background: limegreen;
    padding: 5px 8px;
    margin-top: 6px;
    display: inline-block;
    margin-right: 6px;
    font-size: 0.9rem;
    border-radius: 3px;
}
.not-past {
    color: #fff;
    background: steelblue;
    padding: 8px;
    margin-top: 6px;
}
.is-expired {
    color: #fff;
    background: red;
    padding: 5px 8px;
    margin-top: 6px;
    display: inline-block;
    margin-right: 6px;
    font-size: 0.9rem;
    border-radius: 3px;
}
// .not-expired {
//   color: #fff;
//   background: steelblue;
//   padding: 8px;
//   margin-top: 6px;
// }
.vessel-name {
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    &:hover {
        color: steelblue;
    }
}
.is-company {
    font-weight: bold;
    color: green;
}
.not-company {
    color: orange;
}
.red {
    color: red;
}
.buttons {
    margin-bottom: 0;
}
.form-group-reg {
    label {
        &.radio-label {
            display: inline-block;
            margin-left: 10px;
            line-height: 17px;
        }
    }
}

.pmt-wrapper {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .pmt-date {
        font-weight: bold;
    }
}
</style>
