<template>

  <div>

    <div class="site-wrapper no-header">

      <div id="page-wrapper" class="container">

                <div id="recaptcha-container"></div>

                <div class="card">

                  <div class="notification is-primary heading">
                    <h2 class="is-size-5 has-text-centered">Admin Sign-in</h2>
                  </div>


                  <div class="tabs is-centered is-boxed">
                    <ul>

                      <li @click="stopProgress" :class="[ signinTab === 'email' ? 'is-active' : '']">
                        <a @click="signinTab='email'"><font-awesome-icon icon="envelope" /><span>Email</span></a>
                      </li>

                      <li @click="stopProgress" :class="[ signinTab === 'phone' ? 'is-active' : '']">
                        <a @click="signinTab='phone'"><font-awesome-icon icon="phone" /><span>Phone</span></a>
                      </li>

                    </ul>
                  </div>

                  <div class="card-content">

                      <div class="tab-content">

                          <div v-if="signinTab === 'email'">


                                <div class="form-group">      
                                  <input class="input" type="email" v-model="email" required>
                                  <span class="highlight"></span>
                                  <span class="bar"></span>
                                  <label>Email address</label>
                                </div>

                                <div class="form-group">      
                                  <input class="input" autocomplete="off" type="password" v-model="password" required>
                                  <span class="highlight"></span>
                                  <span class="bar"></span>
                                  <label>Password</label>
                                </div>

                                <div class=" buttons has-text-centered">                                 
                                  <button @click="signInByEmail" class="button is-primary">Sign-in</button>                                 
                                  <button @click="launchResetPassword" class="button">Reset Password</button>
                                  <p>&nbsp;</p>
                                  <p class="passwordMessage" v-if="pwResetMsg != ''">{{pwResetMsg}}</p> 
                                </div>

                          </div>

                          <div v-if="signinTab === 'phone'">

                                <div v-if="showSmsCode">

                                  <div class="form-group">      
                                    <input class="input" autocomplete="off" type="tel" v-model="smsCode" :maxlength="6" v-on:keypress="isNumber(event)">
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label>Enter SMS Code</label>
                                  </div>

                                  <button :disabled="!showSignInButton" id="phone-signin-button" @click="signInByPhone()" class="button is-primary">Sign in</button>
                                </div>

                                <div v-else>

                                  <div class="form-group tuck">      
                                    <input class="input" autocomplete="off" v-model="phoneNumber" type="tel" :maxlength="10" v-on:keypress="isNumber(event)" required>
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label>Australian mobile number</label>
                                  </div>


                                  <p class="error">{{phoneError}}</p>
                                  <p>{{loginMessage}}</p>
                                  
                                  <div class="buttons">
                                    <button :disabled="!showGetSmsButton" @click="getSMSCode" id="get-sms-button" class="button is-primary">{{showGetSmsButton ? 'Get SMS Code' : 'Mobile number required'}}</button>
                                    <button v-if="phoneNumber.length > 0" class="button" @click="clearNumber">Clear</button>
                                  </div>

                                </div>


                          </div>

                      </div>

                  </div>



                </div>





          <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':isActivePasswordModal}">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
              <!-- <form id="vesselForm" @submit.prevent="resetPassword">  --> 
                <header class="modal-card-head">
                  <p class="modal-card-title">Reset your password:</p>
                  <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">       
                  <div class="form-group">      
                    <input class="input" type="email" v-model="resetEmail" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>Email address:</label>
                  </div>
                </section>
                <footer class="modal-card-foot">
                  <span class="button is-primary" @click="resetPassword" v-show="resetEmail.length">Send reset email</span>
                  <button class="button cancel" @click.prevent="close">Cancel</button>
                </footer>
              <!-- </form> -->
            </div>
          </div>



      </div>

    </div>

  </div>

</template>

<script>

import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; 
import 'firebase/firestore';
import NProgress from 'nprogress';

export default {
  name: 'SignIn',
  // props: [
  //   'reload'
  // ],
  mounted() {
    // console.log("Reload: ",this.reload);
    // if (this.reload) {window.location.reload();}

    // reset reCaptcha
    // grecaptcha.reset(window.recaptchaWidgetId);
    // window.recaptchaVerifier.render().then(function(widgetId) {
    //   grecaptcha.reset(widgetId);
    // });

    // var widgetId = grecaptcha.render(container);
    // grecaptcha.reset(widgetId);

    // ===========
    console.log('Mounted.');
    // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    //   'size': 'invisible',
    //   'callback': function(response) {
    //     console.log(response);
    //     // reCAPTCHA solved, allow signInWithPhoneNumber.
    //     //onSignInSubmit();
    //   },
    //   'expired-callback': function() {
    //     // Response expired. Ask user to solve reCAPTCHA again.
    //     // ...
    //     window.recaptchaVerifier.clear();
    //     console.log('expired');
    //   }
    // });

    //window.recaptchaVerifier.clear();
    
    // const newDiv = document.createElement('div');
    // newDiv.setAttribute("id", "recaptcha-containerx");
    // const pageWrapper =  document.getElementById('page-wrapper');
    // pageWrapper.appendChild(newDiv);

    // setTimeout(function() { 
    //   window.recaptchaVerifier.render().then(function (widgetId) {
    //     console.log('boo');
    //     window.recaptchaWidgetId = widgetId;
    //   });
    // }, 2000);

    
  },
  data: function() {
    return {
      email: '',
      password: '',
      phoneNumber: '',
      signinTab: 'email',
      showSmsCode: false,
      smsCode: '',
      phoneSignInResult: '',
      isActivePasswordModal: false,
      resetEmail: '',
      event: '',
      loginMessage: '',
      canLogin: false,
      pwResetMsg: '',
    };
  },
  computed: {
    showGetSmsButton() {
      //return this.phoneNumber.length == '10';
      return this.phoneNumber.length == 10 && this.phoneNumber.slice(0,2) == '04';
    },
    showSignInButton() {
      return this.smsCode.length == 6;
    },
    user() {
      return this.$store.getters.getUser;
    },
    phoneError() {
      if (this.phoneNumber.length > 0 && this.phoneNumber.length < 10) {
        return this.phoneNumber.slice(0,2) != '04' ? 'Australian mobile numbers must start with 04' : 'Enter a full mobile number';
      } else {
        if (this.phoneNumber.length == 10) {
          return this.phoneNumber.slice(0,2) != '04' ? 'Australian mobile numbers must start with 04' : '';
        } else {
          return '';
        }
      }
    },
    finalNumber() {
      return "+61" + this.phoneNumber.slice(1,10);
    },

  },
  methods: {

    reloadCaptcha: function() {
      console.log('Reload Captcha');

      //window.recaptchaVerifier.clear();

      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': function(response) {
          console.log(response);
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //onSignInSubmit();
        },
        'expired-callback': function() {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
          window.recaptchaVerifier.clear();
          console.log('expired');
        }
      });

      //window.recaptchaVerifier.clear();
      
      // const newDiv = document.createElement('div');
      // newDiv.setAttribute("id", "recaptcha-container");
      // const pageWrapper =  document.getElementById('page-wrapper');
      // pageWrapper.appendChild(newDiv);

      setTimeout(function() { 
        window.recaptchaVerifier.render().then(function (widgetId) {
          window.recaptchaWidgetId = widgetId;
          console.log('boo again',window.recaptchaWidgetId);

          var recaptchaResponse = grecaptcha.getResponse(window.recaptchaWidgetId);
          console.log('VAR',recaptchaResponse);

        });
      }, 2000);

      

      //grecaptcha.reset(widgetId);


    },

    signInByEmail: function() {
      NProgress.start();
      var self = this;
      firebase.auth().signInWithEmailAndPassword(self.email, self.password)
        .then( user => {
            //console.log(user);
            // console.log(user.user.uid);
            self.$store.dispatch('setUser').then(() => {

                //const userName = user.user.displayName;
                // const userName = self.email;
                // const userID = user.user.uid;
                // var userHasEngaged = firebase.functions().httpsCallable('userHasEngaged');
                // userHasEngaged({
                //   userID: userID, 
                //   userName: userName,
                //   message: 'A user has signed in to Offshore SMS by email login.',
                //   subject: 'A user has signed in by email'
                // }); 

                NProgress.done();
                self.$router.push('/');
            });
          },
          error => {
            NProgress.done();
            alert(error.message);
          }
        );
    },
    close: function() {
      this.isActivePasswordModal = false;
      this.resetEmail = '';
      this.showSignUpModal = false;
    },

    clearNumber: function() {
      this.phoneNumber = '';
      this.loginMessage = '';
    },

    toSignUp: function() {
      this.showSignUpModal = false;
      this.$router.push({ name: 'signUp', params: { 
          planType: 'free',
          maxVessels: 0
        }
      });
    },

    stopProgress: function() {
      NProgress.done();
    },

    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    launchResetPassword: function() {
      this.isActivePasswordModal = true
    },
    getSMSCode: function() {
      //

      // 'recaptcha-container' is the ID of an element in the DOM.
      // var applicationVerifier = new firebase.auth.RecaptchaVerifier('get-sms-button');
      // firebase.auth().signInWithPhoneNumber(phoneNumber, applicationVerifier)
      //     .then(function(confirmationResult) {

      //       var verificationCode = window.prompt('Please enter the verification ' +
      //           'code that was sent to your mobile device.');
      //       return confirmationResult.confirm(verificationCode);

      //     })
      //     .catch(function(error) {

      //     });

// recaptchaVerifier.render().then(function(widgetId) {
//   window.recaptchaWidgetId = widgetId;
// });

      //
      NProgress.start();
      var self = this;
      self.loginMessage = "Please wait while we look you up...";
      // Firebase phone auth recaptcha object initialisation
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('get-sms-button', {
        'size': 'invisible',
        'callback': function(response) {
          //console.log(response);
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //onSignInSubmit();
        }
      });
      //
      var phoneNumber = "+61" + this.phoneNumber.slice(1,10);
      var appVerifier = window.recaptchaVerifier;

      self.canLogin = false;
      db.collection("userPhoneNumbers").get()
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
              // doc.data() is never undefined for query doc snapshots
              if (doc.data().number == phoneNumber) {
                //console.log("Number",phoneNumber," exists",doc.data());
                //self.showSmsCode = true;     
                self.canLogin = true;
                return;
              }
          });
      })
      .then(function() {
          if (self.canLogin) {



              firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
              .then(function (confirmationResult) {

                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                //window.confirmationResult = confirmationResult;
                self.phoneSignInResult = confirmationResult;
                //console.log("success",confirmationResult);
                self.showSmsCode = true;
                //console.log("SMS successfully sent",confirmationResult);

                //NProgress.done();

                // var verificationCode = window.prompt('Please enter the verification code that was sent to your mobile device.');
                // return confirmationResult.confirm("123456").then((result) => {
                //   console.log(result);
                // });

              })
              .then((result) => {
                // Phone credential now linked to current user.
                // User now can sign in with email/pass or phone.
                //console.log("Result obtained",result);
                //this.$router.replace('my-dashboard');
                NProgress.done();

              })
              .catch(function (error) {
                // Error; SMS not sent
                console.log(error);
                window.recaptchaVerifier.render().then(function(widgetId) {
                  grecaptcha.reset(widgetId);
                });
                self.loginMessage = "Something went wrong. Please try email login, or try again in a few minutes.";
                NProgress.done();
              });
          
          } else {
            self.loginMessage = "Sorry, phone number not found. Please check number or try email login.";
            NProgress.done();
          }
      }).catch(function(error) {
        console.log(error);
        NProgress.done();
      });

          

    },
    signInByPhone: function() {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('phone-signin-button', {
        'size': 'invisible',
        'callback': function(response) {
          //console.log(response);
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //onSignInSubmit();
        }
      });

      //console.log("starting phone signin...");
      //console.log("SMS Code is: ",this.smsCode);
      NProgress.start();
      var self = this;
      //var code = getCodeFromUserInput();
      self.phoneSignInResult.confirm(self.smsCode).then(function () {
        // User signed in successfully.
        // var user = result.user;
        //console.log("Result",result);
        //console.log("Phone signin successful", user);

        // const userName = user.displayName;
        // const userID = user.uid;
        // var userHasEngaged = firebase.functions().httpsCallable('userHasEngaged');
        // userHasEngaged({
        //   userID: userID, 
        //   userName: userName,
        //   message: 'A user has signed in to Offshore SMS by phone login.',
        //   subject: 'A user has signed in by phone'
        // }); 

        self.$store.dispatch('setUser').then(() => {
            self.$router.replace('/');
        });
        NProgress.done();
      }).catch(function () {
        // User couldn't sign in (bad verification code?)
        self.loginMessage = "Wrong code - please try again.";
        //console.log(error);
        NProgress.done();
      });
    },
    resetPassword: function() {
      //console.log(this.resetEmail);
      //this.resetEmail = '';
      //this.isActivePasswordModal = false;
      NProgress.start();
      var self = this;

      var auth = firebase.auth();

      var actionCodeSettings = {
        url: 'https://admin.offshoresms.com.au/sign-in',
        //url: 'https://www.example.com/?email=user@example.com',
        // iOS: {
        //   bundleId: 'com.example.ios'
        // },
        // android: {
        //   packageName: 'com.example.android',
        //   installApp: true,
        //   minimumVersion: '12'
        // },
        // handleCodeInApp: true
      };

      auth.sendPasswordResetEmail(this.resetEmail, actionCodeSettings).then(function() {
        // Email sent.
        self.isActivePasswordModal = false;
        self.resetEmail = '';
        NProgress.done();
        self.pwResetMsg = 'Password reset email sent. Please check your email inbox (or maybe Junk mail).';
      }).catch(function() {
        // An error happened.
        self.isActivePasswordModal = false;
        self.resetEmail = '';
        //console.log(error);
        self.pwResetMsg = 'Something went wrong. Please refresh and try again.';
        NProgress.done();
      });
    }

    //


  }
};
</script>

<style lang="scss" scoped>

  .tabs {
    ul {
      li {
        a {
          span {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .passwordMessage {
    color: red;
    margin: 1rem auto;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  .notification {
    margin-top: 5px !important;
    padding: 0.9rem 2rem 0.7rem 2rem;
  }

  .bd-notification {
    margin-top: 1rem;
  }

  h2 {
    line-height: 1.5rem;
  }

  .header-image {
    background: url('../assets/img/boat-tied-up.jpg') no-repeat center top;
    color: #fff;
  }
  p {
    &.error {
      color: red;
      font-size: 0.8rem;
      text-indent: 6px;
    }
  }
  .form-group {
    &.tuck {
      margin-bottom: 0.5rem;
    }
  }

</style>
