<template>
    <div>
        <div class="container">
            <div class="spacer s20"></div>

            <!-- {{sentUser}} -->

            <div class="notification is-info">
                <h3>Update {{ sentVessel.vesselName }}</h3>
            </div>

            <!-- label>User ID:</label>
			<input class="input" type="text" placeholder="User ID" v-model="userid" /> -->

            <div v-if="sentVessel.vesselID">
                <!-- {{newData}} -->

                <!--  <label>Set a New Password:</label>
				<input class="input" type="text" placeholder="New password..." v-model="updatedPassword" /> -->

                <!-- <label>User Role:</label>
        <div class="select is-primary">
          <select v-model="userrole">
            <option value="free" default>Free</option>
            <option value="starter">Starter</option>
            <option value="fleet">Fleet</option>
          </select>
        </div> -->
                <!--
        <label>Next Charge Amount:</label>
        <input class="input" type="number" placeholder="Next charge amount" v-model="nextCharge" /> -->

                <!-- <label>Max Vessels:</label>
        <input class="input" type="number" placeholder="Max vessels" v-model="maxVessels" /> -->

                <table class="table">
                    <tr>
                        <th>Vessel Name:</th>
                        <td>{{ newData.vesselName }}</td>
                    </tr>
                    <tr>
                        <th>Vessel ID:</th>
                        <td>{{ newData.vesselID }}</td>
                    </tr>
                    <tr>
                        <th>Vessel Owner:</th>
                        <td>{{ newData.vesselOwnerName }}</td>
                    </tr>
                </table>

                <div class="spacer s20"></div>

                <div class="form-group-reg">
                    <label>Next vessel charge amount:</label>
                    <input class="input" type="number" placeholder="Next charge" v-model="newData.nextVesselCharge" />
                </div>

                <div class="form-group-reg">
                    <input class="is-checkradio" type="checkbox" id="isPaidFor" v-model="newData.isPaidFor" />
                    <label for="isPaidFor">Is Paid For?</label>
                </div>

                <div class="form-group-reg">
                    <input class="is-checkradio" type="checkbox" id="isBillable" v-model="newData.isBillable" />
                    <label for="isBillable">Is Billable?</label>
                </div>

                <div class="form-group-reg">
                    <input class="is-checkradio" type="checkbox" id="isDemo" v-model="newData.isDemo" />
                    <label for="isDemo">Is a DEMO vessel?</label>
                </div>

                <hr />

                <label>Next Charge Date:</label>
                <div class="form-group-reg">
                    <datetime placeholder="Next Charge Date" auto v-model="newData.nextChargeDate"></datetime>
                </div>

                <!-- {{newData.nextChargeDate}} -->

                <div class="spacer s50"></div>

                <div class="buttons">
                    <span class="button is-primary" @click="updateVesselData()">Update vessel</span>
                    <span class="button is-info is-medium" @click="$router.go(-1)"><font-awesome-icon icon="chevron-left" /> &nbsp;Back</span>
                </div>

                <div class="spacer s50"></div>
            </div>

            <div v-else class="">
                <h3>No vessel found.</h3>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '../main.js';
//import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {
    name: 'UpdateVessel',

    data: function() {
        return {
            userid: '',
            newData: {}
        };
    },

    props: {
        sentVessel: Object
    },

    mounted() {
        if (this.sentVessel) {
            this.newData = this.sentVessel;
            this.newData.nextChargeDate = this.sentVessel.nextChargeDate == '' ? '' : this.sentVessel.nextChargeDate.toISOString();
            // this.newData.lastChargeDate = this.sentVessel.lastChargeDate == '' ? '' : this.sentVessel.lastChargeDate.toISOString();
        }
    },

    computed: {
        showButton() {
            return true;
        }
    },

    methods: {
        updateVesselData() {
            let self = this;

            let newNextChargeDate =
                self.newData.nextChargeDate != ''
                    ? moment(self.newData.nextChargeDate)
                          .toDate()
                          .toISOString()
                    : '';

            let updateArray = {
                isPaidFor: self.newData.isPaidFor,
                isBillable: self.newData.isBillable,
                isDemo: self.newData.isDemo,
                nextVesselCharge: parseInt(self.newData.nextVesselCharge)
            };

            console.log(updateArray, newNextChargeDate);

            let message = {
                title: 'Update Vessel?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES, UPDATE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();

                    //update vessel data
                    var adminUpdateVesselData = firebase.functions().httpsCallable('adminUpdateVesselData');
                    adminUpdateVesselData({
                        vesselID: self.sentVessel.vesselID,
                        vesselName: self.sentVessel.vesselName,
                        updateArray: updateArray,
                        nextChargeDate: newNextChargeDate
                    })
                        .then(function(result) {
                            console.log('Result', result.data.message);
                        })
                        .catch(function(error) {
                            console.log('Error', result.data.message);
                        });

                    setTimeout(function() {
                        // self.$router.push({
                        //   name: 'fleet-vessels',
                        // });
                        self.goBack();
                        NProgress.done();
                    }, 2000);
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        // changeField(field) {
        //   console.log('Field changed: ',field);
        // },

        goBack: function() {
            this.$router.go(-1);
        }
    }
};
</script>

<style lang="scss" scoped>
input[type='text'],
input[type='number'] {
    padding: 20px;
    margin-bottom: 1rem;
}
label {
    margin-bottom: 5px;
    margin-top: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
}
</style>
