<template>
  
  <div>

      <div class="site-wrapper no-header">
      
        <div class="container">

            <h3 class="is-size-5">Activity Dashboard</h3><br>

            <div class="spacer s30"></div>

            <div class="buttons">
              <router-link to="users" class="button is-info is-medium">View all app users</router-link>
            </div>

            <div class="buttons">
              <router-link to="activity" class="button is-skip is-medium">Recent user activity</router-link>
            </div>

            <div class="buttons">
              <router-link to="action-tags" class="button is-warning is-medium">Action Tags</router-link>
            </div>

            <div class="buttons">
              <router-link to="active-vessels-map" class="button is-primary is-medium">Active Vessels Map</router-link>
            </div>

            <div class="spacer s30"></div>

            <div class="buttons">
              <router-link to="selected-users" class="button is-light is-medium">Select individual users</router-link>
            </div>

            <hr>

            <div class="buttons">
              <router-link class="button is-success is-medium" to="all-timelines">All Timelines</router-link>
            </div>

            <div class="buttons">
              <router-link class="button is-warning is-medium" to="user-timelines">Individual Timelines</router-link>
            </div>

            <hr>

            <div class="buttons">
              <router-link class="button is-dark is-medium" to="all-crm-timelines">All CRM Timelines</router-link>
            </div>

            <div class="buttons">
              <router-link class="button is-dark is-medium" to="crm-timelines">CRM Members</router-link>
            </div>

            <div class="buttons">
              <router-link class="button is-primary is-medium" to="crm-follow-ups">All Follow Ups</router-link>
            </div>


        </div>

      </div>





  </div>

</template>


<script> 

  import firebase from 'firebase/compat/app';
  import { db } from '@/main.js';
  // import 'firebase/compat/auth'; 
  import { getAuth, onAuthStateChanged } from "firebase/auth";
  import 'firebase/firestore';
  import 'firebase/compat/functions';
  import NProgress from 'nprogress';  
  import * as moment from 'moment';



  export default {

    name: 'ActivityDashboard',
    components: {

    },
    mounted: function() {
      firebase.auth().onAuthStateChanged( this.onUserLogin ); 
    },

    data: function() {
      return {
        
      }
    },

    computed: {
      userData() {
        return this.$store.getters.getUser;
      },
      isSteve() {
        return this.userData ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false : false;
      },
    },

    methods: {

      onUserLogin: function( user ) {
        // if (user) {         

        // }
      },
      


    },

  }



</script> 




<style lang="scss" scoped>
  
  .site-wrapper .container {
    max-width: 850px;
  }
  svg {
    display: inline-block;
    margin-right: 10px;
  }


</style> 