<template>
  
  <div class="site-wrapper no-header">

    <div class="container">
    
       <div class="notification is-info"><h3>All App Users</h3></div>

        <div v-if="!isSelected" class="notification is-primary">
          <input class="input" v-model="findName" id="filtername" autocomplete="off" type="text" placeholder="Start typing user name..." />
        </div>

        <div class="buttons">
          <span v-if="findName != ''" class="button is-warning" @click="clearUser()">Clear</span>
        </div>

        <div v-if="isSelected" class="highlight">{{selectedUser.username}} </div>

        <div v-if="findName.length > 2 && !isSelected">

          <div v-for="(user, index) in filteredNames" :key="index">

            <div class="buttons results">
              <span class="button is-light" @click="selectUser(user)">{{user.username}}</span>
            </div>

          </div>



        </div>


        <div v-if="isSelected">

          <div v-if="!vesselSelected" class="notification is-primary">
            <input class="input" v-model="findVessel" id="filtername" autocomplete="off" type="text" placeholder="Start typing vessel name..." />
          </div>

          <div v-if="vesselSelected" class="highlight">{{selectedVessel.vesselName}} </div>

          <div v-if="findVessel.length > 2 && !vesselSelected">

            <!-- <div v-for="(vessel, index) in filteredVesselNames" :key="vessel.vesselID">

              <div class="buttons results">
                <span class="button is-light" @click="selectVessel(vessel)">{{selectedVessel.vesselName}}</span>
              </div>

            </div> -->

            <div v-for="(vessel, index) in filteredVesselNames" :key="index">

              <div class="buttons">
                <span class="button is-light" @click="selectVessel(vessel)">{{vessel.vesselName}} ({{vessel.vesselOwnerName}})</span>
              </div>

            </div>



          </div>

          <div class="spacer s30"></div>


          <div v-if="findVessel.length > 2 && vesselSelected" class="buttons">
            <button :disabled="showSave" class="button is-primary is-medium" @click="addVesselAsOwner()">Add Vessel as Owner</button>
            <button :disabled="showSave" class="button is-info is-medium" @click="addVesselAsCrew('crew')">Add Vessel as Crew</button>
            <button :disabled="showSave" class="button is-skip is-medium" @click="addVesselAsCrew('master')">Add Vessel as Master</button>
          </div>

          

        </div>




        

        <!-- <div class="posi">{{finalCodes}}<br>{{codeGroups}}</div> -->



    </div>



  </div>


</template>




<script>
  
  import { db } from '../main.js';
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth'; 
  import * as moment from 'moment';
  import NProgress from 'nprogress';

  export default {
    name: 'AdminAddVessel',

    computed: {
      user() {
        return this.$store.getters.getUser;
      },
      userData() {
        return this.$store.getters.getSubscriptionInfo;
      },
      filteredNames() {
        let filter = new RegExp(this.findName, 'i')
        return this.users.filter(user => user.username.match(filter));
      },
      filteredVessels() {
        return this.hideExpired ? this.allVessels.filter((vessel) => !vessel.isExpired).map((vess) => vess) : this.allVessels;
      },
      filteredVesselNames() {
        let filter = new RegExp(this.findVessel, 'i')
        return this.filteredVessels.filter(vessel => vessel.vesselName.match(filter));
      },
      showSave() {
        return false;
      },

      selectedStateCodes() {
        return this.selectedStates.map(item => item.code);
      },
      selectedStateNames() {
        return this.selectedStates.map(item => item.name);
      },

      selectedIndustryCodes() {
        return this.selectedIndustries.map(item => item.code);
      },
      selectedIndustryNames() {
        return this.selectedIndustries.map(item => item.name);
      },

      selectedClassCodes() {
        return this.selectedClasses.map(item => item.code);
      },
      selectedClassNames() {
        return this.selectedClasses.map(item => item.name);
      },

      selectedAreaCodes() {
        return this.selectedAreas.map(item => item.code);
      },
      selectedAreaNames() {
        return this.selectedAreas.map(item => item.name);
      },
      selectedAnswerCodes() {
        return this.selectedAnswers.map(item => item.code);
      },
      selectedAnswerNames() {
        return this.selectedAnswers.map(item => item.name);
      },

      selectedCodes() {
        return [...['base'],...this.selectedAnswerCodes,...this.selectedClassCodes,...this.selectedAreaCodes,...this.selectedIndustryCodes,...this.selectedStateCodes];
      },
      finalCodes() {
        return [...new Set(this.selectedCodes)];
      },

      gBasis() {
        return this.groupBasis.some(item => this.finalCodes.includes(item)) ? ['g-basis'] : [];
      },
      gRisky() {
        return this.groupRisky.some(item => this.finalCodes.includes(item)) ? ['g-risky'] : [];
      },
      gHeavy() {
        return this.groupHeavy.some(item => this.finalCodes.includes(item)) ? ['g-heavy'] : [];
      },
      gTravs() {
        return this.groupTravs.some(item => this.finalCodes.includes(item)) ? ['g-travs'] : [];
      },
      gHirer() {
        return this.groupHirer.some(item => this.finalCodes.includes(item)) ? ['g-hirer'] : [];
      },
      gDiver() {
        return this.groupDiver.some(item => this.finalCodes.includes(item)) ? ['g-diver'] : [];
      },
      gFishy() {
        return this.groupFishy.some(item => this.finalCodes.includes(item)) ? ['g-fishy'] : [];
      },
      gMotor() {
        return this.groupMotor.some(item => this.finalCodes.includes(item)) ? ['g-motor'] : [];
      },      
      gAlone() {
        return this.groupAlone.some(item => this.finalCodes.includes(item)) ? ['g-alone'] : [];
      },
      gSleep() {
        return this.groupSleep.some(item => this.finalCodes.includes(item)) ? ['g-sleep'] : [];
      },

      codeGroups() {
        return [...this.gBasis, ...this.gRisky, ...this.gHeavy, ...this.gTravs, ...this.gHirer, ...this.gDiver, ...this.gFishy,  ...this.gMotor, ...this.gAlone, ...this.gSleep];
      },



    },

    data() {
      return {
        users: [],
        isSelected: false,
        findName: '',
        findVessel: '',
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        selectedUser: '',
        newVessel: {
          vesselName: '',
          vesselUVI: '',
          nextCharge: 149,
          isCompanyVessel: false,
          takesPassengers: false,
          homePort: '',
          description: '',
          vesselMaster: '',
          vesselCrew: '',
          appCrewExplanation: '',
          shoreContactName: '',
          shoreContactNumber: '',
        },

        hideExpired: true,
        selectedVessel: '',
        allVessels: [],
        vesselSelected: false,

        vesselInfo: {
          description: '',
          homePort: '',
          selectedStates: '',
          selectedIndustries: '',
          selectedClasses: '',
          selectedAreas: '',
          vesselMaster: '',
          shoreContactName: '',
          shoreContactNumber: '',
          vesselCrew: '',
          appCrewExplanation: '',
          vesselOwnerName: '',
          vesselOwnerPhone: '',
          vesselOwnerEmail: '',
        },


        allstates: [
          { id: "s1", name: "Tasmania", checked: false, code: 'base'}, //make
          { id: "s2", name: "Victoria", checked: false, code: 'base'}, //deep
          { id: "s3", name: "New South Wales", checked: false, code: 'base'}, //just
          { id: "s4", name: "Queensland", checked: false, code: 'base'}, //wear
          { id: "s5", name: "South Australia", checked: false, code: 'base'}, //snap
          { id: "s6", name: "Western Australia", checked: false, code: 'base'}, //dash
          { id: "s7", name: "Northern Territory", checked: false, code: 'base'}, //slip
        ],
        selectedStates: [],
        industries: [
          { id: "i1", name: "Fishing", checked: false, code: 'keep'},
          { id: "i2", name: "Aquaculture", checked: false, code: 'wrap'},
          { id: "i3", name: "Commercial Diving", checked: false, code: 'lump'},
          { id: "i4", name: "Marine Construction", checked: false, code: 'corn'},
          { id: "i5", name: "Charter Vessel", checked: false, code: 'wood'},
          { id: "i6", name: "Passenger Vessel", checked: false, code: 'pest'},
          { id: "i7", name: "Other", checked: false, code: 'base'}, //gate
        ],
        selectedIndustries: [],
        classes: [
          { id: 'c1', name: '1 - Passenger vessel (13 or more passengers)', code: 'tent'},
          { id: 'c2', name: '2 - Non-passenger vessel (up to 12 passengers)', code: 'wall'},
          { id: 'c3', name: '3 - Fishing vessel', code: 'firm'},
          { id: 'c4', name: '4 - Hire and drive vessel used by the hirer only for recreational purposes', code: 'aunt'},
        ],
        selectedClasses: [],
        areas: [
          { id: 'A', name: 'Unlimited domestic operations', code: 'burp'},
          { id: 'B', name: 'B - Offshore operations (within 200 nautical miles)', code: 'news'},
          { id: 'Be', name: 'B (extended) - Offshore operations (beyond 200 nautical miles)', code: 'lazy'},
          { id: 'C', name: 'C - Offshore operations (within 30 nautical miles)', code: 'grow'},
          { id: 'Cr', name: 'C (restricted) - Offshore operations - specified areas only', code: 'club'},
          { id: 'D', name: 'D - Partially smooth water operations', code: 'bank'}, 
          { id: 'E', name: 'E - Smooth water operations', code: 'step'}, 
        ],
        selectedAreas: [],
        questions: [
          { id: 'Q1', checked: false, name: 'This vessel takes paying passengers', code: 'flex'},
          { id: 'Q2', checked: false, name: 'This vessel is operated single-handedly', code: 'solo'},
          { id: 'Q3', checked: false, name: 'Diving operations are carried out from this vessel', code: 'vote'},
          { id: 'Q4', checked: false, name: 'This is a dry hire or hire-drive vessel', code: 'toss'},
          { id: 'Q5', checked: false, name: 'This vessel has a crane or other lifting equipment', code: 'pair'},
          { id: 'Q6', checked: false, name: 'This vessel has an engine room', code: 'boom'},
          { id: 'Q7', checked: false, name: 'This vessel has a cabin or wheelhouse', code: 'raid'},
          { id: 'Q8', checked: false, name: 'This vessel is has sleeping accommodation', code: 'slab'},
          { id: 'Q9', checked: false, name: 'This operation requires overnight trips', code: 'zone'},
          { id: 'Q10', checked: false, name: 'This vessel is occasionally out of phone range', code: 'chop'},
          { id: 'Q11', checked: false, name: 'This vessel navigates busy traffic routes', code: 'lift'},
          { id: 'Q12', checked: false, name: 'This vessel can be launched from a trailer', code: 'pipe'},
          { id: 'Q13', checked: false, name: 'This carries one or more tender vessels', code: 'snow'},
        ],
        selectedAnswers: [],

        groupBasis: ['base'],
        groupRisky: ['keep','burp','news','lazy','grow','club','chop','lift'],
        groupHeavy: ['corn','pair'],
        groupTravs: ['wood','pest','tent','flex','toss'],
        groupHirer: ['aunt','toss','pest'],
        groupDiver: ['lump','vote'],
        groupFishy: ['keep','wrap','firm'],
        groupMotor: ['boom'],
        groupAlone: ['solo'],
        groupSleep: ['raid','slab','zone','chop','lift'],

        newVesselID: '',
        addFasttrack: false,

      } 

    },

    mounted() {
      // this.$store.dispatch('setUser');
      // this.$store.dispatch('SETSUBSCRIPTIONINFO');
      firebase.auth().onAuthStateChanged( this.onUserLogin ); 
    },

    methods: {
      onUserLogin: function( user ) {
        if (user) {         
          this.firebaseemail = user.email;
          this.firebaseid = user.uid;
          this.firebasedisplayname = this.userData.username;
          this.loadUsers();
          this.loadAllVessels();
        }
      },

      loadUsers: function() {
        let self = this;
        db.collection('userdata').orderBy('signUpDate','desc').onSnapshot(function(querySnapshot) {
          self.users = [];
          querySnapshot.forEach(function(doc) {
            // self.users.push(doc.data())
            self.users.push({
              userid: doc.id,
              signUpDate: doc.data().signUpDate,
              userstate: doc.data().userstate,
              industry: doc.data().industry,
              userrole: doc.data().userrole,
              userGroup: doc.data().userGroup ? doc.data().userGroup : '',
              username: doc.data().username,
              useremail: doc.data().useremail,
              userphone: doc.data().userphone,
              favouriteVesselID: doc.data().favouriteVesselID ? doc.data().favouriteVesselID : '',
              signupPlatform: doc.data().signupPlatform ? doc.data().signupPlatform : 'not set',
              favouriteFixed: doc.data().favouriteFixed ? doc.data().favouriteFixed : false,
              // profilingInfo: doc.data().profilingInfo ? doc.data().profilingInfo : '',
            })
          });
        })
      },

      loadAllVessels: function() {
        let self = this;
        db.collection('vessels').orderBy("vesselOwnerName", "asc").onSnapshot(function(querySnapshot) {
          self.allVessels = [];
          querySnapshot.forEach((doc) => {
            self.allVessels.push({
              vesselID: doc.id,
              vesselUVI: doc.data().vesselUVI ? doc.data().vesselUVI : '',
              vesselName: doc.data().vesselName,
              vesselOwnerName: (doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?'),
              vesselOwnerID: doc.data().vesselOwnerID,

            });
          });
        });
      },

      cleanPhone(number) {
        return "0" + number.slice(3,12);
      },

      selectUser(user) {
        console.log(user);
        this.isSelected = true;
        this.selectedUser = user;

      },

      selectVessel(vessel) {
        // NProgress.start();
        // console.log(vessel);
        let self = this;
        this.selectedVessel = vessel;
        this.vesselSelected = true;
        setTimeout(() => {
          self.displayInfo();
        }, 1000);
        
      },

      displayInfo() {
        // console.log(this.selectedVessel.vesselID);
        let self = this;
        db.collection('vessels').doc(this.selectedVessel.vesselID).get().then((doc) => {
          // console.log(doc.data());
          self.vesselInfo.description = doc.data().description ? doc.data().description : '';
          self.vesselInfo.homePort = doc.data().homePort ? doc.data().homePort : '';
          self.vesselInfo.selectedStates = doc.data().vesselStates ? doc.data().vesselStates : [];
          self.vesselInfo.selectedIndustries = doc.data().vesselIndustries ? doc.data().vesselIndustries : [];
          self.vesselInfo.selectedClasses = doc.data().surveyClasses ? doc.data().surveyClasses : [];
          self.vesselInfo.selectedAreas = doc.data().operationalAreas ? doc.data().operationalAreas : [];
          self.vesselInfo.vesselMaster = doc.data().vesselMaster ? doc.data().vesselMaster : '';
          self.vesselInfo.shoreContactName = doc.data().shoreContactName ? doc.data().shoreContactName : '';
          self.vesselInfo.shoreContactNumber = doc.data().shoreContactNumber ? doc.data().shoreContactNumber : '';
          self.vesselInfo.vesselCrew = doc.data().vesselCrew ? doc.data().vesselCrew : '';
          self.vesselInfo.appCrewExplanation = doc.data().appCrewExplanation ? doc.data().appCrewExplanation : '';
          self.vesselInfo.vesselOwnerName = doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '';
          self.vesselInfo.vesselOwnerPhone = doc.data().vesselOwnerPhone ? doc.data().vesselOwnerPhone : '';
          self.vesselInfo.vesselOwnerEmail = doc.data().vesselOwnerEmail ? doc.data().vesselOwnerEmail : '';
        })
        .then(() => {
          NProgress.done();
        });
      },

      clearUser: function() {
        this.findName = '';
        this.findVessel = '';
        this.isSelected = false;
        this.selectedUser = '';
        this.newVessel = {
          vesselName: '',
          vesselUVI: '',
          nextCharge: 149,
          isCompanyVessel: false,
          takesPassengers: false,
          homePort: '',
          description: '',
          vesselMaster: '',
          vesselCrew: '',
          appCrewExplanation: '',
          shoreContactName: '',
          shoreContactNumber: '',
        };
      },

      addVesselAsOwner() {
        console.log(this.selectedVessel);
        NProgress.start();

        let self = this;

        db.collection("userdata").doc(self.selectedUser.userid).update({
          myVessels: firebase.firestore.FieldValue.arrayUnion({
            vesselName: self.selectedVessel.vesselName.toUpperCase(),
            vesselID: self.selectedVessel.vesselID,
            vesselUVI: self.selectedVessel.vesselUVI,
          }),
        })
        .then(() => {
          NProgress.done();
        })
        .catch((err) => {
          console.log('Error',err);
          NProgress.done();
        });

      },

      addVesselAsCrew(type) {
        console.log(this.selectedVessel);
        NProgress.start();

        let self = this;

        db.collection('vessels').doc(self.selectedVessel.vesselID).update({
          listOfCrewIDs: firebase.firestore.FieldValue.arrayUnion(self.selectedUser.userid)
        })
        .then(() => {
          var updateCrewVesselAccess = firebase.functions().httpsCallable("updateCrewVesselAccess");
          updateCrewVesselAccess({
            vesselID: self.selectedVessel.vesselID,
            vesselName: self.selectedVessel.vesselName,
            userName: 'OffshoreSMS',
            updatedCrewList: [{
              crewID: self.selectedUser.userid,
              isCrew: true,
              isMaster: type == 'master' ? true : false,
              crewName: self.selectedUser.username
            }],
          });
          
          var cloudFunctionSendMultiplePushMessages = firebase.functions().httpsCallable('cloudFunctionSendMultiplePushMessages');
          cloudFunctionSendMultiplePushMessages({
            senderID: '',
            senderName: 'OffshoreSMS',
            recipientIDs: [self.selectedUser.userid],
            msgTitle: 'You have been added as crew.',
            msgBody: 'You have been added to the vessel ' + self.selectedVessel.vesselName + '. Go to My Vessels in the app to access the vessel SMS.',
            targetScreen: 'messages',
            senderType: 'individual',
            fleetID: ''
          }); 

        })
        .then(() => {
          NProgress.done();
        })
        .catch((err) => {
          console.log('Error',err);
          NProgress.done();
        });

      }





    }

  }



</script>



<style lang="scss" scoped>
  
  .highlight {
    background: cadetblue;
    padding: 15px;
    color: #fff;
  }
  .posi {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: #fff;
    z-index: 999;
  }
  label {
    &.label {
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 10px;
      &.tight {
        margin-bottom: 4px;
      }
    }
  }
  .buttons.results {
    margin-bottom: 4px;
  }




</style>





