<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <div class="before-icon"><font-awesome-icon icon="list-ul" /></div>
                <h4 class="icon">Pooled Owner Statement</h4>
                <div class="clear"></div>

                <table class="table is-bordered is-fullwidth">
                    <tr>
                        <th>Statement</th>
                        <th>Edit</th>
                    </tr>
                    <tr>
                        <td>
                            <span class="">{{ helperStatements.owner }}</span>
                        </td>
                        <td>
                            <div class="buttons">
                                <span class="button is-primary" @click="editItem('owner')">Edit</span>
                            </div>
                        </td>
                    </tr>
                </table>

                <div class="clear"></div>

                <div class="spacer s80"></div>

                <div class="before-icon"><font-awesome-icon icon="list-ul" /></div>
                <h4 class="icon">Pooled Master Statement</h4>
                <div class="clear"></div>

                <table class="table is-bordered is-fullwidth">
                    <tr>
                        <th>Statement</th>
                        <th>Edit</th>
                    </tr>
                    <tr>
                        <td>
                            <span class="">{{ helperStatements.master }}</span>
                        </td>
                        <td>
                            <div class="buttons">
                                <span class="button is-primary" @click="editItem('master')">Edit</span>
                            </div>
                        </td>
                    </tr>
                </table>

                <div class="clear"></div>

                <div class="spacer s80"></div>

                <div class="before-icon"><font-awesome-icon icon="list-ul" /></div>
                <h4 class="icon">Pooled DPO Statement</h4>
                <div class="clear"></div>

                <table class="table is-bordered is-fullwidth">
                    <tr>
                        <th>Statement</th>
                        <th>Edit</th>
                    </tr>
                    <tr>
                        <td>
                            <span class="">{{ helperStatements.dpa }}</span>
                        </td>
                        <td>
                            <div class="buttons">
                                <span class="button is-primary" @click="editItem('dpa')">Edit</span>
                            </div>
                        </td>
                    </tr>
                </table>

                <div class="clear"></div>

                <div class="spacer s80"></div>

                <div class="before-icon"><font-awesome-icon icon="list-ul" /></div>
                <h4 class="icon">Pooled Line of Communication</h4>
                <div class="clear"></div>

                <table class="table is-bordered is-fullwidth">
                    <tr>
                        <th>Statement</th>
                        <th>Edit</th>
                    </tr>
                    <tr>
                        <td>
                            <span class="">{{ helperStatements.comms }}</span>
                        </td>
                        <td>
                            <div class="buttons">
                                <span class="button is-primary" @click="editItem('comms')">Edit</span>
                            </div>
                        </td>
                    </tr>
                </table>

                <div class="clear"></div>
            </div>
        </div>

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showDetailsModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-info">
                    <p class="modal-card-title">
                        {{ selectedItem.title }}
                    </p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <div v-if="selectedItem != ''">
                        <div v-for="line in selectedItem.details.split('\n')" :key="line">{{ line }}<br /></div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-info" @click.prevent="close">Done</button>
                </footer>
            </div>
        </div>

        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': showEditItemModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">Edit Selected Item</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <!-- <input class="input" type="text" placeholder="Type title..." v-model="selectedEditItem.title"> -->
                    <div class="spacer s10"></div>
                    <textarea class="input textarea" placeholder="Type statement..." v-model="helperStatements[selectedEditItem]"></textarea>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click.prevent="saveEditItem(selectedEditItem)">Save</button>
                    <button class="button is-danger" @click.prevent="close">Cancel</button>
                </footer>
            </div>
        </div>

        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': addItemModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">Add an Item</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <input class="input" type="text" placeholder="Type title..." v-model="newItem.title" />

                    <div class="spacer s10"></div>
                    <textarea class="input textarea" placeholder="Type statement..." v-model="newItem.details"></textarea>

                    <div class="spacer s30"></div>
                </section>
                <footer class="modal-card-foot">
                    <button :disabled="newItem.details == '' || newItem.title == ''" class="button is-primary" @click.prevent="saveNewItem()">
                        Save New Item
                    </button>
                    <button class="button is-danger" @click.prevent="close">Cancel</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {
    name: 'BuildPooledStatements',
    components: {},
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            pooledOwnerStatements: [],
            pooledMasterStatements: [],
            pooledDesignatedStatements: [],
            pooledLineOfComms: [],
            selectedItem: { title: '', details: '', collection: '' },
            selectedEditItem: { title: '', details: '' },
            showDetailsModal: false,
            showEditItemModal: false,

            addItemModal: false,
            newItem: {
                title: '',
                details: '',
                collection: ''
            },

            helperStatements: {
                owner: '',
                master: '',
                roleTwo: '',
                dpa: '',
                crew: '',
                comms: '',
                snapshot: ''
            }
        };
    },

    computed: {
        userData() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.userData
                ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2'
                    ? true
                    : false
                : false;
        }
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                // this.displayOwnerStatements();
                // this.displayMasterStatements();
                // this.displayDesignatedStatements();
                // this.displayLineOfComms();
                this.loadHelpers();
            }
        },

        isNumber: function($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        close() {
            this.showDetailsModal = false;
            this.showEditItemModal = false;
            this.selectedItem = { title: '', details: '', collection: '' };
            this.selectedEditItem = { title: '', details: '' };
            this.addItemModal = false;
            this.newItem = {
                title: '',
                details: '',
                collection: ''
            };
        },

        loadHelpers() {
            let self = this;
            self.pooledOwnerStatements = [];
            self.pooledMasterStatements = [];
            self.pooledDesignatedStatements = [];
            self.pooledLineOfComms = [];
            db.collection('helperStatements')
                .doc('allStatements')
                .onSnapshot(doc => {
                    self.helperStatements.owner = doc.data().ownerStatement ? doc.data().ownerStatement : '';
                    self.helperStatements.master = doc.data().masterStatement ? doc.data().masterStatement : '';
                    self.helperStatements.roleTwo = doc.data().roleTwoStatement ? doc.data().roleTwoStatement : '';
                    self.helperStatements.dpa = doc.data().designatedStatement ? doc.data().designatedStatement : '';
                    self.helperStatements.crew = doc.data().crewStatement ? doc.data().crewStatement : '';
                    self.helperStatements.comms = doc.data().lineOfComms ? doc.data().lineOfComms : '';
                    self.helperStatements.snapshot = doc.data().snapshotIntro ? doc.data().snapshotIntro : '';
                });
            //
        },

        displayOwnerStatements() {
            let self = this;
            db.collection('pooledOwnerStatements').onSnapshot(querySnapshot => {
                self.pooledOwnerStatements = [];
                querySnapshot.forEach(doc => {
                    self.pooledOwnerStatements.push({
                        id: doc.id,
                        title: doc.data().title ? doc.data().title : 'no title',
                        details: doc.data().details ? doc.data().details : 'no details'
                    });
                });
            });
        },

        displayMasterStatements() {
            let self = this;
            db.collection('pooledMasterStatements').onSnapshot(querySnapshot => {
                self.pooledMasterStatements = [];
                querySnapshot.forEach(doc => {
                    self.pooledMasterStatements.push({
                        id: doc.id,
                        title: doc.data().title ? doc.data().title : 'no title',
                        details: doc.data().details ? doc.data().details : 'no details'
                    });
                });
            });
        },

        displayDesignatedStatements() {
            let self = this;
            db.collection('pooledDesignatedStatements').onSnapshot(querySnapshot => {
                self.pooledDesignatedStatements = [];
                querySnapshot.forEach(doc => {
                    self.pooledDesignatedStatements.push({
                        id: doc.id,
                        title: doc.data().title ? doc.data().title : 'no title',
                        details: doc.data().details ? doc.data().details : 'no details'
                    });
                });
            });
        },

        displayLineOfComms() {
            let self = this;
            db.collection('pooledLineOfComms').onSnapshot(querySnapshot => {
                self.pooledLineOfComms = [];
                querySnapshot.forEach(doc => {
                    self.pooledLineOfComms.push({
                        id: doc.id,
                        title: doc.data().title ? doc.data().title : 'no title',
                        details: doc.data().details ? doc.data().details : 'no details'
                    });
                });
            });
        },

        updateList(localList, selectedList, savedList) {
            let tempList = localList.filter(item => savedList.includes(item.name)).map(itemtwo => itemtwo);
            console.log(tempList);
            tempList.forEach(item => selectedList.push(item));
        },

        showDetails(item) {
            this.selectedItem = item;
            this.showDetailsModal = true;
        },

        editItem(item) {
            this.selectedEditItem = item;
            // this.selectedEditItem.collection = collection;
            // console.log(this.selectedEditItem);]
            this.showEditItemModal = true;
        },

        saveEditItem(itemToSave) {
            let self = this;
            console.log(itemToSave);
            NProgress.start();
            db.collection('helperStatements')
                .doc('allStatements')
                .update({
                    ownerStatement: self.helperStatements.owner,
                    masterStatement: self.helperStatements.master,
                    roleTwoStatement: self.helperStatements.roleTwo,
                    designatedStatement: self.helperStatements.dpa,
                    crewStatement: self.helperStatements.crew,
                    lineOfComms: self.helperStatements.comms,
                    snapshotIntro: self.helperStatements.snapshot
                })

                // var adminSaveSmsItem = firebase.functions().httpsCallable('adminSaveSmsItem');
                // adminSaveSmsItem({
                //   collection: itemToSave.collection,
                //   docID: itemToSave.id,
                //   title: itemToSave.title,
                //   details: itemToSave.details,
                // })
                .then(function(result) {
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        },

        addItem(collection) {
            this.newItem.collection = collection;
            this.addItemModal = true;
        },

        saveNewItem() {
            console.log(this.newItem);
            let self = this;
            NProgress.start();
            var adminSaveNewSmsItem = firebase.functions().httpsCallable('adminSaveNewSmsItem');
            adminSaveNewSmsItem({
                collection: self.newItem.collection,
                title: self.newItem.title,
                details: self.newItem.details,
                codes: [],
                index: 1
            })
                .then(function(result) {
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
label {
    margin-bottom: 10px;
    display: block;
}

.checkbox-wrapper {
    .field {
        float: left;
        margin-right: 2rem;
    }
}

.site-wrapper .container {
    max-width: 1050px;
}
.buttons {
    margin-bottom: 5px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
input[type='text'],
input[type='tel'] {
    padding: 2rem 1rem;
}

textarea {
    min-height: 120px;
    height: 400px;
    margin-top: 10px;
    padding: 1.5rem 1rem;
}

.show-details {
    cursor: pointer;
    &:hover {
        color: dodgerblue;
    }
}
.modal {
    .modal-card-body {
        position: relative;
    }
}
.posi {
    position: absolute;
    // bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: #fff;
    z-index: 999;
}
</style>
