<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">All OffshoreSMS Fleets</h3>
                <br />

                <div v-for="(company, index) in allCompanies" :key="`fleet-${index}`">
                    <div class="fleet-name buttons">
                        <div
                            class="button"
                            :class="[company.show ? 'is-primary' : 'is-delete']"
                            @click="
                                company.show = !company.show;
                                loadFleetInfo(company);
                            "
                        >
                            {{ company.companyName }}
                            <span class="green" v-if="company.verified">&nbsp; <font-awesome-icon icon="star"/></span>
                        </div>
                        <span class="tag is-small">{{ company.id }}</span>
                    </div>
                    <table v-show="company.show" class="table is-bordered is-fullwidth is-clean">
                        <tr>
                            <th>Company Name</th>
                            <td class="bold green">
                                {{ company.companyName }} &nbsp;
                                <span
                                    class="button is-small"
                                    :class="[company.verified ? 'is-success' : 'is-light']"
                                    @click="updateVerification(company)"
                                    >{{ company.verified ? 'Verified' : 'Verify' }}</span
                                >
                            </td>
                        </tr>
                        <tr>
                            <th>Company ID</th>
                            <td>{{ company.id }}</td>
                        </tr>
                        <tr>
                            <th>Company State</th>
                            <td>{{ company.companyState }}</td>
                        </tr>
                        <!-- <tr>
                            <th>Primary User</th>
                            <td>{{ company.primaryUserName }}</td>
                        </tr> -->
                        <tr>
                            <th>Audit Vessels</th>
                            <td>
                                {{
                                    company.companyVessels
                                        .filter(v => company.auditVesselIDs.includes(v.id))
                                        .map(n => n.vesselName)
                                        .join(', ')
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th>Fleet Vessels ({{ company.companyVessels.length }})</th>
                            <td>
                                <div v-if="company.companyVessels.length > 0">
                                    <h4 class="heading">(Audited vessels in blue)</h4>
                                    <!-- <div v-for="(vessel, ind) in company.companyVessels" :key="ind">
                                        <div>{{ vessel.vesselName }}</div>
                                        <div class="italic">({{ vessel.vesselID }})</div>
                                    </div> -->
                                    <!-- {{ company.selectedVessels.map(n => n.vesselName) }} -->
                                    <div class="form-group-reg">
                                        <div class="field">
                                            <input
                                                class="is-checkradio"
                                                type="checkbox"
                                                :id="`selectAllAssets-${company.id}`"
                                                v-model="company.selectAllAssets"
                                                @input="selectAll(company)"
                                            />
                                            <label :for="`selectAllAssets-${company.id}`">Select All</label>
                                        </div>
                                    </div>
                                    <!-- {{ company.companyVessels }}
                                    <hr />
                                    {{ company.selectedVessels }} -->
                                    <div class="form-group-reg">
                                        <div class="field" v-for="(vessel, idx) in company.companyVessels" :key="`vessel-2-${idx}`">
                                            <input
                                                class="is-checkradio"
                                                type="checkbox"
                                                :id="vessel.vesselID"
                                                :value="vessel"
                                                v-model="company.selectedVessels"
                                            />
                                            <label :for="vessel.vesselID">{{ vessel.vesselName }} - {{ vessel.vesselID }}</label>
                                        </div>
                                    </div>
                                    <label class="label">New Next Charge Date:</label>
                                    <div class="form-group-reg">
                                        <datetime placeholder="hide this.." auto v-model="bulkUpdateDate"></datetime>
                                    </div>
                                    <!-- {{ new Date(bulkUpdateDate) }} -->
                                </div>
                                <div class="spacer s10"></div>
                                <div class="buttons">
                                    <span class="button is-primary is-small" @click="loadFleetInfo(company)">
                                        {{ company.vesselsLoaded ? 'Reload' : 'Load' }} Vessels
                                    </span>
                                    <span class="button is-warning is-small" @click="bulkUpdateVessels(company)">
                                        Bulk Update Vessels
                                    </span>
                                    <span class="button is-info is-small" @click="bulkUpdateRoles(company)">
                                        Bulk Update Roles
                                    </span>
                                    <span class="button is-warning is-small" @click="copyVesselIDs(company)">
                                        Copy Vessel IDs
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Audits</th>
                            <!-- <td>{{ company.fleetAudits }}</td> -->
                            <td>
                                <div v-if="company.fleetAudits.length > 0">
                                    <!-- Due Events / Reminders =========================================== -->
                                    <div
                                        style="display: flex; justify-content: space-between; margin-bottom: 4px; border-bottom: 1px solid #eee; align-items: center; padding: 12px 0; max-width: 500px;"
                                    >
                                        <div style="display: flex; flex-grow: 1; justify-content: flex-start; align-items: center;">
                                            <div style="flex-wrap: wrap; line-height: 1rem; font-size: 1.1rem;">Events / Reminders</div>
                                        </div>
                                        <div style="display: flex;">
                                            <div
                                                style="width: auto; height: 25px; border-radius: 15px; padding: 2px 15px; color: #fff; font-size: 14px; margin-right: 5px;"
                                                :style="{ 'background-color': company.fleetAudits[0].datesDue == 0 ? 'mediumseagreen' : '#ff6868' }"
                                            >
                                                Due: {{ company.fleetAudits[0].datesDue }}
                                            </div>
                                            <div
                                                style="width: auto; height: 25px; border-radius: 15px; padding: 2px 15px; color: #fff; font-size: 14px;"
                                                :style="{ 'background-color': company.fleetAudits[0].datesOverdue == 0 ? 'mediumseagreen' : 'red' }"
                                            >
                                                Overdue: {{ company.fleetAudits[0].datesOverdue }}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- // -->
                                    <div
                                        style="display: flex; justify-content: space-between; margin-bottom: 4px; border-bottom: 1px solid #eee; align-items: center; padding: 12px 0; max-width: 500px;"
                                    >
                                        <div
                                            style="display: flex; flex-direction: column; flex-grow: 1; justify-content: center; align-items: flex-start;"
                                        >
                                            <div style="flex-wrap: wrap; line-height: 1rem; font-size: 1.1rem;">Events / Reminders</div>
                                            <div style="flex-wrap: wrap; line-height: 1rem; font-size: 1rem;">
                                                {{ company.fleetAudits[0].datesDue }} Due | {{ company.fleetAudits[0].datesOverdue }} Overdue
                                            </div>
                                        </div>
                                        <div style="display: flex; width: 50px; justify-content: center; align-items: center;">
                                            <div
                                                style="width: 30px; height: 30px; border-radius: 15px;"
                                                :style="{ 'background-color': company.fleetAudits[0].datesDue == 0 ? 'mediumseagreen' : '#ff6868' }"
                                            ></div>
                                        </div>
                                    </div>

                                    <!-- Overdue Events / Reminders =========================================== -->
                                    <div
                                        style="display: flex; justify-content: space-between; margin-bottom: 4px; border-bottom: 1px solid #eee; align-items: center; padding: 12px 0; max-width: 300px;"
                                    >
                                        <div style="display: flex; flex-grow: 1; justify-content: flex-start; align-items: center;">
                                            <div
                                                style="width: 25px; height: 25px; border-radius: 50%; margin: 0 3px; margin-right: 10px;"
                                                :style="{ 'background-color': company.fleetAudits[0].datesOverdue == 0 ? 'mediumseagreen' : 'red' }"
                                            ></div>
                                            <div style="flex-wrap: wrap;">Events / Reminders Overdue</div>
                                        </div>
                                        <div style="display: flex;">
                                            <div
                                                style="width: auto; height: 25px; border-radius: 15px; padding: 2px 15px; color: #fff; font-size: 14px;"
                                                :style="{ 'background-color': company.fleetAudits[0].datesOverdue == 0 ? 'mediumseagreen' : 'red' }"
                                            >
                                                {{ company.fleetAudits[0].datesOverdue }}
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Unresolved Defects =========================================== -->
                                    <div
                                        style="display: flex; justify-content: space-between; margin-bottom: 4px; border-bottom: 1px solid #eee; align-items: center; padding: 12px 0; max-width: 300px;"
                                    >
                                        <div style="display: flex; flex-grow: 1; justify-content: flex-start; align-items: center;">
                                            <div
                                                style="width: 25px; height: 25px; border-radius: 50%; margin: 0 3px; margin-right: 10px;"
                                                :style="{
                                                    'background-color': company.fleetAudits[0].unresolvedDefects == 0 ? 'mediumseagreen' : '#ffcb5d'
                                                }"
                                            ></div>
                                            <div style="flex-wrap: wrap;">Unresolved Defects</div>
                                        </div>
                                        <div style="display: flex;">
                                            <div
                                                style="width: auto; height: 25px; border-radius: 15px; padding: 2px 15px; color: #fff; font-size: 14px;"
                                                :style="{
                                                    'background-color': company.fleetAudits[0].unresolvedDefects == 0 ? 'mediumseagreen' : '#ffcb5d'
                                                }"
                                            >
                                                {{ company.fleetAudits[0].unresolvedDefects }}
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Low Stock Inventory =========================================== -->
                                    <div
                                        style="display: flex; justify-content: space-between; margin-bottom: 4px; border-bottom: 1px solid #eee; align-items: center; padding: 12px 0; max-width: 300px;"
                                    >
                                        <div style="display: flex; flex-grow: 1; justify-content: flex-start; align-items: center;">
                                            <div
                                                style="width: 25px; height: 25px; border-radius: 50%; margin: 0 3px; margin-right: 10px;"
                                                :style="{
                                                    'background-color': company.fleetAudits[0].lowInventory == 0 ? 'mediumseagreen' : '#ffcb5d'
                                                }"
                                            ></div>
                                            <div style="flex-wrap: wrap;">Low Stock Inventory</div>
                                        </div>
                                        <div>
                                            <div
                                                style="width: auto; height: 25px; border-radius: 15px; padding: 2px 15px; color: #fff; font-size: 14px;"
                                                :style="{
                                                    'background-color': company.fleetAudits[0].lowInventory == 0 ? 'mediumseagreen' : '#ffcb5d'
                                                }"
                                            >
                                                {{ company.fleetAudits[0].lowInventory }}
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Maintenance Due =========================================== -->
                                    <div
                                        style="display: flex; justify-content: space-between; margin-bottom: 4px; border-bottom: 1px solid #eee; align-items: center; padding: 12px 0; max-width: 300px;"
                                    >
                                        <div style="display: flex; flex-grow: 1; justify-content: flex-start; align-items: center;">
                                            <div
                                                style="width: 25px; height: 25px; border-radius: 50%; margin: 0 3px; margin-right: 10px;"
                                                :style="{
                                                    'background-color': company.fleetAudits[0].plantDue == 0 ? 'mediumseagreen' : '#ffcb5d'
                                                }"
                                            ></div>
                                            <div style="flex-wrap: wrap;">Maintenance Due</div>
                                        </div>
                                        <div>
                                            <div
                                                style="width: auto; height: 25px; border-radius: 15px; padding: 2px 15px; color: #fff; font-size: 14px;"
                                                :style="{
                                                    'background-color': company.fleetAudits[0].plantDue == 0 ? 'mediumseagreen' : '#ffcb5d'
                                                }"
                                            >
                                                {{ company.fleetAudits[0].plantDue }}
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Maintenance Overdue =========================================== -->
                                    <div
                                        style="display: flex; justify-content: space-between; margin-bottom: 4px; border-bottom: 1px solid #eee; align-items: center; padding: 12px 0; max-width: 300px;"
                                    >
                                        <div style="display: flex; flex-grow: 1; justify-content: flex-start; align-items: center;">
                                            <div
                                                style="width: 25px; height: 25px; border-radius: 50%; margin: 0 3px; margin-right: 10px;"
                                                :style="{
                                                    'background-color': company.fleetAudits[0].plantOverdue == 0 ? 'mediumseagreen' : 'red'
                                                }"
                                            ></div>
                                            <div style="flex-wrap: wrap;">Maintenance Overdue</div>
                                        </div>
                                        <div>
                                            <div
                                                style="width: auto; height: 25px; border-radius: 15px; padding: 2px 15px; color: #fff; font-size: 14px;"
                                                :style="{
                                                    'background-color': company.fleetAudits[0].plantOverdue == 0 ? 'mediumseagreen' : 'red'
                                                }"
                                            >
                                                {{ company.fleetAudits[0].plantOverdue }}
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Drill Schedules Due =========================================== -->
                                    <div
                                        style="display: flex; justify-content: space-between; margin-bottom: 4px; border-bottom: 1px solid #eee; align-items: center; padding: 12px 0; max-width: 300px;"
                                    >
                                        <div style="display: flex; flex-grow: 1; justify-content: flex-start; align-items: center;">
                                            <div
                                                style="width: 25px; height: 25px; border-radius: 50%; margin: 0 3px; margin-right: 10px;"
                                                :style="{
                                                    'background-color': company.fleetAudits[0].schedsDue == 0 ? 'mediumseagreen' : '#ffcb5d'
                                                }"
                                            ></div>
                                            <div style="flex-wrap: wrap;">Drill Schedules Due</div>
                                        </div>
                                        <div>
                                            <div
                                                style="width: auto; height: 25px; border-radius: 15px; padding: 2px 15px; color: #fff; font-size: 14px;"
                                                :style="{
                                                    'background-color': company.fleetAudits[0].schedsDue == 0 ? 'mediumseagreen' : '#ffcb5d'
                                                }"
                                            >
                                                {{ company.fleetAudits[0].schedsDue }}
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Drill Schedules Overdue =========================================== -->
                                    <div
                                        style="display: flex; justify-content: space-between; margin-bottom: 4px; border-bottom: 1px solid #eee; align-items: center; padding: 12px 0; max-width: 300px;"
                                    >
                                        <div style="display: flex; flex-grow: 1; justify-content: flex-start; align-items: center;">
                                            <div
                                                style="width: 25px; height: 25px; border-radius: 50%; margin: 0 3px; margin-right: 10px;"
                                                :style="{
                                                    'background-color': company.fleetAudits[0].schedsOverdue == 0 ? 'mediumseagreen' : 'red'
                                                }"
                                            ></div>
                                            <div style="flex-wrap: wrap;">Drill Schedules Overdue</div>
                                        </div>
                                        <div>
                                            <div
                                                style="width: auto; height: 25px; border-radius: 15px; padding: 2px 15px; color: #fff; font-size: 14px;"
                                                :style="{
                                                    'background-color': company.fleetAudits[0].schedsOverdue == 0 ? 'mediumseagreen' : 'red'
                                                }"
                                            >
                                                {{ company.fleetAudits[0].schedsOverdue }}
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Equipment Due =========================================== -->
                                    <div
                                        style="display: flex; justify-content: space-between; margin-bottom: 4px; border-bottom: 1px solid #eee; align-items: center; padding: 12px 0; max-width: 300px;"
                                    >
                                        <div style="display: flex; flex-grow: 1; justify-content: flex-start; align-items: center;">
                                            <div
                                                style="width: 25px; height: 25px; border-radius: 50%; margin: 0 3px; margin-right: 10px;"
                                                :style="{
                                                    'background-color': company.fleetAudits[0].equipDue == 0 ? 'mediumseagreen' : '#ffcb5d'
                                                }"
                                            ></div>
                                            <div style="flex-wrap: wrap;">Equipment Due</div>
                                        </div>
                                        <div>
                                            <div
                                                style="width: auto; height: 25px; border-radius: 15px; padding: 2px 15px; color: #fff; font-size: 14px;"
                                                :style="{
                                                    'background-color': company.fleetAudits[0].equipDue == 0 ? 'mediumseagreen' : '#ffcb5d'
                                                }"
                                            >
                                                {{ company.fleetAudits[0].equipDue }}
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Equipment Overdue =========================================== -->
                                    <div
                                        style="display: flex; justify-content: space-between; margin-bottom: 4px; border-bottom: 1px solid #eee; align-items: center; padding: 12px 0; max-width: 300px;"
                                    >
                                        <div style="display: flex; flex-grow: 1; justify-content: flex-start; align-items: center;">
                                            <div
                                                style="width: 25px; height: 25px; border-radius: 50%; margin: 0 3px; margin-right: 10px;"
                                                :style="{
                                                    'background-color': company.fleetAudits[0].equipOverdue == 0 ? 'mediumseagreen' : 'red'
                                                }"
                                            ></div>
                                            <div style="flex-wrap: wrap;">Equipment Overdue</div>
                                        </div>
                                        <div>
                                            <div
                                                style="width: auto; height: 25px; border-radius: 15px; padding: 2px 15px; color: #fff; font-size: 14px;"
                                                :style="{
                                                    'background-color': company.fleetAudits[0].equipOverdue == 0 ? 'mediumseagreen' : 'red'
                                                }"
                                            >
                                                {{ company.fleetAudits[0].equipOverdue }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="button is-danger" @click="testWeeklyUpdate(company)">
                                    <font-awesome-icon icon="envelope" /> &nbsp;Send Weekly Update
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Square Customer ID</th>
                            <td v-if="company.squareCustomerID != ''">
                                <span @click="checkIfCustomerExists(company)" class="button is-small is-success inline"
                                    ><font-awesome-icon icon="check-circle" /> &nbsp;{{ company.squareCustomerID }}</span
                                >
                            </td>
                            <td v-else>
                                <span @click="checkIfCustomerExists(company)" class="button is-small is-warning inline"
                                    ><font-awesome-icon icon="exclamation-triangle" /> &nbsp; Check Square Status</span
                                >
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <div>Admin Emails</div>
                                <div class="spacer s10"></div>
                                <div
                                    @click="emailFleetAdmins(company)"
                                    class="button is-small pad"
                                    :class="[company.adminsEmailed ? 'is-success' : 'is-warning']"
                                >
                                    <font-awesome-icon icon="envelope" /> &nbsp;Email Admins
                                </div>
                            </th>
                            <td>
                                <div v-for="email in company.adminEmails">
                                    <span @click="emailAdminUser(email)" class="button is-small is-warning pad"
                                        ><font-awesome-icon icon="envelope" /> &nbsp;{{ email }}</span
                                    >
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <div v-if="!company.mondayStatus.companyInMonday">
                                    Added to Monday?
                                </div>
                                <div v-else>In Monday!</div>
                            </th>
                            <td>
                                <button
                                    v-if="!company.mondayStatus.companyInMonday"
                                    class="button is-small is-dark"
                                    @click="showMondayOptions(company)"
                                >
                                    Add to Monday
                                </button>
                                <button v-else class="button is-small is-danger" @click="showMondayOptions(company)">
                                    Re-add to Monday
                                </button>
                                <div v-if="company.mondayStatus.companyInMonday">
                                    <ul>
                                        <li>
                                            Activity Status:
                                            <span class="bold">{{ company.mondayStatus.activityStatus }}</span>
                                        </li>
                                        <li>
                                            Billing Status:
                                            <span class="bold">{{ company.mondayStatus.billingStatus }}</span>
                                        </li>
                                        <li>
                                            Fleet Type:
                                            <span class="bold">{{ company.mondayStatus.fleetType }}</span>
                                        </li>
                                        <li>
                                            Journey Stage:
                                            <span class="bold">{{ company.mondayStatus.journeyStage }}</span>
                                        </li>
                                        <li>
                                            Total Assets:
                                            <span class="bold">{{ company.mondayStatus.totalAssets }}</span>
                                        </li>
                                        <li>
                                            Total in Team:
                                            <span class="bold">{{ company.mondayStatus.totalStaff }}</span>
                                        </li>
                                        <li>
                                            Industry:
                                            <span class="bold">{{ company.mondayStatus.industry }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Update Algolia</th>
                            <td>
                                <span
                                    class="button is-small"
                                    :class="[company.inAlgolia ? 'is-success' : 'is-light']"
                                    @click="updateAlgolia(company)"
                                >
                                    <font-awesome-icon :icon="company.inAlgolia ? 'check' : 'copy'" /> Update Algolia
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th>Import Crews to Fleet</th>
                            <td>
                                <span
                                    class="button is-small"
                                    :class="[company.teamImported ? 'is-light' : 'is-success']"
                                    @click="importCrewsToFleet(company)"
                                    ><font-awesome-icon :icon="company.teamImported ? 'check' : 'copy'" /> Import Crews</span
                                >
                            </td>
                        </tr>
                        <tr>
                            <th>Update Primary Admin IDs</th>
                            <td>
                                <span
                                    class="button is-small"
                                    :class="[company.adminsUpdated ? 'is-light' : 'is-warning']"
                                    @click="updatePrimaryAdmins(company)"
                                    ><font-awesome-icon :icon="company.adminsUpdated ? 'check' : 'exclamation-triangle'" /> Update Primary
                                    Admins</span
                                >
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="buttons">
                                    <span
                                        class="button"
                                        :class="[company.renewalSent ? 'is-success' : 'is-light']"
                                        @click="startRenewalAlert(company)"
                                    >
                                        {{ company.renewalSent ? 'Resend' : 'Send' }} Renewal Alerts</span
                                    >
                                    <!-- <span class="button" :class="[company.invoiceSent ? 'is-success' : 'is-light']">
                                    {{ company.invoiceSent ? 'Resend' : 'Send' }} Invoice Due Alert</span
                                > -->
                                    <span
                                        class="button"
                                        :class="[company.confirmationSent ? 'is-success' : 'is-light']"
                                        @click="startPaymentConfirmation(company)"
                                    >
                                        {{ company.confirmationSent ? 'Resend' : 'Send' }} Payment Confirmation</span
                                    >
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="buttons">
                                    <div class="button is-info" @click="loadActivity(company)">
                                        <font-awesome-icon icon="chart-line" /> &nbsp; Load Activity
                                    </div>
                                    <div
                                        class="button is-skip"
                                        @click="
                                            selectedCompany = company;
                                            showNotificationModal = true;
                                        "
                                    >
                                        <font-awesome-icon icon="envelope" /> &nbsp; Post Notification
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showVessel }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        Company Vessel Info &nbsp;<span class="demo" v-if="selectedVessel.isDemo"
                            ><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Demo vessel</span
                        >
                    </p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <!--  {{selectedVessel}}     -->

                    <div v-if="!isGettingData">
                        <div v-if="selectedVessel.isPast">
                            <div class="expired">
                                <font-awesome-icon icon="exclamation-triangle" /> Vessel subscription expired {{ selectedVessel.remaining }}
                            </div>
                        </div>

                        <div v-if="selectedVessel.isDue && !selectedVessel.isPast" class="due">
                            <font-awesome-icon icon="exclamation-triangle" /> Vessel
                            {{ selectedVessel.isDemo ? 'trial period' : 'subscription' }}
                            expires {{ selectedVessel.remaining }}
                        </div>

                        <table class="table is-fullwidth">
                            <tr>
                                <th>Company</th>
                                <td>{{ selectedVessel.vesselOwnerName }}</td>
                            </tr>
                            <tr>
                                <th>Paid For?</th>
                                <td>{{ selectedVessel.isPaidFor ? 'Yes' : 'No' }}</td>
                            </tr>
                            <tr>
                                <th>Billable?</th>
                                <td>{{ selectedVessel.isBillable ? 'Yes' : 'No' }}</td>
                            </tr>
                            <tr>
                                <th>Demo mode?</th>
                                <td>{{ selectedVessel.isDemo ? 'Yes' : 'No' }}</td>
                            </tr>
                            <tr>
                                <th>Last Charge</th>
                                <td>{{ selectedVessel.lastVesselCharge }}</td>
                            </tr>
                            <tr>
                                <th>Last charge date</th>
                                <td>
                                    {{ formatDate(selectedVessel.lastChargeDate, 'DD MMM YYYY (H:mm a)') }}
                                </td>
                            </tr>
                            <tr>
                                <th>Next Charge</th>
                                <td>{{ selectedVessel.nextVesselCharge }}</td>
                            </tr>
                            <tr>
                                <th>Next charge date</th>
                                <td>
                                    {{ formatDate(selectedVessel.nextChargeDate, 'DD MMM YYYY (H:mm a)') }}
                                </td>
                            </tr>
                        </table>

                        <p>Vessel Name: {{ selectedVessel.vesselName }}</p>
                        <p>Vessel ID: {{ selectedVessel.vesselID }}</p>
                    </div>

                    <div v-else>
                        <font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading vessel data for {{ selectedVessel.vesselName }}...
                        <div class="spacer s10"></div>
                        <p v-if="errorMsg != ''">Error: {{ errorMsg }}</p>
                    </div>

                    <div class="spacer s30"></div>
                </section>

                <footer class="modal-card-foot">
                    <button v-if="isSteve" class="button is-warning" @click="updateVesselData(selectedVessel)">
                        Update Vessel
                    </button>
                    <button class="button is-primary" @click="close">Done</button>
                </footer>
            </div>
        </div>

        <simple-modal :title="mondayTitle" theme="is-primary" v-on:closed="showMondayModal = false" v-if="showMondayModal">
            <label class="label">What type of Fleet is {{ selectedCompany.companyName }}?</label>
            <div class="select">
                <select v-model="selectedCompany.mondayStatus.fleetType">
                    <option v-for="option in fleetTypeOptions" :value="option" :key="option">{{ option }}</option>
                </select>
            </div>
            <div class="spacer s20"></div>

            <label class="label">Where they are in their Journey?</label>
            <div class="select">
                <select v-model="selectedCompany.mondayStatus.journeyStage">
                    <option v-for="option in journeyOptions" :value="option" :key="option">{{ option }}</option>
                </select>
            </div>
            <div class="spacer s20"></div>

            <!-- <label class="label">Which Industry are they part of?</label>
      <div class="select">
        <select v-model="selectedCompany.mondayStatus.industry">
          <option
            v-for="option in industryOptions"
            :value="option"
            :key="option"
            >{{ option }}</option
          >
        </select>
      </div>
      <div class="spacer s20"></div> -->

            <!-- <label class="label">What is their billing status?</label>
      <div class="select">
        <select v-model="selectedCompany.mondayStatus.billingStatus">
          <option
            v-for="option in billingStatusOptions"
            :value="option"
            :key="option"
            >{{ option }}</option
          >
        </select>
      </div>
      <div class="spacer s10"></div> -->

            <!-- <label class="label">What is their activity status?</label>
      <div class="select">
        <select v-model="selectedCompany.mondayStatus.activityStatus">
          <option
            v-for="option in activityStatusOptions"
            :value="option"
            :key="option"
            >{{ option }}</option
          >
        </select>
      </div>
      <div class="spacer s20"></div> -->

            <!-- <label class="label">Total assets in the fleet:</label>
      <div class="form-group-reg">
        <input
          class="input"
          type="tel"
          placeholder="Enter a number"
          v-on:keypress="isNumber($event)"
          v-model="selectedCompany.mondayStatus.totalAssets"
        />
      </div> -->

            <!-- <label class="label">Total people in their team:</label>
      <div class="form-group-reg">
        <input
          class="input"
          type="tel"
          placeholder="Enter a number"
          v-on:keypress="isNumber($event)"
          v-model="selectedCompany.mondayStatus.totalStaff"
        />
      </div> -->

            <label class="label">Comments about this fleet:</label>
            <div class="form-group-reg">
                <textarea class="textarea" v-model="selectedCompany.mondayStatus.comments" placeholder="Type any comments.."></textarea>
            </div>

            <label class="label">Which group will they be added to?</label>
            <div class="select">
                <select v-model="selectedCompany.mondayStatus.fleetGroup">
                    <option v-for="(option, idx) in groupOptions" :value="option.id" :key="option.id">{{ option.title }}</option>
                </select>
            </div>
            <div class="spacer s20"></div>

            <div class="button is-dark is-medium" @click="addToMonday()"><font-awesome-icon icon="users" /> &nbsp; Add to Monday</div>
            <div class="spacer s30"></div>
            <!-- {{ selectedCompany.mondayStatus }} -->
        </simple-modal>

        <simple-modal :title="selectedFleet.companyName" theme="is-primary" v-on:closed="showActivityModal = false" v-if="showActivityModal">
            <div>
                <!-- <apexchart width="100%" :options="chartOptions" :series="simpleActivity"></apexchart> -->
            </div>
            <!-- <pre>{{ currentActivity }}</pre> -->
        </simple-modal>

        <simple-modal title="Send Payment Confirmation" theme="is-primary" submitText="Cancel" @closed="close()" v-if="showConfirmationModal">
            <div>
                <div class="bold green" v-if="renewalEmailInfo.paymentConfirmationSent">
                    <font-awesome-icon icon="calendar" /> &nbsp;Last Confirmation Sent on {{ simpleDate(renewalEmailInfo.lastConfirmationDate) }}
                </div>
                <div class="bold red" v-else><font-awesome-icon icon="calendar" /> &nbsp;No payment confirmation sent yet</div>
                <div class="spacer s10"></div>
            </div>
            <div>
                <div class="bold"><font-awesome-icon icon="globe" /> &nbsp;Admin Emails</div>
                <div class="spacer s10"></div>
            </div>

            <div v-for="(email, idx) in renewalEmailInfo.recipients" :key="`email-${idx}`" class="buttons tight">
                <div class="button is-cancel">{{ email }}</div>
                <div class="button is-light" @click="dropRenewalEmail(idx)"><font-awesome-icon icon="trash-alt" /></div>
            </div>
            <div v-if="renewalEmailInfo.recipients.length == 0">
                <div class="bold red"><font-awesome-icon icon="exclamation-triangle" /> &nbsp;No email recipients!</div>
                <div class="spacer s10"></div>
            </div>
            <div>
                <input class="input padded" type="email" placeholder="Add an email.." v-model="renewalEmailInfo.addEmail" />
                <div class="spacer s10"></div>
            </div>
            <div class="buttons">
                <div class="button is-small is-skip" :disabled="!validateEmail(renewalEmailInfo.addEmail)" @click="addRenewalEmail()">Add Email</div>
            </div>
            <div class="spacer s10"></div>

            <div>
                <textarea class="input textarea" placeholder="Confirmation message..." v-model="renewalEmailInfo.confirmationMessage"></textarea>
            </div>
            <div class="spacer s20"></div>
            <div class="buttons">
                <div class="button is-success" @click="sendConfirmationAlert()">
                    <font-awesome-icon icon="envelope" /> &nbsp;Send Payment Confirmation Email
                </div>
            </div>
            <div class="spacer s20"></div>
        </simple-modal>

        <simple-modal
            :title="'Post System Notification for: ' + selectedCompany.companyName"
            theme="is-primary"
            submitText="Cancel"
            @closed="close()"
            v-if="showNotificationModal"
        >
            <!-- {{ selectedCompany }} -->
            <label class="label">Details:</label>
            <textarea class="textarea" cols="30" rows="10" v-model="newNotification.details"></textarea>
            <div class="spacer s20"></div>

            <label class="label">Type of Notice:</label>
            <div class="select">
                <select v-model="newNotification.type">
                    <option v-for="option in notificationTypes" :value="option.slug" :key="option.slug">{{ option.label }}</option>
                </select>
            </div>

            <input class="is-checkradio" type="checkbox" id="nnSendEmails" v-model="newNotification.sendEmails" />
            <label for="nnSendEmails"> Send emails to relevant fleet admins</label>
            <div class="spacer s20"></div>

            <input class="is-checkradio" type="checkbox" id="nnUpdatedate" v-model="newNotification.updateDate" />
            <label for="nnUpdatedate"> Mark as most recent notification date</label>
            <div class="spacer s20"></div>

            <div class="spacer s10"></div>
            <div class="buttons">
                <span class="button is-success" :disabled="newNotification.details == ''" @click="postNotification()"
                    ><font-awesome-icon icon="envelope" /> &nbsp;Post Notification</span
                >
            </div>
            <div class="spacer s20"></div>
        </simple-modal>

        <simple-modal
            :title="'Send Renewal Alert: ' + renewalEmailInfo.companyName"
            theme="is-primary"
            submitText="Cancel"
            @closed="close()"
            v-if="showRenewalAlertModal"
        >
            <div>
                <div class="bold green" v-if="renewalEmailInfo.renewalAlertSent">
                    <font-awesome-icon icon="calendar" /> &nbsp;Last Renewal Sent on {{ simpleDate(renewalEmailInfo.lastRenewalAlertDate) }}
                </div>
                <div class="bold red" v-else><font-awesome-icon icon="calendar" /> &nbsp;No renewal alerts sent yet</div>
                <div class="spacer s10"></div>
            </div>
            <div>
                <div class="bold"><font-awesome-icon icon="globe" /> &nbsp;Admin Emails</div>
                <div class="spacer s10"></div>
            </div>

            <div v-for="(email, idx) in renewalEmailInfo.recipients" :key="`email-${idx}`" class="buttons tight">
                <div class="button is-cancel">{{ email }}</div>
                <div class="button is-light" @click="dropRenewalEmail(idx)"><font-awesome-icon icon="trash-alt" /></div>
            </div>
            <div v-if="renewalEmailInfo.recipients.length == 0">
                <div class="bold red"><font-awesome-icon icon="exclamation-triangle" /> &nbsp;No email recipients!</div>
                <div class="spacer s10"></div>
            </div>
            <div>
                <input class="input padded" type="email" placeholder="Add an email.." v-model="renewalEmailInfo.addEmail" />
                <div class="spacer s10"></div>
            </div>
            <div class="buttons">
                <div class="button is-small is-skip" :disabled="!validateEmail(renewalEmailInfo.addEmail)" @click="addRenewalEmail()">Add Email</div>
            </div>
            <div class="spacer s10"></div>

            <div class="form-group-reg">
                <div class="bold green" @click="renewalEmailInfo.showAssets = !renewalEmailInfo.showAssets">Assets to Include:</div>
                <!-- <div class="spacer s10"></div> -->
            </div>
            <div class="form-group-reg">
                <div class="field" v-for="(vessel, idx) in renewalEmailInfo.assets" :key="`comp-asset-${vessel.vesselID}`">
                    <input
                        class="is-checkradio"
                        type="checkbox"
                        :id="`comp-asset-item-${vessel.vesselID}`"
                        :value="vessel.vesselName"
                        v-model="renewalEmailInfo.selectedAssets"
                    />
                    <label :for="`comp-asset-item-${vessel.vesselID}`">{{ vessel.vesselName }}</label>
                </div>
            </div>
            <div class="spacer s20"></div>

            <div>
                <textarea class="input textarea" placeholder="Renewal message" v-model="renewalEmailInfo.renewalMessage"></textarea>
            </div>
            <div class="spacer s20"></div>
            <div>
                <textarea class="input textarea" placeholder="Renewal message" v-model="renewalEmailInfo.invoiceMessage"></textarea>
            </div>
            <div class="spacer s20"></div>
            <div>{{ finalRenewalMessage }}</div>
            <div class="spacer s20"></div>
            <div>
                <input class="input padded" type="text" placeholder="Square invoice link" v-model="renewalEmailInfo.invoiceLink" />
            </div>
            <div class="spacer s20"></div>
            <input class="is-checkradio" type="checkbox" id="autoRenewal" v-model="renewalEmailInfo.autoRenew" />
            <label for="autoRenewal"> This is an auto renewal alert</label>
            <div class="spacer s20"></div>
            <div class="buttons">
                <div class="button is-success" @click="sendRenewalAlert()"><font-awesome-icon icon="envelope" /> &nbsp;Send Renewal Alert Email</div>
                <div class="button is-info" @click="markRenewalAsSent()"><font-awesome-icon icon="check" /> &nbsp;Just Mark as Sent</div>
            </div>
            <div class="spacer s20"></div>
        </simple-modal>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '../main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';
import axios from 'axios';
const SimpleModal = () => import('../reusables/SimpleModal.vue');
import algoliasearch from 'algoliasearch';

// const UpdateAudit = () => import('./UpdateAudit.vue')

export default {
    name: 'AllCompanies',
    components: {
        SimpleModal
    },
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            allVessels: [],
            selectedVessel: {},
            showVessel: false,
            allCompanies: [],
            isGettingData: false,
            errorMsg: '',
            selectedVessels: [],
            selectedCompany: {},
            showMondayModal: false,
            billingStatusOptions: ['None', 'Expired', 'New prospect', 'Invoice pending', 'In trial mode', 'Active customer'],
            activityStatusOptions: ['Inactive', 'Trialling products', 'Stuck', 'Dropping off', 'Occasional use', 'Honeymooner', 'Very active'],
            fleetTypeOptions: [
                'Unknown',
                'Consultants',
                'Small Charter',
                'Large Charter',
                'Small Commercial',
                'Large Commercial',
                'Owner Operator',
                'Paid fleet customer',
                'Passenger / Ferry',
                'VMR / Coast Guard',
                'Government'
            ],
            journeyOptions: ['In Sales', 'Payment Made', 'Up and Running', 'Renewed 2nd Year'],
            industryOptions: [
                'Unknown',
                'Commercial Fishing',
                'Passenger Vessel',
                'Marine Rescue',
                'Hire Drive',
                'Aquaculture',
                'Charter',
                'Marine Construction',
                'Miscellaneous',
                'Government'
            ],
            groupOptions: [
                { id: 'topics', title: 'New Fleet Prospects' },
                { id: 'new_group', title: 'Stage 1: Early Stage (Learning phase)' },
                { id: 'new_group8080', title: 'First Year Business (Up and Running)' },
                { id: 'new_group14960', title: 'Long Term Customer (2nd+ year renewals)' }
            ],
            currentActivity: [
                {
                    name: 'Activity',
                    data: []
                }
            ],
            simpleActivity: [
                {
                    data: []
                }
            ],
            // series: [
            //     {
            //         data: [23, 34, 12, 54, 32, 43]
            //     }
            // ],
            selectedFleet: {},
            showActivityModal: false,
            series: [
                {
                    name: 'Activity',
                    data: [
                        [1486684800000, 34],
                        [1486771200000, 43],
                        [1486857600000, 31],
                        [1486944000000, 43],
                        [1487030400000, 33],
                        [1487116800000, 52]
                    ]
                }
            ],
            temp: [],
            chartOptions: {
                chart: {
                    height: 350,
                    // type: 'area',
                    type: 'bar'
                    // zoom: {
                    //     enabled: false
                    // },
                    // animations: {
                    //     initialAnimation: {
                    //         enabled: false
                    //     }
                    // }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: 'Activity Trend By Days',
                    align: 'left'
                },
                // grid: {
                //     row: {
                //         colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                //         opacity: 0.5
                //     }
                // },
                xaxis: {
                    categories: []
                    // type: 'datetime'
                }
            },
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            renewalEmailInfo: {
                assets: [],
                selectedAssets: [],
                showAssets: false,
                price: '',
                companyID: '',
                companyName: '',
                checkoutLink: '',
                recipients: [],
                addEmail: '',
                adminUsers: [],
                primaryUserID: '',
                lastRenewalAlertDate: '',
                renewalAlertSent: false,
                lastConfirmationDate: '',
                paymentConfirmationSent: false,
                renewalMessage: "Hi team. It's that time of year and the subscriptions on one or more of your vessels are due for renewal",
                invoiceMessage: "We've generated an invoice in your Fleet Dashboard under Billing and from the link below.",
                confirmationMessage: 'Hi team. We have received your recent payment, so thank you!',
                invoiceLink: '',
                autoRenew: false
            },
            showRenewalAlertModal: false,
            showConfirmationModal: false,
            bulkUpdateDate: new Date().toISOString(),
            showNotificationModal: false,
            newNotification: {
                details: '',
                type: 'recommendation',
                sendEmails: false,
                updateDate: true
            },
            notificationTypes: [
                { slug: 'notice', label: 'General Notice' },
                { slug: 'alert', label: 'OffshoreSMS Alert' },
                { slug: 'tip', label: 'Helpful Tip' },
                { slug: 'recommendation', label: 'Recommendation' },
                { slug: 'update', label: 'Development Update' }
            ]
        };
    },

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.user.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.user.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false;
        },
        billableVesselCount() {
            return this.allVessels.filter(vessel => vessel.isBillable).length;
        },
        mondayTitle() {
            let title = this.selectedCompany.companyName ? this.selectedCompany.companyName : 'this fleet';
            return 'Add ' + title + ' to Monday';
        },
        finalRenewalMessage() {
            let assetNames = this.renewalEmailInfo.selectedAssets.join(', ');
            return this.renewalEmailInfo.renewalMessage + ' (' + assetNames + ').\n\n' + this.renewalEmailInfo.invoiceMessage;
        }
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                this.loadAllCompanies();
            }
            let now = new Date();
            this.bulkUpdateDate = new Date(now.setDate(now.getDate() + 365)).toISOString();
        },

        testWeeklyUpdate(company) {
            console.log('Emailing company', company.companyName, company.id);
            NProgress.start();
            var manualFleetEmailUpdate = firebase.functions().httpsCallable('manualFleetEmailUpdate');
            manualFleetEmailUpdate({
                fleets: [
                    {
                        fleetName: company.companyName,
                        fleetID: company.id
                    }
                ]
            });
            setTimeout(() => {
                NProgress.done();
            }, 1000);
        },

        postNotification() {
            let self = this;
            console.log(this.newNotification);
            NProgress.start();
            db.collection('companies')
                .doc(this.selectedCompany.id)
                .collection('systemNotifications')
                .add({
                    details: self.newNotification.details,
                    type: self.newNotification.type,
                    sendEmails: self.newNotification.sendEmails,
                    updateDate: self.newNotification.updateDate,
                    viewedIDs: [],
                    archived: false,
                    timestamp: new Date()
                })
                .then(docRef => {
                    if (self.newNotification.updateDate) {
                        db.collection('companies')
                            .doc(this.selectedCompany.id)
                            .update({
                                lastSystemNotificationDate: new Date(),
                                lastSystemNotificationID: docRef.id
                            });
                    }
                })
                .then(() => {
                    NProgress.done();
                    this.close();
                });
        },

        close() {
            this.showVessel = false;
            this.selectedVessel = {};
            this.isGettingData = false;
            this.selectedCompany = {};
            this.showMondayModal = false;
            this.showActivityModal = false;
            this.showRenewalAlertModal = false;
            this.showConfirmationModal = false;
            this.showNotificationModal = false;
            this.newNotification = {
                details: '',
                type: 'recommendation',
                sendEmails: false,
                updateDate: true
            };
        },

        updateVerification(company) {
            console.log(company.verified);
            NProgress.start();
            db.collection('companies')
                .doc(company.id)
                .update({
                    verified: !company.verified
                })
                .then(() => {
                    NProgress.done();
                });
        },

        selectAll(company) {
            if (!company.selectAllAssets) {
                company.selectedVessels = company.companyVessels;
            } else {
                company.selectedVessels = [];
            }
        },

        copyAllSettings() {
            let self = this;
            NProgress.start();
            let updateData = {
                savedCompanyPassengerInductionItems: [
                    'EayDU6fWeI5Cc3W643Zd',
                    'WYQz1u5BIZ2aXAFhN3i9',
                    'haYynbw0tlxxwdDOpmHC',
                    'mtwCO6eJ8m3hMDXZOJYG',
                    'qScgF3QM8FlyGJx4kVyu',
                    's7LCpeSQ4OOIY5fh2Ypy',
                    'sboLKTlEund8Ccu8WFRN'
                ],
                savedAppInfoSections: [
                    'Safety Information',
                    'Visitor Check-in',
                    'Passenger Manifests',
                    'Vessel Information',
                    'General Safety Briefing'
                ]
            };
            this.selectedVessels.forEach(vessel => {
                db.collection('vessels')
                    .doc(vessel)
                    .update(updateData)
                    .catch(error => console.log('Error!', error));
            });
            setTimeout(() => {
                NProgress.done();
            }, 2000);
        },

        async loadAllCompanies() {
            let self = this;
            console.log('reloading companies');
            // NProgress.start();
            db.collection('companies')
                .orderBy('companyName', 'asc')
                .onSnapshot(function(querySnapshot) {
                    self.allCompanies = [];
                    querySnapshot.forEach(doc => {
                        // console.log(doc.data().squareCustomerID ? doc.data().companyName + ': ' + doc.data().squareCustomerID : doc.data().companyName + ' no id');
                        let data = doc.data();
                        data.id = doc.id;
                        let isExpired = doc.data().isExpired ? doc.data().isExpired : false;

                        data.adminsEmailed = doc.data().adminsEmailed ? doc.data().adminsEmailed : false;
                        data.teamImported = doc.data().teamImported ? doc.data().teamImported : false;
                        data.primaryAdminIDs = doc.data().primaryAdminIDs ? doc.data().primaryAdminIDs : [];
                        data.adminsUpdated =
                            doc.data().primaryAdminIDs && doc.data().primaryAdminIDs.length > 0
                                ? true
                                : doc.data().adminsUpdated
                                ? doc.data().adminsUpdated
                                : false;
                        data.dateAdded = doc.data().timestamp ? doc.data().timestamp.toDate() : new Date();
                        data.sinceIndexesUpdated = doc.data().indexesUpdated ? moment(doc.data().indexesUpdated.toDate()).fromNow() : '--';
                        data.confirmationSent = doc.data().confirmationSent ? doc.data().confirmationSent : false;
                        data.invoiceSent = doc.data().invoiceSent ? doc.data().invoiceSent : false;
                        data.renewalSent = doc.data().renewalSent ? doc.data().renewalSent : false;
                        data.renewalAlertSent = doc.data().renewalAlertSent ? doc.data().renewalAlertSent : false;
                        data.lastRenewalAlertDate = doc.data().lastRenewalAlertDate ? doc.data().lastRenewalAlertDate.toDate() : '';
                        data.paymentConfirmationSent = doc.data().paymentConfirmationSent ? doc.data().paymentConfirmationSent : false;
                        data.lastConfirmationDate = doc.data().lastConfirmationDate ? doc.data().lastConfirmationDate.toDate() : '';
                        data.auditVesselIDs = doc.data().auditVesselIDs ? doc.data().auditVesselIDs : [];
                        data.primaryUserID = doc.data().primaryUserID ? doc.data().primaryUserID : '';
                        data.companyLocation = doc.data().companyLocation
                            ? doc.data().companyLocation
                            : doc.data().fleetLocation
                            ? doc.data().fleetLocation
                            : '';
                        data.newFleetLogo = doc.data().newFleetLogo ? doc.data().newFleetLogo : '';
                        data.companyVessels = [];
                        data.selectedVessels = [];
                        data.fleetAudits = [];
                        data.vesselsLoaded = false;
                        data.activityLoaded = false;
                        data.selectAllAssets = false;
                        data.verified = doc.data().verified ? doc.data().verified : false;
                        data.adminUsers = doc.data().adminUsers ? doc.data().adminUsers : [];
                        data.adminRestrictedUsers = doc.data().adminRestrictedUsers ? doc.data().adminRestrictedUsers : [];
                        data.activity = [
                            {
                                name: 'Activity',
                                data: []
                            }
                        ];
                        data.mondayStatus = doc.data().mondayStatus
                            ? doc.data().mondayStatus
                            : {
                                  companyInMonday: false,
                                  activityStatus: 'Inactive',
                                  billingStatus: 'None',
                                  fleetType: 'Unknown',
                                  journeyStage: 'In Sales',
                                  totalAssets: 'Unknown',
                                  totalStaff: 'Unknown',
                                  fleetGroup: 'topics',
                                  industry: 'Unknown',
                                  comments: ''
                              };
                        data.inAlgolia = doc.data().inAlgolia ? doc.data().inAlgolia : false;
                        data.show = false;
                        if (!isExpired) self.allCompanies.push(data);
                    });
                });
        },

        async checkAlgolia(fleetID) {
            let searchClient = algoliasearch('789GF6G7HW', '0dfd28f6ac249c3ebef88e13f6adbc34');
            let searchIndex = searchClient.initIndex('app_fleets');
            NProgress.start();
            let self = this;
            this.selectedUserHasIndex = false;

            console.log(fleetID);
            setTimeout(() => {
                searchIndex
                    .getObject(fleetID)
                    .then(object => {
                        self.selectedUserHasIndex = true;
                        NProgress.done();
                    })
                    .catch(err => {
                        console.log(err);
                        console.log('No user index found');
                        NProgress.done();
                    });
            }, 200);
        },

        updateAlgolia(company) {
            let searchClient = algoliasearch('789GF6G7HW', '0dfd28f6ac249c3ebef88e13f6adbc34');
            let searchIndex = searchClient.initIndex('app_fleets');
            NProgress.start();
            let fleet = {};
            fleet.objectID = company.id;
            fleet.fleetID = company.id;
            fleet.fleetName = company.companyName;
            fleet.fleetLocation = company.companyLocation;
            fleet.isExpired = company.isExpired ? company.isExpired : false;
            fleet.isEnterprise = company.isEnterprise ? company.isEnterprise : false;
            searchIndex.saveObject(fleet);
            NProgress.done();
        },

        copyVesselIDs(company) {
            console.log(company);
            let vessels = company.companyVessels.map(n => {
                return {
                    vesselID: n.vesselID,
                    vesselName: n.vesselName
                };
            });
            let url = JSON.stringify(vessels);

            this.$copyText(url).then(
                function(e) {
                    alert(company.companyVessels.length + ' Vessels copied to clipboard');
                },
                function(e) {
                    alert('This data cannot be copied');
                    console.log(e);
                }
            );
        },

        async bulkUpdateRoles(company) {
            console.log(company.selectedVessels);
            let vesselIDs = company.selectedVessels.map(m => m.vesselID);
            let self = this;
            // let message;
            // message = {
            //     title: 'Update these vessel user roles?',
            //     body: 'Click YES below to update.'
            // };
            // let options = {
            //     okText: 'YES',
            //     backdropClose: true,
            //     cancelText: 'Cancel'
            // };
            // this.$dialog
            //     .confirm(message, options)
            //     .then(async dialog => {
            //         NProgress.start();
            //         var bulkUpdateVesselRoles = firebase.functions().httpsCallable('bulkUpdateVesselRoles');
            //         bulkUpdateVesselRoles({
            //             vesselIDs: vesselIDs
            //         }).catch(function(error) {
            //             console.log('Error', error);
            //             NProgress.done();
            //         });
            //         NProgress.done();
            //     })
            //     .catch(error => {
            //         console.log(error);
            //         NProgress.done();
            //     });
            //
            NProgress.start();
            var bulkUpdateVesselRoles = firebase.functions().httpsCallable('bulkUpdateVesselRoles');
            bulkUpdateVesselRoles({
                vesselIDs: vesselIDs
            }).catch(function(error) {
                console.log('Error', error);
                NProgress.done();
            });
            setTimeout(() => {
                company.selectAllAssets = false;
                company.selectedVessels = [];
                NProgress.done();
            }, 500);
        },

        async bulkUpdateVessels(company) {
            console.log(company.selectedVessels);
            var now = new Date();
            // let newDate = new Date(now.setDate(now.getDate() + 365));
            // let newDate = new Date('12/31/2024');
            let newDate = new Date(this.bulkUpdateDate);

            let self = this;
            let message;
            message = {
                title: 'Update the nextChargeDate on ' + company.selectedVessels.length + ' vessels to ' + this.simpleDate(newDate),
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(async dialog => {
                    NProgress.start();
                    await Promise.all(
                        company.selectedVessels.map(v => {
                            db.collection('vessels')
                                .doc(v.vesselID)
                                .update({
                                    nextChargeDate: newDate
                                });
                        })
                    );
                    NProgress.done();
                })
                .catch(error => {
                    console.log(error);
                    NProgress.done();
                });
        },

        async loadFleetInfo(company) {
            let vessels = [];
            if (!company.vesselsLoaded) {
                console.log('loading vessels for', company.companyName);
                NProgress.start();
                db.collection('companies')
                    .doc(company.id)
                    .collection('companyVessels')
                    .get()
                    .then(querySnapshot => {
                        company.companyVessels = [];
                        querySnapshot.forEach(doc => {
                            let data = doc.data();
                            data.id = doc.id;
                            data.auditVessel = company.auditVesselIDs.includes(doc.id);
                            company.companyVessels.push(data);
                            vessels.push(data);
                        });
                    })
                    .then(() => {
                        db.collection('companies')
                            .doc(company.id)
                            .collection('fleetAssetAudits')
                            .orderBy('timestamp', 'desc')
                            .limit(1)
                            .get()
                            .then(snap => {
                                company.fleetAudits = [];
                                snap.forEach(doc => {
                                    let data = doc.data();
                                    data.id = doc.id;
                                    company.fleetAudits.push(data);
                                });
                            });
                    })
                    .then(() => {
                        NProgress.done();
                        company.vesselsLoaded = true;
                        // return vessels;
                    })
                    .catch(err => {
                        console.log(err);
                        NProgress.done();
                    });
            }
        },

        switchAuditStatus(company, vessel) {
            console.log(vessel);
            let newVal = !vessel.auditVessel;
            console.log('newVal', newVal);
            NProgress.start();
            db.collection('vessels')
                .doc(vessel.id)
                .update({
                    auditVessel: newVal
                })
                .then(() => {
                    db.collection('companies')
                        .doc(company.id)
                        .update({
                            auditVesselIDs: newVal
                                ? firebase.firestore.FieldValue.arrayUnion(vessel.id)
                                : firebase.firestore.FieldValue.arrayRemove(vessel.id)
                        });
                })
                .then(() => {
                    newVal
                        ? db
                              .collection('auditVessels')
                              .doc(vessel.id)
                              .set({ vesselID: vessel.id, vesselName: vessel.vesselName })
                        : db
                              .collection('auditVessels')
                              .doc(vessel.id)
                              .delete();
                })
                .then(() => {
                    NProgress.done();
                });
        },

        updateFleetItemIndexes: function(companyID) {
            let self = this;
            let message;
            message = {
                title: 'Update all fleet item indexes?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    var AdminUpdateFleetItemIndexes = firebase.functions().httpsCallable('AdminUpdateFleetItemIndexes');
                    AdminUpdateFleetItemIndexes({
                        companyID: companyID
                    })
                        .then(function(result) {
                            // var sanitizedMessage = result.data.text;
                            // console.log('Result',sanitizedMessage);
                            console.log('Message', result.data.message);
                            NProgress.done();
                        })
                        .catch(function(error) {
                            console.log('Error', error.text);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        isNumber($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        async loadAllCrewData() {
            let self = this;
            self.allVesselCrewIDs.forEach(crewID => {
                db.collection('userdata')
                    .doc(crewID)
                    .get()
                    .then(doc => {
                        // console.log(doc.data())
                        self.allVesselCrew.push({
                            id: crewID,
                            crewName: doc.data().username,
                            crewEmail: doc.data().useremail ? doc.data().useremail : '',
                            avatarUrl: doc.data().avatarUrl ? doc.data().avatarUrl : '',
                            qualExpired: false
                        });
                    });
            });
        },

        validateEmail(email) {
            return email == '' ? false : this.reg.test(email);
        },

        addRenewalEmail() {
            this.renewalEmailInfo.recipients.push(this.renewalEmailInfo.addEmail);
            this.renewalEmailInfo.addEmail = '';
        },

        dropRenewalEmail(index) {
            this.renewalEmailInfo.recipients.splice(index, 1);
        },

        async startPaymentConfirmation(company) {
            NProgress.start();
            console.log(company);

            this.renewalEmailInfo.recipients = [];
            this.renewalEmailInfo.adminUsers = [];
            let primaryEmails = company.primaryUserEmail ? [company.primaryUserEmail] : [];
            let adminEmails = company.adminEmails ? company.adminEmails : [];
            this.renewalEmailInfo.recipients = [...primaryEmails, ...adminEmails];

            this.renewalEmailInfo.adminUsers = company.adminUsers ? company.adminUsers : [];
            this.renewalEmailInfo.primaryUserID = company.primaryUserID ? company.primaryUserID : '';

            this.renewalEmailInfo.lastConfirmationDate = company.lastConfirmationDate;
            this.renewalEmailInfo.paymentConfirmationSent = company.paymentConfirmationSent;
            this.renewalEmailInfo.companyID = company.id;
            this.renewalEmailInfo.companyName = company.companyName;
            this.renewalEmailInfo.assets = [...company.companyVessels];
            this.renewalEmailInfo.selectedAssets = [];
            this.renewalEmailInfo.showAssets = false;

            setTimeout(() => {
                this.showConfirmationModal = true;
                NProgress.done();
            }, 500);
        },

        async sendConfirmationAlert() {
            console.log(this.renewalEmailInfo);
            let self = this;
            NProgress.start();
            this.showConfirmationModal = false;

            let emailData = {
                confirmationMessage: this.renewalEmailInfo.confirmationMessage,
                recipients: this.renewalEmailInfo.recipients,
                emailSubject: 'Your subscriptions were successfully renewed',
                fcmTitle: 'Payment received, thanks!',
                fcmBody: 'Hi team. The annual subscriptions for one or more fleet assets has been renewed',
                adminUsers: [this.renewalEmailInfo.primaryUserID],
                hasRedirect: true,
                redirectTarget: 'allFleets',
                assetID: ''
            };
            console.log(emailData);

            var sendFleetPaymentConfirmation = firebase.functions().httpsCallable('sendFleetPaymentConfirmation');
            sendFleetPaymentConfirmation(emailData).catch(function(error) {
                console.log('Error', error);
                NProgress.done();
            });

            db.collection('companies')
                .doc(self.renewalEmailInfo.companyID)
                .update({
                    paymentConfirmationSent: true,
                    lastConfirmationDate: new Date()
                });

            setTimeout(() => {
                NProgress.done();
                self.close();
            }, 1000);
        },

        async startRenewalAlert(company) {
            NProgress.start();
            console.log(company);
            this.renewalEmailInfo.recipients = [];
            this.renewalEmailInfo.adminUsers = [];
            let primaryEmails = company.primaryUserEmail ? [company.primaryUserEmail] : [];
            let adminEmails = company.adminEmails ? company.adminEmails : [];
            this.renewalEmailInfo.recipients = [...primaryEmails, ...adminEmails];

            this.renewalEmailInfo.adminUsers = company.adminUsers ? company.adminUsers : [];
            this.renewalEmailInfo.primaryUserID = company.primaryUserID ? company.primaryUserID : '';

            this.renewalEmailInfo.lastRenewalAlertDate = company.lastRenewalAlertDate;
            this.renewalEmailInfo.renewalAlertSent = company.renewalAlertSent;
            this.renewalEmailInfo.companyID = company.id;
            this.renewalEmailInfo.companyName = company.companyName;
            this.renewalEmailInfo.assets = [...company.companyVessels];
            this.renewalEmailInfo.selectedAssets = [];
            this.renewalEmailInfo.showAssets = false;

            setTimeout(() => {
                this.showRenewalAlertModal = true;
                NProgress.done();
            }, 500);
        },

        sendRenewalAlert() {
            console.log(this.renewalEmailInfo);
            let self = this;
            NProgress.start();
            this.showRenewalAlertModal = false;

            let assetNames = this.renewalEmailInfo.selectedAssets.join(', ');

            let emailData = {
                renewalMessage: this.renewalEmailInfo.renewalMessage + ' (' + assetNames + ').\n' + this.renewalEmailInfo.invoiceMessage,
                invoiceLink: this.renewalEmailInfo.invoiceLink,
                autoRenew: this.renewalEmailInfo.autoRenew,
                recipients: this.renewalEmailInfo.recipients,
                emailSubject: this.renewalEmailInfo.autoRenew ? 'Subscriptions due for auto renewal' : 'Subscriptions due for renewal',
                fcmTitle: this.renewalEmailInfo.autoRenew ? 'Subscriptions due for auto renewal' : 'Subscriptions due for renewal',
                fcmBody: 'Hi team. The annual subscriptions for one or more fleet assets is due',
                // adminUsers: this.renewalEmailInfo.adminUsers,
                adminUsers: [this.renewalEmailInfo.primaryUserID],
                hasRedirect: true,
                redirectTarget: 'allFleets',
                assetID: '',
                assetNames: assetNames
            };
            console.log(emailData);

            var sendFleetRenewalAlerts = firebase.functions().httpsCallable('sendFleetRenewalAlerts');
            sendFleetRenewalAlerts(emailData).catch(function(error) {
                console.log('Error', error.text);
                NProgress.done();
            });

            db.collection('companies')
                .doc(self.renewalEmailInfo.companyID)
                .update({
                    renewalAlertSent: true,
                    lastRenewalAlertDate: new Date()
                });

            setTimeout(() => {
                NProgress.done();
                self.close();
            }, 1000);
        },

        markRenewalAsSent() {
            NProgress.start();
            db.collection('vessels')
                .doc(this.renewalEmailInfo.vesselID)
                .update({
                    renewalAlertSent: true,
                    lastRenewalAlertDate: new Date()
                });
            setTimeout(() => {
                NProgress.done();
                this.close();
            }, 500);
        },

        async loadActivity(company) {
            this.selectedFleet = company;
            let self = this;
            company.vesselsLoaded = true;
            // this.currentActivity = [];
            let vessels = [];
            let activity = {};
            let ids = [];
            NProgress.start();
            db.collection('companies')
                .doc(company.id)
                .collection('companyVessels')
                .get()
                .then(querySnapshot => {
                    company.companyVessels = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        data.auditVessel = company.auditVesselIDs.includes(doc.id);
                        company.companyVessels.push(data);
                        vessels.push(doc.id);
                    });
                })
                .then(async () => {
                    console.log('vessels', vessels);
                    await Promise.all(
                        vessels.map(v => {
                            db.collection('vessels')
                                .doc(v)
                                .collection('vesselRevisions')
                                .limit(100)
                                .get()
                                .then(querySnapshot => {
                                    querySnapshot.forEach(docRef => {
                                        let t = docRef.data().timestamp ? docRef.data().timestamp.toDate() : new Date();
                                        let epochDay = Math.floor(t / 8.64e7);
                                        let val = ids.includes(epochDay) ? activity[epochDay] + 1 : 1;
                                        ids.push(epochDay);
                                        console.log(new Date(epochDay * 8.64e7));
                                        // activity[epochDay] = activity[epochDay] ? activity[epochDay] + 1 : 1;
                                        activity[epochDay] = val;
                                    });
                                    // console.log(activity);
                                });
                        })
                    );
                    // console.log(activity);
                    // for (const key in activity) {
                    //     console.log(`${key}: ${activity[key]}`);
                    // }
                })
                .then(() => {
                    this.chartOptions.xaxis.categories = Object.keys(activity);
                    // this.series[0].data = Object.values(activity);

                    // console.log(activity);
                    // let x = Object.entries(activity);
                    // console.log(JSON.stringify(activity) === '{}');

                    // for (const key in activity) {
                    //     console.log(`${key}: ${activity[key]}`);
                    // }
                    // let obj = {
                    //     123: 'ewrwert',
                    //     45456: 'dfh dfh dfgh ',
                    //     78678: 'dfh fh dfh '
                    // };
                    // console.log(obj);
                    // self.temp = ids;

                    // this.series[0].data = Object.entries(activity);

                    // console.log(activity);
                    setTimeout(() => {
                        console.log(Object.entries(activity));
                        // self.currentActivity[0].data = Object.entries(activity);
                        self.simpleActivity[0].data = Object.values(activity);
                        self.showActivityModal = true;
                    }, 1000);
                    // // company.activity[0].data = Object.entries(activity);

                    // Object.entries(activity).forEach(entry => {
                    //     const [key, value] = entry;
                    //     console.log(key, value);
                    //     company.activity[0].data.push([key, value]);
                    // });

                    company.activityLoaded = true;
                    NProgress.done();
                })
                .catch(err => {
                    console.log(err);
                    NProgress.done();
                });
        },

        async updatePrimaryAdmins(company) {
            console.log(company);
            NProgress.start();
            // let primaryUserID = [company.primaryUserID];
            db.collection('companies')
                .doc(company.id)
                .update({
                    primaryAdminIDs: firebase.firestore.FieldValue.arrayUnion(company.primaryUserID),
                    adminsUpdated: true
                })
                .then(() => {
                    NProgress.done();
                });
        },

        async importCrewsToFleet(company) {
            console.log(company);
            let self = this;
            NProgress.start();
            this.selectedCompany = company;
            let allUserIDs = [];
            let adminUserIDs = company.adminUsers;
            let adminRestrictedUserIDs = company.adminRestrictedUsers;
            allUserIDs = [...allUserIDs, ...adminUserIDs];

            await db
                .collection('companies')
                .doc(company.id)
                .collection('companyVessels')
                .get()
                .then(querySnapshot => {
                    self.selectedCompany.companyVesselIDs = querySnapshot.docs.map(doc => doc.id);
                });
            await Promise.all(
                this.selectedCompany.companyVesselIDs.map(async v => {
                    await db
                        .collection('vessels')
                        .doc(v)
                        .get()
                        .then(doc => {
                            let listOfCrewIDs = doc.data().listOfCrewIDs ? doc.data().listOfCrewIDs : [];
                            // let listOfLevelTwoIDs = doc.data().listOfLevelTwoIDs ? doc.data().listOfLevelTwoIDs : [];
                            // let listOfMasterIDs = doc.data().listOfMasterIDs ? doc.data().listOfMasterIDs : [];
                            // allUserIDs = [...allUserIDs, ...listOfCrewIDs, ...listOfLevelTwoIDs, ...listOfMasterIDs];
                            allUserIDs = [...allUserIDs, ...listOfCrewIDs];
                        });
                })
            );
            let uniqueUserIDs = Array.from(new Set(allUserIDs));

            // console.log(this.selectedCompany.companyVesselIDs);
            // console.log('allUserIDs', allUserIDs);
            // console.log('uniqueUserIDs', uniqueUserIDs);

            let fleetData = {
                fleetID: this.selectedCompany.id,
                fleetName: this.selectedCompany.companyName,
                fleetLocation: this.selectedCompany.companyLocation,
                avatarUrl: this.selectedCompany.newFleetLogo ? this.selectedCompany.newFleetLogo : '',
                // roles: [],
                isOffline: false
            };

            await Promise.all(
                uniqueUserIDs.map(async userID => {
                    await db
                        .collection('userdata')
                        .doc(userID)
                        .get()
                        .then(doc => {
                            if (doc.exists) {
                                fleetData.adminStatus = adminRestrictedUserIDs.includes(userID)
                                    ? 'restricted'
                                    : adminUserIDs.includes(userID)
                                    ? 'full'
                                    : 'none;';
                                let crewData = {
                                    crewID: userID,
                                    crewName: doc.data().username,
                                    avatarUrl: doc.data().avatarUrl ? doc.data().avatarUrl : '',
                                    // roles: [],
                                    isOffline: false
                                };
                                db.collection('companies')
                                    .doc(company.id)
                                    .collection('teamMembers')
                                    .doc(userID)
                                    .set(crewData, { merge: true });
                                db.collection('userdata')
                                    .doc(userID)
                                    .collection('myFleets')
                                    .doc(company.id)
                                    .set(fleetData, { merge: true });

                                let userFleetStatus = {
                                    fleetsImRestrictedAdmin: adminRestrictedUserIDs.includes(userID)
                                        ? firebase.firestore.FieldValue.arrayUnion(company.id)
                                        : firebase.firestore.FieldValue.arrayRemove(company.id),
                                    fleetsImAdmin: adminUserIDs.includes(userID)
                                        ? firebase.firestore.FieldValue.arrayUnion(company.id)
                                        : firebase.firestore.FieldValue.arrayRemove(company.id)
                                };
                                db.collection('userdata')
                                    .doc(userID)
                                    .update(userFleetStatus);
                            }
                        });
                })
            );
            db.collection('companies')
                .doc(company.id)
                .update({
                    teamImported: true
                });

            setTimeout(() => {
                NProgress.done();
            }, 500);
        },

        showMondayOptions(company) {
            this.selectedCompany = { ...company };
            this.selectedCompany.mondayStatus.billingStatus = 'None';
            this.selectedCompany.mondayStatus.activityStatus = 'Inactive';
            this.selectedCompany.mondayStatus.fleetType = 'Unknown';
            this.selectedCompany.mondayStatus.journeyStage = 'In Sales';
            this.selectedCompany.mondayStatus.totalAssets = '';
            this.selectedCompany.mondayStatus.totalStaff = '';
            this.selectedCompany.mondayStatus.fleetGroup = 'topics';
            this.selectedCompany.mondayStatus.industry = 'Unknown';
            this.selectedCompany.mondayStatus.comments = '';
            this.showMondayModal = true;
        },

        addToMonday() {
            console.log(this.selectedCompany);
            let self = this;
            NProgress.start();

            let busID = this.selectedCompany.id;
            let billingStatus = this.selectedCompany.mondayStatus.billingStatus;
            let activityStatus = this.selectedCompany.mondayStatus.activityStatus;
            let fleetType = this.selectedCompany.mondayStatus.fleetType;
            let journeyStage = this.selectedCompany.mondayStatus.journeyStage;
            let totalAssets = this.selectedCompany.mondayStatus.totalAssets;
            let totalStaff = this.selectedCompany.mondayStatus.totalStaff;
            let fleetGroup = this.selectedCompany.mondayStatus.fleetGroup;
            let industry = this.selectedCompany.mondayStatus.industry;
            let comments = this.selectedCompany.mondayStatus.comments;

            let d = new Date(this.selectedCompany.dateAdded);
            let d1 =
                d.getFullYear() +
                '-' +
                (d.getMonth() + 1).toString().padStart(2, '0') +
                '-' +
                d
                    .getDate()
                    .toString()
                    .padStart(2, '0');
            console.log('d', d);
            console.log('d1', d1);
            // status51

            // let column_values =
            //   '{\\"date\\":\\"' +
            //   d1 +
            //   '\\",\\"business_id\\":\\"' +
            //   busID +
            //   // '\\",\\"status8\\":\\"' +
            //   // billingStatus +
            //   // '\\",\\"status_1\\":\\"' +
            //   // activityStatus +
            //   // '\\",\\"label6\\":\\"' +
            //   // fleetType +
            //   '\\",\\"status\\":\\"' +
            //   fleetType +
            //   // '\\",\\"status51\\":\\"' +
            //   // journeyStage +
            //   // '\\",\\"numbers0\\":\\"' +
            //   // totalAssets +
            //   // '\\",\\"text47\\":\\"' +
            //   // totalStaff +
            //   // '\\",\\"industry4\\":\\"' +
            //   // industry +
            //   '\\",\\"text4\\":\\"' +
            //   comments +
            //   '\\"}';

            // let colValues =
            //   '{\\"contact_phone\\": { \\"text\\": \\"' +
            //   userphone +
            //   '\\", \\"phone\\": \\"' +
            //   userphone +
            //   '\\" },\\"contact_email\\": { \\"text\\": \\"' +
            //   useremail +
            //   '\\", \\"email\\": \\"' +
            //   useremail +
            //   '\\" },\\"status\\": \\"' +
            //   userType +
            //   '\\",\\"date3\\": \\"' +
            //   d1 +
            //   '\\",\\"long_text4\\": \\"' +
            //   comments +
            //   '\\"}';

            let colValues =
                '{\\"date\\":\\"' +
                d1 +
                '\\",\\"text\\":\\"' +
                busID +
                '\\",\\"status\\":\\"' +
                fleetType +
                '\\",\\"status7\\":\\"' +
                journeyStage +
                '\\",\\"text4\\":\\"' +
                comments +
                '\\"}';

            let query =
                'mutation { create_item (board_id: 5009300156, group_id: ' +
                fleetGroup +
                ', item_name: "' +
                this.selectedCompany.companyName +
                '", column_values: "' +
                colValues +
                '") { id }}';

            console.log('query', query);

            // WORKS:
            // let query =
            //   'mutation { create_item (board_id: 5009300156, group_id: "topics", item_name: "' +
            //   this.selectedCompany.companyName +
            //   '", column_values: "' +
            //   colValues +
            //   '") { id }}';

            // let query =
            //   'mutation { create_item (board_id: 5009300156, group_id: "' +
            //   this.selectedCompany.mondayStatus.fleetGroup +
            //   '", item_name: "' +
            //   this.selectedCompany.companyName +
            //   '", column_values: "' +
            //   json2 +
            //   '") { id }}';

            console.log(query);
            // let xxx = '{\\"date\\":\\"2023-05-25\\"}';

            axios({
                method: 'post',
                url: 'https://api.monday.com/v2',
                // responseType: asJson ? "json" : "arraybuffer",
                // data: data,
                data: JSON.stringify({
                    query: query
                }),
                // auth: { username: "api", password: "7787b560a3c3436fbd8500675ec28385" }, /// TODO: move into cloud key manager
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjI3NDczMjk3NiwiYWFpIjoxMSwidWlkIjoyOTgzOTEwMiwiaWFkIjoiMjAyMy0wOC0xMlQyMjo1OTozOC44NDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTE5MDcwODcsInJnbiI6InVzZTEifQ.aD_vr66yswbNrCmwe4wjAM3AkVrXrBZ24-P3aj4I8AI'
                }
            })
                .then(response => {
                    console.log('/// ', response.data);
                    self.updateMondayStatus();
                })
                .catch(err => {
                    console.log('error occured', err);
                    NProgress.done();
                });
        },

        updateMondayStatus() {
            let self = this;
            db.collection('companies')
                .doc(self.selectedCompany.id)
                .update({
                    mondayStatus: {
                        companyInMonday: true,
                        activityStatus: self.selectedCompany.mondayStatus.activityStatus,
                        billingStatus: self.selectedCompany.mondayStatus.billingStatus,
                        fleetType: self.selectedCompany.mondayStatus.fleetType,
                        journeyStage: self.selectedCompany.mondayStatus.journeyStage,
                        totalAssets: self.selectedCompany.mondayStatus.totalAssets,
                        totalStaff: self.selectedCompany.mondayStatus.totalStaff,
                        fleetGroup: self.selectedCompany.mondayStatus.fleetGroup,
                        industry: self.selectedCompany.mondayStatus.industry
                    }
                })
                .then(() => {
                    self.close();
                    NProgress.done();
                });
        },

        // new square api functions

        checkIfCustomerExists(company) {
            console.log(company);
            if (!company.id || company.id == '') return;

            let self = this;
            NProgress.start();

            if (company.squareCustomerID && company.squareCustomerID != '') {
                self.searchCustomersByCustomerID(company);
            } else {
                self.searchCustomersByReferenceID(company);
            }
        },

        emailFleetAdmins(company) {
            NProgress.start();
            let self = this;
            // company.adminsEmailed = true;
            console.log('Emailing', company.adminEmails);
            var cloudFunctionEmailFleetAdmins = firebase.functions().httpsCallable('cloudFunctionEmailFleetAdmins');
            cloudFunctionEmailFleetAdmins({
                adminEmails: company.adminEmails,
                msgSubject: 'New fleet administrator notice',
                msgBody: '',
                fleetName: company.companyName,
                templateID: 'd-51552f63b852481eb2dc2cbdbcbbb7a7'
            });
            db.collection('companies')
                .doc(company.id)
                .update({
                    adminsEmailed: true
                })
                .then(() => {
                    NProgress.done();
                });
        },

        searchCustomersByCustomerID(company) {
            let self = this;
            console.log('This company has a Square Customer ID on file. Retrieving from Square...');

            var retrieveCustomerObject = firebase.functions().httpsCallable('retrieveCustomerObject');
            retrieveCustomerObject({
                customerID: company.squareCustomerID
            })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    if (result.data.success) {
                        if (sanitizedMessage.customer) {
                            console.log('Customer located in Square with customer ID: ' + sanitizedMessage.customer.id);
                            console.log('Returned referenceId from Square:', sanitizedMessage.customer.referenceId);
                            // NB if the returned square customer ID does not match the saved customer ID then throw alert!
                            // if (company.squareCustomerID != sanitizedMessage.customer.id) {
                            if (company.id != sanitizedMessage.customer.referenceId) {
                                console.log('Company ID mismatched with Square referenceId - fixing in referenceId in Square');
                                self.fixSquareCustomerMismatch(company);
                            } else if (company.squareCustomerID != sanitizedMessage.customer.id) {
                                console.log('squareCustomerID mismatched with Square customerId - fixing in firestore companies data');
                                self.updateFleetData(company.id, {
                                    squareCustomerID: sanitizedMessage.customer.id
                                });
                                NProgress.done();
                            } else {
                                console.log('Everything OK - company id matches up with referenceId in Square');
                                NProgress.done();
                            }
                        } else {
                            console.log('Search found no existing customer object in Square');
                            console.log('Running function: createNewSquareCustomer');
                            // self.createNewSquareCustomer(company); // add new customer if not found
                            self.searchCustomersByReferenceID(company);
                        }
                    } else {
                        // console.log('Error locating customer in Square');
                        // window.alert('The function searchCustomersByCustomerID returned an error');
                        // NProgress.done();
                        console.log('The function searchCustomersByCustomerID returned an error');
                        // if (sanitizedMessage.errors && sanitizedMessage.errors[0].code == 'NOT_FOUND') self.createNewSquareCustomer(company);
                        self.searchCustomersByReferenceID(company);
                    }
                })
                .catch(function(result) {
                    console.log('Cloud function error: retrieveCustomerObject', result.data.text);
                    window.alert('The cloud function retrieveCustomerObject returned an error');
                    NProgress.done();
                });
        },

        searchCustomersByReferenceID(company) {
            let self = this;
            NProgress.start();
            console.log(
                'This company has no squareCustomerID on file - checking Square for a customer referenceId matching company.id: ',
                company.id
            );

            var searchCustomerObject = firebase.functions().httpsCallable('searchCustomerObject');
            searchCustomerObject({
                referenceID: company.id
            })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    if (result.data.success) {
                        if (sanitizedMessage.customers && sanitizedMessage.customers.length > 0) {
                            console.log('Customer objects located in Square: ' + sanitizedMessage.customers.length);
                            console.log(
                                "Updating this company's squareCustomerID with the returned customerId found in Square for this company",
                                sanitizedMessage.customers[0].id
                            );
                            self.updateFleetData(company.id, {
                                squareCustomerID: sanitizedMessage.customers[0].id
                            });
                            NProgress.done();
                        } else {
                            console.log('Search found no existing customer object in Square for this company.id');
                            console.log('Running function: createNewSquareCustomer');
                            self.createNewSquareCustomer(company); // add new customer if not found
                        }
                    } else {
                        console.log('Search found no existing customer object in Square for this company id');
                        console.log('Running function: createNewSquareCustomer');
                        if (sanitizedMessage.errors) self.createNewSquareCustomer(company);
                        NProgress.done();
                    }
                })
                .catch(function(result) {
                    console.log('Cloud function error: searchCustomerObject', result);
                    window.alert('The cloud function searchCustomerObject returned an error');
                    NProgress.done();
                });
        },

        fixSquareCustomerMismatch(company) {
            console.log('Function Z in flow diagram - fixing the mismatch');
            this.updateCustomerByCustomerID(company);
        },

        updateCustomerByCustomerID(company) {
            let self = this;
            NProgress.start();
            console.log('Updating customer in Square with current company ID as referenceId...');

            var updateCustomerObject = firebase.functions().httpsCallable('updateCustomerObject');
            updateCustomerObject({
                customerID: company.squareCustomerID,
                updateFields: {
                    referenceId: company.id
                }
            })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    // console.log('Result',sanitizedMessage);
                    // console.log('Success?',result.data.success);
                    if (result.data.success) {
                        if (sanitizedMessage.customer) {
                            console.log('Fleet customer updated in Square with correct company id');
                            NProgress.done();
                        } else {
                            console.log('Customer update failed.');
                            NProgress.done();
                        }
                    } else {
                        console.log('The function updateCustomerByCustomerID returned an error');
                    }
                })
                .catch(function(error) {
                    console.log('Cloud function error: updateCustomerObject', error);
                    NProgress.done();
                });
        },

        createNewSquareCustomer(company) {
            console.log('Creating new square customer with referenceID', company.id);
            NProgress.start();
            let self = this;
            let customerData = {
                givenName: company.companyName,
                emailAddress: company.primaryUserEmail != '' ? company.primaryUserEmail : '',
                referenceId: company.id,
                note: company.companyName + ' (companyid: ' + company.id + ')'
            };

            var createNewCustomerObject = firebase.functions().httpsCallable('createNewCustomerObject');
            createNewCustomerObject({
                customerData: customerData
            })
                .then(function(result) {
                    var sanitizedMessage = JSON.parse(result.data.text);
                    console.log('Result', sanitizedMessage);
                    console.log('Success?', result.data.success);
                    if (result.data.success) {
                        console.log('Fleet successfully created as a customer in Square with customer ID: ' + sanitizedMessage.customer.id);
                        self.updateFleetData(company.id, {
                            squareCustomerID: sanitizedMessage.customer.id
                        });
                        NProgress.done();
                    } else {
                        console.log('Error running function createNewSquareCustomer');
                        NProgress.done();
                    }
                })
                .catch(function(result) {
                    console.log('Error with cloud function: createNewCustomerObject');
                    console.log('result', result);
                    NProgress.done();
                });
        },

        // update vessel in fb database with new data, ie new customer id when created in Square
        async updateFleetData(companyID, updateData) {
            console.log(companyID, updateData);
            db.collection('companies')
                .doc(companyID)
                .update(updateData);
        },

        // loadAllVessels: function() {
        // 	let self = this;
        //   let cutoffDate = moment().add(1, 'months');
        // 	db.collection('vessels').orderBy("vesselName", "asc").where('isCompanyVessel','==',true).onSnapshot(function(querySnapshot) {
        // 		self.allVessels = [];
        // 		querySnapshot.forEach((doc) => {
        // 			//let auditD = (doc.data().lastAuditDate ? doc.data().lastAuditDate.toDate() : 'vvv');
        // 			//console.log(auditD);
        // 			self.allVessels.push({
        // 				vesselID: doc.id,
        // 				vesselName: doc.data().vesselName,
        // 				// lastAuditID: (doc.data().lastAuditID ? doc.data().lastAuditID : 'NA'),
        // 				//lastAuditDate: (doc.data().lastAuditDate ? doc.data().lastAuditDate.toDate() : 'Never'),
        // 				// lastAuditDate: (doc.data().lastAuditDate ? self.formatDate(doc.data().lastAuditDate,'dddd D MMMM (h:mm a)') : 'Never'),
        // 				// sinceDate: (doc.data().lastAuditDate ? moment(doc.data().lastAuditDate.toDate()).fromNow() : 'no audit'),
        // 				// lastAuditPercent: (doc.data().lastAuditPercent ? doc.data().lastAuditPercent : 0),
        // 				// lastAuditCode: (doc.data().lastAuditCode ? doc.data().lastAuditCode : 0),
        // 				// hasBeenCounted: false,
        // 				vesselOwnerName: (doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?'),
        // 				vesselOwnerID: doc.data().vesselOwnerID,
        //         isBillable: doc.data().isBillable ? doc.data().isBillable : false,
        //         isPaidFor: doc.data().isPaidFor ? doc.data().isPaidFor : false,
        //         lastVesselCharge: doc.data().lastVesselCharge ? doc.data().lastVesselCharge : 0,
        //         nextVesselCharge: doc.data().nextVesselCharge ? doc.data().nextVesselCharge : 0,
        //         nextChargeDate: doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate() : '',
        //         lastChargeDate: doc.data().lastChargeDate ? doc.data().lastChargeDate.toDate() : '',
        //         remaining: doc.data().nextChargeDate ? moment().to(doc.data().nextChargeDate.toDate()) : '',
        //         isPast: doc.data().nextChargeDate ? moment().isAfter(moment(doc.data().nextChargeDate.toDate())) : false,
        //         isDue: doc.data().nextChargeDate ? moment(cutoffDate).isAfter(moment(doc.data().nextChargeDate.toDate())) : false,
        //         isDemo: doc.data().isDemo ? doc.data().isDemo : false,
        //         vesselType: !doc.data().isBillable ? 'free' : doc.data().isDemo ? 'demo' : 'billable',
        //         expiryStatus: moment().isAfter(moment(doc.data().nextChargeDate.toDate())) ? 'expired' : moment(cutoffDate).isAfter(moment(doc.data().nextChargeDate.toDate())) ? 'due' : 'current',
        // 			});
        // 		});
        // 	});
        // },

        formatDate: function(sentDate, format) {
            // let fixDate = sentDate ? sentDate.toDate() : new Date();
            return moment(sentDate).format(format);
        },

        simpleDate(date) {
            let dateOptions = { timeZone: 'Australia/Sydney', month: 'short', day: 'numeric', year: 'numeric' };
            return date == '' ? '' : new Date(date).toLocaleDateString('en-US', dateOptions);
        },

        loadVesselData(vessel) {
            let self = this;
            let cutoffDate = moment().add(1, 'months');
            this.isGettingData = true;
            this.selectedVessel.vesselName = vessel.vesselName;
            this.selectedVessel.vesselID = vessel.vesselID;
            this.showVessel = true;
            db.collection('vessels')
                .doc(vessel.vesselID)
                .get()
                .then(doc => {
                    self.selectedVessel.vesselOwnerName = doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?';
                    self.selectedVessel.vesselOwnerID = doc.data().vesselOwnerID ? doc.data().vesselOwnerID : '';
                    self.selectedVessel.isBillable = doc.data().isBillable ? doc.data().isBillable : false;
                    self.selectedVessel.isPaidFor = doc.data().isPaidFor ? doc.data().isPaidFor : false;
                    self.selectedVessel.lastVesselCharge = doc.data().lastVesselCharge ? doc.data().lastVesselCharge : 0;
                    self.selectedVessel.nextVesselCharge = doc.data().nextVesselCharge ? doc.data().nextVesselCharge : 0;
                    self.selectedVessel.nextChargeDate = doc.data().nextChargeDate ? doc.data().nextChargeDate.toDate() : '';
                    self.selectedVessel.lastChargeDate = doc.data().lastChargeDate ? doc.data().lastChargeDate.toDate() : '';
                    self.selectedVessel.remaining = doc.data().nextChargeDate ? moment().to(doc.data().nextChargeDate.toDate()) : '';
                    self.selectedVessel.isPast = doc.data().nextChargeDate ? moment().isAfter(moment(doc.data().nextChargeDate.toDate())) : false;
                    self.selectedVessel.isDue = doc.data().nextChargeDate
                        ? moment(cutoffDate).isAfter(moment(doc.data().nextChargeDate.toDate()))
                        : false;
                    self.selectedVessel.isDemo = doc.data().isDemo ? doc.data().isDemo : false;
                    self.selectedVessel.vesselType = !doc.data().isBillable ? 'free' : doc.data().isDemo ? 'demo' : 'billable';
                    self.selectedVessel.expiryStatus = doc.data().nextChargeDate
                        ? moment().isAfter(moment(doc.data().nextChargeDate.toDate()))
                            ? 'expired'
                            : moment(cutoffDate).isAfter(moment(doc.data().nextChargeDate.toDate()))
                            ? 'due'
                            : 'current'
                        : 'unknown';
                })
                .then(() => {
                    self.isGettingData = false;
                })
                .catch(err => {
                    console.log('Error', err);
                    self.errorMsg = err;
                });
        },

        updateVesselData(selectedVessel) {
            console.log(selectedVessel);
            this.$router.push({
                name: 'UpdateVessel',
                params: {
                    sentVessel: selectedVessel
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.fleet-name {
    margin-bottom: 0;
    .button {
        min-width: 300px;
        justify-content: flex-start;
    }
}
.site-wrapper .container {
    max-width: 850px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
    &.is-light {
        margin-bottom: 10px;
    }
}
.expired {
    color: red;
    margin-bottom: 1rem;
}
.due {
    color: darkorange;
    margin-bottom: 1rem;
}
.traffic-light {
    position: relative;
    span {
        float: left;
        margin-right: 10px;
    }
    .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
            background: red;
            &.active {
                background: red;
            }
        }
        &.orange {
            background: orange;
            &.active {
                background: orange;
            }
        }
        &.green {
            background: #03d203;
            &.active {
                background: #03d203;
            }
        }
    }
}
.table {
    margin-bottom: 3rem;
}

.inline {
    display: inline-block;
}

.pad {
    margin-bottom: 6px;
}
</style>
