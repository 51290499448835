<template>
    <div>
        <div v-if="isSteve" class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">Create a new Company</h3>

                <hr />

                <div class="form-wrapper">
                    <div class="form-group-reg">
                        <label class="label-fixed">Company Name:</label>
                        <input class="input" type="text" placeholder="Type name" v-model="company.companyName" required />
                    </div>

                    <div class="form-group-reg">
                        <!-- <label class="label-fixed">State:</label>   -->
                        <div class="select is-primary">
                            <select v-model="company.companyState">
                                <option disabled value="">Company State</option>
                                <option v-for="state in allstates" :key="state.id">
                                    {{ state.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <label class="label-fixed">Company Email:</label>
                        <input class="input" type="text" placeholder="Type email" v-model="company.companyEmail" required />
                    </div>

                    <label class="label-fixed">Primary User:</label>
                    <input class="input" v-model="findName" id="filtername" type="text" placeholder="Start typing crew name..." />

                    <div class="box" v-if="findName.length > 2">
                        <div v-if="filteredNames.length">
                            <ul class="crew-list">
                                <li v-for="(user, index) in filteredNames" :key="index">
                                    <span class="crew-name">{{ user.username }}</span
                                    >&nbsp;
                                    <span class="button is-small is-success" @click="setPrimaryUser(user)">Set as Primary User</span>
                                    <div class="clear"></div>
                                </li>
                            </ul>
                        </div>

                        <div v-else>
                            <p>No users matched your search.</p>
                        </div>
                    </div>

                    {{ company }}

                    <div class="form-group-reg">
                        <label class="label-fixed">Max Vessels:</label>
                        <input class="input" type="number" placeholder="0" v-model="company.maxVessels" required />
                    </div>

                    <!-- <div class="form-group-reg">
            <input class="is-checkradio" id="doesDiving" type="checkbox" v-model="profile.doesDiving">
            <label for="doesDiving">Engaged in diving operations?</label>
          </div>   -->

                    <hr />

                    <div class="buttons">
                        <button class="button is-primary" :disabled="!showButton" @click="createCompany">Create company</button>
                        <button class="button" @click="clearData">Clear</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';

export default {
    name: 'AdminCreateCompany',

    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            firebaseid: '',
            isSteve: false,
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            users: [],
            company: {
                companyName: '',
                companyEmail: '',
                companyState: '',
                maxVessels: 0,
                primaryUserEmail: '',
                primaryUserID: '',
                primaryUserName: '',
                primaryUserPhone: ''
            },
            findName: '',
            allstates: [
                { id: '1', name: 'Tasmania' },
                { id: '2', name: 'Victoria' },
                { id: '3', name: 'New South Wales' },
                { id: '4', name: 'Queensland' },
                { id: '5', name: 'South Australia' },
                { id: '6', name: 'Western Australia' },
                { id: '7', name: 'Northern Territory' }
            ]
        };
    },

    watch: {
        firebaseid: function() {
            this.isSteve = this.firebaseid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.firebaseid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2' ? true : false;
        }
    },

    computed: {
        // filteredNames() {
        //   let filter = new RegExp(this.findName);
        //   // let filter = this.findName;
        //   return this.users.filter(item => {
        //       item.username.match(filter)
        //     }
        //   );
        //   // return this.users.filter(item => item.username === filter);
        // },
        filteredNames: function() {
            var matcher = new RegExp(this.findName, 'i');
            return this.users.filter(function(user) {
                return matcher.test(user.username);
            });
        },
        // isEmailValid: function() {
        //   return (this.profile.email == '') ? false : (this.reg.test(this.profile.email)) ? true : false;
        // },
        showButton() {
            return true;
        },
        phoneNumberValid() {
            //return true;
            if (this.profile.phone) {
                return this.profile.phone.length == 10 && this.profile.phone.slice(0, 2) == '04';
            } else {
                return false;
            }
        }
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                this.firebaseemail = user.email;
                // this.firebasedisplayname = this.userData.username;
                this.firebaseid = user.uid;
                this.loadUsers();
            }
        },

        isNumber: function($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        loadUsers() {
            let self = this;
            db.collection('userdata').onSnapshot(function(querySnapshot) {
                self.users = [];
                querySnapshot.forEach(function(doc) {
                    self.users.push({
                        userid: doc.id,
                        username: doc.data().username ? doc.data().username : '',
                        useremail: doc.data().useremail ? doc.data().useremail : '',
                        userphone: doc.data().userphone ? doc.data().userphone : ''
                    });
                });
            });
        },

        setPrimaryUser(user) {
            this.company.primaryUserEmail = user.useremail;
            this.company.primaryUserID = user.userid;
            this.company.primaryUserName = user.username;
            // this.company.primaryUserPhone = "0" + user.userphone.slice(3);
            this.company.primaryUserPhone = user.userphone;
            this.findName = '';
        },

        createCompany: function() {
            let self = this;
            let message;
            message = {
                title: 'Create Company?',
                body: 'Click YES below to proceed.'
            };
            let options = {
                okText: 'YES',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(function(dialog) {
                    NProgress.start();
                    var adminCreateCompany = firebase.functions().httpsCallable('adminCreateCompany');
                    adminCreateCompany({
                        companyName: self.company.companyName,
                        companyState: self.company.companyState,
                        companyEmail: self.company.companyEmail,
                        primaryUserEmail: self.company.primaryUserEmail,
                        primaryUserID: self.company.primaryUserID,
                        primaryUserName: self.company.primaryUserName,
                        primaryUserPhone: self.company.primaryUserPhone
                    })
                        .then(function(result) {
                            // var sanitizedMessage = result.data.text;
                            // console.log('Result',sanitizedMessage);
                            // console.log('Message',result.data.message);
                            NProgress.done();
                        })
                        .catch(function(error) {
                            console.log('Error', error.text);
                            NProgress.done();
                        });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        clearData: function() {
            this.company = {
                companyName: '',
                companyEmail: '',
                companyState: '',
                maxVessels: 0,
                primaryUserEmail: '',
                primaryUserID: '',
                primaryUserName: '',
                primaryUserPhone: ''
            };
            this.findName = '';
        }
    }
};
</script>

<style lang="scss" scoped>
.is-checkradio[type='checkbox'] + label {
    display: inline-block;
    margin-left: 0;
    padding-top: 0;
}

.form-group-reg {
    .vdatetime {
        input[type='text'] {
            font-size: 1rem !important;
        }
    }
}
ul.crew-list {
    max-width: 320px;
    li {
        margin-bottom: 1rem;
        &:last-of-type {
            margin-bottom: 0;
        }
        .crew-name {
            float: left;
        }
        .button {
            float: right;
        }
    }
}
</style>
