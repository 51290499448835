<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">All OffshoreSMS Fleets</h3>
                <br />
                <div class="notification">
                    <input class="is-checkradio" type="checkbox" id="hideZero" v-model="hideZero" />
                    <label for="hideZero">Hide zero asset items</label>
                </div>

                <div v-for="(fleet, index) in sortedByName(allFleets)" :key="`fleet-${index}`">
                    <div v-if="!hideZero || (hideZero && fleet.adminAssetDatesLoaded && fleet.adminLatestAssetList.length > 0)" class="box">
                        <div class="fleet-title" @click="fleet.showDetails = !fleet.showDetails" :class="{ paid: fleet.totalPaidAssets > 0 }">
                            {{ fleet.fleetName }}
                        </div>
                        <div class="blue onboarding" v-if="fleet.isOnboarding">🚀 Customer is Onboarding</div>
                        <div class="heading">Last Loaded: {{ getDifference(fleet.adminLastDateLoaded) }} ago</div>
                        <div class="status" v-if="fleet.adminAssetDatesLoaded && !fleet.adminIgnored">✅</div>
                        <div class="status" v-if="fleet.adminAssetDatesLoaded && fleet.adminIgnored">⛔️</div>
                        <div class="heading">
                            {{ fleet.totalPaidAssets }} / {{ fleet.adminLatestAssetList.length }} assets paid: ${{ fleet.totalValue }} annual value
                        </div>
                        <div v-if="fleet.showDetails">
                            <div class="buttons">
                                <span
                                    v-if="fleet.showLoadButton && currentAdminIsOffshore"
                                    class="button is-small is-info"
                                    @click="loadAssetData(fleet, index)"
                                    >Reload Assets</span
                                >
                                <router-link v-if="currentAdminIsOffshore" class="button is-small is-primary" to="fleet-information"
                                    >Fleet Details</router-link
                                >
                                <span class="button is-small" :class="[!fleet.adminIgnored ? 'is-light' : 'is-danger']" @click="ignoreFleet(fleet)">{{
                                    fleet.adminIgnored ? 'Unignore' : 'Ignore Fleet'
                                }}</span>
                            </div>
                            <div class="spacer s10"></div>
                            <div v-if="fleet.adminLatestAssetList.length > 0" class="tags">
                                <div class="tag" v-for="(asset, idx) in fleet.adminLatestAssetList" :key="`asset-1-${idx}`">
                                    <span>{{ asset.vesselName }} [</span>
                                    <span>{{ asset.aaaPlans.includes('pro') ? 'PRO' : asset.aaaPlans.includes('sms') ? 'STD' : 'FREE' }}&nbsp;</span>
                                    <span>{{ isCurrent(asset.nextChargeDate) ? 'Current' : 'Expired' }}]</span>
                                    <span>{{ asset.isDemo }}</span>
                                </div>
                            </div>
                            <div v-else>No assets in this fleet.</div>
                        </div>
                    </div>
                </div>

                <div class="widget">
                    <div class="group">
                        <div class="group-title a">>50 Assets</div>
                        <div class="group-tally">{{ tier5Tally }} fleets</div>
                        <div class="group-value">${{ Math.round(tier5Value / 1.1) }} /yr</div>
                        <div class="group-average">${{ Math.round(Math.round(tier5Value / 1.1) / tier5Tally) }} /fleet</div>
                    </div>
                    <div class="group">
                        <div class="group-title b">21-50 Assets</div>
                        <div class="group-tally">{{ tier4Tally }} fleets</div>
                        <div class="group-value">${{ Math.round(tier4Value / 1.1) }} /yr</div>
                        <div class="group-average">${{ Math.round(Math.round(tier4Value / 1.1) / tier4Tally) }} /fleet</div>
                    </div>
                    <div class="group">
                        <div class="group-title c">6-20 Assets</div>
                        <div class="group-tally">{{ tier3Tally }} fleets</div>
                        <div class="group-value">${{ Math.round(tier3Value / 1.1) }} /yr</div>
                        <div class="group-average">${{ Math.round(Math.round(tier3Value / 1.1) / tier3Tally) }} /fleet</div>
                    </div>
                    <div class="group">
                        <div class="group-title d">3-5 Assets</div>
                        <div class="group-tally">{{ tier2Tally }} fleets</div>
                        <div class="group-value">${{ Math.round(tier2Value / 1.1) }} /yr</div>
                        <div class="group-average">${{ Math.round(Math.round(tier2Value / 1.1) / tier2Tally) }} /fleet</div>
                    </div>
                    <div class="group">
                        <div class="group-title e">1-2 Assets</div>
                        <div class="group-tally">{{ tier1Tally }} fleets</div>
                        <div class="group-value">${{ Math.round(tier1Value / 1.1) }} /yr</div>
                        <div class="group-average">${{ Math.round(Math.round(tier1Value / 1.1) / tier1Tally) }} /fleet</div>
                    </div>
                    <div class="group total">
                        <div class="group-title e">TOTALS</div>
                        <div class="group-tally">{{ totalFleetTally }} fleets</div>
                        <div class="group-tally">{{ totalPaidAssets }} paid assets</div>
                        <div class="group-tally">{{ (totalPaidAssets / totalFleetTally).toFixed(1) }} assets/fleet</div>
                        <div class="group-value">${{ Math.round(totalValue / 1.1) }} ARR</div>
                        <div class="group-value">${{ Math.round(Math.round(totalValue / 1.1) / totalFleetTally) }} /fleet</div>
                        <div class="group-average">${{ Math.round(Math.round(totalValue / 1.1) / totalPaidAssets) }} /asset</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '../main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import NProgress from 'nprogress';
const SimpleModal = () => import('../reusables/SimpleModal.vue');

export default {
    name: 'AllFleetData',
    components: {
        // SimpleModal
    },
    mounted() {
        this.loadFleets();
    },

    computed: {
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        offshoresmsAdmins() {
            return this.$store.getters.getOffshoresmsAdmins;
        },
        currentAdminIsOffshore() {
            return this.offshoresmsAdmins.includes(this.userData.userid);
        },
        tier1Tally() {
            return this.allFleets.filter(m => !m.adminIgnored && m.totalPaidAssets < 3).length;
        },
        tier1Value() {
            return this.allFleets.filter(m => !m.adminIgnored && m.totalPaidAssets < 3).reduce((acc, n) => acc + n.totalValue, 0);
        },
        tier2Tally() {
            return this.allFleets.filter(m => !m.adminIgnored && m.totalPaidAssets > 2 && m.totalPaidAssets < 6).length;
        },
        tier2Value() {
            return this.allFleets
                .filter(m => !m.adminIgnored && m.totalPaidAssets > 2 && m.totalPaidAssets < 6)
                .reduce((acc, n) => acc + n.totalValue, 0);
        },
        tier3Tally() {
            return this.allFleets.filter(m => !m.adminIgnored && m.totalPaidAssets > 5 && m.totalPaidAssets < 21).length;
        },
        tier3Value() {
            return this.allFleets
                .filter(m => !m.adminIgnored && m.totalPaidAssets > 5 && m.totalPaidAssets < 21)
                .reduce((acc, n) => acc + n.totalValue, 0);
        },
        tier4Tally() {
            return this.allFleets.filter(m => !m.adminIgnored && m.totalPaidAssets > 20 && m.totalPaidAssets < 51).length;
        },
        tier4Value() {
            return this.allFleets
                .filter(m => !m.adminIgnored && m.totalPaidAssets > 20 && m.totalPaidAssets < 51)
                .reduce((acc, n) => acc + n.totalValue, 0);
        },
        tier5Tally() {
            return this.allFleets.filter(m => !m.adminIgnored && m.totalPaidAssets > 50).length;
        },
        tier5Value() {
            return this.allFleets.filter(m => !m.adminIgnored && m.totalPaidAssets > 50).reduce((acc, n) => acc + n.totalValue, 0);
        },
        totalFleetTally() {
            return this.tier1Tally + this.tier2Tally + this.tier3Tally + this.tier4Tally + this.tier5Tally;
        },
        totalValue() {
            return this.tier1Value + this.tier2Value + this.tier3Value + this.tier4Value + this.tier5Value;
        },
        totalPaidAssets() {
            return this.allFleets.filter(m => !m.adminIgnored).reduce((acc, n) => acc + n.totalPaidAssets, 0);
        }
    },

    data() {
        return {
            allFleets: [],
            allFleetData: [],
            hideZero: true,
            today: new Date()
        };
    },
    methods: {
        sortedByName(arr) {
            return arr.slice().sort(function(a, b) {
                return a.fleetName < b.fleetName ? -1 : a.fleetName > b.fleetName ? 1 : 0;
            });
        },

        isCurrent(date) {
            if (date == '') return false;
            let realDate = !isNaN(new Date(date)) ? date : new Date(date.toDate());
            return realDate.getTime() > this.today.getTime();
        },

        simpleDate(date) {
            let realDate = !isNaN(new Date(date)) ? date : new Date(date.toDate());
            let dateOptions = { timeZone: 'Australia/Sydney', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
            return date == '' ? '' : new Date(realDate).toLocaleDateString('en-US', dateOptions);
        },

        getDifference(sentTimestamp) {
            let newDate = new Date();
            var diff = Math.round((newDate - sentTimestamp) / 1000);

            var d = Math.floor(diff / (24 * 60 * 60));
            diff = diff - d * 24 * 60 * 60;
            var h = Math.floor(diff / (60 * 60));
            diff = diff - h * 60 * 60;
            var m = Math.floor(diff / 60);
            diff = diff - m * 60;
            var s = diff;

            return d + ' days, ' + h + ' hours, ' + m + ' minutes';
            // return d + ':' + h + ':' + m + ':' + s;
        },

        async loadFleets() {
            NProgress.start();
            let self = this;
            let now = new Date();
            await db
                .collection('companies')
                .get()
                .then(async snap => {
                    this.allFleets = [];
                    await Promise.all(
                        snap.docs.map(async (doc, index) => {
                            let adminLastDateLoaded = doc.data().adminLastDateLoaded ? doc.data().adminLastDateLoaded.toDate() : now;
                            let adminLatestAssetList = doc.data().adminLatestAssetList ? doc.data().adminLatestAssetList : [];
                            let data = {
                                id: doc.id,
                                fleetID: doc.id,
                                fleetName: doc.data().companyName,
                                adminAssetDatesLoaded: doc.data().adminAssetDatesLoaded ? doc.data().adminAssetDatesLoaded : false,
                                adminLastDateLoaded: adminLastDateLoaded,
                                adminLatestAssetList: adminLatestAssetList,
                                showDetails: false,
                                showLoadButton: true,
                                totalValue: adminLatestAssetList
                                    .filter(m => self.isCurrent(m.nextChargeDate))
                                    .reduce((acc, n) => acc + n.nextVesselCharge, 0),
                                totalPaidAssets: adminLatestAssetList.filter(m => self.isCurrent(m.nextChargeDate) && m.aaaPlans.includes('sms'))
                                    .length,
                                isOnboarding: doc.data().isOnboarding ? doc.data().isOnboarding : false,
                                adminIgnored: doc.data().adminIgnored ? doc.data().adminIgnored : false
                            };
                            self.allFleets.push(data);
                        })
                    );
                })
                // .then(async () => {
                //     self.allFleetData = [];
                //     await Promise.all(
                //         this.allFleets.map(async fleet => {
                //             // loop through all adminLatestAssetList for each fleet and create a value per fleet number, a total paid assets number

                //             fleet.totalValue = fleet.adminLatestAssetList
                //                 .filter(m => self.isCurrent(m.nextChargeDate))
                //                 .reduce((acc, n) => acc + n.nextVesselCharge, 0);

                //             fleet.totalPaidAssets = fleet.adminLatestAssetList.filter(
                //                 m => self.isCurrent(m.nextChargeDate) && m.aaaPlans.includes('sms')
                //             ).length;
                //         })
                //     );
                // })
                .then(() => {
                    NProgress.done();
                })
                .catch(error => {
                    console.log('error', error);
                    NProgress.done();
                });
        },

        async ignoreFleet(fleet) {
            NProgress.start();
            db.collection('companies')
                .doc(fleet.fleetID)
                .update({
                    adminIgnored: !fleet.adminIgnored
                });
            fleet.adminIgnored = !fleet.adminIgnored;
            NProgress.done();
        },

        async loadAssetData(fleet, index) {
            console.log(fleet, index);
            let self = this;
            NProgress.start();
            // fleet.adminLatestAssetList = [];

            let tempAssets = [];
            await db
                .collection('companies')
                .doc(fleet.fleetID)
                .collection('companyVessels')
                .get()
                .then(async snap => {
                    await Promise.all(
                        snap.docs.map(async (doc, index) => {
                            tempAssets.push(doc.data());
                        })
                    );
                });

            let allAssets = [];
            await Promise.all(
                tempAssets.map(async asset => {
                    await db
                        .collection('vessels')
                        .doc(asset.vesselID)
                        .get()
                        .then(doc => {
                            let data = doc.data();
                            let isExpired = data.isExpired ? data.isExpired : false;
                            let isDemo = data.isDemo ? data.isDemo : false;
                            console.log('CHECK', data.vesselName, isDemo);
                            if (!isExpired && !isDemo)
                                allAssets.push({
                                    vesselName: data.vesselName,
                                    vesselID: doc.id,
                                    aaaPlans: data.aaaPlans ? data.aaaPlans : [],
                                    nextChargeDate: data.nextChargeDate ? data.nextChargeDate.toDate() : '',
                                    nextVesselCharge: data.nextVesselCharge ? data.nextVesselCharge : 0
                                });
                        });
                })
            );
            fleet.adminAssetDatesLoaded = true;
            fleet.adminLastDateLoaded = new Date();
            fleet.showLoadButton = false;
            fleet.adminLatestAssetList = allAssets;
            fleet.totalValue = allAssets.filter(m => self.isCurrent(m.nextChargeDate)).reduce((acc, n) => acc + n.nextVesselCharge, 0);
            fleet.totalPaidAssets = allAssets.filter(m => self.isCurrent(m.nextChargeDate) && m.aaaPlans.includes('sms')).length;
            db.collection('companies')
                .doc(fleet.fleetID)
                .update({
                    adminAssetDatesLoaded: true,
                    adminLastDateLoaded: new Date(),
                    adminLatestAssetList: allAssets
                });
            NProgress.done();
        }
    }
};
</script>

<style lang="scss" scoped>
.box {
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    .fleet-title {
        transition: 0.3s;
        &.paid {
            color: mediumseagreen;
        }
        &:hover {
            color: orange;
        }
    }
    .status {
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .onboarding {
        margin: 3px 0 6px;
    }
}
.widget {
    position: fixed;
    right: 20px;
    bottom: 120px;
    border: 1px solid #ddd;
    background: #fff;
    .group {
        border-bottom: 1px solid #ddd;
        &:last-of-type {
            border-bottom: none;
        }
        .group-title {
            padding: 5px;
            background: #f5f5f5;
            font-size: 11px;
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;
            &.a {
                background: #e3f3f4;
            }
            &.b {
                background: #fcf8df;
            }
            &.c {
                background: #f8eefd;
            }
            &.d {
                background: #e4f4e3;
            }
            &.e {
                background: #fbeded;
            }
        }
        .group-tally {
            padding: 5px 8px 0;
            font-size: 11px;
            text-transform: uppercase;
            text-align: center;
        }
        .group-value {
            padding: 5px 8px 0;
            font-size: 11px;
            text-transform: uppercase;
            text-align: center;
        }
        .group-average {
            padding: 2px 8px 8px;
            font-size: 11px;
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;
        }
        &.total {
            .group-title {
                background: mediumseagreen;
                color: #fff;
            }
            .group-tally {
                font-weight: bold;
                color: cadetblue;
            }
            .group-value {
                font-weight: bold;
                color: cadetblue;
            }
            .group-average {
                font-weight: bold;
                padding: 5px 8px 8px;
                color: mediumseagreen;
            }
        }
    }
}
</style>
