<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <div class="before-icon"><font-awesome-icon icon="globe" /></div>
                <h4 class="icon">Micro Emergency Procedures</h4>
                <div class="clear"></div>

                <table v-if="poolOfDocs.length" class="table is-bordered is-fullwidth">
                    <tr>
                        <th>Title</th>
                        <th>Edit</th>
                    </tr>
                    <tr v-for="(item, index) in poolOfDocs" :key="index">
                        <td>
                            <span>{{ item.title }}</span>
                        </td>
                        <td>
                            <div class="buttons">
                                <!-- <span class="button is-primary" @click="editItem(item)">Content</span> -->
                                <span class="button is-primary" @click="launchItemWindow(item, 'Edit')">Content</span>
                                <span class="button is-warning" @click="editMeta(item)">Meta</span>
                                <span class="button is-delete" @click="archiveItem(item)">Archive</span>
                            </div>
                        </td>
                    </tr>
                </table>

                <span v-if="poolOfDocs.length > 0" class="simple-link flex right" @click="enableSortModal">Update order</span>
                <div class="spacer s100"></div>

                <div class="clear"></div>

                <div class="buttons floating">
                    <span class="button is-primary is-medium" @click="launchItemWindow({}, 'Write New')">Add a Procedure</span>
                    <span class="button is-info is-medium" @click="$router.go(-1)"><font-awesome-icon icon="chevron-left" /> &nbsp;Back</span>
                </div>
            </div>
        </div>

        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': showEditItemModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">Edit Selected Item</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <input class="input" type="text" placeholder="Type title..." v-model="selectedEditItem.title" />
                    <div class="spacer s10"></div>
                    <textarea class="input textarea" placeholder="Type details..." v-model="selectedEditItem.details"></textarea>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click.prevent="saveEditItem(selectedEditItem)">Save</button>
                    <button class="button is-danger" @click.prevent="close">Cancel</button>
                </footer>
            </div>
        </div>

        <filter-tags-modal
            v-if="showEditMetaModal"
            :title="selectedEditMeta.title"
            :tagCategories="tagCategories"
            :sentSelectedTags="selectedEditMeta.tags"
            @closed="showEditMetaModal = false"
            @returnResult="updateTags($event)"
        >
        </filter-tags-modal>

        <div class="modal modal-fx-fadeInScale" :class="{ 'is-active': enableSort }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Drag to change order of items</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <div v-if="poolOfDocs.length">
                        <draggable v-model="poolOfDocs" class="sorting-list" group="people" @start="drag = true" @end="drag = false">
                            <div v-for="(item, index) in poolOfDocs" :key="item.id">{{ index + 1 }} - {{ item.title }} <span>&#8597;</span></div>
                        </draggable>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="saveNewOrder()">
                        Update
                    </button>
                    <button class="button cancel" @click.prevent="close">Cancel</button>
                </footer>
            </div>
        </div>

        <!-- <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': showEditMetaModal }">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
          <header class="modal-card-head is-warning">
            <p class="modal-card-title">{{selectedEditMeta.title}}</p>
            <button class="delete" aria-label="close" @click.prevent="close"></button>
          </header>
          <section class="modal-card-body">
            <label class="label">Place Index</label>
            <input class="input" type="tel" placeholder="999" maxlength="4" v-on:keypress="isNumber($event)" v-model="selectedEditMeta.index">
            <div class="spacer s30"></div>
            <div v-if="selectedEditMeta != ''" class="form-group-reg">
              <div class="checkbox-wrapper">
              <label class="label">Fast Track Codes</label>
                <div class="spacer s10"></div>
                <div class="field" v-for="code in allCodes" :key="code.id">
                  <input class="is-checkradio" type="checkbox" :id="code.id" :value="code.code" v-model="tempSelectedMetaCodes">
                  <label :class="{'has-code' : tempSelectedMetaCodes.includes(code.code)}" :for="code.id">{{code.name}}</label>
                </div>
                <div class="clear"></div>
              </div>
            </div>

            <div v-if="selectedEditMeta != ''" class="posi">{{tempSelectedMetaCodes}}<br>{{codeGroups}}</div>

          </section>
          <footer class="modal-card-foot">
            <button class="button is-warning" @click.prevent="saveEditMeta(selectedEditMeta,tempSelectedMetaCodes)">Save</button>
            <button class="button is-danger" @click.prevent="close">Cancel</button>
          </footer>
        </div>
      </div> -->

        <div class="modal maximise modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': addItemModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">Add a Helper Emergency Procedure</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>
                <section class="modal-card-body">
                    <input class="input" type="text" placeholder="Type title..." v-model="newItem.title" />

                    <div class="spacer s10"></div>
                    <textarea class="input textarea" placeholder="Type details..." v-model="newItem.details"></textarea>

                    <!-- <div class="spacer s30"></div>

            <label class="label">Place Index</label>
            <input class="input" type="tel" placeholder="999" maxlength="4" v-on:keypress="isNumber($event)" v-model="newItem.index">

            <div class="spacer s30"></div>
            <div class="form-group-reg">
              <div class="checkbox-wrapper">
              <label class="label">Fast Track Codes</label>
                <div class="spacer s10"></div>
                <div class="field" v-for="code in allCodes" :key="code.id">
                  <input class="is-checkradio" type="checkbox" :id="code.id" :value="code.code" v-model="newItem.codes">
                  <label :class="{'has-code' : newItem.codes.includes(code.code)}" :for="code.id">{{code.name}}</label>
                </div>
                <div class="clear"></div>
              </div>
            </div> -->

                    <div class="spacer s30"></div>
                </section>
                <footer class="modal-card-foot">
                    <button :disabled="newItem.details == '' || newItem.title == ''" class="button is-primary" @click.prevent="saveNewItem()">
                        Save New Item
                    </button>
                    <button class="button is-danger" @click.prevent="close">Cancel</button>
                </footer>
            </div>
        </div>

        <edit-item-window :item="selectedItem" :allowPDFs="false" v-if="showItemWindow" v-on:sendItem="returnedItem"></edit-item-window>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import draggable from 'vuedraggable';
const FilterTagsModal = () => import('@/reusables/FilterTagsModal.vue');
const EditItemWindow = () => import('@/reusables/EditItemWindow.vue');

export default {
    name: 'BuildMicroEops',
    components: {
        FilterTagsModal,
        draggable,
        EditItemWindow
    },
    mounted() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data() {
        return {
            poolOfDocs: [],
            selectedItem: { title: '', details: '' },
            selectedEditItem: { title: '', details: '' },
            selectedEditMeta: '',
            showDetailsModal: false,
            showEditItemModal: false,
            showEditMetaModal: false,

            addItemModal: false,
            newItem: {
                title: '',
                details: '',
                codes: [],
                index: 999
            },
            allTags: [],
            tagCategories: [],
            selectedTags: [],
            enableSort: false,
            showItemWindow: false
        };
    },

    computed: {
        userData() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.userData
                ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2'
                    ? true
                    : false
                : false;
        },
        selectedEditMetaCodes() {
            return this.selectedEditMeta.codes.filter(item => item.checked).map(itemB => item);
        },
        tempSelectedCodeNames() {
            return this.allCodes
                .filter(code => this.tempSelectedMetaCodes.includes(code.code))
                .map(item => item.name)
                .join(', ');
        }
    },

    methods: {
        onUserLogin(user) {
            if (user) {
                this.displayDocs();
                this.loadAllTags();
                setTimeout(() => {
                    this.loadTagCategories();
                }, 600);
            }
        },

        enableSortModal() {
            this.poolOfDocs = this.sorted(this.poolOfDocs);
            this.enableSort = true;
        },

        sorted(arr) {
            // Set slice() to avoid to generate an infinite loop!
            return arr.slice().sort(function(a, b) {
                return a.index - b.index;
            });
        },

        saveNewOrder() {
            var self = this;
            NProgress.start();
            this.poolOfDocs.forEach((item, index) => {
                db.collection('microHelpersEOPS')
                    .doc(item.id)
                    .update({
                        index: index + 1
                    })
                    .then(() => {
                        self.enableSort = false;
                        NProgress.done();
                    })
                    .catch(error => {
                        console.error('Error updating indexes: ', error);
                        NProgress.done();
                    });
            });
        },

        loadAllTags() {
            let self = this;
            db.collection('tagLibrary')
                .where('assetCategory', '==', 'vessel')
                .orderBy('index', 'asc')
                .onSnapshot(querySnapshot => {
                    self.allTags = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        data.editing = false;
                        data.changed = false;
                        let parentCatID = doc.data().parentCatID ? doc.data().parentCatID : 'xSu8bBuH6RbVsPdE7SWs'; // this cat ID is for "Other" cat
                        data.parentCatID = parentCatID;
                        self.allTags.push(data);
                    });
                });
        },

        loadTagCategories() {
            NProgress.start();
            let self = this;
            db.collection('tagCategories')
                .where('assetCategory', '==', 'vessel')
                .orderBy('index', 'asc')
                .onSnapshot(querySnapshot => {
                    self.tagCategories = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        data.editing = false;
                        data.changed = false;
                        data.tags = self.allTags.filter(n => n.parentCatID == doc.id);
                        self.tagCategories.push(data);
                    });
                });
            setTimeout(() => {
                self.tagsLoaded = true;
            }, 500);
            NProgress.done();
        },

        updateTags(result) {
            console.log(result);
            let self = this;
            this.selectedTags = result;
            console.log(self.selectedEditMeta);
            NProgress.start();
            let saveData = {
                collection: 'microHelpersEOPS',
                docID: self.selectedEditMeta.id,
                tags: result,
                index: parseInt(self.selectedEditMeta.index)
            };
            console.log(saveData);
            var adminSaveSmsItemMeta = firebase.functions().httpsCallable('adminSaveSmsItemMeta');
            adminSaveSmsItemMeta(saveData);
            this.close();
            NProgress.done();
        },

        isNumber($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        close() {
            this.showDetailsModal = false;
            this.showEditItemModal = false;
            this.showEditMetaModal = false;
            this.selectedItem = { title: '', details: '' };
            this.selectedEditItem = { title: '', details: '' };
            this.selectedEditMeta = '';
            this.addItemModal = false;
            this.newItem = {
                title: '',
                details: '',
                index: 999
            };
            this.tempSelectedMetaCodes = [];
            this.enableSort = false;
            this.showItemWindow = false;
        },

        displayDocs() {
            let self = this;
            db.collection('microHelpersEOPS')
                .orderBy('index', 'asc')
                .where('archived', '==', false)
                .onSnapshot(querySnapshot => {
                    self.poolOfDocs = [];
                    querySnapshot.forEach(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        data.tags = doc.data().tags ? doc.data().tags : [];
                        self.poolOfDocs.push(data);
                    });
                });
        },

        launchItemWindow(item, action) {
            console.log('item', item);
            this.selectedItem = {
                id: item.id,
                title: item.title ? item.title : '',
                details: item.details ? item.details : '',
                link: item.link ? item.link : '',
                uploadUrls: item.uploadUrls ? item.uploadUrls : [],
                uploadPDFs: item.uploadPDFs ? item.uploadPDFs : [],
                showLink: true,
                index: item.index ? item.index : 999,
                heading: 'Emergency Procedure',
                action: action
                // new version control
                // currentVersionID: item.currentVersionID,
                // currentVersionTitle: item.currentVersionTitle,
                // loadVersion: item.loadVersion,
                // versionPath: '/microHelpersEOPS'
            };
            this.showItemWindow = true;
        },

        returnedItem(returnItem) {
            // console.log(returnItem);

            this.showItemWindow = false;
            this.selectedItem = {};

            if (returnItem.edited) {
                NProgress.start();
                let self = this;
                if (returnItem.isNewItem) {
                    db.collection('microHelpersEOPS')
                        .add({
                            archived: returnItem.archived ? returnItem.archived : false,
                            title: returnItem.title ? returnItem.title : 'No title',
                            details: returnItem.details ? returnItem.details : '',
                            link: returnItem.link ? returnItem.link : '',
                            index: returnItem.index ? returnItem.index : 999,
                            uploadUrls: returnItem.uploadUrls ? returnItem.uploadUrls : [],
                            uploadPDFs: returnItem.uploadPDFs ? returnItem.uploadPDFs : [],
                            tags: returnItem.tags ? returnItem.tags : [],
                            versionControl: firebase.firestore.FieldValue.arrayUnion(firebase.firestore.Timestamp.fromDate(new Date()))
                        })
                        .then(docRef => {
                            NProgress.done();
                        })
                        .catch(error => {
                            console.error('Error adding new item: ', error);
                            NProgress.done();
                        });
                } else {
                    db.collection('microHelpersEOPS')
                        .doc(returnItem.id)
                        .update({
                            archived: returnItem.archived ? returnItem.archived : false,
                            title: returnItem.title ? returnItem.title : 'No title',
                            details: returnItem.details ? returnItem.details : '',
                            link: returnItem.link ? returnItem.link : '',
                            index: returnItem.index ? returnItem.index : 999,
                            uploadUrls: returnItem.uploadUrls ? returnItem.uploadUrls : [],
                            uploadPDFs: returnItem.uploadPDFs ? returnItem.uploadPDFs : [],
                            tags: returnItem.tags ? returnItem.tags : [],
                            versionControl: firebase.firestore.FieldValue.arrayUnion(firebase.firestore.Timestamp.fromDate(new Date()))
                        })
                        .then(docRef => {
                            NProgress.done();
                        })
                        .catch(error => {
                            console.error('Error updating item: ', error);
                            NProgress.done();
                        });
                }
            } else {
                NProgress.done();
            }
        },

        archiveItem(item) {
            let message;
            message = {
                title: 'Really archive this helper?',
                body: 'Click YES below to archive.'
            };
            let options = {
                okText: 'YES, ARCHIVE',
                backdropClose: true,
                cancelText: 'Cancel'
            };
            this.$dialog
                .confirm(message, options)
                .then(dialog => {
                    NProgress.start();
                    db.collection('microHelpersEOPS')
                        .doc(item.id)
                        .update({
                            archived: true
                        })
                        .then(() => {
                            NProgress.done();
                        });
                })
                .catch(error => {
                    console.log(error);
                    this.close();
                    NProgress.done();
                });
        },

        updateList(localList, selectedList, savedList) {
            let tempList = localList.filter(item => savedList.includes(item.name)).map(itemtwo => itemtwo);
            console.log(tempList);
            tempList.forEach(item => selectedList.push(item));
        },

        showDetails(item) {
            this.tempSelectedMetaCodes = item.codes;
            this.selectedItem = item;
            this.showDetailsModal = true;
        },

        editItem(item) {
            this.selectedEditItem = item;
            this.showEditItemModal = true;
        },

        saveEditItem(itemToSave) {
            let self = this;
            console.log(itemToSave);
            NProgress.start();
            var adminSaveSmsItem = firebase.functions().httpsCallable('adminSaveSmsItem');
            adminSaveSmsItem({
                collection: 'microHelpersEOPS',
                docID: itemToSave.id,
                title: itemToSave.title,
                details: itemToSave.details
            })
                .then(function(result) {
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        },

        editMeta(item) {
            // console.log(item);
            this.tempSelectedMetaCodes = item.codes;
            this.selectedEditMeta = item;
            this.showEditMetaModal = true;
        },

        // saveEditMeta(itemToSave,newCodes) {
        //   let self = this;
        //   // console.log(itemToSave);
        //   // console.log(newCodes);
        //   NProgress.start();
        //   var adminSaveSmsItemMeta = firebase.functions().httpsCallable('adminSaveSmsItemMeta');
        //   adminSaveSmsItemMeta({
        //     collection: 'microHelpersEOPS',
        //     docID: itemToSave.id,
        //     codes: newCodes,
        //     codeGroups: self.codeGroups,
        //           tags: self.selectedTags,
        //     index: parseInt(itemToSave.index),
        //   })
        //   .then(function(result) {
        //     self.close();
        //     NProgress.done();

        //   })
        //   .catch(function(error) {
        //     console.log('Error',error);
        //     NProgress.done();
        //   });

        // },

        addItem() {
            this.addItemModal = true;
        },

        saveNewItem() {
            console.log(this.newItem);
            let self = this;
            NProgress.start();
            var adminSaveNewSmsItem = firebase.functions().httpsCallable('adminSaveNewSmsItem');
            adminSaveNewSmsItem({
                collection: 'microHelpersEOPS',
                title: self.newItem.title,
                details: self.newItem.details,
                index: parseInt(self.newItem.index)
            })
                .then(function(result) {
                    self.close();
                    NProgress.done();
                })
                .catch(function(error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
label {
    margin-bottom: 10px;
    display: block;
}

.checkbox-wrapper {
    .field {
        float: left;
        margin-right: 2rem;
        .has-code {
            color: red;
            font-weight: bold;
        }
    }
}

.site-wrapper .container {
    max-width: 1050px;
}
.buttons {
    margin-bottom: 5px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
input[type='text'],
input[type='tel'] {
    padding: 2rem 1rem;
}

textarea {
    min-height: 120px;
    height: 400px;
    margin-top: 10px;
    padding: 1.5rem 1rem;
}

.show-details {
    cursor: pointer;
    &:hover {
        color: dodgerblue;
    }
}

.modal {
    .modal-card-body {
        position: relative;
    }
}
.posi {
    position: absolute;
    // bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    background: #fff;
    z-index: 999;
}
</style>
