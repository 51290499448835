<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="container">
                <h3 class="is-size-5">Fix SMS Info</h3>
                <br />
                <!--    <div class="input-wrapper">
              <label>Source Collection Path (no trailing slashes)</label>
              <input class="input" type="text" placeholder="Paste source doc path" v-model="sourcePath">
            </div> -->

                <div v-if="!isSelected" class="notification is-primary">
                    <input
                        class="input"
                        v-model="findName"
                        id="filtername"
                        autocomplete="off"
                        type="text"
                        placeholder="Start typing vessel name..."
                    />
                </div>

                <div class="buttons">
                    <span v-if="findName != ''" class="button is-warning" @click="clearVessel()">Clear</span>
                </div>

                <hr />

                <div v-if="selectedVessel != ''" class="highlight">{{ selectedVessel.vesselName }} ({{ selectedVessel.vesselOwnerName }})</div>

                <div v-if="findName != '' && !isSelected">
                    <div v-for="(vessel, index) in filteredNames" :key="index">
                        <div class="buttons">
                            <span class="button is-light" @click="selectVessel(vessel)">{{ vessel.vesselName }} ({{ vessel.vesselOwnerName }})</span>
                        </div>
                    </div>
                </div>

                <div class="clear"></div>

                <div v-if="findName != '' && isSelected">
                    <h4 class="icon">Modify vessel info</h4>
                    <div class="clear"></div>

                    <div class="spacer s30"></div>

                    <div class="form-group-reg">
                        <label class="label">Vessel Name</label>
                        <input class="input" type="text" placeholder="The vessel name" v-model="selectedVessel.vesselName" />
                    </div>

                    <div class="form-group-reg">
                        <label class="label">Vessel UVI</label>
                        <input class="input" type="text" placeholder="The 6 digit UVI number" v-model="selectedVessel.vesselUVI" />
                    </div>

                    <div class="spacer s30"></div>

                    <div class="form-group-reg">
                        <label class="label">Vessel Owner Name</label>
                        <input class="input" type="text" placeholder="The vessel owner name.." v-model="vesselInfo.vesselOwnerName" />
                    </div>

                    <div class="form-group-reg">
                        <label class="label">Vessel Owner Phone</label>
                        <input class="input" type="text" placeholder="The vessel owner phone.." v-model="vesselInfo.vesselOwnerPhone" />
                    </div>

                    <div class="form-group-reg">
                        <label class="label">Vessel Owner Email</label>
                        <input class="input" type="text" placeholder="The vessel owner email address.." v-model="vesselInfo.vesselOwnerEmail" />
                    </div>

                    <div class="spacer s30"></div>

                    <div class="form-group-reg">
                        <label class="label">Home Port</label>
                        <input class="input" type="email" placeholder="The vessel's home port" v-model="vesselInfo.homePort" />
                    </div>

                    <div class="form-group-reg">
                        <label class="label">Vessel Description</label>
                        <textarea class="textarea" placeholder="A basic description of your vessel..." v-model="vesselInfo.description"></textarea>
                    </div>

                    <div class="spacer s10"></div>

                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Vessel States</label>
                            <div class="field" v-for="item in allstates" :key="item.id">
                                <input class="is-checkradio" type="checkbox" :id="item.id" :value="item.name" v-model="vesselInfo.selectedStates" />
                                <label :for="item.id">{{ item.name }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="spacer s10"></div>

                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Vessel industries</label>
                            <div class="field" v-for="item in industries" :key="item.id">
                                <input
                                    class="is-checkradio"
                                    type="checkbox"
                                    :id="item.id"
                                    :value="item.name"
                                    v-model="vesselInfo.selectedIndustries"
                                />
                                <label :for="item.id">{{ item.name }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="spacer s10"></div>

                    <div class="form-group-reg">
                        <div class="checkbox-wrapper">
                            <label class="label">Vessel survey Classes</label>
                            <div class="field" v-for="item in classes" :key="item.id">
                                <input class="is-checkradio" type="checkbox" :id="item.id" :value="item.name" v-model="vesselInfo.selectedClasses" />
                                <label :for="item.id">{{ item.name }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group-reg">
                        <div class="spacer s30"></div>
                        <label class="label">Vessel Operational Areas</label>
                        <div class="checkbox-wrapper">
                            <div class="field" v-for="item in areas" :key="item.id">
                                <input class="is-checkradio" type="checkbox" :id="item.id" :value="item.name" v-model="vesselInfo.selectedAreas" />
                                <label :for="item.id">{{ item.name }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="spacer s20"></div>

                    <div class="form-group-reg">
                        <label class="label">Name of the main vessel Master / Skipper</label>
                        <input class="input" type="text" placeholder="Skipper name" v-model="vesselInfo.vesselMaster" />
                    </div>

                    <!-- <div class="form-group-reg">
                <label class="label">List one crew or deckhand (optional)</label>
                <input class="input" type="text" placeholder="Crew name..." v-model="vesselInfo.crewName">
                <input class="input" type="text" placeholder="Crew phone number..." v-model="vesselInfo.crewNumber">
              </div> -->

                    <div class="form-group-reg">
                        <label class="label">List one shore based contact person</label>
                        <input class="input" type="text" placeholder="Shore contact name" v-model="vesselInfo.shoreContactName" />
                        <input class="input" type="text" placeholder="Shore contact number" v-model="vesselInfo.shoreContactNumber" />
                    </div>

                    <div class="form-group-reg">
                        <label class="label">The ideal number of people on board on a trip</label>
                        <input class="input" type="tel" placeholder="Number of crew" v-model="vesselInfo.vesselCrew" />
                    </div>

                    <div class="form-group-reg">
                        <label class="label">Crewing explanation</label>
                        <textarea
                            class="textarea"
                            placeholder="A basic description of your vessel..."
                            v-model="vesselInfo.appCrewExplanation"
                        ></textarea>
                    </div>

                    <!-- <div class="form-group-reg">
                <div class="spacer s30"></div>
                <label class="label">Other information about your operation</label>
                <div class="checkbox-wrapper">
                  <div class="field" v-for="item in questions" :key="item.id">
                    <input class="is-checkradio" type="checkbox" :id="item.id" :value="item" v-model="selectedAnswers">
                    <label :for="item.id">{{item.name}}</label>
                  </div>
                </div>
              </div> -->

                    <!--  <hr> -->

                    <!--  {{vesselInfo}} -->

                    <div class="clear"></div>
                </div>

                <div class="clear"></div>
                <div class="spacer s30"></div>

                <div class="buttons">
                    <span class="button is-info is-medium" @click="$router.go(-1)"><font-awesome-icon icon="chevron-left" /> &nbsp;Back</span>
                    <span class="button is-primary is-medium" @click="updateInfo()">Update</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { db } from '@/main.js';
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import * as moment from 'moment';

export default {
    name: 'FixSmsInfo',
    components: {},
    mounted: function() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    data: function() {
        return {
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            allVessels: [],
            isSelected: false,
            findName: '',
            hideExpired: true,
            selectedVessel: '',
            vesselInfo: {
                description: '',
                homePort: '',
                selectedStates: '',
                selectedIndustries: '',
                selectedClasses: '',
                selectedAreas: '',
                vesselMaster: '',
                shoreContactName: '',
                shoreContactNumber: '',
                vesselCrew: '',
                appCrewExplanation: '',
                vesselOwnerName: '',
                vesselOwnerPhone: '',
                vesselOwnerEmail: ''
            },

            allstates: [
                { id: 's1', name: 'Tasmania', checked: false, code: 'base' }, //make
                { id: 's2', name: 'Victoria', checked: false, code: 'base' }, //deep
                { id: 's3', name: 'New South Wales', checked: false, code: 'base' }, //just
                { id: 's4', name: 'Queensland', checked: false, code: 'base' }, //wear
                { id: 's5', name: 'South Australia', checked: false, code: 'base' }, //snap
                { id: 's6', name: 'Western Australia', checked: false, code: 'base' }, //dash
                { id: 's7', name: 'Northern Territory', checked: false, code: 'base' } //slip
            ],
            industries: [
                { id: 'i1', name: 'Fishing', checked: false, code: 'keep' },
                { id: 'i2', name: 'Aquaculture', checked: false, code: 'wrap' },
                { id: 'i3', name: 'Commercial Diving', checked: false, code: 'lump' },
                { id: 'i4', name: 'Marine Construction', checked: false, code: 'corn' },
                { id: 'i5', name: 'Charter Vessel', checked: false, code: 'wood' },
                { id: 'i6', name: 'Passenger Vessel', checked: false, code: 'pest' },
                { id: 'i7', name: 'Other', checked: false, code: 'base' } //gate
            ],
            classes: [
                { id: 'c1', name: '1 - Passenger vessel (13 or more passengers)', code: 'tent' },
                { id: 'c2', name: '2 - Non-passenger vessel (up to 12 passengers)', code: 'wall' },
                { id: 'c3', name: '3 - Fishing vessel', code: 'firm' },
                { id: 'c4', name: '4 - Hire and drive vessel used by the hirer only for recreational purposes', code: 'aunt' }
            ],
            areas: [
                { id: 'A', name: 'Unlimited domestic operations', code: 'burp' },
                { id: 'B', name: 'B - Offshore operations (within 200 nautical miles)', code: 'news' },
                { id: 'Be', name: 'B (extended) - Offshore operations (beyond 200 nautical miles)', code: 'lazy' },
                { id: 'C', name: 'C - Offshore operations (within 30 nautical miles)', code: 'grow' },
                { id: 'Cr', name: 'C (restricted) - Offshore operations - specified areas only', code: 'club' },
                { id: 'D', name: 'D - Partially smooth water operations', code: 'bank' },
                { id: 'E', name: 'E - Smooth water operations', code: 'step' }
            ]
        };
    },

    computed: {
        userData() {
            return this.$store.getters.getUser;
        },
        isSteve() {
            return this.userData
                ? this.userData.uid == 'M3GGz7qWIxNqAsN2Ojglrv334jm1' || this.userData.uid == '3wTzZecD4QYOPUk4IyWRA2JmDcJ2'
                    ? true
                    : false
                : false;
        },
        pathsAreValid() {
            return this.sourcePath == '' || this.targetPath == '';
        },
        filteredVessels() {
            return this.hideExpired ? this.allVessels.filter(vessel => !vessel.isExpired).map(vess => vess) : this.allVessels;
        },
        filteredNames() {
            let filter = new RegExp(this.findName, 'i');
            return this.filteredVessels.filter(user => user.vesselName.match(filter));
        }
        // selectedItemIDs() {
        //   return this.selectedItems.map((item) => item.id);
        // }
    },

    methods: {
        onUserLogin: function(user) {
            if (user) {
                // console.log(user);
                this.loadAllVessels();
            }
        },

        close: function() {},

        // editItem(item) {
        //   console.log(item);
        //   this.selectedEditItem = item;
        //   this.showEditItemModal = true;
        // },

        // saveEditItem(itemToSave) {
        //   let self = this;
        //   console.log(itemToSave);
        //   NProgress.start();
        //   var adminSaveClientSmsItem = firebase.functions().httpsCallable('adminSaveClientSmsItem');
        //   adminSaveClientSmsItem({
        //     collection: 'smsContacts',
        //     vesselID: self.selectedVessel.vesselID,
        //     docID: itemToSave.id,
        //     title: itemToSave.title,
        //     details: itemToSave.details,
        //     index: itemToSave.index
        //   })
        //   .then(function(result) {
        //     self.close();
        //     NProgress.done();

        //   })
        //   .catch(function(error) {
        //     console.log('Error',error.text);
        //     NProgress.done();
        //   });

        // },

        isNumber: function($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        displayInfo() {
            // console.log(this.selectedVessel.vesselID);
            let self = this;
            db.collection('vessels')
                .doc(this.selectedVessel.vesselID)
                .get()
                .then(doc => {
                    // console.log(doc.data());
                    self.vesselInfo.description = doc.data().description ? doc.data().description : '';
                    self.vesselInfo.homePort = doc.data().homePort ? doc.data().homePort : '';
                    self.vesselInfo.selectedStates = doc.data().vesselStates ? doc.data().vesselStates : [];
                    self.vesselInfo.selectedIndustries = doc.data().vesselIndustries ? doc.data().vesselIndustries : [];
                    self.vesselInfo.selectedClasses = doc.data().surveyClasses ? doc.data().surveyClasses : [];
                    self.vesselInfo.selectedAreas = doc.data().operationalAreas ? doc.data().operationalAreas : [];
                    self.vesselInfo.vesselMaster = doc.data().vesselMaster ? doc.data().vesselMaster : '';
                    self.vesselInfo.shoreContactName = doc.data().shoreContactName ? doc.data().shoreContactName : '';
                    self.vesselInfo.shoreContactNumber = doc.data().shoreContactNumber ? doc.data().shoreContactNumber : '';
                    self.vesselInfo.vesselCrew = doc.data().vesselCrew ? doc.data().vesselCrew : '';
                    self.vesselInfo.appCrewExplanation = doc.data().appCrewExplanation ? doc.data().appCrewExplanation : '';
                    self.vesselInfo.vesselOwnerName = doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '';
                    self.vesselInfo.vesselOwnerPhone = doc.data().vesselOwnerPhone ? doc.data().vesselOwnerPhone : '';
                    self.vesselInfo.vesselOwnerEmail = doc.data().vesselOwnerEmail ? doc.data().vesselOwnerEmail : '';
                })
                .then(() => {
                    NProgress.done();
                });
        },

        updateInfo() {
            // console.log(this.vesselInfo);
            let self = this;
            NProgress.start();
            db.collection('vessels')
                .doc(this.selectedVessel.vesselID)
                .update({
                    description: self.vesselInfo.description,
                    homePort: self.vesselInfo.homePort,
                    vesselStates: self.vesselInfo.selectedStates,
                    vesselIndustries: self.vesselInfo.selectedIndustries,
                    surveyClasses: self.vesselInfo.selectedClasses,
                    operationalAreas: self.vesselInfo.selectedAreas,
                    vesselMaster: self.vesselInfo.vesselMaster,
                    shoreContactName: self.vesselInfo.shoreContactName,
                    shoreContactNumber: self.vesselInfo.shoreContactNumber,
                    vesselCrew: self.vesselInfo.vesselCrew,
                    appCrewExplanation: self.vesselInfo.appCrewExplanation,
                    vesselOwnerName: self.vesselInfo.vesselOwnerName,
                    vesselOwnerPhone: self.vesselInfo.vesselOwnerPhone,
                    vesselOwnerEmail: self.vesselInfo.vesselOwnerEmail
                })
                .then(() => {
                    NProgress.done();
                    self.clearVessel();
                })
                .catch(err => {
                    console.log(err);
                    NProgress.done();
                });
        },

        clearVessel: function() {
            this.findName = '';
            this.isSelected = false;
            this.selectedVessel = '';
            this.vesselInfo = {
                description: '',
                homePort: '',
                selectedStates: '',
                selectedIndustries: '',
                selectedClasses: '',
                selectedAreas: '',
                vesselMaster: '',
                shoreContactName: '',
                shoreContactNumber: '',
                vesselCrew: '',
                appCrewExplanation: '',
                vesselOwnerName: '',
                vesselOwnerPhone: '',
                vesselOwnerEmail: ''
            };
        },

        selectVessel(vessel) {
            NProgress.start();
            // console.log(vessel);
            let self = this;
            this.selectedVessel = vessel;
            this.isSelected = true;
            setTimeout(() => {
                self.displayInfo();
            }, 1000);
        },

        loadAllVessels: function() {
            let self = this;
            db.collection('vessels')
                .orderBy('vesselOwnerName', 'asc')
                .onSnapshot(function(querySnapshot) {
                    self.allVessels = [];
                    querySnapshot.forEach(doc => {
                        self.allVessels.push({
                            vesselID: doc.id,
                            vesselUVI: doc.data().vesselUVI ? doc.data().vesselUVI : '',
                            vesselName: doc.data().vesselName,
                            vesselOwnerName: doc.data().vesselOwnerName ? doc.data().vesselOwnerName : '?',
                            vesselOwnerID: doc.data().vesselOwnerID
                        });
                    });
                });
        },

        formatDate: function(sentDate, format) {
            let fixDate = sentDate ? sentDate.toDate() : new Date();
            //console.log("Fix date:",fixDate);
            return moment(fixDate).format(format);
        }
    }
};
</script>

<style lang="scss" scoped>
.site-wrapper .container {
    max-width: 850px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
.traffic-light {
    position: relative;
    span {
        float: left;
        margin-right: 10px;
    }
    .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
            background: red;
            &.active {
                background: red;
            }
        }
        &.orange {
            background: orange;
            &.active {
                background: orange;
            }
        }
        &.green {
            background: #03d203;
            &.active {
                background: #03d203;
            }
        }
    }
}

// input[type="text"] {
//   width: 90%;
//   float: left;
//   display: block;
//   margin-bottom: 2rem;
//   // &:after {
//   //   content: "\f16c";
//   //   font-family: "Font Awesome 5 Brands";
//   //   color: red;
//   // }
//   // &.valid:after {

//   // }
// }
.icon-wrap {
    display: block;
    float: left;
    height: 35px;
    margin-left: 10px;
    font-size: 1.5rem;
    color: red;
    &.valid {
        color: green;
    }
}
label {
    margin-bottom: 10px;
    display: block;
}
// .is-checkradio[type=checkbox] {
//   opacity: 1.0;
//   -ms-transform: scale(2); /* IE */
//   -moz-transform: scale(2); /* FF */
//   -webkit-transform: scale(2); /* Safari and Chrome */
//   -o-transform: scale(2); /* Opera */
//   transform: scale(2);
//   padding: 10px;
//   margin-top: 10px;
//   margin-left: 10px;
// }

.site-wrapper .container {
    max-width: 1050px;
}
.buttons {
    margin-bottom: 5px;
}
.button {
    margin-top: 0;
    &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #fff;
    }
}
input[type='text'] {
    padding: 2rem 1rem;
}
.form-group {
    label {
        top: auto;
        bottom: -25px;
    }
}
.traffic-light {
    position: relative;
    span {
        float: left;
        margin-right: 10px;
    }
    .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        &.red {
            background: red;
            &.active {
                background: red;
            }
        }
        &.orange {
            background: orange;
            &.active {
                background: orange;
            }
        }
        &.green {
            background: #03d203;
            &.active {
                background: #03d203;
            }
        }
    }
}
.highlight {
    background: dodgerblue;
    padding: 15px;
    color: #fff;
}
.modal {
    .modal-card {
        .modal-card-head {
            z-index: 999 !important;
        }
        .modal-card-foot {
            z-index: 999 !important;
        }
    }
}
input[type='text'],
input[type='tel'] {
    padding: 2rem 1rem;
}

textarea {
    min-height: 120px;
    height: 400px;
    margin-top: 10px;
    padding: 1.5rem 1rem;
}
table {
    td {
        padding-bottom: 21px;
        &.is-updated {
            color: green;
            font-weight: bold;
        }
        .small {
            font-size: 0.8rem;
            margin-top: 5px;
            text-align: center;
        }
    }
}
.show-details {
    cursor: pointer;
    &:hover {
        color: dodgerblue;
    }
}
</style>
